<template>
  <div v-if="leaks.length"
       class="row">
    <div class="col-12 mb-2">
      <div class="custom-card"
           v-b-toggle="`leaks-list`">
        <div class="card-body d-flex justify-content-between align-items-center">
          <div class="task-card-title mb-0 d-flex align-items-center">
            Утечки
            <div class="items-count">{{ leaks.length }}</div>
          </div>
          <div class="chevron-block">
            <chevron-right-icon class="chevron"
                                :class="{'rotate': collapseVisible}" />
          </div>
        </div>
      </div>
    </div>
    <b-collapse id="leaks-list"
                v-model="collapseVisible"
                class="col-12">
      <div v-for="leak in leaks"
           :key="leak.id"
           class="custom-card mb-2 leak-item">
        <div class="card-body">
          <div class="row">
            <div class="col-12 mb-2">
              <div class="leak-data">{{ leak.getCategoryLabel() }} <span>#{{ leak.id }}</span></div>
            </div>
            <div class="col-12">
              <div class="leak-data">
                Тип: <span>{{ leak.getTypeLabel() }}</span>,
                <span v-if="leak.type === 1">{{ leak.wormholes_count }} шт.</span>
                <span v-else-if="leak.type === 2">{{ leak.width }}x{{ leak.height }}мм</span>
                <span v-else-if="leak.type === 3 || leak.type === 4">Ø{{ leak.diameter }}мм</span>
              </div>
            </div>
            <div class="col-12">
              <div class="leak-data">
                Объём потерь: <span>{{ leak.volume.toFixed(2) }} м<sup>3</sup></span>
              </div>
            </div>
            <div class="col-6">
              <div class="leak-block">
                <div>Факт. дата начала</div>
                <div>{{ $moment(leak.actual_start_date).format('DD.MM.YY HH:mm') }}</div>
              </div>
            </div>
            <div class="col-6">
              <div class="leak-block">
                <div>Факт. дата окончания</div>
                <div>{{ $moment(leak.actual_end_date).format('DD.MM.YY HH:mm') }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </b-collapse>
  </div>
</template>

<script>
import {mapGetters} from "vuex";

export default  {
  name: "LeaksList",
  data() {
    return {
      collapseVisible: false
    }
  },
  computed: {
    ...mapGetters({
      leaks: 'leaks/getLeaks'
    })
  }
}
</script>

<style scoped>
.leak-block {
  margin-top: 20px;
}

.leak-block div:first-child {
  color: #262F3D;
  font-size: 14px;
  line-height: 15px;
  font-weight: 600;
  margin-bottom: 5px;
}

.leak-block div:last-child {
  color: #468FC5;
  font-size: 16px;
  line-height: 17px;
}

.leak-data {
  color: #262F3D;
  font-size: 16px;
  font-weight: 600;
}

.leak-data span {
  color: #468FC5;
  font-size: 16px;
  font-weight: 600;
}
</style>