<template>
  <b-modal
    id="add-file"
    ref="add-file"
    centered
    hide-header
    hide-footer
  >
    <div class="col-12">
      <div class="custom-modal-header">
        <div class="custom-modal-title">
          Прикрепление файла
        </div>
        <img
          class="custom-modal-close"
          src="@/assets/img/icons/close.svg"
          @click="$bvModal.hide('add-file')"
        >
      </div>
      <div class="modal-divider" />
    </div>
    <form @submit.prevent="submitForm()">
      <div class="col-12 mb-2">
        <div class="form-group">
          <label class="form-label">Наименование</label>
          <input v-model="name"
                 class="form-control form-control-fix"
                 placeholder="Введите краткое описание файла"
                 type="text">
          <div v-for="(tag, index) in nameInputTags"
               :key="index"
               @click="name = tag"
               class="input-tag">{{tag}}</div>
        </div>
        <div class="form-group">
          <label class="form-label">Файл</label>
          <input @change="handleFileUpload()"
                 ref="file"
                 class="form-control form-control-fix"
                 type="file"
                 accept=".pdf,.png,.jpg">
        </div>
        <div class="modal-divider" />
        <div class="row">
          <div class="col-12">
            <button
              type="submit"
              class="btn custom-btn-blue w-100"
            >
              Прикрепить
            </button>
          </div>
        </div>
      </div>
    </form>
  </b-modal>
</template>

<script>
import {mapGetters} from "vuex";
import notification from "@/mixins/notification";

export default {
  name: "AddFiles",
  mixins: [notification],
  data() {
    return {
      payload: {
        task_id: null,
        formData: null
      },
      nameInputTags: [
        'Ограждение',
        'Разрешение на разрытие',
        'Сварочный шов',
        'Благоустройство',
        'Траншея',
        'Установлен хомут'
      ],
      name: null,
      file: null
    }
  },
  computed: {
    ...mapGetters({
      task_id: 'task/getTaskId'
    })
  },
  methods: {
    submitForm() {
      this.payload.task_id = this.task_id;
      this.payload.formData = new FormData();
      this.payload.formData.append('file', this.file);
      this.payload.formData.append('name', this.name);

      this.$store.dispatch('task/addFile', this.payload)
          .then(() => {
            this.$bvModal.hide('add-file');
            this.toast('Файл добавлен', 'success');
            this.$store.dispatch('files/getTasksFiles', this.task_id);
          })
          .catch(error => {
            this.alert('Ошибка', error, 'error');
          })
    },
    handleFileUpload() {
      const [file] = this.$refs.file.files;
      this.file = file;
    }
  }
}
</script>

<style scoped>
.input-tag {
  display: inline-block;
  cursor: pointer;
  background-color: white;
  color: #468FC5;
  border: 1px solid #468fc599;
  border-radius: 5px;
  padding: 2px 6px;
  margin: 2px 2px 0 0;
  font-weight: 600;
  font-size: 12px;
  transition: .3s;
}
</style>