<template>
  <base-map :center="this.$store.getters['baseMap/getCenter']"
            :zoom="this.$store.getters['baseMap/getZoom']"
            :markers="marker"
            base_layers_control_position_top="10px"
  />
</template>

<script>
import Marker from "@/models/baseMap/Marker";
import Icon from "@/models/baseMap/Icon";
import BaseMap from "@/components/BaseMap";

export default {
  name: "IssueMap",
  components: {BaseMap},
  props: ['issue'],
  data() {
    return {
      marker: null
    }
  },
  mounted() {
    this.createMarker();
  },
  methods: {
    createMarker() {
      this.marker = [new Marker({
        coords: this.issue.coordinates,
        icon: new Icon({
          type: 'icon',
          url: require('@/assets/img/icons/pin-low.svg'),
          size: [36, 36],
          anchor: [18, 36]
        })
      })]
    }
  }
}
</script>

<style scoped>

</style>