<template>
  <b-modal
    id="add-transport-order"
    ref="add-transport-order"
    centered
    hide-header
    hide-footer
    @hidden="clearFields()"
  >
    <div class="col-12">
      <div class="custom-modal-header">
        <div class="custom-modal-title">
          Заказать транспорт
        </div>
        <img
          alt="Закрыть"
          class="custom-modal-close"
          src="@/assets/img/icons/close.svg"
          @click="$bvModal.hide('add-transport-order')"
        >
      </div>
      <div class="modal-divider" />
    </div>
    <form @submit.prevent="submitForm()">
      <div class="col-12 mb-2">
        <div class="form-group">
          <label class="form-label">Необходимый тип транспорта<span class="red">*</span></label>
          <v-select
            v-model="payload.transport_type"
            :options="transportTypes"
            :reduce="type => type.id"
            :get-option-label="(option) => option.name"
            placeholder="Выберите тип транспорта"
          />
        </div>
        <div class="form-group">
          <label class="form-label">Необходимый транспорт</label>
          <v-select
            v-model="payload.transport_id"
            :options="vehicles.filter(vehicle => vehicle.attributes.type === payload.transport_type)"
            :reduce="vehicle => vehicle.id"
            :get-option-label="(option) => option.attributes.label + ' ' + option.attributes.gov_number"
            placeholder="Выберите транспорт"
          />
        </div>
        <div class="form-group">
          <label class="form-label">Желаемая дата и время подачи<span class="red">*</span></label>
          <date-picker
            v-model="payload.delivery_date"
            :default-value="defaultValue"
            input-class="form-control-fix w-100 pl-2"
            placeholder="Выберите дату и время"
            type="datetime"
            value-type="YYYY-MM-DD HH:mm"
            :format="DATE_TIME_FORMAT"
          />
        </div>
        <div class="form-group">
          <label class="form-label">Комментарий</label>
          <textarea
            v-model="payload.comment"
            maxlength="1500"
            class="form-control form-control-fix"
            placeholder="Введите комментарий" />
        </div>
        <div class="modal-divider" />
        <div class="row">
          <div class="col-12">
            <button
              type="submit"
              class="btn custom-btn-blue w-100"
            >
              Добавить
            </button>
          </div>
        </div>
      </div>
    </form>
  </b-modal>
</template>

<script>
import Vehicle from "@/models/transport/Vehicle";
import {mapState} from "vuex";
import {showAlert, showToast} from "@/utils/notification";
import DatePicker from "vue2-datepicker";

export default {
  name: "AddTransportOrder",
  components: {DatePicker},
  data() {
    return {
      payload: {
        transport_type: null,
        transport_id: null,
        delivery_date: null,
        comment: null
      },
      defaultValue: new Date()
    }
  },
  computed: {
    ...mapState({
      vehicles: state => state.autos.autos,
      task: state => state.task.task
    }),
    transportTypes: () => Vehicle.getTransportTypes(),
    DATE_TIME_FORMAT: () => process.env.VUE_APP_BASE_DATE_TIME_FORMAT
  },
  mounted() {
    this.$store.dispatch('autos/getAllAutos', {state: 1});
  },
  methods: {
    submitForm() {
      this.payload.task_id = this.task.task.id;
      this.$store.dispatch('transportOrders/createTransportOrder', this.payload)
          .then(() => {
            this.$store.commit('transportOrders/SET_FILTER_PARAMS', {
              task_id: this.task.task.id
            });
            this.$store.dispatch('transportOrders/getTransportOrders');
            showToast('Транспорт заказан', 'success');
            this.$bvModal.hide('add-transport-order');
          })
          .catch(error => showAlert('Ошибка', error));
    },
    clearFields() {
      this.payload = {
        transport_type: null,
        transport_id: null,
        delivery_date: null,
        comment: null
      }
    }
  }
}
</script>

<style scoped>

</style>