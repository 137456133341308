import DeviceService from "@/services/DeviceService";

export const namespaced = true;

export const state = {
    networkObjectDevices: [],
    loading: false,
    currentEquipmentParameter: null,
    equipmentId: null,
    currentDeviceSensor: null,
    deviceId: null
}

export const mutations = {
    SET_NETWORK_OBJECT_DEVICES(state, devices) {
        state.networkObjectDevices = devices;
    },
    SET_LOADING(state, loading) {
        state.loading = loading;
    },
    SET_CURRENT_EQUIPMENT_PARAMETER(state, parameter) {
        state.currentEquipmentParameter = parameter;
    },
    SET_EQUIPMENT_ID(state, id) {
        state.equipmentId = id;
    },
    SET_CURRENT_DEVICE_SENSOR(state, sensor) {
        state.currentDeviceSensor = sensor;
    },
    SET_DEVICE_ID(state, id) {
        state.deviceId = id;
    }
}

export const actions = {
    getNetworkObjectDevices({commit}, filterParams) {
        commit("SET_LOADING", true);
        DeviceService.getDevices(filterParams)
            .then(devices => commit("SET_NETWORK_OBJECT_DEVICES", devices))
            .finally(() => commit("SET_LOADING", false))
    }
}