<template>
  <div class="row">
    <div class="col-xs-12 col-lg-4 min-w">
      <network-objects-filter
        :active-tab="activeTab"
        @change-active-tab="activeTab = $event"
        @toggle-settings-block="sortingBlock = $event"
        @search-query="query = $event"
      />
      <network-objects-list
        v-show="!sortingBlock"
        :active-tab="activeTab"
        :query="query" />

    </div>
    <div class="col">
      <telemetry-map
        :active-tab="activeTab"
        @change-active-tab="activeTab = $event"
      />
    </div>
  </div>
</template>

<script>
import TelemetryMap from "@/components/Telemetry/TelemetryMap";
import NetworkObjectsFilter from "@/components/Telemetry/NetworkObjectsFilter";
import NetworkObjectsList from "@/components/Telemetry/NetworkObjectsList";

export default {
  name: "TelemetryPage",
  components: {
    NetworkObjectsList,
    NetworkObjectsFilter,
    TelemetryMap
  },
  data() {
    return {
      activeTab: 1,
      timing: null,
      sortingBlock: false,
      query: ''
    }
  },
  mounted() {
    this.$store.dispatch('telemetry/getNetworkObjects');
    this.$store.dispatch('telemetry/getNetworkEquipments');
    this.getParametersValues();

    if (localStorage.getItem('hiddenInList') == null) {
      localStorage.setItem('hiddenInList', JSON.stringify([]));
    }
    if (localStorage.getItem('hiddenOnMap') == null) {
      localStorage.setItem('hiddenOnMap', JSON.stringify([]));
    }
    if (localStorage.getItem('fixedOnMap') == null) {
      localStorage.setItem('fixedOnMap', JSON.stringify([]));
    }
  },
  beforeDestroy() {
    clearInterval(this.timing);
  },
  methods: {
    getParametersValues() {
      this.$store.dispatch('telemetry/getParametersValues');

      if (!this.timing) {
        this.runTiming();
      }
    },
    runTiming() {
      clearInterval(this.timing);
      this.timing = setInterval(this.getParametersValues, 60000)
    }
  }
}
</script>

<style scoped>
.content {
  padding: 2rem 2.5rem;
}

.min-w {
  min-width: 370px;
}

@media (max-width: 400px) {
  .content {
    padding: 2rem 1rem;
  }
}
</style>