<template>
  <div class="row mb-3">
    <div class="col-11">
      <div class="custom-card">
        <div class="card-body">
          <div class="d-flex">
            <date-picker
              id="opened_date"
              class="w-100 mr-4"
              input-class="form-control-fix w-100 pl-2"
              placeholder="Выберите период открытия заявки"
              v-model="opened_period"
              :default-value="defaultValue"
              format="DD.MM.YYYY"
              type="date"
              range
              value-type="YYYY-MM-DD"
              @clear="filter.opened_date = null"
              @input="submitFilter()"
            />
            <date-picker
              id="closed_date"
              class="w-100 mr-4"
              input-class="form-control-fix w-100 pl-2"
              placeholder="Выберите период закрытия заявки"
              v-model="closed_period"
              :default-value="defaultValue"
              format="DD.MM.YYYY"
              type="date"
              range
              value-type="YYYY-MM-DD"
              @clear="filter.closed_date = null"
              @input="submitFilter()"
            />
            <button @click="clearFilter()"
                    class="btn custom-btn-blue-outline reset-filter-btn">
              <icon-reset/>
            </button>
          </div>
          <b-collapse id="filter-collapse"
                      v-model="filterCollapseToggled">
            <div class="row mt-3">
              <div class="col-xl-6 col-xs-12">
                <div class="form-group">
                  <label class="form-label">Тег</label>
                  <v-select
                    v-model="filter.tags"
                    :options="getIssueTags()"
                    :reduce="tag => tag.id"
                    multiple
                    :get-option-label="(option) => option.label"
                    placeholder="Выберите теги"
                    @input="submitFilter()"
                  />
                </div>
              </div>
              <div class="col-xl-6 col-xs-12">
                <div class="form-group">
                  <label class="form-label">Адрес</label>
                  <dadata-suggestions
                    v-model="address"
                    class="form-control form-control-fix"
                    type="ADDRESS"
                    @change="setAddress"
                    placeholder="Введите адрес"
                  />
                </div>
              </div>
              <div class="col-xl-6 col-xs-12">
                <div class="form-group">
                  <label class="form-label">Тип</label>
                  <v-select
                    v-model="issueType"
                    :options="issueTypes"
                    :reduce="type => type.id"
                    :get-option-label="option => option.name"
                    placeholder="Выберите тип"
                    @input="submitFilter()" />
                </div>
              </div>
              <div class="col-xl-6 col-xs-12">
                <div class="form-group">
                  <label class="form-label">Категория</label>
                  <v-select
                    v-model="categoryId"
                    :options="categories.concat()
                      .sort((a, b) => a.group.id - b.group.id || a.name.localeCompare(b.name))"
                    :reduce="category => category.id"
                    :get-option-label="(option) => option.name"
                    placeholder="Выберите категорию"
                    @input="submitFilter()"
                  >
                    <template #option="{name, group}">
                      <span class="font-weight-bold">{{group.name}}: </span>{{name}}
                    </template>
                  </v-select>
                </div>
              </div>
              <div class="col-xl-6 col-xs-12">
                <div class="state-filter-label">Статус</div>
                <label v-for="state in states"
                       :key="state.id"
                       class="custom-control custom-checkbox mr-3 d-inline-block">
                  <input
                    v-model="filter.issue_states"
                    :value="state.id"
                    class="custom-control-input"
                    type="checkbox"
                    :disabled="validateStatesFilter(state.id)"
                    @change="submitFilter()"
                  >
                  <span class="custom-control-label">
                    {{state.label}}
                  </span>
                </label>
              </div>
            </div>
          </b-collapse>
        </div>
      </div>
    </div>
    <div class="col-1">
      <div class="toggle-filter-block">
        <div class="toggle-filter"
             v-b-toggle.filter-collapse>
          <chevron-left-icon :class="{rotate: filterCollapseToggled}"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import IconReset from "@/components/Icons/IconReset";
import DatePicker from "vue2-datepicker";
import Tag from "@/models/serviceDesk/Tag";
import IssueState from "@/models/serviceDesk/IssueState";
import Vue from "vue";
import DadataSuggestions from "vue-dadata-suggestions";
import {mapGetters} from "vuex";

Vue.use(DadataSuggestions, {
  token: process.env.VUE_APP_DADATA_API_KEY,
  geoLocation: [{
    "kladr_id": process.env.VUE_APP_DADATA_KLADR_ID
  }]
});

export default {
  name: "IssuesArchiveFilter",
  components: {IconReset, DatePicker},
  data() {
    return {
      filter: {
        issue_states: [IssueState.CANCELED, IssueState.CLOSED],
        tags: []
      },
      opened_period: null,
      closed_period: null,
      defaultValue: new Date(),
      filterCollapseToggled: false,
      address: null,
      splitAddress: null,
      categoryId: null,
      issueType: null,
      states: [
        {id: IssueState.CANCELED, label: 'Отменена'},
        {id: IssueState.CLOSED, label: 'Закрыта'}
      ]
    }
  },
  computed: {
    ...mapGetters({
      categories: 'serviceDeskCategories/getCategories',
      issueTypes: 'issueTypes/getIssueTypes'
    }),
    getIssueTags: function () {
      return () => {
        return Tag.tags;
      }
    }
  },
  mounted() {
    this.$store.dispatch('serviceDeskCategories/getCategories');
    this.$store.dispatch('issueTypes/getIssueTypes');
  },
  methods: {
    validateStatesFilter(state_id) {
      if (this.filter.issue_states.length === 1) {
        return !!this.filter.issue_states.find(id => id === state_id);
      }
    },
    setAddress(i) {
      this.splitAddress = {
        address_region: i.data.region,
        address_city: i.data.city,
        address_settlement: i.data.settlement_with_type,
        address_street: i.data.street_with_type,
        address_house: i.data.house,
        address_block: i.data.block,
        address_flat: i.data.flat
      }
      this.submitFilter();
    },
    submitFilter() {
      const filter = {
        issue_states: this.filter.issue_states.join(',')
      }

      if (this.filter.tags.length) {
        filter.tags = this.filter.tags.join(',')
      }

      if (this.opened_period) {
        const [open_date_start, open_date_end] = this.opened_period;
        filter.open_date_start = open_date_start;
        filter.open_date_end = open_date_end;
      }

      if (this.closed_period) {
        const [close_date_start, close_date_end] = this.closed_period;
        filter.close_date_start = close_date_start;
        filter.close_date_end = close_date_end;
      }

      if (this.categoryId) filter.category_id = this.categoryId;
      if (this.issueType) filter.type = this.issueType;

      this.$store.commit("issues/SET_FILTER_PARAMS", {
        ...filter,
        ...this.splitAddress
      });
      this.$store.dispatch("issues/getIssues", 1);
    },
    clearFilter() {
      this.filter = {
        issue_states: [IssueState.CANCELED, IssueState.CLOSED],
        tags: []
      }

      this.opened_period = null;
      this.closed_period = null;
      this.splitAddress = null;
      this.address = null;
      this.issueType = null;
      this.categoryId = null;

      this.$store.commit("issues/SET_FILTER_PARAMS", {
        issue_states: [IssueState.CANCELED, IssueState.CLOSED].join()
      });

      this.$store.dispatch("issues/getIssues", 1);
    }
  }
}
</script>

<style scoped>
.toggle-filter-block {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
}

.toggle-filter {
  display: flex;
  align-items: center;
  height: 44px;
  cursor: pointer;
}

.toggle-filter svg {
  width: 30px;
  height: 30px;
  color: #468FC5;
  transition: .3s;
}

.rotate {
  transform: rotateZ(-90deg);
}

.state-filter-label {
  font-size: 14px;
  color: #262F3D;
  font-weight: 600;
  margin-bottom: 15px;
}
</style>