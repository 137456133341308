<template>
  <div>
    <div class="row">
      <div class="col-md-12 mbp-20">
        <div class="input-group">
          <input
            v-model="query"
            class="custom-form-control mr-3 search"
            placeholder="Поиск..."
            type="text"
          >
          <button
            id="toggleSortingBlock"
            class="btn custom-btn"
            :class="{'custom-btn-active': sortingBlock}"
            type="button"
            @click="sortingBlock = !sortingBlock"
          >
            <img
              v-if="!sortingBlock"
              alt="settings"
              src="@/assets/img/transport/settings.svg"
            >
            <img
              v-if="sortingBlock"
              alt="close"
              height="15"
              src="@/assets/img/transport/close.svg"
              width="17"
            >
          </button>
        </div>
      </div>
    </div>

    <div
      v-show="sortingBlock"
      class="settings-block-telemetry pr-24"
    >
      <div class="settings-scroll">
        <div class="settings-body">
          <div class="row">
            <div class="col-12 mb-3 d-flex justify-content-between">
              <div class="construction-type-block">
                <div
                  :class="{activeConstructionType : activeTab === 1}"
                  class="device-icon"
                  @click="changeNetworkObjectActiveType(1)"
                >
                  <img
                    alt="ВНС"
                    src="@/assets/img/telemetry/pump.svg"
                  >
                </div>
                <div class="construction-name">
                  ВНС
                </div>
              </div>
              <div class="construction-type-block">
                <div
                  :class="{activeConstructionType : activeTab === 2}"
                  class="device-icon"
                  @click="changeNetworkObjectActiveType(2)"
                >
                  <img
                    alt="РЧВ"
                    src="@/assets/img/telemetry/tank.svg"
                  >
                </div>
                <div class="construction-name">
                  РЧВ
                </div>
              </div>
              <div class="construction-type-block">
                <div
                  :class="{activeConstructionType : activeTab === 3}"
                  class="device-icon"
                  @click="changeNetworkObjectActiveType(3)"
                >
                  <img
                    alt="МКД"
                    src="@/assets/img/telemetry/mkd.svg"
                  >
                </div>
                <div class="construction-name">
                  МКД
                </div>
              </div>
              <div class="construction-type-block">
                <div
                  :class="{activeConstructionType : activeTab === 4}"
                  class="device-icon"
                  @click="changeNetworkObjectActiveType(4)"
                >
                  <img
                    alt="КП"
                    src="@/assets/img/telemetry/counter.svg"
                  >
                </div>
                <div class="construction-name">
                  КП
                </div>
              </div>
              <div class="construction-type-block">
                <div
                  :class="{activeConstructionType : activeTab === 5}"
                  class="device-icon"
                  @click="changeNetworkObjectActiveType(5)"
                >
                  <img
                    alt="Котельная"
                    src="@/assets/img/telemetry/boiler.svg"
                  >
                </div>
                <div class="construction-name">
                  Котельная
                </div>
              </div>
              <div class="construction-type-block">
                <div
                  :class="{activeConstructionType : activeTab === 0}"
                  class="device-icon"
                  @click="changeNetworkObjectActiveType(0)"
                >
                  <img
                    alt="Все устройства"
                    src="@/assets/img/telemetry/telemetry-all.svg"
                  >
                </div>
                <div class="construction-name">
                  Все
                </div>
              </div>
            </div>

            <div class="col-12 settings-table">
              <div class="row settings-table-header">
                <div>Наименование</div>
                <div>Список</div>
                <div>Карта</div>
                <div>Закрепить</div>
              </div>
              <div
                v-for="networkObject in networkObjects"
                v-show="networkEquipmentsByNetworkObject(networkObject.id).length"
                :key="networkObject.id"
                class="row settings-table-body"
              >
                <div>{{ networkObject.name }}</div>
                <div @click="handleInputChange('show_in_list', networkObject.id)">
                  <img v-if="networkObject.showInList"
                       alt="Показан"
                       src="@/assets/img/transport/listActive.svg">
                  <img v-else
                       alt="Скрыт"
                       src="@/assets/img/transport/list.svg">
                </div>
                <div @click="handleInputChange('show_on_map', networkObject.id)">
                  <img v-if="networkObject.showOnMap"
                       alt="Показан"
                       src="@/assets/img/transport/hideMapActive.svg">
                  <img v-else
                       alt="Скрыт"
                       src="@/assets/img/transport/hideMap.svg">
                </div>
                <div @click="handleInputChange('fix_on_map', networkObject.id)">
                  <img v-if="networkObject.fixOnMap"
                       alt="Показан"
                       src="@/assets/img/transport/fixMapActive.svg">
                  <img v-else
                       alt="Скрыт"
                       src="@/assets/img/transport/fixMap.svg">
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters, mapState} from "vuex";

export default {
  name: "NetworkObjectsFilter",
  props: ['activeTab'],
  data() {
    return {
      query: '',
      sortingBlock: false
    }
  },
  computed: {
    ...mapState({
      networkObjects: state => state.telemetry.networkObjects
    }),
    ...mapGetters({
      networkEquipmentsByNetworkObject: 'telemetry/getNetworkEquipmentByNetworkObjectId'
    })
  },
  watch: {
    sortingBlock: function (value) {
      this.$emit('toggle-settings-block', value);
    },
    query: function (query) {
      this.$emit('search-query', query);
    }
  },
  methods: {
    changeNetworkObjectActiveType(type) {
      this.$emit('change-active-tab', type)
    },
    handleInputChange(type, id) {
      switch (type) {
        case 'show_in_list':
          this.$store.commit('telemetry/TOGGLE_NETWORK_OBJECT_IN_LIST', id);
          break;
        case 'show_on_map':
          this.$store.commit('telemetry/TOGGLE_NETWORK_OBJECT_ON_MAP', id);
          break;
        case 'fix_on_map':
          this.$store.commit('telemetry/TOGGLE_NETWORK_OBJECT_FIX_MAP', id);
          break;
      }
    }
  }
}
</script>

<style scoped>
.activeConstructionType {
  border: 2px solid #1F9BCF;
}

.search {
  width: 88%;
}

.construction-type-block {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.construction-name {
  font-size: 12px;
  color: #468FC5;
  font-weight: 600;
  margin-top: 6px;
}

@media (min-width: 1551px) and (max-width: 1880px) {
  .search {
    width: 82% !important;
  }
}

@media (min-width: 992px) and (max-width: 1550px) {
  .search {
    width: 80% !important;
  }
}

@media (min-width: 450px) and (max-width: 560px) {
  .search {
    width: 81%;
  }
}

@media (max-width: 450px) {
  .search {
    width: 70%;
    margin-left: 20px;
  }

  .settings-scroll {
    max-height: 500px;
  }
}
</style>