<template>
  <table class="service-desk-table table-responsive-xxl">
    <thead class="service-desk-table-header">
      <tr>
        <td>#</td>
        <td>Дата</td>
        <td>Адрес</td>
        <td>Комментарий</td>
      </tr>
    </thead>
    <tbody class="service-desk-table-body">
      <tr v-for="appeal in appeals"
          :key="appeal.id"
          class="appeal-item"
          @click="getAppeal(appeal.id)">
        <td>{{ appeal.id }}</td>
        <td>{{ appeal.created_at | formatDateTime }}</td>
        <td>{{ appeal.getFullAddress() }}</td>
        <td>{{ appeal.message }}</td>
      </tr>
    </tbody>

    <appeal-modal />
  </table>
</template>

<script>
import {mapGetters} from "vuex";
import AppealModal from "@/components/CallCenter/AppealModal";

export default {
  name: "UnallocatedAppealsList",
  components: {AppealModal},
  computed: {
    ...mapGetters({
      appeals: 'appeals/getAppeals'
    })
  },
  methods: {
    getAppeal(id) {
      this.$store.dispatch('appeals/getAppeal', id)
          .then(() => {
            this.$bvModal.show('appeal-modal');
            this.$store.dispatch('history/getAppealHistory', id);
          })
    }
  }
}
</script>

<style scoped>

</style>