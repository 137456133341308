<template>
  <div>
    <div v-for="parameter in parametersList"
         :key="parameter.id"
         class="parameter">
      <div class="parameter__header">
        <div class="parameter__name">{{parameter.name}}</div>
        <div class="parameter__actions">
          <div
            class="network_object__control_btn"
            @click="editParameter(parameter)"
          >
            <img
              src="@/assets/img/transport/settings.svg"
              alt="Изменить">
          </div>
          <div
            class="network_object__control_btn"
            @click="deleteParameter(parameter.id)"
          >
            <img
              src="@/assets/img/telemetry/trash.svg"
              alt="Удалить">
          </div>
        </div>
      </div>

      <div class="parameter__text">
        <span>Краткое наименование:</span>
        <span>{{parameter.shortName}}</span>
      </div>
      <div class="parameter__text">
        <span>Единицы измерения: </span>
        <span>{{parameter.measure}}</span>
      </div>
      <div class="parameter__text">
        <span>Мин. значение</span>
        <span>{{parameter.minValue}}</span>
      </div>
      <div class="parameter__text">
        <span>Макс. значение</span>
        <span>{{parameter.maxValue}}</span>
      </div>
    </div>
    <div @click="createParameter()"
         class="parameter__add_btn">Добавить параметр</div>

  </div>
</template>

<script>
import {showAlert, showToast} from "@/utils/notification";
export default {
  name: "EquipmentParametersList",
  props: {
    parametersList: {
      type: Array,
      required: true
    },
    equipmentId: {
      type: Number,
      required: true
    }
  },
  methods: {
    createParameter() {
      this.$store.commit('networkObjectPage/SET_CURRENT_EQUIPMENT_PARAMETER', null);
      this.$store.commit('networkObjectPage/SET_EQUIPMENT_ID', this.equipmentId);
      this.$bvModal.show('parameter-form');
    },
    editParameter(parameter) {
      this.$store.commit('networkObjectPage/SET_CURRENT_EQUIPMENT_PARAMETER', parameter);
      this.$store.commit('networkObjectPage/SET_EQUIPMENT_ID', this.equipmentId);
      this.$bvModal.show('parameter-form');
    },
    deleteParameter(parameterId) {
      this.$swal({
        title: 'Вы действительно хотите удалить параметр?',
        showCancelButton: true,
        confirmButtonText: `Да`,
        cancelButtonText: 'Отмена',
        icon: 'warning'
      }).then(result => {
        if (result.isConfirmed) {
          this.$store.dispatch('networkEquipmentParameters/deleteNetworkEquipmentParameter', {
            equipmentId: this.equipmentId,
            parameterId: parameterId
          })
              .then(() => {
                this.$store.dispatch('networkEquipments/getNetworkEquipments', {
                  object_id: this.$route.params.id
                });
                showToast('Параметр удалён', 'success');
              })
              .catch(error => showAlert('Ошибка', error))
        }
      });
    }
  }
}
</script>

<style scoped>
.parameter {
  padding: 10px;
}

.parameter__header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.parameter__name {
  display: inline-block;
  font-size: 18px;
  font-weight: 700;
  color: #262f3d;
  line-height: 1;
}

.parameter__actions {
  display: flex;
}

.parameter__text span {
  font-size: 14px;
  font-weight: 600;
}

.parameter__text span:first-child {
  color: #468fc5;
  margin-right: 3px;
}

.parameter__text span:last-child {
  color: #262f3d;
}

.parameter__add_btn {
  width: 100%;
  text-align: center;
  cursor: pointer;
  color: #468fc5;
  font-weight: 600;
}
</style>