<template>
  <div class="row">
    <div class="col-lg-12">
      <div class="mb-4">
        <network-object-info />
      </div>

      <div v-if="networkObject"
           class="mb-4">
        <equipments-list />
      </div>

      <div v-if="networkObject">
        <devices-list />
      </div>
    </div>
  </div>
</template>

<script>
import NetworkObjectInfo from "@/components/Telemetry/admin/NetworkObject/NetworkObjectInfo";
import EquipmentsList from "@/components/Telemetry/admin/NetworkObject/EquipmentsList";
import DevicesList from "@/components/Telemetry/admin/NetworkObject/DevicesList";
import {mapState} from "vuex";
export default {
  name: "NetworkObjectPage",
  components: {DevicesList, EquipmentsList, NetworkObjectInfo},
  computed: {
    ...mapState({
      networkObject: state => state.networkObjects.networkObject
    })
  },
  mounted() {
    this.$store.dispatch('networkObjects/getNetworkObject', this.$route.params.id)
        .then(() => {
          this.$store.dispatch('networkEquipments/getNetworkEquipments', {
            object_id: this.$route.params.id
          });

          this.$store.dispatch('networkObjectPage/getNetworkObjectDevices', {
            construction_id: this.$route.params.id
          });
        })
  }
}
</script>

<style>
.network_object__card_title {
  font-size: 24px;
  color: #262F3D;
  font-weight: 700;
  display: inline-block;
  margin-bottom: 0;
}

.network_object__info div:first-child {
  display: block;
  color: #262F3D;
  font-size: 14px;
  line-height: 1;
  font-weight: 600;
  margin-bottom: 5px;
}

.network_object__info div:last-child {
  color: #468FC5;
  font-size: 16px;
  font-weight: 600;
  line-height: 1.2;
}

.network_object__control_btn {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background-color: #468FC5;
  width: 18px;
  height: 18px;
  border-radius: 4px;
  margin-left: 5px;
}

.network_object__control_btn img {
  width: 12px;
}
</style>