import {getError} from "@/utils/helpers";
import LayerService from "@/services/LayerService";

export const namespaced = true;

export const state  = {
    layers: [],
    meta: null,
    links: null,
    loading: false,
    error: null
};

export const mutations = {
    SET_LAYERS(state, layers) {
        state.layers = layers;
    },
    SET_META(state, meta) {
        state.meta = meta;
    },
    SET_LINKS(state, links) {
        state.links = links;
    },
    SET_LOADING(state, loading) {
        state.loading = loading;
    },
    SET_ERROR(state, error) {
        state.error = error;
    },
    DELETE_LAYER(state, layer_id){
        let index = state.layers.findIndex(layer => layer.id == layer_id);
        state.layers.splice(index, 1);
    }
};

export const actions = {
    getLayers({ commit }) {
        commit("SET_LOADING", true);
        LayerService.getLayers()
            .then(layers => commit("SET_LAYERS", layers))
            .catch(error => commit("SET_ERROR", getError(error)));
    },
    createLayer(context, payload) {
        return new Promise((resolve, reject) => {
            LayerService.createLayer(payload)
                .then(() => resolve())
                .catch(error => reject(getError(error)))
        })
    },
    updateLayer(context, {layerId, payload}) {
        return new Promise((resolve, reject) => {
            LayerService.updateLayer(layerId, payload)
                .then(() => resolve())
                .catch(error => reject(getError(error)))
        })
    },
    deleteLayer(context, layerId) {
        return new Promise((resolve, reject) => {
            LayerService.deleteLayer(layerId)
                .then(() => resolve())
                .catch(error => reject(getError(error)))
        })
    }
};

export const getters = {
    getLayer: state => id =>{
        return state.layers.find(layer => layer.id === parseInt(id));
    },
    layers: state => {
        return state.layers;
    },
    meta: state => {
        return state.meta;
    },
    links: state => {
        return state.links;
    },
    loading: state => {
        return state.loading;
    },
    error: state => {
        return state.error;
    }
}