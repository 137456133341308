<template>
  <div class="links-pagination">
    <div v-for="(link, index) in meta.links"
         :key="index">
      <div v-if="link.label === 'pagination.previous'"
           @click="paginate(meta.current_page - 1)"
           class="nav-arrow">
        <chevron-left-icon/>
      </div>
      <div v-else-if="link.label === 'pagination.next'"
           @click="paginate(meta.current_page + 1)"
           class="nav-arrow">
        <chevron-right-icon/>
      </div>
      <div v-else-if="link.label === '...'"
           class="hidden-elements">
        {{ link.label }}
      </div>
      <div v-else
           @click="paginate(parseInt(link.label))"
           class="pagination-item"
           :class="{active: link.active}">
        {{ link.label }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "BasePaginate",
  props: {
    action: {
      type: String,
      required: true
    },
    commit: {
      type: String
    },
    meta: {
      type: Object,
      required: true
    }
  },
  methods: {
    paginate(page) {
      this.$store.commit(this.commit, page);
      this.$store.dispatch(this.action, page);
    }
  }
}
</script>

<style scoped>
.links-pagination {
  display: flex;
  margin-top: .5rem;
  justify-content: flex-end;
}

.pagination-item {
  margin-right: 5px;
  background-color: #EAEFF2;
  padding: 4px 6px;
  color: #468FC5;
  border-radius: 3px;
  cursor: pointer;
}

.active {
  background-color: #468FC5;
  color: white;
}

.hidden-elements {
  display: flex;
  align-items: flex-end;
  color: #468FC5;
  margin-right: 5px;
  font-size: 17px;
  height: 100%;
  padding: 0 5px;
}

.nav-arrow {
  display: flex;
  align-items: center;
  height: 100%;
  cursor: pointer;
}

.nav-arrow svg {
  color: #468FC5;
}
</style>