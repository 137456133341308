<template>
  <table class="admin-table">
    <thead class="admin-table-header">
      <tr>
        <td>ID</td>
        <td>Наименование</td>
        <td>Ссылка</td>
        <td>Ресурс</td>
        <td>Действия</td>
      </tr>
    </thead>
    <tbody class="admin-table-body">
      <tr
        v-for="layer in this.$store.state.layer.layers"
        :key="layer.id"
      >
        <td>{{ layer.id }}</td>
        <td>{{ layer.name }}</td>
        <td>{{ layer.url }}</td>
        <td>{{ layer.resource }}</td>
        <td class="table-action">
          <router-link :to="'/admin-layers-update/'+layer.id">
            <edit-icon class="mr-1" />
          </router-link>
          <a @click="deleteLayer(layer.id)">
            <trash2-icon />
          </a>
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
import {showToast, showAlert} from "@/utils/notification";
import {getError} from "@/utils/helpers";

export default {
  name: "LayersList",
  methods:{
    async deleteLayer(layerId){
      this.$swal({
        title: 'Вы действительно хотите удалить слой?',
        showCancelButton: true,
        confirmButtonText: `Да`,
        cancelButtonText: 'Отмена',
        icon: 'warning'
      }).then(result=>{
        if (result.isConfirmed) {
          this.$store.dispatch('layer/deleteLayer', layerId)
              .then(() => {
                showToast('Слой удален', 'success')
                this.$store.commit('layer/DELETE_LAYER', layerId);
              })
              .catch(error => showAlert('Ошибка', getError(error)))
        }
      });
    }
  }
}
</script>

<style scoped>

</style>