<template>
  <b-modal
    id="transport-order-modal"
    ref="transport-order-modal"
    centered
    hide-header
    hide-footer
    @shown="fillField()"
    @hidden="clearFields()"
  >
    <div class="col-12">
      <div class="custom-modal-header">
        <div class="custom-modal-title">
          Заказ транспорт
        </div>
        <img
          alt="Закрыть"
          class="custom-modal-close"
          src="@/assets/img/icons/close.svg"
          @click="$bvModal.hide('transport-order-modal')"
        >
      </div>
      <div class="modal-divider" />
    </div>
    <form @submit.prevent="submitForm()">
      <div v-if="transportOrder"
           class="col-12 mb-2">
        <div class="transport_order__info">
          Необходимый тип транспорта:
          <span>{{transportOrder.transportType.name}}</span>
        </div>

        <div class="transport_order__info">
          Адрес:
          <span>{{transportOrder.getFullAddress()}}</span>
        </div>

        <div class="transport_order__info mb-3">
          Комментарий:
          <span>{{transportOrder.comment}}</span>
        </div>

        <div class="form-group">
          <label class="form-label">Транспорт<span class="red">*</span></label>
          <v-select
            v-model="payload.transport_id"
            :options="vehicles.filter(vehicle => vehicle.attributes.type === transportOrder.transportType.id)"
            :reduce="vehicle => vehicle.id"
            :get-option-label="(option) => option.attributes.label + ' ' + option.attributes.gov_number"
            placeholder="Выберите транспорт"
          />
        </div>

        <div class="form-group">
          <label class="form-label">Дата и время подачи<span class="red">*</span></label>
          <date-picker
            v-model="payload.delivery_date"
            :default-value="defaultValue"
            input-class="form-control-fix w-100 pl-2"
            placeholder="Выберите дату и время"
            type="datetime"
            value-type="YYYY-MM-DD HH:mm"
            :format="DATE_TIME_FORMAT"
          />
        </div>

        <div class="form-group">
          <label class="form-label">Водитель<span class="red">*</span></label>
          <v-select
            v-model="payload.brigade_id"
            :options="departmentBrigades"
            :reduce="brigade => brigade.id"
            :get-option-label="(option) => option.name"
            placeholder="Выберите водителя"
          />
        </div>

        <div class="modal-divider" />
        <div class="row">
          <div class="col-12">
            <button
              type="submit"
              class="btn custom-btn-blue w-100"
            >
              Подтвердить
            </button>
          </div>
        </div>
      </div>
    </form>
  </b-modal>
</template>

<script>
import {mapState} from "vuex";
import DatePicker from "vue2-datepicker";
import {showToast, showAlert} from "@/utils/notification";

export default {
  name: "TransportOrdersForm",
  components: {DatePicker},
  props: {
    transportOrder: {
      required: true
    }
  },
  data() {
    return {
      payload: {
        transport_id: null,
        brigade_id: null,
        delivery_date: null
      },
      defaultValue: new Date()
    }
  },
  computed: {
    ...mapState({
      vehicles: state => state.autos.autos,
      departmentBrigades: state => state.departmentBrigades.brigades
    }),
    DATE_TIME_FORMAT: () => process.env.VUE_APP_BASE_DATE_TIME_FORMAT
  },
  mounted() {
    this.$store.dispatch('autos/getAllAutos', {state: 1});
  },
  methods: {
    fillField() {
      this.$store.dispatch('departmentBrigades/getAllDepartmentBrigades', this.transportOrder.task.department_id);
      if (this.transportOrder.transport) this.payload.transport_id = this.transportOrder.transport.id;
      this.payload.delivery_date = this.transportOrder.deliveryAt;
    },
    clearFields() {
      this.payload = {
        transport_id: null,
        brigade_id: null,
        delivery_date: null
      }
    },
    submitForm() {
      this.$store.dispatch('transportOrders/acceptTransportOrder', {
        transportOrderId: this.transportOrder.id,
        payload: this.payload
      })
          .then(() => {
            this.$store.dispatch('transportOrders/getTransportOrders');
            showToast('Заказ транспорта подтверждён', 'success');
            this.$bvModal.hide('transport-order-modal');
          })
          .catch(error => showAlert('Ошибка', error))
    }
  }
}
</script>

<style scoped>
.transport_order__info {
  color: #262F3D;
}

.transport_order__info span{
  color: #468FC5;
}
</style>