<template>
  <div class="row">
    <div class="col-12 col-lg-5 mobile-mb-20">
      <div class="tab mb-0">
        <b-tabs
          v-model="tabIndex"
          content-class="tabs-custom"
          active-nav-item-class="active-b-tab"
        >
          <div class="tab-content-custom scroll">
            <billing-clients-filter @data-complete="filter = $event" />
            <billing-clients-filter-result :filter_params="filter" />
            <billing-client-detail title="Выберите абонента в результате фильтрации"/>
          </div>
        </b-tabs>
      </div>
    </div>
    <div class="col-12 col-lg-7">
      <billing-clients-map/>
    </div>
  </div>
</template>

<script>
import BillingClientsFilter from "@/components/Billing/BillingClientsFilter";
import BillingClientsFilterResult from "@/components/Billing/BillingClientsFilterResult";
import BillingClientsMap from "@/components/Billing/BillingClientsMap";
import BillingClientDetail from "@/components/Billing/BillingClientDetail";

export default {
  name: "BillingClientsPage",
  components: {BillingClientDetail, BillingClientsMap, BillingClientsFilterResult, BillingClientsFilter},
  data() {
    return {
      client_detail: null,
      detail_disabled: true,
      filter: null
    }
  },
  computed: {
    tabIndex: {
      get() {
        return this.$store.getters['client/getTabIndex']
      },
      set(index) {
        this.$store.commit('client/SET_TAB_INDEX', index)
      }
    }
  }
}
</script>

<style scoped>
.scroll {
  overflow-y: scroll;
  height: calc(100vh - 120px);
}

.tab-content-custom {
  padding: 1.25rem;
  background-color: #F9FDFF;
}

.tab-content-custom::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  background-color: #C7D3D9;
  border-radius: 0.2em;
}

.tab-content-custom::-webkit-scrollbar-thumb {
  background-color: #97ACB5;
  border-radius: 0.2em;
}

@media (max-width: 500px) {
  .mobile-mb-20 {
    margin-bottom: 20px;
  }
}
</style>
