import VueRouter from 'vue-router';
import guest from "@/middleware/guest";
import auth from "@/middleware/auth";
import admin from "@/middleware/admin"
import store from "@/store/index"
import middlewarePipeline from "@/router/middlewarePipeline";
import Vue from "vue";
import DashboardPage from "@/views/DashboardPage";
import LoginPage from "@/views/LoginPage";
import ForcesAndResourcesView from "@/views/Transport/TransportPage";
import AppealsList from "@/views/CallCenter/AppealsListPage";
import BalancePage from "@/views/Billing/BalancePage";
import BillingClients from "@/views/Billing/BillingClientsPage";
import BillingBuildings from "@/views/Billing/BillingBuildingsPage";
import TelemetryPage from "@/views/Telemetry/TelemetryPage";
import ArchiveJournal from "@/views/Telemetry/ArchiveJournalPage";
import LogbookPage from "@/views/Telemetry/LogbookPage";
import JournalConfigs from "@/views/Telemetry/admin/JournalConfigsPage";
import JournalConfigsForm from "@/views/Telemetry/admin/JournalConfigsFormPage";
import AutosView from "@/views/Transport/admin/AutosPage";
import AutoFormView from "@/views/Transport/admin/AutoFormPage";
import ZonesForm from "@/views/Billing/admin/ZonesFormPage";
import ZonesLayout from "@/views/Billing/admin/ZonesPage";
import BalanceGroup from "@/views/Billing/admin/BalanceGroupPage";
import BalanceGroupForm from "@/views/Billing/admin/BalanceGroupFormPage";
import UsersLayout from "@/views/admin/UsersPage";
import UsersForm from "@/views/admin/UsersFormPage";
import RolesLayout from "@/views/admin/RolesPage";
import RolesForm from "@/views/admin/RolesFormPage";
import NetworkObjectsListPage from "@/views/Telemetry/admin/NetworkObjectsListPage";
import NetworkObjectsFormPage from "@/views/Telemetry/admin/NetworkObjectsFormPage";
import LayersLayout from "@/views/admin/LayersPage";
import LayersForm from "@/views/admin/LayersFormPage";
import WorkShift from "@/views/WorkShiftPage";
import StandardBuildingPage from "@/views/Billing/StandardBuildingPage";
import IssuesListPage from "@/views/ServiceDesk/IssuesListPage";
import CreateIssuePage from "@/views/ServiceDesk/CreateIssuePage";
import IssuePage from "@/views/ServiceDesk/IssuePage";
import MyTasksListPage from "@/views/ServiceDesk/MyTasksListPage";
import TaskPage from "@/views/ServiceDesk/TaskPage";
import TaskTypesListPage from "@/views/ServiceDesk/admin/TaskTypesListPage";
import TaskTypesFormPage from "@/views/ServiceDesk/admin/TaskTypesFormPage";
import AccessDeniedPage from "@/views/AccessDeniedPage";
import objectMonitoring from "@/middleware/objectMonitoring";
import transportTracking from "@/middleware/transportTracking";
import billing from "@/middleware/billing";
import TasksListPage from "@/views/ServiceDesk/TasksListPage";
import WorksListPage from "@/views/ServiceDesk/admin/WorksListPage";
import WorksFormPage from "@/views/ServiceDesk/admin/WorksFormPage";
import ReportsPage from "@/views/ServiceDesk/ReportsPage";
import DepartmentsListPage from "@/views/ServiceDesk/admin/DepartmentsListPage";
import DepartmentsFormPage from "@/views/ServiceDesk/admin/DepartmentsFormPage";
import DepartmentIssuesListPage from "@/views/ServiceDesk/DepartmentIssuesListPage";
import IssuesArchivePage from "@/views/ServiceDesk/IssuesArchivePage";
import BrigadesWorkShiftPage from "@/views/ServiceDesk/BrigadesWorkShiftPage";
import EngineeringNetworksListPage from "@/views/EngineeringNetwork/EngineeringNetworksListPage";
import EngineeringNetworkFormPage from "@/views/EngineeringNetwork/EngineeringNetworkFormPage";
import TransportDataServerFormPage from "@/views/Transport/admin/TransportDataServerFormPage";
import TransportDataServersListPage from "@/views/Transport/admin/TransportDataServersListPage";
import AppealsDashboardPage from "@/views/CallCenter/AppealsDashboardPage";
import IssuesDashboardPage from "@/views/ServiceDesk/IssuesDashboardPage";
import MechanicalEquipmentsListPage from "@/views/ServiceDesk/admin/MechanicalEquipmentsListPage";
import MechanicalEquipmentFormPage from "@/views/ServiceDesk/admin/MechanicalEquipmentFormPage";
import CategoriesListPage from "@/views/ServiceDesk/admin/CategoriesListPage";
import CategoryFormPage from "@/views/ServiceDesk/admin/CategoryFormPage";
import NetworkObjectTypesListPage from "@/views/Telemetry/admin/NetworkObjectTypesListPage";
import NetworkObjectTypesFormPage from "@/views/Telemetry/admin/NetworkObjectTypesFormPage";
import DeviceAccessesListPage from "@/views/Telemetry/admin/DeviceAccessesListPage";
import DeviceAccessFormPage from "@/views/Telemetry/admin/DeviceAccessFormPage";
import NetworkEquipmentTypesListPage from "@/views/Telemetry/admin/NetworkEquipmentTypesListPage";
import NetworkEquipmentTypesFormPage from "@/views/Telemetry/admin/NetworkEquipmentTypesFormPage";
import ResourceDashboardPage from "@/views/ServiceDesk/ResourceDashboardPage";
import ConsumablesListPage from "@/views/ServiceDesk/admin/ConsumablesListPage";
import ConsumablesFormPage from "@/views/ServiceDesk/admin/ConsumablesFormPage";
import checkPermission from "@/middleware/checkPermission";
import NetworkObjectPage from "@/views/Telemetry/admin/NetworkObjectPage";
import TransportOrdersListPage from "@/views/ServiceDesk/TransportOrdersListPage";
import EnergyConsumptionObjectsListPage from "@/views/EnergyConsumption/admin/EnergyConsumptionObjectsListPage";
import EnergyConsumptionObjectPage from "@/views/EnergyConsumption/admin/EnergyConsumptionObjectPage";
import EnergyConsumptionLogbookPage from "@/views/EnergyConsumption/EnergyConsumptionLogbookPage";

const router = new VueRouter({
    mode: 'history',
    routes: [
        {
            path: '/',
            name: 'DashboardPage',
            component: DashboardPage,
            meta: { middleware: [auth], title: 'Главная' }
        },
        {
            path: '/login',
            name: 'LoginPage',
            component: LoginPage,
            meta: { middleware: [guest], title: 'Авторизация', layout: 'Login' }
        },
        {
            path: '/access-denied',
            name: 'AccessDenied',
            component: AccessDeniedPage,
            meta: { middleware: [auth], title: 'Доступ запрещен' }
        },
        {
            path: '/telemetry',
            name: 'Telemetry',
            component: TelemetryPage,
            meta: { middleware: [auth, objectMonitoring], title: 'Телеметрия' }
        },
        {
            path: '/telemetry-archive',
            name: 'ArchiveJournal',
            component: ArchiveJournal,
            meta: { middleware: [auth, objectMonitoring], title: 'Архивный журнал' }
        },
        {
            path: '/network-equipment-types',
            name: 'NetworkEquipmentTypes',
            component: NetworkEquipmentTypesListPage,
            meta: { middleware: [auth, admin], title: 'Типы сетевого оборудования' }
        },
        {
            path: '/network-equipment-types-create',
            name: 'CreateNetworkEquipmentType',
            component: NetworkEquipmentTypesFormPage,
            meta: { middleware: [auth, admin], title: 'Добавить тип сетевого оборудования' }
        },
        {
            path: '/logbook',
            name: 'Logbook',
            component: LogbookPage,
            meta: { middleware: [auth, objectMonitoring], title: 'Журнал ручного ввода' }
        },
        {
            path: '/admin-journal-configs',
            name: 'JournalConfigs',
            component: JournalConfigs,
            meta: { middleware: [auth, admin], title: 'Конфигурация журнала' }
        },
        {
            path: '/admin-journal-configs-create',
            name: 'JournalConfigsCreate',
            component: JournalConfigsForm,
            meta: { middleware: [auth, admin], title: 'Создать конфигурацию' }
        },
        {
            path: '/admin-journal-configs-update/:id',
            name: 'JournalConfigsUpdate',
            component: JournalConfigsForm,
            meta: { middleware: [auth, admin], title: 'Изменить конфигурацию' }
        },
        {
            path: '/tracking',
            name: 'Tracking',
            component: ForcesAndResourcesView,
            meta: { middleware: [auth, transportTracking], title: 'Трекинг транспорта' }
        },
        {
            path: '/admin-autos',
            name: 'Autos',
            component: AutosView,
            meta: {
                middleware: [auth, checkPermission],
                permission: 'transport.management',
                title: 'Автопарк'
            }
        },
        {
            path: '/admin-autos-create',
            name: 'AutosCreate',
            component: AutoFormView,
            meta: {
                middleware: [auth, checkPermission],
                permission: 'transport.management',
                title: 'Создать транспорт'
            }
        },
        {
            path: '/admin-autos-update/:id',
            name: 'AutosUpdate',
            component: AutoFormView,
            meta: {
                middleware: [auth, checkPermission],
                permission: 'transport.management',
                title: 'Изменить транспорт'
            }
        },
        {
            path: '/transport-data-servers',
            name: 'TransportDataServers',
            component: TransportDataServersListPage,
            meta: { middleware: [auth, admin], title: 'Сервера данных транспорта' }
        },
        {
            path: '/create-transport-data-server',
            name: 'CreateTransportDataServer',
            component: TransportDataServerFormPage,
            meta: { middleware: [auth, admin], title: 'Добавить сервер данных транспорта' }
        },
        {
            path: '/update-transport-data-server/:id',
            name: 'UpdateTransportDataServer',
            component: TransportDataServerFormPage,
            meta: { middleware: [auth, admin], title: 'Обновить сервер данных транспорта' }
        },
        {
            path: '/issues-dashboard',
            name: 'IssuesDashboard',
            component: IssuesDashboardPage,
            meta: {
                middleware: [auth, checkPermission],
                permission: 'service-desk.issues',
                title: 'Статистика заявок'
            }
        },
        {
            path: '/issues-list',
            name: 'IssuesList',
            component: IssuesListPage,
            meta: {
                middleware: [auth, checkPermission],
                permission: 'service-desk.issues',
                title: 'Диспетчерская'
            }
        },
        {
            path: '/create-issue',
            name: 'CreateIssue',
            component: CreateIssuePage,
            meta: {
                middleware: [auth, checkPermission],
                permission: 'service-desk.issues',
                title: 'Создать заявку'
            }
        },
        {
            path: '/create-related-issue/:id',
            name: 'CreateRelatedIssue',
            component: CreateIssuePage,
            meta: {
                middleware: [auth, checkPermission],
                permission: 'service-desk.issues',
                title: 'Создать заявку'
            }
        },
        {
            path: '/issue/:id',
            name: 'IssuePage',
            component: IssuePage,
            meta: {
                middleware: [auth, checkPermission],
                permission: 'service-desk.issues',
                title: 'Заявка'
            }
        },
        {
            path: '/issues-archive',
            name: 'IssuesArchive',
            component: IssuesArchivePage,
            meta: {
                middleware: [auth, checkPermission],
                permission: 'service-desk.issues',
                title: 'Архив заявок'
            }
        },
        {
            path: '/department-issues',
            name: 'DepartmentIssues',
            component: DepartmentIssuesListPage,
            meta: {
                middleware: [auth, checkPermission],
                permission: 'service-desk.issues',
                title: 'Список заявок подразделения'
            }
        },
        {
            path: '/my-tasks',
            name: 'MyTasks',
            component: MyTasksListPage,
            meta: {middleware: [auth], title: 'Назначенные мне задания'}
        },
        {
            path: '/tasks',
            name: 'TasksListPage',
            component: TasksListPage,
            meta: {
                middleware: [auth, checkPermission],
                permission: 'service-desk.issues',
                title: 'Задания'
            }
        },
        {
            path: '/task/:id',
            name: 'TaskPage',
            component: TaskPage,
            meta: {
                middleware: [auth],
                title: 'Задание'
            }
        },
        {
            path: '/appeals-dashboard',
            name: 'AppealsDashboard',
            component: AppealsDashboardPage,
            meta: {
                middleware: [auth, checkPermission],
                permission: 'service-desk.appeals',
                title: 'Статистика обращений'
            }
        },
        {
            path: '/appeals-list',
            name: 'AppealsList',
            component: AppealsList,
            meta: {
                middleware: [auth, checkPermission],
                permission: 'service-desk.appeals',
                title: 'Обращения'
            }
        },
        {
            path: '/service-desk-reports',
            name: 'ServiceDeskReports',
            component: ReportsPage,
            meta: {
                middleware: [auth, checkPermission],
                permission: 'service-desk.reports',
                title: 'Диспетчерская - отчёты'
            }
        },
        {
            path: '/transport-orders',
            name: 'TransportOrders',
            component: TransportOrdersListPage,
            meta: {
                middleware: [auth, checkPermission],
                permission: 'service-desk.transport-orders',
                title: 'Заказы транспорта'
            }
        },
        {
            path: '/brigades-work-shift',
            name: 'BrigadesWorkShift',
            component: BrigadesWorkShiftPage,
            meta: { middleware: [auth], title: 'Расписание работы бригад' }
        },
        {
            path: '/resource-statistic/:id',
            name: 'ResourceStatistic',
            component: ResourceDashboardPage,
            meta: { middleware: [auth], title: 'Статистика бригады' }
        },
        {
            path: '/departments',
            name: 'Departments',
            component: DepartmentsListPage,
            meta: {
                middleware: [auth, checkPermission],
                permission: 'service-desk.settings',
                title: 'Справочник подразделений'
            }
        },
        {
            path: '/create-department',
            name: 'CreateDepartment',
            component: DepartmentsFormPage,
            meta: {
                middleware: [auth, checkPermission],
                permission: 'service-desk.settings',
                title: 'Создать подразделение'
            }
        },
        {
            path: '/update-department/:id',
            name: 'UpdateDepartment',
            component: DepartmentsFormPage,
            meta: {
                middleware: [auth, checkPermission],
                permission: 'service-desk.settings',
                title: 'Обновить подразделение'
            }
        },
        {
            path: '/task-types',
            name: 'TaskTypes',
            component: TaskTypesListPage,
            meta: {
                middleware: [auth, checkPermission],
                permission: 'service-desk.settings',
                title: 'Справочник типов заданий'
            }
        },
        {
            path: '/task-types-create',
            name: 'CreateTaskType',
            component: TaskTypesFormPage,
            meta: {
                middleware: [auth, checkPermission],
                permission: 'service-desk.settings',
                title: 'Добавить тип задания'
            }
        },
        {
            path: '/task-types-update/:id',
            name: 'UpdateTaskType',
            component: TaskTypesFormPage,
            meta: {
                middleware: [auth, checkPermission],
                permission: 'service-desk.settings',
                title: 'Обновить тип задания'
            }
        },
        {
            path: '/works',
            name: 'Works',
            component: WorksListPage,
            meta: {
                middleware: [auth, checkPermission],
                permission: 'service-desk.settings',
                title: 'Каталог работ'
            }
        },
        {
            path: '/create-work',
            name: 'CreateWork',
            component: WorksFormPage,
            meta: {
                middleware: [auth, checkPermission],
                permission: 'service-desk.settings',
                title: 'Создать работу'
            }
        },
        {
            path: '/update-work/:id',
            name: 'UpdateWork',
            component: WorksFormPage,
            meta: {
                middleware: [auth, checkPermission],
                permission: 'service-desk.settings',
                title: 'Изменить работу'
            }
        },
        {
            path: '/mechanical-equipments',
            name: 'MechanicalEquipments',
            component: MechanicalEquipmentsListPage,
            meta: {
                middleware: [auth, checkPermission],
                permission: 'service-desk.settings',
                title: 'Справочник средств малой механизации'
            }
        },
        {
            path: '/create-mechanical-equipment',
            name: 'CreateMechanicalEquipment',
            component: MechanicalEquipmentFormPage,
            meta: {
                middleware: [auth, checkPermission],
                permission: 'service-desk.settings',
                title: 'Создать средство малой механизации'
            }
        },
        {
            path: '/update-mechanical-equipment/:id',
            name: 'UpdateMechanicalEquipment',
            component: MechanicalEquipmentFormPage,
            meta: {
                middleware: [auth, checkPermission],
                permission: 'service-desk.settings',
                title: 'Обновить средство малой механизации'
            }
        },
        {
            path: '/service-desk-categories',
            name: 'ServiceDeskCategories',
            component: CategoriesListPage,
            meta: {
                middleware: [auth, checkPermission],
                permission: 'service-desk.settings',
                title: 'Категории заявок и обращений'
            }
        },
        {
            path: '/create-category',
            name: 'CreateCategory',
            component: CategoryFormPage,
            meta: {
                middleware: [auth, checkPermission],
                permission: 'service-desk.settings',
                title: 'Создать категорию'
            }
        },
        {
            path: '/update-category/:id',
            name: 'UpdateCategory',
            component: CategoryFormPage,
            meta: {
                middleware: [auth, checkPermission],
                permission: 'service-desk.settings',
                title: 'Обновить категорию'
            }
        },
        {
            path: '/consumables-catalog',
            name: 'ConsumablesCatalog',
            component: ConsumablesListPage,
            meta: {
                middleware: [auth, checkPermission],
                permission: 'service-desk.settings',
                title: 'Каталог расходных материалов'
            }
        },
        {
            path: '/create-consumable',
            name: 'CreateConsumable',
            component: ConsumablesFormPage,
            meta: {
                middleware: [auth, checkPermission],
                permission: 'service-desk.settings',
                title: 'Добавить расходный материал'
            }
        },
        {
            path: '/update-consumable/:id',
            name: 'UpdateConsumable',
            component: ConsumablesFormPage,
            meta: {
                middleware: [auth, checkPermission],
                permission: 'service-desk.settings',
                title: 'Обновить расходный материал'
            }
        },
        {
            path: '/billing-buildings',
            name: 'BillingBuildings',
            component: BillingBuildings,
            meta: {middleware: [auth, billing], title: 'Фильтр по объектам'}
        },
        {
            path: '/billing-clients',
            name: 'BillingClients',
            component: BillingClients,
            meta: {middleware: [auth, billing], title: 'Фильтр по абонентам'}
        },
        {
            path: '/standard-building',
            name: 'StandardBuilding',
            component: StandardBuildingPage,
            meta: {middleware: [auth, billing], title: 'Эталонный объект'}
        },
        {
            path: '/water-balance',
            name: 'WaterBalance',
            component: BalancePage,
            meta: { middleware: [auth, billing], title: 'Баланс водоснабжения' }
        },
        {
            path: '/drain-balance',
            name: 'DrainBalance',
            component: BalancePage,
            meta: { middleware: [auth, billing], title: 'Баланс водоотведения' }
        },
        {
            path: '/admin-zones-create',
            name: 'ZonesCreate',
            component: ZonesForm,
            meta: { middleware: [auth, admin], title: 'Создать зону' }
        },
        {
            path: '/admin-zones-update/:id',
            name: 'ZonesUpdate',
            component: ZonesForm,
            meta: { middleware: [auth, admin], title: 'Изменить зону' }
        },
        {
            path: '/admin-zones',
            name: 'Zones',
            component: ZonesLayout,
            meta: { middleware: [auth, admin], title: 'Зоны водоснабжения' }
        },
        {
            path: '/admin-balance-groups',
            name: 'BalanceGroup',
            component: BalanceGroup,
            meta: { middleware: [auth, admin], title: 'Балансовые группы' }
        },
        {
            path: '/admin-balance-group-create',
            name: 'BalanceGroupCreate',
            component: BalanceGroupForm,
            meta: { middleware: [auth, admin], title: 'Создать балансовую группы' }
        },
        {
            path: '/admin-balance-group-update/:id',
            name: 'BalanceGroupUpdate',
            component: BalanceGroupForm,
            meta: { middleware: [auth, admin], title: 'Изменить балансовую группы' }
        },
        {
            path: '/admin-users',
            name: 'Users',
            component: UsersLayout,
            meta: { middleware: [auth, admin], title: 'Пользователи' }
        },
        {
            path: '/admin-users-create',
            name: 'UsersCreate',
            component: UsersForm,
            meta: { middleware: [auth, admin], title: 'Создать пользователя' }
        },
        {
            path: '/admin-users-update/:id',
            name: 'UsersUpdate',
            component: UsersForm,
            meta: { middleware: [auth, admin], title: 'Изменить пользователя' }
        },
        {
            path: '/admin-roles',
            name: 'Roles',
            component: RolesLayout,
            meta: { middleware: [auth, admin], title: 'Роли' }
        },
        {
            path: '/admin-roles-create',
            name: 'RolesCreate',
            component: RolesForm,
            meta: { middleware: [auth, admin], title: 'Создать роль' }
        },
        {
            path: '/admin-roles-update/:id',
            name: 'RolesUpdate',
            component: RolesForm,
            meta: { middleware: [auth, admin], title: 'Изменить роль' }
        },
        {
            path: '/network-objects-catalog',
            name: 'NetworkObjects',
            component: NetworkObjectsListPage,
            meta: { middleware: [auth, admin], title: 'Объекты сети' }
        },
        {
            path: '/network-objects-create',
            name: 'NetworkObjectCreate',
            component: NetworkObjectsFormPage,
            meta: { middleware: [auth, admin], title: 'Создать объект сети' }
        },
        {
            path: '/network-objects-update/:id',
            name: 'NetworkObjectUpdate',
            component: NetworkObjectsFormPage,
            meta: { middleware: [auth, admin], title: 'Изменить объект сети' }
        },
        {
            path: '/network-object-page/:id',
            name: 'NetworkObjectPage',
            component: NetworkObjectPage,
            meta: { middleware: [auth, admin], title: 'Объект сети' }
        },
        {
            path: '/admin-layers',
            name: 'Layers',
            component: LayersLayout,
            meta: { middleware: [auth, admin], title: 'Слои' }
        },
        {
            path: '/admin-layers-create',
            name: 'LayersCreate',
            component: LayersForm,
            meta: { middleware: [auth, admin], title: 'Создать слой' }
        },
        {
            path: '/admin-layers-update/:id',
            name: 'LayersUpdate',
            component: LayersForm,
            meta: { middleware: [auth, admin], title: 'Изменить слой' }
        },
        {
            path: '/work-shift',
            name: 'WorkShift',
            component: WorkShift,
            meta: { middleware: [auth, admin], title: 'График работы' }
        },
        {
            path: '/engineering-networks',
            name: 'EngineeringNetworks',
            component: EngineeringNetworksListPage,
            meta: { middleware: [auth, admin], title: 'Справочник инженерных сетей' }
        },
        {
            path: '/engineering-network-create',
            name: 'EngineeringNetworkCreate',
            component: EngineeringNetworkFormPage,
            meta: { middleware: [auth, admin], title: 'Создать инженерную сеть' }
        },
        {
            path: '/engineering-network-update/:id',
            name: 'EngineeringNetworkUpdate',
            component: EngineeringNetworkFormPage,
            meta: { middleware: [auth, admin], title: 'Изменить инженерную сеть' }
        },
        {
            path: '/network-object-types',
            name: 'NetworkObjectTypes',
            component: NetworkObjectTypesListPage,
            meta: { middleware: [auth, admin], title: 'Типы объектов сети' }
        },
        {
            path: '/network-object-types-create',
            name: 'NetworkObjectTypeCreate',
            component: NetworkObjectTypesFormPage,
            meta: { middleware: [auth, admin], title: 'Создать тип объектов сети' }
        },
        {
            path: '/network-object-types-update/:id',
            name: 'NetworkObjectTypeUpdate',
            component: NetworkObjectTypesFormPage,
            meta: { middleware: [auth, admin], title: 'Изменить тип объекта сети' }
        },
        {
            path: '/device-accesses-list',
            name: 'DeviceAccessesList',
            component: DeviceAccessesListPage,
            meta: { middleware: [auth, admin], title: 'Список доступов к устройствам' }
        },
        {
            path: '/allow-device-access',
            name: 'AllowDeviceAccess',
            component: DeviceAccessFormPage,
            meta: { middleware: [auth, admin], title: 'Предоставить доступ к устройству' }
        },
        {
            path: '/energy-consumption-object-catalog',
            name: 'EnergyConsumptionObjectCatalog',
            component: EnergyConsumptionObjectsListPage,
            meta: {
                middleware: [auth, checkPermission],
                title: 'Каталог объектов энергопотребления',
                permission: 'energy-consumption'
            }
        },
        {
            path: '/energy-consumption-object/:id',
            name: 'EnergyConsumptionObject',
            component: EnergyConsumptionObjectPage,
            meta: {
                middleware: [auth, checkPermission],
                title: 'Объект энергопотребления',
                permission: 'energy-consumption'
            }
        },
        {
            path: '/energy-consumption-logbook',
            name: 'EnergyConsumptionLogbookPage',
            component: EnergyConsumptionLogbookPage,
            meta: {
                middleware: [auth, checkPermission],
                title: 'Журнал энергопотребления',
                permission: 'energy-consumption'
            }
        }
    ]
});

router.beforeEach((to, from, next) => {
    const {middleware, permission} = to.meta;
    const context = { to, from, next, store, permission };

    if (!middleware) {
        return next();
    }
    middleware[0]({
        ...context,
        next: middlewarePipeline(context, middleware, 1)
    });
});

router.afterEach(to => {
    Vue.nextTick(() => {
        document.title = to.meta.title;
    });
});

export default router;