<template>
  <div>
    <table class="admin-table">
      <thead class="admin-table-header">
        <tr>
          <td>#</td>
          <td>Наименование</td>
          <td>Тип топлива</td>
          <td>Расход топлива</td>
          <td>Тип масла</td>
          <td>Расход масла</td>
          <td>Управление</td>
        </tr>
      </thead>
      <tbody class="admin-table-body">
        <tr v-for="equipment in mechanicalEquipments"
            :key="equipment.id"
            class="equipment-item"
        >
          <td>{{ equipment.id }}</td>
          <td>{{ equipment.name }}</td>
          <td>{{ equipment.fuelType.name }}</td>
          <td>{{ equipment.fuelConsumption }}</td>
          <td>{{ equipment.oilType.name }}</td>
          <td>{{ equipment.oilConsumption }}</td>
          <td class="table-action w-25 ">
            <router-link :to="`/update-mechanical-equipment/`+equipment.id">
              <edit-icon class="mr-1" />
            </router-link>
            <trash2-icon @click="deleteMechanicalEquipment(equipment.id)"
                         class="cursor-pointer" />
          </td>
        </tr>
      </tbody>
    </table>

    <base-paginate
      v-if="$store.state.mechanicalEquipments.meta && $store.state.mechanicalEquipments.meta.last_page > 1"
      :meta="$store.state.mechanicalEquipments.meta"
      action="mechanicalEquipments/getMechanicalEquipments"
      commit="mechanicalEquipments/SET_PAGE"
    />
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import BasePaginate from "@/components/BasePaginate";
import notification from "@/mixins/notification";

export default {
  name: "MechanicalEquipmentsList",
  components: {BasePaginate},
  mixins: [notification],
  computed: {
    ...mapGetters({
      mechanicalEquipments: 'mechanicalEquipments/getMechanicalEquipments'
    })
  },
  methods: {
    deleteMechanicalEquipment(id) {
      this.$store.dispatch('mechanicalEquipments/deleteMechanicalEquipment', id)
          .then(() => {
            this.$store.dispatch('mechanicalEquipments/getMechanicalEquipments');
            this.toast('Средство малой механизации удалено', 'success');
          })
          .catch(error => this.alert('Ошибка', error, 'error'));
    }
  }
}
</script>

<style scoped>

</style>