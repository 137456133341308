<template>
  <base-map :center="center"
            :zoom="zoom"
            :layers="layers"
            :markers="createMarkers()"
  />
</template>

<script>
import BaseMap from "@/components/BaseMap";
import Marker from "@/models/baseMap/Marker";
import Icon from "@/models/baseMap/Icon";
import {mapState} from "vuex";

export default {
  name: "IssuesListMap",
  components: {BaseMap},
  computed: {
    ...mapState({
      center: state => state.baseMap.center,
      zoom: state => state.baseMap.zoom,
      layers: state => state.baseMap.layers
    })
  },
  mounted() {
    this.$store.dispatch('baseMap/getLayers');
  },
  beforeDestroy() {
    this.$store.commit("baseMap/SET_LAYERS", []);
  },
  methods: {
    createMarkers() {
      return this.$store.state.issues.issues.map(issue => {
        return new Marker({
          coords: issue.coordinates,
          icon: new Icon({
            type: 'icon',
            url: require('@/assets/img/icons/pin-low.svg'),
            size: [36, 36],
            anchor: [18, 36]
          })
        })
      })
    }
  }
}
</script>

<style scoped>

</style>