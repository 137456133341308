<template>
  <form @submit.prevent="submitForm()">
    <div class="row">
      <div class="col-xl-6 col-xs-12">
        <div class="form-group">
          <label class="form-label">Группировка</label>
          <v-select
            v-model="reportParams.group"
            :options="groups"
            :reduce="report => report.key"
            :get-option-label="(option) => option.name"
            @input="$store.commit('reportsData/SET_REPORT_DATA', [])"
            placeholder="Выберите группировку" />
        </div>
      </div>
      <div class="col-xl-6 col-xs-12">
        <div class="form-group">
          <label class="form-label">Дата</label>
          <date-picker
            input-class="form-control-fix w-100 pl-2"
            placeholder="Выберите дату"
            v-model="reportParams.date"
            :default-value="defaultValue"
            :format="reportParams.group === 1 ? 'YYYY':'MMMM'"
            :type="reportParams.group === 1 ? 'year':'month'"
            value-type="YYYY-MM-DD" />
        </div>
      </div>
    </div>
    <button class="btn custom-btn-blue w-100">Сформировать</button>
  </form>
</template>

<script>
import DatePicker from "vue2-datepicker";

export default {
  name: "AccidentReportForm",
  components: {DatePicker},
  data() {
    return {
      reportParams: {
        date: this.$moment().format('YYYY-MM-DD'),
        group: 1
      },
      groups: [
        {key: 1, name: 'Год'},
        {key: 2, name: 'Месяц'}
      ],
      defaultValue: new Date()
    }
  },
  methods: {
    submitForm() {
      this.$store.commit('reportsData/SET_REPORT_PARAMS', {
        date: this.reportParams.data,
        group: this.reportParams.group
      });
      this.$store.dispatch('reportsData/getAccidentReportData', this.reportParams);
    }
  }
}
</script>

<style scoped>

</style>