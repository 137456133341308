<template>
  <div v-if="Object.keys(reportData).length"
       class="custom-card">
    <div class="card-body">
      <div class="col-12">
        <table class="admin-table table-responsive">
          <thead class="admin-table-header">
            <tr>
              <td>Наименование</td>
              <td>Значение, м<sup>3</sup></td>
            </tr>
          </thead>
          <tbody class="admin-table-body">
            <tr class="row-title">
              <td class="text-left">{{ reportData.loss_volume.name }}</td>
              <td>{{ reportData.loss_volume.summary_volume.toLocaleString('ru') }}</td>
            </tr>
            <tr v-for="(item, index) in reportData.loss_volume.details"
                :key="index"
                class="sub-row-title">
              <td class="text-left">- {{ item.name }}</td>
              <td>{{ item.volume.toLocaleString('ru') }}</td>
            </tr>
            <tr class="row-title">
              <td class="text-left">{{ reportData.own_costs_volume.name }}</td>
              <td>{{ reportData.own_costs_volume.summary_volume.toLocaleString('ru') }}</td>
            </tr>
            <tr v-for="(item, index) in reportData.own_costs_volume.details"
                :key="index"
                class="sub-row-title">
              <td class="text-left">- {{ item.name }}</td>
              <td>{{ item.volume.toLocaleString('ru') }}</td>
            </tr>
            <tr class="row-title">
              <td class="text-left">{{ reportData.realization_volume.name }}</td>
              <td>{{ reportData.realization_volume.summary_volume.toLocaleString('ru') }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import {mapState} from "vuex";

export default {
  name: "WaterFlowReport",
  computed: {
    ...mapState({
      reportData: state => state.reportsData.reportData
    })
  }
}
</script>

<style scoped>
.row-title td {
  color: #262F3D;
  font-size: 20px;
  font-weight: 600;
}

.sub-row-title td {
  font-size: 14px;
  font-weight: 400;
}
</style>
