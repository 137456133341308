export default class NetworkElement {
    constructor(id, params) {
        this.id = id;
        this.externalId = params.external_id;
        this.networkId = params.network.id;
        this.networkName = params.network.name;
        this.typeId = params.type.id;
        this.typeName = params.type.name;
        this.geometryType = params.geometry.type;
        this.coordinates = params.geometry.coordinates;
        this.isSelected = false;
    }
}