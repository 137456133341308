import NetworkEquipmentsService from "@/services/NetworkEquipmentsService";
import NetworkEquipmentParametersService from "@/services/NetworkEquipmentParametersService";
import NetworkObjectsService from "@/services/NetworkObjectsService";

function setLocalStorageItem(ls_key, id) {
    let ls = JSON.parse(localStorage.getItem(ls_key))
    if (ls.includes(id)) {
        let index = ls.indexOf(id)
        ls.splice(index, 1)
    } else {
        ls.push(id)
    }
    localStorage.setItem(ls_key, JSON.stringify(ls))
}

export const namespaced = true;

export const state = {
    networkObjects: [],
    networkEquipments: [],
    parametersValues: [],
    loading: false,
    error: null
}

export const mutations = {
    SET_NETWORK_OBJECTS(state, networkObjects) {
        state.networkObjects = networkObjects;
    },
    SET_NETWORK_EQUIPMENTS(state, equipments) {
        state.networkEquipments = equipments;
    },
    SET_PARAMETERS_VALUES(state, values) {
        state.parametersValues = values;
    },
    SET_LOADING(state, loading) {
        state.loading = loading;
    },
    SET_ERROR(state, error) {
        state.error = error;
    },
    TOGGLE_NETWORK_OBJECT_ON_MAP(state, id) {
        setLocalStorageItem('hiddenOnMap', id)

        let networkObject = state.networkObjects.find(networkObject => networkObject.id === id);
        networkObject.showOnMap = !networkObject.showOnMap
    },
    TOGGLE_NETWORK_OBJECT_FIX_MAP(state, id) {
        setLocalStorageItem('fixedOnMap', id)

        let networkObject = state.networkObjects.find(networkObject => networkObject.id === id);
        networkObject.fixOnMap = !networkObject.fixOnMap
    },
    TOGGLE_NETWORK_OBJECT_IN_LIST(state, id) {
        setLocalStorageItem('hiddenInList', id);

        let networkObject = state.networkObjects.find(networkObject => networkObject.id === id);
        networkObject.showInList = !networkObject.showInList;
    }
}

export const actions = {
    getNetworkObjects({commit}) {
        commit("SET_LOADING", true);
        NetworkObjectsService.fetchTelemetryNetworkObjects()
            .then(networkObjects => commit("SET_NETWORK_OBJECTS", networkObjects))
            .catch(error => commit("SET_ERROR", error))
            .finally(() => commit("SET_LOADING", false))
    },
    getNetworkEquipments({commit}) {
        NetworkEquipmentsService.fetchNetworkEquipments()
            .then(equipments => commit("SET_NETWORK_EQUIPMENTS", equipments))
            .catch(error => commit("SET_ERROR", error));
    },
    getParametersValues({commit}) {
        NetworkEquipmentParametersService.fetchLatestParametersValues()
            .then(parametersValues => commit("SET_PARAMETERS_VALUES", parametersValues))
            .catch(error => commit("SET_ERROR", error))
    }
}

export const getters = {
    getNetworkObjectsByType: state => type => {
        if (type === 0) return state.networkObjects
        else return state.networkObjects.filter(networkObject => networkObject.type === type)
    },
    getNetworkEquipmentByNetworkObjectId: state => networkObjectId => {
        return state.networkEquipments.filter(equipment => equipment.networkObject.id === networkObjectId);
    },
    getParameterValueObject: state => parameterId => {
        const parameterValue = state.parametersValues.find(value => value.parameterId === parameterId);
        return parameterValue ? parameterValue : {}
    },
    getParameterValue: state => parameterId => {
        const parameterValue = state.parametersValues.find(value => value.parameterId === parameterId);
        return parameterValue ? parameterValue.value : '-'
    }
}