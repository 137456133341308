<template>
  <div class="col-12">
    <div
      v-if="grouping === 0"
      class="row"
    >
      <div
        v-for="balance in $store.getters['balance/balance']"
        :key="balance.id"
        class="col-12 balance-item"
      >
        <div class="group-title">
          <span>Зона: </span>
          <span>{{ balance.name }}</span>
        </div>
        <div class="row">
          <div class="col-lg-6 col-sm-12">
            <table class="table">
              <thead class="table-bg-color sticky-head">
                <tr>
                  <th>Наименование</th>
                  <th>Объём</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(value, key) in balance.getDevicesSupplySumForAllPeriods()"
                  :key="key"
                >
                  <td>{{ getDeviceName(key) }}</td>
                  <td>{{ value.toFixed(0) }}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="col-lg-6 col-sm-12">
            <div class="pie-chart-block">
              <pie
                :pie-chart-data="processPieChartData(balance.sumPeriodsConsumption(), balance.calculatePeriodsLosses())"
              />
            </div>
            <div class="pie-data">
              <div>
                <div v-if="isVs"
                     class="pie-data-title">
                  Подача воды в сеть:
                </div>
                <div v-else
                     class="pie-data-title">
                  Подача стоков в сеть:
                </div>
                <div class="pie-data-value">
                  {{ balance.sumPeriodsSupply() }} м<sup>3</sup>
                </div>
              </div>
              <div>
                <div class="pie-data-title">
                  Начисленный объём:
                </div>
                <div class="pie-data-value text-blue">
                  {{ balance.sumPeriodsConsumption() }} м<sup>3</sup>
                </div>
              </div>
              <div>
                <div v-if="isVs"
                     class="pie-data-title">
                  Потери:
                </div>
                <div v-else
                     class="pie-data-title">
                  Профицит стока:
                </div>
                <div class="pie-data-value text-red">
                  {{ balance.calculatePeriodsLosses() }} м<sup>3</sup>
                </div>
              </div>
            </div>
          </div>
        </div>
        <hr>
      </div>
    </div>

    <div
      v-else-if="grouping === 1"
      class="row"
    >
      <div
        v-for="balance in $store.getters['balance/balance']"
        :key="balance.id"
        class="col-12 balance-item"
      >
        <div class="row">
          <div class="col-12">
            <div class="group-title d-inline-block">
              <span>Зона: </span>
              <span>{{ balance.name }}</span>
            </div>
            <div class="d-inline-block float-right">
              <download-excel
                :data="processExportData(balance)"
                :header="balance.name"
                :worksheet="balance.name"
                class="btn custom-btn-blue-outline-xs"
                default-value="0"
                name="Баланс"
              >
                Экспорт в Excel
              </download-excel>
            </div>
          </div>

          <b-modal
            :id="`set-rate_${balance.id}`"
            centered
            hide-footer
            hide-header
            title="* все поля обязательны к заполнению"
          >
            <div class="col-12">
              <div class="custom-modal-header">
                <div class="custom-modal-title">
                  Введите тариф
                </div>
                <img
                  alt="Закрыть"
                  class="custom-modal-close"
                  src="@/assets/img/icons/close.svg"
                  @click="$bvModal.hide(`set-rate_${balance.id}`)"
                >
              </div>
              <div class="modal-divider" />
            </div>
            <div class="col-12">
              <div
                v-for="supply in balance.periods[0].supply"
                :key="supply.device_id"
              >
                <div class="form-group">
                  <label class="form-label">{{ getDeviceName(supply.device_id) }}</label>
                  <input
                    id="rate"
                    class="form-control form-control-fix"
                    placeholder="Стоимость ресурса"
                    type="text"
                    @change="setRate($event.target.value, supply.device_id)"
                  >
                </div>
              </div>
              <div class="modal-divider" />
              <button
                class="btn custom-btn-blue w-100"
                @click="$bvModal.hide(`set-rate_${balance.id}`)"
              >
                Закрыть
              </button>
            </div>
          </b-modal>

          <div class="col-12">
            <b-table
              :fields="createFields()"
              :items="makeTable(balance)"
              class="balance-table"
              responsive
            >
              <template #cell(show_details)="row">
                <chevron-down-icon
                  @click="row.toggleDetails"
                  v-if="row.detailsShowing"
                  class="table-icon"
                />
                <chevron-right-icon
                  @click="row.toggleDetails"
                  v-else
                  class="table-icon"
                />
              </template>
              <template #head(losses_rub)>
                <span v-if="isVs">Потери (₽)</span>
                <span v-else>Профицит (₽)</span>
                <settings-icon
                  v-b-modal="`set-rate_${balance.id}`"
                  class="rate-button"
                />
              </template>
              <template #cell(losses_rub)="row">
                {{ getLossesInRubles(row.item) }}
              </template>
              <template #row-details="row">
                <span>Источники:</span>
                <div
                  v-for="device in row.item.devices_supply"
                  :key="device.id"
                  class="col-12"
                >
                  <span>{{ getDeviceName(device.device_id) }} - </span>
                  <span>{{ device.value.toFixed(0) }} м<sup>3</sup></span>
                </div>
              </template>
            </b-table>
            <div class="col-12">
              <column
                ref="chart"
                :column-chart-data="generateChartData(balance)"
                :labels="setChartLabels"
              />
            </div>
          </div>
        </div>
        <hr>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import Pie from "@/components/Billing/charts/PieChart";
import Column from "@/components/Billing/charts/ColumnChart";
import JsonExcel from "vue-json-excel";

export default {
  name: "BalanceResult",
  components: {
    Pie,
    Column,
    "download-excel": JsonExcel
  },
  props: ['filter', 'isVs'],
  data() {
    return {
      balance_data: [],
      items: [],
      groups: [],
      rates: []
    }
  },
  computed: {
    ...mapGetters({
      getDeviceName: 'device/name',
      grouping: 'balance/grouping'
    }),
    setChartLabels: function () {
      let months = []
      this.filter.period.forEach(item => {
        months.push(this.$moment(item).format('MMMM'));
      })
      return months
    },
    getLossesInRubles: function () {
      return balance => {
        let supply_sum = balance.devices_supply.reduce((s, i) => s = s + i.value, 0);
        let losses_in_rub = 0;

        balance.devices_supply.forEach(device => {
          let supply_percent = (device.value * 100) / supply_sum;
          let losses_by_percent = supply_percent / 100 * parseInt(balance.losses);

          let el = this.rates.find(item => item.device_id == device.device_id);
          if (el !== undefined) losses_in_rub += losses_by_percent * el.value;
        })

        if (losses_in_rub !== (-Infinity) && !isNaN(losses_in_rub)) {
          return new Intl.NumberFormat('ru-RU',
              {style: 'currency', currency: 'RUB', minimumFractionDigits: 0}).format(losses_in_rub)
        } else {
          return '-'
        }
      }
    },
    processPieChartData: function () {
      return (consumption, losses) => {
        return [Number(consumption), Number(losses)]
      }
    }
  },
  methods: {
    createFields() {
      let fields = [
        {
          key: 'show_details',
          label: ''
        },
        {
          key: 'month',
          label: 'Месяц'
        },
        {
          key: 'supply',
          label: 'Подача'
        },
        {
          key: 'consumption',
          label: 'Начисления'
        },
        {
          key: 'losses_in_percent',
          label: 'Потери (%)'
        },
        {
          key: 'losses_rub',
          label: 'Потери (₽)'
        }
      ]

      if (!this.isVs) {
        fields.find(item => item.key === 'losses_in_percent').label = 'Профицит (%)'
      } else {
        fields.find(item => item.key === 'losses_in_percent').label = 'Потери (%)'
      }

      return fields
    },
    makeTable(balance) {
      let items = []
      balance.periods.forEach(period => {
        items.push({
          month: this.$moment(period.period).format('MMMM'),
          supply: period.sumSupply(),
          devices_supply: period.supply,
          consumption: period.sumConsumption(),
          losses: period.calculateLosses(),
          losses_in_percent: period.calculateLossesInPercent(),
          losses_rub: 0
        })
      })
      return items
    },
    generateChartData(balance) {
      let sup = [];
      let los = [];
      balance.periods.forEach(period => {
        sup.push(period.sumSupply());
        los.push(period.calculateLosses());
      })

      let chartData = [{
        name: 'Потери',
        data: []
      }, {
        name: 'Подано',
        data: []
      }]
      chartData[0].data = los;
      chartData[1].data = sup;

      if (!this.isVs) chartData[0].name = 'Профицит'
      else chartData[0].name = 'Потери'

      return chartData
    },
    setRate(val, id) {
      let index = this.rates.findIndex(el => el.device_id == id)
      if (index !== -1) {
        this.rates.splice(index, 1)
      }
      this.rates.push({
        device_id: id,
        value: val
      })
    },
    processExportData(balance_group) {
      let exportData = this.makeTable(balance_group)
      exportData.forEach(item => {
        item["Месяц"] = item.month;
        item["Подача"] = item.supply;
        item["Начисления"] = item.consumption;
        if (this.isVs) item["Потери"] = item.losses;
        else item["Профицит"] = item.losses;
        if (this.rates.length != 0) {
          if (this.isVs) item["Потери (₽)"] = this.getLossesInRubles(item);
          else item["Профицит (₽)"] = this.getLossesInRubles(item);
        }
        item.devices_supply.forEach(device => {
          let nameOfDevice = this.getDeviceName(device.device_id)
          if (nameOfDevice == "ТГВ-7 (лиц.)") {
            item["ТГВ-7"] = device.value
          } else {
            item[nameOfDevice] = device.value
          }
        })
        delete item ['devices'];
        delete item ['month'];
        delete item ['supply'];
        delete item ['consumption'];
        delete item ['devices_supply'];
        delete item ['losses'];
        delete item ['losses_in_percent'];
        delete item ['losses_rub'];
      });
      return exportData
    }
  }
}
</script>

<style scoped>
table {
  border-collapse: separate;
  border-spacing: 0;
}

thead tr th {
  border-top: 1px solid #468FC5;
  border-left: 1px solid #468FC5;
  color: #fff;
  font-size: 12px;
  font-weight: 700;
  background-color: #262F3D;
  text-align: center;
  vertical-align: middle;
}

thead tr th:first-child {
  border-radius: 5px 0 0 0;
}

thead tr th:last-child {
  border-right: 1px solid #468FC5;
  border-radius: 0 5px 0 0;
}

tbody tr td {
  border-left: 1px solid #468FC5;
  border-top: 1px solid #468FC5;
  color: #262F3D;
  font-size: 16px;
  font-weight: 700;
  background-color: #FFFFFF;
  text-align: center;
}

tbody tr:last-child td {
  border-bottom: 1px solid #468FC5;
}

tbody tr:last-child td:first-child {
  border-radius: 0 0 0 5px;
}

tbody tr:last-child td:last-child {
  border-radius: 0 0 5px 0;
}

tbody tr td:last-child {
  border-right: 1px solid #468FC5;
}

.table thead th {
  border-bottom: 0;
}

.group-title {
  margin-bottom: 16px;
}

.group-title > span:first-child {
  color: #468FC5;
  font-weight: 700;
  font-style: italic;
}

.group-title > span:last-child {
  color: #262F3D;
  font-weight: 700;
  font-style: italic;
}

.pie-chart-block {
  padding: 0 50px 10px 50px
}

.pie-data-title {
  display: inline-block;
  font-size: 14px;
  font-weight: 700;
  color: #262F3D;
  line-height: 13px;
  margin-right: 5px;
}

.pie-data-value {
  display: inline-block;
  font-size: 14px;
  font-weight: 700;
  color: #262F3D;
  line-height: 13px;
}

.text-blue {
  color: #468FC5;
}

.text-red {
  color: #E02800;
}
</style>