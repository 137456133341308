<template>
  <div class="custom-card">
    <div class="card-body">
      <div class="energy-consumption-table__wrapper">
        <table class="table table-responsive-xxl mb-0">
          <thead>
            <tr>
              <th class="w-40">
                Объект
              </th>
              <th class="w-40">
                Счётчик
              </th>
              <th
                v-for="month in $moment.months()"
                :key="`${month}_header`"
              >
                {{month}}
              </th>
            </tr>
          </thead>
          <tbody>
            <template v-for="energyConsumptionObject in energyConsumptionObjects">
              <tr
                v-for="(electricMeter, electricMeterKey) in energyConsumptionObject.electricMeters"
                :key="electricMeter.id"
                class="energy-consumption-object"
              >
                <td
                  v-if="electricMeterKey === 0"
                  :rowspan="energyConsumptionObject.electricMeters.length">
                  {{energyConsumptionObject.name}}
                </td>
                <td>{{ electricMeter.number }}
                </td>
                <td
                  v-for="(month, index) in $moment.months()"
                  :key="month"
                >
                  <input
                    :value="electricMeterMonthReading(electricMeter.id, index)"
                    class="electric-meter__reading-input"
                    type="text"
                    maxlength="11"
                    @change="saveElectricMeterReading($event, electricMeter.id, index + 1)"
                    @keypress="isNumber($event)"
                  >
                </td>
              </tr>
            </template>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters, mapState} from "vuex";
import {isNumber} from "@/utils/helpers";
import {showAlert, showToast} from "@/utils/notification";

export default {
  name: "EnergyConsumptionLogbook",
  computed: {
    ...mapState({
      energyConsumptionObjects: state => state.energyConsumptionObjects.energyConsumptionObjectsList,
      selectedDate: state => state.energyConsumptionLogbook.selectedDate
    }),
    ...mapGetters({
      electricMeterMonthReading: 'electricMetersReadings/getElectricMeterMonthReading'
    }),
    isNumber: () => isNumber
  },
  methods: {
    saveElectricMeterReading(event, electricMeterId, monthNumber) {
      this.$store.dispatch('electricMetersReadings/createElectricMeterMonthReading', {
        payload: {
          value: parseFloat(event.target.value),
          reading_at: `${this.$moment(this.selectedDate).format('YYYY')}-${monthNumber}`
        },
        electricMeterId
      })
          .then(() => {
            showToast('Значение сохранено', 'success');
          })
          .catch(error => showAlert('Ошибка', error))
    }
  }
}
</script>

<style scoped>
table {
  border-collapse: collapse;
  border-spacing: 0;
  padding-bottom: 10px;
}

thead tr th {
  color: #262F3D;
  font-size: 16px;
  font-weight: 700;
  background-color: #EAEFF2;
  text-align: center;
  vertical-align: middle;
  border: 1px solid #97ACB5;;
  padding: 5px;
}

tbody tr td {
  color: #262F3D;
  font-size: 16px;
  font-weight: 700;
  background-color: #FFFFFF;
  text-align: center;
  padding: 0.15rem;
  border: 1px solid #97ACB5;;
}

.table thead th {
  border-bottom: 0;
}

.table::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  background-color: #C7D3D9;
  border-right: .2em;
}

.table::-webkit-scrollbar-thumb {
  background-color: #97acb5;
  border-radius: .2em;
}

.electric-meter__reading-input {
  width: 100%;
  border: 0;
  font-size: 14px;
  text-align: center;
}

.electric-meter__reading-input:focus-visible {
  outline: none;
}
</style>