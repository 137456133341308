<template>
  <b-modal
    id="merge-issue"
    ref="merge-issue"
    centered
    hide-header
    hide-footer
    @shown="getActiveIssuesList()"
    @hidden="clearIssuesFilter()"
  >
    <div class="col-12">
      <div class="custom-modal-header">
        <div class="custom-modal-title">
          Объединить заявку
        </div>
        <img
          class="custom-modal-close"
          src="@/assets/img/icons/close.svg"
          alt="Закрыть"
          @click="$bvModal.hide('change-appeal-address')"
        >
      </div>
      <div class="modal-divider" />
    </div>
    <form @submit.prevent="submitForm()">
      <div class="col-12 mb-2">
        <div class="form-group">
          <label class="form-label">Объединить с заявкой</label>
          <v-select
            v-model="issue_id"
            :options="issues"
            :reduce="issue => issue.id"
            :get-option-label="(option) => option.getCaption()"
            placeholder="Выберите заявку"
          />
        </div>
        <div class="modal-divider" />
        <div class="row">
          <div class="col-12">
            <button
              type="submit"
              class="btn custom-btn-blue w-100"
            >
              Объединить
            </button>
          </div>
        </div>
      </div>
    </form>
  </b-modal>
</template>

<script>
import IssueState from "@/models/serviceDesk/IssueState";
import {mapState} from "vuex";
import {showToast, showAlert} from "@/utils/notification";

export default {
  name: "MergeIssueForm",
  data() {
    return {
      issue_id: null
    }
  },
  computed: {
    ...mapState({
      issue: state => state.issue.issue,
      issues: state => state.issues.issues
    })
  },
  methods: {
    submitForm() {
      this.$store.dispatch('issue/mergeIssue', {
        issue_id: this.issue_id,
        payload: {
          merged_issue_id: this.issue.id
        }
      })
          .then(() => {
            this.$bvModal.hide('merge-issue');
            showToast('Заявки объединены', 'success');
            this.$store.dispatch('issue/getIssue', this.issue.id);
            this.$store.dispatch('history/getIssueHistory', this.issue.id);
          })
          .catch(error => showAlert('Ошибка', error))
    },
    getActiveIssuesList() {
      this.$store.commit("issues/SET_FILTER_PARAMS", {
        issue_states: [
          IssueState.NEW,
          IssueState.APPOINTED,
          IssueState.ACCEPTED,
          IssueState.IN_WORK,
          IssueState.REJECTED,
          IssueState.POSTPONED
        ].join()
      });

      this.$store.dispatch('issues/getIssues');
    },
    clearIssuesFilter() {
      this.$store.commit("issues/SET_FILTER_PARAMS", null);
      this.$store.commit("issues/SET_ISSUES", []);
    }
  }
}
</script>

<style scoped>

</style>