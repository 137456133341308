<template>
  <div class="row">
    <div class="col-xs-12 col-lg-4 min-w">
      <transports-list ref="transportList"/>
    </div>
    <div class="col">
      <transport-map ref="transportMap" />
    </div>
  </div>
</template>

<script>
import TransportsList from "@/components/Transport/TransportsList";
import TransportMap from "@/components/Transport/TransportMap";
import {mapGetters} from 'vuex';

export default {
  name: "TransportPage",
  components: {
    TransportsList,
    TransportMap
  },
  data() {
    return {
      timing: null,
      timingStat: null
    };
  },
  computed: {
    ...mapGetters({
      transportIds: 'autos/getIds'
    })
  },
  watch: {
    $route: 'getAllTransport',
    transportIds: function () {
      this.getData();
      this.getTransportStat();
    }
  },
  created() {
    this.getAllTransport();
  },
  beforeDestroy() {
    clearInterval(this.timing);
    clearInterval(this.timingStat);
  },
  mounted() {
    if (localStorage.getItem('hideListAutos') == null) {
      localStorage.setItem('hideListAutos', JSON.stringify([]))
    }

    if (localStorage.getItem('hideMapAutos') == null) {
      localStorage.setItem('hideMapAutos', JSON.stringify([]))
    }

    if (localStorage.getItem('fixAutoMap') == null) {
      localStorage.setItem('fixAutoMap', JSON.stringify([]))
    }
  },
  methods: {
    async getAllTransport() {
      await this.$store.dispatch("autos/getAllAutos");
    },
    getData() {
      this.$store.dispatch('transportData/transportData');
      if (!this.timing) {
        this.runTiming();
      }
    },
    getTransportStat() {
      this.$store.dispatch('transportDayStat/statData');
      if (!this.timingStat) {
        this.runTimingStat();
      }
    },
    runTiming() {
      clearInterval(this.timing);
      this.timing = setInterval(this.getData, 30000);
    },
    runTimingStat() {
      clearInterval(this.timingStat);
      this.timingStat = setInterval(this.getTransportStat, 1800000);
    }
  }
}
</script>

<style scoped>
.min-w {
  min-width: 370px;
}
</style>