<template>
  <div class="custom-card">
    <div class="card-body">
      <div class="container-fluid">
        <div class="row">
          <div class="col-12">
            <form @submit.prevent="submitForm()">
              <div class="form-group">
                <label class="form-label">Наименование</label>
                <input
                  v-model="payload.name"
                  class="form-control form-control-fix"
                  placeholder="Введите наименование"
                >
              </div>
              <div v-if="type === 'update'"
                   class="form-group">
                <button
                  v-if="this.activity === 0"
                  @click="activateDepartment()"
                  class="btn custom-btn-blue-outline-xs w-100">
                  Сменить статус на "Активно"
                </button>
                <button
                  v-else
                  @click="deactivateDepartment()"
                  class="btn custom-btn-blue-outline-xs w-100">
                  Сменить статус на "Не активно"
                </button>
              </div>
              <button class="btn custom-btn-blue w-100">Сохранить</button>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import notification from "@/mixins/notification";

export default {
  name: "DepartmentsForm",
  mixins: [notification],
  data() {
    return {
      payload: {
        name: null
      },
      activity: null,
      type: 'create'
    }
  },
  computed: {
    ...mapGetters({
      department: 'departments/getDepartment',
      users: 'user/users'
    })
  },
  mounted() {
    this.$store.dispatch('user/getAllUsers');

    if (this.$route.name === 'UpdateDepartment') {
      this.$store.dispatch('departments/getDepartment', this.$route.params.id)
          .then(() => {
            this.payload.name = this.department.name;
            this.activity = this.department.activity;
            this.type = 'update'
          })
          .catch(error => this.alert('Ошибка', error, 'error'))
    }
  },
  methods: {
    submitForm() {
      if (this.type === 'create') this.createDepartment()
      else this.updateDepartment()
    },
    createDepartment() {
      this.$store.dispatch('departments/createDepartment', this.payload)
          .then(() => {
            this.$router.push('/departments');
            this.toast('Подразделение создано', 'success');
          })
          .catch(error => {
            this.alert('Ошибка', error, 'error')
          })
    },
    updateDepartment() {
      this.$store.dispatch('departments/updateDepartment', {
        department_id: this.$route.params.id,
        payload: this.payload
      })
          .then(() => {
            this.$router.push('/departments');
            this.toast('Подразделение обновлено', 'success');
          })
          .catch(error => {
            this.alert('Ошибка', error, 'error')
          })
    },
    activateDepartment() {
      this.$store.dispatch('departments/activateDepartment', this.$route.params.id)
          .then(() => this.toast('Подразделение переведено в статус "Активно"', 'success'))
          .catch(error => this.alert('Ошибка', error, 'error'));
    },
    deactivateDepartment() {
      this.$store.dispatch('departments/deactivateDepartment', this.$route.params.id)
          .then(() => this.toast('Подразделение переведено в статус "Не активно"', 'success'))
          .catch(error => this.alert('Ошибка', error, 'error'));
    }
  }
}
</script>

<style scoped>

</style>