import * as API from './API';
import WorkConsumptionRate from "@/models/serviceDesk/WorkConsumptionRate";
import {getError} from "@/utils/helpers";

export default {
    fetchAllWorkConsumptionRates(workId) {
        return new Promise((resolve, reject) => {
            API.apiClient.get(`/service-desk/works/${workId}/consumption-rates/all`)
                .then(response => resolve(response.data.data.map(item => new WorkConsumptionRate({
                    id: item.id,
                    ...item.attributes
                }))))
                .catch(error => reject(getError(error)))
        })
    },
    createWorkConsumableRate(workId, payload) {
        return API.apiClient.post(`/service-desk/works/${workId}/consumption-rates`, payload);
    },
    updateWorkConsumableRate(workId, rateId, payload) {
        return API.apiClient.patch(`/service-desk/works/${workId}/consumption-rates/${rateId}`, payload);
    },
    deleteWorkConsumableRate(workId, rateId) {
        return API.apiClient.delete(`/service-desk/works/${workId}/consumption-rates/${rateId}`);
    }
}