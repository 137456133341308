import WorkConsumptionRatesService from "@/services/WorkConsumptionRatesService";
import {getError} from "@/utils/helpers";

export const namespaced = true;

export const state = {
    workConsumptionRates: [],
    error: null,
    loading: false
}

export const mutations = {
    SET_WORK_CONSUMPTION_RATES(state, rates) {
        state.workConsumptionRates = rates;
    },
    SET_ERROR(state, error) {
        state.error = error;
    },
    SET_LOADING(state, loading) {
        state.loading = loading;
    },
    DELETE_WORK_CONSUMPTION_RATE(state, rateId) {
        const index = state.workConsumptionRates.findIndex(rate => rate.id === rateId);
        state.workConsumptionRates.splice(index, 1);
    }
}

export const actions = {
    getWorkConsumptionRates({commit}, workId) {
        commit("SET_LOADING", true);
        return new Promise((resolve, reject) => {
            WorkConsumptionRatesService.fetchAllWorkConsumptionRates(workId)
                .then(consumptionRates => {
                    commit("SET_WORK_CONSUMPTION_RATES", consumptionRates);
                    resolve();
                })
                .catch(error => {
                    commit("SET_ERROR", error);
                    reject();
                })
                .finally(() => commit("SET_LOADING", false))
        })
    },
    createWorkConsumptionRate(context, {workId, payload}) {
        return new Promise((resolve, reject) => {
            WorkConsumptionRatesService.createWorkConsumableRate(workId, payload)
                .then(() => resolve())
                .catch(error => reject(getError(error)))
        })
    },
    updateWorkConsumptionRate(context, {workId, rateId, payload}) {
        return new Promise((resolve, reject) => {
            WorkConsumptionRatesService.updateWorkConsumableRate(workId, rateId, payload)
                .then(() => resolve())
                .catch(error => reject(getError(error)))
        })
    },
    deleteWorkConsumptionRate(context, {workId, rateId}) {
        return new Promise((resolve, reject) => {
            WorkConsumptionRatesService.deleteWorkConsumableRate(workId, rateId)
                .then(() => resolve())
                .catch(error => reject(getError(error)))
        })
    }
}