export default class User {
    constructor({id, attributes}) {
        this.id = id;
        this.name = attributes.name;
        this.email = attributes.email;
        this.isAdmin = attributes.isAdmin;
        this.permissions = attributes.permissions;
        this.roles = attributes.roles;
        this.active = attributes.status;
        this.phone = attributes.phone;
        this.job_title = attributes.job_title;
    }

    getActiveLabel() {
        return this.active ? 'Активен' : 'Не активен'
    }
}