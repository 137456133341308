<template>
  <b-tab
    title="Фильтр"
    title-item-class="custom-nav-item"
    title-link-class="custom-nav-link"
  >
    <h4 class="billing-tab-title mb-20">
      Параметры фильтра
    </h4>

    <div class="row">
      <div class="col-12 mb-3">
        <h5>По адресу</h5>
        <dadata-suggestions
          class="form-control form-control-fix"
          @change="setAddress"
          v-model="full_address"
          placeholder="Начните вводить адрес"
        />
      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <h5>По ФИО абонента</h5>
        <input
          v-model="filter.client_name"
          class="form-control form-control-fix mb-3"
          placeholder="Введите ФИО абонента"
          type="text"
        >
      </div>
    </div>

    <div class="row">
      <div class="col-xxl-6 col-xl-12">
        <h5>По номеру договора</h5>
        <input
          v-model="filter.contract_number"
          class="form-control form-control-fix mb-3"
          placeholder="Введите номер договора"
          type="text"
        >
      </div>
      <div class="col-xxl-6 col-xl-12">
        <h5>По дате заключения</h5>
        <date-picker
          id="connectionDate"
          input-class="form-control-fix w-100 pl-2"
          placeholder="Выберите период"
          v-model="filter.open_date"
          :default-value="defaultValue"
          range
          format="DD.MM.YYYY"
          type="date"
          value-type="YYYY-MM-DD"
          @clear="filter.open_date = null"
        />
      </div>
    </div>

    <div class="row">
      <div class="col-xxl-6 col-xl-12 col-lg-12 col-md-12 col-sm-12">
        <h5>По типу клиента</h5>
        <select
          id="contract_type"
          v-model="filter.contract_type"
          class="form-control form-control-fix mb-3"
        >
          <option value="">
            Выберите значение
          </option>
          <option value="1">
            Физические лица
          </option>
          <option value="2">
            Юридические лица
          </option>
        </select>
      </div>
      <div class="col-xxl-6 col-xl-12">
        <h5>По статусу договора</h5>
        <select
          id="status"
          v-model="filter.status"
          class="form-control form-control-fix mb-3"
        >
          <option value="">
            Выберите значение
          </option>
          <option value="1">
            Открыт
          </option>
          <option value="2">
            Закрыт
          </option>
        </select>
      </div>
    </div>

    <div class="row">
      <div class="col-xxl-6 col-xl-12 col-lg-12 col-md-12 col-sm-12">
        <h5>По структуре потреб.</h5>
        <select
          id="structure"
          v-model="filter.structure"
          class="form-control form-control-fix mb-3"
        >
          <option value="">
            Выберите значение
          </option>
          <option :value="1">
            Холодная вода
          </option>
          <option :value="2">
            Стоки холодной воды
          </option>
          <option :value="3">
            Стоки горячей воды
          </option>
          <option :value="4">
            Неизвестно
          </option>
        </select>
      </div>
      <div class="col-xxl-6 col-xl-12 col-lg-12 col-md-12 col-sm-12">
        <h5>По статусу ПУ</h5>
        <select
          id="meter_status"
          v-model="filter.meter_status"
          class="form-control form-control-fix mb-3"
        >
          <option value="">
            Выберите статус
          </option>
          <option :value="1">
            Активен
          </option>
          <option :value="2">
            В ремонте
          </option>
          <option :value="3">
            На поверке
          </option>
          <option :value="4">
            Отключен/закрыт
          </option>
        </select>
      </div>
    </div>

    <div class="row">
      <div class="col-xxl-6 col-xl-12">
        <h5>По зоне водоснабжения</h5>
        <select
          v-model="filter.zone_ws_id"
          class="form-control form-control-fix mb-3"
        >
          <option value="null">
            Выберите зону
          </option>
          <option
            v-for="zone in this.$store.getters['zone/getZonesByNetworkType'](1)"
            :key="zone.id"
            :value="zone.attributes.sys_id"
          >
            {{ zone.attributes.name }}
          </option>
        </select>
      </div>
      <div class="col-xxl-6 col-xl-12">
        <h5>По зоне водоотведения</h5>
        <select
          v-model="filter.zone_wd_id"
          class="form-control form-control-fix mb-3"
        >
          <option value="null">
            Выберите зону
          </option>
          <option
            v-for="zone in this.$store.getters['zone/getZonesByNetworkType'](2)"
            :key="zone.id"
            :value="zone.attributes.sys_id"
          >
            {{ zone.attributes.name }}
          </option>
        </select>
      </div>
    </div>

    <div>
      <h5 class="mt-2">
        По объёму потребления ресурса
      </h5>
      <div class="row">
        <div class="col-xxl-6 col-xl-12 col-lg-12 col-md-12 col-sm-12 mb-2">
          <div class="input-group">
            <date-picker
              id="consumptionPeriod"
              v-model="filter.consumption_period"
              :default-value="defaultValue"
              class="w-100"
              format="MMMM YYYY г."
              input-class="form-control-fix w-100 pl-2"
              placeholder="Выберите период"
              range
              type="month"
              value-type="YYYY-MM-DD"
              @clear="filter.consumption_period = null"
            />
          </div>
        </div>
        <div class="col-xxl-3 col-xl-12 col-lg-12 col-md-12 col-sm-12">
          <div class="input-group mb-2">
            <input
              id="consValue-min"
              v-model="filter.consumption_values[0]"
              class="form-control form-control-fix"
              placeholder="Значение от"
              type="text"
              @change="consValueMinFix($event)"
            >
          </div>
        </div>
        <div class="col-xxl-3 col-xl-12 col-lg-12 col-md-12 col-sm-12">
          <div class="input-group">
            <input
              id="consValue-max"
              v-model="filter.consumption_values[1]"
              class="form-control form-control-fix"
              placeholder="Значение до"
              type="text"
              @change="consValueMaxFix($event)"
            >
          </div>
        </div>
      </div>
    </div>

    <div>
      <h5 class="mt-2">
        По объёму задолженности
      </h5>
      <div class="row mb-4">
        <div class="col-xxl-6 col-xl-12 col-lg-12 col-md-12 col-sm-12">
          <div class="input-group mb-2">
            <input
              id="debt-min"
              class="form-control form-control-fix"
              placeholder="Минимальное значение"
              type="text"
              @change="debtMinFix($event)"
            >
          </div>
        </div>
        <div class="col-xxl-6 col-xl-12 col-lg-12 col-md-12 col-sm-12">
          <div class="input-group">
            <input
              id="debt-max"
              class="form-control form-control-fix"
              placeholder="Максимальное значение"
              type="text"
              @change="debtMaxFix($event)"
            >
          </div>
        </div>
      </div>
    </div>

    <div class="row mt-4">
      <div class="col-md-6 col-xs-12">
        <button
          class="btn custom-btn-blue-outline w-100"
          @click="submitFilter()"
        >
          Применить фильтр
          <loader v-if="loader" />
        </button>
      </div>
      <div class="col-md-6 col-xs-12">
        <button
          class="btn custom-btn-blue-outline w-100 d-flex align-items-center justify-content-center"
          @click="clearFilter()"
        >
          <refresh-ccw-icon class="mr-2"/>
          Сбросить
        </button>
      </div>
    </div>
  </b-tab>
</template>

<script>
import DatePicker from "vue2-datepicker";
import Loader from "@/layout/BaseLoader";
import notification from "@/mixins/notification";
import Vue from "vue";
import DadataSuggestions from "vue-dadata-suggestions";

Vue.use(DadataSuggestions, {
  token: process.env.VUE_APP_DADATA_API_KEY,
  type: 'ADDRESS',
  geoLocation: [{
    "kladr_id": process.env.VUE_APP_DADATA_KLADR_ID
  }]
});

export default {
  name: "BillingClientsFilter",
  components: {DatePicker, Loader},
  mixins: [notification],
  data() {
    return {
      full_address: '',
      filter: {
        region: '',
        city: '',
        settlement: '',
        street: '',
        house: '',
        block: '',
        zone_ws_id: null,
        zone_wd_id: null,
        contract_number: '',
        client_name: '',
        status: '',
        open_date: [],
        meter_status: '',
        saldo: [],
        consumption_period: null,
        consumption_values: [],
        structure: '',
        contract_type: ''
      },
      defaultValue: new Date(),
      loader: false
    }
  },
  computed: {
    validateForm: function () {
      return () => {
        let payload = JSON.parse(JSON.stringify(this.filter));
        if (payload.consumption_values[0] == null && payload.consumption_values[1] != null) {
          this.alert('Ошибка', 'Заполните поле "Значение от', 'error');
          return false;
        }
        if (payload.consumption_values[0] != null && payload.consumption_values[1] == null) {
          this.alert('Ошибка', 'Заполните поле "Значение до', 'error');
          return false;
        }
        if (payload.consumption_period == null && payload.consumption_values[0] != null) {
          this.alert('Ошибка', 'Выберите период объема потребления ресурса', 'error');
          return false;
        }
        if (payload.consumption_period != null && payload.consumption_values[0] == null) {
          this.alert('Ошибка', 'Заполните поля "Значение от" и "Значение до', 'error');
          return false;
        }
        if (payload.saldo[0] == null && payload.saldo[1] != null) {
          this.alert('Ошибка', 'Заполните поле "Минимальное значение', 'error');
          return false;
        }
        if (payload.saldo[1] == null && payload.saldo[0] != null) {
          this.alert('Ошибка', 'Заполните поле "Максимальное значение', 'error');
          return false;
        }
      }
    },
    cleanFilter: function () {
      return filter => {
        let payload = JSON.parse(JSON.stringify(filter))
        for (let propName in payload) {
          if (payload[propName] === null
              || payload[propName] === 0
              || payload[propName] === undefined
              || payload[propName] === ""
              || payload[propName].length === 0) {
            delete payload[propName]
          }
        }
        return payload
      }
    }
  },
  mounted() {
    this.$store.dispatch("zone/getAllZones");
  },
  methods: {
    submitFilter() {
      let filter = {
        params: this.cleanFilter(this.filter),
        page: 1
      }
      if (this.validateForm() === false) return false;
      this.loader = true;
      this.$store.dispatch("client/getClients", filter)
          .then(() => {
            this.loader = false;
            this.$store.commit("client/SET_TAB_INDEX", 1);
            this.$emit("data-complete", filter);
          })
    },
    clearFilter() {
      this.full_address = '';
      this.filter.region = '';
      this.filter.city = '';
      this.filter.settlement = '';
      this.filter.street = '';
      this.filter.house = '';
      this.filter.block = '';
      this.filter.zone_ws_id = null;
      this.filter.zone_wd_id = null;
      this.filter.contract_number = '';
      this.filter.client_name = '';
      this.filter.status = '';
      this.filter.open_date = [];
      this.filter.meter_status = '';
      this.filter.saldo = [];
      this.filter.consumption_period = null;
      this.filter.consumption_values = [];
      this.filter.structure = '';
      this.filter.contract_type = '';

      document.getElementById('debt-min').value = '';
      document.getElementById('debt-max').value = '';
    },
    debtMinFix(event) {
      if (event.target.value == '') {
        delete this.filter.saldo[1]
      } else {
        this.filter.saldo[1] = -event.target.value
      }
      if (this.filter.saldo[0] == null && this.filter.saldo[1] == null) {
        this.filter.saldo = []
      }
    },
    debtMaxFix(event) {
      if (event.target.value == '') {
        delete this.filter.saldo[0]
      } else {
        this.filter.saldo[0] = -event.target.value
      }
      if (this.filter.saldo[0] == null && this.filter.saldo[1] == null) {
        this.filter.saldo = []
      }

    },
    consValueMinFix(event) {
      if (event.target.value == '') {
        delete this.filter.consumption_values[0]
      }
      if (this.filter.consumption_values[0] == null && this.filter.consumption_values[1] == null) {
        this.filter.consumption_values = []
      }
    },
    consValueMaxFix(event) {
      if (event.target.value == '') {
        delete this.filter.consumption_values[1]
      }
      if (this.filter.consumption_values[0] == null && this.filter.consumption_values[1] == null) {
        this.filter.consumption_values = []
      }
    },
    setAddress(event) {
      if (typeof event === 'object') {
        this.filter.region = event.data.region;
        this.filter.city = event.data.city;
        this.filter.settlement = event.data.settlement;
        this.filter.street = event.data.street;
        this.filter.house = event.data.house;
        this.filter.block = event.data.block;
      }
    }
  }
}
</script>

<style scoped>
</style>