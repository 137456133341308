<template>
  <table class="admin-table">
    <thead class="admin-table-header">
      <tr>
        <td>ID</td>
        <td>Имя</td>
        <td>E-mail</td>
        <td>Статус</td>
        <td>Роли</td>
        <td>Действия</td>
      </tr>
    </thead>
    <tbody class="admin-table-body">
      <tr
        v-for="user in $store.state.user.users"
        :key="user.id"
      >
        <td>{{ user.id }}</td>
        <td>{{ user.name }}</td>
        <td>{{ user.email }}</td>
        <td>{{ user.getActiveLabel() }}</td>
        <td>{{ user.roles.map(role => role.attributes.name).join(', ') }}</td>
        <td class="table-action">
          <edit-icon
            class="cursor-pointer mr-1"
            @click="showUser(user.id)"/>
          <a @click="deleteUser(user.id)">
            <trash2-icon />
          </a>
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
import notification from "@/mixins/notification";

export default {
  name: "UsersList",
  mixins: [notification],
  methods:{
    showUser(userId) {
      this.$store.dispatch('user/getUser', userId)
          .then(() => {
            this.$router.push(`/admin-users-update/${userId}`)
          })
    },
    async deleteUser(user_id){
      this.$swal({
        title: 'Вы действительно хотите удалить пользователя?',
        showCancelButton: true,
        confirmButtonText: `Да`,
        cancelButtonText: 'Отмена',
        icon: 'warning'
      }).then(result=>{
        if (result.isConfirmed) {
          this.$store.dispatch('user/deleteUser', user_id)
              .then(() => {
                this.toast('Пользователь удалён', 'success');
                this.$store.commit('user/DELETE_USER', user_id);
              });
        }
      });
    }
  }
}
</script>

<style scoped>

</style>