export default class IssueState {
    static NEW = 1;
    static APPOINTED = 2;
    static ACCEPTED = 3;
    static IN_WORK = 4;
    static REJECTED = 5;
    static CANCELED = 6;
    static CLOSED = 7;
    static POSTPONED = 8;

    constructor(id) {
        this.id = id;
        this.label = this.getStateLabel();
        this.color = this.getStateColor();
    }

    getStateLabel() {
        switch (this.id) {
            case IssueState.NEW:
                return 'Новая'
            case IssueState.APPOINTED:
                return 'Назначена'
            case IssueState.ACCEPTED:
                return 'Принята'
            case IssueState.IN_WORK:
                return 'В работе'
            case IssueState.REJECTED:
                return 'Снята'
            case IssueState.CANCELED:
                return 'Отменена'
            case IssueState.CLOSED:
                return 'Закрыта'
            case IssueState.POSTPONED:
                return 'Отложена'
        }
    }

    getStateColor() {
        switch (this.id) {
            case IssueState.NEW:
                return '#468FC5'
            case IssueState.APPOINTED:
                return '#46c5b2'
            case IssueState.ACCEPTED:
                return '#04BD00'
            case IssueState.IN_WORK:
                return '#FFBF1C'
            case IssueState.REJECTED:
                return '#E02800'
            case IssueState.CANCELED:
                return '#4f4f4f'
            case IssueState.CLOSED:
                return '#4f4f4f'
            case IssueState.POSTPONED:
                return '#4f4f4f'
        }
    }

}