<template>
  <div class="row">
    <div class="col-12">
      <div class="custom-card">
        <div class="card-body">
          <div class="row">
            <div class="col-12">
              <h4 class="table-title d-inline-block">
                Сервера данных транспорта
              </h4>
              <div v-if="$store.state.transportDataServers.loading"
                   class="d-inline-block ml-2">
                <base-loader />
              </div>
              <router-link to="/create-transport-data-server">
                <button class="btn custom-btn-blue float-right">
                  Добавить сервер данных
                </button>
              </router-link>
              <hr class="divider">
            </div>

            <div
              v-if="$store.state.transportDataServers.dataServers.length"
              class="col-12"
            >
              <transport-data-servers-list />
            </div>

            <div
              v-if="!$store.state.transportDataServers.dataServers.length && !$store.state.transportDataServers.loading"
              class="col-12 mt-2"
            >
              <div class="no-result-title">Сервера данных не найдены</div>
            </div>

          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TransportDataServersList from "@/components/Transport/admin/TransportDataServersList";
import BaseLoader from "@/layout/BaseLoader";
export default {
  name: "TransportDataServersListPage",
  components: {BaseLoader, TransportDataServersList},
  mounted() {
    this.$store.dispatch('transportDataServers/getAllTransportDataServers');
  }
}
</script>

<style scoped>

</style>