<template>
  <div class="row">
    <div class="col-12">
      <button @click="$bvModal.show('add-appeal')"
              class="btn custom-btn-blue-outline-xs mr-1 mb-1">Прикрепить обращение</button>

      <button v-if="issue.state.id === IssueState.NEW"
              @click="$bvModal.show('merge-issue')"
              class="btn custom-btn-blue-outline-xs mr-1 mb-1">Объединить заявку</button>

      <button @click="$router.push(`/create-related-issue/${issue.id}`)"
              class="btn custom-btn-blue-outline-xs mr-1 mb-1">Создать связанную заявку</button>

      <button v-if="issue.state.id !== IssueState.POSTPONED"
              @click="$bvModal.show('add-shutdown')"
              class="btn custom-btn-blue-outline-xs mr-1 mb-1">Добавить отключение</button>

      <button v-if="issue.state.id !== IssueState.CLOSED && issue.state.id !== IssueState.CANCELED &&
                issue.state.id !== IssueState.POSTPONED"
              @click="$bvModal.show('add-leak')"
              class="btn custom-btn-blue-outline-xs mr-1 mb-1">Добавить утечку</button>

      <button v-if="issue.state.id === IssueState.NEW || issue.state.id === IssueState.APPOINTED
                || issue.state.id === IssueState.REJECTED"
              @click="$bvModal.show('add-issue-category')"
              class="btn custom-btn-blue-outline-xs mr-1 mb-1">
        <span v-if="!issue.category">Назначить категорию</span>
        <span v-else>Изменить категорию</span>
      </button>

      <button v-if="issue.state.id === IssueState.ACCEPTED || issue.state.id === IssueState.IN_WORK"
              @click="$bvModal.show('add-task')"
              class="btn custom-btn-blue-outline-xs mr-1 mb-1">Добавить задание</button>

      <button v-if="issue.state.id === IssueState.NEW || issue.state.id === IssueState.REJECTED"
              @click="$bvModal.show('appoint-department')"
              class="btn custom-btn-blue-outline-xs mr-1 mb-1">Назначить подразделение</button>

      <button v-if="issue.state.id === IssueState.APPOINTED"
              @click="doIssueAction('issue/acceptIssue', 'Заявка принята')"
              class="btn custom-btn-blue-outline-xs mr-1 mb-1">Принять</button>

      <button v-if="issue.state.id === IssueState.ACCEPTED || issue.state.id === IssueState.POSTPONED"
              @click="doIssueAction('issue/startIssue', 'Заявка переведена в работу')"
              class="btn custom-btn-blue-outline-xs mr-1 mb-1">В работу</button>

      <button v-if="issue.state.id === IssueState.ACCEPTED || issue.state.id === IssueState.IN_WORK"
              @click="$bvModal.show('postpone-issue')"
              class="btn custom-btn-blue-outline-xs mr-1 mb-1">Отложить</button>

      <button v-if="issue.state.id === IssueState.NEW || issue.state.id === IssueState.ACCEPTED ||
                !issue.planned_start_at"
              @click="$bvModal.show('plan-issue')"
              class="btn custom-btn-blue-outline-xs mr-1 mb-1">Запланировать</button>

      <button v-if="issue.state.id === IssueState.NEW || issue.state.id === IssueState.APPOINTED
                || issue.state.id === IssueState.ACCEPTED"
              @click="doIssueAction('issue/cancelIssue', 'Заявка отменена')"
              class="btn custom-btn-blue-outline-xs mr-1 mb-1">Отменить</button>

      <button v-if="issue.state.id === IssueState.APPOINTED || issue.state.id === IssueState.ACCEPTED
                || issue.state.id === IssueState.IN_WORK"
              @click="doIssueAction('issue/rejectIssue', 'Заявка снята')"
              class="btn custom-btn-blue-outline-xs mr-1 mb-1">Снять</button>

      <button v-if="issue.state.id === IssueState.IN_WORK || issue.state.id === IssueState.REJECTED"
              @click="doIssueAction('issue/closeIssue', 'Заявка закрыта')"
              class="btn custom-btn-blue-outline-xs mr-1 mb-1">Закрыть</button>
    </div>

    <add-appeal-form />
    <appoint-department-form />
    <add-task-form />
    <add-shutdown-form />
    <add-issue-category-form />
    <add-leak-form />
    <postpone-issue-form />
    <plan-issue-form />
    <merge-issue-form />
  </div>
</template>

<script>
import AddAppealForm from "@/components/ServiceDesk/Issues/ActionsForms/AddAppealForm";
import AppointDepartmentForm from "@/components/ServiceDesk/Issues/ActionsForms/AppointDepartmentForm";
import AddTaskForm from "@/components/ServiceDesk/Issues/ActionsForms/AddTaskForm";
import {mapGetters} from "vuex";
import notification from "@/mixins/notification";
import AddShutdownForm from "@/components/ServiceDesk/Issues/ActionsForms/AddShutdownForm";
import AddIssueCategoryForm from "@/components/ServiceDesk/Issues/ActionsForms/AddIssueCategoryForm";
import AddLeakForm from "@/components/ServiceDesk/Issues/ActionsForms/AddLeakForm";
import IssueState from "@/models/serviceDesk/IssueState";
import PostponeIssueForm from "@/components/ServiceDesk/Issues/ActionsForms/PostponeIssueForm";
import PlanIssueForm from "@/components/ServiceDesk/Issues/ActionsForms/PlanIssueForm";
import MergeIssueForm from "@/components/ServiceDesk/Issues/ActionsForms/MergeIssueForm";

export default {
  name: "IssueActions",
  components: {
    MergeIssueForm,
    PlanIssueForm,
    PostponeIssueForm,
    AddLeakForm,
    AddIssueCategoryForm,
    AddShutdownForm,
    AddAppealForm,
    AddTaskForm,
    AppointDepartmentForm
  },
  mixins: [notification],
  computed: {
    ...mapGetters({
      issue: 'issue/getIssue'
    }),
    IssueState: () => IssueState
  },
  methods: {
    doIssueAction(action, successMessage) {
      this.$store.dispatch(action, this.issue.id)
          .then(() => {
            this.toast(successMessage, 'success');
            this.$store.dispatch('issue/getIssue', this.issue.id);
            this.$store.dispatch('history/getIssueHistory', this.issue.id);
          })
          .catch(error => {
            this.alert('Ошибка', error, 'error');
          })
    }
  }
}
</script>

<style scoped>

</style>