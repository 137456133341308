import LeaksService from "@/services/LeaksService";
import {getError} from "@/utils/helpers";

export const namespaced = true;

export const state = {
    leaks: [],
    loading: false,
    error: null
}

export const mutations = {
    SET_LEAKS(state, leaks) {
        state.leaks = leaks;
    },
    SET_LOADING(state, loading) {
        state.loading = loading;
    },
    SET_ERROR(state, error) {
        state.error = error;
    }
}

export const actions = {
    getLeaks({commit}, issue_id) {
        commit("SET_LOADING", true);
        LeaksService.getLeaks(issue_id)
            .then(leaks => commit("SET_LEAKS", leaks))
            .catch(error => commit("SET_ERROR", error))
            .finally(() => commit("SET_LOADING", false));
    },
    createLeak({commit}, {issue_id, payload}) {
        return new Promise((resolve, reject) => {
            LeaksService.createLeak({issue_id, payload})
                .then(() => resolve())
                .catch(error => {
                    commit("SET_ERROR", getError(error))
                    reject(getError(error))
                })
        })
    }
}

export const getters = {
    getLeaks: state => {
        return state.leaks;
    }
}