<template>
  <div class="col-md-12">
    <form @submit.prevent="checkType">
      <div class="form-group">
        <label class="form-label">Наименование</label>
        <input
          v-model="label"
          type="text"
          class="form-control form-control-fix"
          maxlength="15"
          placeholder="Наименование"
          required
        >
      </div>
      <div class="form-group">
        <label class="form-label">Гос.номер</label>
        <input
          v-model="gov_number"
          type="text"
          class="form-control form-control-fix"
          placeholder="Гос.номер"
          required
        >
      </div>
      <div class="form-group">
        <label class="form-label">Источник данных транспорта</label>
        <v-select
          v-model="data_source"
          :options="transportDataServers"
          :reduce="server => server.id"
          :get-option-label="(option) => option.name"
          placeholder="Выберите источник данных транспорта"
        />
      </div>
      <div class="form-group">
        <label class="form-label">Внешний ID</label>
        <input
          v-model="external_id"
          type="text"
          class="form-control form-control-fix"
          placeholder="Внешний ID"
          required
        >
      </div>
      <div class="form-group">
        <label class="form-label">Тип</label>
        <select
          v-model="type"
          class="form-control form-control-fix mb-3"
          required
        >
          <option
            :value="1"
            selected
          >
            Легковая
          </option>
          <option :value="2">
            Грузовая
          </option>
          <option :value="3">
            Водовозка
          </option>
          <option :value="4">
            Спец. техника
          </option>
        </select>
      </div>
      <div class="form-group">
        <span>Иконка на карте:</span>
        <div class="row">
          <div class="col-md-2">
            <label class="custom-control custom-radio">
              <input
                v-model="icon_type"
                name="custom-radio"
                type="radio"
                value="car-tab11.png"
                class="custom-control-input"
              >
              <span class="custom-control-label">
                <img
                  class="choose-icon"
                  src="@/assets/img/transport/car-tab11.png"
                >
              </span>
            </label>
          </div>
          <div class="col-md-2">
            <label class="custom-control custom-radio">
              <input
                v-model="icon_type"
                name="custom-radio"
                type="radio"
                value="car-tab22.png"
                class="custom-control-input"
              >
              <span class="custom-control-label">
                <img
                  class="choose-icon"
                  src="@/assets/img/transport/car-tab22.png"
                >
              </span>
            </label>
          </div>
          <div class="col-md-2">
            <label class="custom-control custom-radio">
              <input
                v-model="icon_type"
                name="custom-radio"
                type="radio"
                value="water.png"
                class="custom-control-input"
              >
              <span class="custom-control-label">
                <img
                  class="choose-icon"
                  src="@/assets/img/transport/water.png"
                >
              </span>
            </label>
          </div>
          <div class="col-md-2">
            <label class="custom-control custom-radio">
              <input
                v-model="icon_type"
                name="custom-radio"
                type="radio"
                value="car-tab33.png"
                class="custom-control-input"
              >
              <span class="custom-control-label">
                <img
                  class="choose-icon"
                  src="@/assets/img/transport/car-tab33.png"
                >
              </span>
            </label>
          </div>
          <div class="col-md-2">
            <label class="custom-control custom-radio">
              <input
                v-model="icon_type"
                name="custom-radio"
                type="radio"
                value="tow-truck.png"
                class="custom-control-input"
              >
              <span class="custom-control-label">
                <img
                  class="choose-icon"
                  src="@/assets/img/transport/tow-truck.png"
                >
              </span>
            </label>
          </div>
          <div class="col-md-2">
            <label class="custom-control custom-radio">
              <input
                v-model="icon_type"
                name="custom-radio"
                type="radio"
                value="tractor.png"
                class="custom-control-input"
              >
              <span class="custom-control-label">
                <img
                  class="choose-icon"
                  src="@/assets/img/transport/tractor.png"
                >
              </span>
            </label>
          </div>
          <div class="col-md-2">
            <label class="custom-control custom-radio">
              <input
                v-model="icon_type"
                name="custom-radio"
                type="radio"
                value="truck-1.png"
                class="custom-control-input"
              >
              <span class="custom-control-label">
                <img
                  class="choose-icon"
                  src="@/assets/img/transport/truck-1.png"
                >
              </span>
            </label>
          </div>
          <div class="col-md-2">
            <label class="custom-control custom-radio">
              <input
                v-model="icon_type"
                name="custom-radio"
                type="radio"
                value="truck-2.png"
                class="custom-control-input"
              >
              <span class="custom-control-label">
                <img
                  class="choose-icon"
                  src="@/assets/img/transport/truck-2.png"
                >
              </span>
            </label>
          </div>
          <div class="col-md-2">
            <label class="custom-control custom-radio">
              <input
                v-model="icon_type"
                name="custom-radio"
                type="radio"
                value="truck-3.png"
                class="custom-control-input"
              >
              <span class="custom-control-label">
                <img
                  class="choose-icon"
                  src="@/assets/img/transport/truck-3.png"
                >
              </span>
            </label>
          </div>
          <div class="col-md-2">
            <label class="custom-control custom-radio">
              <input
                v-model="icon_type"
                name="custom-radio"
                type="radio"
                value="van.png"
                class="custom-control-input"
              >
              <span class="custom-control-label">
                <img
                  class="choose-icon"
                  src="@/assets/img/transport/van.png"
                >
              </span>
            </label>
          </div>
        </div>
      </div>
      <div class="text-center">
        <button
          type="submit"
          class="btn custom-btn-blue w-100"
        >
          Сохранить
        </button>
      </div>
    </form>
  </div>
</template>

<script>
import AutosService from "@/services/AutosService";
import {getError} from "@/utils/helpers";
import {mapGetters} from "vuex";

export default {
  name: "AutoForm",
  data() {
    return {
      auto_id: null,
      external_id: null,
      data_source:  null,
      type: null,
      gov_number: '',
      label: '',
      error: null,
      formType: 'create',
      icon_type: ''

    }
  },
  computed: {
    ...mapGetters({
      transportDataServers: 'transportDataServers/getTransportDataServers'
    })
  },
  mounted() {
    this.$store.dispatch('transportDataServers/getAllTransportDataServers');

    if (this.$route.name === 'AutosUpdate') {
      let auto = this.$store.getters['autos/getAuto'](this.$route.params.id);
      this.auto_id = this.$route.params.id;
      this.external_id = auto.attributes.external_id;
      this.data_source = auto.attributes.data_source_id;
      this.type = auto.attributes.type;
      this.gov_number = auto.attributes.gov_number;
      this.engine_param = auto.attributes.engine_param;
      this.engine_value = auto.attributes.engine_value;
      this.label = auto.attributes.label;
      this.icon_type = auto.attributes.img;
      this.formType = 'update';
    }
  },
  methods: {
    checkType() {
      if (this.formType === 'create') {
        this.createAuto();
      } else if (this.formType === 'update') {
        this.updateAuto();
      }
    },
    createAuto() {
      const payload = {
        external_id: this.external_id,
        data_source: this.data_source,
        type: Number.parseInt(this.type),
        gov_number: this.gov_number,
        label: this.label,
        img: this.icon_type
      }
      this.error = null;
      AutosService.createAuto(payload)
          .then(() => this.$router.push("/admin-autos"))
          .catch(error => {
            this.error = getError(error);
            this.$swal('Некорректные данные', this.error, 'error')
          });
    },
    updateAuto() {
      const payload = {
        external_id: this.external_id,
        data_source: this.data_source,
        type: Number.parseInt(this.type),
        gov_number: this.gov_number,
        label: this.label,
        img: this.icon_type
      }
      this.error = null;
      AutosService.updateAuto(this.auto_id, payload)
          .then(() => this.$router.push("/admin-autos"))
          .catch(error => {
            this.error = getError(error);
            this.$swal('Некорректные данные', this.error, 'error')
          });
    }
  }
}
</script>

<style scoped>
.choose-icon {
  width: 32px;
}
</style>