<template>
  <div class="custom-card">
    <div class="card-body">
      <div class="container-fluid">
        <div class="row">
          <div class="col-12">
            <form @submit.prevent="checkType">
              <div class="form-group">
                <label class="form-label">Наименование</label>
                <input
                  v-model="payload.name"
                  type="text"
                  class="form-control form-control-fix"
                  placeholder="Введите наименование СММ"
                  required
                >
              </div>
              <div class="form-group">
                <label class="form-label">Тип топлива</label>
                <v-select
                  v-model="payload.fuel_type"
                  :options="fuelTypes"
                  :reduce="type => type.id"
                  :get-option-label="(option) => option.name"
                  placeholder="Выберите тип топлива"/>

              </div>
              <div class="form-group">
                <label class="form-label">Расход топлива, л/ч</label>
                <input
                  v-model="payload.fuel_consumption"
                  type="text"
                  class="form-control form-control-fix"
                  placeholder="Введите количество литров в час"
                  required
                >
              </div>
              <div class="form-group">
                <label class="form-label">Тип масла</label>
                <v-select
                  v-model="payload.oil_type"
                  :options="oilTypes"
                  :reduce="type => type.id"
                  :get-option-label="(option) => option.name"
                  placeholder="Выберите тип масла"/>

              </div>
              <div class="form-group">
                <label class="form-label">Расход масла, л/ч</label>
                <input
                  v-model="payload.oil_consumption"
                  type="text"
                  class="form-control form-control-fix"
                  placeholder="Введите количество литров в час"
                  required
                >
              </div>
              <button class="btn custom-btn-blue w-100">Сохранить</button>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import notification from "@/mixins/notification";
import {mapGetters, mapState} from "vuex";

export default {
  name: "MechanicalEquipmentForm",
  mixins: [notification],
  data() {
    return {
      payload: {
        name: null,
        fuel_type: null,
        oil_type: null,
        fuel_consumption: null,
        oil_consumption: null
      },
      type: 'create',
      equipmentId: null
    }
  },
  computed: {
    ...mapGetters({
      mechanicalEquipment: 'mechanicalEquipments/getMechanicalEquipment'
    }),
    ...mapState({
      fuelTypes: state => state.mechanicalEquipments.fuelTypes,
      oilTypes: state => state.mechanicalEquipments.oilTypes
    })
  },
  mounted() {
    this.$store.dispatch('mechanicalEquipments/getFuelTypes');
    this.$store.dispatch('mechanicalEquipments/getOilTypes');

    if (this.$route.name === 'UpdateMechanicalEquipment') {
      this.type = 'update';
      const equipment = this.mechanicalEquipment(this.$route.params.id);
      this.payload.name = equipment.name;
      this.payload.fuel_type = equipment.fuelType.id;
      this.payload.oil_type = equipment.oilType.id;
      this.payload.fuel_consumption = equipment.fuelConsumption;
      this.payload.oil_consumption = equipment.oilConsumption;

      this.equipmentId = equipment.id;
    }
  },
  methods: {
    checkType() {
      if (this.type === 'create') {
        this.createMechanicalEquipment();
      } else {
        this.updateMechanicalEquipment();
      }
    },
    createMechanicalEquipment() {
      this.$store.dispatch('mechanicalEquipments/createMechanicalEquipment', this.payload)
          .then(() => {
            this.$router.push('/mechanical-equipments');
            this.payload.name = null;
            this.toast('Средство малой механизации добавлено', 'success');
          })
          .catch(error => this.alert('Ошибка', error, 'error'));
    },
    updateMechanicalEquipment() {
      this.$store.dispatch('mechanicalEquipments/updateMechanicalEquipment', {
        id: this.equipmentId,
        payload: this.payload
      })
          .then(() => {
            this.$router.push('/mechanical-equipments');
            this.payload.name = null;
            this.toast('Средство малой механизации изменено', 'success');
          })
          .catch(error => this.alert('Ошибка', error, 'error'));
    }
  }
}
</script>

<style scoped>

</style>