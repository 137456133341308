<template>
  <div class="row">
    <div class="col-12">
      <div class="custom-card">
        <div class="card-body">
          <div class="row">
            <div class="col-12">
              <h4 class="table-title d-inline-block">
                Объекты энергопотребления
              </h4>
              <div v-if="loading"
                   class="d-inline-block ml-2">
                <base-loader />
              </div>
              <button
                @click="$bvModal.show('energy-consumption-object-modal')"
                class="btn custom-btn-blue float-right">
                Добавить объект
              </button>
              <hr class="divider">
            </div>
            <div v-if="energyConsumptionObjects.length"
                 class="col-12">
              <energy-consumption-objects-list />
            </div>
            <div v-if="!energyConsumptionObjects.length
                   && !loading"
                 class="col-12 mt-2">
              <div class="no-result-title">Объекты энергопотребления не найдены</div>
            </div>
          </div>
        </div>
      </div>
      <energy-consumption-object-form form-type="create" />
    </div>
  </div>
</template>

<script>
import BaseLoader from "@/layout/BaseLoader";
import EnergyConsumptionObjectsList from "@/components/EnergyConsumption/admin/EnergyConsumptionObjectsList";
import {mapState} from "vuex";
import EnergyConsumptionObjectForm from "@/components/EnergyConsumption/admin/EnergyConsumptionObjectForm";
export default {
  name: "EnergyConsumptionObjectsListPage",
  components: {EnergyConsumptionObjectForm, EnergyConsumptionObjectsList, BaseLoader},
  computed: {
    ...mapState({
      energyConsumptionObjects: state => state.energyConsumptionObjects.energyConsumptionObjectsList,
      loading: state => state.energyConsumptionObjects.loading
    })
  },
  mounted() {
    this.$store.dispatch('energyConsumptionObjects/getEnergyConsumptionObjectsListWithMeters', 1);
  }
}
</script>

<style scoped>

</style>