import {getError} from "@/utils/helpers";
import WorkService from "@/services/WorkService";

export const namespaced = true

export const state = {
    works: [],
    task_works: [],
    meta: null,
    links: null,
    loading: false,
    error: null
}

export const mutations = {
    SET_WORKS(state, works) {
        state.works = works
    },
    SET_TASK_WORKS(state, works) {
        state.task_works = works;
    },
    SET_META(state, meta) {
        state.meta = meta;
    },
    SET_LINKS(state, links) {
        state.links = links;
    },
    SET_LOADING(state, loading) {
        state.loading = loading;
    },
    SET_ERROR(state, error) {
        state.error = error;
    }
}

export const actions = {
    getWorks({commit}, page) {
        commit('SET_LOADING', true);
        WorkService.getWorks(page)
            .then(({works, meta, links}) => {
                commit("SET_WORKS", works);
                commit("SET_META", meta);
                commit("SET_LINKS", links)
            })
            .catch(error => commit("SET_ERROR", getError(error)))
            .finally(() => commit("SET_LOADING", false));
    },
    getAllWorks({commit}) {
        commit("SET_LOADING", true);
        WorkService.getAllWorks()
            .then(works => commit("SET_WORKS", works))
            .catch(error => commit("SET_ERROR", error))
            .finally(() => commit("SET_LOADING", false));
    },
    createWork({commit}, payload) {
        return new Promise((resolve, reject) => {
            WorkService.createWork(payload)
                .then(() => resolve())
                .catch(error => {
                    reject(getError(error));
                    commit("SET_ERROR", getError(error));
                })
        })
    },
    updateWork({commit}, {work_id, payload}) {
        return new Promise((resolve, reject) => {
            WorkService.updateWork({work_id, payload})
                .then(() => resolve())
                .catch(error => {
                    reject(getError(error));
                    commit("SET_ERROR", getError(error));
                })
        })
    },
    deleteWork({commit}, work_id) {
        return new Promise((resolve, reject) => {
            WorkService.deleteWork(work_id)
                .then(() => resolve())
                .catch(error => {
                    reject(getError(error));
                    commit("SET_ERROR", getError(error));
                })
        })
    },
    getTaskWorks({commit}, task_id) {
        commit("SET_LOADING", true);
        WorkService.getTaskWorks(task_id)
            .then(taskWorks => commit("SET_TASK_WORKS", taskWorks))
            .catch(error => commit("SET_ERROR", error))
            .finally(() => commit("SET_LOADING", false));
    },
    addActualConsumptionInWork(context, {taskId, workId, payload}) {
        return new Promise((resolve, reject) => {
            WorkService.addActualConsumptionToWork(taskId, workId, payload)
                .then(() => resolve())
                .catch(error => reject(getError(error)))
        })
    },
    updateActualConsumptionInWork(context, {taskId, workId, payload}) {
        return new Promise((resolve, reject) => {
            WorkService.updateActualConsumptionInWork(taskId, workId, payload)
                .then(() => resolve())
                .catch(error => reject(getError(error)))
        })
    },
    deleteActualConsumptionFromWork(context, {taskId, workId, consumptionId}) {
        return new Promise((resolve, reject) => {
            WorkService.deleteActualConsumption(taskId, workId, consumptionId)
                .then(() => resolve())
                .catch(error => reject(getError(error)))
        })
    }
}

export const getters = {
    getWorks: state => {
        return state.works
    },
    getTaskWorks: state => {
        return state.task_works;
    },
    getWork: state => work_id => {
        return state.works.find(work => work.id === parseInt(work_id));
    }
}