<template>
  <div>
    <div class="form-group">
      <label class="form-label">Расходный материал</label>
      <div class="input-group">
        <v-select
          v-model="selectedConsumable"
          :options="filterConsumables"
          :reduce="consumable => consumable"
          :get-option-label="(option) => option.name"
          placeholder="Выберите материал"
        />
        <button
          @click="addActualConsumptionToList()"
          type="button"
          class="consumable__prepend_btn add input-group-prepend">+</button>
      </div>
    </div>
    <div v-for="(actualConsumption, index) in actualConsumptions"
         :key="index"
         class="form-group">
      <label class="form-label">{{actualConsumption.consumable.name}}</label>
      <div class="input-group">
        <input
          v-model="actualConsumption.amount"
          type="number"
          class="form-control">
        <button
          @click="removeActualConsumptionFromList(index)"
          type="button"
          class="consumable__prepend_btn remove input-group-prepend">-</button>
      </div>
    </div>
  </div>
</template>

<script>
import {mapState} from "vuex";

export default {
  name: "ActualConsumptionsListCreate",
  props: {
    taskWork: {
      required: true,
      type: Object
    },
    formType: {
      required: true
    },
    updateActualConsumptionsList: {
      required: true
    }
  },
  data() {
    return {
      actualConsumptions: [],
      selectedConsumable: null
    }
  },
  computed: {
    ...mapState({
      workConsumptionRates: state => state.workConsumptionRates.workConsumptionRates,
      consumables: state => state.consumables.consumables
    }),
    filterConsumables: function () {
      const consumablesLists = [...this.actualConsumptions, ...this.updateActualConsumptionsList];
      return this.consumables.filter(consumable => !consumablesLists.some(item => item.consumable.id === consumable.id))
    }
  },
  watch: {
    actualConsumptions: function (newActualConsumptionsList) {
      this.$emit('getNewActualConsumptionsList', newActualConsumptionsList)
    }
  },
  mounted() {
    this.$store.dispatch('consumables/getConsumablesList');
    if (this.formType === 'create') {
      this.$store.dispatch('workConsumptionRates/getWorkConsumptionRates', this.taskWork.workId)
          .then(() => this.createActualWorkConsumptionRatesArray());
    }
  },
  methods: {
    createActualWorkConsumptionRatesArray() {
      this.actualConsumptions = this.workConsumptionRates
          .concat()
          .map(rate => {
            return {
              amount: rate.amount,
              consumable: rate.consumable
            }
          })
    },
    addActualConsumptionToList() {
      if (this.selectedConsumable) {
        this.actualConsumptions.push({
          consumable: this.selectedConsumable,
          amount: null
        })
        this.selectedConsumable = null;
      }
    },
    removeActualConsumptionFromList(index) {
      this.actualConsumptions.splice(index, 1);
    }
  }
}
</script>

<style scoped>
.consumable__prepend_btn {
  border-radius: 0 6px 6px 0;
  outline: none;
  border: none;
  width: 30px;
  font-size: 24px;
  color: white;
  line-height: 1.4;
  display: flex;
  align-items: center;
  justify-content: center;
}

.consumable__prepend_btn.remove {
  background-color: #eb5757;
}

.consumable__prepend_btn.add {
  background-color: #468FC5;
}
</style>