import EngineeringNetworkService from "@/services/EngineeringNetworkService";
import {getError} from "@/utils/helpers";

export const namespaced = true;

export const state = {
    engineeringNetworks: [],
    meta: null,
    page: null,
    loading: false,
    error: null
}

export const mutations = {
    SET_ENGINEERING_NETWORKS(state, networks) {
        state.engineeringNetworks = networks;
    },
    SET_META(state, meta) {
        state.meta = meta;
    },
    SET_PAGE(state, page) {
        state.page = page;
    },
    SET_LOADING(state, loading) {
        state.loading = loading;
    },
    SET_ERROR(state, error) {
        state.error = error;
    }
}

export const actions = {
    getEngineeringNetworks({commit}, page) {
        commit("SET_LOADING", true);
        EngineeringNetworkService.fetchEngineeringNetworks(page)
            .then(({networks, meta}) => {
                commit("SET_ENGINEERING_NETWORKS", networks);
                commit("SET_META", meta);
            })
            .catch(error => commit("SET_ERROR", error))
            .finally(() => commit("SET_LOADING", false));
    },
    getAllEngineeringNetworks({commit}) {
        commit("SET_LOADING", true);
        EngineeringNetworkService.fetchAllEngineeringNetworks()
            .then(networks => commit("SET_ENGINEERING_NETWORKS", networks))
            .catch(error => commit("SET_ERROR", error))
            .finally(() => commit("SET_LOADING", false));
    },
    createEngineeringNetworks({commit}, payload) {
        return new Promise((resolve, reject) => {
            EngineeringNetworkService.makeEngineeringNetwork(payload)
                .then(() => resolve())
                .catch(error => {
                    reject(getError(error));
                    commit("SET_ERROR", getError(error))
                })
        })
    },
    updateEngineeringNetwork({commit}, {networkId, payload}) {
        return new Promise((resolve, reject) => {
            EngineeringNetworkService.updateEngineeringNetwork({networkId, payload})
                .then(() => resolve())
                .catch(error => {
                    reject(getError(error));
                    commit("SET_ERROR", getError(error))
                })
        })
    },
    activateEngineeringNetwork({commit}, networkId) {
        return new Promise((resolve, reject) => {
            EngineeringNetworkService.activateEngineeringNetwork(networkId)
                .then(() => resolve())
                .catch(error => {
                    reject(getError(error));
                    commit("SET_ERROR", getError(error))
                })
        })
    },
    deactivateEngineeringNetwork({commit}, networkId) {
        return new Promise((resolve, reject) => {
            EngineeringNetworkService.deactivateEngineeringNetwork(networkId)
                .then(() => resolve())
                .catch(error => {
                    reject(getError(error));
                    commit("SET_ERROR", getError(error))
                })
        })
    }
}

export const getters = {
    getEngineeringNetworks: state => {
        return state.engineeringNetworks;
    },
    getEngineeringNetwork: state => id => {
        const engineeringNetwork = state.engineeringNetworks.find(item => item.id === parseInt(id));
        if (engineeringNetwork) return engineeringNetwork;
    }
}