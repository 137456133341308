<template>
  <div class="row">
    <div class="col-12 table-responsive">
      <table class="service-desk-table">
        <thead class="service-desk-table-header">
          <tr>
            <td>Статус</td>
            <td>Задание</td>
            <td>Адрес</td>
            <td>Тип транспорта</td>
            <td>Транспорт</td>
            <td>Дата подачи</td>
            <td>Комментарий</td>
          </tr>
        </thead>
        <tbody class="service-desk-table-body">
          <tr v-for="transportOrder in transportOrders"
              :key="transportOrder.id"
              @click="showTransportOrder(transportOrder)"
              class="transport_order">
            <td>
              <base-badge
                :label="transportOrder.state.name"
                :color="transportOrder.getStateColor()" />
            </td>
            <td>{{transportOrder.task.type.name}}</td>
            <td>{{transportOrder.getFullAddress()}}</td>
            <td>{{transportOrder.transportType.name}}</td>
            <td>
              <div v-if="transportOrder.transport">
                {{transportOrder.transport.name}} {{transportOrder.transport.gov_number}}
              </div>
            </td>
            <td>{{transportOrder.deliveryAt | formatDateTime}}</td>
            <td>{{transportOrder.comment}}</td>
          </tr>
        </tbody>
      </table>
      <transport-orders-form :transport-order="selectedTransportOrder" />
    </div>
  </div>
</template>

<script>
import {mapState} from "vuex";
import BaseBadge from "@/layout/BaseBadge";
import TransportOrdersForm from "@/components/ServiceDesk/TransportOrdersForm";
import {showToast} from "@/utils/notification";

export default {
  name: "TransportOrdersList",
  components: {BaseBadge, TransportOrdersForm},
  data() {
    return {
      selectedTransportOrder: null
    }
  },
  computed: {
    ...mapState({
      transportOrders: state => state.transportOrders.transportOrders
    })
  },
  methods: {
    showTransportOrder(transportOrder) {
      if (transportOrder.state.id === 1) {
        this.selectedTransportOrder = transportOrder;
        this.$bvModal.show('transport-order-modal');
      } else {
        showToast('Заказ транспорта не в статусе "Новый"')
      }
    }
  }
}
</script>

<style scoped>

</style>