import WorkShiftService from "@/services/WorkShiftService";

export const namespaced = true;

export const state = {
    workShifts: [],
    filterParams: null,
    loading: false,
    error: null
}

export const mutations = {
    SET_WORK_SHIFTS(state, workShifts) {
        state.workShifts = workShifts;
    },
    SET_FILTER_PARAMS(state, params) {
        state.filterParams = params;
    },
    SET_LOADING(state, loading) {
        state.loading = loading;
    },
    SET_ERROR(state, error) {
        state.error = error;
    }
}

export const actions = {
    getWorkShifts({commit}, params) {
        commit("SET_LOADING", true);
        WorkShiftService.getWorkShift(params)
            .then(workShifts => commit("SET_WORK_SHIFTS", workShifts))
            .catch(error => commit("SET_ERROR", error))
            .finally(() => commit("SET_LOADING", false));
    },
    createWorkShift({commit}, payload) {
        return new Promise((resolve, reject) => {
            WorkShiftService.createWorkShiftItem(payload)
                .then(() => resolve())
                .catch(error => {
                    commit("SET_ERROR", error);
                    reject(error);
                })
        })
    }
}

export const getters = {
    getWorkShifts: state => {
        return state.workShifts;
    },
    getFilterParams: state => {
        return state.filterParams;
    }
}