<template>
  <b-modal
    id="parameter-form"
    ref="parameter-form"
    centered
    hide-header
    hide-footer
    @shown="checkType()"
    @hidden="clearFields()"
  >
    <div class="col-12">
      <div class="custom-modal-header">
        <div class="custom-modal-title">
          Параметры
        </div>
        <img
          alt="Закрыть"
          class="custom-modal-close"
          src="@/assets/img/icons/close.svg"
          @click="$bvModal.hide('parameter-form')"
        >
      </div>
      <div class="modal-divider"/>
    </div>
    <form @submit.prevent="submitForm()">
      <div class="col-12 mb-2">
        <div class="form-group">
          <label class="form-label">Наименование параметра</label>
          <input
            v-model="payload.name"
            type="text"
            class="form-control form-control-fix"
            placeholder="Наименование"
            required
          >
        </div>
        <div class="form-group">
          <label class="form-label">Краткое наименование параметра</label>
          <input
            v-model="payload.short_name"
            type="text"
            class="form-control form-control-fix"
            placeholder="Краткое наименование"
            required
          >
        </div>
        <div class="form-group">
          <label class="form-label">Единицы измерения</label>
          <input
            v-model="payload.measure"
            type="text"
            class="form-control form-control-fix"
            placeholder="Единицы измерения"
            required
          >
        </div>
        <div class="form-group">
          <label class="form-label">Минимальное значение</label>
          <input
            v-model.number="payload.min_value"
            type="text"
            class="form-control form-control-fix"
            placeholder="Минимальная величина"
            required
          >
        </div>
        <div class="form-group">
          <label class="form-label">Максимальное значение</label>
          <input
            v-model.number="payload.max_value"
            type="text"
            class="form-control form-control-fix"
            placeholder="Максимальная величина"
            required
          >
        </div>
        <div class="modal-divider"/>
        <button
          type="submit"
          class="btn custom-btn-blue w-100">
          Сохранить
        </button>
      </div>
    </form>
  </b-modal>
</template>

<script>
import {mapState} from "vuex";
import {showAlert, showToast} from "@/utils/notification";

export default {
  name: "EquipmentParametersForm",
  data() {
    return {
      payload: {
        name: null,
        short_name: null,
        measure: null,
        min_value: null,
        max_value: null
      },
      formType: 'create'
    }
  },
  computed: {
    ...mapState({
      networkEquipment: state => state.networkEquipments.networkEquipment,
      currentParameter: state => state.networkObjectPage.currentEquipmentParameter,
      equipmentId: state => state.networkObjectPage.equipmentId
    })
  },
  methods: {
    checkType() {
      if (this.currentParameter) {
        this.formType = 'update';

        this.payload.name = this.currentParameter.name;
        this.payload.short_name = this.currentParameter.shortName;
        this.payload.measure = this.currentParameter.measure;
        this.payload.min_value = this.currentParameter.minValue;
        this.payload.max_value = this.currentParameter.maxValue;
      } else this.formType = 'create';
    },
    submitForm() {
      if (this.formType === 'update') this.updateParameter()
      else this.createParameter()
    },
    clearFields() {
      this.payload = {
        ame: null,
        short_name: null,
        measure: null,
        min_value: null,
        max_value: null
      }
    },
    createParameter() {
      this.$store.dispatch('networkEquipmentParameters/createNetworkEquipmentParameter', {
        equipmentId: this.equipmentId,
        payload: this.payload
      })
          .then(() => {
            this.$bvModal.hide('parameter-form');
            showToast('Параметр добавлен', 'success');
            this.$store.dispatch('networkEquipments/getNetworkEquipments', {
              object_id: this.$route.params.id
            });
          })
          .catch(error => showAlert('Ошибка', error))
    },
    updateParameter() {
      this.$store.dispatch('networkEquipmentParameters/updateNetworkEquipmentParameter', {
        equipmentId: this.equipmentId,
        parameterId: this.currentParameter.id,
        payload: this.payload
      })
          .then(() => {
            this.$bvModal.hide('parameter-form');
            showToast('Параметр обновлён', 'success');
            this.$store.dispatch('networkEquipments/getNetworkEquipments', {
              object_id: this.$route.params.id
            });
          })
          .catch(error => showAlert('Ошибка', error))
    }
  }
}
</script>

<style scoped>

</style>