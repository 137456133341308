<template>
  <table class="admin-table">
    <thead class="admin-table-header">
      <tr>
        <td>Наименование</td>
        <td>Разрешения</td>
        <td>Действия</td>
      </tr>
    </thead>
    <tbody class="admin-table-body">
      <tr
        v-for="role in this.$store.state.role.roles"
        :key="role.id"
        class="role-item"
      >
        <td>{{ role.name }}</td>
        <td>{{ role.permissions
          .map(item => item.attributes.custom_name ? item.attributes.custom_name : item.attributes.name).join(', ') }}
        </td>
        <td class="table-action">
          <edit-icon @click="getRole(role.id)"
                     class="cursor-pointer mr-1" />
          <a @click="deleteRole(role.id)">
            <trash2-icon />
          </a>
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
import RoleService from "@/services/RoleService";

export default {
  name: "RolesList",
  methods:{
    getRole(id) {
      this.$store.dispatch('role/getRole', id)
          .then(() => this.$router.push(`/admin-roles-update/${id}`))
    },
    async deleteRole(role_id){
      this.$swal({
        title: 'Вы действительно хотите удалить роль?',
        showCancelButton: true,
        confirmButtonText: `Да`,
        cancelButtonText: 'Отмена',
        icon: 'warning'
      }).then(result=>{
        if (result.isConfirmed) {
          RoleService.deleteRole(role_id).then(() => {
            this.$swal('Роль успешно удалена!', '', 'success')
            this.$store.commit('role/DELETE_ROLE', role_id);
          })
        }
      });
    }
  }
}
</script>

<style scoped>

</style>