<template>
  <b-tab
    title="Результат"
    title-item-class="custom-nav-item"
    title-link-class="custom-nav-link"
  >
    <h4 class="billing-tab-title mb-20">
      Результат
    </h4>

    <div class="loader-position"
         v-if="loading">
      <base-loader width="1rem !important"
                   height="1rem !important"
      />
    </div>

    <div
      v-if="this.$store.state.client.clients.length === 0"
      class="row"
    >
      <div class="col-12">
        <div class="no-result-title">
          Абоненты не найдены.
        </div>
        <div class="no-result-text">
          Попробуйте упросить параметры фильтрации.
        </div>
      </div>
    </div>

    <div
      v-if="storeStatus"
      id="filter-result"
      class="row"
    >
      <div
        v-for="client_group in this.$store.state.client.clients"
        :key="client_group.id"
        class="col-12 d-flex justify-content-end mb-3 client-group-item"
      >
        <div class="result-item">
          <div class="address-wrapper">
            <div class="result-address">
              {{ getFullAddress(client_group) }}
            </div>
            <img
              :id="`${client_group.id}_icon`"
              v-b-toggle="`${client_group.id}`"
              alt="Раскрыть"
              class="collapse-arrow"
              src="@/assets/img/billing/arrow-right.svg"
            >
            <div class="result-map"
                 @click="$store.commit('baseMap/SET_CENTER_ZOOM',
                                       {center: client_group.attributes.geometry.coordinates, zoom: 17})">
              <img
                alt="Показать на карте"
                src="@/assets/img/billing/map.svg"
              >
            </div>
          </div>
          <b-collapse
            :id="`${client_group.id}`"
            visible
          >
            <div
              v-if="client_group.attributes.consumers.filter(
                client => client.attributes.client_type === 1).length !== 0"
            >
              <div class="collapse-block-title">
                Физические лица:
              </div>
              <ol type="1">
                <li
                  v-for="consumer in client_group.attributes.consumers.filter(
                    consumer => consumer.attributes.client_type === 1)"
                  :key="consumer.id"
                  class="collapse-list-item"
                  @click="getClientDetail(consumer.id)"
                >
                  №{{ consumer.attributes.ls }} от {{ $moment(consumer.attributes.ls_date).format('DD-MM-YYYY') }}
                  {{ describeLsStatus(consumer.attributes.status) }} <br>
                  {{ consumer.attributes.client_name }} <br>
                  <span
                    v-if="consumer.attributes.saldo < 0"
                    class="text-danger"
                  >
                    Задолженность: {{ Math.abs(consumer.attributes.saldo) }}</span>
                </li>
              </ol>
            </div>

            <div
              v-if="client_group.attributes.consumers.filter(consumer =>
                consumer.attributes.client_type === 2).length !== 0"
            >
              <div class="collapse-block-title">
                Юридические лица:
              </div>
              <ol type="1">
                <li
                  v-for="consumer in client_group.attributes.consumers.filter(
                    consumer => consumer.attributes.client_type === 2)"
                  :key="consumer.id"
                  class="collapse-list-item"
                  @click="getClientDetail(consumer.id)"
                >
                  №{{ consumer.attributes.ls }} от {{ $moment(consumer.attributes.ls_date).format('DD-MM-YYYY') }}
                  {{ describeLsStatus(consumer.attributes.status) }} <br>
                  {{consumer.attributes.client_name}} <br>
                  <span
                    v-if="consumer.attributes.saldo < 0"
                    class="text-danger"
                  >
                    Задолженность: {{ Math.abs(consumer.attributes.saldo) }}</span>
                </li>
              </ol>
            </div>
          </b-collapse>
        </div>
      </div>
    </div>

    <base-pagination-payload
      v-if="this.$store.state.client.meta && this.$store.state.client.meta.last_page > 1"
      :links="this.$store.state.client.links"
      :meta="this.$store.state.client.meta"
      :payload="generatePaginationPayload(this.filter_params)"
      action="client/paginateClients"
      path="billing-clients"
    />
  </b-tab>
</template>

<script>
import BasePaginationPayload from "@/components/BasePaginationPayload";
import {mapGetters} from "vuex";
import {getError} from "@/utils/helpers";
import notification from "@/mixins/notification";
import BaseLoader from "@/layout/BaseLoader";

export default {
  name: "BillingClientsFilterResult",
  components: {BasePaginationPayload, BaseLoader},
  mixins: [notification],
  props: ['filter_params'],
  computed: {
    ...mapGetters({
      storeStatus: 'client/getStoreStatus',
      loading: 'client/getClientLoading'
    }),
    generatePaginationPayload: function () {
      return filter_params => {
        return {
          data: filter_params,
          link: 0
        }
      }
    },
    describeLsStatus: function () {
      return i => {
        switch (i) {
          case 1:
            return 'Открыт'
          case 2:
            return 'Закрыт'
          case 3:
            return 'Не активен'
        }
      }
    },
    getFullAddress: function () {
      return building => {
        let full_address_arr = [building.attributes.city, building.attributes.settlement, building.attributes.street,
          building.attributes.house, building.attributes.block].filter(item => item !== null)
        return full_address_arr.join(', ')
      }
    }
  },
  mounted() {
    this.$root.$on("bv::collapse::state", (collapseID, opened) => {
      let arrow_element = document.getElementById(collapseID + '_icon')
      if (opened) arrow_element.style.transform = 'rotateZ(90deg)'
      else arrow_element.style.transform = 'rotate(0deg)'
    })
  },
  methods: {
    getClientDetail(id) {
      this.$store.dispatch('client/getClient', id)
          .then(() => {
            this.$store.commit("client/SET_TAB_INDEX", 2);
          })
          .catch(() => {
            this.alert('Ошибка', getError(this.$store.state.client.error), 'error')
          })
    }
  }
}
</script>

<style scoped>
.result-item {
  width: 100%;
  padding: 10px 15px;
  background-color: white;
  border: 1px solid #468FC5;
  border-radius: 6px;
  margin-right: 45px;
}

.address-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.result-address {
  display: flex;
  color: #262F3D !important;
  font-weight: 600;
  cursor: pointer;
}

.result-map {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  background-color: #EBF1F3;
  border-radius: 6px;
  position: absolute;
  right: 10px;
  cursor: pointer;
}

.result-map img {
  width: 16px;
}

.address-wrapper img {
  transition: .2s;
}

.collapse-block-title {
  color: #97ACB5;
  font-weight: 700;
  margin-top: 20px;
}

.collapse-list-item {
  color: #677385;
  font-weight: 400;
  cursor: pointer;
  margin-bottom: 8px;
}

.loader-position {
  position: absolute;
  right: 20px;
  top: 50px;
}

.collapse-list-item {
  color: #677385;
  transition: .3s;
}

.collapse-list-item:hover {
  color: #468FC5;
  transition: .5s;
}
</style>