<template>
  <div class="row">
    <div class="col-12">
      <button @click="$bvModal.show('tasks-filter')"
              class="btn custom-btn-blue-outline">Фильтр</button>
    </div>

    <b-modal
      id="tasks-filter"
      ref="tasks-filter"
      centered
      hide-footer
      hide-header
    >
      <div class="col-12">
        <div class="custom-modal-header">
          <div class="custom-modal-title">
            Фильтр заданий
          </div>
          <img
            class="custom-modal-close"
            src="@/assets/img/icons/close.svg"
            @click="$bvModal.hide('tasks-filter')"
          >
        </div>
        <div class="modal-divider"/>
      </div>
      <div class="col-12 mb-2">
        <div class="row">
          <div class="col-6 mb-3">
            <label class="form-check-label">Дата создания</label>
            <date-picker
              v-model="filter.date"
              :default-value="defaultValue"
              input-class="form-control-fix w-100 pl-2"
              placeholder="Выберите дату"
              value-type="YYYY-MM-DD"
            />
          </div>
          <div class="col-6 mb-3">
            <label class="form-check-label">Тип задания</label>
            <v-select
              v-model="filter.type"
              :options="this.$store.state.task_types.task_types"
              :reduce="task_type => task_type.id"
              :get-option-label="(option) => option.name"
              placeholder="Выберите тип задания"
            />
          </div>
          <div class="col-6 mb-3">
            <label class="form-check-label">Статус</label>
            <v-select
              v-model="filter.state"
              :options="stateOptions"
              :reduce="state => state.code"
              :get-option-label="(option) => option.name"
              placeholder="Выберите статус"
            />
          </div>
        </div>
        <div class="modal-divider"/>
        <div class="row">
          <div class="col-12">
            <button
              class="btn custom-btn-blue-outline w-100 mb-2"
              type="submit"
              @click="clearFilter()"
            >
              Сбросить фильтр
            </button>
            <button
              class="btn custom-btn-blue w-100"
              type="submit"
              @click="submitFilter()"
            >
              Применить фильтр
            </button>
          </div>
        </div>
      </div>
    </b-modal>

  </div>
</template>

<script>
import DatePicker from "vue2-datepicker";
import TaskState from "@/models/serviceDesk/TaskState";

export default {
  name: "TasksListFilter",
  components: {DatePicker},
  data() {
    return {
      filter: {
        date: null,
        type: null,
        state: null,
        executor: null
      },
      defaultValue: new Date(),
      stateOptions: [
        {code: TaskState.NEW, name: 'Новое'},
        {code: TaskState.PLANNED, name: 'Запланировано'},
        {code: TaskState.IN_WORK, name: 'В работе'},
        {code: TaskState.CANCELED, name: 'Отменено'},
        {code: TaskState.CLOSED, name: 'Закрыто'},
        {code: TaskState.SUSPENDED, name: 'Приостановлено'}
      ]
    }
  },
  mounted() {
    this.$store.dispatch('task_types/getAllTaskTypes');
  },
  methods: {
    submitFilter() {
      this.$store.dispatch('tasks/getTasks', {page: 1, params: this.filter});
      this.$store.commit('tasks/SET_FILTER_PARAMS', this.filter);
      this.$bvModal.hide('tasks-filter');
    },
    clearFilter() {
      this.$store.dispatch('tasks/getTasks', {page: 1, params: null});
      this.filter = {
        date: null,
        type: null,
        state: null,
        executor: null
      }
      this.$store.commit('tasks/SET_FILTER_PARAMS', null);
      this.$bvModal.hide('tasks-filter');
    }
  }
}
</script>

<style scoped>

</style>