<template>
  <form @submit.prevent="submitForm()">
    <div class="form-group">
      <v-select
        v-model="role_id"
        :options="roles"
        :reduce="role => role.id"
        :get-option-label="(option) => option.name"
        placeholder="Выберите роль"
      />
    </div>
    <button class="btn custom-btn-blue w-100">Добавить роль</button>
  </form>
</template>

<script>
import {mapState} from "vuex";
import {showToast, showAlert} from "@/utils/notification";

export default {
  name: "UserChangeRoleForm",
  data() {
    return {
      role_id: null
    }
  },
  computed: {
    ...mapState({
      roles: state => state.role.roles,
      user: state => state.user.user
    })
  },
  mounted() {
    this.$store.dispatch('role/getAllRoles');
  },
  methods: {
    submitForm() {
      this.$store.dispatch('user/assignRole', {
        user_id: this.user.id,
        role_id: this.role_id
      })
          .then(() => {
            showToast('Роль добавлена', 'success');
            this.$store.dispatch('user/getUser', this.user.id);
            this.role_id = null;
          })
          .catch(error => showAlert('Ошибка', error));
    }
  }
}
</script>

<style scoped>

</style>