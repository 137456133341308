import ConsumablesService from "@/services/ConsumablesService";
import {getError} from "@/utils/helpers";

export const namespaced = true;

export const state = {
    consumables: [],
    consumable: null,
    error: null,
    loading: false
}

export const mutations = {
    SET_CONSUMABLES_LIST(state, consumables) {
        state.consumables = consumables;
    },
    SET_CONSUMABLE(state, consumable) {
        state.consumable = consumable;
    },
    SET_ERROR(state, error) {
        state.error = error;
    },
    SET_LOADING(state, loading) {
        state.loading = loading;
    },
    DELETE_CONSUMABLE(state, consumableId) {
        const index = state.consumables.findIndex(consumable => consumable.id === consumableId);
        state.consumables.splice(index, 1);
    }
}

export const actions = {
    getConsumablesList({commit}) {
        commit("SET_LOADING", true);
        ConsumablesService.fetchConsumablesList()
            .then(consumables => commit("SET_CONSUMABLES_LIST", consumables))
            .catch(error => commit("SET_ERROR", error))
            .finally(() => commit("SET_LOADING", false));
    },
    getConsumable({commit}, consumableId) {
        return new Promise((resolve, reject) => {
            ConsumablesService.fetchConsumable(consumableId)
                .then(consumable => {
                    commit("SET_CONSUMABLE", consumable);
                    resolve()
                })
                .catch(error => reject(error))
        })
    },
    createConsumable(context, payload) {
        return new Promise((resolve, reject) => {
            ConsumablesService.createConsumable(payload)
                .then(() => resolve())
                .catch(error => reject(getError(error)))
        })
    },
    updateConsumable(context, {consumableId, payload}) {
        return new Promise((resolve, reject) => {
            ConsumablesService.updateConsumable(consumableId, payload)
                .then(() => resolve())
                .catch(error => reject(getError(error)))
        })
    },
    deleteConsumable(context, consumableId) {
        return new Promise((resolve, reject) => {
            ConsumablesService.deleteConsumable(consumableId)
                .then(() => resolve())
                .catch(error => reject(getError(error)))
        })
    }
}