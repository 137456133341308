<template>
  <div v-if="reportData.length"
       class="custom-card">
    <div class="card-body">
      <div class="col-12">
        <table class="report-table table-responsive">
          <thead class="report-table-header">
            <tr>
              <td/>
              <td colspan="2">Топливо</td>
              <td colspan="2">Масло</td>
            </tr>
          </thead>
          <tbody class="report-table-body">
            <tr>
              <td/>
              <td>Наименование</td>
              <td>Кол-во</td>
              <td>Наименование</td>
              <td>Кол-во</td>
            </tr>
            <template v-for="item in reportData">
              <tr v-for="index in Array.apply(null, Array(getLongestArrayLength(item.consumptions)))
                    .map(function (_, i) {return i;})"
                  :key="index">
                <td
                  v-if="index === 0"
                  :rowspan="getLongestArrayLength(item.consumptions)">
                  Заявка #{{item.issue_id}}
                </td>

                <td>{{ getArrayPropertyByIndex(item.consumptions.fuel, index, fuel => fuel.type.attributes.name) }}</td>
                <td>{{ getArrayPropertyByIndex(item.consumptions.fuel, index, fuel => fuel.amount) }}</td>

                <td>{{ getArrayPropertyByIndex(item.consumptions.oil, index, oil => oil.type.attributes.name) }}</td>
                <td>{{ getArrayPropertyByIndex(item.consumptions.oil, index, oil => oil.amount) }}</td>
              </tr>
            </template>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import {mapState} from "vuex";

export default {
  name: "FuelAndLubricantsReport",
  computed: {
    ...mapState({
      reportData: state => state.reportsData.reportData
    })
  },
  methods: {
    getLongestArrayLength(consumptions) {
      return Math.max(consumptions.fuel.length, consumptions.oil.length);
    },
    getArrayPropertyByIndex(array, index, keyGetter) {
      const arrayItem = array[index];
      if (arrayItem) return keyGetter(arrayItem)
    }
  }
}
</script>

<style scoped>
.report-table {
  border-collapse: separate;
  border-spacing: 0;
  width: 100%;
  text-align: center
}

.report-table-header td {
  border-left: 1px solid #468FC5;
  border-top: 1px solid #468FC5;
  background-color: #262F3D;
  color: white;
  font-weight: 700;
  font-size: 16px;
  padding: 8px;
}

.report-table-header td:last-child {
  border-right: 1px solid #468FC5;
  border-radius: 0 10px 0 0;
}

.report-table-header td:first-child {
  border-radius: 10px 0 0 0;
}

.report-table-body tr td {
  border-left: 1px solid #468FC5;
  border-top: 1px solid #468FC5;
  color: #262F3D;
  padding: 8px;
}

.report-table-body tr td:last-child {
  border-right: 1px solid #468FC5;
}

.report-table-body tr:last-child td {
  border-bottom: 1px solid #468FC5;
}

.report-table-body tr:last-child td:first-child {
  border-radius: 0 0 0 10px;
}

.report-table-body tr:last-child td:last-child {
  border-radius: 0 0 10px 0;
}
</style>