<template>
  <div class="row">
    <div class="col-12 mb-2">
      <div class="custom-card">
        <div class="card-body d-flex justify-content-between align-items-center">
          <div class="task-card-title mb-0 d-flex align-items-center">
            Аварийные элементы
            <div class="items-count">{{ emergencyElements.length }}</div>
          </div>
          <div class="task_component__actions_wrapper">
            <div
              v-if="showAddBtn && task.task.state.id !== TASK_STATE.CLOSED
                && task.task.state.id !== TASK_STATE.CANCELED"
              class="task_component__action">
              <plus-icon
                @click="$bvModal.show('add-emergency-element')"
                class="chevron" />
            </div>
            <div
              v-if="emergencyElements.length"
              class="task_component__action">
              <chevron-right-icon
                @click="collapseVisible = !collapseVisible"
                class="chevron"
                :class="{'rotate': collapseVisible}" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <b-collapse
      v-model="collapseVisible"
      class="col-12">
      <div v-for="element in emergencyElements"
           :key="element.id"
           class="custom-card mb-2 emergency-element">
        <div class="card-body">
          <div class="d-flex justify-content-between elements-list-title">
            <div class="d-flex align-items-center mb-1">
              {{ element.element_type.name }} #{{ element.id }}
            </div>
            <edit-icon
              v-if="showEditBtn && element.features.length"
              @click="updateElement(element.id)"
              class="cursor-pointer" />
          </div>
          <div class="task_component__param">
            Сеть: <span>{{ element.network.name }}</span>
          </div>
          <div v-for="feature in element.features.filter(item => item.value !== null)"
               :key="feature.id"
               class="task_component__param">
            {{ feature.feature.name }}:
            <span>{{ feature.value }}{{ feature.feature.unit }}</span>
          </div>
        </div>
      </div>
    </b-collapse>

    <add-emergency-element />
    <update-emergency-element :element="element" />
  </div>
</template>

<script>
import {mapGetters, mapState} from "vuex";
import UpdateEmergencyElement from "@/components/ServiceDesk/Tasks/ActionsForms/UpdateEmergencyElement";
import AddEmergencyElement from "@/components/ServiceDesk/Tasks/ActionsForms/AddEmergencyElement";
import TaskState from "@/models/serviceDesk/TaskState";

export default {
  name: "EmergencyElementsList",
  components: {AddEmergencyElement, UpdateEmergencyElement},
  props: {
    showAddBtn: {
      default: false,
      type: Boolean
    },
    showEditBtn: {
      default: false,
      type: Boolean
    }
  },
  data() {
    return {
      element: null,
      collapseVisible: false
    }
  },
  computed: {
    ...mapGetters({
      emergencyElements: 'emergencyElements/getEmergencyElements'
    }),
    ...mapState({
      task: state => state.task.task
    }),
    TASK_STATE: () => TaskState
  },
  mounted() {
    this.$root.$on("bv::collapse::state", (collapseID, opened) => {
      let arrow_element = document.getElementById(collapseID + '_icon')
      if (opened) arrow_element.style.transform = 'rotateZ(180deg)'
      else arrow_element.style.transform = 'rotate(0deg)'
    })
  },
  methods: {
    updateElement(id) {
      this.element = this.emergencyElements.find(element => element.id === id);
      this.$bvModal.show('update-emergency-element');
    }
  }
}
</script>

<style scoped>
.elements-list {
  list-style: disc;
  padding-left: 18px;
  color: #468FC5;
}

.elements-list-title {
  font-size: 16px;
  font-weight: 600;
  color: #262F3D;
}

.feature-name {
  font-size: 14px;
  font-weight: 400;
  color: #262F3D;
  margin-right: 3px;
}

.feature-value {
  font-size: 16px;
  font-weight: 400;
  color: #468FC5;
}

.collapse-chevron {
  transition: .4s;
}
</style>