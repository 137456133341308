<template>
  <div v-if="appealsStatistic.length"
       class="custom-card">
    <div class="card-body">
      <div class="col-12">
        <table class="table table-bordered">
          <tbody v-for="group in categoryGroups"
                 :key="group.id">
            <tr v-if="appealsStatistic.filter(item => item.group.id === group.id).length">
              <td class="group-name"
                  colspan="2">{{ group.name }}
              </td>
            </tr>
            <tr v-for="statItem in appealsStatistic.filter(item => item.group.id === group.id)"
                :key="statItem.category.id"
                class="group-categories">
              <td>{{ statItem.category.name }}</td>
              <td class="text-center">{{ statItem.count }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import {mapState} from "vuex";

export default {
  name: "AppealsStatisticReport",
  computed: {
    ...mapState({
      categoryGroups: state => state.serviceDeskCategories.categoryGroups,
      appealsStatistic: state => state.reportsData.reportData
    })
  }
}
</script>

<style scoped>
table td {
  border-color: #468FC5;
}

table tbody + tbody {
  border-top: 1px solid #468FC5;
}

.group-name {
  text-align: center;
  font-weight: 600;
  font-size: 16px;
  color: #262F3D;
}

.group-categories {
  color: #262F3D;
}
</style>