<template>
  <div>
    <div class="row">
      <div class="col-md-12 col-xs-12">
        <div class="custom-card mb-3">
          <div class="card-header">
            <h5 class="card-title mb-0">
              Выбор параметров
            </h5>
          </div>
          <div class="card-body">
            <form
              id="archive-form"
              @submit.prevent="submitFilter"
            >
              <div id="objects">
                <div class="form-row">
                  <div class="col-md-4">
                    <div class="form-group">
                      <label>Оборудование</label>
                      <v-select
                        v-model="networkEquipmentId"
                        placeholde="Выберите устройство"
                        :options="networkEquipments"
                        :reduce="device => device.id"
                        :get-option-label="(option) => option.name"
                        placeholder="Выберите устройство"
                      />
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="form-group">
                      <label>Детализация по</label>
                      <select
                        id="groupType"
                        v-model="group"
                        class="form-control form-control-fix"
                        name="groupType"
                        required
                      >
                        <option value="hour">
                          Часам
                        </option>
                        <option value="day">
                          Дням
                        </option>
                        <option value="month">
                          Месяцам
                        </option>
                        <option value="quarter">
                          Кварталам
                        </option>
                        <option value="year">
                          Годам
                        </option>
                      </select>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="form-group mb-xl-0">
                      <label class="form-label">Период</label>
                      <date-picker
                        v-model="range"
                        :default-value="defaultValue"
                        :type="typeDataInput"
                        input-class="form-control-fix w-100 pl-3"
                        placeholder="Выберите период"
                        range
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div class="form-row">
                <div class="col-md-12 form__buttons">
                  <button class="btn custom-btn-blue">
                    Сформировать
                  </button>
                  <button
                    class="btn custom-btn-blue-outline d-flex align-items-center"
                    type="button"
                    @click="resetFilter"
                  >
                    <refresh-ccw-icon class="mr-2" />
                    Сбросить
                  </button>
                  <base-loader v-if="loading" />
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>

    <div
      v-if="parametersValues.length"
      class="row"
    >
      <div class="col-12">
        <div class="custom-card mb-3">
          <div class="card-header">
            <h5 class="card-title d-inline-block" />
            <button
              class="btn custom-btn-blue excel-btn"
              type="button"
              @click="this.getExcel"
            >
              Сохранить таблицу
              в Excel
            </button>
          </div>
          <div class="card-body">
            <table
              id="archive-table"
              class="table"
              data-cols-width="19"
            >
              <thead>
                <tr class="header_one">
                  <td/>
                  <td :colspan="networkEquipmentParameters(networkEquipmentId).length">
                    {{ networkEquipmentName(networkEquipmentId) }}
                  </td>
                </tr>
                <tr class="header_two">
                  <td data-b-a-s="thin">
                    Время
                  </td>
                  <td
                    v-for="parameter in networkEquipmentParameters(networkEquipmentId)"
                    :key="parameter.id"
                    data-a-h="center"
                    data-b-a-s="thin"
                  >
                    {{ parameter.name }}
                  </td>
                </tr>
              </thead>
              <tbody class="archive-table-body">
                <tr
                  v-for="(dateLabel, index) in this.hours_array"
                  :key="`table_label`+index"
                >
                  <td data-b-a-s="thin">
                    {{ dateLabel }}
                  </td>
                  <td
                    v-for="parameter in networkEquipmentParameters(networkEquipmentId)"
                    :key="`value_table_`+parameter.id"
                    :data-t="getParameterValue(dateLabel, parameter.id) === '-' ? 's' : 'n'"
                    data-b-a-s="thin"
                    data-a-h="center"
                  >
                    {{ getParameterValue(dateLabel, parameter.id) }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>

    <div
      v-if="parametersValues.length"
      class="row"
    >
      <div v-for="chartItem in createParametersChartData()"
           :key="chartItem.id"
           v-show="chartItem.options.xaxis.categories.length"
           class="col-md-6 col-xs-12 mb-3">
        <div class="custom-card">
          <div class="card-header">
            <h5 class="card-title mb-0">
              {{chartItem.parameterName}}
            </h5>
          </div>
          <div class="card-body">
            <div class="chart w-100">
              <div id="apexcharts-line">
                <apexchart
                  :options="chartItem.options"
                  :series="chartItem.series"
                  height="400"
                  type="line"
                  width="100%"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import {mapGetters, mapState} from "vuex";
import TableToExcel from "@linways/table-to-excel";
import VueApexCharts from "vue-apexcharts";
import ArchiveJournalService from "@/services/ArchiveJournalService";
import BaseLoader from "@/layout/BaseLoader";

export default {
  name: "ArchiveJournalPage",
  components: {
    BaseLoader,
    DatePicker,
    'apexchart': VueApexCharts
  },
  data() {
    return {
      networkEquipmentId: null,
      range: [],
      group: 'hour',
      hours_array: null,
      chartSensors: [],
      defaultValue: new Date()
    }
  },
  computed: {
    typeDataInput: function () {
      let type = 'date';
      switch (this.group) {
        case 'hour':
          type = 'date';
          break;
        case 'day':
          type = 'date';
          break;
        case 'month':
          type = 'month';
          break;
        case 'quarter':
          type = 'year';
          break;
        case 'year':
          type = 'year';
          break;
      }
      return type;
    },
    ...mapGetters({
      networkEquipmentName: 'networkEquipments/getNetworkEquipmentName',
      networkEquipmentParameters: 'networkEquipments/getNetworkEquipmentParameters'
    }),
    ...mapState({
      networkEquipments: state => state.networkEquipments.networkEquipments,
      parametersValues: state => state.archivePage.parametersValues,
      loading: state => state.archivePage.loading
    })
  },
  created() {
    this.fetchData();
  },
  beforeDestroy() {
    this.$store.commit("archivePage/SET_PARAMETERS_VALUES", []);
  },
  methods: {
    async getExcel() {
      let wb = TableToExcel.convert(document.getElementById("archive-table"), {
        name: "Отчет по телеметрии.xlsx",
        sheet: {
          name: "Отчет"
        }
      });
      ArchiveJournalService.getExcel(wb)
    },
    resetFilter() {
      this.range = [];
      this.group = 'hour';
      this.hours_array = null;
      this.networkEquipmentId = null;

      this.$store.commit('archivePage/SET_PARAMETERS_VALUES', []);
    },
    fetchData() {
      this.$store.dispatch('networkEquipments/getNetworkEquipments');
    },
    submitFilter() {
      const [startDate, endDate] = this.range;
      endDate.setHours(23)
      endDate.setMinutes(59)

      const params = {
        start_period: startDate.toLocaleString(),
        end_period: endDate.toLocaleString()
      }

      this.$store.dispatch('archivePage/getNetworkEquipmentArchive', {
        networkEquipmentId: this.networkEquipmentId,
        params: params,
        routeGroup: this.group
      })
          .then(() => {
            this.hours_array = Array.from(new Set(this.parametersValues.map(item => item.label)));
          })
    },
    getParameterValue(dateLabel, parameterId) {
      let item = this.parametersValues.find(item =>
          item.parameterId === parameterId
          && item.label === dateLabel
      );

      if (item) {
        return parseFloat(item.value).toFixed(2);
      } else {
        return '-';
      }
    },
    createParametersChartData: function () {
      return this.networkEquipmentParameters(this.networkEquipmentId)
          .map(parameter => {
            return {
              id: parameter.id,
              parameterName: parameter.name,
              options: {
                chart: {
                  type: 'line',
                  height: 400,
                  zoom: {
                    enabled: false
                  }
                },
                dataLabels: {
                  enabled: false
                },
                stroke: {
                  curve: 'straight'
                },
                xaxis: {
                  categories: this.parametersValues
                      .filter(item => item.parameterId === parameter.id)
                      .map(item => item.label),
                  labels: {
                    show: true,
                    rotateAlways: false
                  }
                }
              },
              series: [
                {
                  name: parameter.name,
                  data: this.parametersValues
                      .filter(item => item.parameterId === parameter.id)
                      .map(item => item.value)
                }
              ]
            }
          })
    }
  }
}
</script>

<style>
.mx-datepicker {
  position: relative;
  display: block !important;
  width: auto;
}

.active_chart_icon {
  color: #23ff45;
}

#archive-table {
  width: 100%;
  text-align: center
}

table {
  border-collapse: separate;
  border-spacing: 0;
}

.header_one td {
  border-right: 1px solid #468FC5;
  border-top: 1px solid #468FC5;
  background-color: #262F3D;
  color: white;
  font-weight: 700;
  font-size: 16px;
}

.header_one td:first-child {
  border-left: 1px solid #468FC5;
  border-radius: 10px 0 0 0;
  background-color: #468FC5;
}

.header_one td:last-child {
  border-radius: 0 10px 0 0;
}

.header_two td {
  color: #468FC5;
  font-weight: 700;
  font-size: 16px;
  border-right: 1px solid #468FC5;
  border-top: 1px solid #468FC5;
}

.header_two td:first-child {
  border-left: 1px solid #468FC5;
}

.archive-table-body tr td {
  border-right: 1px solid #468FC5;
  border-top: 1px solid #468FC5;
  color: #262F3D;
}

.archive-table-body tr td:first-child {
  border-left: 1px solid #468FC5;
}

.archive-table-body tr:last-child td {
  border-bottom: 1px solid #468FC5;
}

.archive-table-body tr:last-child td:first-child {
  border-radius: 0 0 0 10px;
}

.archive-table-body tr:last-child td:last-child {
  border-radius: 0 0 10px 0;
}

.excel-btn {
  float: right;
}

.form__buttons {
  display: flex;
  align-items: center;
  gap: 10px;
}

@media (max-width: 767px) {
  .excel-btn {
    float: left;
    display: block;
    margin-bottom: 20px;
  }
}
</style>