<template>
  <div class="row">

    <div class="col-12 mb-2">
      <div class="custom-card">
        <div class="card-body d-flex justify-content-between align-items-center">
          <div class="task-card-title mb-0 d-flex align-items-center">
            Работы
            <div class="items-count">{{ works.length }}</div>
          </div>
          <div class="task_component__actions_wrapper">
            <div
              v-if="task.task.state.id !== TASK_STATE.CLOSED
                && task.task.state.id !== TASK_STATE.CANCELED"
              class="task_component__action">
              <plus-icon
                @click="$bvModal.show('add-work')"
                class="chevron" />
            </div>
            <div
              v-if="works.length"
              class="task_component__action">
              <chevron-right-icon
                @click="collapseVisible = !collapseVisible"
                class="chevron"
                :class="{'rotate': collapseVisible}" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <b-collapse
      v-model="collapseVisible"
      class="col-12">
      <div v-for="work in works"
           :key="work.id"
           class="custom-card mb-2 work__item">
        <div class="card-body">
          <div class="work__item__name">{{ work.name }}</div>
          <div
            class="task_component__param"
            v-if="work.element">
            Аварийный элемент: <span>{{work.element.getCaption()}}</span>
          </div>
          <div
            class="task_component__param"
            v-if="work.comment">
            Комментарий: <span>{{ work.comment }}</span>
          </div>
          <div class="task_component__param">
            Израсходованные материалы:
            <span
              v-if="!work.consumptions.length"
              @click="addActualConsumption(work)"
              class="cursor-pointer">Добавить</span>
            <span>{{work.consumptions.map(item => `${item.consumable.name}: ${item.amount}`).join(', ')}}</span>
            <span
              v-if="work.consumptions.length"
              @click="updateActualConsumption(work)"
              class="cursor-pointer d-block">Изменить</span>

          </div>
        </div>
      </div>
    </b-collapse>

    <add-work />
    <add-work-actual-consumption
      :task-work="selectedTaskWork"
      :type="formType" />
  </div>
</template>

<script>
import {mapGetters, mapState} from "vuex";
import AddWorkActualConsumption from "@/components/ServiceDesk/Tasks/ActionsForms/AddWorkActualConsumption";
import TaskState from "@/models/serviceDesk/TaskState";
import AddWork from "@/components/ServiceDesk/Tasks/ActionsForms/AddWork";

export default {
  name: "WorksList",
  components: {AddWork, AddWorkActualConsumption},
  data() {
    return {
      selectedTaskWork: null,
      formType: 'create',
      collapseVisible: false
    }
  },
  computed: {
    ...mapGetters({
      works: 'works/getTaskWorks'
    }),
    ...mapState({
      task: state => state.task.task
    }),
    TASK_STATE: () => TaskState
  },
  methods: {
    addActualConsumption(work) {
      this.selectedTaskWork = work;
      this.formType = 'create';
      this.$bvModal.show('add-work-actual-consumption');
    },
    updateActualConsumption(work) {
      this.selectedTaskWork = work;
      this.formType = 'update';
      this.$bvModal.show('add-work-actual-consumption');
    }
  }
}
</script>

<style scoped>
.works-list {
  list-style: disc;
  padding-left: 18px;
  color: #468FC5;
}

.works-list li div {
  font-size: 16px;
  font-weight: 600;
  color: #262F3D;
  line-height: 100%;
  margin-bottom: .5rem;
}

.work__item__name {
  font-size: 16px;
  font-weight: 600;
  color: #262F3D;
  line-height: 100%;
  margin-bottom: 0.5rem;
}
</style>