<template>
  <form @submit.prevent="submitForm()">
    <div class="form-group">
      <v-select
        v-model="type"
        :options="subscriptionTypes"
        :reduce="type => type.id"
        :get-option-label="(option) => option.name"
        placeholder="Выберите подписку"
      />
    </div>
    <button class="btn custom-btn-blue w-100">Подписаться</button>
  </form>
</template>

<script>
import {mapState} from "vuex";
import {showAlert, showToast} from "@/utils/notification";

export default {
  name: "UserSubscriptionsForm",
  data() {
    return {
      type: null
    }
  },
  computed: {
    ...mapState({
      user: state => state.user.user,
      subscriptionTypes: state => state.userSubscriptions.subscriptionTypes
    })
  },
  methods: {
    submitForm() {
      this.$store.dispatch('userSubscriptions/createSubscription', {
        user_id: this.user.id,
        type: this.type
      })
          .then(() => {
            showToast('Подписка активирована', 'success');
            this.$store.dispatch('userSubscriptions/getUserSubscriptions', this.user.id);
            this.type = null;
          })
          .catch(error => showAlert('Ошибка', error));
    }
  }
}
</script>

<style scoped>

</style>