export default class Sensor {
    constructor(params = {}) {
        this.id = params.id;
        this.sensor_name = params.sensor_name;
        this.sensor_short_name = params.sensor_short_name;
        this.sensor_measure = params.sensor_measure;
        this.min_value = params.min_value;
        this.max_value = params.max_value;
        this.decimal = params.decimal || null;
        this.factor = params.factor || null;
        this.no_avg = params.no_avg || null;
        this.opc_node_name = params.opc_node_name || null;
        this.parameter = params.parameter || null;
    }
}