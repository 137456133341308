<template>
  <div class="row">
    <div class="col-md-4 col-xs-12">
      <div class="custom-card">
        <div class="card-body">
          <div class="container-fluid">
            <div class="row">
              <roles-form-el />
            </div>
          </div>
        </div>
      </div>
    </div>

    <div v-if="$route.name === 'RolesUpdate'"
         class="col-md-6 col-xs-12">
      <roles-permissions-list />
    </div>
  </div>
</template>

<script>
import RolesFormEl from "@/components/admin/RolesForm";
import RolesPermissionsList from "@/components/admin/RolesPermissionsList";

export default {
  name: "RolesFormPage",
  components: {
    RolesPermissionsList,
    RolesFormEl
  }
}
</script>

<style scoped>

</style>