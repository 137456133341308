<template>
  <aside class="sidebar"
         :class="{'collapsed': getIsActiveSidebar}">
    <button class="sidebar__toggle"
            @click="isToggled">
      <icon-arrow-double/>
    </button>
    <div class="sidebar__inner">
      <div class="sidebar__top"
           :class="{'sidebar__top--active': isActiveBorderMenu}">
        <router-link to="/">
          <img class="sidebar__logo"
               src="@/assets/img/masis-logo.svg"
               alt="logo">
        </router-link>
      </div>
      <div class="sidebar__navigation">
        <ul class="nav-links">
          <template v-for="part in menu">
            <li
              :key="part.title"
              class="n-nav-link"
              v-if="part.list.some(item => checkPermission(item.typePermission)) || part.isAdmin === isAdmin"
              :class="[{'category-open': part.isActive}, {'category-active': checkActiveCategory(part.list)}]"
              @click="activeCategory(part)"
            >
              <div class="icon-link">
                <div class="icon-link__wrapper">
                  <component :is="`${part.icon}`"/>
                  <span class="nav-name">
                    {{ part.title }}
                  </span>
                </div>
                <icon-arrow class="arrow"/>
              </div>
              <div class="sub-menu__wrapper">
                <div class="sub-menu__title">
                  <span>{{ part.title }}</span>
                </div>
                <div class="sub-menu__line"/>
                <ul class="sub-menu">
                  <template
                    v-for="item in part.list"

                  >
                    <li
                      :key="item.label"
                      class="sub-menu__item"
                      v-if="checkPermission(item.typePermission) || item.isAdmin === isAdmin"
                    >
                      <router-link
                        class="sub-menu__link"
                        :to="`${item.name}`"
                        @click.native.prevent="part.isActive = !part.isActive"
                      >
                        {{ item.label }}
                      </router-link>
                    </li>
                  </template>
                </ul>
              </div>
            </li>
          </template>
        </ul>
      </div>
      <div class="sidebar__profile"
           :class="{'sidebar__profile--active': isActiveBorderMenu}">
        <div class="sidebar__profile-content">
          <span class="sidebar__profile-name">
            {{userName}}
          </span>
        </div>
        <div class="sidebar__profile-btn">
          <button class="profile__btn-logout"
                  @click="logout">
            <icon-logout />
            <span>Выйти</span>
          </button>
        </div>
      </div>
    </div>
  </aside>

</template>

<script>
import {mapGetters, mapState} from "vuex";
import IconArrowDouble from "@/components/Icons/IconArrowDouble.vue";
import IconArrow from "@/components/Icons/IconArrow.vue";
import IconWifi from "@/components/Icons/IconWifi.vue";
import IconCall from "@/components/Icons/IconCall.vue";
import IconTruck from "@/components/Icons/IconTruck.vue";
import IconBilling from "@/components/Icons/IconBilling.vue";
import IconNetworks from "@/components/Icons/IconNetworks.vue";
import IconSettings from "@/components/Icons/IconSettins.vue";
import IconLogout from "@/components/Icons/IconLogout.vue";

export default {
  name: "TheSidebar",
  components: {
    IconLogout,
    IconSettings, IconNetworks, IconBilling, IconTruck, IconCall, IconWifi, IconArrow, IconArrowDouble
},
  data() {
    return {
      logoutBlock: false,
      isActiveBorderMenu: false,
      showServiceDeskSettingsCollapse: false,
      showBillingSettingCollapse: false,
      showTelemetryCollapse: false,
      showTransportCollapse: false,
      showNetworkSettingsCollapse: false,
      menu: [
        {
          title: 'Телеметрия',
          icon: 'iconWifi',
          isActive: false,
          list: [
            { label: 'Телеметрия', name: '/telemetry', typePermission: "object-monitoring" },
            { label: 'Архив', name: '/telemetry-archive', typePermission: "object-monitoring" },
            { label: 'Журнал ручного ввода', name: '/logbook', typePermission: "object-monitoring" },
            { label: 'Доступы к устройствам', name: '/device-accesses-list', isAdmin: true },
            { label: 'Конфигуратор журнала', name: '/admin-journal-configs', isAdmin: true }
          ]
        },
        {
          title: 'Транспорт',
          icon: 'iconTruck',
          isActive: false,
          list: [
            { label: 'Трекинг транспорта', name: '/tracking', typePermission: "object-monitoring" },
            { label: 'Автопарк', name: '/admin-autos', typePermission: "transport.management" },
            { label: 'Сервера данных', name: '/transport-data-servers', isAdmin: true }
          ]
        },
        {
          title: 'Диспетчерская',
          icon: 'iconCall',
          isActive: false,
          list: [
            { label: 'Статистика обращений', name: '/appeals-dashboard', typePermission: "service-desk.appeals" },
            { label: 'Статистика заявок', name: '/issues-dashboard', typePermission: "service-desk.issues" },
            { label: 'Обращения', name: '/appeals-list', typePermission: "service-desk.appeals" },
            { label: 'Заявки', name: '/issues-list', typePermission: "service-desk.issues" },
            { label: 'Архив заявок', name: '/issues-archive', typePermission: "service-desk.issues" },
            { label: 'Задания', name: '/tasks', typePermission: "service-desk.issues" },
            { label: 'Мои задания', name: '/my-tasks', typePermission: "service-desk.transport-orders" },
            { label: 'Заказы транспорта', name: '/transport-orders', typePermission: "service-desk.transport-orders" },
            { label: 'Отчеты', name: '/service-desk-reports', typePermission: "service-desk.reports" },
            {
 label: 'Категории', name: '/service-desk-categories', typePermission: "service-desk.settings",
              isAdmin: true
},
            { label: 'Подразделения', name: '/departments', typePermission: "service-desk.settings", isAdmin: true },
            { label: 'Типы заданий', name: '/task-types', typePermission: "service-desk.settings", isAdmin: true },
            { label: 'Работы', name: '/works', typePermission: "service-desk.settings", isAdmin: true },
            { label: 'СММ', name: '/mechanical-equipments', typePermission: "service-desk.settings", isAdmin: true },
            {
 label: 'Расходные материалы', name: '/consumables-catalog', typePermission: "service-desk.settings",
              isAdmin: true
}
          ]
        },
        {
          title: 'Биллинг',
          icon: 'iconBilling',
          isActive: false,
          list: [
            { label: 'Фильтр по абонентам', name: '/billing-clients', typePermission: "billing" },
            { label: 'Фильтр по объектам', name: '/billing-buildings', typePermission: "billing" },
            { label: 'Эталонный объект', name: '/standard-building', typePermission: "billing" },
            { label: 'Баланс водоснабжения', name: '/water-balance',typePermission: "billing" },
            { label: 'Баланс водоотведения', name: '/drain-balance',typePermission: "billing" },
            { label: 'Зоны', name: '/admin-zones', isAdmin: true },
            { label: 'Балансовые группы', name: '/admin-balance-groups', isAdmin: true }
          ]
        },
        {
          title: 'Инженерные сети',
          icon: 'iconNetworks',
          isActive: false,
          isAdmin: true,
          list: [
            { label: 'Сети', name: '/engineering-networks', isAdmin: true },
            { label: 'Объекты сети', name: '/network-objects-catalog', isAdmin: true },
            { label: 'Типы объектов сети', name: '/network-object-types', isAdmin: true },
            { label: 'Типы сетевого оборудования', name: '/network-equipment-types', isAdmin: true }
          ]
        },
        {
          title: 'Энергопотребление',
          icon: 'iconSettings',
          isActive: false,
          isAdmin: true,
          list: [
            {
              label: 'Энергопотребление', name: '/energy-consumption-logbook', typePermission: "energy-consumption",
              isAdmin: true
            },
            {
              label: 'Объекты энергопотребления', name: '/energy-consumption-object-catalog',
              typePermission: "energy-consumption", isAdmin: true
            }
          ]
        },
        {
          title: 'Система',
          icon: 'iconSettings',
          isActive: false,
          isAdmin: true,
          list: [
            { label: 'Пользователи', name: '/admin-users', typePermission: "system-settings", isAdmin: true},
            { label: 'График работы', name: '/work-shift', typePermission: "system-settings", isAdmin: true},
            { label: 'Роли', name: '/admin-roles', typePermission: "system-settings", isAdmin: true},
            { label: 'Картографические слои', name: '/admin-layers', typePermission: "system-settings", isAdmin: true}
          ]
        }
      ]
    }
  },
  computed: {
    ...mapGetters({
      isAdmin: "auth/isAdmin",
      userName: "auth/userName",
      checkPermission: "auth/checkPermission",
      getIsActiveSidebar: 'sidebar/getIsActiveSidebar'
    }),
    ...mapState({
      authUser: state => state.auth.user
    })
  },
  methods: {
    logout() {
      this.$store.dispatch("auth/logout");
    },
    isToggled() {
      this.$store.dispatch("sidebar/togledSidebar")
    },
    checkActiveCategory(part) {
      const {path} = this.$route

      return part.some(item => item.name === path)
    },
    activeCategory(part) {
      part.isActive = !part.isActive

      setTimeout(() => {
        this.handleHeightMenu()
      }, 100)
    },
    handleHeightMenu() {
      const linksHeight = document.querySelector('.nav-links').clientHeight
      const linkHeight = document.querySelectorAll('.nav-links .n-nav-link')

      const arrayLinkHeight = []
      linkHeight.forEach(link => {
        arrayLinkHeight.push(link.offsetHeight);
      })

      const sumLinkHeight = arrayLinkHeight.reduce(function (previousValue, currentValue) {
        return previousValue + currentValue;
      });

      this.isActiveBorderMenu = linksHeight < sumLinkHeight
    }
  }
}
</script>
