<template>
  <div class="row justify-content-center align-items-center fixed-height">
    <div class="col-4">
      <consumables-form />
    </div>
  </div>
</template>

<script>
import ConsumablesForm from "@/components/ServiceDesk/admin/ConsumablesForm";
export default {
  name: "ConsumablesFormPage",
  components: {ConsumablesForm}
}
</script>

<style scoped>

</style>