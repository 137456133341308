<template>
  <b-tab
    title="Фильтр"
    title-item-class="custom-nav-item"
    title-link-class="custom-nav-link"
  >
    <h4 class="tab-title mb-20">
      Параметры фильтра
    </h4>

    <div class="row mb-3">
      <div class="col-12">
        <h5>По адресу</h5>
        <dadata-suggestions
          class="form-control form-control-fix"
          @change="setAddress"
          v-model="full_address"
          placeholder="Начните вводить адрес"
        />
      </div>
    </div>

    <div class="row mb-3">
      <div class="col-12">
        <h5>Расчётный месяц</h5>
        <date-picker
          id="connectionDate"
          input-class="form-control-fix w-100 pl-2"
          placeholder="Выберите период"
          v-model="filter.filter_period"
          :default-value="defaultValue"
          format="MMMM YYYY"
          type="month"
          value-type="YYYY-MM-DD"
          @clear="filter.filter_period = null"
        />
      </div>
    </div>

    <div class="row mb-3">
      <div class="col-12">
        <h5>По источнику водоснабжения</h5>
        <select
          id="disabled"
          v-model="filter.source"
          class="form-control form-control-fix"
        >
          <option value="">
            Выберите источник
          </option>
          <option value="ТГВ">
            ТГВ
          </option>
          <option value="ВЗУ">
            ВЗУ
          </option>
        </select>
      </div>
    </div>

    <div class="row mb-3">
      <div class="col-xxl-6 col-xl-12">
        <h5>По зоне водоснабжения</h5>
        <select
          v-model="filter.zone_ws_id"
          class="form-control form-control-fix"
        >
          <option value="null">
            Выберите зону
          </option>
          <option
            v-for="zone in this.$store.getters['zone/getZonesByNetworkType'](1)"
            :key="zone.id"
            :value="zone.attributes.sys_id"
          >
            {{ zone.attributes.name }}
          </option>
        </select>
      </div>

      <div class="col-xxl-6 col-xl-12">
        <h5>По зоне водоотведения</h5>
        <select
          v-model="filter.zone_wd_id"
          class="form-control form-control-fix"
        >
          <option value="null">
            Выберите зону
          </option>
          <option
            v-for="zone in this.$store.getters['zone/getZonesByNetworkType'](2)"
            :key="zone.id"
            :value="zone.attributes.sys_id"
          >
            {{ zone.attributes.name }}
          </option>
        </select>
      </div>
    </div>

    <div class="row mb-3">
      <div class="col-12">
        <h5>По объёму потребления ресурса, м<sup>3</sup></h5>
      </div>
      <div class="col-xxl-6 col-xl-12">
        <div class="input-group">
          <input
            id="consValue-min"
            v-model.number="filter.consumption_values[0]"
            class="form-control form-control-fix"
            placeholder="Мин. значение"
            type="text"
          >
        </div>
      </div>
      <div class="col-xxl-6 col-xl-12">
        <div class="input-group">
          <input
            id="consValue-max"
            v-model.number="filter.consumption_values[1]"
            class="form-control form-control-fix"
            placeholder="Макс. значение"
            type="text"
          >
        </div>
      </div>
    </div>

    <div class="row mb-3">
      <div class="col-12">
        <h5>
          По графику подачи
        </h5>
      </div>
      <div class="col-xxl-6 col-xl-12">
        <div class="input-group">
          <date-picker
            id="morning_period"
            v-model="filter.supply_am"
            :default-value="defaultValue"
            :time-picker-options="{
              start: '00:00',
              step: '01:00',
              end: '12:00'
            }"
            class="w-100"
            format="H:mm"
            input-class="form-control-fix w-100 pl-2"
            placeholder="Утро"
            range
            type="time"
            value-type="H"
            @clear="filter.supply_am = []"
          />
        </div>
      </div>
      <div class="col-xxl-6 col-xl-12">
        <div class="input-group">
          <date-picker
            id="evening_period"
            v-model="filter.supply_pm"
            :default-value="defaultValue"
            :time-picker-options="{
              start: '12:00',
              step: '01:00',
              end: '24:00'
            }"
            class="w-100"
            format="H:mm"
            input-class="form-control-fix w-100 pl-2"
            placeholder="Вечер"
            range
            type="time"
            value-type="H"
            @clear="filter.supply_pm = []"
          />
        </div>
      </div>
    </div>

    <div class="row mb-3">
      <div class="col-12">
        <h5>Несоответствие ТУ ВС, %</h5>
      </div>
      <div class="col-xxl-6 col-lg-12">
        <input v-model.number="filter.consumption_coef[0]"
               class="form-control form-control-fix"
               placeholder="Мин. значение">
      </div>

      <div class="col-xxl-6 col-lg-12">
        <input v-model.number="filter.consumption_coef[1]"
               class="form-control form-control-fix"
               placeholder="Макс. значение">
      </div>
    </div>

    <div class="row mb-3">
      <div class="col-12">
        <h5>Несоответствие ТУ ВО, %</h5>
      </div>
      <div class="col-xxl-6 col-lg-12">
        <input v-model.number="filter.drainage_coef[0]"
               class="form-control form-control-fix"
               placeholder="Мин. значение">
      </div>

      <div class="col-xxl-6 col-lg-12">
        <input v-model.number="filter.drainage_coef[1]"
               class="form-control form-control-fix"
               placeholder="Макс. значение">
      </div>
    </div>

    <div class="row mb-3">
      <div class="col-12">
        <h5>Несоответствие водоснабжения / водоотведения, %</h5>
      </div>
      <div class="col-xxl-6 col-lg-12">
        <input v-model.number="filter.fact_coef[0]"
               class="form-control form-control-fix"
               placeholder="Мин. значение">
      </div>
      <div class="col-xxl-6 col-lg-12">
        <input v-model.number="filter.fact_coef[1]"
               class="form-control form-control-fix"
               placeholder="Макс. значение">
      </div>
    </div>

    <div class="row mb-3">
      <div class="col-xxl-12 col-xl-12">
        <label class="custom-control custom-checkbox">
          <input
            id="Illegal"
            v-model="filter.illegal"
            :false-value="0"
            :true-value="1"
            class="custom-control-input"
            type="checkbox"
          >
          <span class="custom-control-label">Незаконные подключения</span>
        </label>
        <label class="custom-control custom-checkbox">
          <input
            id="technicalConditions"
            v-model="filter.technical_conditions"
            :false-value="0"
            :true-value="1"
            class="custom-control-input"
            type="checkbox"
          >
          <span class="custom-control-label">По несогласованным / не верифицированным ТУ</span>
        </label>
        <label class="custom-control custom-checkbox">
          <input
            id="leaks"
            v-model="filter.leaks"
            :false-value="0"
            :true-value="1"
            class="custom-control-input"
            type="checkbox"
          >
          <span class="custom-control-label">Утечки</span>
        </label>
        <label class="custom-control custom-checkbox">
          <input
            id="accident"
            v-model="filter.accident"
            :false-value="0"
            :true-value="1"
            class="custom-control-input"
            type="checkbox"
          >
          <span class="custom-control-label">Аварийность</span>
        </label>
        <label class="custom-control custom-checkbox">
          <input
            id="consumption_not_compare"
            v-model="filter.consumption_not_compare"
            :false-value="0"
            :true-value="1"
            class="custom-control-input"
            type="checkbox"
          >
          <span class="custom-control-label">
            Фактическое потребление больше потребления по ТУ
          </span>
        </label>
        <label class="custom-control custom-checkbox">
          <input
            id="drainage_not_compare"
            v-model="filter.drainage_not_compare"
            :false-value="0"
            :true-value="1"
            class="custom-control-input"
            type="checkbox"
          >
          <span class="custom-control-label">
            Фактическое отведение больше чем отведение по ТУ
          </span>
        </label>
        <label class="custom-control custom-checkbox">
          <input
            id="fact_not_compare"
            v-model="filter.fact_not_compare"
            :false-value="0"
            :true-value="1"
            class="custom-control-input"
            type="checkbox"
          >
          <span class="custom-control-label">
            Фактическое потребление не соответствует фактическому отведению
          </span>
        </label>
      </div>
    </div>

    <hr>

    <div class="row mb-4">
      <div class="col-12">
        <h5>Сортировка результатов</h5>
      </div>
      <div class="col-xxl-6 col-xl-12">
        <select v-model="filter.sort_by"
                class="form-control form-control-fix">
          <option :value="null">Параметр сортировки</option>
          <option value="fact_ratio">Коэффициент факт. водопотребления к факт. водоотведению</option>
          <option value="consumption_ratio">Коэффициент факт. водопотребления к ТУ водопотребления</option>
          <option value="drainage_ratio">Коэффициент факт. водоотведения к ТУ водоотведения</option>
          <option value="fact_consumption">Факт. водопотребление</option>
          <option value="normative_consumption">ТУ водопотребления</option>
          <option value="fact_drainage">Факт. водоотведение</option>
          <option value="normative_drainage">ТУ водоотведения</option>
        </select>
      </div>
      <div class="col-xxl-6 col-xl-12">
        <select v-model="filter.sort_dir"
                class="form-control form-control-fix">
          <option :value="null">Направление сортировки</option>
          <option value="asc">По возрастанию</option>
          <option value="desc">По убыванию</option>
        </select>
      </div>
    </div>

    <div class="row">
      <div class="col-md-6 col-xs-12">
        <button
          class="btn custom-btn-blue-outline w-100"
          @click="submitFilter()"
        >
          Применить фильтр
          <loader v-if="loader" />
        </button>
      </div>
      <div class="col-xxl-6 col-lg-12">
        <button
          class="btn custom-btn-blue-outline w-100 d-flex align-items-center justify-content-center"
          @click="clearFilter()"
        >
          <refresh-ccw-icon class="mr-2"/>
          Сбросить
        </button>
      </div>
    </div>
  </b-tab>
</template>

<script>
import DatePicker from "vue2-datepicker";
import Loader from "@/layout/BaseLoader";
import Vue from "vue";
import DadataSuggestions from "vue-dadata-suggestions";
import notification from "@/mixins/notification";

Vue.use(DadataSuggestions, {
  token: process.env.VUE_APP_DADATA_API_KEY,
  type: 'ADDRESS',
  geoLocation: [{
    "kladr_id": process.env.VUE_APP_DADATA_KLADR_ID
  }]
});

export default {
  name: "BillingBuildingsFilter",
  components: {DatePicker, Loader},
  mixins: [notification],
  data() {
    return {
      full_address: '',
      filter: {
        region: '',
        filter_period: null,
        city: '',
        settlement: '',
        street: '',
        house: '',
        block: '',
        zone_ws_id: null,
        zone_wd_id: null,
        source: '',
        consumption_values: [],
        consumption_coef: [],
        drainage_coef: [],
        fact_coef: [],
        supply_am: [],
        supply_pm: [],
        consumption_not_compare: 0,
        drainage_not_compare: 0,
        fact_not_compare: 0,
        illegal: 0,
        technical_conditions: 0,
        accident: 0,
        leaks: 0,
        sort_dir: null,
        sort_by: null
      },
      defaultValue: new Date(),
      loader: false
    }
  },
  computed: {
    cleanFilter: function () {
      return filter => {
        let payload = JSON.parse(JSON.stringify(filter))
        for (let propName in payload) {
          if (payload[propName] === null
              || payload[propName] === 0
              || payload[propName] === undefined
              || payload[propName] === ""
              || payload[propName].length === 0) {
            delete payload[propName]
          }
          if (typeof payload[propName] === 'object') {
            if (payload[propName][0] === '' && payload[propName][1] === '') {
              delete payload[propName]
            } else {
              payload[propName][0] = (parseInt(payload[propName][0]) + 100) / 100
              payload[propName][1] = (parseInt(payload[propName][1]) + 100) / 100
            }
          }
        }
        return payload
      }
    },
    validateForm: function () {
      return () => {
        let payload = JSON.parse(JSON.stringify(this.filter));
        // eslint-disable-next-line no-bitwise
        if (typeof payload.consumption_values[0] == 'number' ^ typeof payload.consumption_values[1] == 'number') {
          this.alert('Ошибка', 'Заполните поле мин. и макс. значение фильтра по объёму потребления ресурса', 'error');
          return false;
        }
        // eslint-disable-next-line no-bitwise
        if (typeof payload.consumption_coef[0] == 'number' ^ typeof payload.consumption_coef[1] == 'number') {
          this.alert('Ошибка', 'Заполните мин. и макс. значения для фильтра по несоответствию ТУ ВС', 'error');
          return false;
        }
        // eslint-disable-next-line no-bitwise
        if (typeof payload.drainage_coef[0] == 'number' ^ typeof payload.drainage_coef[1] == 'number') {
          this.alert('Ошибка', 'Заполните мин. и макс. значения для фильтра по несоответствию ТУ ВО', 'error');
          return false;
        }
        // eslint-disable-next-line no-bitwise
        if (typeof payload.fact_coef[0] == 'number' ^ typeof payload.fact_coef[0] == 'number') {
          this.alert('Ошибка',
              'Заполните мин. и макс. значения для фильтра по несоответствию водоснабжения / водоотведения', 'error');
          return false;
        }
      }
    }
  },
  mounted() {
    this.$store.dispatch("zone/getAllZones");
  },
  methods: {
    submitFilter() {
      if (this.validateForm() === false) return false;

      let filter = {
        params: this.cleanFilter(this.filter),
        page: 1
      }
      this.loader = true;
      this.$store.dispatch("building/getBuildings", filter)
          .then(() => {
            this.loader = false;
            this.$store.commit('building/SET_TAB_INDEX', 1)
            this.$emit("data-complete", filter)
          })
          .catch(() => this.loader = false)
    },
    clearFilter() {
      this.full_address = '';
      this.filter.region = '';
      this.filter.city = '';
      this.filter.settlement = '';
      this.filter.street = '';
      this.filter.house = '';
      this.filter.block = '';
      this.filter.zone_ws_id = null;
      this.filter.zone_wd_id = null;
      this.filter.consumption_values = [];
      this.filter.illegal = 0;
      this.filter.technical_conditions = 0;
      this.filter.accident = 0;
      this.filter.leaks = 0;
      this.filter.consumption_not_compare = 0;
      this.filter.drainage_not_compare = 0;
      this.filter.fact_not_compare = 0;
      this.filter.source = '';
      this.filter.supply_am = [];
      this.filter.supply_pm = [];
      this.filter.consumption_coef = [];
      this.filter.drainage_coef = [];
      this.filter.fact_coef = [];
      this.filter.sort_by = null;
      this.filter.sort_dir = null;
    },
    setAddress(event) {
      if (typeof event === 'object') {
        this.filter.region = event.data.region;
        this.filter.city = event.data.city;
        this.filter.settlement = event.data.settlement;
        this.filter.street = event.data.street;
        this.filter.house = event.data.house;
        this.filter.block = event.data.block;
      }
    }
  }
}
</script>

<style scoped>
.tab-title {
  font-size: 24px;
  font-weight: 700;
  color: #262F3D;
  margin-bottom: 40px;
}

.mb-20 {
  margin-bottom: 20px;
}
</style>