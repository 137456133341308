import { render, staticRenderFns } from "./IconReset.vue?vue&type=template&id=6c375c6c&scoped=true&"
import script from "./IconReset.vue?vue&type=script&lang=js&"
export * from "./IconReset.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6c375c6c",
  null
  
)

export default component.exports