<template>
  <b-modal
    id="add-brigade"
    centered
    hide-header
    hide-footer
    @shown="checkType()"
    @hidden="clearFields()"
  >
    <div class="col-12">
      <div class="custom-modal-header">
        <div v-if="formType === 'create'"
             class="custom-modal-title">
          Добавить ресурс
        </div>
        <div v-else
             class="custom-modal-title">
          Изменить ресурс
        </div>
        <img
          class="custom-modal-close"
          alt="x"
          src="@/assets/img/icons/close.svg"
          @click="$bvModal.hide('add-brigade')"
        >
      </div>
      <div class="modal-divider" />
    </div>
    <form @submit.prevent="submitForm()">
      <div class="col-12 mb-2">
        <div class="form-group">
          <label class="form-label">Наименование</label>
          <input
            v-model="payload.name"
            class="form-control"
            type="text"
            placeholder="Введите наименование">
        </div>
        <div class="form-group">
          <label class="form-label">Мастер</label>
          <v-select
            v-model="payload.master_id"
            :options="departmentStaff"
            :reduce="employee => employee.id"
            :get-option-label="(option) => option.name"
            placeholder="Выберите мастера"
          />
        </div>
        <div class="form-group">
          <label class="form-label">Транспорт</label>
          <v-select
            v-model="payload.transport_id"
            :options="vehicles"
            :reduce="vehicle => vehicle.id"
            :get-option-label="(option) => option.attributes.gov_number + ' ' + option.attributes.label"
            placeholder="Выберите транспорт"
          />
        </div>
        <div class="modal-divider" />
        <div class="row">
          <div class="col-12">
            <button
              type="submit"
              class="btn custom-btn-blue w-100"
            >
              Сохранить
            </button>
          </div>
        </div>
      </div>
    </form>
  </b-modal>
</template>

<script>
import {mapGetters, mapState} from 'vuex';
import notification from "@/mixins/notification";
export default {
  name: "DepartmentAddBrigadeForm",
  mixins: [notification],
  props: {
    formType: {
      type: String,
      default: 'create'
    },
    brigadeId: {
      type: Number
    }
  },
  data() {
    return {
      payload: {
        name: null,
        master_id: null,
        transport_id: null
      }
    }
  },
  computed: {
    ...mapState({
      departmentStaff: state => state.departments.staff,
      vehicles: state => state.autos.autos
    }),
    ...mapGetters({
      getBrigadeById: 'departmentBrigades/getBrigadeById'
    })
  },
  mounted() {
    this.$store.dispatch('autos/getAllAutos');
  },
  methods: {
    checkType() {
      if (this.formType === 'update') {
        const brigade = this.getBrigadeById(this.brigadeId);

        this.payload.name = brigade.name;
        this.payload.master_id = brigade.masterId;
        this.payload.transport_id = brigade.transportId;
      }
    },
    submitForm() {
      if (this.formType === 'create') {
        this.$store.dispatch('departmentBrigades/createDepartmentBrigade', {
          department_id: this.$route.params.id,
          payload: this.payload
        })
            .then(() => {
              this.toast('Бригада добавлена', 'success');
              this.$bvModal.hide('add-brigade');
              this.$store.dispatch('departmentBrigades/getDepartmentBrigadesList', {
                department_id: this.$route.params.id,
                page: 1
              })

              this.clearFields();
            })
            .catch(error => this.alert('Ошибка', error, 'error'));
      } else if (this.formType === 'update') {
        this.$store.dispatch('departmentBrigades/updateDepartmentBrigade', {
          department_id: this.$route.params.id,
          brigade_id: this.brigadeId,
          payload: this.payload
        })
            .then(() => {
              this.toast('Бригада обновлена', 'success');
              this.$bvModal.hide('add-brigade');
              this.$store.dispatch('departmentBrigades/getDepartmentBrigadesList', {
                department_id: this.$route.params.id,
                page: 1
              })

              this.clearFields();
              this.$emit('change-form-type', 'create');
            })
            .catch(error => this.alert('Ошибка', error, 'error'));
      }
    },
    clearFields() {
      this.payload = {
        name: null,
        master_id: null,
        transport_id: null
      }
    }
  }
}
</script>

<style scoped>

</style>