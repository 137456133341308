<template>
  <div class="custom-card">
    <div class="card-body">
      <div class="container-fluid">
        <div class="row">
          <div class="col-12">
            <form @submit.prevent="submitForm()">

              <div class="form-group">
                <label class="form-label">Пользователь</label>
                <v-select
                  v-model="payload.user_id"
                  :options="users"
                  :reduce="user => user.id"
                  placeholder="Выберите пользователя"
                  :get-option-label="(option) => option.name"
                />
              </div>

              <div class="form-group">
                <label class="form-label">Устройства</label>
                <v-select
                  v-model="payload.devices_id"
                  :options="devices"
                  :reduce="device => device.id"
                  :get-option-label="(option) => option.name"
                  placeholder="Выберите устройства"
                  multiple
                />
              </div>

              <div class="text-center">
                <button
                  type="submit"
                  class="btn custom-btn-blue w-100"
                >
                  Сохранить
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapState} from "vuex";
import {showAlert, showToast} from "@/utils/notification";

export default {
  name: "DeviceAccessForm",
  data() {
    return {
      payload: {
        user_id: null,
        devices_id: null
      }
    }
  },
  computed: {
    ...mapState({
      users: state => state.user.users,
      devices: state => state.device.devices
    })
  },
  mounted() {
    this.$store.dispatch('user/getAllUsers');
    this.$store.dispatch('device/getAllDevices');
  },
  methods: {
    submitForm() {
      this.$store.dispatch('deviceAccess/allowAccess', this.payload)
          .then(() => {
            this.$router.push('/device-accesses-list');
            showToast('Доступ к устройствам разрешён', 'success');
          })
          .catch(error => showAlert('Ошибка', error));
    }
  }
}
</script>

<style scoped>

</style>