import { render, staticRenderFns } from "./CreateIssueForm.vue?vue&type=template&id=9e8cbd96&scoped=true&"
import script from "./CreateIssueForm.vue?vue&type=script&lang=js&"
export * from "./CreateIssueForm.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9e8cbd96",
  null
  
)

export default component.exports