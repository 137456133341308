<template>
  <b-modal
    id="add-issue-category"
    centered
    hide-header
    hide-footer
  >
    <div class="col-12">
      <div class="custom-modal-header">
        <div v-if="!this.issue.category"
             class="custom-modal-title">
          Назначить категорию заявки
        </div>
        <div v-else
             class="custom-modal-title">
          Изменить категорию заявки
        </div>
        <img
          class="custom-modal-close"
          src="@/assets/img/icons/close.svg"
          @click="$bvModal.hide('add-issue-category')"
        >
      </div>
      <div class="modal-divider" />
    </div>
    <form @submit.prevent="submitForm()">
      <div class="col-12 mb-2">
        <div class="form-group">
          <label class="form-label">Категория</label>
          <v-select
            v-model="payload.category_id"
            :options="processSelectOptions()"
            :reduce="category => category.id"
            :get-option-label="(option) => option.name"
            placeholder="Выберите категорию"
          />
        </div>
        <div class="modal-divider" />
        <div class="row">
          <div class="col-12">
            <button
              type="submit"
              class="btn custom-btn-blue w-100"
            >
              Сохранить
            </button>
          </div>
        </div>
      </div>
    </form>
  </b-modal>
</template>

<script>
import {mapGetters} from "vuex";
import notification from "@/mixins/notification";

export default {
  name: "AddIssueCategoryForm",
  mixins: [notification],
  data() {
    return {
      payload: {
        category_id: null
      }
    }
  },
  computed: {
    ...mapGetters({
      issueCategories: 'serviceDeskCategories/getCategories',
      issue: 'issue/getIssue'
    })
  },
  mounted() {
    this.$store.dispatch('serviceDeskCategories/getAllCategories');
  },
  methods: {
    submitForm() {
      this.$store.dispatch('issue/changeIssueCategory', {
        issue_id: this.issue.id,
        payload: this.payload
      })
          .then(() => {
            this.$store.dispatch('issue/getIssue', this.issue.id);
            this.$store.dispatch('history/getIssueHistory', this.issue.id);
            this.payload.category_id = null;
            this.toast('Категория изменена', 'success');
            this.$bvModal.hide('add-issue-category');
          })
          .catch(error => this.alert('Ошибка', error, 'error'));
    },
    processSelectOptions() {
      let options = this.issueCategories.concat();
      options.unshift({
        id: '',
        name: 'Снять категорию'
      })
      return options
    }
  }
}
</script>

<style scoped>

</style>