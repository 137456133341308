<template>
  <div class="row">
    <div class="col-12">
      <div class="custom-card">
        <div class="card-body">
          <div class="container-fluid">
            <div class="row">
              <div class="col-12">
                <h4 class="table-title d-inline-block">
                  Средства малой механизации
                </h4>
                <div v-if="$store.state.mechanicalEquipments.loading"
                     class="d-inline-block ml-2">
                  <base-loader />
                </div>
                <router-link to="/create-mechanical-equipment">
                  <button class="btn custom-btn-blue float-right">
                    Добавить СММ
                  </button>
                </router-link>
                <hr class="divider">
              </div>
              <div v-if="$store.state.mechanicalEquipments.mechanicalEquipments.length"
                   class="col-12">
                <mechanical-equipments-list />
              </div>
              <div v-if="!$store.state.mechanicalEquipments.mechanicalEquipments.length
                     && !$store.state.mechanicalEquipments.loading"
                   class="col-12 mt-2">
                <div class="no-result-title">Средства малой механизации не найдены</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BaseLoader from "@/layout/BaseLoader";
import MechanicalEquipmentsList from "@/components/ServiceDesk/admin/MechanicalEquipmentsList";
export default {
  name: "MechanicalEquipmentsListPage",
  components: {MechanicalEquipmentsList, BaseLoader},
  mounted() {
    this.$store.dispatch('mechanicalEquipments/getMechanicalEquipments');
  }
}
</script>

<style scoped>

</style>