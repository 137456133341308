import NetworkObjectTypesService from "@/services/NetworkObjectTypesService";
import {getError} from "@/utils/helpers";

export const namespaced = true;

export const state = {
    networkObjectTypes: [],
    networkObjectType: null,
    error: null,
    loading: false
};

export const mutations = {
    SET_NETWORK_OBJECT_TYPES(state, types) {
        state.networkObjectTypes = types;
    },
    SET_NETWORK_OBJECT_TYPE(state, type) {
        state.networkObjectType = type;
    },
    SET_ERROR(state, error) {
        state.error = error;
    },
    SET_LOADING(state, loading) {
        state.loading = loading;
    },
    DELETE_NETWORK_OBJECT_TYPE(state, typeId) {
        const index = state.networkObjectTypes.findIndex(type => type.id === typeId);
        state.networkObjectTypes.splice(index, 1);
    }
};

export const actions = {
    getNetworkObjectTypesList({commit}) {
        commit("SET_LOADING", true);
        NetworkObjectTypesService.fetchNetworkObjectTypesList()
            .then(types => commit("SET_NETWORK_OBJECT_TYPES", types))
            .catch(error => commit("SET_ERROR", error))
            .finally(() => commit("SET_LOADING", false))
    },
    getNetworkObject({commit}, typeId) {
        return new Promise((resolve, reject) => {
            NetworkObjectTypesService.fetchNetworkObject(typeId)
                .then(type => {
                    commit("SET_NETWORK_OBJECT_TYPE", type);
                    resolve();
                })
                .catch(error => {
                    commit("SET_ERROR", error);
                    reject(error);
                });
        })
    },
    createNetworkObjectType(context, payload) {
        return new Promise((resolve, reject) => {
            NetworkObjectTypesService.createNetworkObjectType(payload)
                .then(() => resolve())
                .catch(error => reject(getError(error)));
        })
    },
    updateNetworkObjectType(context, {typeId, payload}) {
        return new Promise((resolve, reject) => {
            NetworkObjectTypesService.updateNetworkObjectType(typeId, payload)
                .then(() => resolve())
                .catch(error => reject(getError(error)));
        })
    },
    deleteNetworkObjectType(context, typeId) {
        return new Promise((resolve, reject) => {
            NetworkObjectTypesService.deleteNetworkObjectType(typeId)
                .then(() => resolve())
                .catch(error => reject(getError(error)));
        })
    }
};

export const getters = {};