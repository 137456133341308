export default {
    methods: {
        toast(title, icon) {
            this.$swal({
                toast: true,
                position: 'top-end',
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
                didOpen: toast => {
                    toast.addEventListener('mouseenter', this.$swal.stopTimer)
                    toast.addEventListener('mouseleave', this.$swal.resumeTimer)
                },
                title: title,
                icon:  icon
            })
        },
        alert(title, text, icon) {
            this.$swal({
                title: title,
                text: text,
                icon: icon,
                confirmButtonColor: '#1178bd'
            })
        },
        confirmAlert(text) {
            return this.$swal({
                title: text,
                showCancelButton: true,
                confirmButtonText: `Да`,
                confirmButtonColor: '#EB5757',
                cancelButtonText: 'Отмена',
                cancelButtonColor: '#c2c2c5',
                icon: 'warning'
            })
        }
    }
}