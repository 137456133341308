<template>
  <div class="row">
    <div class="col-xl-8 col-xs-12">
      <issues-list-filter/>

      <div class="custom-card mb-3">
        <div class="card-body">
          <div class="row">

            <div class="col-md-12 d-flex align-items-center mb-3">
              <div class="issues-list-header mr-2">Активные заявки</div>
              <base-loader v-if="$store.state.issues.loading" />
            </div>

            <div
              v-if="!$store.state.issues.issues.length
                && !$store.state.issues.loading"
              class="col-md-12"
            >
              <div class="no-result-title">
                Заявки не найдены
              </div>
            </div>

            <div
              v-else-if="$store.state.issues.issues.length"
              class="col-md-12 items-list-overflow"
            >
              <issues-list
                :headers="activeIssuesHeaders"
                :issues="issues"
              />
            </div>

            <div
              v-if="this.$store.state.issues.meta
                && this.$store.state.issues.meta.last_page > 1"
              class="col-md-12"
            >
              <base-paginate
                :meta="$store.state.issues.meta"
                action="issues/getIssues"
                commit="issues/SET_PAGE"
              />
            </div>

          </div>
        </div>
      </div>

      <hr>

      <planned-issues-list-filter />

      <div class="custom-card mb-3">
        <div class="card-body">
          <div class="row">

            <div class="col-md-12 d-flex align-items-center mb-3">
              <div class="issues-list-header mr-2">Запланированные заявки</div>
              <base-loader v-if="$store.state.plannedIssues.loading" />
            </div>

            <div
              v-if="!$store.state.plannedIssues.issues.length
                && !$store.state.plannedIssues.loading"
              class="col-md-12"
            >
              <div class="no-result-title">
                Запланированные заявки не найдены
              </div>
            </div>

            <div
              v-else-if="$store.state.plannedIssues.issues.length"
              class="col-md-12 items-list-overflow"
            >
              <issues-list
                :headers="plannedIssuesHeaders"
                :issues="plannedIssues"
              />
            </div>

            <div
              v-if="this.$store.state.plannedIssues.meta
                && this.$store.state.plannedIssues.meta.last_page > 1"
              class="col-md-12"
            >
              <base-paginate
                :meta="$store.state.plannedIssues.meta"
                action="plannedIssues/getIssues"
                commit="plannedIssues/SET_PAGE"
              />
            </div>

          </div>
        </div>
      </div>

      <hr>

      <div class="custom-card">
        <div class="card-body">
          <div class="row">

            <div class="col-md-12 d-flex align-items-center mb-3">
              <div class="issues-list-header mr-2">Нераспределённые обращения</div>
              <base-loader v-if="$store.state.appeals.loading" />
            </div>

            <div
              v-if="!$store.state.appeals.appeals.length
                && !$store.state.appeals.loading"
              class="col-md-12"
            >
              <div class="no-result-title">
                Нераспределённые обращения не найдены
              </div>
            </div>

            <div
              v-if="$store.state.appeals.appeals.length"
              class="col-md-12 items-list-overflow"
            >
              <unallocated-appeals-list />
            </div>

            <div
              v-if="this.$store.state.appeals.meta
                && this.$store.state.appeals.meta.last_page > 1"
              class="col-md-12"
            >
              <base-paginate
                :meta="$store.state.appeals.meta"
                action="appeals/getAppeals"
                commit="appeals/SET_PAGE"
              />
            </div>

          </div>
        </div>
      </div>

    </div>
    <div class="col-xl-4 col-xs-12 map-base-height">
      <issues-list-map/>
    </div>
  </div>
</template>

<script>
import IssuesListMap from "@/components/ServiceDesk/Issues/IssuesListMap";
import IssuesList from "@/components/ServiceDesk/Issues/IssuesList";
import IssuesListFilter from "@/components/ServiceDesk/Issues/IssuesListFilter";
import IssueState from "@/models/serviceDesk/IssueState";
import {mapGetters} from "vuex";
import BaseLoader from "@/layout/BaseLoader";
import BasePaginate from "@/components/BasePaginate";
import UnallocatedAppealsList from "@/components/CallCenter/UnallocatedAppealsList";
import PlannedIssuesListFilter from "@/components/ServiceDesk/Issues/PlannedIssuesListFilter";

export default {
  name: "IssuesListPage",
  components: {
    PlannedIssuesListFilter,
    UnallocatedAppealsList,
    BaseLoader,
    IssuesListFilter,
    IssuesList,
    IssuesListMap,
    BasePaginate
  },
  data() {
    return {
      timing: null,
      activeIssuesHeaders: [
        {text: '#', value: 'id', type: 'text'},
        {text: 'Дата открытия', value: 'created_at', type: 'dateTime'},
        {text: 'Тип', value: 'type_name', type: 'text'},
        {text: 'Категория', value: 'category', type: 'category'},
        {text: 'Адрес', value: 'address', type: 'address'},
        {text: 'Подразделение', value: 'department_name', type: 'text'},
        {text: 'Обращений', value: 'appeals_count', type: 'text'},
        {text: 'Теги', value: 'tags', type: 'tags'},
        {text: 'Комментарий', value: 'description', type: 'text'},
        {text: 'Статус', value: 'state', type: 'badge'}
      ],
      plannedIssuesHeaders: [
        {text: '#', value: 'id', type: 'text'},
        {text: 'Дата открытия', value: 'created_at', type: 'dateTime'},
        {text: 'Плановая дата', value: 'activated_in', type: 'date'},
        {text: 'Тип', value: 'type_name', type: 'text'},
        {text: 'Категория', value: 'category', type: 'category'},
        {text: 'Адрес', value: '', type: 'address'},
        {text: 'Подразделение', value: 'department_name', type: 'text'},
        {text: 'Комментарий', value: 'description', type: 'text'}
      ]
    }
  },
  computed: {
    ...mapGetters({
      issues: 'issues/getIssues',
      plannedIssues: 'plannedIssues/getIssues'
    })
  },
  mounted() {
    this.$store.commit('issues/SET_FILTER_PARAMS', {
      issue_states: [
        IssueState.NEW,
        IssueState.APPOINTED,
        IssueState.ACCEPTED,
        IssueState.IN_WORK,
        IssueState.REJECTED
      ].join()
    });
    this.$store.commit('plannedIssues/SET_FILTER_PARAMS', {
      issue_states: IssueState.POSTPONED
    });
    this.$store.commit('appeals/SET_FILTER_PARAMS', {
      appeal_status: '1'
    })

    this.fetchData();
    this.timing = setInterval(this.fetchData, process.env.VUE_APP_SERVICE_DESK_GET_DATA_TIMING);

    this.$store.dispatch('departments/getAllDepartments');
  },
  beforeDestroy() {
    clearInterval(this.timing);
    this.$store.commit('issues/SET_ISSUES', []);
    this.$store.commit('plannedIssues/SET_ISSUES', []);
    this.$store.commit('issues/SET_FILTER_PARAMS', null);
    this.$store.commit('issues/SET_META', null);
    this.$store.commit('issues/SET_LINKS', null);
    this.$store.commit('issues/SET_PAGE', null);
    this.$store.commit('appeals/SET_FILTER_PARAMS', null);
    this.$store.commit('appeals/SET_APPEALS', []);
  },
  methods: {
    fetchData() {
      this.$store.dispatch("issues/getIssues", this.$store.state.issues.page);
      this.$store.dispatch('plannedIssues/getIssues', this.$store.state.plannedIssues.page);
      this.$store.dispatch('appeals/getAppeals', this.$store.state.appeals.page);
    }
  }
}
</script>

<style scoped>
.issues-list-header {
  font-size: 22px;
  font-weight: 600;
  color: #262F3D;
}
</style>