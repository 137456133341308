<template>
  <div class="custom-card">
    <div class="card-body">
      <div class="chart-title">Количество поступивших обращений за период
        <base-loader v-if="$store.state.appealsStatistic.appealsCountByDayLoading" />
      </div>
      <apexchart
        ref="chartComponent"
        type="bar"
        :options="createChartOptions"
        :series="createChartSeries"
      />
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import VueApexCharts from "vue-apexcharts";
import BaseLoader from "@/layout/BaseLoader";
export default {
  name: "AppealsCountByDay",
  components: {BaseLoader, 'apexchart': VueApexCharts},
  props: ['filter'],
  computed: {
    ...mapGetters({
      appealsCount: 'appealsStatistic/getAppealsCountByDay'
    }),
    createChartOptions() {
      return {
        legend: {
          show: true,
          markers: {
            fillColors: ['#0A6187', '#A4D9F6']
          }
        },
        chart: {
          type: 'bar',
          height: 320
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: '55%',
            endingShape: 'rounded',
            borderRadius: 0
          }
        },
        dataLabels: {
          enabled: true
        },
        stroke: {
          show: true,
          width: 2,
          colors: ['transparent']
        },
        xaxis: {
          categories: this.appealsCount.map(item => this.$moment(item.date).format('DD.MM.YYYY')),
          labels: {
            show: true,
            rotateAlways: true
          }
        },
        fill: {
          opacity: 1,
          colors: ['#0A6187', '#A4D9F6']
        },
        tooltip: {
          y: {
            formatter: function (val) {
              return val.toFixed()
            }
          }
        }
      }
    },
    createChartSeries() {
      return [{
        name: 'Количество обращений',
        data: this.appealsCount.map(item => item.count)
      }]
    }
  },
  watch: {
    filter: function () {
      this.$store.dispatch('appealsStatistic/getAppealsCountByDay', this.filter);
    }
  },
  mounted() {
    this.$store.dispatch('appealsStatistic/getAppealsCountByDay', this.filter);
  }
}
</script>

<style scoped>
.chart-title {
  font-size: 22px;
  font-weight: 600;
  color: #262F3D;
}
</style>