import * as API from './API'
import NetworkElement from "@/models/EngineeringNetworks/NetworkElement";
import {getError} from "@/utils/helpers";

export default {
    fetchAllNetworkElements(filter) {
        return new Promise((resolve, reject) => {
            API.apiClient.get('network-engineering/elements/all', {params: filter})
                .then(response => resolve(
                    response.data.data.map(item => new NetworkElement(item.id, item.attributes))
                ))
                .catch(error => reject(getError(error)));
        })
    }
}