<template>
  <div class="custom-card">
    <div class="card-body">
      <div class="container-fluid">
        <div class="row">
          <div class="col-12">
            <form @submit.prevent="checkType">

              <div class="form-group">
                <label class="form-label">Наименование</label>
                <input
                  v-model="payload.name"
                  class="form-control"
                  type="text"
                  placeholder="Введите наименование"
                  required
                >
              </div>

              <div class="form-group">
                <label class="form-label">Тип</label>
                <v-select
                  v-model="payload.type"
                  :options="dataServerTypes"
                  :reduce="type => type.id"
                  placeholder="Выберите тип сервера"
                  :get-option-label="(option) => option.name"
                />
              </div>

              <div class="form-group">
                <label class="form-label">Адрес</label>
                <input
                  v-model="payload.address"
                  class="form-control"
                  type="text"
                  placeholder="Введите адрес"
                  required
                >
              </div>

              <div class="form-group">
                <label class="form-label">Тип аутентификации</label>
                <v-select
                  v-model="payload.auth_type"
                  :options="authTypes"
                  :reduce="authType => authType.id"
                  placeholder="Выберите тип аутентификации"
                />
              </div>

              <div
                v-if="payload.auth_type === 'login_and_password'"
                class="form-group"
              >
                <label class="form-label">Логин</label>
                <input
                  v-model="payload.username"
                  class="form-control"
                  type="text"
                  placeholder="Введите логин"
                >
              </div>

              <div
                v-if="payload.auth_type === 'login_and_password'"
                class="form-group"
              >
                <label class="form-label">Пароль</label>
                <input
                  v-model="payload.password"
                  class="form-control"
                  type="text"
                  placeholder="Введите пароль"
                >
              </div>

              <div
                v-if="payload.auth_type === 'token'"
                class="form-group"
              >
                <label class="form-label">Ключ</label>
                <input
                  v-model="payload.token"
                  class="form-control"
                  type="text"
                  placeholder="Введите ключ"
                >
              </div>

              <div class="text-center">
                <button
                  type="submit"
                  class="btn custom-btn-blue w-100"
                >
                  Сохранить
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import notification from "@/mixins/notification";
import {mapGetters} from "vuex";

export default {
  name: "TransportDataServerForm",
  mixins: [notification],
  data() {
    return {
      payload: {
        name: null,
        type: null,
        address: null,
        auth_type: null,
        username: null,
        password: null,
        token: null,
        is_active: true
      },
      type: 'create',
      authTypes: [
        {id: 'no_auth', label: 'Без аутентификации'},
        {id: 'login_and_password', label: 'Логин и пароль'},
        {id: 'token', label: 'По ключу'}
      ],
      dataServerId: null
    }
  },
  computed: {
    ...mapGetters({
      dataServer: 'transportDataServers/getTransportDataServer',
      dataServerTypes: 'transportDataServerTypes/getDataServerTypes'
    })
  },
  mounted() {
    this.$store.dispatch('transportDataServerTypes/getAllTransportDataServerTypes');

    if (this.$route.name === 'UpdateTransportDataServer') {
      this.type = 'update';
      const dataServer = this.dataServer(this.$route.params.id);
      this.dataServerId = dataServer.id;
      this.payload.name = dataServer.name;
      this.payload.type = dataServer.type.id;
      this.payload.address = dataServer.address;
      this.payload.auth_type = dataServer.auth_type;
      this.payload.username = dataServer.username;
      this.payload.password = dataServer.password;
      this.payload.token = dataServer.token;
      this.payload.is_active = dataServer.is_active;
    }
  },
  methods: {
    checkType() {
      if (this.type === 'create') this.createTransportDataServer()
      else this.updateTransportDataServer()
    },
    createTransportDataServer() {
      Object.keys(this.payload).forEach(key => {
        if (this.payload[key] === null) {
          delete this.payload[key];
        }
      });

      this.$store.dispatch('transportDataServers/createTransportDataServer', this.payload)
          .then(() => {
            this.toast('Сервер данных транспорта добавлен', 'success');
            this.$router.push('/transport-data-servers');
          })
          .catch(error => this.alert('Ошибка', error, 'error'));
    },
    updateTransportDataServer() {
      Object.keys(this.payload).forEach(key => {
        if (this.payload[key] === null) {
          delete this.payload[key];
        }
      });

      this.$store.dispatch('transportDataServers/updateTransportDataServer', {
        dataServerId: this.dataServerId,
        payload: this.payload
      })
          .then(() => {
            this.toast('Сервер данных транспорта обновлён', 'success');
            this.$router.push('/transport-data-servers');
          })
          .catch(error => this.alert('Ошибка', error, 'error'));
    }
  }
}
</script>

<style scoped>

</style>