<template>
  <table class="admin-table">
    <thead class="admin-table-header">
      <tr>
        <td>ID</td>
        <td>Наименование</td>
        <td>Входящие зоны</td>
        <td>Расходомеры</td>
        <td>Действия</td>
      </tr>
    </thead>
    <tbody class="admin-table-body">
      <tr
        v-for="balanceGroup in this.$store.state.balanceGroup.balanceGroups"
        :key="balanceGroup.id"
      >
        <td>{{ balanceGroup.id }}</td>
        <td>{{ balanceGroup.attributes.name }}</td>
        <td>{{ getZoneNames(balanceGroup.attributes.zones) }}</td>
        <td>{{ getDevicesNames(balanceGroup.attributes.devices) }}</td>
        <td class="table-action">
          <router-link :to="'/admin-balance-group-update/'+balanceGroup.id">
            <edit-icon class="mr-1" />
          </router-link>
          <a>
            <trash2-icon @click="deleteBalanceGroup(balanceGroup.id)" />
          </a>
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
import BalanceGroupService from "@/services/BalanceGroupService";
import {mapGetters} from "vuex";
export default {
  name: "BalanceGroupsList",
  computed: {
    ...mapGetters({
      getZoneNames: 'zone/getNames',
      getDevicesNames: 'device/getNames'
    })
  },
  methods: {
    async deleteBalanceGroup(balanceGroup_id) {
      this.$swal({
        title: 'Вы действительно хотите удалить группу?',
        showCancelButton: true,
        confirmButtonText: `Да`,
        cancelButtonText: 'Отмена',
        icon: 'warning'
      }).then(result => {
        if (result.isConfirmed) {
          BalanceGroupService.deleteBalanceGroup(balanceGroup_id).then(() => {
            this.$swal('Группу успешно удалена!', '', 'success')
            this.$store.commit('balanceGroup/DELETE_BALANCE_GROUP', balanceGroup_id);
          })
        }
      });
    }
  }
}
</script>

<style scoped>

</style>