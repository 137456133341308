<template>
  <div class="map-base-height">
    <base-map :center="center"
              :zoom="zoom"
              :circles="createCircles()"
              :layers="layers"
    />
  </div>
</template>

<script>
import BaseMap from "@/components/BaseMap";
import Circle from "@/models/baseMap/Circle";
import {mapState} from "vuex";

export default {
  name: "BillingClientsMap",
  components: {BaseMap},
  props: ['buildings'],
  computed: {
    ...mapState({
      center: state => state.baseMap.center,
      zoom: state => state.baseMap.zoom,
      layers: state => state.baseMap.layers
    }),
    getGeometryColor: function () {
      return clients_group => {
        let group_debt = clients_group.attributes.consumers
            .filter(item => item.attributes.saldo < 0)
            .reduce((sum, item) => sum + Math.abs(item.attributes.saldo), 0);

        if (group_debt === 0) return "#468FC5";
        else if (group_debt > 0 && group_debt < 1000) return "#ffc149";
        else if (group_debt >= 1000 && group_debt < 10000) return "#ff8800";
        else return "#E02800";
      }
    }
  },
  mounted() {
    this.$store.dispatch('baseMap/getLayers');
  },
  methods: {
    createCircles() {
      return this.$store.getters['client/getClients'].map(client => {
        return new Circle({
          coords: client.attributes.geometry.coordinates,
          radius: 10,
          color: this.getGeometryColor(client)
        })
      })
    }
  }
}
</script>

<style scoped>

</style>