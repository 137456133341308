import {getError} from "@/utils/helpers";
import AutosService from "@/services/AutosService";

export const namespaced = true;

function setLocalStorageItem(ls_key, id) {
    let ls = JSON.parse(localStorage.getItem(ls_key))
    if (ls.includes(id)) {
        let index = ls.indexOf(id)
        ls.splice(index, 1)
    } else {
        ls.push(id)
    }
    localStorage.setItem(ls_key, JSON.stringify(ls))
}

export const state  = {
    autos: [],
    page: null,
    movement_history: [],
    display_type: 1,
    meta: null,
    links: null,
    loading: false,
    error: null
};

export const mutations = {
    SET_AUTOS(state, autos) {
        state.autos = autos;
    },
    SET_PAGE(state, page) {
        state.page = page;
    },
    SET_META(state, meta) {
        state.meta = meta;
    },
    SET_LINKS(state, links) {
        state.links = links;
    },
    SET_LOADING(state, loading) {
        state.loading = loading;
    },
    SET_ERROR(state, error) {
        state.error = error;
    },
    SET_MOVEMENT_HISTORY(state, movement_history) {
        state.movement_history = movement_history;
    },
    DELETE_AUTOS(state, id){
        let index = state.autos.findIndex(auto => auto.id == id);
        state.autos.splice(index, 1);
    },
    TOGGLE_AUTO_ON_MAP(state, id) {
        setLocalStorageItem('hideMapAutos', id);

        let auto = state.autos.find(auto => auto.id === id);
        auto.showOnMap = !auto.showOnMap;
    },
    TOGGLE_AUTO_IN_LIST(state, id) {
        setLocalStorageItem('hideListAutos', id);

        let auto = state.autos.find(auto => auto.id === id);
        auto.showInList = !auto.showInList
    },
    TOGGLE_AUTO_FIX_MAP(state, id) {
        setLocalStorageItem('fixAutoMap', id);

        let auto = state.autos.find(auto => auto.id === id);
        auto.fixOnMap = !auto.fixOnMap;
    },
    SET_DISPLAY_TYPE(state, type) {
        state.display_type = type;
    },
    CLEAR_MOVEMENT_HISTORY(state) {
        state.movement_history = []
    }
};

export const actions = {
    getAutos({ commit }, page) {
        commit("SET_LOADING", true);
        AutosService.getAutos(page)
            .then(({autos, meta}) => {
                commit("SET_AUTOS", autos);
                commit("SET_META", meta);
            })
            .catch(error => {
                commit("SET_ERROR", getError(error));
            })
            .finally(() => commit("SET_LOADING", false))
    },
    getAllAutos({ commit }, filterParams) {
        commit("SET_LOADING", true);
        AutosService.getAllAutos(filterParams)
            .then(vehicles => commit("SET_AUTOS", vehicles))
            .catch(error => commit("SET_ERROR", error))
            .finally(() => commit("SET_LOADING", false))
    },
    getMovementHistory({commit}, payload) {
        return new Promise((resolve, reject) => {
            AutosService.doYouKnowTheWay(payload.from, payload.to, payload.id)
                .then(response => {
                    commit('SET_MOVEMENT_HISTORY', response.data.data);
                    resolve();
                })
                .catch(error => {
                    commit('SET_ERROR', getError(error))
                    reject();
                })
        })
    },
    sendVehicleToRepair(context, vehicleId) {
        return new Promise((resolve, reject) => {
            AutosService.sendVehicleToRepair(vehicleId)
                .then(() => resolve())
                .catch(error => reject(getError(error)))
        })
    },
    makeVehicleAvailable(context, vehicleId) {
        return new Promise((resolve, reject) => {
            AutosService.makeVehicleAvailable(vehicleId)
                .then(() => resolve())
                .catch(error => reject(getError(error)))
        })
    }
};

export const getters = {
    getAutos: state => {
        return state.autos
    },
    getAuto: state => id =>{
        return state.autos.find(auto => auto.id == id);
    },
    getLabel: state => id =>{
        if (state == null || id == 0) {
            return '';
        } else {
            return state.autos.find(auto => auto.id == id).attributes.label;
        }
    },
    getGovNumber: state => id =>{
        if (state == null || id == 0) {
            return '';
        } else {
            return state.autos.find(auto => auto.id == id).attributes.gov_number;
        }
    },
    getIds: state => {
        return state.autos.map(auto => auto.attributes.external_id);
    },
    getFilterAutos: state => (speedSort, sortList, query) => {
        try{
            let autos = state.autos.filter(
                auto => state.display_type === 0 ? true : auto.attributes.type === state.display_type)
            autos = autos.filter(auto => auto.showInList)
            if (query !== ''){
                autos = autos.filter(auto =>
                    auto.attributes.label.toLowerCase().includes(query.toLowerCase()) ||
                    auto.attributes.gov_number.toLowerCase().includes(query.toLowerCase()));
            }
            if (speedSort && sortList.length > 0) {
                autos = autos.sort(function (a,b) {
                    return sortList.findIndex(i => i.id === a.attributes.external_id) -
                        sortList.findIndex(i => i.id === b.attributes.external_id)
                });
            }
            if (!speedSort && sortList.length > 0){
                autos = autos.sort(function (a,b){
                    return sortList.indexOf(a.id) - sortList.indexOf(b.id)
                });
            }
            return autos;
        } catch (e) {
            alert('Данные отсутствуют')
        }
    },
    autos: state => {
        if (state == null) {
            return [];
        } else {
            return state.autos;
        }
    },
    autosMap: state => hideMap => {
        if (state == null) {
            return [];
        } else {
            return state.autos.filter(a => hideMap.indexOf(a.id) == -1);
        }
    },
    getMovementHistory: state => {
        return state.movement_history;
    },
    meta: state => {
        return state.meta;
    },
    links: state => {
        return state.links;
    },
    loading: state => {
        return state.loading;
    },
    error: state => {
        return state.error;
    }
}