<template>
  <b-modal
    id="update-emergency-element"
    ref="update-emergency-element"
    centered
    hide-header
    hide-footer
  >
    <div class="col-12">
      <div class="custom-modal-header">
        <div class="custom-modal-title">
          Изменить аварийный элемент
        </div>
        <img
          class="custom-modal-close"
          src="@/assets/img/icons/close.svg"
          alt="Закрыть"
          @click="$bvModal.hide('update-emergency-element')"
        >
      </div>
      <div class="modal-divider" />
    </div>
    <form v-if="element"
          @submit.prevent="submitForm()">
      <div class="col-12 mb-2">
        <div v-for="feature in features"
             :key="feature.feature_type"
             class="form-group">
          <label class="form-label">{{ feature.name }}</label>
          <input v-model="feature.value"
                 placeholder="Введите значение"
                 type="text"
                 class="form-control form-control-fix">
        </div>
        <div class="modal-divider" />
        <div class="row">
          <div class="col-12">
            <button
              type="submit"
              class="btn custom-btn-blue w-100"
            >
              Изменить
            </button>
          </div>
        </div>
      </div>
    </form>
  </b-modal>
</template>

<script>
import notification from "@/mixins/notification";
import {mapGetters} from "vuex";

export default {
  name: "UpdateEmergencyElement",
  mixins: [notification],
  props: ['element'],
  data() {
    return {
      features: []
    }
  },
  computed: {
    ...mapGetters({
      task: 'task/getTask'
    })
  },
  watch: {
    element: function () {
      this.features = this.element.features
          .concat()
          .map(feature => {
            return {feature_type: feature.feature.id, value: feature.value, name: feature.feature.name}
          })
    }
  },
  methods: {
    submitForm() {
      this.$store.dispatch('emergencyElements/updateEmergencyElement', {
        issue_id: this.task.issue.id,
        element_id: this.element.id,
        payload: {
          features: this.features.map(feature => {
            return {feature_type: feature.feature_type, value: feature.value}
          })
        }
      })
          .then(() => {
            this.toast('Аварийный элемент обновлён', 'success');
            this.$bvModal.hide('update-emergency-element');
            this.$store.dispatch('emergencyElements/getEmergencyElements', this.task.issue.id);
          })
          .catch(error => this.alert('Ошибка', error, 'error'));
    }
  }
}
</script>

<style scoped>

</style>