<template>
  <div class="row justify-content-center align-items-center fixed-height">
    <div class="col-xl-4 col-xs-12">
      <network-object-types-form />
    </div>
  </div>
</template>

<script>
import NetworkObjectTypesForm from "@/components/Telemetry/admin/NetworkObjectTypesForm";
export default {
  name: "NetworkObjectTypesFormPage",
  components: {NetworkObjectTypesForm}
}
</script>

<style scoped>

</style>