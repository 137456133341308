<template>
  <b-modal id="add-shutdown"
           ref="add-shutdown"
           centered
           hide-header
           hide-footer>
    <div class="col-12">
      <div class="custom-modal-header">
        <div class="custom-modal-title">
          Добавить отключение
        </div>
        <img
          class="custom-modal-close"
          src="@/assets/img/icons/close.svg"
          @click="$bvModal.hide('add-shutdown')"
        >
      </div>
      <div class="modal-divider" />
    </div>
    <form @submit.prevent="submitForm()">
      <div class="col-12 mb-2">
        <div class="form-group">
          <label class="form-label">Плановая дата начала</label>
          <date-picker
            v-model="payload.planned_start_date"
            :default-value="defaultValue"
            input-class="form-control-fix w-100 pl-2"
            placeholder="Выберите дату"
            type="datetime"
            :format="DATE_TIME_FORMAT"
            value-type="YYYY-MM-DD HH:mm"
          />
        </div>
        <div class="form-group">
          <label class="form-label">Плановая дата окончания</label>
          <date-picker
            v-model="payload.planned_end_date"
            :default-value="defaultValue"
            input-class="form-control-fix w-100 pl-2"
            placeholder="Выберите дату"
            type="datetime"
            :format="DATE_TIME_FORMAT"
            value-type="YYYY-MM-DD HH:mm"
          />
        </div>
        <div class="form-group">
          <label class="form-label">Комментарий</label>
          <input v-model="payload.comment"
                 type="text"
                 class="form-control form-control-fix"
                 placeholder="Введите комментарий">
        </div>
        <div class="modal-divider" />
        <div class="row">
          <div class="col-12">
            <button
              type="submit"
              class="btn custom-btn-blue w-100"
            >
              Добавить
            </button>
          </div>
        </div>
      </div>
    </form>
  </b-modal>
</template>

<script>
import {mapGetters} from "vuex";
import notification from "@/mixins/notification";
import DatePicker from "vue2-datepicker";

export default {
  name: "AddShutdownForm",
  components: {DatePicker},
  mixins: [notification],
  data() {
    return {
      payload: {
        planned_start_date: null,
        planned_end_date: null,
        comment: null
      },
      defaultValue: new Date()
    }
  },
  computed: {
    ...mapGetters({
      issueId: 'issue/getIssueId'
    }),
    DATE_TIME_FORMAT: () => process.env.VUE_APP_BASE_DATE_TIME_FORMAT
  },
  methods: {
    submitForm() {
      const payload = {
        payload: this.payload,
        issue_id: this.issueId
      }
      this.$store.dispatch('shutdowns/createShutdown', payload)
          .then(() => {
            this.payload = {
              planned_start_date: null,
              planned_end_date: null,
              comment: null
            };
            this.$bvModal.hide('add-shutdown');
            this.toast('Отключение добавлено', 'success');
            this.$store.dispatch('shutdowns/getIssueShutdowns', this.issueId);
            this.$store.dispatch('history/getIssueHistory', this.issueId);
          })
          .catch(error => {
            this.alert('Ошибка', error, 'error');
          })
    }
  }
}
</script>

<style scoped>

</style>