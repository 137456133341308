<template>
  <div class="row">
    <div class="col-12">
      <div class="custom-card">
        <div class="card-body">
          <div class="row">
            <div class="col-12">
              <h4 class="table-title d-inline-block">
                Подразделения
              </h4>
              <div v-if="$store.state.departments.loading"
                   class="d-inline-block ml-2">
                <base-loader />
              </div>
              <router-link to="/create-department">
                <button class="btn custom-btn-blue float-right">
                  Добавить подразделение
                </button>
              </router-link>
              <hr class="divider">
            </div>
            <div v-if="$store.state.departments.departments.length"
                 class="col-12">
              <departments-list />
            </div>
            <div v-if="!$store.state.departments.departments.length && !$store.state.departments.loading"
                 class="col-12 mt-2">
              <div class="no-result-title">Подразделения не найдены</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DepartmentsList from "@/components/ServiceDesk/admin/DepartmentsList";
import BaseLoader from "@/layout/BaseLoader";
import store from "@/store";

export default {
  name: "DepartmentsListPage",
  components: {BaseLoader, DepartmentsList},
  beforeRouteEnter(to, from, next) {
    const currentPage = parseInt(to.query.page) || 1;
    store.dispatch("departments/getDepartments", currentPage).then(() => {
      to.params.page = currentPage;
      next();
    });
  }
}
</script>

<style scoped>

</style>