<template>
  <div class="base-map">
    <div v-if="this.layers"
         class="map-control map-layers-control"
         :style="[{top: layers_control_position_top}, {right: layers_control_position_right}]">
      <div class="map-control-icon">
        <img src="@/assets/img/icons/layers.svg"
             alt="слои">
      </div>
      <div class="map-control-content">
        <label v-for="(layer, index) in layers_control"
               :key="index"
               class="custom-control custom-radio d-flex align-items-center">
          <input v-model="selected_layer"
                 :value="index"
                 class="custom-control-input"
                 name="layer-radio"
                 type="radio">
          <span class="custom-control-label">{{ layer.name }}</span>
        </label>
      </div>
    </div>

    <div class="map-control map-base-layers-control"
         :style="[{top: base_layers_control_position_top}, {right: base_layers_control_position_right}]">
      <div class="map-control-icon">
        <img src="@/assets/img/icons/base-layer.svg"
             alt="слои">
      </div>
      <div class="map-control-content">
        <label v-for="(baseLayer, index) in baseLayers"
               :key="baseLayer.id"
               class="custom-control custom-radio d-flex align-items-center">
          <input v-model="selected_base_layer"
                 :value="index"
                 class="custom-control-input"
                 name="base-layer-radio"
                 type="radio">
          <span class="custom-control-label">{{baseLayer.name}}</span>
        </label>
      </div>
    </div>

    <div :id="`map`+idKey"
         ref="map"
         class="base-map"/>
  </div>
</template>

<script>
import * as L from 'leaflet'
import 'leaflet-wms-header';
import 'leaflet/dist/leaflet.css';
import "leaflet-rotatedmarker";
import 'leaflet.markercluster/dist/MarkerCluster.css'
import 'leaflet.markercluster/dist/MarkerCluster.Default.css'
import 'leaflet.markercluster/dist/leaflet.markercluster'
import {mapState} from "vuex";

delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png')
});

export default {
  name: "BaseMap",
  props: {
    idKey: {
      type: String,
      default: ''
    },
    center: {
      type: Array,
      default: () => [],
      required: true
    },
    zoom: {
      type: Number,
      default: 10,
      required: true
    },
    layers: {
      type: Array
    },
    baseLayerOpacity: {
      type: Number,
      default: 0.75
    },
    attributionControl: {
      type: Boolean,
      default: false
    },
    set_marker_by_click: {
      type: Boolean
    },
    markers: {
      type: Array
    },
    polygons: {
      type: Array
    },
    polyline: {
      type: Object
    },
    polylines: {
      type: Array
    },
    circles: {
      type: Array
    },
    layers_control_position_top: {
      type: String,
      default: '10px'
    },
    layers_control_position_right: {
      type: String,
      default: '20px'
    },
    base_layers_control_position_top: {
      type: String,
      default: '60px'
    },
    base_layers_control_position_right: {
      type: String,
      default: '20px'
    },
    needResize: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      map: null,
      layers_control: {},
      base_layers_group: L.layerGroup(),
      show_movement_history_btn: false,
      selected_layer: 0,
      selected_base_layer: 0,
      marker_cluster_group: window.L.markerClusterGroup({
        showCoverageOnHover: false,
        maxClusterRadius: 10
      }),
      markers_group: L.layerGroup(),
      polygons_group: L.layerGroup(),
      polyline_group: L.layerGroup(),
      polylines_group: L.layerGroup(),
      circles_group: L.layerGroup(),
      tile_layer_group: L.layerGroup()
    }
  },
  computed: {
    ...mapState({
      baseLayers: state => state.baseLayers.baseLayers
    })
  },
  watch: {
    center: function (center) {
      this.map.setView(center, this.zoom);
    },
    layers: function (layers, oldLayers) {
      if (JSON.stringify(layers) !== JSON.stringify(oldLayers)) {
        this.createLayers(layers);
      }
    },
    selected_layer: function (selected_layer) {
      this.setLayer(selected_layer);
    },
    selected_base_layer: function (selected_layer) {
      this.setBaseLayer(selected_layer)
    },
    markers: function (markers) {
      this.setMarkers(markers);
    },
    polygons: function (polygons) {
      this.setPolygons(polygons);
    },
    polyline: function (polyline) {
      this.setPolyline(polyline);
    },
    polylines: function (polylines) {
      this.setPolyLines(polylines);
    },
    circles: function (circles) {
      this.setCircles(circles);
    },
    needResize: function (value) {
      if (value) this.map.invalidateSize();
    }
  },
  mounted() {
    this.$store.dispatch('baseLayers/getBaseLayers')
        .then(() => this.initMap())
  },
  methods: {
    initMap() {
      this.map = L.map(`map${this.idKey}`, {
        center: this.center,
        zoom: this.zoom,
        attributionControl: this.attributionControl
      });

      this.map.on('click', e => {
        this.$root.$emit('BaseMap::map-click', e.latlng)
      })

      this.setBaseLayer(0)
    },
    createWMS(layer) {
      let auth = btoa(layer.username + ':' + layer.password)
      return L.TileLayer.wmsHeader(
          layer.url,
          {
            layers: layer.resource,
            transparent: true,
            crossOrigin: true,
            format: 'image/png'
          },
          [
            {
              header: "Authorization",
              value: `Basic ` + auth
            }
          ],
          null,
          null
      )
    },
    createLayers(layers) {
      this.layers_control = [];
      layers.forEach(layer => {
        this.layers_control.push({
          name: layer.name,
          layer: this.createWMS(layer)
        })
      });
      this.setLayer(0);
    },
    setLayer(layer) {
      this.base_layers_group.clearLayers();
      if (this.layers_control[layer] !== undefined) {
        this.base_layers_group.addLayer(this.layers_control[layer].layer);
      }
      this.base_layers_group.addTo(this.map);
    },
    setBaseLayer(selected_base_layer) {
      this.tile_layer_group.clearLayers();

      const base_layer = this.baseLayers[selected_base_layer];
      const l_tileLayer = L.tileLayer(base_layer.url, {
        opacity: this.baseLayerOpacity,
        reuseTiles: true,
        updateWhenIdle: false
      })

      this.tile_layer_group.addLayer(l_tileLayer);
      this.tile_layer_group.addTo(this.map);
      this.setLayer(this.selected_layer);
    },
    setMarkers(markers) {
      this.markers_group.clearLayers();
      this.marker_cluster_group.clearLayers();

      markers.forEach(marker => {
        let options = {};
        let l_icon;

        if (marker.icon.type === 'icon') {
          l_icon = L.icon({
            iconUrl: marker.icon.url,
            iconSize: marker.icon.size,
            iconAnchor: marker.icon.anchor
          })
        } else if (marker.icon.type === 'div_icon') {
          l_icon = L.divIcon({
            html: marker.icon.html,
            className: marker.icon.class,
            iconAnchor: marker.icon.anchor
          })
        }

        options.icon = l_icon
        if (marker.rotation_angle) options.rotationAngle = marker.rotation_angle
        let l_marker = L.marker(marker.coords, options);

        if (marker.popup_content) l_marker.bindPopup(marker.popup_content);
        if (marker.accumulation) this.marker_cluster_group.addLayer(l_marker);
        else this.markers_group.addLayer(l_marker);

      });

      this.marker_cluster_group.addTo(this.map);
      this.markers_group.addTo(this.map);
    },
    setPolygons(polygons) {
      this.polygons_group.clearLayers();

      polygons.forEach(item => {

        let polygon = L.polygon(item.coords, {
          color: item.color,
          fillColor: item.fill,
          fillOpacity: item.fillOpacity,
          weight: item.weight
        });

        if (item.popup_content) polygon.bindPopup(item.popup_content);

        this.polygons_group.addLayer(polygon);
      })

      this.polygons_group.addTo(this.map);
    },
    setPolyline(polyline) {
      this.polyline_group.clearLayers();

      let l_polyline = L.polyline(polyline.coords, {color: polyline.color});
      this.polyline_group.addLayer(l_polyline);

      this.polyline_group.addTo(this.map);
    },
    setPolyLines(polylines) {
      this.polylines_group.clearLayers();

      polylines.forEach(polyline => {
        const l_polyline = L.polyline(polyline.coords, {color: polyline.color});
        this.polylines_group.addLayer(l_polyline);
      })

      this.polylines_group.addTo(this.map);
    },
    setCircles(circles) {
      this.circles_group.clearLayers();

      circles.forEach(circle => {
        let l_circle = L.circle(circle.coords, {
          radius: circle.radius,
          color: circle.color
        });

        this.circles_group.addLayer(l_circle);
      })

      this.circles_group.addTo(this.map);
    }
  }
}
</script>

<style scoped>
.base-map {
  height: 100% !important;
  border-radius: 10px;
}

.map-control {
  position: absolute;
}

.map-layers-control {
  z-index: 402;
}

.map-base-layers-control {
  z-index: 401;
}

.map-control-icon {
  width: 40px;
  height: 40px;
  background-color: #EAEFF2;
  border: 1px solid #97ACB5;
  border-radius: 7px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.map-control-icon img {
  width: 24px;
  height: 24px;
}

.map-control:hover .map-control-icon {
  display: none;
}

.map-control:hover .map-control-content {
  display: block;
}

.map-control-content {
  font-family: Nunito, -apple-system, BlinkMacSystemFont, Segoe UI, Helvetica Neue, Arial, sans-serif;
  display: none;
  background-color: #EAEFF2;
  border: 1px solid #97ACB5;
  border-radius: 7px;
  padding: 8px;
}
</style>
