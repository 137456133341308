import CommentsService from "@/services/CommentsService";

export const namespaced = true;

export const state = {
    comments: [],
    loading: false,
    error: null
}

export const mutations = {
    SET_COMMENTS(state, comments) {
        state.comments = comments;
    },
    SET_LOADING(state, loading) {
        state.loading = loading;
    },
    SER_ERROR(state, error) {
        state.error = error;
    }
}

export const actions = {
    getTaskComments({commit}, task_id) {
        commit("SET_LOADING", true);
        CommentsService.getTaskComments(task_id)
            .then(comments => commit("SET_COMMENTS", comments))
            .catch(error => commit("SER_ERROR", error))
            .finally(() => commit("SET_LOADING", false));
    },
    getIssueComments({commit}, issue_id) {
        commit("SET_LOADING", true);
        CommentsService.fetchIssueCommentItems(issue_id)
            .then(comments => commit("SET_COMMENTS", comments))
            .catch(error => commit("SER_ERROR", error))
            .finally(() => commit("SET_LOADING", false));
    }
}

export const getters = {
    getComments: state => {
        return state.comments;
    }
}