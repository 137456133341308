<template>
  <div class="row">
    <div class="col-xl-4 col-xs-12 mb-3">
      <departments-form />
    </div>
    <div v-if="$route.params.id"
         class="col-xl-8 col-xs-12">
      <div class="row">
        <div class="col-12 mb-3">
          <department-staff-list />
        </div>
        <div class="col-12">
          <department-brigades-list />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DepartmentsForm from "@/components/ServiceDesk/admin/DepartmentsForm";
import DepartmentStaffList from "@/components/ServiceDesk/admin/DepartmentStaffList";
import DepartmentBrigadesList from "@/components/ServiceDesk/admin/DepartmentBrigadesList";
export default {
  name: "DepartmentsFormPage",
  components: {DepartmentBrigadesList, DepartmentStaffList, DepartmentsForm}
}
</script>

<style scoped>

</style>