import ProjectConfigService from "@/services/ProjectConfigService";
import Echo from "laravel-echo";

export const namespaced = true;

export const state = {
    projectConfig: null,
    error: null
}

export const mutations = {
    SET_PROJECT_CONFIG(state, config) {
        state.projectConfig = config;
    },
    SET_ERROR(state, error) {
        state.error = error;
    },
    SET_WS_CLIENT(state, ws_app_key) {
        window.Echo = new Echo({
            broadcaster: 'pusher',
            key: ws_app_key,
            httpHost: window.location.hostname,
            wsHost: window.location.hostname,
            wsPort: window.location.port,
            wssPort: window.location.port,
            wsPath: '/websocket',
            cluster: "mt1",
            forceTLS: false,
            authEndpoint: '/auth/broadcasting/auth',
            enabledTransports: ['ws', 'wss']
        });
    }
}

export const actions = {
    getProjectConfig({commit}) {
        ProjectConfigService.getProjectConfig()
            .then(config => {
                commit("SET_PROJECT_CONFIG", config);
                commit("baseMap/SET_CENTER_ZOOM", {
                    center: config.coordinates,
                    zoom: config.zoom
                }, {root: true});
                commit('SET_WS_CLIENT', config.ws_app_key)
            })
            .catch(error => commit("SET_ERROR", error))
    }
}

export const getters = {
    getProjectName: state => {
        if (state.projectConfig) return state.projectConfig.name;
    },
    getProjectCoordinates: state => {
        if (state.projectConfig) return state.projectConfig.coordinates;
    },
    getProjectZoom: state => {
        if (state.projectConfig) return state.projectConfig.zoom;
    }
}
