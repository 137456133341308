<template>
  <div class="row">
    <div class="col-xl-4 col-lg-6 col-md-6 col-sm-6 col-xs-12 mb-3">
      <div class="custom-card">
        <div class="card-header">
          <h1 class="card-title mb-0">
            Объект сети
          </h1>
        </div>
        <div class="card-body">
          <div class="container-fluid">
            <div class="row">
              <network-object-create-form />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import NetworkObjectCreateForm from "@/components/Telemetry/admin/NetworkObject/NetworkObjectCreateForm";

export default {
  name: "NetworkObjectsFormPage",
  components: {
    NetworkObjectCreateForm
  }
}
</script>

<style scoped>
</style>