<template>
  <div class="row justify-content-center">
    <div class="col-md-4">
      <div class="custom-card mb-0">
        <div class="card-body">
          <div class="container-fluid">
            <div class="row">
              <div class="col-md-12">
                <form @submit.prevent="checkType">
                  <div class="form-group">
                    <label class="form-label">Наименование</label>
                    <input
                      v-model="name"
                      type="text"
                      class="form-control form-control-fix"
                      placeholder="Наименование"
                      required
                    >
                  </div>
                  <div class="form-group">
                    <label class="form-label">Показания для датчика</label>
                    <select
                      v-model="sensor_id"
                      class="form-control form-control-fix mb-3"
                    >
                      <option value="">
                        Не привязывать
                      </option>
                      <option
                        v-for="sensor in this.$store.state.sensor.sensors"
                        :key="sensor.id"
                        :value="sensor.id"
                      >
                        {{ nameDevice(sensor.attributes.device_id) }} - {{ sensor.attributes.sensor_measure }}
                      </option>
                    </select>
                  </div>
                  <div class="form-group">
                    <label class="form-label">Тип показаний</label>
                    <select
                      v-model="typeConfig"
                      class="form-control form-control-fix mb-3"
                      required
                    >
                      <option value="1">
                        Уровень РЧВ
                      </option>
                      <option value="2">
                        УЗР - накопленый
                      </option>
                      <option value="3">
                        УЗР - моментальный
                      </option>
                      <option value="4">
                        Стоки
                      </option>
                      <option value="5">
                        Текстовое значение
                      </option>
                    </select>
                  </div>
                  <div class="form-group">
                    <label class="form-label">Время сбора показаний</label>
                    <select
                      v-model="times"
                      class="form-control form-control-fix mb-3"
                      required
                      multiple=""
                    >
                      <option
                        v-for="hour in 24"
                        :key="hour"
                        :value="hour - 1"
                      >
                        {{ hour - 1 }}
                      </option>
                    </select>
                  </div>
                  <div class="form-group">
                    <label class="form-label">Сортировка</label>
                    <input
                      v-model="sort"
                      type="text"
                      class="form-control form-control-fix"
                      placeholder="Введите номер сортировки"
                    >
                  </div>
                  <div class="form-group">
                    <div class="custom-control custom-switch">
                      <input
                        id="customSwitch1"
                        v-model="active"
                        type="checkbox"
                        class="custom-control-input"
                      >
                      <label
                        class="custom-control-label"
                        for="customSwitch1"
                      >Активно</label>
                    </div>
                  </div>
                  <div style="text-align: center">
                    <button
                      type="submit"
                      class="btn custom-btn-blue w-100"
                    >
                      Сохранить
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LogbookService from "@/services/LogbookService";
import {getError} from "@/utils/helpers";
import {mapGetters} from "vuex";

export default {
  name: "JournalConfigsForm",
  data() {
    return {
      config_id: null,
      active: 1,
      name: null,
      sensor_id: null,
      typeConfig: 1,
      times: [],
      error: null,
      type: 'create',
      sort: ''
    }
  },
  computed: {
    ...mapGetters({
      nameDevice: 'device/name'
    })
  },
  mounted() {
    if (this.$route.name === 'JournalConfigsUpdate') {
      let config = this.$store.getters['journalConfigs/getJournalConfig'](this.$route.params.id);
      this.config_id = this.$route.params.id;
      this.active = config.attributes.active;
      this.name = config.attributes.name;
      this.sensor_id = config.attributes.sensor_id;
      this.typeConfig = config.attributes.type;
      this.times = config.attributes.times;
      this.sort = config.attributes.sort;
      this.type = 'update';
    }
  },
  methods: {
    checkType() {
      if (this.type === 'create') {
        this.createConfig();
      } else if (this.type === 'update') {
        this.updateConfig();
      }
    },
    createConfig() {
      const payload = {
        active: this.active,
        name: this.name,
        sensor_id: this.sensor_id,
        type: this.typeConfig,
        times: JSON.stringify(this.times),
        sort: this.sort
      }
      this.error = null;
      LogbookService.createConfig(payload)
          .then(() => this.$router.push("/admin-journal-configs"))
          .catch(error => {
            this.error = getError(error);
            this.$swal('Некорректные данные', this.error, 'error')
          });
    },
    updateConfig() {
      const payload = {
        config_id: this.config_id,
        active: this.active,
        name: this.name,
        sensor_id: this.sensor_id,
        type: this.typeConfig,
        times: JSON.stringify(this.times),
        sort: this.sort
      }
      this.error = null;
      LogbookService.updateConfig(this.config_id, payload)
          .then(() => this.$router.push("/admin-journal-configs"))
          .catch(error => {
            this.error = getError(error);
            this.$swal('Некорректные данные', this.error, 'error')
          });
    }
  }
}
</script>

<style scoped>

</style>