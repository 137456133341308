<template>
  <div class="row">
    <div class="col-12 table-responsive">
      <table class="service-desk-table">
        <thead class="service-desk-table-header">
          <tr>
            <td
              v-for="header in headers"
              :key="header.value"
            >{{header.text}}</td>
          </tr>
        </thead>
        <tbody class="service-desk-table-body">
          <tr v-for="issue in issues"
              :key="issue.id"
              @click="$router.push(`/issue/${issue.id}`)"
              class="issue-item">
            <td
              v-for="header in headers"
              :key="header.value"
            >
              <div v-if="header.type === 'text'">
                {{ issue[header.value] }}
              </div>

              <div v-else-if="header.type === 'dateTime'">
                {{ issue[header.value] | formatDateTime }}
              </div>

              <div v-else-if="header.type === 'date'">
                {{ issue[header.value] | formatDate }}
              </div>

              <div v-else-if="header.type === 'address'">
                {{ issue.getFullAddress() }}
              </div>

              <div v-else-if="header.type === 'tags'">
                {{ issue.tags.join(', ') }}
              </div>

              <div v-else-if="header.type === 'category'">
                <span v-if="issue.category">
                  {{ issue.category.group_name }}: {{ issue.category.name }}
                </span>
              </div>

              <div v-else-if="header.type === 'badge'">
                <base-badge
                  :label="issue.state.label"
                  :color="issue.state.color"
                />
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import BaseBadge from "@/layout/BaseBadge";

export default {
  name: "IssuesList",
  components: {BaseBadge},
  props: {
    headers: {
      type: Array,
      required: true
    },
    issues: {
      type: Array,
      required: true
    }
  }
}
</script>

<style scoped>
.issue-item {
  transition: .3s;
  cursor: pointer;
}

.issue-item:hover {
  background-color: #e9e9e9;
  transition: .3s;
}
</style>