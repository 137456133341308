<template>
  <div class="row">
    <div class="col-xl-4 col-lg-6 col-md-6 col-sm-6 col-xs-12">
      <div class="row">
        <div v-if="checkPermission('object-monitoring')"
             class="col-12">
          <router-link to="/telemetry">
            <div class="dashboard-card">
              <div class="dashboard-card-content">
                <img
                  class="dashboard-card-icon"
                  src="@/assets/img/icons/telemetry.svg"
                >
                <span class="dashboard-card-title">Телеметрия</span>
              </div>
            </div>
          </router-link>
        </div>
        <div v-if="checkPermission('transport-tracking')"
             class="col-12">
          <router-link to="/tracking">
            <div class="dashboard-card">
              <div class="dashboard-card-content">
                <img
                  class="dashboard-card-icon"
                  src="@/assets/img/icons/transport.svg"
                >
                <span class="dashboard-card-title">Транспорт</span>
              </div>
            </div>
          </router-link>
        </div>
        <div v-if="checkPermission('billing')"
             class="col-12">
          <router-link to="/billing-clients">
            <div class="dashboard-card">
              <div class="dashboard-card-content">
                <img
                  class="dashboard-card-icon"
                  src="@/assets/img/icons/billing.svg"
                >
                <span class="dashboard-card-title">Биллинг</span>
              </div>
            </div>
          </router-link>
        </div>
      </div>
    </div>

    <div class="col-xl-4 col-lg-6 col-md-6 col-sm-6 col-xs-12">
      <div class="row">
        <div v-if="checkPermission('service-desk.issues')"
             class="col-12">
          <router-link to="/issues-list">
            <div class="dashboard-card">
              <div class="dashboard-card-content">
                <img
                  class="dashboard-card-icon"
                  src="@/assets/img/icons/task-manager.png"
                >
                <span class="dashboard-card-title">Диспетчерская</span>
              </div>
            </div>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: 'DashboardPage',
  computed: {
    ...mapGetters({
      checkPermission: 'auth/checkPermission'
    })
  }
}
</script>

<style scoped>
a {
  text-decoration: none !important;
}

.dashboard-card {
  height: 170px;
  background-color: #E7F1F5;
  border: 10px solid #E0ECF1;
  border-radius: 10px;
  margin-bottom: 50px;
}

.dashboard-card-content {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.dashboard-card-icon {
  height: 50px;
  width: 50px;
  margin-right: 18px;
}

.dashboard-card-title {
  color: #0A6187;
  font-size: 35px;
  font-weight: 700;
  vertical-align: middle;
}

.mt-120 {
  margin-top: 120px;
}

.bg-image {
  background-image: url("@/assets/img/icons/bg-logo-element.svg");
  background-repeat: no-repeat;
  background-position: 100% 100%;
}

.vertical-align:before {
  display: inline-block;
  height: 100%;
  vertical-align: middle;
  content: "";
}

.vertical-align-inner {
  display: inline-block;
  vertical-align: middle;
}

@media (max-width: 1600px) {
  .dashboard-card-title {
    font-size: 32px;
  }
}

@media (max-width: 1420px) {
  .dashboard-card-title {
    font-size: 30px;
  }
}

@media (min-width: 1200px) and (max-width: 1300px) {
  .dashboard-card-title {
    font-size: 32px;
  }

  .dashboard-card-icon {
    display: none;
  }
}

@media (max-width: 1120px) {
  .dashboard-card-title {
    font-size: 32px;
  }
}
</style>