<template>
  <div class="custom-card">
    <div class="card-body">
      <div class="col-12">
        <h4 class="table-title d-inline-block">
          Нормы расходования материалов
        </h4>
        <div v-if="loading"
             class="d-inline-block ml-2">
          <base-loader />
        </div>
        <button
          class="btn custom-btn-blue-outline float-right"
          @click="createConsumptionRate()"
        >
          Добавить
        </button>
        <hr class="divider">
      </div>
      <div v-if="workConsumptionRates.length"
           class="col-12">
        <table class="admin-table">
          <thead class="admin-table-header">
            <tr>
              <td>Наименование</td>
              <td>Количество</td>
              <td>Управление</td>
            </tr>
          </thead>
          <tbody class="admin-table-body">
            <tr v-for="rate in workConsumptionRates"
                :key="rate.id"
                class="employee-item"
            >
              <td>{{rate.consumable.name}}</td>
              <td>{{rate.amount}}</td>
              <td class="w-25">
                <edit-icon
                  class="cursor-pointer"
                  @click="editConsumptionRate(rate)"
                />
                <trash2-icon
                  class="cursor-pointer"
                  @click="deleteConsumptionRate(rate.id)"
                />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div v-else
           class="col-12">
        <div class="no-result-title">
          Нормы не найдены.
        </div>
      </div>
    </div>

    <work-consumption-rates-form :work-consumption-rate="consumptionRate" />

  </div>
</template>

<script>
import WorkConsumptionRatesForm from "@/components/ServiceDesk/admin/WorkConsumptionRatesForm";
import BaseLoader from "@/layout/BaseLoader";
import {mapState} from "vuex";
import {showAlert, showToast} from "@/utils/notification";

export default {
  name: "WorkConsumptionRatesList",
  components: {BaseLoader, WorkConsumptionRatesForm},
  data() {
    return {
      consumptionRate: null
    }
  },
  computed: {
    ...mapState({
      workConsumptionRates: state => state.workConsumptionRates.workConsumptionRates,
      loading: state => state.workConsumptionRates.loading
    })
  },
  mounted() {
    this.$store.dispatch('consumables/getConsumablesList');
    this.$store.dispatch('workConsumptionRates/getWorkConsumptionRates', this.$route.params.id);
  },
  methods: {
    createConsumptionRate() {
      this.consumptionRate = null;
      this.$bvModal.show('consumption-rate-form');
    },
    editConsumptionRate(consumptionRate) {
      this.consumptionRate = consumptionRate;
      this.$bvModal.show('consumption-rate-form');
    },
    deleteConsumptionRate(rateId) {
      this.$store.dispatch('workConsumptionRates/deleteWorkConsumptionRate', {
        workId: this.$route.params.id,
        rateId: rateId
      })
          .then(() => {
            showToast('Норма расходования материала удалена', 'success');
            this.$store.commit('workConsumptionRates/DELETE_WORK_CONSUMPTION_RATE', rateId);
          })
          .catch(error => showAlert('Ошибка', error))
    }
  }
}
</script>

<style scoped>

</style>