<template>
  <div class="col-md-12">
    <form @submit.prevent="checkType">
      <div class="form-group">
        <label class="form-label">Наименование</label>
        <input
          v-model="payload.name"
          type="text"
          class="form-control form-control-fix"
          placeholder="Наименование"
          required
        >
      </div>
      <div class="form-group">
        <label class="form-label">URL</label>
        <input
          v-model="payload.url"
          type="text"
          class="form-control form-control-fix"
          placeholder="URL"
          required
        >
      </div>
      <div class="form-group">
        <label class="form-label">Ресурс</label>
        <input
          v-model="payload.resource"
          type="text"
          class="form-control form-control-fix"
          placeholder="Ресурс"
          required
        >
      </div>
      <div class="form-group">
        <label class="form-label">Имя пользователя</label>
        <input
          v-model="payload.username"
          type="text"
          class="form-control form-control-fix"
          placeholder="Имя пользователя"
          required
        >
      </div>
      <div class="form-group">
        <label class="form-label">Пароль</label>
        <input
          v-model="payload.password"
          type="text"
          class="form-control form-control-fix"
          placeholder="Пароль"
          required
        >
      </div>
      <div style="text-align: center">
        <button
          type="submit"
          class="btn custom-btn-blue w-100"
        >
          Сохранить
        </button>
      </div>
    </form>
  </div>
</template>

<script>
import {showAlert, showToast} from "@/utils/notification";

export default {
  name: "LayersForm",
  data() {
    return {
      layerId: null,
      payload: {
        name: null,
        url: null,
        resource: null,
        username: null,
        password: null
      },
      type: 'create'
    }
  },
  mounted() {
    if (this.$route.name === 'LayersUpdate') {
      this.layerId = this.$route.params.id;
      this.type = 'update';

      const layer = this.$store.getters['layer/getLayer'](this.$route.params.id);
      this.payload.name = layer.name;
      this.payload.url = layer.url;
      this.payload.resource = layer.resource;
      this.payload.username = layer.username;
      this.payload.password = layer.password;
    }
  },
  methods: {
    checkType() {
      if (this.type === 'create') {
        this.createLayer();
      } else if (this.type === 'update') {
        this.updateLayer();
      }
    },
    createLayer() {
      this.$store.dispatch('layer/createLayer', this.payload)
          .then(() => {
            this.$router.push("/admin-layers");
            showToast('Картографический слой добавлен', 'success');
          })
          .catch(error => showAlert('Ошибка', error));
    },
    updateLayer() {
      this.$store.dispatch('layer/updateLayer', {
        layerId: this.layerId,
        payload: this.payload
      })
          .then(() => {
            this.$router.push("/admin-layers");
            showToast('Картографический слой обновлён', 'success');
          })
          .catch(error => showAlert('Ошибка', error));
    }
  }
}
</script>

<style scoped>

</style>