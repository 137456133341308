<template>
  <main class="main d-flex justify-content-center w-100">
    <div class="container d-flex flex-column">
      <div class="row h-100">
        <div class="col-sm-10 col-md-8 col-lg-6 mx-auto d-table h-100">
          <div class="d-table-cell align-middle">
            <slot />
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
export default {
  name: "LoginTemplate"
}
</script>

<style scoped>

</style>