<template>
  <div class="tab mb-0">
    <!----- Panorama modal ------>

    <b-modal
      id="panorama"
      ref="panoramaModal"
      centered
      hide-footer
      hide-header
      size="xl"
      style="z-index: 1;"
      title="Понарама"
    >
      <div
        id="body_panorama"
        class="body-panorama"
      />
      <div class="panorama-txt">
        В данном месте панорамы отсутствуют
      </div>
    </b-modal>

    <!----- Transport information modal ------>

    <b-modal
      v-if="!this.$store.state.autos.loading"
      id="transport_detail_modal"
      centered
      hide-footer
      hide-header
      size="sm"
    >
      <div class="row custom-modal-wrapper">
        <div class="col-12">
          <div class="custom-modal-header">
            <div class="custom-modal-title">
              <span class="vehicle-label">{{ label(modalData.id) }}</span>
              <span class="vehicle-gov">{{ govNumber(modalData.id) }}</span>
            </div>
            <div
              class="custom-modal-close"
              @click="$bvModal.hide('transport_detail_modal')"
            >
              <img src="@/assets/img/transport/close.svg">
            </div>
          </div>
          <hr class="custom-modal-divider">
        </div>
        <div class="col-12 mb-2">
          <div class="vehicle-status">
            <div class="vehicle-status-icon">
              <icon-engine
                :icon-color="statusColor(engine(modalData.external_id))"
                :width="11"
              />
            </div>
            <div class="vehicle-status-icon">
              <icon-satellite
                v-b-popover.hover.right="'Захвачено спутников' + ' ' + sat(modalData.external_id)"
                :icon-color="statusColor(sat(modalData.external_id))"
                :width="10"
              />
            </div>
          </div>
        </div>
        <div class="col-12">
          <div class="sensors-block">
            <div class="vehicle-sensors">
              <icon-speed
                :icon-color="speedColor(sp(modalData.external_id))"
                :width="25"
              />
              <span class="vehicle-speed mt-1">{{ sp(modalData.external_id) }} км/ч</span>
            </div>
            <div class="vehicle-sens-divider"/>
            <div
              v-b-popover.hover.bottom="'Пробег за сутки'"
              class="vehicle-sensors"
            >
              <icon-day-mileage :width="25"/>
              <span class="vehicle-speed mt-1">{{ mileage(modalData.external_id) }} км</span>
            </div>
            <div class="vehicle-sens-divider"/>
            <div
              v-b-popover.hover.bottom="'Моточасы за сутки'"
              class="vehicle-sensors"
            >
              <img
                src="@/assets/img/transport/engine-hours.svg"
                width="25"
              >
              <span class="vehicle-speed mt-1">{{ engineHours(modalData.external_id) }}</span>
            </div>
            <div class="vehicle-sens-divider"/>
            <div
              v-b-popover.hover.bottom="'Расход топлива (л/100км)'"
              class="vehicle-sensors"
            >
              <img
                src="@/assets/img/transport/fuel.svg"
                width="25"
              >
              <span class="vehicle-speed mt-1">{{ fuel(modalData.external_id) }} л/100 км</span>
            </div>
          </div>
          <hr class="custom-modal-divider">
        </div>
        <div class="col-12">
          <div class="vehicle-location">
            <div>
              <span class="d-block">Текущее местоположение</span>
              <span class="vehicle-address">{{ address(modalData.external_id) }}</span>
            </div>
            <img
              v-b-modal.panorama
              v-b-popover.hover.bottom="'Посмотреть панораму в текущем местоположении техники'"
              src="@/assets/img/transport/panorama-icon.svg"
              @click="showPanorama(modalData.id, modalData.external_id)"
            >
          </div>
          <hr class="custom-modal-divider">
        </div>
        <div class="col-12">
          <span class="d-block mb-1">История передвижения (выберите период)</span>
          <span
            v-if="historyError"
            class="text-danger"
          >История перемещений не найдена!</span>
          <div class="row">
            <div class="col-6 road-history-input">
              <date-picker
                v-model="historyFrom"
                :default-value="defaultValue"
                :disabled="loadingHistory"
                format="YYYY-MM-DD HH:mm:ss"
                input-class="road-history"
                placeholder="Начало периода"
                type="datetime"
                @change="historyTo = null"
              />
            </div>
            <div class="col-6 road-history-input">
              <date-picker
                v-model="historyTo"
                :default-value="defaultValue"
                :disabled="loadingHistory"
                :disabled-date="disableDaily"
                :disabled-time="disableHours"
                format="YYYY-MM-DD HH:mm:ss"
                input-class="road-history"
                placeholder="Конец периода"
                type="datetime"
              />
            </div>
            <div class="col-12 mt-2 mb-50">
              <button
                :disabled="loadingHistory"
                class="btn custom-btn-outline"
                @click="getVehicleMovementHistory(modalData.external_id, modalData.id)"
              >
                Показать
                <base-loader v-if="loadingHistory"/>
              </button>
            </div>
            <div class="col-12">
              <button
                class="btn custom-btn-colored"
                @click="$bvModal.hide('transport_detail_modal')"
              >
                Вернуться к
                списку
              </button>
            </div>
          </div>
        </div>
      </div>
    </b-modal>

    <!----- Tab content ------>

    <div>
      <div class="row">
        <div class="col-md-12 mbp-20">
          <div class="input-group">
            <input
              v-model="query"
              class="custom-form-control mr-3 search"
              placeholder="Поиск..."
              type="text"
            >
            <button
              id="toggleSortingBlock"
              class="btn custom-btn"
              type="button"
              @click="toggleSortingBlock"
            >
              <img
                v-if="!sortingBtn"
                src="@/assets/img/transport/settings.svg"
              >
              <img
                v-if="sortingBtn"
                height="15"
                src="@/assets/img/transport/close.svg"
                width="17"
              >
            </button>
          </div>
        </div>
      </div>


      <div
        :id="`icon-tab-`+this.$store.state.autos.display_type"
        class="tab-pane active position-relative"
        role="tabpanel"
      >
        <!----- Transport sorting block ------>

        <div
          v-show="sortingBlock"
          class="settings-block"
        >
          <div class="settings-scroll">
            <div class="settings-body">
              <div class="row">
                <div class="col-12 mb-3 d-flex justify-content-between">
                  <div
                    :class="{activeTransportType : this.$store.state.autos.display_type === 1}"
                    class="vehicle-icon"
                    @click="this.$store.commit('autos/SET_DISPLAY_TYPE', 1)"
                  >
                    <img
                      alt="Легковые автомобили"
                      src="@/assets/img/transport/car.svg"
                    >
                  </div>
                  <div
                    :class="{activeTransportType : this.$store.state.autos.display_type === 2}"
                    class="vehicle-icon"
                    @click="this.$store.commit('autos/SET_DISPLAY_TYPE', 2)"
                  >
                    <img
                      alt="Грузовые автомобили"
                      src="@/assets/img/transport/truck.svg"
                    >
                  </div>
                  <div
                    :class="{activeTransportType : this.$store.state.autos.display_type === 3}"
                    class="vehicle-icon"
                    @click="tthis.$store.commit('autos/SET_DISPLAY_TYPE', 3)"
                  >
                    <img
                      alt="Водовозки"
                      src="@/assets/img/transport/water-carrier.svg"
                    >
                  </div>
                  <div
                    :class="{activeTransportType : this.$store.state.autos.display_type === 4}"
                    class="vehicle-icon"
                    @click="this.$store.commit('autos/SET_DISPLAY_TYPE', 4)"
                  >
                    <img
                      alt="Спец. техника"
                      src="@/assets/img/transport/special-vehicle.svg"
                    >
                  </div>
                  <div
                    :class="{activeTransportType : this.$store.state.autos.display_type === 0}"
                    class="vehicle-icon"
                    @click="this.$store.commit('autos/SET_DISPLAY_TYPE', 0)"
                  >
                    <img
                      alt="Вся техника"
                      src="@/assets/img/transport/all-vehicles.svg"
                    >
                  </div>
                </div>

                <div class="col-12">
                  <label class="custom-control custom-checkbox">
                    <input
                      v-model="speedSort"
                      class="custom-control-input"
                      type="checkbox"
                    >
                    <span class="custom-control-label">Авто-сортировка в списке по скорости
                    </span>
                  </label>
                </div>

                <div class="col-12 settings-table">
                  <div class="row settings-table-header">
                    <div>Наименование</div>
                    <div>Список</div>
                    <div>Карта</div>
                    <div>Закрепить</div>
                  </div>
                  <div
                    v-for="auto in autos"
                    :key="auto.id"
                    class="row settings-table-body"
                  >
                    <div>{{ auto.attributes.label }}</div>
                    <div @click="handleAutoState('showInList', auto.id)">
                      <img v-if="auto.showInList"
                           alt="Показан"
                           src="@/assets/img/transport/listActive.svg">
                      <img v-else
                           alt="Скрыт"
                           src="@/assets/img/transport/list.svg">
                    </div>
                    <div @click="handleAutoState('showOnMap', auto.id)">
                      <img v-if="auto.showOnMap"
                           alt="Показан"
                           src="@/assets/img/transport/hideMapActive.svg">
                      <img v-else
                           alt="Скрыт"
                           src="@/assets/img/transport/hideMap.svg">
                    </div>
                    <div @click="handleAutoState('fixOnMap', auto.id)">
                      <img v-if="auto.fixOnMap"
                           alt="Показан"
                           src="@/assets/img/transport/fixMapActive.svg">
                      <img v-else
                           alt="Скрыт"
                           src="@/assets/img/transport/fixMap.svg">
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>


        <div
          v-if="this.$store.state.autos.loading"
          class="text-center"
        >
          <base-loader/>
        </div>

        <div
          v-if="this.$store.state.autos.autos.length === 0
            && this.$store.state.autos.loading === false"
          class="no-result-title"
        >
          Транспорт не найден.
        </div>

        <div class="scroll">
          <draggable
            v-model="selectListMethod"
            :disabled="this.speedSort || $isMobile()"
          >
            <div
              v-for="auto in selectListMethod"
              :key="auto.id"
              class="row auto-item"
            >
              <div class="col-12 col-md-12 col-lg-12">
                <div class="vehicle-card">
                  <div class="vehicle-card-body">
                    <div class="background-icon">
                      <img
                        v-if="auto.attributes.type == 1"
                        alt="Легковой автомобиль"
                        src="@/assets/img/transport/car.svg"
                        width="190"
                      >
                      <img
                        v-if="auto.attributes.type == 2"
                        alt="Грузовой автомобиль"
                        class="truck-vehicle-img"
                        src="@/assets/img/transport/truck.svg"
                        width="180"
                      >
                      <img
                        v-if="auto.attributes.type == 3"
                        alt="Водовозка"
                        src="@/assets/img/transport/water-carrier.svg"
                        width="190"
                      >
                      <img
                        v-if="auto.attributes.type == 4"
                        alt="Спец. техника"
                        class="special-vehicle-img"
                        src="@/assets/img/transport/special-vehicle.svg"
                        width="100"
                      >
                    </div>
                    <div class="row">
                      <div class="col-12">
                        <div class="d-inline-block">
                          <div class="vehicle-status">
                            <div class="vehicle-status-icon">
                              <icon-engine
                                :icon-color="statusColor(engine(auto.attributes.external_id))"
                                :width="11"
                              />
                            </div>
                            <div class="vehicle-status-icon">
                              <icon-satellite
                                v-b-popover.hover.right="'Захвачено спутников' +
                                  ' ' + sat(auto.attributes.external_id)"
                                :icon-color="statusColor(sat(auto.attributes.external_id))"
                                :width="10"
                              />
                            </div>
                          </div>
                        </div>
                        <div class="d-inline-block float-right">
                          <div class="vehicle-control">
                            <div @click="handleAutoState('showInList', auto.id)"
                                 class="vehicle-control-card">
                              <img v-if="auto.showInList"
                                   alt="Показан"
                                   src="@/assets/img/transport/listActive.svg">
                              <img v-else
                                   alt="Скрыт"
                                   src="@/assets/img/transport/list.svg">
                            </div>
                            <div @click="handleAutoState('showOnMap', auto.id)"
                                 class="vehicle-control-card">
                              <img v-if="auto.showOnMap"
                                   alt="Показан"
                                   src="@/assets/img/transport/hideMapActive.svg">
                              <img v-else
                                   alt="Скрыт"
                                   src="@/assets/img/transport/hideMap.svg">
                            </div>
                            <div @click="handleAutoState('fixOnMap', auto.id)"
                                 class="vehicle-control-card">
                              <img v-if="auto.fixOnMap"
                                   alt="Показан"
                                   src="@/assets/img/transport/fixMapActive.svg">
                              <img v-else
                                   alt="Скрыт"
                                   src="@/assets/img/transport/fixMap.svg">
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="col-12">
                        <div class="d-inline-block">
                          <div class="vehicle-icon">
                            <img
                              v-if="auto.attributes.type == 1"
                              alt="Легковой автомобиль"
                              src="@/assets/img/transport/car.svg"
                            >
                            <img
                              v-if="auto.attributes.type == 2"
                              alt="Грузовой автомобиль"
                              src="@/assets/img/transport/truck.svg"
                            >
                            <img
                              v-if="auto.attributes.type == 3"
                              alt="Водовозка"
                              src="@/assets/img/transport/water-carrier.svg"
                            >
                            <img
                              v-if="auto.attributes.type == 4"
                              alt="Спец. техника"
                              src="@/assets/img/transport/special-vehicle.svg"
                            >
                          </div>
                        </div>
                        <div class="d-inline-block ml-2">
                          <span class="vehicle-title">{{ auto.attributes.label }}</span>
                          <span
                            class="vehicle-detail-button"
                            @click="openDetailModal(auto.id,auto.attributes.external_id)"
                          >Подробнее
                            <img
                              alt="Подробнее"
                              src="@/assets/img/transport/more-detailed.svg"
                            ></span>
                        </div>
                        <div class="d-inline-block float-right">
                          <span class="vehicle-number">{{ auto.attributes.gov_number }}</span>
                        </div>
                      </div>

                      <div class="col-12 vertical-alignment">
                        <div
                          class="vehicle-address-block cursor-pointer"
                          @click="$store.commit('baseMap/SET_CENTER_ZOOM',
                                                {center: [lat(auto.attributes.external_id),
                                                          lon(auto.attributes.external_id)], zoom: 18})">
                          <img
                            src="@/assets/img/transport/location.svg"
                            width="16"
                          >
                        </div>
                        <div class="d-inline-block float-right">
                          <div class="speed-block text-center">
                            <icon-speed
                              :icon-color="speedColor(sp(auto.attributes.external_id))"
                              :width="25"
                            />
                            <span class="vehicle-speed">{{ sp(auto.attributes.external_id) }} км/ч</span>
                          </div>
                          <div class="mileage-block text-center">
                            <icon-day-mileage :width="25"/>
                            <span class="vehicle-speed">{{ mileage(auto.attributes.external_id) }} км</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </draggable>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from 'vuex';
import DatePicker from "vue2-datepicker";
import {loadYmap} from 'vue-yandex-maps';
import draggable from 'vuedraggable'
import {transportLS} from "@/services/localStorageService";
import IconSpeed from "@/components/Icons/IconSpeed";
import IconDayMileage from "@/components/Icons/IconDayMileage";
import IconEngine from "@/components/Icons/IconEngine";
import IconSatellite from "@/components/Icons/IconSatellite";
import BaseLoader from "@/layout/BaseLoader";

export default {
  name: "TransportsList",
  components: {
    BaseLoader,
    IconSatellite,
    IconEngine,
    IconDayMileage,
    IconSpeed,
    DatePicker,
    draggable
  },
  data() {
    return {
      modalData: {
        id: 0,
        external_id: 0
      },
      historyFrom: null,
      historyTo: null,
      query: '',
      trip: null,
      speedSort: false,
      sortList: [],
      hide_auto: [],
      coursePoints: [],
      loadingHistory: false,
      historyError: false,
      sortingBlock: false,
      sortingBtn: false,
      defaultValue: new Date()
    };
  },
  computed: {
    ...mapGetters({
      autos: 'autos/autos',
      label: 'autos/getLabel',
      govNumber: 'autos/getGovNumber',
      lat: 'transportData/getLat',
      lon: 'transportData/getLon',
      sp: 'transportData/getSpeed',
      address: 'transportData/getShortStreet',
      engine: 'transportData/getEngineStatus',
      sat: 'transportData/getSatellite',
      timedata: 'transportData/getTimestamp',
      sortTransport: 'autos/getFilterAutos',
      sortSpeedList: 'transportData/getSortList',
      fuel: 'transportDayStat/getFuelCons',
      mileage: 'transportDayStat/getMileage',
      engineHours: 'transportDayStat/getEngineHours'
    }),
    selectListMethod: {
      get: function () {
        return this.sortTransport(this.speedSort, this.sortList, this.query)
      },
      set: function (value) {
        let arr = value.map(v => v.id);
        transportLS.setArray(this.$store.state.autos.display_type, arr);
        this.sortList = arr;
      }
    },
    speedColor: function () {
      return speed => {
        if (speed == 0) {
          return '#000'
        } else if (speed > 0 && speed < 60) {
          return '#04BD00'
        } else if (speed >= 60 && speed < 100) {
          return '#FFBF1C'
        } else if (speed >= 100) {
          return '#e80000'
        }
      }
    },
    statusColor: function () {
      return param => {
        if (param == true || param > 0) {
          return '#468FC5'
        }
      }
    }
  },
  watch: {
    speedSort: function (newValue) {
      localStorage.setItem('sortSpeedAutos', newValue);
      if (newValue) {
        this.sortList = this.sortSpeedList
      } else {
        this.sortList = transportLS.getArray(this.$store.state.autos.display_type);
      }
    },
    hide_auto: function (newValue) {
      localStorage.setItem('hideListAutos', JSON.stringify(newValue))
    },
    sortSpeedList: function () {
      this.sortList = this.speedSort ? this.sortSpeedList : transportLS.getArray(this.$store.state.autos.display_type);
    }
  },
  async mounted() {
    if (localStorage.getItem('sortSpeedAutos') == null) {
      localStorage.setItem('sortSpeedAutos', false);
    }
    this.speedSort = localStorage.getItem('sortSpeedAutos') === "true";
    this.sortList = this.speedSort ? this.sortSpeedList : transportLS.getArray(this.$store.state.autos.display_type);
    const settings = {
      apiKey: process.env.VUE_APP_YANDEX_MAP_API_KEY,
      lang: 'ru_RU',
      coordorder: 'latlong',
      enterprise: false,
      version: '2.1'
    }
    await loadYmap(settings);

    this.hide_auto = JSON.parse(localStorage.getItem('hideListAutos'))
  },
  methods: {
    getVehicleMovementHistory(id) {
      this.historyError = false;
      this.loadingHistory = true;

      let from = this.$moment(this.historyFrom).format('YYYY-MM-DD HH:mm:ss');
      let to = this.$moment(this.historyTo).format('YYYY-MM-DD HH:mm:ss');

      this.$store.dispatch('autos/getMovementHistory', {from: from, to: to, id: id})
          .then(() => {
            this.loadingHistory = false;
            this.$bvModal.hide('transport_detail_modal');
          })
          .catch(() => {
            this.historyError = true;
            this.loadingHistory = false;
          })

      this.historyFrom = null;
      this.historyTo = null;
    },
    showPanorama(id, external_id) {
      this.$bvModal.hide('transport_detail_modal')
      ymaps.panorama.locate([this.lon(external_id), this.lat(external_id)]).done(
          function (panoramas) {
            if (panoramas.length > 0) {
              new ymaps.panorama.Player(
                  'body_panorama',
                  panoramas[0],
                  {controls: ['fullscreenControl', 'zoomControl'], direction: [256, 16]}
              );
            }
          },
          function (error) {
            alert(error.message);
          }
      );
    },
    disableDaily(date) {
      let from = new Date(this.historyFrom);
      let h = from.getHours();
      let m = from.getMinutes();
      let s = from.getSeconds();
      let d1 = date;
      return d1.setHours(h, m, s) < from || date > new Date(from.getTime() + 86400000);
    },
    disableHours(date) {
      let from = new Date(this.historyFrom);
      let d1 = new Date(date);
      return d1 < from || date > new Date(from.getTime() + 86400000);
    },
    openDetailModal(id, external_id) {
      this.historyError = false;
      this.modalData.id = id;
      this.modalData.external_id = external_id;
      this.$bvModal.show('transport_detail_modal')
    },
    toggleSortingBlock() {
      this.sortingBlock = !this.sortingBlock
      this.sortingBtn = !this.sortingBtn
      document.querySelector('#toggleSortingBlock').classList.toggle('custom-btn-active')
    },
    handleAutoState(type, id) {
      switch (type) {
        case 'showInList':
          this.$store.commit('autos/TOGGLE_AUTO_IN_LIST', id);
          break;
        case 'showOnMap':
          this.$store.commit('autos/TOGGLE_AUTO_ON_MAP', id);
          break;
        case 'fixOnMap':
          this.$store.commit('autos/TOGGLE_AUTO_FIX_MAP', id);
          break;
      }
    }
  }
}
</script>

<style scoped>
.vehicle-status {
  background-color: #262F3D;
  width: 36px;
  height: 15px;
  border-radius: 4px;
  display: flex;
  align-content: center;
  justify-content: space-evenly;
}

.vehicle-status-icon {
  display: flex;
  align-items: center;
}

.vehicle-control {
  display: flex;
}

.vehicle-control-card {
  display: flex;
  margin-right: 5px;
  cursor: pointer;
}

.vehicle-control-card:nth-last-child(1) {
  margin-right: 0;
}

.scroll {
  overflow-y: scroll;
  max-height: calc(100vh - 125px);
  overflow-x: hidden;
  padding-right: 20px;
}

.scroll::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  background-color: #d6d6d6;
  border-radius: 0.2em;
}

.scroll::-webkit-scrollbar-thumb {
  background-color: rgba(151, 172, 181, 1);
  border-radius: 0.2em;
}

.panorama-txt {
  position: absolute;
  top: 50%;
  left: 34%;
  font-size: 20px;
  z-index: -1;
}

.ymaps-2-1-79-islets_round-button__icon {
  padding: 0px;
  width: 37px;
  height: 36px;
}

.show-hide-title {
  font-weight: bold;
  margin-bottom: 10px;
  font-size: 15px;
  display: block;
}

.table-sm td {
  padding: 0 0.5rem;
}

.custom-spinner {
  width: 1.5rem !important;
  height: 1.5rem !important;
}

.body-panorama {
  height: 60vh;
}

.mbp-20 {
  margin-bottom: 20px;
}

.vehicle-card {
  height: 115px;
  padding: 15px;
  background-color: #EBF1F3;
  border-radius: 10px;
  margin-bottom: 10px;
}

.search {
  width: 88%;
}

.vehicle-icon {
  width: 35px;
  height: 35px;
  display: flex;
  padding: 4px;
  justify-content: center;
  align-content: center;
  background-color: #fff;
  border: 1px solid #C7D3D9;
  border-radius: 6px;
  cursor: pointer;
}

.background-icon {
  opacity: .05;
  position: absolute;
  right: 13px;
  bottom: 6px;
}

.special-vehicle-img {
  margin-bottom: 4px;
}

.truck-vehicle-img {
  margin-bottom: 4px;
}


.vehicle-title {
  font-size: 14px;
  font-weight: 700;
  color: #468FC5;
}

.vehicle-detail-button {
  font-size: 8px;
  font-style: italic;
  color: #468FC5;
  display: block;
  cursor: pointer;
}

.vehicle-number {
  font-size: 14px;
  font-weight: 700;
  color: #468FC5;
}

.vehicle-address-block {
  display: flex;
  align-items: center;
}

.vehicle-address-position {
  font-size: 8px;
  font-style: italic;
  margin-left: 8px;
  -webkit-line-clamp: 1;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.vehicle-speed {
  display: block;
  font-size: 9px;
  font-weight: 600;
  color: #262F3D;
}

.speed-block {
  display: inline-block;
  padding-right: 5px;
  border-right: 1px solid #C7D3D9;
  width: 55px;
}

.mileage-block {
  display: inline-block;
  padding-left: 5px;
  width: 58px;
}

.vertical-alignment {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.custom-modal-wrapper {
  padding: 0 14px;
}

.custom-modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.vehicle-label {
  display: block;
  font-size: 18px;
  line-height: 14px;
  color: #0A6187;
  font-weight: 700;
}

.vehicle-gov {
  display: block;
  font-size: 14px;
  color: #0A6187;
}

.custom-modal-close {
  cursor: pointer;
}

.custom-modal-divider {
  width: 100%;
  border-top: 1px solid #C7D3D9;
}

.sensors-block {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.vehicle-sensors {
  text-align: center;
}

.vehicle-sens-divider {
  height: 25px;
  width: 1px;
  background-color: #C7D3D9;
}

.vehicle-location {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: space-between;
}

.vehicle-address {
  font-size: 14px;
  font-weight: 700;
  color: #468FC5;
}

.custom-btn-outline {
  height: 40px;
  width: 100%;
  border-radius: 6px;
  font-size: 16px;
  font-weight: bold;
  border: 2px solid #468FC5;
  color: #468FC5;
  transition: .5s;
}

.custom-btn-outline:hover {
  background-color: #468FC5;
  color: white;
  transition: .5s;
}

.custom-btn-colored {
  height: 40px;
  width: 100%;
  border-radius: 6px;
  font-size: 16px;
  font-weight: bold;
  border: 2px solid #468FC5;
  background-color: #468FC5;
  color: white;
  transition: .5s;
}

.mb-50 {
  margin-bottom: 50px;
}

.activeTransportType {
  border: 2px solid #1F9BCF;
}

@media (min-width: 1650px) {
  .vehicle-address-position {
    font-size: 10px;
  }

  .vehicle-speed {
    font-size: 10px;
  }

  .speed-block {
    width: 65px;
  }

  .mileage-block {
    width: 60px;
  }
}

@media (min-width: 1551px) and (max-width: 1880px) {
  .search {
    width: 82% !important;
  }
}

@media (min-width: 992px) and (max-width: 1550px) {
  .search {
    width: 80% !important;
  }
}

@media (max-width: 450px) {
  .search {
    width: 70%;
    margin-left: 20px;
  }
}
</style>