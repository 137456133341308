import DepartmnetsService from "@/services/DepartmnetsService";
import {getError} from "@/utils/helpers";

export const namespaced = true;

export const state = {
    departments: [],
    department: null,
    staff: [],
    brigades: [],
    meta: null,
    links: null,
    error: null,
    loading: false,
    staffLoading: false
}

export const mutations = {
    SET_DEPARTMENTS(state, departments) {
        state.departments = departments;
    },
    SET_DEPARTMENT(state, department) {
        state.department = department;
    },
    SET_STAFF(state, staff) {
        state.staff = staff;
    },
    SET_META(state, meta) {
        state.meta = meta;
    },
    SET_LINKS(state, links) {
        state.links = links;
    },
    SET_ERROR(state, error) {
        state.error = error;
    },
    SET_LOADING(state, loading) {
        state.loading = loading;
    },
    SET_STAFF_LOADING(state, loading) {
        state.staffLoading = loading;
    }
}

export const actions = {
    getAllDepartments({commit}) {
        commit("SET_LOADING", true);
        DepartmnetsService.getAllDepartments()
            .then(departments => commit("SET_DEPARTMENTS", departments))
            .catch(error => commit("SET_ERROR", error))
            .finally(() => commit("SET_LOADING", false));
    },
    getDepartments({commit}, page) {
        commit("SET_LOADING", true);
        DepartmnetsService.getDepartments(page)
            .then(({departments, meta, links}) => {
                commit("SET_DEPARTMENTS", departments);
                commit("SET_META", meta);
                commit("SET_LINKS", links);
            })
            .catch(error => commit("SET_ERROR", error))
            .finally(() => commit("SET_LOADING", false));
    },
    getDepartment({commit}, department_id) {
        return new Promise((resolve, reject) => {
            DepartmnetsService.fetchDepartment(department_id)
                .then(department => {
                    commit("SET_DEPARTMENT", department);
                    resolve();
                })
                .catch(error => {
                    commit("SET_ERROR", error);
                    reject(error);
                });
        })
    },
    createDepartment({commit}, payload) {
        return new Promise((resolve, reject) => {
            DepartmnetsService.createDepartment(payload)
                .then(() => resolve())
                .catch(error => {
                    commit("SET_ERROR", error);
                    reject(error);
                });
        })
    },
    updateDepartment({commit}, {department_id, payload}) {
        return new Promise((resolve, reject) => {
            DepartmnetsService.updateDepartment({department_id, payload})
                .then(() => resolve())
                .catch(error => {
                    commit("SET_ERROR", error);
                    reject(error);
                });
        })
    },
    activateDepartment({commit}, department_id) {
        return new Promise((resolve, reject) => {
            DepartmnetsService.activateDepartment(department_id)
                .then(() => resolve())
                .catch(error => {
                    commit("SET_ERROR", error);
                    reject(error);
                })
        })
    },
    deactivateDepartment({commit}, department_id) {
        return new Promise((resolve, reject) => {
            DepartmnetsService.deactivateDepartment(department_id)
                .then(() => resolve())
                .catch(error => {
                    commit("SET_ERROR", error);
                    reject(error);
                })
        })
    },
    getDepartmentStaffList({commit}, department_id) {
        commit("SET_STAFF_LOADING", true);
        DepartmnetsService.fetchDepartmentStaffList(department_id)
            .then(staff => commit("SET_STAFF", staff))
            .catch(error => commit("SET_ERROR", error))
            .finally(() => commit("SET_STAFF_LOADING", false));
    },
    addEmployee({commit}, {department_id, payload}) {
        return new Promise((resolve, reject) => {
            DepartmnetsService.addEmployeeToDepartment(department_id, payload)
                .then(() => resolve())
                .catch(error => {
                    commit("SET_ERROR", getError(error));
                    reject(getError(error));
                })
        })
    },
    removeEmployee({commit}, {department_id, employee_id}) {
        return new Promise((resolve, reject) => {
            DepartmnetsService.removeEmployeeFromDepartment(department_id, employee_id)
                .then(() => resolve())
                .catch(error => {
                    commit("SET_ERROR", getError(error));
                    reject(getError(error));
                })
        })
    }
}

export const getters = {
    getDepartments: state => {
        return state.departments;
    },
    getDepartmentById: state => id => {
        return state.departments.find(department => department.id === parseInt(id));
    },
    getDepartment: state => {
        return state.department;
    },
    getDepartmentName: state => id => {
        const department = state.departments.find(department => department.id === id);
        if (department) return department.name;
        else return '-'
    },
    getStaff: state => {
        return state.staff;
    }
}