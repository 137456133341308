<template>
  <div class="row">
    <div class="col-xl-12 mb-2">
      <div class="page-title">Статистика за
        <div
          @click="daysCount = 6"
          class="days-count-button"
          :class="{active: daysCount === 6}">7 дней</div>
        <div
          @click="daysCount = 30"
          class="days-count-button"
          :class="{active: daysCount === 30}">31 день</div>
      </div>
    </div>
    <div class="col-xl-6 col-lg-12 col-xs-12 mb-3">
      <closed-issues-count-by-day :filter="createFilterParams" />
    </div>
    <div class="col-xl-6 col-lg-12 mb-3">
      <transit-issues-chart :filter="createFilterParams" />
    </div>
    <div class="col-12">
      <opened-issues-count-by-groups :filter="createFilterParams" />
    </div>
    <div class="col-12">
      <closed-issues-count-by-groups :filter="createFilterParams" />
    </div>
  </div>
</template>

<script>
import ClosedIssuesCountByDay from "@/components/ServiceDesk/Issues/ClosedIssuesCountByDay";
import OpenedIssuesCountByGroups from "@/components/ServiceDesk/Issues/OpenedIssuesCountByGroups";
import TransitIssuesChart from "@/components/ServiceDesk/Issues/TransitIssuesChart";
import ClosedIssuesCountByGroups from "@/components/ServiceDesk/Issues/ClosedIssuesCountByGroups";
export default {
  name: "IssuesDashboardPage",
  components: {ClosedIssuesCountByGroups, TransitIssuesChart, OpenedIssuesCountByGroups, ClosedIssuesCountByDay},
  data() {
    return {
      daysCount: 6
    }
  },
  computed: {
    createFilterParams () {
      return {
        start_period: this.$moment().subtract(this.daysCount, 'days').format('YYYY-MM-DD'),
        end_period: this.$moment().format('YYYY-MM-DD')
      }
    }
  }
}
</script>

<style scoped>
.page-title {
  display: flex;
  align-items: center;
  font-size: 24px;
  font-weight: 600;
  color: #468FC5;
}

.days-count-button:first-child {
  margin-left: 10px;
}

.days-count-button {
  display: inline-block;
  padding: 8px 12px;
  background-color: #EAEFF2;
  border-radius: 3px;
  color: #468FC5;
  font-size: 13px;
  font-weight: 500;
  margin-right: 5px;
  cursor: pointer;
}

.days-count-button.active {
  background-color: #262F3D;
  color: white;
}
</style>