<template>
  <div class="custom-card">
    <div class="card-body">
      <div class="col-12">
        <h4 class="table-title d-inline-block">
          Сотрудники подразделения
        </h4>
        <div v-if="$store.state.departments.staffLoading"
             class="d-inline-block ml-2">
          <base-loader />
        </div>
        <button
          class="btn custom-btn-blue-outline float-right"
          @click="$bvModal.show('add-employee')"
        >
          Добавить
        </button>
        <hr class="divider">
      </div>
      <div v-if="$store.state.departments.staff.length"
           class="col-12">
        <table class="admin-table">
          <thead class="admin-table-header">
            <tr>
              <td>Имя</td>
              <td>Управление</td>
            </tr>
          </thead>
          <tbody class="admin-table-body">
            <tr v-for="employee in departmentStaff"
                :key="employee.id"
                class="employee-item"
            >
              <td>{{employee.name}}</td>
              <td class="w-25">
                <trash2-icon
                  class="cursor-pointer"
                  @click="deleteEmployee(employee.id)"
                />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div v-else
           class="col-12">
        <div class="no-result-title">
          Сотрудники не найдены.
        </div>
      </div>
    </div>

    <department-add-employee-form />

  </div>
</template>

<script>
import BaseLoader from "@/layout/BaseLoader";
import {mapGetters} from "vuex";
import notification from "@/mixins/notification";
import DepartmentAddEmployeeForm from "@/components/ServiceDesk/admin/DepartmentAddEmployeeForm";
export default {
  name: "DepartmentStaffList",
  components: {DepartmentAddEmployeeForm, BaseLoader},
  mixins: [notification],
  computed: {
    ...mapGetters({
      departmentStaff: 'departments/getStaff'
    })
  },
  mounted() {
    this.$store.dispatch('departments/getDepartmentStaffList', this.$route.params.id);
  },
  methods: {
    deleteEmployee(employee_id) {
      this.$store.dispatch('departments/removeEmployee', {
        employee_id,
        department_id: this.$route.params.id
      })
          .then(() => {
            this.$store.dispatch('departments/getDepartmentStaffList', this.$route.params.id);
            this.toast('Сотрудник удалён из подразделения', 'success')
          })
          .catch(error => this.alert('Ошибка', error, 'error'));
    }
  }
}
</script>

<style scoped>

</style>