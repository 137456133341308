<template>
  <div class="row mb-3">
    <div class="col-11">
      <div class="custom-card">
        <div class="card-body">
          <div class="d-flex">
            <input class="form-control form-control-fix"
                   placeholder="Поиск"
                   disabled
            >
            <button @click="clearFilter()"
                    class="btn custom-btn-blue-outline reset-filter-btn">
              <icon-reset  />
            </button>
            <button @click="$bvModal.show('create-appeal')"
                    class="btn custom-btn-blue add-appeal-btn">
              <img src="@/assets/img/icons/plus.svg"
                   alt="+">
            </button>
          </div>
          <b-collapse id="filter-collapse"
                      v-model="filterCollapseToggled">
            <div class="row mt-3">
              <div class="col-xs-12 mb-3"
                   :class="{'col-xl-3': hideMap, 'col-xl-6': !hideMap}">
                <label class="form-label">ФИО обратившегося</label>
                <input
                  v-model="filter.applicant_name"
                  type="text"
                  class="form-control form-control-fix"
                  placeholder="Введите ФИО"
                  @change="submitFilter()"
                >
              </div>
              <div class="col-xs-12 mb-3"
                   :class="{'col-xl-3': hideMap, 'col-xl-6': !hideMap}">
                <label class="form-label">Адрес проблемы</label>
                <dadata-suggestions
                  v-model="address"
                  class="form-control form-control-fix"
                  type="ADDRESS"
                  @change="setAddress"
                  placeholder="Введите адрес"
                />
              </div>
              <div class="col-xs-12 appeal-filter-item"
                   :class="{'col-xl-3': hideMap, 'col-xl-6': !hideMap}">
                <label class="form-label">Дата обращения</label>
                <date-picker
                  v-model="filter.appeal_date"
                  :default-value="defaultValue"
                  input-class="form-control-fix w-100 pl-2"
                  placeholder="Выберите дату"
                  value-type="YYYY-MM-DD"
                  @change="submitFilter()"
                />
              </div>
              <div class="col-xs-12 mb-3"
                   :class="{'col-xl-3': hideMap, 'col-xl-6': !hideMap}">
                <label class="form-label">Категория обращения</label>
                <v-select
                  v-model="filter.category_id"
                  :options="appealCategories.concat()
                    .sort((a, b) => a.group.id - b.group.id || a.name.localeCompare(b.name))"
                  :reduce="category => category.id"
                  :get-option-label="(option) => option.name"
                  placeholder="Выберите категорию"
                  @input="submitFilter()"
                >
                  <template #option="{name, group}">
                    <span class="font-weight-bold">{{group.name}}: </span>{{name}}
                  </template>
                </v-select>
              </div>

              <div class="col-xl-12">
                <label class="form-label">Сортировка</label>
                <v-select
                  v-model="filter.sort_dir"
                  :options="sortingOptions"
                  :reduce="sort => sort.dir"
                  :get-option-label="(option) => option.label"
                  placeholder="Выберите сортировку"
                  @input="submitFilter()"
                />
              </div>
            </div>
          </b-collapse>
        </div>
      </div>
    </div>
    <div class="col-1">
      <div class="toggle-filter-block">
        <div class="toggle-filter"
             v-b-toggle.filter-collapse>
          <chevron-left-icon :class="{rotate: filterCollapseToggled}"/>
        </div>
        <div v-show="hideMap"
             @click="$store.commit('appeals/TOGGLE_HIDE_MAP')"
             class="hide-map">
          <img src="@/assets/img/icons/map-blue.svg"
               alt="Скрыть">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DatePicker from "vue2-datepicker";
import {mapGetters} from "vuex";
import Vue from "vue";
import DadataSuggestions from "vue-dadata-suggestions";
import IconReset from "@/components/Icons/IconReset";

Vue.use(DadataSuggestions, {
  token: process.env.VUE_APP_DADATA_API_KEY,
  geoLocation: [{
    "kladr_id": process.env.VUE_APP_DADATA_KLADR_ID
  }]
});

export default {
  name: "AppealsListFilter",
  components: {
    IconReset,
    DatePicker
  },
  data() {
    return {
      filter: {
        applicant_name: null,
        category_id: null,
        address_region: null,
        address_city: null,
        address_settlement: null,
        address_street: null,
        address_house: null,
        address_block: null,
        address_flat: null,
        appeal_date: null,
        sort_dir: 'asc'
      },
      sortingOptions: [
        {dir: 'asc', label: 'По возрастанию даты'},
        {dir: 'desc', label: 'По убыванию даты'}
      ],
      address: null,
      defaultValue: new Date(),
      filterCollapseToggled: false
    }
  },
  computed: {
    ...mapGetters({
      checkPermission: 'auth/checkPermission',
      appealCategories: 'serviceDeskCategories/getCategories',
      hideMap: 'appeals/getHideMap'
    })
  },
  methods: {
    submitFilter() {
      this.$store.commit('appeals/SET_FILTER_PARAMS', this.filter);
      this.$store.dispatch('appeals/getAppeals', 1);
    },
    clearFilter() {
      this.filter = {
        applicant_name: null,
        category_id: null,
        address_region: null,
        address_city: null,
        address_settlement: null,
        address_street: null,
        address_house: null,
        address_block: null,
        address_flat: null,
        appeal_date: null,
        sort_dir: 'asc'
      }
      this.address = null

      this.$store.commit('appeals/SET_FILTER_PARAMS', null);
      this.$store.dispatch('appeals/getAppeals', 1)
    },
    setAddress(i) {
      this.filter.address_region = i.data.region;
      this.filter.address_city = i.data.city;
      this.filter.address_settlement = i.data.settlement_with_type;
      this.filter.address_street = i.data.street_with_type;
      this.filter.address_house = i.data.house;
      this.filter.address_block = i.data.block;
      this.filter.address_flat = i.data.flat;

      this.submitFilter()
    }
  }
}
</script>

<style scoped>
.toggle-filter-block {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
}

.toggle-filter {
  display: flex;
  align-items: center;
  height: 44px;
  cursor: pointer;
}

.toggle-filter svg {
  width: 30px;
  height: 30px;
  color: #468FC5;
  transition: .3s;
}

.add-appeal-btn {
  padding: 0 9px;
  margin-left: 20px;
}

.rotate {
  transform: rotateZ(-90deg);
}

.hide-map {
  width: 40px;
  height: 40px;
  background-color: #FFFFFF;
  border: 1px solid #468FC5;
  border-radius: 7px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.reset-filter-btn {
  margin-left: 20px;
}

@media (max-width: 766px) {
  .appeal-filter-item {
    margin-bottom: 1rem;
  }
}
</style>