<template>
  <b-modal
    id="electric-meter-modal"
    centered
    hide-header
    hide-footer
    @shown="fillFormFields()"
    @hidden="clearFormFields()"
  >
    <div class="col-12">
      <div class="custom-modal-header">
        <div
          v-if="formType === 'create'"
          class="custom-modal-title">
          Добавить счётчик
        </div>
        <div
          v-else
          class="custom-modal-title">
          Изменить счётчик
        </div>
        <img
          class="custom-modal-close"
          src="@/assets/img/icons/close.svg"
          alt="Закрыть"
          @click="$bvModal.hide('electric-meter-modal')"
        >
      </div>
      <div class="modal-divider" />
    </div>
    <form @submit.prevent="submitForm()">
      <div class="col-12 mb-2">
        <div class="form-group">
          <label class="form-label">Номер</label>
          <input
            v-model="payload.number"
            type="text"
            class="form-control form-control-fix"
            placeholder="Введите номер">
        </div>

        <div class="form-group">
          <label class="form-label">Модель</label>
          <input
            v-model="payload.model"
            type="text"
            class="form-control form-control-fix"
            placeholder="Введите модель">
        </div>

        <div class="form-group">
          <label class="form-label">Дата установки</label>
          <date-picker
            v-model="payload.installation_date"
            :default-value="defaultValue"
            input-class="form-control-fix w-100 pl-2"
            placeholder="Выберите дату установки"
            type="day"
            format="DD.MM.YYYY"
            value-type="YYYY-MM-DD"
          />
        </div>

        <div class="modal-divider" />
        <div class="row">
          <div class="col-12">
            <button
              type="submit"
              class="btn custom-btn-blue w-100"
            >
              Сохранить
            </button>
          </div>
        </div>
      </div>
    </form>
  </b-modal>
</template>

<script>
import {showAlert, showToast} from "@/utils/notification";
import DatePicker from "vue2-datepicker";

export default {
  name: "ElectricMeterForm",
  components: {DatePicker},
  props: {
    formType: {
      type: String,
      required: true
    },
    electricMeter: {
      type: Object,
      required: false
    }
  },
  data() {
    return {
      payload: {
        number: null,
        model: null,
        installation_date: null
      },
      defaultValue: new Date()
    }
  },
  methods: {
    submitForm() {
      if (this.formType === 'create') this.createElectricMeter()
      else this.updateElectricMeter()
    },
    createElectricMeter() {
      this.payload.electricity_consumer_facility_id = this.$route.params.id;
      this.$store.dispatch('electricMeters/createElectricMeter', {
        energyConsumptionObjectId: this.$route.params.id,
        payload: this.payload
      })
          .then(() => {
            showToast('Счётчик добавлен', 'success');
            this.$store.dispatch('energyConsumptionObjects/getEnergyConsumptionObjectWithMeters',
                this.$route.params.id);
            this.$bvModal.hide('electric-meter-modal');
          })
          .catch(error => showAlert('Ошибка', error));
    },
    updateElectricMeter() {
      this.payload.electricity_consumer_facility_id = this.$route.params.id;
      this.$store.dispatch('electricMeters/updateElectricMeter', {
        electricMeterId: this.electricMeter.id,
        energyConsumptionObjectId: this.$route.params.id,
        payload: this.payload
      })
          .then(() => {
            showToast('Счётчик обновлён', 'success');
            this.$store.dispatch('energyConsumptionObjects/getEnergyConsumptionObjectWithMeters',
                this.$route.params.id);
            this.$bvModal.hide('electric-meter-modal');
          })
          .catch(error => showAlert('Ошибка', error));
    },
    fillFormFields() {
      if (this.formType === 'update') {
        this.payload = {
          number: this.electricMeter.number,
          model: this.electricMeter.model,
          installation_date: this.electricMeter.installationDate
        }
      }
    },
    clearFormFields() {
      this.payload = {
        number: null,
        model: null,
        installation_date: null
      }
    }
  }
}
</script>

<style scoped>

</style>