<template>
  <div class="custom-card">
    <div class="card-header">
      <div class="energy-consumption-logbook__header">
        <div class="card-title mb-0">Журнал энергопотребления</div>
        <date-picker
          v-model="currentYear"
          :default-value="currentYear"
          input-class="form-control-fix w-100 pl-2"
          placeholder="Выберите год"
          type="year"
          format="YYYY"
          @input="getElectricMetersReadings()" />
        <base-loader v-if="loading" />
      </div>
    </div>
  </div>
</template>

<script>
import DatePicker from "vue2-datepicker";
import BaseLoader from "@/layout/BaseLoader";
import {mapState} from "vuex";

export default {
  name: "EnergyConsumptionLogbookHeader",
  components: {BaseLoader, DatePicker},
  computed: {
    ...mapState({
      loading: state => state.energyConsumptionObjects.loading
    }),
    currentYear: {
      get() {
        return this.$store.state.energyConsumptionLogbook.selectedDate
      },
      set(value) {
        this.$store.commit("energyConsumptionLogbook/SET_DATE", value)
      }
    }
  },
  methods: {
    getElectricMetersReadings() {
      this.$store.commit('electricMetersReadings/SET_FILTER_PARAMS', {
        year: this.$moment(this.currentYear).format('YYYY')
      });
      this.$store.dispatch('electricMetersReadings/getElectricMetersMonthReading');
    }
  }
}
</script>

<style scoped>
.energy-consumption-logbook__header {
  display: flex;
  gap: 12px;
  align-items: center;
}
</style>