<template>
  <div class="col-md-12">
    <form @submit.prevent="checkType">
      <div class="form-group">
        <label class="form-label">Имя</label>
        <input
          v-model="payload.name"
          type="text"
          class="form-control form-control-fix"
          placeholder="Имя"
          required
        >
      </div>
      <div class="form-group">
        <label class="form-label">E-mail</label>
        <input
          v-model="payload.email"
          type="email"
          class="form-control form-control-fix"
          placeholder="E-mail"
          required
        >
      </div>
      <div class="form-group">
        <label class="form-label">Номер телефона</label>
        <input
          v-model="payload.phone"
          type="text"
          class="form-control form-control-fix"
          placeholder="Введите номер телефона"
        >
      </div>
      <div class="form-group">
        <label class="form-label">Должность</label>
        <input
          v-model="payload.job_title"
          type="text"
          class="form-control form-control-fix"
          placeholder="Введите должность"
        >
      </div>
      <div class="form-group">
        <label class="form-label">Пароль</label>
        <input
          v-model="payload.password"
          type="password"
          class="form-control form-control-fix"
          placeholder="Пароль"
        >
      </div>
      <div class="form-group">
        <label class="form-label">Повтор пароля</label>
        <input
          v-model="payload.password_confirmation"
          type="password"
          class="form-control form-control-fix"
          placeholder="Повторите пароль"
        >
      </div>
      <div class="form-group">
        <div class="custom-control custom-switch">
          <input
            id="customSwitch1"
            v-model="payload.active"
            type="checkbox"
            class="custom-control-input"
          >
          <label
            class="custom-control-label"
            for="customSwitch1"
          >Активен</label>
        </div>
      </div>
      <div style="text-align: center">
        <button
          type="submit"
          class="btn custom-btn-blue w-100"
        >
          Сохранить
        </button>
      </div>
    </form>
  </div>
</template>

<script>
import notification from "@/mixins/notification";
import {mapState} from "vuex";

export default {
  name: "UsersForm",
  mixins: [notification],
  data() {
    return {
      payload: {
        name: '',
        email: '',
        password: '',
        password_confirmation: '',
        active: true,
        phone: null,
        job_title: null
      },
      user_id: null,
      error: null,
      type: 'create'
    }
  },
  computed: {
    ...mapState({
      user: state => state.user.user
    })
  },
  mounted() {
    if (this.$route.name === 'UsersUpdate') {
      this.payload = {
        name: this.user.name,
        email: this.user.email,
        active: this.user.active === 1,
        phone: this.user.phone,
        job_title: this.user.job_title
      }

      this.type = 'update';
    }
  },
  methods: {
    checkType() {
      if (this.type === 'create') {
        this.createUser();
      } else if (this.type === 'update') {
        this.updateUser();
      }
    },
    createUser() {
      this.$store.dispatch('user/createUser', this.payload)
          .then(() => {
            this.toast('Пользователь создан', 'success')
            this.$router.push("/admin-users");
          })
          .catch(error => this.alert('Ошибка', error, 'error'));
    },
    updateUser() {
      this.$store.dispatch('user/updateUser', {
        userId: this.user.id,
        payload: this.payload
      })
          .then(() => {
            this.toast('Пользователь обновлён', 'success')
            this.$router.push("/admin-users");
          })
          .catch(error => this.alert('Ошибка', error, 'error'));
    }
  }
}
</script>

<style scoped>

</style>