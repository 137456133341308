<template>
  <form @submit.prevent="checkType">
    <div class="form-group">
      <label class="form-label">Наименование</label>
      <input
        v-model="payload.name"
        type="text"
        class="form-control form-control-fix"
        placeholder="Введите наименование типа задания"
        required
      >
    </div>
    <div class="form-group">
      <label class="form-label">Тег</label>
      <v-select
        v-model="payload.tag"
        :options="tag.tags"
        :reduce="permission => permission.id"
        :get-option-label="(option) => option.label"
        placeholder="Выберите тег"
      />
    </div>
    <div class="form-group">
      <label class="form-label">Группы проблем</label>
      <v-select
        v-model="payload.category_groups"
        :options="categoryGroups"
        :reduce="group => group.id"
        :get-option-label="(option) => option.name"
        placeholder="Выберите группу"
        multiple
      />
    </div>
    <div class="form-group">
      <label class="form-label">Компоненты</label>
      <v-select
        v-model="payload.features"
        :options="featuresList"
        :reduce="feature => feature.slug"
        :get-option-label="(option) => option.name"
        placeholder="Выберите компонент"
        multiple
      />
    </div>
    <div class="form-group">
      <label class="form-label">Максимальное время выполнения в часах</label>
      <input
        v-model="payload.lead_time"
        type="text"
        class="form-control form-control-fix"
        placeholder="Введите количество часов"
        required
      >
    </div>
    <div class="text-center">
      <button
        type="submit"
        class="btn custom-btn-blue w-100"
      >
        Сохранить
      </button>
    </div>
  </form>
</template>

<script>
import {showAlert, showToast} from "@/utils/notification";
import Tag from '@/models/serviceDesk/Tag'
import {mapGetters, mapState} from "vuex";

export default {
  name: "TaskTypesForm",
  data() {
    return {
      payload: {
        name: null,
        tag: null,
        features: null,
        category_groups: [],
        lead_time: null
      },
      formType: 'create'
    }
  },
  computed: {
    ...mapState({
      featuresList: state => state.task_types.taskTypesFeatures,
      categoryGroups: state => state.serviceDeskCategories.categoryGroups
    }),
    ...mapGetters({
      getTaskTypeById: 'task_types/getTaskTypeById'
    }),
    tag: () => Tag
  },
  mounted() {
    this.$store.dispatch('task_types/getTaskTypesFeatures');
    this.$store.dispatch('serviceDeskCategories/getCategoryGroups');

    if (this.$route.name === 'UpdateTaskType') {
      this.formType = 'update'

      const taskType = this.getTaskTypeById(this.$route.params.id);
      this.payload.name = taskType.name;
      this.payload.tag = taskType.tag;
      this.payload.features = taskType.features.map(item => item.slug);
      this.payload.category_groups = taskType.categoryGroups.map(item => item.id);
      this.payload.lead_time = taskType.leadTime;
    }
  },
  methods: {
    checkType() {
      if (this.formType === 'update') this.updateTaskType()
      else this.createTaskType();
    },
    createTaskType() {
      this.$store.dispatch('task_types/createTaskType', this.payload)
          .then(() => {
            this.$router.push('/task-types');
            showToast('Тип задания добавлен', 'success')
          })
          .catch(error => showAlert('Ошибка', error))
    },
    updateTaskType() {
      this.$store.dispatch('task_types/updateTaskType', {
        taskTypeId: this.$route.params.id,
        payload: this.payload
      })
          .then(() => {
            this.$router.push('/task-types');
            showToast('Тип задания обновлён', 'success')
          })
          .catch(error => showAlert('Ошибка', error))
    }
  }
}
</script>

<style scoped>

</style>