import TransportOrdersService from "@/services/TransportOrdersService";
import {getError} from "@/utils/helpers";

export const namespaced = true;

export const state = {
    transportOrders: [],
    transportOrder: null,
    filterParams: null,
    error: null,
    loading: false
}

export const mutations = {
    SET_TRANSPORT_ORDERS(state, transportOrders) {
        state.transportOrders = transportOrders;
    },
    SET_TRANSPORT_ORDER(state, transportOrder) {
        state.transportOrder = transportOrder;
    },
    SET_FILTER_PARAMS(state, filterParams) {
        state.filterParams = filterParams;
    },
    SET_ERROR(state, error) {
        state.error = error;
    },
    SET_LOADING(state, loading) {
        state.loading = loading;
    }
}

export const actions = {
    getTransportOrders({commit, state}) {
        commit("SET_LOADING", true);
        TransportOrdersService.fetchTransportOrders(state.filterParams)
            .then(transportOrders => commit("SET_TRANSPORT_ORDERS", transportOrders))
            .catch(error => commit("SET_ERROR", error))
            .finally(() => commit("SET_LOADING", false));
    },
    getTransportOrder({commit}, transportOrderId) {
        return new Promise((resolve, reject) => {
            TransportOrdersService.fetchTransportOrder(transportOrderId)
                .then(transportOrder => {
                    commit("SET_TRANSPORT_ORDER", transportOrder);
                    resolve();
                })
                .catch(error => reject(error))
        })
    },
    createTransportOrder(context, payload) {
        return new Promise((resolve, reject) => {
            TransportOrdersService.createTransportOrder(payload)
                .then(() => resolve())
                .catch(error => reject(getError(error)))
        })
    },
    acceptTransportOrder(context, {transportOrderId, payload}) {
        return new Promise((resolve, reject) => {
            TransportOrdersService.acceptTransportOrder(transportOrderId, payload)
                .then(() => resolve())
                .catch(error => reject(getError(error)))
        })
    },
    cancelTransportOrder(context, transportOrderId) {
        return new Promise((resolve, reject) => {
            TransportOrdersService.cancelTransportOrder(transportOrderId)
                .then(() => resolve())
                .catch(error => reject(getError(error)))
        })
    }
}