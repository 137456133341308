<template>
  <b-modal
    id="device-form"
    ref="device-form"
    centered
    hide-header
    hide-footer
    @shown="fillFields()"
    @hidden="clearFields()"
  >
    <div class="col-12">
      <div class="custom-modal-header">
        <div class="custom-modal-title">
          Устройство
        </div>
        <img
          alt="Закрыть"
          class="custom-modal-close"
          src="@/assets/img/icons/close.svg"
          @click="$bvModal.hide('device-form')"
        >
      </div>
      <div class="modal-divider"/>
    </div>

    <div class="col-12">
      <form @submit.prevent="checkType">
        <div class="form-group">
          <label class="form-label">Объект сети</label>
          <v-select
            v-model="payload.construction_id"
            :options="networkObjects"
            :reduce="networkObject => networkObject.id"
            :get-option-label="(option) => option.name"
            placeholder="Выберите объект сети"/>
        </div>

        <div class="form-group">
          <label class="form-label">Родительское устройство</label>
          <v-select
            v-model="payload.parent_id"
            :options="devices"
            :reduce="device => device.id"
            :get-option-label="(option) => option.name"
            placeholder="Выберите родительское устройство"/>
        </div>

        <div class="form-group">
          <label class="form-label">Тип подключения</label>
          <v-select
            v-model="payload.connection"
            :options="connectionTypes"
            :reduce="type => type.id"
            :get-option-label="(option) => option.name"
            placeholder="Выберите тип подключения"/>
        </div>

        <div class="form-group">
          <label class="form-label">Наименование</label>
          <input
            v-model="payload.name"
            type="text"
            class="form-control form-control-fix"
            placeholder="Введите наименование"
            maxlength="21"
          >
        </div>

        <div class="form-group">
          <label class="form-label">Наименование устройства</label>
          <input
            v-model="payload.device_name"
            type="text"
            class="form-control form-control-fix"
            placeholder="Введите наименование устройства"
          >
        </div>

        <div class="form-group">
          <label class="form-label">Широта</label>
          <input
            v-model="payload.lat"
            type="text"
            class="form-control form-control-fix"
            placeholder="Введите широту"
          >
        </div>

        <div class="form-group">
          <label class="form-label">Долгота</label>
          <input
            v-model="payload.lng"
            type="text"
            class="form-control form-control-fix"
            placeholder="Введите долготу"
          >
        </div>

        <div class="form-group">
          <div class="custom-control custom-switch">
            <input
              id="customSwitch1"
              v-model="payload.active"
              type="checkbox"
              class="custom-control-input"
            >
            <label
              class="custom-control-label"
              for="customSwitch1"
            >Активен</label>
          </div>
        </div>

        <div class="text-center">
          <hr>
          <button
            type="submit"
            class="btn custom-btn-blue w-100"
          >
            Сохранить
          </button>
        </div>
      </form>
    </div>
  </b-modal>
</template>

<script>
import {mapState} from "vuex";
import {showAlert, showToast} from "@/utils/notification";
import Device from "@/models/telemetry/Device";

export default {
  name: "DeviceForm",
  props: {
    device: {
      required: true
    }
  },
  data() {
    return {
      formType: 'create',
      payload: {
        construction_id: null,
        connection: null,
        name: null,
        device_name: null,
        lat: null,
        lng: null,
        active: true,
        parent_id: null
      }
    }
  },
  computed: {
    ...mapState({
      networkObjects: state => state.networkObjects.networkObjects,
      devices: state => state.device.devices
    }),
    connectionTypes: () => Device.connectionTypesList
  },
  mounted() {
    this.$store.dispatch('networkObjects/getAllNetworkObjects');
    this.$store.dispatch('device/getDevices');
  },
  methods: {
    checkType() {
      if (this.formType === 'create') this.createDevice()
      else this.updateDevice();
    },
    createDevice() {
      this.$store.dispatch('device/createDevice', this.payload)
          .then(() => {
            showToast('Устройство добавлено', 'success');
            this.$bvModal.hide('device-form');
            this.$store.dispatch('networkObjectPage/getNetworkObjectDevices', {
              construction_id: this.$route.params.id
            });
          })
          .catch(error => showAlert('Ошибка', error))
    },
    updateDevice() {
      this.$store.dispatch('device/updateDevice', {
        deviceId: this.device.id,
        payload: this.payload
      })
          .then(() => {
            showToast('Устройство обновлено', 'success');
            this.$bvModal.hide('device-form');
            this.$store.dispatch('networkObjectPage/getNetworkObjectDevices', {
              construction_id: this.$route.params.id
            });
          })
          .catch(error => showAlert('Ошибка', error))
    },
    fillFields() {
      if (this.device) {
        this.formType = 'update';

        this.payload.construction_id = this.device.networkObject ? this.device.networkObject.id : null;
        this.payload.connection = this.device.dataSource;
        this.payload.name = this.device.name;
        this.payload.device_name = this.device.deviceName;
        const [lat, lng] = this.device.coords;
        this.payload.lat = lat.toString();
        this.payload.lng = lng.toString();
        this.payload.active = this.device.active;
        this.payload.parent_id = this.device.parentId;
      }
    },
    clearFields() {
      this.formType = 'create';

      this.payload = {
        construction_id: null,
        connection: null,
        name: null,
        device_name: null,
        lat: null,
        lng: null,
        active: true,
        parent_id: null
      }
    }
  }
}
</script>

<style scoped>

</style>