<template>
  <div v-if="task !== null"
       class="row">
    <div class="col-xl-4 col-xs-12 mb-3">
      <div class="row">
        <div class="col-12 mb-3">
          <task-info/>
        </div>

        <div class="col-12 mb-3">
          <div class="custom-card">
            <div @click="$router.push(`/issue/${task.issue.id}`)"
                 class="card-body cursor-pointer">
              <div class="task-card-title">
                Связанная заявка <span>#{{ task.issue.id }}</span>
              </div>
              <div class="task-info">
                Адрес: {{ task.issue.getFullAddress() }}
              </div>
            </div>
          </div>
        </div>

        <div v-if="task.task.state.id !== TASK_STATE.CLOSED"
             class="col-12 mb-3">
          <task-actions />
        </div>

        <div class="col-12">
          <task-files-list v-if="task.task.typeFeatures.some(item => item.slug === 'attachments')" />
        </div>

      </div>
    </div>

    <div class="col-xl-4 col-xs-12 mb-3">
      <works-list
        v-if="task.task.typeFeatures.some(item => item.slug === 'works')"
        class="mb-3" />

      <emergency-elements-list
        v-if="task.task.typeFeatures.some(item => item.slug === 'emergency_elements')"
        :show-add-btn="true"
        :show-edit-btn="true"
        class="mb-3" />

      <mechanical-equipments-list
        v-if="task.task.typeFeatures.some(item => item.slug === 'equipments')"
        class="mb-3"/>

      <water-flows-list
        v-if="task.task.typeFeatures.some(item => item.slug === 'water_flows')"
        class="mb-3"/>

      <task-transport-orders-list
        v-if="task.task.typeFeatures.some(item => item.slug === 'transport_order')"
        class="mb-3" />

      <comments-list />
    </div>

    <div class="col-lg-4 col-xs-12">
      <div class="row">
        <div class="col-12">
          <div class="custom-card">
            <div class="card-body">
              <div class="task-card-title mb-1">
                История
              </div>
              <history-list :history="taskHistory"/>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import TaskActions from "@/components/ServiceDesk/Tasks/TaskActions";
import TaskFilesList from "@/components/ServiceDesk/Tasks/TaskFilesList";
import TaskInfo from "@/components/ServiceDesk/Tasks/TaskInfo";
import HistoryList from "@/components/ServiceDesk/HistoryList";
import WorksList from "@/components/ServiceDesk/Tasks/WorksList";
import CommentsList from "@/components/ServiceDesk/CommentsList";
import EmergencyElementsList from "@/components/ServiceDesk/EmergencyElementsList";
import MechanicalEquipmentsList from "@/components/ServiceDesk/Tasks/MechanicalEquipmentsList";
import WaterFlowsList from "@/components/ServiceDesk/Tasks/WaterFlowsList";
import TaskTransportOrdersList from "@/components/ServiceDesk/Tasks/TaskTransportOrdersList";
import TaskState from "@/models/serviceDesk/TaskState";

export default {
  name: "TaskPage",
  components: {
    TaskTransportOrdersList,
    WaterFlowsList,
    MechanicalEquipmentsList,
    EmergencyElementsList,
    CommentsList,
    WorksList,
    HistoryList,
    TaskInfo,
    TaskActions,
    TaskFilesList
  },
  data() {
    return {
      timing: null
    }
  },
  computed: {
    ...mapGetters({
      task: 'task/getTask',
      taskHistory: 'history/getTaskHistory'
    }),
    TASK_STATE: () => TaskState
  },
  mounted() {
    this.getTask();
    this.timing = setInterval(this.getTask, process.env.VUE_APP_SERVICE_DESK_GET_DATA_TIMING);
  },
  beforeDestroy() {
    this.$store.commit('files/SET_FILES', []);
    this.$store.commit('task/SET_TASK', {});
    clearInterval(this.timing);
  },
  methods: {
    getTask() {
      const taskId = this.$route.params.id;

      this.$store.dispatch('task/getTask', taskId)
        .then(() => this.$store.dispatch('emergencyElements/getEmergencyElements', this.task.issue.id));
      this.$store.dispatch('history/getTaskHistory', taskId);
      this.$store.dispatch('files/getTasksFiles', taskId);
      this.$store.dispatch('works/getTaskWorks', taskId);
      this.$store.dispatch('comments/getTaskComments', taskId);
      this.$store.dispatch('taskMechanicalEquipments/getTaskMechanicalEquipments', taskId);
      this.$store.dispatch('waterFlows/getTaskWaterFlows', taskId);
      this.$store.commit('transportOrders/SET_FILTER_PARAMS', {task_id: taskId});
      this.$store.dispatch('transportOrders/getTransportOrders');
    }
  }
}
</script>

<style scoped>
.task-info {
  font-size: 15px;
}

.task-card-title {
  font-size: 20px;
  font-weight: 600;
  color: black;
  margin-bottom: 0;
}

.task-card-title span {
  color: #468FC5;
  font-weight: 500;
}
</style>