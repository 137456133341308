<template>
  <div class="row">
    <div class="col-12">
      <div class="custom-card">
        <div class="card-body">
          <div class="row">
            <div class="col-12">
              <h4 class="table-title d-inline-block">
                Доступы к устройствам
              </h4>
              <div
                v-if="loading"
                class="d-inline-block ml-2">
                <base-loader />
              </div>
              <router-link to="/allow-device-access">
                <button class="btn custom-btn-blue float-right">
                  Предоставить доступ
                </button>
              </router-link>
              <hr class="divider">
            </div>

            <div
              v-if="deviceAccesses.length"
              class="col-12"
            >
              <device-accesses-list />
            </div>

            <div
              v-if="!deviceAccesses.length && !loading"
              class="col-12 mt-2"
            >
              <div class="no-result-title">Доступы не найдены</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BaseLoader from "@/layout/BaseLoader";
import DeviceAccessesList from "@/components/Telemetry/admin/DeviceAccessesList";
import {mapState} from "vuex";
export default {
  name: "DeviceAccessesListPage",
  components: {DeviceAccessesList, BaseLoader},
  computed: {
    ...mapState({
      deviceAccesses: state => state.deviceAccess.deviceAccesses,
      loading: state => state.deviceAccess.loading
    })
  },
  mounted() {
    this.$store.dispatch('deviceAccess/getAccessesList');
  }
}
</script>

<style scoped>

</style>