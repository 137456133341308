<template>
  <div class="col-12 custom-padding">
    <div class="device-card"
         :class="getBackgroundIconClass(equipment.networkObject.type)">
      <div class="device-card-body">

        <div class="row">

          <div class="col-12 d-flex align-items-center mb-1">
            <div class="d-inline-block">
              <div class="device-icon"
                   :class="getIconClass(equipment.networkObject.type)"/>
            </div>
            <div class="d-inline-block ml-2">
              <span
                class="device-title"
              >{{ equipment.name }}</span>
            </div>
          </div>

          <div class="col-12 row">
            <div
              v-if="!equipment.parameters.length"
              class="no-result-title"
            >
              Датчики отсутствуют.
            </div>
            <parameter-item
              v-for="parameter in equipment.parameters"
              :id="`sensor_${parameter.id}`"
              :key="`sensor_${parameter.id}`"
              :parameter="parameter"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ParameterItem from "@/components/Telemetry/ParameterItem";
import formatDate from "@/mixins/formatDate";
import sensorsTime from "@/mixins/sensorsTime";

export default {
  name: "NetworkEquipmentItem",
  components: {ParameterItem},
  mixins: [formatDate, sensorsTime],
  props: [
    "equipment",
    "coords"
  ],
  computed: {
    getIconClass: function () {
      return type => {
        switch (type) {
          case 1:
            return 'pump-svg'
          case 2:
            return 'tank-svg'
          case 3:
            return 'mkd-svg'
          case 4:
            return 'counter-svg'
          case 5:
            return 'boiler-svg'
        }
      }
    },
    getBackgroundIconClass: function () {
      return type => {
        switch (type) {
          case 1:
            return 'pump-bg'
          case 2:
            return 'tank-bg'
          case 3:
            return 'mkd-bg'
          case 4:
            return 'counter-bg'
          case 5:
            return 'boiler-bg'
        }
      }
    }
  }
}
</script>

<style scoped>
.device-card {
  background-color: #EBF1F3;
  border-radius: 10px;
  padding: 13px 15px;
  margin-bottom: 10px;
  position: relative;
  overflow: hidden;
}

.time-block-wrapper {
  display: flex;
  line-height: 1;
}

.time-block {
  font-size: 12px;
  font-weight: 700;
}

.control-wrapper {
  display: flex;
}

.vehicle-control {
  display: flex;
}

.vehicle-control-card {
  display: flex;
  margin-right: 5px;
  cursor: pointer;
}

.vehicle-control-card:nth-last-child(1) {
  margin-right: 0;
}

.device-title {
  font-size: 14px;
  font-weight: 700;
  color: #468FC5;
}

.custom-padding {
  padding: 0 20px 0 0;
}

.no-result-title {
  font-size: 14px;
  padding: 0 12px;
}
</style>