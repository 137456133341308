<template>
  <div class="row">
    <div class="col-lg-12">
      <div class="custom-card">
        <div class="card-header pb-0">
          <h4 class="table-title d-inline-block">
            Пользователи
          </h4>
          <router-link
            to="/admin-users-create"
            class="btn custom-btn-blue-outline float-right"
          >
            Добавить пользователя
          </router-link>
          <hr class="divider">
        </div>
        <div class="card-body">
          <div class="container-fluid">
            <div class="row">
              <users-table />
              <base-pagination
                v-if="this.$store.state.user.meta && this.$store.state.user.meta.last_page > 1"
                path="admin-users"
                :meta="this.$store.state.user.meta"
                :links="this.$store.state.user.links"
                action="user/getUsers"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import UsersTable from "@/components/admin/UsersList";
import BasePagination from "@/components/BasePagination";
import store from "@/store/index";

export default {
  name: "UsersPage",
  components: {
    UsersTable,
    BasePagination
  },
  beforeRouteEnter(to, from, next) {
    const currentPage = parseInt(to.query.page) || 1;
    store.dispatch("user/getUsers", currentPage).then(() => {
      to.params.page = currentPage;
      next();
    });
  },
  watch: {
    $route: 'fetchData'
  }
}
</script>

<style scoped>

</style>