<template>
  <b-modal
    id="create-appeal"
    ref="create-appeal"
    centered
    hide-header
    hide-footer
    size="xl"
    class="appeal-modal"
    @shown="needResize = true"
    @hidden="needResize = false"
  >
    <div class="appeal-modal-content">
      <div class="row">
        <div class="col-12 mb-3">
          <div class="custom-modal-title">
            Создать обращение
          </div>
          <img
            class="custom-modal-close"
            src="@/assets/img/icons/close.svg"
            @click="$bvModal.hide('create-appeal')"
          >
        </div>
      </div>
      <div class="row">
        <div class="col-xl-5 col-xs-12">
          <form @submit.prevent="submitForm()"
                class="appeal-form scroll">
            <problem-category-selector
              @update:category_id="event => payload.category_id = event"
              :category_id="payload.category_id"
              :validation="$v.payload.category_id"
            />
            <div class="form-group">
              <label class="form-label d-block">Способ подачи обращения</label>
              <label class="custom-control custom-radio d-inline-block mr-2">
                <input v-model="payload.source"
                       :value="2"
                       class="custom-control-input"
                       name="custom-radio"
                       type="radio">
                <span class="custom-control-label">Звонок</span>
              </label>
              <label class="custom-control custom-radio d-inline-block mr-2">
                <input v-model="payload.source"
                       :value="1"
                       class="custom-control-input"
                       name="custom-radio"
                       type="radio">
                <span class="custom-control-label">Сайт</span>
              </label>
              <label class="custom-control custom-radio d-inline-block">
                <input v-model="payload.source"
                       :value="3"
                       class="custom-control-input"
                       name="custom-radio"
                       type="radio">
                <span class="custom-control-label">Мессенджер</span>
              </label>
            </div>
            <div class="form-group">
              <label class="form-label">Адрес проблемы</label>
              <dadata-suggestions
                class="form-control form-control-fix"
                type="ADDRESS"
                @change="setAddress"
                placeholder="Введите адрес"
              />
            </div>
            <div class="form-group">
              <label class="form-label">Телефон обратившегося</label>
              <the-mask
                v-model="$v.payload.applicant_phone.$model"
                :mask="['+7 (###) ###-##-##']"
                :masked="true"
                class="form-control"
                :class="{'is-invalid': ($v.payload.applicant_phone.$dirty && !$v.payload.applicant_phone.required)}"
                placeholder="Введите телефон"
              />
              <label class="error small form-text invalid-feedback">
                Поле обязательно для заполнения
              </label>
            </div>
            <div class="form-group">
              <label class="form-label">ФИО обратившегося</label>
              <dadata-suggestions
                class="form-control form-control-fix"
                v-model="$v.payload.applicant_name.$model"
                :class="{'is-invalid': ($v.payload.applicant_name.$dirty && !$v.payload.applicant_name.required)}"
                type="NAME"
                placeholder="Введите ФИО"
              />
              <label class="error small form-text invalid-feedback">
                Поле обязательно для заполнения
              </label>
            </div>
            <div class="form-group">
              <label class="form-label">Тип обратившегося</label>
              <select
                v-model="$v.payload.applicant_type.$model"
                class="form-control form-control-fix mb-1"
                :class="{'is-invalid': ($v.payload.applicant_type.$dirty && !$v.payload.applicant_type.required)}"
              >
                <option
                  disabled
                  value="null"
                >
                  Выберите тип
                </option>
                <option :value="1">
                  Физическое лицо
                </option>
                <option :value="2">
                  Юридическое лицо
                </option>
                <option :value="3">
                  Тех. лаборатория отдела ОРСиС
                </option>
                <option :value="4">
                  ВКХ
                </option>
                <option :value="5">
                  ИВР
                </option>
                <option :value="6">
                  ЕДС
                </option>
              </select>
              <label class="error small form-text invalid-feedback">
                Поле обязательно для заполнения
              </label>
              <div v-for="(tag, index) in applicantTypeTags"
                   :key="index"
                   class="form-tag"
                   @click="payload.applicant_type = tag.value">{{tag.name}}</div>
            </div>
            <div class="form-group">
              <label class="form-label">Комментарий</label>
              <textarea
                v-model="$v.payload.appeal_message.$model"
                class="form-control"
                :class="{'is-invalid': ($v.payload.appeal_message.$dirty && !$v.payload.appeal_message.required)}"
                maxlength="1500"
                placeholder="Комментарий..."
              />
              <label class="error small form-text invalid-feedback">
                Поле обязательно для заполнения
              </label>
            </div>
            <div class="form-group">
              <label class="form-label">Связанная заявка</label>
              <v-select
                v-model="linked_issue_id"
                :options="issues"
                :reduce="issue => issue.id"
                :get-option-label="(option) => option.getCaption()"
                placeholder="Выберите заявку"
              />
            </div>
            <p v-if="$v.payload.lat.$dirty && !$v.payload.lat.required"
               class="form-error">
              Не указаны координаты, поставьте метку на карте
            </p>
            <button
              type="submit"
              :disabled="lock_button"
              class="btn custom-btn-blue w-100"
            >
              Создать обращение
            </button>
          </form>
        </div>
        <div class="col-xl-7 col-xs-12 map-block">
          <create-appeal-map :need-resize="needResize" />
        </div>
      </div>
    </div>
  </b-modal>
</template>

<script>
import notification from "@/mixins/notification";
import {TheMask} from "vue-the-mask";
import Vue from "vue";
import DadataSuggestions from "vue-dadata-suggestions";
import {mapGetters} from "vuex";
import CreateAppealMap from "@/components/CallCenter/CreateAppealMap";
import { validationMixin } from 'vuelidate'
import { required } from 'vuelidate/lib/validators'
import IssueState from "@/models/serviceDesk/IssueState";
import ProblemCategorySelector from "@/components/ServiceDesk/ProblemCategorySelector";

Vue.use(DadataSuggestions, {
  token: process.env.VUE_APP_DADATA_API_KEY,
  geoLocation: [{
    "kladr_id": process.env.VUE_APP_DADATA_KLADR_ID
  }]
});

export default {
  name: "CreateAppealForm",
  components: {ProblemCategorySelector, CreateAppealMap, TheMask},
  mixins: [notification, validationMixin],
  data() {
    return {
      payload: {
        applicant_type: null,
        applicant_name: null,
        applicant_phone: null,
        source: 2,
        appeal_message: null,
        category_id: null,
        address_region: null,
        address_city: null,
        address_settlement: null,
        address_street: null,
        address_house: null,
        address_block: null,
        address_flat: null,
        lat: null,
        lon: null
      },
      linked_issue_id: null,
      lock_button: false,
      needResize: false,
      applicantTypeTags: [
        {name: 'физ. лицо', value: 1},
        {name: 'юр. лицо', value: 2},
        {name: 'ОРСиС', value: 3},
        {name: 'ВКХ', value: 4},
        {name: 'ИВР', value: 5},
        {name: 'ЕДС', value: 6}
      ]
    }
  },
  validations: {
    payload: {
      applicant_type: {required},
      applicant_name: {required},
      applicant_phone: {required},
      source: {required},
      appeal_message: {required},
      category_id: {required},
      lat: {required},
      lon: {required}
    }
  },
  computed: {
    ...mapGetters({
      issues: 'issues/getAllIssues',
      appealCategories: 'serviceDeskCategories/getCategories'
    })
  },
  mounted() {
    this.$store.dispatch('serviceDeskCategories/getAllCategories');
    this.$store.dispatch('issues/getAllIssues', [
        IssueState.NEW,
        IssueState.APPOINTED,
        IssueState.ACCEPTED,
        IssueState.IN_WORK,
        IssueState.REJECTED
    ].join());

    this.$root.$on('BaseMap::map-click', coords => {
      this.payload.lat = coords.lat
      this.payload.lon = coords.lng
    })
  },
  methods: {
    submitForm() {
      this.lock_button = true
      if (this.$v.$invalid) {
        this.$v.$touch();
        this.lock_button = false;
        return false
      } else {
        this.$v.$reset();
        this.$store.dispatch('appeals/createAppeal', this.payload)
            .then(id => {
              if (this.linked_issue_id !== null) {
                this.$store.dispatch('appeals/linkAppealToIssue', {
                  appeal_id: id,
                  params: {
                    issue_id: this.linked_issue_id
                  }
                });
              }

              this.$store.dispatch('appeals/getAppeals', 1);
              this.$bvModal.hide('create-appeal');
              this.lock_button = false;

              this.payload = {
                applicant_type: null,
                applicant_name: null,
                applicant_phone: null,
                source: 2,
                appeal_message: null,
                category_id: null,
                address_region: null,
                address_city: null,
                address_settlement: null,
                address_street: null,
                address_house: null,
                address_block: null,
                address_flat: null,
                lat: null,
                lon: null
              }
            })
            .catch(() => {
              this.alert('Ошибка', this.$store.state.appeals.error, 'error');
              this.lock_button = false;
            })
      }
    },
    setAddress(i, inputEnter = true) {
      this.payload.address_region = i.data.region;
      this.payload.address_city = i.data.city;
      this.payload.address_settlement = i.data.settlement_with_type;
      this.payload.address_street = i.data.street_with_type;
      this.payload.address_house = i.data.house;
      this.payload.address_block = i.data.block;
      this.payload.address_flat = i.data.flat;

      if (i.data.qc_geo < 4) {
        this.payload.lat = parseFloat(i.data.geo_lat);
        this.payload.lon = parseFloat(i.data.geo_lon);
      }

      if (inputEnter) {
        let dadataCallback = {
          coords: [parseFloat(i.data.geo_lat), parseFloat(i.data.geo_lon)],
          qc_geo: i.data.qc_geo
        }
        this.$root.$emit('fit-bounds-map', dadataCallback)
      }
    }
  }
}
</script>

<style scoped>
.custom-modal-title {
  font-size: 24px;
  text-align: center;
}

.custom-modal-close {
  position: absolute;
  right: 10px;
  top: 10px;
}

.form-label {
  font-weight: 600;
  color: #262F3D;
}

.scroll {
  overflow-y: auto;
  max-height: calc(100vh - 200px);
  overflow-x: hidden;
  padding: 0 20px 0 10px;
}

.scroll::-webkit-scrollbar {
  width: 7px;
  height: 8px;
  background-color: #C7D3D9;
  border-radius: 0.2em;
}

.scroll::-webkit-scrollbar-thumb {
  background-color: #97ACB5;
  border-radius: 0.2em;
}

@media (max-width: 766px) {
  .map-block {
    margin-top: 40px;
    height: 400px;
  }
}
</style>