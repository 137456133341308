<template>
  <nav aria-label="Page navigation example">
    <p class="page-number">
      Страница {{ meta.current_page }} из {{ meta.last_page }}
    </p>
    <ul class="pagination pagination-md">
      <li class="page-item">
        <a
          v-if="links.prev"
          class="page-link"
          href="javascript:void(0)"
          @click="prevPage"
        >
          <arrow-left-icon class="pagination-arrows" />
        </a>
      </li>
      <li class="page-item">
        <a
          v-if="links.prev"
          class="page-link"
          href="javascript:void(0)"
          @click="firstPage"
        >Первая</a>
      </li>
      <li class="page-item">
        <a
          v-if="links.next"
          class="page-link"
          href="javascript:void(0)"
          @click="lastPage"
        >Последняя</a>
      </li>
      <li class="page-item">
        <a
          v-if="links.next"
          class="page-link"
          href="javascript:void(0)"
          @click="nextPage"
        >
          <arrow-right-icon class="pagination-arrows" />
        </a>
      </li>
    </ul>
  </nav>
</template>

<script>
export default {
  name: "BasePaginationPayload",
  props: {
    action: {
      type: String,
      required: true
    },
    path: {
      type: String,
      default: null
    },
    meta: {
      type: Object,
      required: true
    },
    links: {
      type: Object,
      required: true
    },
    payload: {
      type: Object,
      required: true
    }
  },
  methods: {
    firstPage() {
      this.payload['page'] = 1
      this.$store.dispatch(this.action, this.payload).then(() => {
        if (this.path) {
          this.$router.push({
            path: this.path,
            query: {page: 1}
          });
        }
      });
    },
    prevPage() {
      this.payload['page'] = this.meta.current_page - 1
      this.$store.dispatch(this.action, this.payload).then(() => {
        if (this.path) {
          this.$router.push({
            path: this.path,
            query: {page: this.meta.current_page - 1}
          });
        }
      });
    },
    nextPage() {
      this.payload['page'] = this.meta.current_page + 1
          this.$store.dispatch(this.action, this.payload).then(() => {
        if (this.path) {
          this.$router.push({
            path: this.path,
            query: {page: this.meta.current_page + 1}
          });
        }
      });
    },
    lastPage() {
      this.payload['page'] = this.meta.last_page
      this.$store.dispatch(this.action, this.payload).then(() => {
        if (this.path) {
          this.$router.push({
            path: this.path,
            query: {page: this.meta.last_page}
          });
        }
      });
    }
  }
}
</script>

<style scoped>
.page-number {
  color: #262F3D;
  font-size: 13px;
  margin: 5px 0 10px;
}

.page-link {
  color: #262F3D;
  background-color: #F9FDFF;
  border: 1px solid #468FC5;
}
</style>