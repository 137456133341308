<template>
  <div>
    <table class="admin-table">
      <thead class="admin-table-header">
        <tr>
          <td>#</td>
          <td>Наименование</td>
          <td>Тег</td>
          <td>Макс. время выполнения</td>
          <td>Группы проблем</td>
          <td>Компоненты</td>
          <td>Управление</td>
        </tr>
      </thead>
      <tbody class="admin-table-body">
        <tr
          v-for="task_type in task_types"
          :key="task_type.id"
          class="task-type-item"
        >
          <td>{{ task_type.id }}</td>
          <td>{{ task_type.name }}</td>
          <td>{{ Tag.getTagLabel(task_type.tag) }}</td>
          <td>{{ task_type.leadTime }}</td>
          <td>{{ task_type.categoryGroups.map(item => item.name).join(', ') }}</td>
          <td>{{ task_type.features.map(item => item.name).join(', ') }}</td>
          <td>
            <router-link :to="`/task-types-update/`+task_type.id">
              <edit-icon class="mr-1" />
            </router-link>
            <trash2-icon @click="deleteTaskType(task_type.id)"
                         class="cursor-pointer" />
          </td>
        </tr>
      </tbody>
    </table>
    <base-pagination
      v-if="$store.state.task_types.meta
        && $store.state.task_types.meta.last_page > 1"
      path="task-types"
      :meta="$store.state.task_types.meta"
      :links="$store.state.task_types.links"
      action="task_types/getTaskTypes"
    />
  </div>
</template>

<script>
import BasePagination from "@/components/BasePagination";
import {mapGetters} from "vuex";
import Tag from '@/models/serviceDesk/Tag'
import {showAlert, showToast} from "@/utils/notification";

export default {
  name: "TaskTypesList",
  components: {BasePagination},
  data() {
    return {
      Tag: Tag
    }
  },
  computed: {
    ...mapGetters({
      task_types: 'task_types/getTaskTypes'
    })
  },
  methods: {
    deleteTaskType(taskTypeId) {
      this.$store.dispatch('task_types/deleteTaskType', taskTypeId)
          .then(() => {
            showToast('Тип задания удалён', 'success');
            this.$store.commit('task_types/DELETE_TASK_TYPE', taskTypeId);
          })
          .catch(error => showAlert('Ошибка', error))
    }
  }
}
</script>

<style scoped>

</style>