import WorkActualConsumption from "@/models/serviceDesk/WorkActualConsumption";

export default class TaskWork {
    constructor(id, attributes) {
        this.id = id;
        this.name = attributes.name;
        this.comment = attributes.comment;
        this.element = attributes.element || null;
        this.workId = attributes.work_id;
        this.consumptions = attributes.consumptions
            .map(consumption => new WorkActualConsumption({
                id: consumption.id,
                ...consumption.attributes
            }))
    }
}