<template>
  <b-modal
    id="update-network-object"
    ref="update-network-object"
    centered
    hide-header
    hide-footer
    @shown="fillFields()"
    @hidden="clearFields()"
  >
    <div class="col-12">
      <div class="custom-modal-header">
        <div class="custom-modal-title">
          Изменить объект сети
        </div>
        <img
          alt="Закрыть"
          class="custom-modal-close"
          src="@/assets/img/icons/close.svg"
          @click="$bvModal.hide('update-network-object')"
        >
      </div>
      <div class="modal-divider"/>
    </div>
    <form @submit.prevent="updateNetworkObject()">
      <div class="col-12">
        <div class="form-group">
          <label class="form-label">Наименование</label>
          <input
            v-model="payload.name"
            type="text"
            class="form-control form-control-fix"
            placeholder="Наименование"
            maxlength="31"
            required
          >
        </div>
        <div class="form-group">
          <label class="form-label">Тип</label>
          <v-select
            v-model="payload.construction_type"
            :options="NetworkObjectTypes"
            :reduce="type => type.id"
            :get-option-lable="(option) => option.label"
            placeholder="Выберите тип объекта"
          />
        </div>
        <div class="form-group">
          <label class="form-label">Адрес</label>
          <input
            v-model="payload.address"
            type="text"
            class="form-control form-control-fix"
            placeholder="Адрес"
            required
          >
        </div>
        <div class="form-group">
          <label class="form-label">Телефон</label>
          <input
            v-model="payload.phone"
            type="text"
            class="form-control form-control-fix"
            placeholder="Телефон"
            required
          >
        </div>
        <div class="form-group">
          <label class="form-label">Широта</label>
          <input
            v-model="payload.lat"
            type="text"
            class="form-control form-control-fix"
            placeholder="Широта"
            required
          >
        </div>
        <div class="form-group">
          <label class="form-label">Долгота</label>
          <input
            v-model="payload.lng"
            type="text"
            class="form-control form-control-fix"
            placeholder="Долгота"
            required
          >
        </div>
        <div class="text-center">
          <hr>
          <button
            type="submit"
            class="btn custom-btn-blue w-100"
          >
            Сохранить
          </button>
        </div>
      </div>
    </form>
  </b-modal>
</template>

<script>
import {mapState} from "vuex";
import {showAlert, showToast} from "@/utils/notification";
import NetworkObject from "@/models/telemetry/NetworkObject";

export default {
  name: "NetworkObjectUpdateForm",
  data() {
    return {
      payload: {
        construction_type: null,
        name: null,
        address: null,
        phone: null,
        lat: null,
        lng: null
      }
    }
  },
  computed: {
    ...mapState({
      networkObject: state => state.networkObjects.networkObject
    }),
    NetworkObjectTypes: () => NetworkObject.getTypesArray()
  },
  methods: {
    updateNetworkObject() {
      this.$store.dispatch('networkObjects/updateNetworkObject', {
        networkObjectId: this.networkObject.id,
        payload: this.payload
      })
          .then(() => {
            showToast('Объект сети обновлён', 'success');
            this.$store.dispatch('networkObjects/getNetworkObject', this.networkObject.id);
            this.$bvModal.hide('update-network-object');
          })
          .catch(error => showAlert('Ошибка', error))
    },
    fillFields() {
      const [lat, lng] = this.networkObject.coordinates;
      this.payload = {
        construction_type: this.networkObject.type,
        name: this.networkObject.name,
        address: this.networkObject.address,
        phone: this.networkObject.phone,
        lat: lat,
        lng: lng
      }
    },
    clearFields() {
      this.payload = {
        construction_type: null,
        name: null,
        address: null,
        phone: null,
        lat: null,
        lng: null
      }
    }
  }
}
</script>

<style scoped>

</style>