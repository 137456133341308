<template>
  <div class="row">
    <div class="col-md-12 mb-3">
      <energy-consumption-object-info />
    </div>
    <div class="col-md-12">
      <electric-meters-list />
    </div>
  </div>
</template>

<script>
import EnergyConsumptionObjectInfo from "@/components/EnergyConsumption/admin/EnergyConsumptionObjectInfo";
import ElectricMetersList from "@/components/EnergyConsumption/admin/ElectricMetersList";
export default {
  name: "EnergyConsumptionObjectPage",
  components: {ElectricMetersList, EnergyConsumptionObjectInfo},
  mounted() {
    this.$store.dispatch('energyConsumptionObjects/getEnergyConsumptionObjectWithMeters', this.$route.params.id);
  }
}
</script>

<style scoped>

</style>