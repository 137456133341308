<template>
  <b-modal
    id="assign-brigade"
    ref="assign-brigade"
    centered
    hide-header
    hide-footer
  >
    <div class="col-12">
      <div class="custom-modal-header">
        <div class="custom-modal-title">
          Назначение бригады
        </div>
        <img
          alt="Закрыть"
          class="custom-modal-close"
          src="@/assets/img/icons/close.svg"
          @click="$bvModal.hide('assign-brigade')"
        >
      </div>
      <div class="modal-divider" />
    </div>
    <form @submit.prevent="submitForm()">
      <div class="col-12 mb-2">
        <div class="form-group">
          <label class="form-label">Бригада</label>
          <v-select v-model="payload.brigade_id"
                    :options="brigades"
                    :reduce="brigade => brigade.id"
                    :get-option-label="(option) => option.name"
                    placeholder="Выберите бригаду"
          />
        </div>
        <div class="form-group">
          <label class="form-label">Плановая дата начала</label>
          <date-picker
            v-model="payload.planned_start_date"
            :default-value="defaultValue"
            input-class="form-control-fix w-100 pl-2"
            placeholder="Выберите дату"
            type="datetime"
            :minuteStep="15"
            :format="DATE_TIME_FORMAT"
            value-type="YYYY-MM-DD HH:mm"
          />
          <div
            class="input-tag"
            v-for="tag in dateTags"
            :key="tag.value"
            @click="payload.planned_start_date = $moment().add(tag.value, 'hours').format('YYYY-MM-DD HH:mm')"
          >{{tag.label}}</div>
        </div>
        <div class="form-group">
          <label class="form-label">Плановая дата окончания</label>
          <date-picker
            v-model="payload.planned_end_date"
            :default-value="defaultValue"
            input-class="form-control-fix w-100 pl-2"
            placeholder="Выберите дату"
            type="datetime"
            :minuteStep="15"
            :format="DATE_TIME_FORMAT"
            value-type="YYYY-MM-DD HH:mm"
          />
          <div
            class="input-tag"
            v-for="tag in dateTags"
            :key="tag.value"
            @click="payload.planned_end_date = $moment().add(tag.value, 'hours').format('YYYY-MM-DD HH:mm')"
          >{{tag.label}}</div>
        </div>
        <div class="modal-divider" />
        <div class="row">
          <div class="col-12">
            <button
              type="submit"
              class="btn custom-btn-blue w-100"
            >
              Назначить
            </button>
          </div>
        </div>
      </div>
    </form>
  </b-modal>
</template>

<script>
import {mapState} from "vuex";
import notification from "@/mixins/notification";
import DatePicker from "vue2-datepicker";

export default {
  name: "AssignBrigade",
  components: {DatePicker},
  mixins: [notification],
  data() {
    return {
      payload: {
        brigade_id: null,
        planned_start_date: null,
        planned_end_date: null
      },
      defaultValue: new Date(),
      dateTags: [
        {value: 0, label: 'Сейчас'},
        {value: 2, label: '+2 часа'},
        {value: 4, label: '+4 часа'},
        {value: 8, label: '+8 часов'}
      ]
    }
  },
  computed: {
    ...mapState({
      brigades: state => state.departmentBrigades.brigades,
      task: state => state.task.task
    }),
    DATE_TIME_FORMAT: () => process.env.VUE_APP_BASE_DATE_TIME_FORMAT
  },
  mounted() {
    this.$store.dispatch('departmentBrigades/getAllDepartmentBrigades', this.task.department.id);
  },
  methods: {
    submitForm() {
      this.$store.dispatch('task/assignBrigade', {
        task_id: this.task.task.id,
        payload: this.payload
      })
          .then(() => {
            this.$store.dispatch('task/getTask', this.task.task.id);
            this.$bvModal.hide('assign-brigade');
            this.toast('Бригада назначена на задание', 'success');
            this.$store.dispatch('history/getTaskHistory', this.task.task.id);
          })
          .catch(error => this.alert('Ошибка', error, 'error'));
    }
  }
}
</script>

<style scoped>
.input-tag {
  display: inline-block;
  cursor: pointer;
  background-color: white;
  color: #468FC5;
  border: 1px solid #468fc599;
  border-radius: 5px;
  padding: 2px 6px;
  margin: 2px 2px 0 0;
  font-weight: 600;
  font-size: 12px;
  transition: .3s;
}
</style>