<template>
  <div class="custom-card">
    <div class="card-body">
      <div class="card-title">Разрешения</div>
      <label v-for="permission in permissionsList"
             :key="permission.id"
             class="custom-control custom-checkbox">
        <input
          v-model="rolePermissions"
          :value="permission.id"
          class="custom-control-input"
          type="checkbox"
          :disabled="loading"
          @change="toggleRolePermission(permission.id, $event.target.checked)"
        >
        <span class="custom-control-label">
          {{ permission.customName ? permission.customName : permission.name }}
          <help-circle-icon
            v-if="permission.description"
            :id="`popover_${permission.id}`"
          />

          <b-popover
            :target="`popover_${permission.id}`"
            triggers="hover"
            placement="right"
          >
            {{permission.description}}
          </b-popover>
        </span>
      </label>
    </div>
  </div>
</template>

<script>
import {mapState} from "vuex";
import {showToast} from "@/utils/notification";

export default {
  name: "RolesPermissionsList",
  data() {
    return {
      rolePermissions: [],
      loading: false
    }
  },
  computed: {
    ...mapState({
      permissionsList: state => state.user.permissions,
      role: state => state.role.role
    })
  },
  mounted() {
    this.$store.dispatch('user/getPermissions');
    this.rolePermissions = this.role.permissions.map(item => item.id);
  },
  methods: {
    toggleRolePermission(id, checked) {
      this.loading = true;
      if (checked) {
        this.$store.dispatch('role/assignPermission', {
          roleId: this.role.id,
          permissionId: id
        })
            .then(() => showToast('Разрешение добавлено', 'success'))
            .finally(() => this.loading = false)
      } else {
        this.$store.dispatch('role/removePermission', {
          roleId: this.role.id,
          permissionId: id
        })
            .then(() => showToast('Разрешение удалено', 'success'))
            .finally(() => this.loading = false)
      }
    }
  }
}
</script>

<style scoped>

</style>