<template>
  <div class="row">
    <div class="col-xl-8 col-xs-12">
      <department-issues-list-filter />

      <div class="custom-card">
        <div class="card-body">
          <div class="row">

            <div
              v-if="!$store.state.issues.issues.length && !$store.state.issues.loading"
              class="col-md-12"
            >
              <div class="no-result-title">
                Заявки не найдены
              </div>
            </div>

            <div
              v-if="$store.state.issues.issues.length"
              class="col-md-12"
            >
              <issues-list
                :headers="headers"
                :issues="issues"
              />
            </div>

            <div
              v-if="$store.state.issues.loading"
              class="col-12 text-center"
            >
              <base-loader />
            </div>

            <div
              v-if="$store.state.issues.meta
                && $store.state.issues.meta.last_page > 1"
              class="col-md-12">
              <base-paginate
                :meta="$store.state.issues.meta"
                action="issues/getIssues"
                commit="issues/SET_PAGE"
              />
            </div>

          </div>
        </div>
      </div>

    </div>
    <div class="col-xl-4 col-xs-12 map-base-height">
      <issues-list-map />
    </div>
  </div>
</template>

<script>
import IssuesList from "@/components/ServiceDesk/Issues/IssuesList";
import IssuesListMap from "@/components/ServiceDesk/Issues/IssuesListMap";
import DepartmentIssuesListFilter from "@/components/ServiceDesk/Issues/DepartmentIssuesListFilter";
import {mapGetters} from "vuex";
import BaseLoader from "@/layout/BaseLoader";
import BasePaginate from "@/components/BasePaginate";

export default {
  name: "DepartmentIssuesListPage",
  components: {BaseLoader, DepartmentIssuesListFilter, IssuesListMap, IssuesList, BasePaginate},
  data() {
    return {
      headers: [
        {text: '#', value: 'id', type: 'text'},
        {text: 'Дата открытия', value: 'created_at', type: 'dateTime'},
        {text: 'Тип', value: 'type_name', type: 'text'},
        {text: 'Категория', value: 'category', type: 'category'},
        {text: 'Адрес', value: 'address', type: 'address'},
        {text: 'Подразделение', value: 'department_name', type: 'text'},
        {text: 'Обращений', value: 'appeals_count', type: 'text'},
        {text: 'Теги', value: 'tags', type: 'tags'},
        {text: 'Комментарий', value: 'description', type: 'text'},
        {text: 'Статус', value: 'state', type: 'badge'}
      ]
    }
  },
  computed: {
    ...mapGetters({
      issues: 'issues/getIssues'
    })
  },
  beforeDestroy() {
    this.$store.commit('issues/SET_ISSUES', []);
    this.$store.commit('issues/SET_FILTER_PARAMS', null);
    this.$store.commit('issues/SET_META', null);
    this.$store.commit('issues/SET_LINKS', null);
    this.$store.commit('issues/SET_PAGE', null);
  }
}
</script>

<style scoped>

</style>