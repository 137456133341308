<template>
  <div class="appeals-map map-base-height">
    <base-map :center="center"
              :zoom="zoom"
              :layers="layers"
              :markers="createMarkers()"
              layers_control_position_top="60px"
              layers_control_position_right="20px"
              base_layers_control_position_top="110px"
    />
    <div @click="$store.commit('appeals/TOGGLE_HIDE_MAP')"
         class="hide-map">
      <img src="@/assets/img/icons/map.svg"
           alt="Скрыть">
    </div>
  </div>
</template>

<script>
import BaseMap from "@/components/BaseMap";
import {mapGetters, mapState} from "vuex";
import Marker from "@/models/baseMap/Marker";
import Icon from "@/models/baseMap/Icon";

export default {
  name: "AppealsListMap",
  components: {BaseMap},
  computed: {
    ...mapGetters({
      issues: 'issues/getIssues',
      hideMap: 'appeals/getHideMap',
      appeals: 'appeals/getAppeals'
    }),
    ...mapState({
      center: state => state.baseMap.center,
      zoom: state => state.baseMap.zoom,
      layers: state => state.baseMap.layers
    })
  },
  mounted() {
    this.$store.dispatch('baseMap/getLayers');
  },
  beforeDestroy() {
    this.$store.commit("baseMap/SET_LAYERS", []);
  },
  methods: {
    createMarkers() {
      return this.appeals.map(appeal => {
        return new Marker({
          coords: appeal.coordinates,
          popup_content: this.createPopupContent(appeal),
          icon: new Icon({
            type: 'div_icon',
            size: [24, 24],
            anchor: [12, 12],
            html: `<div class="appeal-map-icon" style="outline-color: ${appeal.category.group_color}">
                     <div class="appeal-map-img ${appeal.getImageClassName()}" />
                   </div>`
          })
        })
      })
    },
    createPopupContent(appeal) {
      return `
        <div class="appeal-popup-content">
            <div class="row">
                <div class="col-12 mb-2">
                    <div class="appeal-popup-number">
                        Обращение
                        <span>#${appeal.id}</span>
                    </div>
                </div>
                <div class="col-12 mb-2 d-flex align-items-end">
                    <div class="appeal-popup-icon ${appeal.getImageClassName()}"></div>
                    ${appeal.getStateLabel()}
                </div>
                <div class="col-12 mb-2 d-flex align-items-end">
                    <div class="appeal-popup-appeal-category"></div>
                    ${appeal.category.group_name}: ${appeal.category.name}
                </div>
            </div>
        </div>
        `
    }
  }
}
</script>

<style scoped>
.hide-map {
  position: absolute;
  z-index: 499;
  right: 20px;
  top: 10px;
  width: 40px;
  height: 40px;
  background-color: #EAEFF2;
  border: 1px solid #97ACB5;
  border-radius: 7px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
</style>