<template>
  <form @submit.prevent="submitForm()">
    <div class="row">
      <div class="col-12">
        <div class="form-group">
          <label class="form-label">Период</label>
          <date-picker
            input-class="form-control-fix w-100 pl-2"
            placeholder="Выберите период"
            v-model="period"
            :default-value="defaultValue"
            format="DD.MM.YYYY"
            type="day"
            range
            value-type="YYYY-MM-DD" />
        </div>
      </div>
    </div>
    <button class="btn custom-btn-blue w-100">Сформировать</button>
  </form>
</template>

<script>
import DatePicker from "vue2-datepicker";

export default {
  name: "FuelAndLubricantsReportForm",
  components: {DatePicker},
  data() {
    return {
      period: [this.$moment().format('YYYY-MM-DD'), this.$moment().format('YYYY-MM-DD')],
      defaultValue: new Date()
    }
  },
  methods: {
    submitForm() {
      this.$store.commit('reportsData/SET_REPORT_DATE', this.period);

      const [startDate, endDate] = this.period;
      this.$store.dispatch('reportsData/getFuelAndLubricantsReportData', {
        period_start: startDate,
        period_end: endDate
      })
    }
  }
}
</script>

<style scoped>

</style>