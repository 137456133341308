<template>
  <div>
    <table class="admin-table">
      <thead class="admin-table-header">
        <tr>
          <td>#</td>
          <td>Наименование</td>
          <td>Статус</td>
        </tr>
      </thead>
      <tbody class="admin-table-body">
        <tr v-for="department in departments"
            :key="department.id"
            class="department-item"
            @click="$router.push(`/update-department/`+department.id)"
        >
          <td>{{department.id}}</td>
          <td>{{department.name}}</td>
          <td>{{department.getActivityLabel()}}</td>
        </tr>
      </tbody>
    </table>
    <base-pagination
      v-if="$store.state.departments.meta
        && $store.state.departments.meta.last_page > 1"
      path="departments"
      :meta="$store.state.departments.meta"
      :links="$store.state.departments.links"
      action="departments/getDepartments"
    />
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import BasePagination from "@/components/BasePagination";

export default {
  name: "DepartmentsList",
  components: {BasePagination},
  computed: {
    ...mapGetters({
      departments: 'departments/getDepartments'
    })
  }
}
</script>

<style scoped>
.department-item {
  cursor: pointer;
}

.department-item:hover {
  background-color: #e8e8e8;
}
</style>