<template>
  <b-modal
    id="appoint-department"
    ref="appoint-department"
    centered
    hide-header
    hide-footer
  >
    <div class="col-12">
      <div class="custom-modal-header">
        <div class="custom-modal-title">
          Назначение подразделения
        </div>
        <img
          class="custom-modal-close"
          src="@/assets/img/icons/close.svg"
          @click="$bvModal.hide('appoint-department')"
        >
      </div>
      <div class="modal-divider" />
    </div>
    <form @submit.prevent="submitForm()">
      <div class="col-12 mb-2">
        <div class="form-group">
          <label class="form-label">Подразделение</label>
          <v-select
            v-model="department_id"
            :options="departments"
            :reduce="department => department.id"
            :get-option-label="(option) => option.name"
            placeholder="Выберите подразделение"
          />
        </div>
        <div class="modal-divider" />
        <div class="row">
          <div class="col-12">
            <button
              type="submit"
              class="btn custom-btn-blue w-100"
            >
              Назначить
            </button>
          </div>
        </div>
      </div>
    </form>
  </b-modal>
</template>

<script>
import {mapGetters} from "vuex";
import notification from "@/mixins/notification";

export default {
  name: "AppointDepartmentForm",
  mixins: [notification],
  data() {
    return {
      department_id: null
    }
  },
  computed: {
    ...mapGetters({
      departments: 'departments/getDepartments',
      issue_id: 'issue/getIssueId'
    })
  },
  mounted() {
    this.$store.dispatch('departments/getAllDepartments');
  },
  methods: {
    submitForm() {
      const payload = {
        issue_id: this.issue_id,
        params: {
          department_id: this.department_id
        }
      }
      this.$store.dispatch('issue/AppointDepartmentToIssue', payload)
          .then(() => {
            this.$bvModal.hide('appoint-department');
            this.$store.dispatch('issue/getIssue', this.issue_id);
            this.toast('Подразделение назначено', 'success');
            this.$store.dispatch('history/getIssueHistory', this.issue_id);
          })
          .catch(error => this.alert('Ошибка', error, 'error'));
    }
  }
}
</script>

<style scoped>

</style>