<template>
  <table v-if="dataServers.length"
         class="admin-table">
    <thead class="admin-table-header">
      <tr>
        <td>Наименование</td>
        <td>Тип</td>
        <td>Адрес</td>
        <td>Тип аутентификации</td>
        <td>Управление</td>
      </tr>
    </thead>
    <tbody class="admin-table-body">
      <tr
        v-for="dataServer in dataServers"
        :key="dataServer.id"
        class="brigade-item"
      >
        <td>{{ dataServer.name }}</td>
        <td>{{ dataServer.type.name }}</td>
        <td>{{ dataServer.address }}</td>
        <td>{{ dataServer.getAuthTypeLabel() }}</td>
        <td>
          <router-link :to="`/update-transport-data-server/${dataServer.id}`">
            <edit-icon class="mr-1"/>
          </router-link>
          <trash2-icon @click="deleteTransportDataServer(dataServer.id)"
                       class="cursor-pointer"/>
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
import {mapGetters} from "vuex";
import notification from "@/mixins/notification";

export default {
  name: "TransportDataServersList",
  mixins: [notification],
  computed: {
    ...mapGetters({
      dataServers: 'transportDataServers/getTransportDataServers'
    })
  },
  methods: {
    deleteTransportDataServer(id) {
      this.$store.dispatch('transportDataServers/deleteTransportDataServer', id)
          .then(() => {
            this.$store.dispatch('transportDataServers/getAllTransportDataServers');
            this.toast('Сервер данных удалён', 'success');
          })
    }
  }
}
</script>

<style scoped>
.tab-title {
  font-size: 24px;
  color: #468FC5;
  font-weight: bold;
}
</style>