<template>
  <div class="custom-card">
    <div class="card-body">
      <div class="container-fluid">
        <div class="row">
          <div class="col-12">
            <form @submit.prevent="submitForm">

              <div class="form-group">
                <label class="form-label">Наименование</label>
                <input
                  v-model="payload.name"
                  class="form-control"
                  type="text"
                  placeholder="Введите наименование"
                  required
                >
              </div>


              <div class="text-center">
                <button
                  type="submit"
                  class="btn custom-btn-blue w-100"
                >
                  Сохранить
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {showAlert, showToast} from "@/utils/notification";

export default {
  name: "NetworkEquipmentTypesForm",
  data() {
    return {
      payload: {
        name: null
      }
    }
  },
  methods: {
    submitForm() {
      this.$store.dispatch('networkEquipmentTypes/createNetworkEquipmentType', this.payload)
          .then(() => {
            this.$router.push('/network-equipment-types');
            showToast('Тип сетевого оборудования добавлен', 'success');
          })
          .catch(error => showAlert('Ошибка', error));
    }
  }
}
</script>

<style scoped>

</style>