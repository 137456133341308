<template>
  <div class="row">
    <div
      class="col-xl-4 col-md-6 col-sm-12 mb-3"
      v-for="chart in charts"
      :key="chart.id"
    >
      <div class="custom-card">
        <div class="card-body">
          <div class="chart-title">Количество открытых заявок {{ chart.group_name }}
            <base-loader v-if="loading"/>
          </div>
          <apexchart
            type="donut"
            :options="chart.options"
            :series="chart.series"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BaseLoader from "@/layout/BaseLoader";
import {mapState} from 'vuex'
import VueApexCharts from "vue-apexcharts";
import notification from "@/mixins/notification";

export default {
  name: "OpenedIssuesCountByGroups",
  components: {BaseLoader, 'apexchart': VueApexCharts},
  mixins: [notification],
  props: {
    filter: {
      required: true,
      type: Object
    }
  },
  data() {
    return {
      charts: []
    }
  },
  computed: {
    ...mapState({
      issuesCount: state => state.issuesStatistic.openedIssuesCountByGroups,
      categoryGroups: state => state.serviceDeskCategories.categoryGroups,
      loading: state => state.issuesStatistic.openedIssuesCountByGroupsLoading
    })
  },
  watch: {
    filter: function () {
      this.createChatData();
    }
  },
  mounted() {
    this.$store.dispatch('serviceDeskCategories/getCategoryGroups')
        .then(() => {
          this.createChatData();
        }).catch(error => this.toast(error, 'error'))
  },
  methods: {
    createChatData() {
      this.charts = [];
      this.$store.dispatch('issuesStatistic/getOpenedIssuesCountByGroups', {
        ...this.filter,
        finished: 0
      })
          .then(() => {
            this.categoryGroups.forEach(item => {
              const filteredStatistic = this.issuesCount.filter(statItem => statItem.group.id === item.id);
              if (filteredStatistic.length) {
                this.charts.push({
                  id: item.id,
                  group_name: item.name,
                  series: filteredStatistic.map(statItem => statItem.count),
                  options: {
                    legend: {
                      show: true,
                      position: 'bottom'
                    },
                    labels: filteredStatistic.map(statItem => statItem.category.name),
                    tooltip: {
                      enabled: false
                    },
                    dataLabels: {
                      formatter: (val, opts) => {
                        return opts.w.config.series[opts.seriesIndex]
                      }
                    },
                    plotOptions: {
                      pie: {
                        expandOnClick: false,
                        donut: {
                          labels: {
                            show: true,
                            total: {
                              show: true,
                              label: 'Всего'
                            }
                          }
                        }
                      }
                    }
                  }
                })
              }
            })
          }).catch(error => this.toast(error, 'error'))
    }
  }
}
</script>

<style scoped>
.chart-title {
  font-size: 22px;
  font-weight: 600;
  color: #262F3D;
}

@media (max-width: 767px) {
  chart-title {
    font-size: 18px;
  }
}
</style>