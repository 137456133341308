<template>
  <div class="row">
    <div class="col-12 mb-2">
      <div class="custom-card">
        <div class="card-body d-flex justify-content-between align-items-center">
          <div class="task-card-title mb-0 d-flex align-items-center">
            Прикрепленные файлы
            <div class="items-count">{{ files.length }}</div>
          </div>
          <div class="task_component__actions_wrapper">
            <div class="task_component__action">
              <paperclip-icon
                @click="$bvModal.show('add-file')"
                class="chevron task_component__paper_clip" />
            </div>
            <div
              v-if="files.length"
              class="task_component__action">
              <chevron-right-icon
                @click="collapseVisible = !collapseVisible"
                class="chevron"
                :class="{'rotate': collapseVisible}" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <b-collapse
      v-model="collapseVisible"
      class="col-12">
      <div v-for="file in files"
           :key="file.id"
           class="custom-card mb-2 file">
        <div class="card-body">
          <a :href="file.url"
             target="_blank">
            <div class="d-flex align-items-center">
              <image-icon v-if="file.getFileType() === 'image'"
                          class="file-icon" />
              <file-icon v-else-if="file.getFileType() === 'document'"
                         class="file-icon" />
              <help-circle-icon v-else
                                class="file-icon" />
              <div class="file-name">{{file.name}}</div>
            </div>
          </a>
        </div>
      </div>
    </b-collapse>

    <add-files />

  </div>
</template>

<script>
import {mapGetters} from "vuex";
import AddFiles from "@/components/ServiceDesk/Tasks/ActionsForms/AddFiles";

export default {
  name: "TaskFilesList",
  components: {AddFiles},
  data() {
    return {
      collapseVisible: false
    }
  },
  computed: {
    ...mapGetters({
      files: 'files/getFiles',
      task_id: 'task/getTaskId'
    })
  }
}
</script>

<style scoped>
.file-item {
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  margin-bottom: 2px;
}

.file-item:hover .file-name {
  color: #468FC5;
  transition: .3s;
}

.file-icon {
  color: #468FC5;
  margin-right: 5px;
  width: 16px;
  height: 16px;
}

.file-name {
  font-size: 15px;
  color: #495057;
  transition: .3s;
}

.file-date {
  color: #cfcfcf;
}
</style>