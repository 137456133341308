<template>
  <table class="admin-table">
    <thead class="admin-table-header">
      <tr>
        <td>Наименование</td>
        <td>Иконка</td>
        <td>Управление</td>
      </tr>
    </thead>
    <tbody class="admin-table-body">
      <tr
        v-for="type in networkObjectTypes"
        :key="type.id"
        class="network-object-type"
      >
        <td>{{ type.name }}</td>
        <td class="text-center">
          <div class="type-icon"
               :class="type.icon"/>
        </td>
        <td>
          <router-link :to="`/network-object-types-update/${type.id}`">
            <edit-icon class="mr-1"/>
          </router-link>
          <trash2-icon @click="deleteNetworkObjectType(type.id)"
                       class="cursor-pointer"/>
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
import {mapState} from "vuex";
import {showToast, showAlert} from "@/utils/notification";

export default {
  name: "NetworkObjectTypesList",
  computed: {
    ...mapState({
      networkObjectTypes: state => state.networkObjectTypes.networkObjectTypes
    })
  },
  methods: {
    deleteNetworkObjectType(typeId) {
      this.$store.dispatch('networkObjectTypes/deleteNetworkObjectType', typeId)
          .then(() => {
            showToast('Тип объекта сети удалён', 'success');
            this.$store.commit("networkObjectTypes/DELETE_NETWORK_OBJECT_TYPE", typeId);
          })
          .catch(error => showAlert('Ошибка', error))
    }
  }
}
</script>

<style scoped>
.type-icon {
  width: 26px;
  height: 26px;
  display: inline-block;
}

.type-icon:before {
  display: block;
}
</style>