<template>
  <div class="col-md-12">
    <div class="card-title">Роль</div>
    <form @submit.prevent="checkType">
      <div class="form-group">
        <label class="form-label">Наименование</label>
        <input
          v-model="payload.name"
          type="text"
          class="form-control form-control-fix"
          placeholder="Наименование"
          required
        >
      </div>
      <div style="text-align: center">
        <button
          type="submit"
          class="btn custom-btn-blue w-100"
        >
          Сохранить
        </button>
      </div>
    </form>
  </div>
</template>

<script>
import notification from "@/mixins/notification";
import {mapState} from "vuex";

export default {
  name: "RolesForm",
  mixins: [notification],
  data() {
    return {
      payload: {
        name: ''
      },
      error: null,
      type: 'create'
    }
  },
  computed: {
    ...mapState({
      role: state => state.role.role
    })
  },
  mounted() {
    if (this.$route.name === 'RolesUpdate') {
      this.payload.name = this.role.name;
      this.payload.permission_ids = this.role.permissions.map(item => item.id);
      this.type = 'update';
    }
  },
  methods: {
    checkType() {
      if (this.type === 'create') {
        this.createRole();
      } else if (this.type === 'update') {
        this.updateRole();
      }
    },
    createRole() {
      this.$store.dispatch('role/createRole', this.payload)
          .then(() => {
            this.$router.push("/admin-roles");
            this.toast('Роль создана', 'success')
          })
          .catch(error => this.alert('Ошибка', error, 'error'));
    },
    updateRole() {
      this.$store.dispatch('role/updateRole', {
        roleId: this.role.id,
        payload: this.payload
      })
          .then(() => {
            this.$router.push("/admin-roles");
            this.toast('Роль обновлена', 'success')
          })
          .catch(error => this.alert('Ошибка', error, 'error'));
    }
  }
}
</script>

<style scoped>

</style>