<template>
  <div>
    <table class="admin-table">
      <thead class="admin-table-header">
        <tr>
          <td>#</td>
          <td>Наименование</td>
          <td>Группа</td>
          <td>Управление</td>
        </tr>
      </thead>
      <tbody class="admin-table-body">
        <tr v-for="category in categories"
            :key="category.id"
            class="work-item"
        >
          <td>{{ category.id }}</td>
          <td>{{ category.name }}</td>
          <td>{{ category.group.name }}</td>
          <td class="table-action">
            <router-link :to="`/update-category/`+category.id">
              <edit-icon class="mr-1" />
            </router-link>
            <trash2-icon @click="deleteCategory(category.id)"
                         class="cursor-pointer" />
          </td>
        </tr>
      </tbody>
    </table>

    <base-paginate
      v-if="$store.state.serviceDeskCategories.meta && $store.state.serviceDeskCategories.meta.last_page > 1"
      :meta="$store.state.serviceDeskCategories.meta"
      action="serviceDeskCategories/getCategories"
      commit="serviceDeskCategories/SET_PAGE"
    />
  </div>
</template>

<script>
import BasePaginate from "@/components/BasePaginate";
import {mapGetters} from "vuex";
import notification from "@/mixins/notification";
export default {
  name: "CategoriesList",
  components: {BasePaginate},
  mixins: [notification],
  computed: {
    ...mapGetters({
      categories: 'serviceDeskCategories/getCategories'
    })
  },
  methods: {
    deleteCategory(id) {
      this.$store.dispatch('serviceDeskCategories/deleteCategory', id)
          .then(() => {
            this.$store.dispatch('serviceDeskCategories/getCategories',
                this.$store.state.serviceDeskCategories.page);
            this.toast('Категория удалена', 'success');
          })
          .catch(error => this.alert('Ошибка', error, 'error'));
    }
  }
}
</script>

<style scoped>

</style>