import moment from "moment";

export class WorkShift {
    constructor(id, attributes) {
        this.id = id;
        this.start = attributes.start_date;
        this.end = attributes.end_date;
        this.brigade = attributes.brigade;
    }

    getCalendarEvent() {
        return {
            id: this.id,
            title: this.brigade.name,
            start: this.start,
            end: this.end,
            time_start: moment(this.start).format('HH:mm'),
            time_end: moment(this.end).format('HH:mm'),
            classNames: ['calendar-event'],
            display: 'block'
        }
    }
}