<template>
  <div class="row justify-content-center align-items-center fixed-height">
    <div class="col-4">
      <mechanical-equipment-form />
    </div>
  </div>
</template>

<script>
import MechanicalEquipmentForm from "@/components/ServiceDesk/admin/MechanicalEquipmentForm";
export default {
  name: "MechanicalEquipmentFormPage",
  components: {MechanicalEquipmentForm}
}
</script>

<style scoped>

</style>