<template>
  <table class="admin-table">
    <thead class="admin-table-header">
      <tr>
        <td>ID</td>
        <td>Наименование</td>
        <td>Управление</td>
      </tr>
    </thead>
    <tbody class="admin-table-body">
      <tr
        v-for="type in networkEquipmentTypes"
        :key="type.id"
        class="network-equipment-type"
      >
        <td>{{ type.id }}</td>
        <td>{{ type.name }}</td>
        <td>
          <trash2-icon @click="deleteNetworkEquipmentType(type.id)"
                       class="cursor-pointer"/>
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
import {mapState} from "vuex";
import {showAlert, showToast} from "@/utils/notification";

export default {
  name: "NetworkEquipmentTypesList",
  computed: {
    ...mapState({
      networkEquipmentTypes: state => state.networkEquipmentTypes.networkEquipmentTypes
    })
  },
  methods: {
    deleteNetworkEquipmentType(id) {
      this.$store.dispatch('networkEquipmentTypes/deleteNetworkEquipmentType', id)
          .then(() => {
            this.$store.commit('networkEquipmentTypes/DELETE_NETWORK_EQUIPMENT_TYPE', id);
            showToast('Тип сетевого оборудования удалён', 'success')
          })
          .catch(error => showAlert('Ошибка', error))
    }
  }
}
</script>

<style scoped>

</style>