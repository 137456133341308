<template>
  <b-tab
    title="Абонент"
    title-item-class="custom-nav-item"
    title-link-class="custom-nav-link"
  >
    <h4 class="billing-tab-title mb-20">
      Информация по абоненту
    </h4>

    <div
      v-if="this.$store.state.client.client === null"
      class="row"
    >
      <div class="col-12">
        <div class="no-result-title">
          Нет данных.
        </div>
        <div class="no-result-text">
          {{ title }}
        </div>
      </div>
    </div>

    <div
      v-else
      class="row client-detail"
    >
      <div class="col-12">
        <div class="field-name">
          Тип абонента:
        </div>
        <div class="field-value">
          {{ clientType }}
        </div>
      </div>
      <div class="col-12">
        <div class="field-name">
          ФИО / Наименование:
        </div>
        <div class="field-value">
          {{ clientName }}
        </div>
      </div>
      <div class="col-12">
        <div class="field-name">
          Адрес абонента:
        </div>
        <div class="field-value">
          {{ clientAddress }}
        </div>
      </div>
      <!--      <div
        v-if="this.clientType === 'Юридическое лицо'"
        class="col-12"
      >
        <div class="field-name">
          Наименование объекта:
        </div>
        <div class="field-value">
          {{ clientObjectName }}
        </div>
      </div>-->
      <div class="col-12">
        <div class="field-name">
          Номер договора:
        </div>
        <div class="field-value">
          {{ clientLS }}
        </div>
      </div>
      <div class="col-12">
        <div class="field-name">
          Текущий статус договора:
        </div>
        <div class="field-value">
          {{ clientLsStatus }}
        </div>
      </div>
      <div
        v-if="clientReadings.length !== 0"
        class="col-12"
      >
        <div
          v-b-toggle.readings
          class="field-name"
        >
          <img
            id="readings_icon"
            alt="Раскрыть начисления"
            class="collapse-arrow"
            src="@/assets/img/billing/arrow-right.svg"
          >
          Начисления за имеющиеся периоды
        </div>
        <b-collapse
          id="readings"
          class="building-collapse"
        >
          <div class="row">
            <div class="col-12 mb-2">

              <div class="view-switcher">
                <div @click="readingsView = 'list'"
                     :class="{active: readingsView === 'list'}">
                  <list-icon />
                </div>
                <div @click="readingsView = 'table'"
                     :class="{active: readingsView === 'table'}">
                  <grid-icon />
                </div>
                <div @click="readingsView = 'table-years'"
                     :class="{active: readingsView === 'table-years'}">
                  <divide-icon />
                </div>
                <div v-b-modal.reading-modal>
                  <bar-chart-icon />
                </div>
              </div>
            </div>

            <b-modal
              id="reading-modal"
              size="xl"
              hide-footer
              hide-header
              ok-only
              @shown="generateChartData()"
            >
              <div class="col-12">
                <div class="custom-modal-header">
                  <div class="custom-modal-title"/>
                  <img
                    class="custom-modal-close"
                    src="@/assets/img/icons/close.svg"
                    @click="$bvModal.hide('reading-modal')"
                  >
                </div>
                <div class="modal-divider"/>
              </div>
              <apexchart
                :options="chartOptions"
                :series="chartData"
                height="350"
                type="bar"
              />
            </b-modal>

            <div class="col-12">
              <div v-if="readingsView === 'list'"
                   class="row">
                <div
                  v-for="reading in clientReadings"
                  :key="reading.id"
                  class="col-4 mb-3 reading-item"
                >
                  <div class="field-name">
                    {{ setMonth(reading.period) }}
                  </div>
                  <div class="field-value">
                    Хол. вода {{ reading.hw }}м<sup>3</sup>
                  </div>
                  <div class="field-value">
                    Стоки хол. воды {{ reading.shw }}м<sup>3</sup>
                  </div>
                  <div class="field-value">
                    Стоки гор. воды {{ reading.sgw }}м<sup>3</sup>
                  </div>
                </div>
              </div>
            </div>

            <div v-if="readingsView === 'table'"
                 class="col-12">
              <table class="admin-table">
                <thead class="admin-table-header">
                  <tr>
                    <td>Период</td>
                    <td>ХВС, м<sup>3</sup></td>
                    <td>Стоки, м<sup>3</sup></td>
                  </tr>
                </thead>
                <tbody class="admin-table-body">
                  <tr v-for="reading in clientReadings"
                      :key="reading.id">
                    <td>{{setMonth(reading.period)}}</td>
                    <td>{{ reading.hw }}</td>
                    <td>{{ reading.shw + reading.sgw}}</td>
                  </tr>
                </tbody>
              </table>
            </div>

            <div v-if="readingsView === 'table-years'"
                 class="col-12">

              <table class="admin-table">
                <thead class="admin-table-header">
                  <tr>
                    <td rowspan="2">Месяц</td>
                    <td :colspan="getTableYears(clientReadings).size">
                      <span v-if="tableYearsDrain">Стоки, м<sup>3</sup></span>
                      <span v-else>ХВС, м<sup>3</sup></span>
                      <repeat-icon class="float-right cursor-pointer mt-2p"
                                   @click="tableYearsDrain = !tableYearsDrain" />
                    </td>
                  </tr>
                  <tr class="table-header-second-row">
                    <td v-for="year in getTableYears(clientReadings)"
                        :key="year">
                      {{year}}
                    </td>
                  </tr>
                </thead>
                <tbody class="admin-table-body">
                  <tr v-for="reading_group in getSortedReadingsForTable(clientReadings)"
                      :key="reading_group.month">
                    <td>{{reading_group.month}}</td>
                    <td v-for="(reading, index) in reading_group.readings"
                        :key="index">
                      {{reading}}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

          </div>
        </b-collapse>
      </div>
      <div
        v-if="clientMeters.length !== 0"
        class="col-12"
      >
        <div
          v-b-toggle.meters
          class="field-name"
        >
          <img
            id="meters_icon"
            alt="Раскрыть приборы учёта"
            class="collapse-arrow"
            src="@/assets/img/billing/arrow-right.svg"
          >
          Установленные приборы учета (ПУ) с сопутствующей
          информацией
        </div>
        <b-collapse
          id="meters"
          class="building-collapse"
        >
          <div
            v-for="meter in clientMeters"
            :key="meter.id"
            class="col-12 p-2"
          >
            <div class="row">
              <div class="col-12">
                <div class="field-name">
                  Тип ПУ:
                </div>
                <div class="field-value">
                  {{ meter.attributes.type | nullFilter }}
                </div>
              </div>
              <div class="col-12">
                <div class="field-name">
                  Дата установки:
                </div>
                <div class="field-value">
                  {{ meter.attributes.install_date | nullFilter }}
                </div>
              </div>
              <div class="col-12">
                <div class="field-name">
                  Дата проверки:
                </div>
                <div class="field-value">
                  {{ meter.attributes.check_date | nullFilter }}
                </div>
              </div>
              <div class="col-12">
                <div class="field-name">
                  Номер ПУ:
                </div>
                <div class="field-value">
                  {{ meter.attributes.number | nullFilter }}
                </div>
              </div>
            </div>
          </div>
        </b-collapse>
      </div>
      <div class="col-12">
        <div class="field-name">
          Сальдо текущее:
        </div>
        <div class="field-value">
          {{ clientBalance }}
        </div>
      </div>
    </div>
  </b-tab>
</template>

<script>
import {mapGetters} from "vuex";
import VueApexCharts from "vue-apexcharts";

export default {
  name: "BillingClientDetail",
  components: {'apexchart': VueApexCharts},
  filters: {
    nullFilter: function (value) {
      if (value === null) {
        return 'данные отсутствуют'
      } else {
        return value
      }
    }
  },
  props: {
    title: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      chartOptions: null,
      chartData: [],
      readingsView: 'list',
      tableYearsDrain: false
    }
  },
  computed: {
    ...mapGetters({
      clientType: "client/getClientType",
      clientName: "client/getClientName",
      clientAddress: "client/getClientAddress",
      clientObjectName: "client/getClientObjectName",
      clientLS: "client/getClientLS",
      clientLsStatus: "client/getClientLsStatus",
      clientReadings: "client/getClientReadings",
      clientMeters: "client/getClientMeters",
      clientBalance: "client/getClientBalance"
    }),
    setMonth: function () {
      return period => {
        return this.$moment(period).format('MMMM-Y')
      }
    },
    getTableYears: function () {
      return readings => {
        const periods = readings.concat().reverse().map(reading => new Date(reading.period).getFullYear());
        return new Set(periods);
      }
    },
    getSortedReadingsForTable: function () {
      return readings => {
        const months = this.$moment.months()
        const table_readings = months.map(month => {
          return {
            month: month,
            readings: []
          }
        })

        this.getTableYears(readings).forEach(year => {

          table_readings.forEach(item => {
            let current_reading = readings.find(reading => {
              let reading_month = this.$moment(reading.period).format('MMMM');
              let reading_year = this.$moment(reading.period).format('YYYY');

              if (reading_month === item.month && parseInt(reading_year) === year) {
                return reading
              }
            })

            if (current_reading !== undefined) {
              if (this.tableYearsDrain) {
                item.readings.push(current_reading.sgw + current_reading.shw);
              } else {
                item.readings.push(current_reading.hw);
              }
            } else {
              item.readings.push('-')
            }
          })
        })
        return table_readings
      }
    }
  },
  mounted() {
    this.$root.$on("bv::collapse::state", (collapseID, opened) => {
      let arrow_element = document.getElementById(collapseID + '_icon')
      if (opened) arrow_element.style.transform = 'rotateZ(90deg)'
      else arrow_element.style.transform = 'rotate(0deg)'
    })
  },
  methods: {
    generateChartData() {
      let ru = require('apexcharts/dist/locales/ru.json')
      let months = []

      this.$store.getters["client/getClientReadings"].forEach(el => {
        months.push(this.$moment(el.period).format('MMMM-Y'))
      })

      this.chartOptions = {
        xaxis: {
          categories: [].concat(months).reverse()
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: '80%',
            endingShape: 'rounded',
            borderRadius: 5
          }
        },
        fill: {
          opacity: 1,
          colors: ['#0A6187', '#f0ad4e']
        },
        legend: {
          show: true,
          markers: {
            fillColors: ['#0A6187', '#f0ad4e']
          }
        },
        dataLabels: {
          enabled: false
        },
        tooltip: {
          y: {
            formatter: function (val) {
              return val + " м<sup>3</sup>"
            }
          }
        },
        chart: {
          locales: [ru],
          defaultLocale: 'ru'
        }
      }

      let hw = []
      let drains = []

      let readings = [].concat(this.$store.getters["client/getClientReadings"]).reverse()
      readings.forEach(el => {
        hw.push(el.hw)
        drains.push(el.shw + el.sgw)
      })
      this.chartData = [
        {
          name: `ХВС, м<sup>3</sup>`,
          data: hw
        },
        {
          name: `Стоки, м<sup>3</sup>`,
          data: drains
        }]
    }
  }
}
</script>

<style scoped>
.client-detail > .col-12 {
  margin-bottom: 3px;
}

.field-name {
  display: inline-block;
  color: #97ACB5;
  font-size: 16px;
  font-weight: 700;
  margin-right: 10px;
}

.field-value {
  display: inline-block;
  color: #468FC5;
  font-size: 16px;
  font-weight: 700;
}

.building-collapse {
  background-color: white;
  border-radius: 10px;
  padding: 10px;
}

.show-chart-button {
  color: #468FC5;
  font-size: 16px;
  font-weight: 700;
  transition: .3s;
  margin-bottom: 10px;
}

.show-chart-button:hover {
  transition: .3s;
  text-decoration: underline;
}

.collapse-arrow {
  margin-right: 6px;
  transition: 0.2s;
}

.view-switcher {
  display: inline-block;
  float: right;
}

.view-switcher div {
  display: inline-block;
  background-color: #97ACB5;
  padding: 2px 3px;
  cursor: pointer;
}

.view-switcher div:first-child {
  border-radius: 3px 0 0 3px;
}

.view-switcher div:last-child {
  border-radius: 0 3px 3px 0;
}

.view-switcher div svg {
  color: white;
}

.view-switcher div.active {
  background-color: #468FC5;
}

.table-header-second-row td:first-child {
  border-radius: 0;
  border-left: none;
}

.table-header-second-row td:last-child {
  border-radius: 0;
}

.mt-2p {
  margin-top: 2px;
}
</style>