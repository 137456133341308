<template>
  <div v-if="reportData.length"
       class="custom-card">
    <div class="card-body">
      <div class="col-12">
        <div class="row">
          <div class="col-12">
            <button @click="saveExcel()"
                    class="btn custom-btn-blue-outline-xs float-right">Сохранить в excel
            </button>
          </div>
        </div>
        <div v-for="categoryGroup in categoryGroups"
             :key="categoryGroup.id"
             class="mb-3">
          <div v-if="issuesByCategoryGroup(categoryGroup.id).length">
            <div class="type-title mb-2">
              {{ categoryGroup.name }}
            </div>

            <div v-for="type in issueTypes"
                 :key="type.id">
              <div v-if="issuesByCategoryGroup(categoryGroup.id).filter(item => {
                return item.type_id ? item.type_id === type.id : false
              }).length">
                <div class="group-title mb-2">
                  {{ type.name }}
                </div>

                <daily-report-table
                  :issues="issuesByCategoryGroup(type.id).filter(item => {
                    return item.type_id ? item.type_id === type.id : false
                  })"/>
              </div>
            </div>

            <div v-if="issuesWithoutCategory.length">
              <div class="type-title mb-2">
                Другое
              </div>
              <div v-for="type in issueTypes"
                   :key="type.id">
                <div v-if="issuesWithoutCategory.filter(item => {
                  return item.type_id ? item.type_id === type.id : false
                }).length">
                  <div class="group-title mb-2">
                    {{ type.name }}
                  </div>

                  <daily-report-table
                    :issues="issuesWithoutCategory.filter(item => {
                      return item.type_id ? item.type_id === type.id : false
                    })"/>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters, mapState} from "vuex";
import DailyReportTable from "@/components/ServiceDesk/ReportsPreviews/DailyReportTable";
import DailyReportExcelService from "@/services/excelReports/DailyReportExcelService";

export default {
  name: "DailyReport",
  components: {DailyReportTable},
  computed: {
    ...mapState({
      categoryGroups: state => state.serviceDeskCategories.categoryGroups,
      issueTypes: state => state.issueTypes.types,
      reportData: state => state.reportsData.reportData
    }),
    ...mapGetters({
      issuesByCategoryGroup: 'reportsData/getIssuesByCategoryGroup',
      issuesWithoutCategory: 'reportsData/getIssuesWithoutCategory'
    })
  },
  mounted() {
    this.$store.dispatch('serviceDeskCategories/getCategoryGroups');
    this.$store.dispatch('issueTypes/getIssueTypes');
  },
  methods: {
    saveExcel() {
      DailyReportExcelService.excelExport();
    }
  }
}
</script>

<style scoped>
.type-title {
  text-align: center;
  font-size: 24px;
  color: #468FC5;
  font-weight: 700;
}

.group-title {
  text-align: center;
  font-size: 22px;
  color: #468FC5;
  font-weight: 600;
}
</style>