import * as API from "@/services/API";

export default {
    getClients(payload) {
        return API.apiClient.get(`/billing/consumers?page=${payload.page}`, {params: payload.params});
    },
    paginateClients(payload) {
        return API.apiClient.get(`/billing/consumers?page=${payload.page}`, payload.data);
    },
    getBuildings(payload) {
        return API.apiClient.get(`/billing/objects?page=${payload.page}`, {params: payload.params});
    },
    paginateBuildings(payload) {
        return API.apiClient.get(`/billing/objects?page=${payload.page}`, payload.data);
    },
    getClientDetail(id) {
        return API.apiClient.get(`/billing/consumers/${id}`);
    },
    getBuildingDetail(id) {
        return API.apiClient.get(`/billing/objects/${id}`);
    },
    getBuildingByCoords(payload) {
        return API.apiClient.get('/billing/map/info', payload);
    },
    async getStandardBuildingsV1(payload) {
        let response = await API.apiClient.get(`/billing/reference-objects/by-people-count?page=${payload.page}`,
            {params: payload.filter});
        return response.data;
    },
    async getStandardBuildingsV2(payload) {
        let response = await API.apiClient.get(`billing/reference-objects/by-leaving-area?page=${payload.page}`,
            {params: payload.filter});
        return response.data;
    }
}