<template>
  <div class="custom-card">
    <div class="card-body">
      <div class="work-shifts-count">
        Отработано смен: <span>{{resourceStatistic.count_work_shifts}}</span>
      </div>
    </div>
  </div>
</template>

<script>
import {mapState} from "vuex";

export default {
  name: "ResourceWorkshiftsCount",
  computed: {
    ...mapState({
      resourceStatistic: state => state.resourceStatistic.resourceStatistic
    })
  }
}
</script>

<style scoped>
.work-shifts-count {
  font-size: 20px;
  color: #262F3D;
  font-weight: 600;
}

.work-shifts-count span {
  color: #468FC5;
  font-size: 24px;
}
</style>