import {getError} from "@/utils/helpers";
import AutosService from "@/services/AutosService";
import moment from "moment";

export const namespaced = true;

export const state = {
    transportData: null,
    loading: false,
    error: null
};

export const mutations = {
    SET_DATA(state, data) {
        state.transportData = data;
    },
    SET_LOADING(state, loading) {
        state.loading = loading;
    },
    SET_ERROR(state, error) {
        state.error = error;
    }
};

export const actions = {
    transportData({commit}) {
        commit("SET_LOADING", true);
        AutosService.searchTransportDataByIds()
            .then(response => {
                commit("SET_DATA", response.data.data);
                commit("SET_LOADING", false);
            })
            .catch(error => {
                commit("SET_LOADING", false);
                commit("SET_ERROR", getError(error));
            });
    }
};

export const getters = {
    complete: state => {
        return state.transportData != null;
    },
    getLat: state => external_id => {
        if(state.transportData != null){
            let s = state.transportData.find(d => d.id == external_id);
            if(s != null){
                return s.attributes.lat;
            }else{
                return 0;
            }
        }else{
            return 0;
        }
    },
    getLon: state => external_id => {
        if(state.transportData != null){
            let s = state.transportData.find(d => d.id == external_id);
            if(s != null){
                return s.attributes.lon;
            }else{
                return 0;
            }
        }else{
            return 0;
        }
    },
    getSpeed: state => external_id => {
        if(state.transportData != null){
            let s = state.transportData.find(w => w.id == external_id);
            if(s != null){
                return s.attributes.speed;
            }else{
                return '-';
            }
        }else{
            return '-';
        }
    },
    getStreet: state => external_id => {
        if(state.transportData != null){
            let s = state.transportData.find(d => d.id == external_id);
            if(s != null){
                return s.attributes.address;
            }else{
                return '-';
            }
        }else{
            return '-';
        }
    },
    getSatellite: state => external_id => {
        if(state.transportData != null){
            let s = state.transportData.find(d => d.id == external_id);
            if(s != null){
                return s.attributes.satellite;
            }else{
                return 0;
            }
        }else{
            return 0;
        }
    },
    getShortStreet: state => external_id => {
        if (state.transportData != null) {
            let s = state.transportData.find(d => d.id == external_id);
            if(s != null){
                return s.attributes.address;
            }else{
                return '-';
            }
        } else {
            return '-';
        }
    },
    getEngineStatus: state => external_id => {
        if(state.transportData != null){
            let s = state.transportData.find(d => d.id == external_id);
            if(s != null){
                return s.attributes.engine;
            }else{
                return false;
            }
        }else{
            return false;
        }
    },
    getTimestamp: state => external_id => {
        if(state.transportData != null){
            let s = state.transportData.find(d => d.id == external_id);
            if(s != null){
                return moment(s.attributes.date).format("HH:mm");
            }else{
                return '-';
            }
        }else{
            return '-';
        }
    },
    getSortList: state => {
        if (state.transportData != null) {
            return state.transportData.map(function (d){
                return {id: d.id, speed: d.attributes.speed}
            }).sort((a,b) => b.speed - a.speed);
        } else {
            return [];
        }
    },
    loading: state => {
        return state.loading;
    },
    error: state => {
        return state.error;
    }
}