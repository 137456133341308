<template>
  <div class="custom-card">
    <div class="card-header">
      <h1 class="card-title mb-0 claim-list-title d-inline-block">
        Создать заявку
      </h1>
    </div>
    <div class="card-body">
      <div class="container-fluid">
        <div class="row">
          <div class="col-12">
            <form @submit.prevent="submitForm">
              <div class="form-group">
                <label class="form-label">Тип заявки<span>*</span></label>
                <v-select
                  v-model="$v.payload.type.$model"
                  :options="issueTypes"
                  :reduce="type => type.id"
                  :get-option-label="(option) => option.name"
                  placeholder="Выберите тип заявки"
                  :class="{'is-invalid': ($v.payload.type.$dirty && !$v.payload.type.required)}"
                />
                <div class="error small form-text invalid-feedback">
                  Поле обязательно для заполнения
                </div>
              </div>

              <problem-category-selector
                @update:category_id="event => payload.category_id = event"
                @update:group_id="groupId => group_id = groupId"
                :category_id="payload.category_id"
                :group="group_id"
              />

              <div class="form-group">
                <label class="form-label">Адрес</label>
                <dadata-suggestions
                  class="form-control form-control-fix"
                  type="ADDRESS"
                  @change="setAddress"
                  @input="handelAddressInput"
                  placeholder="Начните вводить адрес"
                  v-model="address"
                />
              </div>
              <div class="form-group">
                <label class="form-label">Основание заявки<span>*</span></label>
                <input type="text"
                       class="form-control"
                       :class="{'is-invalid': ($v.payload.based_on.$dirty && !$v.payload.based_on.required)}"
                       placeholder="Введите основание заявки"
                       v-model="$v.payload.based_on.$model">
                <div class="error small form-text invalid-feedback">
                  Поле обязательно для заполнения
                </div>
              </div>
              <div class="form-group">
                <label class="form-label">Подразделение</label>
                <v-select
                  v-model="payload.department_id"
                  :options="departments"
                  :reduce="department => department.id"
                  :get-option-label="(option) => option.name"
                  placeholder="Выберите подразделение"
                />
              </div>
              <div class="form-group">
                <label class="form-label">Комментарий</label>
                <textarea
                  v-model="payload.description"
                  class="form-control"
                  maxlength="1500"
                  placeholder="Введите комментарий"
                />
              </div>
              <div class="form-group">
                <label class="form-label">Связанная заявка</label>
                <v-select
                  v-model="payload.parent_issue_id"
                  :options="issues"
                  :reduce="issue => issue.id"
                  :get-option-label="(option) => '#' + option.id"
                  placeholder="Выберите заявку"
                  :filter="(option, search) => {
                    return option.filter(option => {
                      return option.id.toString().includes(search)
                    })
                  }">
                  <template #option="{id, created_at}">
                    Заявка <span class="font-weight-bold">#{{id}}</span> от {{created_at | formatDateTime}}
                  </template>
                </v-select>
              </div>
              <div class="form-group">
                <label class="form-label">
                  Кол-во прикреплённых обращений: {{ appealsForLink.length }}
                </label>
              </div>
              <p v-if="$v.payload.address_lat.$dirty && !$v.payload.address_lat.required"
                 class="form-error">
                Не указаны координаты, поставьте метку на карте
              </p>
              <button type="submit"
                      class="btn custom-btn-blue w-100">
                Создать заявку
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import DadataSuggestions from "vue-dadata-suggestions";
import notification from "@/mixins/notification";
import {mapGetters} from "vuex";
import { validationMixin } from 'vuelidate'
import { required } from 'vuelidate/lib/validators'
import IssueState from "@/models/serviceDesk/IssueState";
import ProblemCategorySelector from "@/components/ServiceDesk/ProblemCategorySelector";

Vue.use(DadataSuggestions, {
  token: process.env.VUE_APP_DADATA_API_KEY,
  geoLocation: [{
    "kladr_id": process.env.VUE_APP_DADATA_KLADR_ID
  }]
});

export default {
  name: "CreateIssueForm",
  components: {ProblemCategorySelector},
  mixins: [notification, validationMixin],
  data() {
    return {
      payload: {
        address_region: null,
        address_city: null,
        address_settlement: null,
        address_street: null,
        address_house: null,
        address_block: null,
        address_flat: null,
        address_lat: null,
        address_lon: null,
        based_on: null,
        appeals_array: [],
        type: null,
        department_id: null,
        description: null,
        category_id: null,
        parent_issue_id: null
      },
      address: null,
      group_id: null,
      autoFillAddress: true
    }
  },
  validations: {
    payload: {
      type: {required},
      based_on: {required},
      address_lat: {required},
      address_lon: {required}
    }
  },
  computed: {
    ...mapGetters({
      appealsForLink: 'appeals/getAppealsForLink',
      getAppeal: 'appeals/getAppealById',
      issueTypes: 'issueTypes/getIssueTypes',
      departments: 'departments/getDepartments',
      categories: 'serviceDeskCategories/getCategories',
      issues: 'issues/getAllIssues'
    })
  },
  watch: {
    'payload.address_lat': function () {
      this.getRelatedIssuesList();
    },
    'group_id': function () {
      this.getRelatedIssuesList();
    },
    'payload.parent_issue_id': function (id) {
      this.payload.based_on = `Заявка #${id}`
    },
    appealsForLink: function (appeals) {
      this.payload.appeals_array = appeals;

      if (this.autoFillAddress) {
        if (appeals.length) {
          const [appeal_id] = appeals;
          const appeal = this.getAppeal(appeal_id);

          this.address = appeal.getFullAddress();
          this.group_id = appeal.category.group_id;
          this.payload.category_id = appeal.category.id;

          this.payload.address_region = appeal.address.region;
          this.payload.address_city = appeal.address.city;
          this.payload.address_settlement = appeal.address.settlement;
          this.payload.address_street = appeal.address.street;
          this.payload.address_house = appeal.address.house;
          this.payload.address_block = appeal.address.block;
          this.payload.address_flat = appeal.address.flat;
          const [lat, lon] = appeal.coordinates;
          this.payload.address_lat = lat;
          this.payload.address_lon = lon;

          this.payload.based_on = 'Обращение';
          this.payload.description = appeal.message;

        }
        if (!appeals.length) {
          this.address = null;
          this.payload.address_region = null;
          this.payload.address_region = null
          this.payload.address_city = null
          this.payload.address_settlement = null
          this.payload.address_street = null
          this.payload.address_house = null
          this.payload.address_block = null
          this.payload.address_flat = null
          this.payload.address_lat = null
          this.payload.address_lon = null
          this.payload.based_on = null
          this.payload.appeals_array = []
          this.payload.category_id = null
        }
      }
    }
  },
  mounted() {
    this.$store.dispatch('issues/getAllIssues', [
        IssueState.NEW,
        IssueState.APPOINTED,
        IssueState.ACCEPTED,
        IssueState.IN_WORK,
        IssueState.POSTPONED
    ].join())
    this.$root.$on('BaseMap::map-click', coords => {
      this.payload.address_lat = coords.lat;
      this.payload.address_lon = coords.lng;
    })

    if (this.$route.name === 'CreateRelatedIssue') {
      const relatedIssueId = this.$route.params.id;
      this.payload.parent_issue_id = Number.parseInt(relatedIssueId);
      this.payload.based_on = `Заявка #${relatedIssueId}`
    }
  },
  beforeDestroy() {
    this.$store.commit('appeals/SET_APPEALS_FOR_LINK', []);
  },
  methods: {
    handelAddressInput(value) {
      this.autoFillAddress = !value;
    },
    submitForm() {
      if (this.$v.$invalid) {
        this.$v.$touch();
      } else {
        this.$v.$reset();
        this.$store.dispatch('issues/createIssue', this.payload)
            .then(() => {
              this.$store.commit('appeals/SET_APPEALS_FOR_LINK', []);
              this.$router.push('/issues-list');
              this.toast('Заявка создана', 'success');
            })
            .catch(() => {
              this.alert('Ошибка', this.$store.state.issues.error, 'error');
            })
      }
    },
    setAddress(i) {
      this.payload.address_region = i.data.region;
      this.payload.address_city = i.data.city;
      this.payload.address_settlement = i.data.settlement_with_type;
      this.payload.address_street = i.data.street_with_type;
      this.payload.address_house = i.data.house;
      this.payload.address_block = i.data.block;
      this.payload.address_flat = i.data.flat;

      if (!this.payload.address_lat && !this.payload.address_lon) {
        this.payload.address_lat = parseFloat(i.data.geo_lat);
        this.payload.address_lon = parseFloat(i.data.geo_lon);

        this.$root.$emit('fit-bounds-map', {
          coords: [parseFloat(i.data.geo_lat), parseFloat(i.data.geo_lon)],
          qc_geo: i.data.qc_geo
        })
      }
    },
    getRelatedIssuesList() {
      if (this.payload.address_region && this.group_id) {
        this.$store.commit('issues/SET_FILTER_PARAMS', {
          position_lat: this.payload.address_lat,
          position_lon: this.payload.address_lon,
          position_radius: 100,
          category_group_id: this.group_id,
          issue_states: [
            IssueState.NEW,
            IssueState.APPOINTED,
            IssueState.ACCEPTED,
            IssueState.IN_WORK,
            IssueState.REJECTED,
            IssueState.POSTPONED
          ].join()
        })
        this.$store.dispatch('issues/getIssues', 1);
      }
      if (!this.payload.address_lat) this.$store.commit('issues/SET_ISSUES', []);
    }
  }
}
</script>

<style scoped>

</style>