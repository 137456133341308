<template>
  <div class="col-md-12">
    <form @submit.prevent="checkType">
      <div class="form-group">
        <label class="form-label">Наименование</label>
        <input
          v-model="name"
          type="text"
          class="form-control"
          placeholder="Наименование"
          required
        >
      </div>
      <div class="form-group">
        <label class="form-label">Sys_id</label>
        <input
          v-model="sys_id"
          type="text"
          class="form-control"
          placeholder="Zulu sys"
          required
        >
      </div>
      <div class="form-group">
        <label class="form-label">Тип зоны</label>
        <select
          v-model="zone_type"
          class="form-control mb-3"
          required
        >
          <option value="1">
            Водоснабжение
          </option>
          <option value="2">
            Водоотведение
          </option>
        </select>
      </div>
      <div class="form-group">
        <div class="custom-control custom-switch">
          <input
            id="customSwitch2"
            v-model="active"
            type="checkbox"
            class="custom-control-input"
          >
          <label
            v-if="this.active"
            class="custom-control-label"
            for="customSwitch2"
          >Активна</label>
          <label
            v-else
            class="custom-control-label"
            for="customSwitch2"
          >Не активна</label>
        </div>
      </div>
      <div style="text-align: center">
        <button
          type="submit"
          class="btn btn-primary"
        >
          Сохранить
        </button>
      </div>
    </form>
  </div>
</template>

<script>
import ZonesService from "@/services/ZonesService";
import {getError} from "@/utils/helpers";

export default {
  name: "ZonesForm",
  data() {
    return {
      zone_id: null,
      name: '',
      sys_id: '',
      error: null,
      type: 'create',
      relatedDevice: '',
      zone_type: null,
      active: null
    }
  },
  mounted() {
    if (this.$route.name == "ZonesUpdate") {
      let zone = this.$store.getters['zone/getZone'](this.$route.params.id);
      this.zone_id = this.$route.params.id;
      this.name = zone.attributes.name;
      this.sys_id = zone.attributes.sys_id;
      this.zone_type = zone.attributes.type;
      this.active = zone.attributes.active;
      this.type = 'update';
    }
  },
  methods: {
    checkType() {
      if (this.type === 'create') {
        this.createZone();
      } else if (this.type === 'update') {
        this.updateZone();
      }
    },
    createZone() {
      const payload = {
        name: this.name,
        sys_id: this.sys_id,
        type: this.zone_type,
        active: this.active
      }
      this.error = null
      ZonesService.createZone(payload)
          .then(() => this.$router.push('/admin-zones'))
          .catch(error => {
            this.error = getError(error);
            this.$swal('Некорректные данные', this.error, 'error')
          });
    },
    updateZone() {
      const payload = {
          name: this.name,
          sys_id: this.sys_id,
          type: this.zone_type,
          active: this.active
      }
      this.error = null
      ZonesService.updateZone(this.zone_id, payload)
          .then(() => this.$router.push("/admin-zones"))
          .catch(error => {
            this.error = getError(error);
            this.$swal('Некорректные данные', this.error, 'error')
          });
    }
  }
}
</script>

<style scoped>

</style>