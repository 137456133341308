import * as API from './API'
import {getError} from "@/utils/helpers";

export default {
    fetchClosedIssuesByType(filter) {
        return new Promise((resolve, reject) => {
            API.apiClient.get(`service-desk/statistic/issues/completed-by-types`, {params: filter})
                .then(response => resolve(response.data.data))
                .catch(error => reject(getError(error)))
        })
    },
    fetchIssuesCountByGroups(filter) {
        return new Promise((resolve, reject) => {
            API.apiClient.get(`/service-desk/statistic/issues/opened-by-group`, {params: filter})
                .then(response => resolve(response.data.data))
                .catch(error => reject(getError(error)));
        })
    }
}
