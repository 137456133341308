import Vue from "vue";
import moment from "moment";

Vue.filter('formatDateTime', function (date) {
    if (date) return moment(date).format(process.env.VUE_APP_BASE_DATE_TIME_FORMAT);
    else return '-'
})

Vue.filter('formatDate', function (date) {
    if (date) return moment(date).format('DD.MM.YYYY');
    else return '-'
})