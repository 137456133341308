import EmergencyElemenstService from "@/services/EmergencyElemenstService";

export const namespaced = true;

export const state = {
    emergencyElements: [],
    loading: false,
    error: null
}

export const mutations = {
    SET_EMERGENCY_ELEMENTS(state, elements) {
        state.emergencyElements = elements;
    },
    SET_LOADING(state, loading) {
        state.loading = loading;
    },
    SET_ERROR(state, error) {
        state.error = error;
    }
}

export const actions = {
    getEmergencyElements({commit}, issue_id) {
        EmergencyElemenstService.getEmergencyElements(issue_id)
            .then(emergencyElements => commit("SET_EMERGENCY_ELEMENTS", emergencyElements))
            .catch(error => commit("SET_ERROR", error))
            .finally(() => commit("SET_LOADING", false));
    },
    createEmergencyElement({commit}, {issue_id, payload}) {
        return new Promise((resolve, reject) => {
            EmergencyElemenstService.createEmergencyElement({issue_id, payload})
                .then(() => resolve())
                .catch(error => {
                    reject(error);
                    commit("SET_ERROR", error);
                })
        })
    },
    updateEmergencyElement({commit}, {issue_id, element_id, payload}) {
        return new Promise((resolve, reject) => {
            EmergencyElemenstService.updateEmergencyElement({issue_id, element_id, payload})
                .then(() => resolve())
                .catch(error => {
                    reject(error);
                    commit("SET_ERROR", error);
                })
        })
    }
}

export const getters = {
    getEmergencyElements: state => {
        return state.emergencyElements;
    }
}