<template>
  <b-tabs id="logbook_tabs">
    <b-tab
      v-for="type in networkObjects
        .map(item => {return {id: item.typeId, name: item.typeName}})
        .filter((v,i,a)=>a.findIndex(v2=>(v2.id===v.id))===i)"
      :key="type.id"
      :title="type.name"
      title-item-class="custom-nav-link"
    >
      <div class="row m-0">
        <div class="col-12">
          <div class="logbook__table_wrapper mb-2">
            <table class="table table-responsive mb-0">
              <thead>
                <tr>
                  <th class="w-40">
                    Объект
                  </th>
                  <th class="w-40">
                    Оборудование
                  </th>
                  <th class="w-40">
                    Параметр
                  </th>
                  <th
                    v-for="index in 24"
                    :key="`${index}_head_hours`"
                  >
                    {{ index - 1 + ':00' + '-' + index + ':00' }}
                  </th>
                </tr>
              </thead>
              <tbody>
                <template v-for="networkObject in networkObjects.filter(item => item.typeId === type.id)">
                  <template v-for="(equipment, equipmentKey) in networkObject.equipments">
                    <tr
                      v-for="(parameter, parameterKey) in equipment.parameters"
                      :key="parameter.id"
                    >
                      <td
                        v-if="equipmentKey === 0 && parameterKey === 0"
                        :rowspan="networkObject.equipments
                          .reduce((sum, currentEquip) => sum + currentEquip.parameters.length, 0)"
                      >
                        {{ networkObject.name }}
                      </td>
                      <td
                        v-if="parameterKey === 0"
                        :rowspan="equipment.parameters.length"
                      >
                        {{ equipment.name }}
                      </td>
                      <td :id="`popover_${parameter.id}`">{{ parameter.name }} ({{parameter.short_name}})</td>
                      <b-popover
                        :target="`popover_${parameter.id}`"
                        triggers="hover"
                        placement="right"
                      >
                        <div class="popover-content">
                          Максимальный уровень: {{ parameter.max }}
                        </div>
                        <div class="popover-content">
                          Минимальный уровень: {{ parameter.min }}
                        </div>
                      </b-popover>
                      <td
                        v-for="hour in 24"
                        :key="`${hour}_hours`"
                      >
                        <input
                          v-mask="'9{1,2},9{1,2}'"
                          :value="processParameterValue(parameterValue(parameter.id, hour))"
                          class="parameter__value__input"
                          type="text"
                          @change="saveParameterValue($event, parameter.id, hour)"
                          @keypress="isNumber($event)"
                        >
                      </td>
                    </tr>
                  </template>
                </template>
              </tbody>
            </table>
          </div>
          <button @click="showToast('Значения сохранены', 'success')"
                  class="btn custom-btn-blue-outline w-100">Сохранить значения</button>
        </div>
      </div>
    </b-tab>
  </b-tabs>
</template>

<script>
import {mapGetters, mapState} from "vuex";
import Inputmask from "inputmask";
import Vue from "vue";
import {showToast} from "@/utils/notification";
import {isNumber} from "@/utils/helpers";

const VueInputMask = {
  install: function (Vue) {
    Vue.directive('mask', {
      bind: function (el, binding) {
        Inputmask(binding.value).mask(el);
      }
    });
  }
}
Vue.use(VueInputMask);

export default {
  name: "LogbookTable",
  computed: {
    ...mapState({
      networkObjects: state => state.logbookPage.networkObjects,
      currentDate: state => state.logbookPage.currentLogbookDate
    }),
    ...mapGetters({
      parameterValue: 'logbookPage/getParameterValueByIdAndHour'
    }),
    showToast: () => showToast,
    isNumber: () => isNumber
  },
  methods: {
    processParameterValue(value) {
      return value ? value.toFixed(2).toString().replace('.', ',') : null
    },
    saveParameterValue(event, parameterId, hour) {
      const payload = {
        parameter_id: parameterId,
        value: event.target.value.replace(',', '.')
      }
      if (hour === 24) {
        const date = this.$moment(this.currentDate).add(1, 'days');
        payload.reading_at = date.format('YYYY-MM-DD 00:00')
      } else {
        payload.reading_at = this.$moment(this.currentDate).set('hour', hour).format('YYYY-MM-DD HH:00')
      }

      if (event.target.value) this.$store.dispatch('logbookPage/saveParameterValue', payload)
          .catch(error => showToast(error, 'error'));
    }
  }
}
</script>

<style scoped>
.logbook__table_wrapper {
  padding: 20px;
  border-radius: 20px;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
  padding-bottom: 10px;
}

thead tr th {
  color: #262F3D;
  font-size: 16px;
  font-weight: 700;
  background-color: #EAEFF2;
  text-align: center;
  vertical-align: middle;
  border: 1px solid #97ACB5;;
  padding: 5px;
}

tbody tr td {
  color: #262F3D;
  font-size: 16px;
  font-weight: 700;
  background-color: #FFFFFF;
  text-align: center;
  padding: 0.15rem;
  border: 1px solid #97ACB5;;
}

.table thead th {
  border-bottom: 0;
}

.table::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  background-color: #C7D3D9;
  border-right: .2em;
}

.table::-webkit-scrollbar-thumb {
  background-color: #97acb5;
  border-radius: .2em;
}

.parameter__value__input {
  max-width: 42px;
  border: 0;
  font-size: 14px;
  text-align: center;
}

.parameter__value__input:focus-visible {
  outline: none;
}

.popover-content {
  color: #262F3D;
  font-weight: 700;
}
</style>