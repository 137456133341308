<template>
  <div class="row"
       v-if="relatedIssues.length">
    <div class="col-12 mb-2">
      <div class="custom-card"
           v-b-toggle="`related-appeals`">
        <div class="card-body d-flex justify-content-between align-items-center">
          <div class="task-card-title d-flex align-items-center">
            Связанные заявки
            <div class="items-count">{{relatedIssues.length}}</div>
          </div>
          <div class="chevron-block">
            <chevron-right-icon class="chevron"
                                :class="{'rotate': collapseVisible}" />
          </div>
        </div>
      </div>
    </div>
    <b-collapse id="related-appeals"
                class="col-12"
                v-model="collapseVisible">
      <div v-for="issue in relatedIssues"
           :key="issue.id"
           class="custom-card mb-2 appeal">
        <div class="card-body">
          <div class="d-flex justify-content-between">
            <div @click="$router.push(`/issue/${issue.id}`)"
                 class="issue-info">
              {{ issue.getCaption() }}
            </div>
            <base-badge :label="issue.state.label"
                        :color="issue.state.color" />
          </div>

        </div>
      </div>
    </b-collapse>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import BaseBadge from "@/layout/BaseBadge";
export default {
  name: "RelatedIssuesList",
  components: {BaseBadge},
  data() {
    return {
      collapseVisible: false
    }
  },
  computed: {
    ...mapState({
      relatedIssues: state => state.issue.relatedIssues
    })
  }
}
</script>

<style scoped>
.issue-info {
  font-size: 14px;
  color: #468FC5;
  font-weight: 700;
  cursor: pointer;
}
</style>