import { render, staticRenderFns } from "./TransportPage.vue?vue&type=template&id=57a7ff21&scoped=true&"
import script from "./TransportPage.vue?vue&type=script&lang=js&"
export * from "./TransportPage.vue?vue&type=script&lang=js&"
import style0 from "./TransportPage.vue?vue&type=style&index=0&id=57a7ff21&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "57a7ff21",
  null
  
)

export default component.exports