<template>
  <li class="timeline-item">
    <div class="row">
      <div class="col-8">
        <div class="message">{{ item.message }}</div>
      </div>
      <div class="col-4">
        <div class="author">{{ item.userName }}</div>
        <div class="date">{{ item.historyDate | formatDateTime }}</div>
      </div>
    </div>
  </li>
</template>

<script>
export default {
  name: "HistoryItem",
  props: {
    item: {
      type: Object,
      required: true
    }
  }
}
</script>

<style scoped>

</style>