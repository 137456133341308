<template>
  <b-modal
    id="add-task"
    ref="add-task"
    centered
    hide-header
    hide-footer
  >
    <div class="col-12">
      <div class="custom-modal-header">
        <div class="custom-modal-title">
          Добавить задание
        </div>
        <img
          alt="x"
          class="custom-modal-close"
          src="@/assets/img/icons/close.svg"
          @click="$bvModal.hide('change-appeal-address')"
        >
      </div>
      <div class="modal-divider" />
    </div>
    <form @submit.prevent="submitForm()">
      <div class="col-12 mb-2">
        <div class="form-group">
          <label class="form-label">Тип задания</label>
          <v-select
            v-model="task_type_id"
            :options="taskTypesByCategoryGroup(issue.category.group_id)"
            :reduce="type => type.id"
            :get-option-label="(option) => option.name"
            placeholder="Выберите тип задания"
          />
        </div>

        <div class="form-group">
          <label class="form-label">Подразделение</label>
          <v-select
            v-model="department_id"
            :options="departments"
            :reduce="department => department.id"
            :get-option-label="(option) => option.name"
            placeholder="Выберите подразделение"/>
        </div>
        <div class="modal-divider" />
        <div class="row">
          <div class="col-12">
            <button
              type="submit"
              class="btn custom-btn-blue w-100"
            >
              Добавить
            </button>
          </div>
        </div>
      </div>
    </form>
  </b-modal>
</template>

<script>
import {mapGetters, mapState} from "vuex";
import notification from "@/mixins/notification";

export default {
  name: "AddTaskForm",
  mixins: [notification],
  data() {
    return {
      task_type_id: null,
      department_id: null
    }
  },
  computed: {
    ...mapGetters({
      taskTypesByCategoryGroup: 'task_types/getTaskTypesByCategoryGroup',
      issue_id: 'issue/getIssueId',
      departments: 'departments/getDepartments'
    }),
    ...mapState({
      issue: state => state.issue.issue
    })
  },
  mounted() {
    this.$store.dispatch('task_types/getAllTaskTypes');
    this.$store.dispatch('departments/getAllDepartments');
  },
  methods: {
    submitForm() {
      const payload = {
        issue_id: this.issue_id,
        params: {
          task_type_id: this.task_type_id,
          department_id: this.department_id
        }
      }
      this.$store.dispatch('issue/AddTaskToIssue', payload)
          .then(() => {
            this.$bvModal.hide('add-task');
            this.toast('Задание добавлено в заявку', 'success');
            this.$store.dispatch('issue/getIssue', this.issue_id);
            this.$store.dispatch('tasks/getLinkedTasks', this.issue_id);
            this.$store.dispatch('history/getIssueHistory', this.issue_id);
          })
          .catch(error => {
            this.alert('Ошибка', error, 'error');
          })
    }
  }
}
</script>

<style scoped>

</style>