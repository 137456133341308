<template>
  <div class="row justify-content-center align-items-center fixed-height">
    <div class="col-xl-4 col-xs-12">
      <engineering-network-form />
    </div>
  </div>
</template>

<script>
import EngineeringNetworkForm from "@/components/EngineeringNetworks/EngineeringNetworkForm";
export default {
  name: "EngineeringNetworkFormPage",
  components: {EngineeringNetworkForm}
}
</script>

<style scoped>

</style>