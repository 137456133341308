<template>
  <div class="row">
    <div class="col-lg-12">
      <div class="custom-card">
        <div class="card-header pb-0">
          <h4 class="table-title d-inline-block">
            Зоны
          </h4>
          <button
            class="btn custom-btn-blue-outline float-right"
            @click="updateZones()"
          >
            Обновить зоны
          </button>
          <hr class="divider">
        </div>
        <div class="card-body">
          <div class="container-fluid">
            <div class="row">
              <zones-table />
              <base-pagination
                v-if="this.$store.state.zone.meta && this.$store.state.zone.meta.last_page > 1"
                path="admin-zones"
                :meta="this.$store.state.zone.meta"
                :links="this.$store.state.zone.links"
                action="zone/paginateZones"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ZonesTable from "@/components/Billing/admin/ZonesList";
import BasePagination from "@/components/BasePagination";
import store from "@/store";
import ZonesService from "@/services/ZonesService";
import {getError} from "@/utils/helpers";

export default {
  name: "ZonesPage",
  components: {
    ZonesTable,
    BasePagination
  },
  beforeRouteEnter(to, from, next) {
    const currentPage = parseInt(to.query.page) || 1;
    store.dispatch("zone/getZones", currentPage).then(() => {
      to.params.page = currentPage;
      next();
    });
  },
  data() {
    return {
      error: null
    }
  },
  methods: {
    updateZones() {
      ZonesService.updateZonesAuto()
          .then(() => {
            this.$swal({
              toast: true,
              position: 'top-end',
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
              didOpen: toast => {
                toast.addEventListener('mouseenter', this.$swal.stopTimer)
                toast.addEventListener('mouseleave', this.$swal.resumeTimer)
              },
              title: 'Зоны обновлены',
              icon: 'success'
            })
            this.$store.dispatch('zone/getZones')
          })
          .catch(error => {
            this.error = getError(error)
            this.$swal({
              title: 'Некорректные данные',
              text: this.error,
              icon: 'error',
              confirmButtonColor: '#1178bd'
            })
          })
    }
  }
}
</script>

<style scoped>

</style>