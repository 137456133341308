import LogbookService from "@/services/LogbookService";
import {getError} from "@/utils/helpers";
import moment from "moment";

export const namespaced = true;

export const state = {
    networkObjects: [],
    parametersValues: [],
    currentLogbookDate: null,
    error: null,
    loading: false,
    valuesLoading: false
}

export const mutations = {
    SET_NETWORK_OBJECTS(state, networkObjects) {
        state.networkObjects = networkObjects;
    },
    SET_PARAMETERS_VALUES(state, values) {
        state.parametersValues = values;
    },
    SET_CURRENT_LOGBOOK_DATE(state, date) {
        state.currentLogbookDate = date;
    },
    SET_ERROR(state, error) {
        state.error = error;
    },
    SET_LOADING(state, loading) {
        state.loading = loading;
    },
    SET_VALUES_LOADING(state, loading) {
        state.valuesLoading = loading;
    }
}

export const actions = {
    getNetworkObjectsList({commit}) {
        commit("SET_LOADING", true);
        LogbookService.fetchNetworkObjectsLogbookList()
            .then(networkObjects => commit("SET_NETWORK_OBJECTS", networkObjects))
            .catch(error => commit("SET_ERROR", error))
            .finally(() => commit("SET_LOADING", false));
    },
    getParametersValues({commit}, params) {
        commit("SET_VALUES_LOADING", true);
        LogbookService.fetchParametersValues(params)
            .then(parametersValues => commit("SET_PARAMETERS_VALUES", parametersValues))
            .catch(error => commit("SET_ERROR", error))
            .finally(() => commit("SET_VALUES_LOADING", false));
    },
    saveParameterValue(context, payload) {
        return new Promise((resolve, reject) => {
            LogbookService.saveParameterValue(payload)
                .then(() => resolve())
                .catch(error => reject(getError(error)))
        })
    }
}

export const getters = {
    getNetworkObjectsByType: state => typeId => {
        return state.networkObjects.filter(item => item.typeId === typeId);
    },
    getParameterValueByIdAndHour: state => (parameterId, hour) => {
        const parameterValue = state.parametersValues.find(item => {
            if (hour !== 24) {
                return item.parameterId === parameterId && moment(item.readingAt).hour() === hour
            } else return item.parameterId === parameterId && moment(item.readingAt).hour() === 0
        })
        if (parameterValue) return parameterValue.value;
    }
}