<template>
  <div class="col-md-12">
    <form @submit.prevent="checkType">
      <div class="form-group">
        <label class="form-label">Наименование</label>
        <input
          v-model="name"
          type="text"
          class="form-control form-control-fix"
          placeholder="Наименование"
          required
        >
      </div>
      <div class="form-group">
        <label class="form-label">Входящие зоны</label>
        <v-select
          v-model="zones"
          :options="this.$store.state.zone.zones"
          :reduce="zone => zone.id"
          multiple
          :get-option-label="(option) => option.attributes.name"
        />
      </div>
      <div class="form-group">
        <label class="form-label">Расходомеры</label>
        <v-select
          v-model="devices"
          :options="this.$store.state.device.devices"
          :reduce="device => Number.parseInt(device.id)"
          multiple
          :get-option-label="(option) => option.attributes.name"
        />
      </div>
      <div class="form-group">
        <label class="form-label">Исключаемые расходомеры</label>
        <v-select
          v-model="excludedDevices"
          :options="this.childrenDevices"
          :reduce="device => Number.parseInt(device.id)"
          multiple
          :get-option-label="(option) => option.attributes.name"
        />
      </div>
      <div class="form-group">
        <label class="form-label">Тип сети</label>
        <select class="form-control form-control-fix"
                v-model="network">
          <option :value="1">Водоснабжение</option>
          <option :value="2">Водоотведение</option>
        </select>
      </div>
      <div class="form-group">
        <div class="custom-control custom-switch">
          <input
            id="customSwitch2"
            v-model="active"
            type="checkbox"
            class="custom-control-input"
          >
          <label
            v-if="this.active"
            class="custom-control-label"
            for="customSwitch2"
          >Активна</label>
          <label
            v-else
            class="custom-control-label"
            for="customSwitch2"
          >Не активна</label>
        </div>
      </div>
      <div class="form-group">
        <div class="custom-control custom-switch">
          <input
            id="customSwitch3"
            v-model="balance_zone"
            type="checkbox"
            class="custom-control-input"
          >
          <label
            v-if="this.balance_zone"
            class="custom-control-label"
            for="customSwitch3"
          >Балансовая зона</label>
          <label
            v-else
            class="custom-control-label"
            for="customSwitch3"
          >Не балансовая зона</label>
        </div>
      </div>
      <div class="text-center">
        <button
          type="submit"
          class="btn custom-btn-blue w-100"
        >
          Сохранить
        </button>
      </div>
    </form>
  </div>
</template>

<script>
import BalanceGroupService from "@/services/BalanceGroupService";
import {getError} from "@/utils/helpers";
import {mapGetters} from "vuex";

export default {
  name: "BalanceGroupsForm",
  data () {
    return {
      balanceGroup_id: null,
      name: '',
      zones: [],
      devices: [],
      active: true,
      error: null,
      type: 'create',
      balance_zone: false,
      excludedDevices: [],
      childrenDevices: [],
      network: 1
    }
  },
  computed: {
    ...mapGetters({
      getChildrenDevices: 'device/getChildrenDevices'
    })
  },
  watch: {
    devices: function (val) {
      this.childrenDevices = this.getChildrenDevices(val)
    }
  },
  mounted() {
    this.$store.dispatch("zone/getAllZones")
    this.$store.dispatch("device/getAllDevices")

    if (this.$route.name == "BalanceGroupUpdate") {
      let balanceGroup = this.$store.getters['balanceGroup/getBalanceGroup'](this.$route.params.id);
      this.balanceGroup_id = this.$route.params.id;
      this.name = balanceGroup.attributes.name;
      this.zones = balanceGroup.attributes.zones;
      this.devices = balanceGroup.attributes.devices;
      this.active = balanceGroup.attributes.active;
      this.balance_zone = balanceGroup.attributes.balance_zone;
      this.excludedDevices = balanceGroup.attributes.excludedDevices;
      this.network = balanceGroup.attributes.network;
      this.type = 'update';
    }
  },
  methods: {
    checkType() {
      if (this.type === 'create') {
        this.createBalanceGroup();
      } else if (this.type === 'update') {
        this.updateBalanceGroup();
      }
    },
    createBalanceGroup() {
      const payload = {
        name: this.name,
        zones: this.zones,
        devices: this.devices,
        active: this.active,
        balance_zone: this.balance_zone,
        excludedDevices: this.excludedDevices,
        network: this.network
      }
      this.error = null
      BalanceGroupService.createBalanceGroup(payload)
          .then(() => this.$router.push('/admin-balance-groups'))
          .catch(error => {
            this.error = getError(error);
            this.$swal('Некорректные данные', this.error, 'error')
          });
    },
    updateBalanceGroup() {
      const payload = {
        name: this.name,
        zones: this.zones,
        devices: this.devices,
        active: this.active,
        balance_zone: this.balance_zone,
        excludedDevices: this.excludedDevices,
        network: this.network
      }
      this.error = null
      BalanceGroupService.updateBalanceGroup(this.balanceGroup_id, payload)
          .then(() => this.$router.push("/admin-balance-groups"))
          .catch(error => {
            this.error = getError(error);
            this.$swal('Некорректные данные', this.error, 'error')
          });
    }
  }
}
</script>

<style scoped>

</style>