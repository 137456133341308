<template>
  <b-tab
    title="Объект"
    title-item-class="custom-nav-item"
    title-link-class="custom-nav-link"
  >
    <h4 class="billing-tab-title mb-20">
      Информация по объекту
    </h4>

    <div
      v-if="this.$store.state.building.building === null"
      class="row"
    >
      <div class="col-12">
        <div class="no-result-title">
          Нет данных.
        </div>
        <div class="no-result-text">
          Выберите объект в результате фильтрации.
        </div>
      </div>
    </div>

    <div
      v-else
      class="row building-detail"
    >
      <div
        v-if="!mapClick"
        class="col-12"
      >
        <div
          class="result-map position-unset"
          @click="$store.commit('baseMap/SET_CENTER_ZOOM', {center: buildingCoords(buildingId), zoom: 17})"
        >
          <img
            alt="Показать на карте"
            src="@/assets/img/billing/map.svg"
          >
        </div>
      </div>
      <div class="col-12">
        <div class="field-name">
          Адрес:
        </div>
        <div class="field-value">
          {{ getFullAddress(this.$store.getters['building/getStateBuilding']) }}
        </div>
      </div>
      <div class="col-12">
        <div class="field-name">
          Зона водоснабжения:
        </div>
        <div class="field-value">
          {{ zoneName(buildingWSZoneId) }}
        </div>
      </div>
      <div class="col-12">
        <div class="field-name">
          Зона водоотведения:
        </div>
        <div class="field-value">
          {{ zoneName(buildingWDZoneId) }}
        </div>
      </div>
      <div class="col-12">
        <div class="field-name">
          Источник водоснабжения:
        </div>
        <div class="field-value">
          {{ buildingSource }}
        </div>
      </div>
      <!--      <div class="col-12">
        <div class="field-name">
          Тип объекта:
        </div>
        <div class="field-value">
          {{ buildingType | nullFilter }}
        </div>
      </div>-->
      <!--      <div class="col-12">
        <div class="field-name">
          Площадь застройки:
        </div>
        <div class="field-value">
          {{ buildingArea | nullFilter }}
        </div>
      </div>-->
      <!--      <div class="col-12">
        <div class="field-name">
          Жилая площадь:
        </div>
        <div class="field-value">
          {{ buildingLeavingArea | nullFilter }}
        </div>
      </div>-->
      <div
        v-if="buildingTechnicalCondition.length !== 0"
        class="col-12"
      >
        <div
          v-b-toggle.tech-condition
          class="field-name"
        >
          <img
            id="tech-condition_icon"
            alt="Раскрыть тех. условия"
            class="collapse-arrow"
            src="@/assets/img/billing/arrow-right.svg"
          >
          Связанные Технические условия по ВС и ВО
        </div>
        <b-collapse
          id="tech-condition"
          class="building-collapse"
        >
          <ol>
            <li
              v-for="techCondition in buildingTechnicalCondition"
              :key="techCondition.id"
              class="techCondition-item mb-2"
            >
              <div class="col-12">
                <div class="field-name">
                  Заявитель:
                </div>
                <div class="field-value">
                  {{ techCondition.attributes.declarer_name }}
                </div>
              </div>
              <div class="col-12">
                <div class="field-name">
                  Тип заявителя:
                </div>
                <div class="field-value">
                  {{ techCondition.attributes.declarer_type | declarer_type_filter }}
                </div>
              </div>
              <div class="col-12">
                <div class="field-name">
                  № ТУ:
                </div>
                <div class="field-value">
                  {{ techCondition.attributes.number }}
                </div>
              </div>
              <div class="col-12">
                <div class="field-name">
                  Тип ТУ:
                </div>
                <div class="field-value">
                  {{ techCondition.attributes.type | type_filter }}
                </div>
              </div>
              <div class="col-12">
                <div class="field-name">
                  Водоснабжение, м3/час:
                </div>
                <div class="field-value">
                  {{ techCondition.attributes.vs_hour }}
                </div>
              </div>
              <div class="col-12">
                <div class="field-name">
                  Водоснабжение, м3/день:
                </div>
                <div class="field-value">
                  {{ techCondition.attributes.vs_day }}
                </div>
              </div>
              <div class="col-12">
                <div class="field-name">
                  Водоотведение, м3/час:
                </div>
                <div class="field-value">
                  {{ techCondition.attributes.vo_hour }}
                </div>
              </div>
              <div class="col-12">
                <div class="field-name">
                  Водоотведение, м3/день:
                </div>
                <div class="field-value">
                  {{ techCondition.attributes.vo_day }}
                </div>
              </div>
              <div class="col-12">
                <div class="field-name">
                  Дата подключения:
                </div>
                <div class="field-value">
                  {{ techCondition.attributes.connection_date | nullFilter }}
                </div>
              </div>
              <div class="col-12">
                <div class="field-name">
                  Дата технического подключения:
                </div>
                <div class="field-value">
                  {{ techCondition.attributes.accession_date | nullFilter }}
                </div>
              </div>
            </li>
          </ol>
        </b-collapse>
      </div>
      <div class="col-12">
        <div v-b-toggle.customers>
          <img
            id="customers_icon"
            alt="Раскрыть абонентов"
            class="collapse-arrow"
            src="@/assets/img/billing/arrow-right.svg"
          >
          <div class="field-name">
            Абоненты:
          </div>

          <div class="field-value">
            ФЛ - {{ buildingPrivateClientsCount }},
            ЮЛ - {{ buildingBusinessClientsCount }}
          </div>
        </div>

        <base-loader v-if="clientLoading"
                     width="1rem !important"
                     height="1rem !important"
                     class="float-right"
        />

        <b-collapse
          v-if="buildingClients.length !== 0"
          id="customers"
          class="building-collapse"
        >
          <div v-if="buildingPrivateClientsCount !== 0">
            <div class="collapse-block-title">
              Физические лица:
            </div>
            <ol type="1">
              <li
                v-for="client in buildingClients.filter((item) => item.attributes.type == 1)"
                @click="getClientDetail(client.id)"
                :key="client.ls"
                class="collapse-list-item"
              >
                №{{ client.attributes.contract_number }} от
                {{ client.attributes.contract_open_date }} <br>
                {{ client.attributes.address }} <br>
                {{ client.attributes.contract_client_name }} <br>
              </li>
            </ol>
          </div>
          <div v-if="buildingBusinessClientsCount !== 0">
            <div class="collapse-block-title">
              Юридические лица:
            </div>
            <ol type="1">
              <li
                v-for="client in buildingClients.filter((item) => item.attributes.type == 2)"
                @click="getClientDetail(client.id)"
                :key="client.ls"
                class="collapse-list-item"
              >
                №{{ client.attributes.contract_number }} от
                {{ client.attributes.contract_open_date }} <br>
                {{ client.attributes.contract_client_name }} <br>
                {{ client.attributes.address }} <br>
              </li>
            </ol>
          </div>
        </b-collapse>
      </div>
      <!--      <div class="col-12">
        <div class="field-name">
          Количество точек подключения по ВС и ВО:
        </div>
        <div class="field-value">
          {{ buildingConnectionPointsVS | nullFilter }},
          {{ buildingConnectionPointsVO | nullFilter }}
        </div>
      </div>-->
      <!--      <div class="col-12">
        <div class="field-name">
          Сальдо по всему объекту:
        </div>
        <div class="field-value">
          {{ buildingBalance }}
        </div>
      </div>
      <div class="col-12">
        <div class="field-name">
          График подачи воды в утренние часы:
        </div>
        <div class="field-value">
          {{ buildingScheduleAm }}
        </div>
      </div>
      <div class="col-12">
        <div class="field-name">
          График подачи воды в вечерние часы:
        </div>
        <div class="field-value">
          {{ buildingSchedulePm }}
        </div>
      </div>-->
      <div class="col-12">
        <div class="field-name">
          Аварийность:
        </div>
        <div class="field-value">
          {{ buildingAccidentRate ? 'Да' : 'Нет' }}
        </div>
      </div>
      <div class="col-12">
        <div class="field-name">
          Наличие утечек:
        </div>
        <div class="field-value">
          {{ buildingLeaks ? 'Да' : 'Нет' }}
        </div>
      </div>
      <div class="col-12">
        <div class="field-name">
          Нелегальные подключения:
        </div>
        <div class="field-value">
          {{ buildingIllegalConnections ? 'Да' : 'Нет' }}
        </div>
      </div>
      <div class="col-12">
        <div class="field-name">
          Подключение без технических условий (ТУ):
        </div>
        <div class="field-value">
          {{ buildingIllegalTU ? 'Да' : 'Нет' }}
        </div>
      </div>
    </div>
  </b-tab>
</template>

<script>
import {mapGetters} from "vuex";
import BaseLoader from "@/layout/BaseLoader";

export default {
  name: "BillingBuildingDetail",
  components: {BaseLoader},
  filters: {
    nullFilter: function (value) {
      if (value === null) {
        return 'данные отсутствуют'
      } else {
        return value
      }
    },
    declarer_type_filter: function (value) {
      switch (value) {
        case 1:
          return 'Физическое лицо'
        case 2:
          return 'Юридическое лицо'
        case 3:
          return 'Неизвестно'
      }
    },
    type_filter: function (value) {
      switch (value) {
        case 1:
          return 'ТУ на водоснабжение'
        case 2:
          return 'ТУ на водоотведение'
        case 3:
          return 'ТУ на водоснабжение и водоотведение'
        case 4:
          return 'Неизвестно'
      }
    }
  },
  data() {
    return {
      mapClick: false
    }
  },
  computed: {
    ...mapGetters({
      buildingAddress: "building/getBuildingAddress",
      buildingWSZoneId: "building/getBuildingWSZoneId",
      buildingWDZoneId: "building/getBuildingWDZoneId",
      buildingSource: "building/getBuildingSource",
      buildingType: "building/getBuildingType",
      buildingArea: "building/getBuildingArea",
      buildingLeavingArea: "building/getBuildingLeavingArea",
      buildingTechnicalCondition: "building/getBuildingTechnicalCondition",
      buildingPrivateClientsCount: "building/getBuildingPrivateClientsCount",
      buildingBusinessClientsCount: "building/getBuildingBusinessClientsCount",
      buildingClients: "building/getBuildingClients",
      buildingConnectionPointsVS: "building/getBuildingConnectionPointsVS",
      buildingConnectionPointsVO: "building/getBuildingConnectionPointsVO",
      buildingBalance: "building/getBuildingBalance",
      buildingScheduleAm: "building/getBuildingScheduleAm",
      buildingSchedulePm: "building/getBuildingSchedulePm",
      buildingAccidentRate: "building/getBuildingAccidentRate",
      buildingLeaks: "building/getBuildingLeaks",
      buildingIllegalConnections: "building/getBuildingIllegalConnections",
      buildingIllegalTU: "building/getBuildingIllegalTU",
      buildingId: "building/getBuildingId",
      buildingCoords: "building/getBuildingCoords",
      zoneName: "zone/getNameBySys",
      clientLoading: "client/getClientLoading"
    }),
    getFullAddress: function () {
      return building => {
        let full_address_arr = [building.attributes.city, building.attributes.settlement, building.attributes.street,
          building.attributes.house, building.attributes.block].filter(item => item !== null)
        return full_address_arr.join(', ')
      }
    }
  },
  methods: {
    getClientDetail(id) {
      this.$store.dispatch('client/getClient', id)
          .then(() => {
            this.$store.commit('building/SET_TAB_INDEX', 3);
          })
    }
  }
}
</script>

<style scoped>
.building-detail > .col-12 {
  margin-bottom: 3px;
}

.field-name {
  display: inline-block;
  color: #97ACB5;
  font-size: 16px;
  font-weight: 700;
  margin-right: 10px;
}

.field-value {
  display: inline-block;
  color: #468FC5;
  font-size: 16px;
  font-weight: 700;
}

.collapse-arrow {
  margin-right: 6px;
  transition: 0.2s;
}

.collapse-block-title {
  color: #97ACB5;
  font-weight: 700;
  margin-top: 20px;
}

.building-collapse {
  background-color: white;
  border-radius: 10px;
  padding: 10px;
}

.result-map {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  background-color: #EBF1F3;
  border-radius: 6px;
  position: absolute;
  right: 10px;
  cursor: pointer;
}

.result-map img {
  width: 16px;
}

.position-unset {
  position: unset !important;
}

.collapse-list-item {
  color: #677385;
  transition: .3s;
  cursor: pointer;
  margin-bottom: 8px;
}

.collapse-list-item:hover {
  color: #468FC5;
  transition: .5s;
}
</style>