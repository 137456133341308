<template>
  <div class="row">
    <div class="col-xl-4 col-xs-12">
      <tasks-list-filter />
      <tasks-list />
    </div>
  </div>
</template>

<script>
import TasksList from "@/components/ServiceDesk/Tasks/TasksList";
import TasksListFilter from "@/components/ServiceDesk/Tasks/TasksListFilter";
import {mapGetters} from "vuex";

export default {
  name: "TasksListPage",
  components: {TasksListFilter, TasksList},
  data() {
    return {
      timing: null
    }
  },
  computed: {
    ...mapGetters({
      filterParams: 'tasks/getFilterParams'
    })
  },
  mounted() {
    this.getTasks();

    this.timing = setInterval(this.getTasks, process.env.VUE_APP_SERVICE_DESK_GET_DATA_TIMING)
  },
  beforeDestroy() {
    this.$store.commit('tasks/SET_TASKS', []);
    clearInterval(this.timing);
  },
  methods: {
    getTasks() {
      const currentPage = this.$route.query.page || 1;
      this.$store.dispatch('tasks/getTasks',{
        page: currentPage, params: this.filterParams
      }
      );
    }
  }
}
</script>

<style scoped>

</style>