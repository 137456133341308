<template>
  <b-modal
    id="add-work"
    ref="add-work"
    centered
    hide-header
    hide-footer
  >
    <div class="col-12">
      <div class="custom-modal-header">
        <div class="custom-modal-title">
          Добавить работу
        </div>
        <img
          class="custom-modal-close"
          src="@/assets/img/icons/close.svg"
          @click="$bvModal.hide('add-work')"
        >
      </div>
      <div class="modal-divider" />
    </div>
    <form @submit.prevent="submitForm()">
      <div class="col-12 mb-2">
        <div class="form-group">
          <label class="form-label">Работа</label>
          <v-select v-model="payload.work_id"
                    :options="works"
                    :reduce="work => work.id"
                    :get-option-label="(option) => option.name"
                    placeholder="Выберите работу"
          />
        </div>
        <div class="form-group">
          <label class="form-label">Аварийный элемент</label>
          <v-select v-model="payload.element_id"
                    :options="emergencyElements"
                    :reduce="element => element.id"
                    :get-option-label="(option) => option.getCaption()"
                    placeholder="Выберите аварийный элемент"
          />
        </div>
        <div class="form-group">
          <label class="form-label">Комментарий</label>
          <textarea v-model="payload.comment"
                    maxlength="1500"
                    class="form-control form-control-fix"
                    placeholder="Введите комментарий" />
        </div>
        <div class="modal-divider" />
        <div class="row">
          <div class="col-12">
            <button
              type="submit"
              class="btn custom-btn-blue w-100"
            >
              Добавить
            </button>
          </div>
        </div>
      </div>
    </form>
  </b-modal>
</template>

<script>
import {mapGetters} from "vuex";
import notification from "@/mixins/notification";

export default {
  name: "AddWork",
  mixins: [notification],
  data() {
    return {
      payload: {
        work_id: null,
        comment: null,
        element_id: null
      }
    }
  },
  computed: {
    ...mapGetters({
      works: 'works/getWorks',
      taskId: 'task/getTaskId',
      emergencyElements: 'emergencyElements/getEmergencyElements'
    })
  },
  mounted() {
    this.$store.dispatch('works/getAllWorks');
  },
  methods: {
    submitForm() {
      this.$store.dispatch('task/addWork', {
        task_id: this.taskId,
        payload: this.payload
      })
          .then(() => {
            this.payload = {
              work_id: null,
              comment: null
            };
            this.$store.dispatch('works/getTaskWorks', this.taskId);
            this.$bvModal.hide('add-work');
            this.toast('Работа добавлена', 'success');
            this.$store.dispatch('history/getTaskHistory', this.taskId);
          })
          .catch(error => this.alert('Ошибка', error, 'error'));
    }
  }
}
</script>

<style scoped>

</style>