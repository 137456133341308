<template>
  <div class="custom-card">
    <div class="card-body">
      <div class="container-fluid">
        <div class="row">
          <div class="col-12">
            <form @submit.prevent="checkType">

              <div class="form-group">
                <label class="form-label">Наименование</label>
                <input
                  v-model="payload.name"
                  class="form-control"
                  type="text"
                  placeholder="Введите наименование"
                  required
                >
              </div>

              <div class="form-group">
                <label class="form-label d-block">Иконка</label>
                <label class="custom-control custom-radio d-inline-block mr-2">
                  <input v-model="payload.icon"
                         value="boiler-svg"
                         class="custom-control-input"
                         name="custom-radio"
                         type="radio">
                  <span class="custom-control-label">
                    <img src="@/assets/img/telemetry/boiler.svg"
                         alt="Котельная">
                  </span>
                </label>

                <label class="custom-control custom-radio d-inline-block mr-2">
                  <input v-model="payload.icon"
                         value="counter-svg"
                         class="custom-control-input"
                         name="custom-radio"
                         type="radio">
                  <span class="custom-control-label">
                    <img src="@/assets/img/telemetry/counter.svg"
                         alt="Котельная">
                  </span>
                </label>

                <label class="custom-control custom-radio d-inline-block mr-2">
                  <input v-model="payload.icon"
                         value="mkd-svg"
                         class="custom-control-input"
                         name="custom-radio"
                         type="radio">
                  <span class="custom-control-label">
                    <img src="@/assets/img/telemetry/mkd.svg"
                         alt="Котельная">
                  </span>
                </label>

                <label class="custom-control custom-radio d-inline-block mr-2">
                  <input v-model="payload.icon"
                         value="pump-svg"
                         class="custom-control-input"
                         name="custom-radio"
                         type="radio">
                  <span class="custom-control-label">
                    <img src="@/assets/img/telemetry/pump.svg"
                         alt="Котельная">
                  </span>
                </label>

                <label class="custom-control custom-radio d-inline-block mr-2">
                  <input v-model="payload.icon"
                         value="tank-svg"
                         class="custom-control-input"
                         name="custom-radio"
                         type="radio">
                  <span class="custom-control-label">
                    <img src="@/assets/img/telemetry/tank.svg"
                         alt="Котельная">
                  </span>
                </label>
              </div>

              <div class="text-center">
                <button
                  type="submit"
                  class="btn custom-btn-blue w-100"
                >
                  Сохранить
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapState} from "vuex";
import {showAlert, showToast} from "@/utils/notification";

export default {
  name: "NetworkObjectTypesForm",
  data() {
    return {
      payload: {
        name: null,
        icon: null
      }
    }
  },
  computed: {
    ...mapState({
      networkObjectType: state => state.networkObjectTypes.networkObjectType
    })
  },
  mounted() {
    if (this.$route.name === 'NetworkObjectTypeUpdate') {
      this.$store.dispatch('networkObjectTypes/getNetworkObject', this.$route.params.id)
          .then(() => {
            this.payload.name = this.networkObjectType.name;
            this.payload.icon = this.networkObjectType.icon;
          })
    }
  },
  methods: {
    checkType() {
      if (this.$route.name === 'NetworkObjectTypeCreate') this.createNetworkObjectType()
      else this.updateNetworkObjectType()
    },
    createNetworkObjectType() {
      this.$store.dispatch('networkObjectTypes/createNetworkObjectType', this.payload)
          .then(() => {
            showToast('Тип объекта сети добавлен', 'success');
            this.$router.push('/network-object-types');
          })
          .catch(error => showAlert('Ошибка', error));
    },
    updateNetworkObjectType() {
      this.$store.dispatch('networkObjectTypes/updateNetworkObjectType', {
        typeId: this.$route.params.id,
        payload: this.payload
      })
          .then(() => {
            showToast('Тип объекта сети обновлён', 'success');
            this.$router.push('/network-object-types');
          })
          .catch(error => showAlert('Ошибка', error));
    }
  }
}
</script>

<style scoped>

</style>