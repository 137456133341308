<template>
  <div v-if="shutdowns.length"
       class="row">
    <div class="col-12 mb-2">
      <div class="custom-card"
           v-b-toggle="`shutdowns-list`">
        <div class="card-body d-flex justify-content-between align-items-center">
          <div class="task-card-title mb-0 d-flex align-items-center">
            Отключения
            <div class="items-count">{{ shutdowns.length }}</div>
          </div>
          <div class="chevron-block">
            <chevron-right-icon class="chevron"
                                :class="{'rotate': collapseVisible}" />
          </div>
        </div>
      </div>
    </div>
    <b-collapse id="shutdowns-list"
                v-model="collapseVisible"
                class="col-12">
      <shutdown-list-item v-for="shutdown in shutdowns"
                          :key="shutdown.id"
                          :shutdown="shutdown" />
    </b-collapse>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import ShutdownListItem from "@/components/ServiceDesk/Issues/ShutdownListItem";

export default {
  name: "ShutdownsList",
  components: {ShutdownListItem},
  data() {
    return {
      collapseVisible: false
    }
  },
  computed: {
    ...mapGetters({
      shutdowns: 'shutdowns/getShutdowns',
      issueId: 'issue/getIssueId'
    })
  }
}
</script>

<style scoped>
</style>