import * as API from './API'
import Leak from "@/models/serviceDesk/Leak";
import {getError} from "@/utils/helpers";

export default {
    getLeaks(issue_id) {
        return new Promise((resolve, reject) => {
            API.apiClient.get(`service-desk/issues/${issue_id}/leaks`)
                .then(response => resolve(response.data.data.map(leak => new Leak(leak.id, leak.attributes))))
                .catch(error => reject(getError(error)));
        })
    },
    createLeak({issue_id, payload}) {
        return API.apiClient.post(`service-desk/issues/${issue_id}/leaks`, payload);
    }
}