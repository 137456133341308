import * as API from './API'
import LogbookNetworkObject from "@/models/telemetry/LogbookNetworkObject";
import {getError} from "@/utils/helpers";
import ParameterValue from "@/models/telemetry/ParameterValue";

export default {
    getAllConfigurations()
    {
        return API.apiClient.get(`/journal/configs/all`);
    },
    getPaginateConfigurations(page)
    {
        return API.apiClient.get(`/journal/configs?page[number]=${page}&page[size]=15`);
    },
    deleteConfig(config_id) {
        return API.apiClient.delete(`/journal/configs/${config_id}`);
    },
    createConfig(payload) {
        return API.apiClient.post(`/journal/configs`, payload);
    },
    updateConfig(config_id, payload) {
        return API.apiClient.patch(`/journal/configs/${config_id}`, payload);
    },
    saveValues(payload) {
        return API.apiClient.post(`/journal/values`, payload);
    },
    getValuesByDate(date) {
        return API.apiClient.get('/journal/values',{ params: {date: date}});
    },
    getDeltaValues(date) {
        return API.apiClient.get('/journal/calculateDeltaValues',{ params: {date: date}});
    },
    getExcelReport(payload) {
        return API.apiClient.get('/journal/excel', { params: payload})
    },

    fetchNetworkObjectsLogbookList() {
        return new Promise((resolve, reject) => {
            API.apiClient.get(`/journal/list`)
                .then(response => resolve(response.data.data.map(item => new LogbookNetworkObject(item))))
                .catch(error => reject(getError(error)))
        })
    },
    fetchParametersValues(params) {
        return new Promise((resolve, reject) => {
            API.apiClient.get(`/journal/values`, {params: params})
                .then(response => resolve(response.data.data.map(item => new ParameterValue(item))))
                .catch(error => reject(getError(error)))
        })
    },
    saveParameterValue(payload) {
        return API.apiClient.post(`/network-equipments/parameters/hour-reading`, payload);
    }
}