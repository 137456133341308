import Vue from 'vue'
import App from './App.vue'
import VueRouter from 'vue-router'
import router from './router/index'
import store from './store'

import {
    ModalPlugin,
    PopoverPlugin,
    BTable,
    BButton,
    BTabs,
    BTab,
    BCollapse,
    BOverlay,
    TabsPlugin,
    BImg,
    VBTogglePlugin,
    VBTooltip,
    BSkeleton,
    BSkeletonWrapper,
    BSkeletonImg,
    BCard,
    BDropdown,
    BDropdownItemButton
} from 'bootstrap-vue';
import {
    EditIcon,
    Trash2Icon,
    LayersIcon,
    ChevronDownIcon,
    ChevronRightIcon,
    ChevronLeftIcon,
    CornerUpRightIcon,
    SettingsIcon,
    RefreshCcwIcon,
    XIcon,
    ListIcon,
    AlignJustifyIcon,
    MapIcon,
    CheckIcon,
    BookOpenIcon,
    InfoIcon,
    ToolIcon,
    ArrowLeftIcon,
    ArrowRightIcon,
    ThermometerIcon,
    BookIcon,
    TruckIcon,
    PhoneCallIcon,
    MonitorIcon,
    ClipboardIcon,
    DownloadIcon,
    HelpCircleIcon,
    FilterIcon,
    PieChartIcon,
    DropletIcon,
    UsersIcon,
    CalendarIcon,
    BriefcaseIcon,
    UserCheckIcon,
    BoxIcon,
    FileTextIcon,
    ColumnsIcon,
    GridIcon,
    GitPullRequestIcon,
    MessageSquareIcon,
    ServerIcon,
    FileIcon,
    AlertOctagonIcon,
    RadioIcon,
    PackageIcon,
    BarChartIcon,
    DivideIcon,
    RepeatIcon,
    XCircleIcon,
    ImageIcon,
    SaveIcon,
    HexagonIcon,
    HomeIcon,
    PlusIcon,
    PaperclipIcon
} from "vue-feather-icons";
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
import vSelect from 'vue-select';
import VueMobileDetection from "vue-mobile-detection";
import VueMoment from "vue-moment";
import 'vue-select/dist/vue-select.css';
import 'moment/locale/ru'
import VueSilentbox from "vue-silentbox"
import 'vue2-datepicker/index.css';
import 'vue2-datepicker/locale/ru';

import 'bootstrap-vue/dist/bootstrap-vue.css'

import "@/assets/css/style.css";
import "@/assets/css/custom.css";
import "@/utils/filters.js"

import moment from 'moment';
import 'moment/locale/ru';

// import 'moment-timezone';
moment.locale('ru')
Vue.prototype.$moment = moment;

Vue.component('BImg', BImg);
Vue.component('BTable', BTable)
Vue.component('BButton', BButton)
Vue.component('VSelect', vSelect);
Vue.component('BTabs', BTabs);
Vue.component('BTab', BTab);
Vue.component("BOverlay", BOverlay);
Vue.component("BCollapse", BCollapse);
Vue.component("BSkeleton", BSkeleton);
Vue.component("BSkeletonWrapper", BSkeletonWrapper);
Vue.component("BSkeletonImg", BSkeletonImg);
Vue.component("BCard", BCard);
Vue.component("BDropdown", BDropdown);
Vue.component("BDropdownItemButton", BDropdownItemButton);
Vue.directive('b-tooltip', VBTooltip);

/* Feather-icons */
Vue.component('EditIcon', EditIcon);
Vue.component('LayersIcon', LayersIcon);
Vue.component('Trash2Icon', Trash2Icon);
Vue.component('ChevronDownIcon', ChevronDownIcon);
Vue.component('ChevronRightIcon', ChevronRightIcon);
Vue.component('ChevronLeftIcon', ChevronLeftIcon);
Vue.component('CornerUpRightIcon', CornerUpRightIcon);
Vue.component('SettingsIcon', SettingsIcon);
Vue.component('RefreshCcwIcon', RefreshCcwIcon);
Vue.component('XIcon', XIcon);
Vue.component('ListIcon', ListIcon);
Vue.component('AlignJustifyIcon', AlignJustifyIcon);
Vue.component('MapIcon', MapIcon);
Vue.component('CheckIcon', CheckIcon);
Vue.component('BookOpenIcon', BookOpenIcon);
Vue.component('InfoIcon', InfoIcon);
Vue.component('ToolIcon', ToolIcon);
Vue.component('ArrowLeftIcon', ArrowLeftIcon);
Vue.component('ArrowRightIcon', ArrowRightIcon);
Vue.component('ThermometerIcon', ThermometerIcon);
Vue.component('BookIcon', BookIcon);
Vue.component('TruckIcon', TruckIcon);
Vue.component('PhoneCallIcon', PhoneCallIcon);
Vue.component('MonitorIcon', MonitorIcon);
Vue.component('ClipboardIcon', ClipboardIcon);
Vue.component('DownloadIcon', DownloadIcon);
Vue.component('HelpCircleIcon', HelpCircleIcon);
Vue.component('FilterIcon', FilterIcon);
Vue.component('PieChartIcon', PieChartIcon);
Vue.component('DropletIcon', DropletIcon);
Vue.component('UsersIcon', UsersIcon);
Vue.component('CalendarIcon', CalendarIcon);
Vue.component('BriefcaseIcon', BriefcaseIcon);
Vue.component('UserCheckIcon', UserCheckIcon);
Vue.component('BoxIcon', BoxIcon);
Vue.component('FileTextIcon', FileTextIcon);
Vue.component('ColumnsIcon', ColumnsIcon);
Vue.component('GridIcon', GridIcon);
Vue.component('GitPullRequestIcon', GitPullRequestIcon);
Vue.component('MessageSquareIcon', MessageSquareIcon);
Vue.component('FileIcon', FileIcon);
Vue.component('AlertOctagonIcon', AlertOctagonIcon);
Vue.component('RadioIcon', RadioIcon);
Vue.component('ServerIcon', ServerIcon);
Vue.component('PackageIcon', PackageIcon);
Vue.component('BarChartIcon', BarChartIcon);
Vue.component('DivideIcon', DivideIcon);
Vue.component('RepeatIcon', RepeatIcon);
Vue.component('XCircleIcon', XCircleIcon);
Vue.component('ImageIcon', ImageIcon);
Vue.component('SaveIcon', SaveIcon);
Vue.component('HomeIcon', HomeIcon);
Vue.component('HexagonIcon', HexagonIcon);
Vue.component('PlusIcon', PlusIcon);
Vue.component('PaperclipIcon', PaperclipIcon);

Vue.use(VueMoment, {moment});
Vue.use(ModalPlugin);
Vue.use(PopoverPlugin);
Vue.use(TabsPlugin);
Vue.use(VueRouter);
Vue.use(VueSweetalert2);
Vue.use(VueMobileDetection);
Vue.use(VueSilentbox);
Vue.use(VBTogglePlugin);

Vue.config.productionTip = false
Vue.prototype.$eventBus = new Vue();

window.Pusher = require('pusher-js');

new Vue({
    render: h => h(App),
    router: router,
    store
}).$mount('#app')
