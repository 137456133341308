import {getError} from "@/utils/helpers";
import NetworkObjectsService from "@/services/NetworkObjectsService";

export const namespaced = true;

export const state  = {
    networkObjects: [],
    networkObject: null,
    meta: null,
    links: null,
    loading: false,
    error: null
};

export const mutations = {
    SET_NETWORK_OBJECTS(state, networkObjects) {
        state.networkObjects = networkObjects;
    },
    SET_NETWORK_OBJECT(state, networkObject) {
        state.networkObject = networkObject;
    },
    SET_META(state, meta) {
        state.meta = meta;
    },
    SET_LINKS(state, links) {
        state.links = links;
    },
    SET_LOADING(state, loading) {
        state.loading = loading;
    },
    SET_ERROR(state, error) {
        state.error = error;
    },
    DELETE_NETWORK_OBJECT(state, networkObjectId){
        let index = state.networkObjects.findIndex(networkObject => networkObject.id == networkObjectId);
        state.networkObjects.splice(index, 1);
    }
};

export const actions = {
    getAllNetworkObjects({ commit }) {
        commit("SET_LOADING", true);
        NetworkObjectsService.getAllNetworkObjects()
            .then(networkObjects => commit("SET_NETWORK_OBJECTS", networkObjects))
            .catch(error => commit("SET_ERROR", getError(error)))
            .finally(() => commit("SET_LOADING", false))
    },
    getNetworkObject({commit}, networkObjectId) {
        return new Promise((resolve, reject) => {
            NetworkObjectsService.fetchNetworkObject(networkObjectId)
                .then(networkObject => {
                    commit("SET_NETWORK_OBJECT", networkObject);
                    resolve();
                })
                .catch(error => {
                    commit("SET_ERROR", error);
                    reject(error);
                })
        })
    },
    createNetworkObject(context, payload) {
        return new Promise((resolve, reject) => {
            NetworkObjectsService.createNetworkObject(payload)
                .then(() => resolve())
                .catch(error => reject(getError(error)))
        })
    },
    updateNetworkObject(context, {networkObjectId, payload}) {
        return new Promise((resolve, reject) => {
            NetworkObjectsService.updateNetworkObject(networkObjectId, payload)
                .then(() => resolve())
                .catch(error => reject(getError(error)))
        })
    },
    deleteNetworkObject(context, networkObjectId) {
        return new Promise((resolve, reject) => {
            NetworkObjectsService.deleteNetworkObject(networkObjectId)
                .then(() => resolve())
                .catch(error => reject(getError(error)))
        })
    }
};

export const getters = {
    getNetworkObject: state => id =>{
        return state.networkObjects.find(networkObject => networkObject.id == id);
    }
}