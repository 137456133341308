<template>
  <table class="admin-table">
    <thead class="admin-table-header">
      <tr>
        <td>Пользователь</td>
        <td>Устройство</td>
        <td>Управление</td>
      </tr>
    </thead>
    <tbody class="admin-table-body">
      <tr
        v-for="(access, index) in deviceAccesses"
        :key="index"
        class="device-access"
      >
        <td>{{ access.userName }}</td>
        <td>{{ access.deviceName }}</td>
        <td>
          <trash2-icon @click="revokeAccess(access.userId, access.deviceId)"
                       class="cursor-pointer"/>
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
import {mapState} from "vuex";
import {showToast, showAlert} from "@/utils/notification";

export default {
  name: "DeviceAccessesList",
  computed: {
    ...mapState({
      deviceAccesses: state => state.deviceAccess.deviceAccesses
    })
  },
  methods: {
    revokeAccess(userId, deviceId) {
      this.$store.dispatch('deviceAccess/revokeAccess', {
        user_id: userId,
        device_id: deviceId
      })
          .then(() => {
            this.$store.dispatch('deviceAccess/getAccessesList');
            showToast('Доступ отозван', 'success');
          })
          .catch(error => showAlert('Ошибка', error));
    }
  }
}
</script>

<style scoped>

</style>