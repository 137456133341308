<template>
  <div class="row justify-content-center align-items-center calc-height">
    <div class="col-md-4">
      <div class="custom-card mb-0">
        <div class="card-body">
          <div class="container-fluid">
            <div class="row">
              <balance-group-form-el />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BalanceGroupFormEl from "@/components/Billing/admin/BalanceGroupsForm";

export default {
  name: "BalanceGroupFormPage",
  components: {
    BalanceGroupFormEl
  },
  mounted() {
    this.$store.dispatch("device/getAllDevices");
  }
}
</script>

<style scoped>
.calc-height {
  height: calc(100vh - 207px);
}
</style>