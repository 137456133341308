<template>
  <div v-if="$store.state.issue.issue !== null"
       class="row">
    <div class="col-lg-5 col-xs-12">
      <issue-info :issue="issue"
                  class="mb-3"/>
      <issue-actions v-if="issue.state.id !== IssueState.CLOSED && issue.state.id !== IssueState.CANCELED"
                     class="mb-3"/>
      <related-tasks-list :issue_id="issue.id"/>
      <related-issues-list />
      <related-appeals :issue_id="issue.id"/>
      <shutdowns-list/>
      <leaks-list />
      <emergency-elements-list />
    </div>
    <div class="col-lg-4 col-xs-12">
      <div class="row">
        <div class="col-12 mb-2">
          <div class="custom-card">
            <div class="card-body">
              <div class="task-card-title mb-1">
                История
              </div>
              <history-list :history="history"/>
            </div>
          </div>
        </div>

        <div
          v-if="$store.state.comments.comments.length"
          class="col-12"
        >
          <comments-list :show-add-btn="false" />
        </div>
      </div>
    </div>
    <div class="col-lg-3 col-xs-12 map-base-height">
      <issue-map :issue="issue"/>
    </div>
  </div>
</template>

<script>
import IssueInfo from "@/components/ServiceDesk/Issues/IssueInfo";
import IssueMap from "@/components/ServiceDesk/Issues/IssueMap";
import {mapGetters} from "vuex";
import RelatedAppeals from "@/components/ServiceDesk/Issues/RelatedAppeals";
import RelatedTasksList from "@/components/ServiceDesk/Issues/RelatedTasksList";
import IssueActions from "@/components/ServiceDesk/Issues/IssueActions";
import HistoryList from "@/components/ServiceDesk/HistoryList";
import ShutdownsList from "@/components/ServiceDesk/Issues/ShutdownsList";
import LeaksList from "@/components/ServiceDesk/Issues/LeaksList";
import IssueState from "@/models/serviceDesk/IssueState";
import CommentsList from "@/components/ServiceDesk/CommentsList";
import RelatedIssuesList from "@/components/ServiceDesk/Issues/RelatedIssuesList";
import EmergencyElementsList from "@/components/ServiceDesk/EmergencyElementsList";

export default {
  name: "IssuePage",
  components: {
    EmergencyElementsList,
    RelatedIssuesList,
    CommentsList,
    LeaksList,
    ShutdownsList,
    HistoryList,
    IssueActions,
    RelatedTasksList,
    RelatedAppeals,
    IssueMap,
    IssueInfo
  },
  data() {
    return {
      timing: null
    }
  },
  computed: {
    ...mapGetters({
      issue: 'issue/getIssue',
      history: 'history/getIssueHistory',
      coordinates: 'projectConfig/getProjectCoordinates'
    }),
    IssueState: () => IssueState
  },
  mounted() {
    this.getIssue();
    this.timing = setInterval(this.getIssue, process.env.VUE_APP_SERVICE_DESK_GET_DATA_TIMING);
  },
  beforeDestroy() {
    clearInterval(this.timing);
    this.$store.commit('baseMap/SET_CENTER_ZOOM', {
      center: this.coordinates,
      zoom: 13
    })
  },
  methods: {
    getIssue() {
      const issue_id = this.$route.params.id;

      this.$store.dispatch('issue/getIssue', issue_id)
          .then(() => {
            this.$store.commit('baseMap/SET_CENTER_ZOOM',
                {center: this.issue.coordinates, zoom: 17});
          });
      this.$store.dispatch('history/getIssueHistory', issue_id);
      this.$store.dispatch('tasks/getLinkedTasks', issue_id);
      this.$store.dispatch('appeals/getLinkedToIssueAppeals', issue_id);
      this.$store.dispatch('shutdowns/getIssueShutdowns', issue_id);
      this.$store.dispatch('leaks/getLeaks', issue_id);
      this.$store.dispatch('emergencyElements/getEmergencyElements', issue_id);
      this.$store.dispatch('comments/getIssueComments', issue_id);
      this.$store.dispatch('issue/getRelatedIssuesList', issue_id);
    }
  }
}
</script>

<style scoped>

</style>