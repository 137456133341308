<template>
  <div class="row">
    <div class="col-xl-4 col-lg-6 col-md-12">
      <works-form />
    </div>
    <div
      v-if="$route.name === 'UpdateWork'"
      class="col-xl-8 col-lg-6 col-md-12">
      <work-consumption-rates-list />
    </div>
  </div>
</template>

<script>
import WorksForm from "@/components/ServiceDesk/admin/WorksForm";
import WorkConsumptionRatesList from "@/components/ServiceDesk/admin/WorkConsumptionRatesList";

export default {
  name: "WorksFormPage",
  components: {WorkConsumptionRatesList, WorksForm}
}
</script>

<style scoped>

</style>