<template>
  <div>
    <div class="custom-card mb-2">
      <div class="card-header d-flex align-items-center">
        <h4 class="network_object__card_title d-inline-block mr-2">
          Оборудование
        </h4>
        <div class="d-flex">
          <div
            class="network_object__control_btn"
            @click="createEquipment()"
          >
            <img
              src="@/assets/img/icons/plus.svg"
              alt="Изменить">
          </div>
        </div>
      </div>
    </div>
    <div
      v-for="equipment in equipments"
      :key="equipment.id"
      class="custom-card">
      <div class="card-body row mb-2">
        <div class="col-md-6 col-sm-12 equipment">
          <div class="d-flex align-items-center mb-2">
            <div class="equipment__name mr-2">{{ equipment.name }}</div>
            <div class="d-flex">
              <div
                class="network_object__control_btn"
                @click="editEquipment(equipment)"
              >
                <img
                  src="@/assets/img/transport/settings.svg"
                  alt="Изменить">
              </div>
              <div
                class="network_object__control_btn"
                @click="deleteEquipment(equipment.id)"
              >
                <img
                  src="@/assets/img/telemetry/trash.svg"
                  alt="Удалить">
              </div>
            </div>
          </div>

          <div class="equipment__info">
            <div class="network_object__info">
              <div>Тип оборудования</div>
              <div>{{ equipment.typeName }}</div>
            </div>

            <div class="network_object__info">
              <div>Серийный номер</div>
              <div>{{ equipment.serial }}</div>
            </div>

            <div class="network_object__info">
              <div>Срок службы</div>
              <div>{{ equipment.durability }}</div>
            </div>

            <div class="network_object__info">
              <div>Дата изготовления</div>
              <div>{{ equipment.buildDate | formatDate }}</div>
            </div>

            <div class="network_object__info">
              <div>Дата установки</div>
              <div>{{ equipment.installationDate | formatDate }}</div>
            </div>
          </div>

        </div>
        <div class="col-md-6 col-sm-12">
          <equipment-parameters-list
            :parameters-list="equipment.parameters"
            :equipment-id="equipment.id"/>
        </div>
      </div>
    </div>

    <div v-if="!equipments.length && !loading">
      <div class="no-result-title">Оборудование не найдено</div>
    </div>

    <div v-if="loading"
         class="text-center mt-2">
      <base-loader />
    </div>

    <equipments-form :equipment="selectedEquipment" />
    <equipment-parameters-form />
  </div>
</template>

<script>
import EquipmentParametersList from "@/components/Telemetry/admin/NetworkObject/EquipmentParametersList";
import {mapState} from "vuex";
import {showAlert, showToast} from "@/utils/notification";
import EquipmentsForm from "@/components/Telemetry/admin/NetworkObject/EquipmentsForm";
import EquipmentParametersForm from "@/components/Telemetry/admin/NetworkObject/EquipmentParametersForm";
import BaseLoader from "@/layout/BaseLoader";

export default {
  name: "EquipmentsList",
  components: {BaseLoader, EquipmentParametersForm, EquipmentsForm, EquipmentParametersList},
  data() {
    return {
      selectedEquipment: null
    }
  },
  computed: {
    ...mapState({
      equipments: state => state.networkEquipments.networkEquipments,
      loading: state => state.networkEquipments.loading
    })
  },
  methods: {
    createEquipment() {
      this.selectedEquipment = null;
      this.$bvModal.show('equipment-form');
    },
    editEquipment(equipment) {
      this.selectedEquipment = equipment;
      this.$bvModal.show('equipment-form');
    },
    deleteEquipment(id) {
      this.$swal({
        title: 'Вы действительно хотите удалить сетевое оборудование?',
        showCancelButton: true,
        confirmButtonText: `Да`,
        cancelButtonText: 'Отмена',
        icon: 'warning'
      }).then(result => {
        if (result.isConfirmed) {
          this.$store.dispatch('networkEquipments/deleteNetworkEquipment', id)
              .then(() => {
                this.$store.dispatch('networkEquipments/getNetworkEquipments', {
                  object_id: this.$route.params.id
                });
                showToast('Сетевое оборудование удалено', 'success');
              })
              .catch(error => showAlert('Ошибка', error));
        }
      });

    }
  }
}
</script>

<style scoped>
.equipment__name {
  font-size: 18px;
  color: #468FC5;
  font-weight: bold;
}

.equipment__info {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(3, 1fr);
  row-gap: 16px;
}
</style>