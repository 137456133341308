<template>
  <b-modal
    id="add-leak"
    ref="add-leak"
    centered
    hide-header
    hide-footer
  >
    <div class="col-12">
      <div class="custom-modal-header">
        <div class="custom-modal-title">
          Добавить утечку
        </div>
        <img
          class="custom-modal-close"
          src="@/assets/img/icons/close.svg"
          @click="$bvModal.hide('add-leak')"
        >
      </div>
      <div class="modal-divider" />
    </div>
    <form @submit.prevent="submitForm()">
      <div class="col-12 mb-2">
        <div class="form-group">
          <label class="form-label">Факт. дата начала</label>
          <date-picker
            v-model="payload.actual_start_date"
            :default-value="defaultValue"
            input-class="form-control-fix w-100 pl-2"
            placeholder="Выберите дату и время"
            type="datetime"
            value-type="YYYY-MM-DD HH:mm"
            :format="DATE_TIME_FORMAT"
          />
        </div>
        <div class="form-group">
          <label class="form-label">Факт. дата окончания</label>
          <date-picker
            v-model="payload.actual_end_date"
            :default-value="defaultValue"
            input-class="form-control-fix w-100 pl-2"
            placeholder="Выберите дату и время"
            type="datetime"
            value-type="YYYY-MM-DD HH:mm"
            :format="DATE_TIME_FORMAT"
          />
        </div>
        <div class="form-group">
          <label class="form-label">Категория утечки</label>
          <v-select v-model="payload.category"
                    :options="leak_categories"
                    :reduce="category => category.id"
                    :get-option-label="(option) => option.name"
                    placeholder="Выберите категорию" />
        </div>
        <div v-if="payload.category"
             class="form-group">
          <label class="form-label">Тип утечки</label>
          <v-select v-model="payload.type"
                    :options="getLeakTypes()"
                    :reduce="type => type.id"
                    :get-option-label="(option) => option.name"
                    placeholder="Выберите тип" />
        </div>
        <div v-if="payload.type === 2"
             class="form-group">
          <label class="form-label">Ширина, мм</label>
          <input v-model="payload.width"
                 type="text"
                 class="form-control form-control-fix"
                 placeholder="Введите ширину">
        </div>
        <div v-if="payload.type === 2"
             class="form-group">
          <label class="form-label">Длина, мм</label>
          <input v-model="payload.height"
                 type="text"
                 class="form-control form-control-fix"
                 placeholder="Введите длину">
        </div>
        <div v-if="payload.type === 3 || payload.type === 4"
             class="form-group">
          <label class="form-label">Диаметр, мм</label>
          <input v-model="payload.diameter"
                 type="text"
                 class="form-control form-control-fix"
                 placeholder="Введите диаметр">
        </div>
        <div v-if="payload.type === 1"
             class="form-group">
          <label class="form-label">Кол-во свищей</label>
          <input v-model="payload.wormholes_count"
                 type="text"
                 class="form-control form-control-fix"
                 placeholder="Введите количество свищей">
        </div>
        <div class="form-group">
          <label class="form-label">Давление, м</label>
          <input v-model="payload.pressure"
                 type="text"
                 class="form-control form-control-fix"
                 placeholder="Давление">
        </div>
        <div class="modal-divider" />
        <div class="row">
          <div class="col-12">
            <button
              type="submit"
              class="btn custom-btn-blue w-100"
            >
              Добавить
            </button>
          </div>
        </div>
      </div>
    </form>
  </b-modal>
</template>

<script>
import DatePicker from "vue2-datepicker";
import {mapGetters} from "vuex";
import notification from "@/mixins/notification";

export default {
  name: "AddLeakForm",
  components: {DatePicker},
  mixins: [notification],
  data() {
    return {
      defaultValue: new Date(),
      payload: {
        actual_start_date: null,
        actual_end_date: null,
        category: null,
        type: null,
        width: null,
        height: null,
        diameter: null,
        wormholes_count: null,
        pressure: process.env.VUE_APP_DEFAULT_PRESSURE
      },
      leak_categories: [
          {id: 1, name: 'Технологический сброс'},
          {id: 2, name: 'Аварийная утечка'}
      ]
    }
  },
  computed: {
    ...mapGetters({
      issueId: 'issue/getIssueId'
    }),
    DATE_TIME_FORMAT: () => process.env.VUE_APP_BASE_DATE_TIME_FORMAT,
    getLeakTypes: function () {
      return () => {
        if (this.payload.category === 1) {
          return [{id: 4, name: 'Гидрант'}]
        } else if (this.payload.category === 2) {
          return [
            {id: 1, name: 'Свищ'},
            {id: 2, name: 'Трещина'},
            {id: 3, name: 'Отверстие'}
          ]
        } else return []
      }
    }
  },
  methods: {
    submitForm() {
      this.$store.dispatch('leaks/createLeak', {
        issue_id: this.issueId,
        payload: this.payload
      })
          .then(() => {
            this.$store.dispatch('leaks/getLeaks', this.issueId);
            this.$store.dispatch('history/getIssueHistory', this.issueId);
            this.$bvModal.hide('add-leak');
            this.payload = {
              actual_start_date: null,
              actual_end_date: null,
              category: null,
              type: null,
              width: null,
              height: null,
              diameter: null,
              wormholes_count: null,
              pressure: 45
            };
            this.toast('Утечка добавлена', 'success');
          })
          .catch(error => {
            this.alert('Ошибка', error, 'error');
          })
    }
  }
}
</script>

<style scoped>

</style>