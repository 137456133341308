<template>
  <b-modal
    id="modal-sensor"
    ref="modal-sensor"
    centered
    hide-header
    hide-footer
    @shown="fillFields()"
    @hidden="clearFields()"
  >
    <div class="col-12">
      <div class="custom-modal-header">
        <div class="custom-modal-title">
          Датчик
        </div>
        <img
          alt="Закрыть"
          class="custom-modal-close"
          src="@/assets/img/icons/close.svg"
          @click="$bvModal.hide('modal-sensor')"
        >
      </div>
      <div class="modal-divider"/>
    </div>
    <form @submit.prevent="checkType()">
      <div class="col-12 mb-2">
        <div class="form-group">
          <label class="form-label">Наименование датчика</label>
          <input
            v-model="payload.sensor_name"
            type="text"
            class="form-control form-control-fix"
            placeholder="Наименование"
            required
          >
        </div>
        <div class="form-group">
          <label class="form-label">Краткое наименование датчика</label>
          <input
            v-model="payload.sensor_short_name"
            type="text"
            class="form-control form-control-fix"
            placeholder="Краткое наименование"
            required
          >
        </div>
        <div class="form-group">
          <label class="form-label">Единицы измерения</label>
          <input
            v-model="payload.sensor_measure"
            type="text"
            class="form-control form-control-fix"
            placeholder="Единицы измерения"
            required
          >
        </div>
        <div class="form-group">
          <label class="form-label">Минимальная величина</label>
          <input
            v-model.number="payload.min_value"
            type="text"
            class="form-control form-control-fix"
            placeholder="Минимальная величина"
            required
          >
        </div>
        <div class="form-group">
          <label class="form-label">Максимальная величина</label>
          <input
            v-model.number="payload.max_value"
            type="text"
            class="form-control form-control-fix"
            placeholder="Максимальная величина"
            required
          >
        </div>
        <div class="form-group">
          <label class="form-label">Множитель</label>
          <input
            v-model="payload.factor"
            type="text"
            class="form-control form-control-fix"
            placeholder="Множитель"
            required
          >
        </div>
        <div class="form-group">
          <label class="form-label">Знаков после запятой</label>
          <input
            v-model="payload.decimal"
            type="text"
            class="form-control form-control-fix"
            placeholder="Знаков после запятой"
            required
          >
        </div>
        <div class="form-group">
          <label class="form-label">Идентификатор OPC</label>
          <input
            v-model="payload.opc_node_name"
            type="text"
            class="form-control form-control-fix"
            placeholder="Идентификатор OPC"
          >
        </div>
        <div class="form-group">
          <div class="custom-control custom-switch">
            <input
              id="avg"
              v-model="payload.no_avg"
              type="checkbox"
              class="custom-control-input"
            >
            <label
              class="custom-control-label"
              for="avg"
            >Не усреднять</label>
          </div>
        </div>
        <div class="modal-divider"/>
        <button
          type="submit"
          class="btn custom-btn-blue w-100">
          Сохранить
        </button>
      </div>
    </form>
  </b-modal>
</template>

<script>
import {showToast, showAlert} from "@/utils/notification";
import {mapState} from "vuex";

export default {
  name: "DeviceSensorsForm",
  props: {
    sensor: {
      required: false
    }
  },
  data() {
    return {
      payload: {
        device_id: null,
        sensor_name: null,
        sensor_short_name: null,
        sensor_measure: null,
        min_value: null,
        max_value: null,
        factor: null,
        decimal: null,
        no_avg: false,
        opc_node_name: null
      },
      type: 'create'
    }
  },
  computed: {
    ...mapState({
      deviceId: state => state.networkObjectPage.deviceId,
      currentSensor: state => state.networkObjectPage.currentDeviceSensor
    })
  },
  methods: {
    fillFields() {
      if (this.currentSensor) {
        this.type = 'update';

        this.payload = {
          sensor_name: this.currentSensor.sensor_name,
          sensor_short_name: this.currentSensor.sensor_short_name,
          sensor_measure: this.currentSensor.sensor_measure,
          min_value: this.currentSensor.min_value,
          max_value: this.currentSensor.max_value,
          factor: this.currentSensor.factor,
          decimal: this.currentSensor.decimal,
          no_avg: this.currentSensor.no_avg || 0,
          opc_node_name: this.currentSensor.opc_node_name
        }
      } else this.type = 'create';
    },
    checkType() {
      if (this.type === 'create') {
        this.createSensor();
      } else if (this.type === 'update') {
        this.updateSensor();
      }
    },
    createSensor() {
      this.payload.device_id = this.deviceId;

      this.$store.dispatch('sensor/createSensor', this.payload)
          .then(() => {
            this.$bvModal.hide('modal-sensor')
            this.$store.dispatch('networkObjectPage/getNetworkObjectDevices', {
              construction_id: this.$route.params.id
            });
            showToast('Датчик создан', 'success')
          })
          .catch(error => showAlert('Ошибка', error));
    },
    updateSensor() {
      this.payload.device_id = this.deviceId;

      this.$store.dispatch('sensor/updateSensor', {
        sensorId: this.currentSensor.id,
        payload: this.payload
      })
          .then(() => {
            this.$bvModal.hide('modal-sensor')
            this.$store.dispatch('networkObjectPage/getNetworkObjectDevices', {
              construction_id: this.$route.params.id
            });
            showToast('Датчик обновлен', 'success')
          })
          .catch(error => showAlert('Ошибка', error));
    },
    clearFields() {
      this.payload = {
        sensor_name: null,
        sensor_short_name: null,
        sensor_measure: null,
        min_value: null,
        max_value: null,
        factor: null,
        decimal: null,
        no_avg: false,
        opc_node_name: null
      }
    }
  }
}
</script>

<style scoped>

</style>