<template>
  <b-modal
    id="consumption-rate-form"
    centered
    hide-header
    hide-footer
    @shown="checkType()"
    @hidden="clearFields()"
  >
    <div class="col-12">
      <div class="custom-modal-header">
        <div class="custom-modal-title">
          Добавить норму расходования материала
        </div>
        <img
          class="custom-modal-close"
          alt="x"
          src="@/assets/img/icons/close.svg"
          @click="$bvModal.hide('consumption-rate-form')"
        >
      </div>
      <div class="modal-divider" />
    </div>
    <form @submit.prevent="submitForm()">
      <div class="col-12 mb-2">
        <div class="form-group">
          <label class="form-label">Расходный материал</label>
          <v-select
            v-model="payload.consumable_id"
            :options="consumables"
            :reduce="consumable => consumable.id"
            :get-option-label="(option) => option.name"
            placeholder="Выберите расходный материал"
          />
        </div>
        <div class="form-group">
          <label class="form-label">Количество</label>
          <input
            v-model="payload.amount"
            type="number"
            class="form-control form-control-fix"
            placeholder="Введите количество"
          >
        </div>
        <div class="modal-divider" />
        <div class="row">
          <div class="col-12">
            <button
              type="submit"
              class="btn custom-btn-blue w-100"
            >
              Добавить
            </button>
          </div>
        </div>
      </div>
    </form>
  </b-modal>
</template>

<script>
import {mapState} from "vuex";
import {showToast, showAlert} from "@/utils/notification";

export default {
  name: "WorkConsumptionRatesForm",
  props: {
    workConsumptionRate: {
      required: false
    }
  },
  data() {
    return {
      payload: {
        consumable_id: null,
        amount: null
      }
    }
  },
  computed: {
    ...mapState({
      consumables: state => state.consumables.consumables
    })
  },
  methods: {
    checkType() {
      if (this.workConsumptionRate) {
        this.payload = {
          consumable_id: this.workConsumptionRate.consumable.id,
          amount: this.workConsumptionRate.amount
        }
      }
    },
    clearFields() {
      this.payload = {
        consumable_id: null,
        amount: null
      }
    },
    submitForm() {
      if (this.workConsumptionRate) {
        this.$store.dispatch('workConsumptionRates/updateWorkConsumptionRate', {
          workId: this.$route.params.id,
          rateId: this.workConsumptionRate.id,
          payload: this.payload
        })
            .then(() => {
              showToast('Норма расходования материала обновлена', 'success');
              this.$store.dispatch('workConsumptionRates/getWorkConsumptionRates', this.$route.params.id);
              this.$bvModal.hide('consumption-rate-form');
            })
            .catch(error => showAlert('Ошибка', error))
      } else {
        this.$store.dispatch('workConsumptionRates/createWorkConsumptionRate', {
          workId: this.$route.params.id,
          payload: this.payload
        })
            .then(() => {
              showToast('Норма расходования материала добавлена', 'success');
              this.$store.dispatch('workConsumptionRates/getWorkConsumptionRates', this.$route.params.id);
              this.$bvModal.hide('consumption-rate-form');
            })
            .catch(error => showAlert('Ошибка', error))
      }
    }
  }
}
</script>

<style scoped>

</style>