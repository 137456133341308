import * as API from './API';
import Vehicle from "@/models/transport/Vehicle";
import {getError} from "@/utils/helpers";

export default {
    getAllAutos(filterParams) {
        return new Promise((resolve, reject) => {
            API.apiClient.get(`/autos/all`, {params: filterParams})
                .then(response => resolve(response.data.data.map(item => new Vehicle(
                    item.id,
                    item.type,
                    item.attributes,
                    localStorage.getItem('hideListAutos') ?
                        !JSON.parse(localStorage.getItem('hideListAutos')).includes(item.id) : false,
                    localStorage.getItem('hideMapAutos') ?
                        !JSON.parse(localStorage.getItem('hideMapAutos')).includes(item.id) : false,
                    localStorage.getItem('fixAutoMap') ?
                        JSON.parse(localStorage.getItem('fixAutoMap')).includes(item.id) : false
                ))))
                .catch(error => reject(getError(error)))
        })
    },
    getAutos(page) {
        return new Promise((resolve, reject) => {
            API.apiClient.get(`/autos?page[number]=${page}&page[size]=10`)
                .then(response => {
                    resolve(
                        {
                            autos: response.data.data.map(item => new Vehicle(
                                item.id,
                                item.type,
                                item.attributes)),
                            meta: response.data.meta
                        }
                    )
                })
                .catch(error => reject(getError(error)));
        })
    },
    getAuto(id) {
        return API.apiClient.get(`/autos/${id}`);
    },
    createAuto(payload) {
        return API.apiClient.post(`/autos`, payload);
    },
    updateAuto(id, payload) {
        return API.apiClient.patch(`/autos/${id}`, payload);
    },
    deleteAuto(id) {
        return API.apiClient.delete(`/autos/${id}`);
    },
    searchTransportDataByIds() {
        return API.apiClient.get('/transport/data/getCoords');
    },
    doYouKnowTheWay(from, to, id) {
        return API.apiClient.get(`/transport/data/doYouKnowTheWay/${id}`, {params: {from: from, to: to}})
    },
    getWorkDayStat() {
        return API.apiClient.get(`/transport/data/workDayStat`);
    },
    sendVehicleToRepair(vehicleId) {
        return API.apiClient.post(`/autos/${vehicleId}/send-for-repair`);
    },
    makeVehicleAvailable(vehicleId) {
        return API.apiClient.post(`/autos/${vehicleId}/make-available`);
    }
}