<template>
  <div class="col-md-12 mb-3">
    <div class="custom-card">
      <div class="card-body">
        <div class="d-flex">
          <dadata-suggestions
            v-model="address"
            class="form-control form-control-fix mr-3"
            type="ADDRESS"
            @change="setAddress"
            placeholder="Введите адрес"
          />
          <button @click="clearFilter()"
                  class="btn custom-btn-blue-outline reset-filter-btn">
            <icon-reset />
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import DadataSuggestions from "vue-dadata-suggestions";
import IconReset from "@/components/Icons/IconReset";
import IssueState from "@/models/serviceDesk/IssueState";

Vue.use(DadataSuggestions, {
  token: process.env.VUE_APP_DADATA_API_KEY,
  geoLocation: [{
    "kladr_id": process.env.VUE_APP_DADATA_KLADR_ID
  }]
});
export default {
  name: "AppealsPageIssuesFilter",
  components: {IconReset},
  data() {
    return {
      filter: {
        address_region: null,
        address_city: null,
        address_settlement: null,
        address_street: null,
        address_house: null,
        address_block: null,
        address_flat: null
      },
      address: null
    }
  },
  methods: {
    submitFilter() {
      this.$store.commit('issues/SET_FILTER_PARAMS', {
        issue_states: [
          IssueState.NEW,
          IssueState.APPOINTED,
          IssueState.ACCEPTED,
          IssueState.IN_WORK,
          IssueState.REJECTED
        ].join(),
        ...this.filter
      });

      this.$store.dispatch('issues/getIssues', 1);
    },
    clearFilter() {
      this.address = null;
      this.filter = {
        address_region: null,
        address_city: null,
        address_settlement: null,
        address_street: null,
        address_house: null,
        address_block: null,
        address_flat: null
      }
      this.$store.commit('issues/SET_FILTER_PARAMS', {
        issues_states: [
          IssueState.NEW,
          IssueState.APPOINTED,
          IssueState.ACCEPTED,
          IssueState.IN_WORK,
          IssueState.REJECTED
        ].join()
      })
      this.$store.dispatch('issues/getIssues', 1);
    },
    setAddress(i) {
      this.filter.address_region = i.data.region;
      this.filter.address_city = i.data.city;
      this.filter.address_settlement = i.data.settlement_with_type;
      this.filter.address_street = i.data.street_with_type;
      this.filter.address_house = i.data.house;
      this.filter.address_block = i.data.block;
      this.filter.address_flat = i.data.flat;

      this.submitFilter()
    }
  }
}
</script>

<style scoped>

</style>