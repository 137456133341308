<template>
  <div class="custom-card">
    <div class="card-body">
      <button v-if="!task.department"
              @click="$bvModal.show('appoint-department')"
              class="btn custom-btn-blue-outline-xs mr-1 mb-1">Назначить подразделение</button>

      <button v-if="!task.brigade && task.department"
              @click="$bvModal.show('assign-brigade')"
              class="btn custom-btn-blue-outline-xs mr-1 mb-1">Назначить бригаду</button>

      <button v-if="task.task.state.id === TASK_STATE.NEW"
              @click="doTaskAction('task/cancelTask', 'Задание отменено')"
              class="btn custom-btn-blue-outline-xs mr-1 mb-1">Отменить</button>

      <button v-if="task.task.state.id === TASK_STATE.PLANNED"
              @click="doTaskAction('task/startTask', 'Задание переведено в работу')"
              class="btn custom-btn-blue-outline-xs mr-1 mb-1">В работу</button>

      <button v-if="task.task.state.id === TASK_STATE.IN_WORK"
              @click="doTaskAction('task/suspendTask', 'Задание приостановлено')"
              class="btn custom-btn-blue-outline-xs mr-1 mb-1">Приостановить</button>

      <button v-if="task.task.state.id === TASK_STATE.SUSPENDED"
              @click="doTaskAction('task/resumeTask', 'Задание возобновлено')"
              class="btn custom-btn-blue-outline-xs mr-1 mb-1">Возобновить</button>

      <button v-if="task.task.state.id === TASK_STATE.IN_WORK"
              @click="doTaskAction('task/completeTask', 'Задание завершено')"
              class="btn custom-btn-blue-outline-xs mr-1 mb-1">Завершить</button>
    </div>
    <assign-brigade v-if="!task.brigade && task.department" />
    <appoint-department-form />
  </div>
</template>

<script>
import AssignBrigade from "@/components/ServiceDesk/Tasks/ActionsForms/AssignBrigade";
import {mapGetters} from "vuex";
import notification from "@/mixins/notification";
import AppointDepartmentForm from "@/components/ServiceDesk/Tasks/ActionsForms/AppointDepartmentForm";
import TaskState from "@/models/serviceDesk/TaskState";

export default {
  name: "TaskActions",
  components: {
    AppointDepartmentForm,
    AssignBrigade
  },
  mixins: [notification],
  computed: {
    ...mapGetters({
      task: 'task/getTask',
      taskId: 'task/getTaskId'
    }),
    TASK_STATE: () => TaskState
  },
  methods: {
    doTaskAction(action, successMessage) {
      this.$store.dispatch(action, this.taskId)
          .then(() => {
            this.$store.dispatch('task/getTask', this.taskId);
            this.$store.dispatch('history/getTaskHistory', this.taskId);
            this.toast(successMessage, 'success');
          })
          .catch(error => this.alert('Ошибка', error, 'error'));
    }
  }
}
</script>

<style scoped>

</style>