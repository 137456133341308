<template>
  <svg
    :width="width"
    :height="height"
    viewBox="0 0 9 9"
    :fill="iconColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4.01705 6.31433L8.09015 2.24124C8.46839 1.87836 8.46843 1.22515
    8.09015 0.862261C7.70996 0.482045 7.09133 0.482045 6.71115 0.862261L2.63806 4.93536L4.01705 6.31433Z" />
    <path
      d="M3.4356 2.98548L3.63578 3.18564L4.00846 2.81295L3.80829
    2.61278L4.33008 2.09101L2.23908 0L0.822815 1.41624L2.91382 3.50725L3.4356 2.98548Z" />
    <path
      d="M6.82118 4.58211L6.29939 5.10388L6.12558 4.93005L5.75288
    5.30275L5.9267 5.47658L5.40491 5.99835L7.58367 8.17712L8.99995 6.76086L6.82118 4.58211Z" />
    <path
      d="M4.112 8.99921C1.90214 9.0449 -0.0448752 7.09779 0.000787398
    4.888H0.527866C0.527866 6.86429 2.1357 8.47213 4.112 8.47213V8.99921Z" />
    <path
      d="M4.11202 7.57265C2.6317 7.57265 1.42737 6.36831 1.42737
    4.888H1.95445C1.95445 6.07768 2.92233 7.04557 4.11202 7.04557V7.57265Z" />
  </svg>
</template>

<script>
export default {
  name: "IconSatellite",
  props: {
    width: {
      type: [Number, String],
      default: 18
    },
    height: {
      type: [Number, String],
      default: 18
    },
    iconColor: {
      type: String,
      default: '#97ACB5'
    }
  }
}
</script>

<style scoped>

</style>