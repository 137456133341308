import {getError} from "@/utils/helpers";
import BalanceGroupService from "@/services/BalanceGroupService";

export const namespaced = true;

function setPaginatedBalanceGroups(commit, response) {
    commit("SET_BALANCE_GROUP", response.data.data);
    commit("SET_META", response.data.meta);
    commit("SET_LINKS", response.data.links);
    commit("SET_LOADING", false);
}

export const state = {
    balanceGroups: [],
    meta: null,
    links: null,
    loading: false,
    error: null
};

export const mutations = {
    SET_BALANCE_GROUP(state, balanceGroups) {
        state.balanceGroups = balanceGroups;
    },
    SET_META(state, meta) {
        state.meta = meta;
    },
    SET_LINKS(state, links) {
        state.links = links;
    },
    SET_LOADING(state, loading) {
        state.loading = loading;
    },
    SET_ERROR(state, error) {
        state.error = error;
    },
    DELETE_BALANCE_GROUP(state, balanceGroup_id) {
        let index = state.balanceGroups.findIndex(balanceGroup => balanceGroup.id == balanceGroup_id);
        state.balanceGroups.splice(index, 1);
    }
};

export const actions = {
    getBalanceGroups({commit}, page) {
        commit("SET_LOADING", true);
        BalanceGroupService.getBalanceGroups(page)
            .then(response => {
                setPaginatedBalanceGroups(commit, response);
            })
            .catch(error => {
                commit("SET_LOADING", false);
                commit("SET_ERROR", getError(error));
            });
    },
    getAllBalanceGroups({commit}) {
        commit("SET_LOADING", true);
        BalanceGroupService.getAllBalanceGroups()
            .then(response => {
                commit("SET_BALANCE_GROUP", response.data.data);
            })
            .catch(error => {
                commit("SET_LOADING", false);
                commit("SET_ERROR", getError(error));
            });
    },
    paginateBalanceGroups({commit}, page) {
        commit("SET_LOADING", true);
        BalanceGroupService.paginateBalanceGroup(page)
            .then(response => {
                setPaginatedBalanceGroups(commit, response);
            })
            .catch(error => {
                commit("SET_LOADING", false);
                commit("SET_ERROR", getError(error));
            });
    }
};

export const getters = {
    getBalanceGroup: state => id => {
        return state.balanceGroups.find(BalanceGroup => BalanceGroup.id == id);
    },
    balanceGroups: state => {
        return state.balanceGroups;
    },
    getActiveBalanceGroups: state => {
        return state.balanceGroups.filter(balanceGroup => balanceGroup.attributes.active == true)
    },
    getBalanceGroupsByZones: state => {
        return state.balanceGroups.filter(
            balanceGroups => balanceGroups.attributes.balance_zone == true && balanceGroups.attributes.active == true
        )
    },
    getBalanceGroupsForTotal: state => {
        return state.balanceGroups.filter(
            balanceGroups => balanceGroups.attributes.balance_zone == false && balanceGroups.attributes.active == true
        )
    },
    getName: state => id => {
        if (state.balanceGroups != null) {
            let f = state.balanceGroups.find(balanceGroups => balanceGroups.id == id);
            if (f != null) {
                return f.attributes.name;
            } else {
                return 'Не найдено';
            }
        } else {
            return 0;
        }
    },
    meta: state => {
        return state.meta;
    },
    links: state => {
        return state.links;
    },
    loading: state => {
        return state.loading;
    },
    error: state => {
        return state.error;
    }
};