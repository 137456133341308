import {saveAs} from 'file-saver'
import moment from "moment";
import store from "@/store";

export default {
    excelExport() {
        const ExcelJS = require('exceljs');
        const wb = new ExcelJS.Workbook();

        const ws = wb.addWorksheet('Суточный отчёт ЕДДС');

        ws.columns = [
            {header: 'Группа', key: 'group', width: 17},
            {header: 'Тип', key: 'type', width: 10},
            {header: '#', key: 'id', width: 4},
            {header: 'Открыта', key: 'opened', width: 14},
            {header: 'Закрыта', key: 'closed', width: 14},
            {header: 'Адрес', key: 'address', width: 23},
            {header: 'Отключения', key: 'shutdowns', width: 31},
            {header: 'Что сделано', key: 'department', width: 15},
            {header: '', key: 'tasks', width: 28}
        ]
        ws.mergeCells('H1', 'I1');

        ws.addRow(['', '', '', '', '', '', '', 'Подразделение', 'Задания'])
        ws.mergeCells('A1', 'A2');
        ws.mergeCells('B1', 'B2');
        ws.mergeCells('C1', 'C2');
        ws.mergeCells('D1', 'D2');
        ws.mergeCells('E1', 'E2');
        ws.mergeCells('F1', 'F2');
        ws.mergeCells('G1', 'G2');

        store.getters['serviceDeskCategories/getCategoryGroups'].forEach(group => {
            const issuesByGroup = store.getters['reportsData/getIssuesByCategoryGroup'](group.id);

            if (issuesByGroup.length) {
                store.getters['issueTypes/getIssueTypes'].forEach(type => {
                    const issuesByGroupAndType = issuesByGroup.filter(item => {
                        return item.type_id ? item.type_id === type.id : false
                    })

                    if (issuesByGroupAndType.length) {
                        createIssuesRowGroups(issuesByGroupAndType, group.name, type.name)
                    }
                })

                const issuesWithoutCategory = store.getters['reportsData/getIssuesWithoutCategory'];
                if (issuesWithoutCategory.length) {
                    store.getters['issueTypes/getIssueTypes'].forEach(type => {
                        const issuesByTypeAndNullCategory = issuesWithoutCategory.filter(item => {
                            return item.type_id ? item.type_id === type.id : false
                        })

                        if (issuesByTypeAndNullCategory.length) {
                            createIssuesRowGroups(issuesByTypeAndNullCategory, group.name, type.name)
                        }
                    })
                }
            }
        })

        const typeCol = ws.getColumn('type');
        typeCol.eachCell(cell => cell.alignment = {vertical: "middle"})

        const groupCol = ws.getColumn('group');
        groupCol.eachCell(cell => cell.alignment = {vertical: "middle"})

        const idCol = ws.getColumn('id');
        idCol.eachCell(cell => cell.alignment = {vertical: "middle"})

        const openedCol = ws.getColumn('opened');
        openedCol.eachCell(cell => cell.alignment = {vertical: "middle"})

        const closedCol = ws.getColumn('closed');
        closedCol.eachCell(cell => cell.alignment = {vertical: "middle"})

        const addressCol = ws.getColumn('address');
        addressCol.eachCell({includeEmpty: true}, cell => {
            cell.alignment = {wrapText: true, vertical: "middle"}
        });

        const shutdownsCol = ws.getColumn('shutdowns');
        shutdownsCol.eachCell(cell => cell.alignment = {vertical: "middle", wrapText: true});

        const departmentCol = ws.getColumn('department');
        departmentCol.eachCell(cell => cell.alignment = {wrapText: true, vertical: "top"});

        const tasksCol = ws.getColumn('tasks');
        tasksCol.eachCell({includeEmpty: true}, cell => cell.alignment = {wrapText: true});

        ws.getRow(1).eachCell(cell => cell.alignment = {horizontal: "center", vertical: "middle"});
        ws.getRow(2).eachCell(cell => cell.alignment = {horizontal: "center", vertical: "middle"});

        function createIssuesRowGroups(issues, groupName, typeName) {
            let firstIssueRowNumber, lastIssueRowNumber;
            firstIssueRowNumber = ws.lastRow._number + 1;

            issues.forEach(issue => {

                ws.addRow({
                    group: groupName,
                    type: typeName,
                    id: issue.id,
                    opened: formatDate(issue.created_at),
                    closed: formatDate(issue.closed_at),
                    address: issue.getFullAddress(),
                    shutdowns: getShutdownsRow(issue.shutdowns),
                    department: getExecutorRow(issue.tasks[0]),
                    tasks: getTaskRow(issue.tasks[0], issue.emergencyElements)
                })

                const filteredTasks = issue.tasks.filter((task, index) => index !== 0);
                if (filteredTasks.length) {
                    filteredTasks.forEach(task => {
                        ws.addRow({
                            department: getExecutorRow(task),
                            tasks: getTaskRow(task, issue.emergencyElements)
                        })
                    })
                }
            })

            lastIssueRowNumber = ws.lastRow._number;
            mergeIssueCells(firstIssueRowNumber, lastIssueRowNumber);
        }

        function getTaskRow(task, emergencyElements) {
            const taskArr = [
                `${task.type_label}`,
                `${formatDate(task.actual_start_date)} - ${formatDate(task.actual_end_date)}`
            ]

            let elementRows = [];
            if (task.works.length) {
                elementRows = emergencyElements.map(element => {
                    const worksByElementId = task.works.filter(work => {
                        return work.element ? work.element.id === element.id : false
                    })

                    if (worksByElementId.length) {
                        let row = `${element.element_type.name} \n`

                        row += element.features.map(feature => {
                            return `${feature.feature.name}: ${feature.value}${feature.feature.unit} \n`
                        }).join('')

                        if (element.damageName) row += `${element.damageName} \n`

                        row += 'Работы: \n'
                        row += worksByElementId.map((work, index) => {
                            return `${index + 1}. ${work.name}`
                        }).join('\n');

                        return row;
                    }
                })

                const worksWithoutElement = task.works.filter(work => !work.element);
                if (worksWithoutElement.length) {
                    elementRows.push('Без аварийного элемента:');
                    elementRows.push(worksWithoutElement.map((work, index) => {
                        return `${index + 1}. ${work.name}`
                    }).join('\n'))
                }

            }

            return [...taskArr, ...elementRows, ''].join('\n')
        }

        function formatDate(date) {
            if (date) return moment(date).format(process.env.VUE_APP_BASE_DATE_TIME_FORMAT)
            else return '-'
        }

        function getShutdownsRow(shutdowns) {
            if (shutdowns) {
                const shutdownsString = shutdowns.map(shutdown => {
                    return `Дата начала: ${formatDate(shutdown.actual_start_date)} \n` +
                        `Дата окончания: ${formatDate(shutdown.actual_end_date)} \n` +
                        `Комментарий: ${shutdown.comment}`
                    })

                return shutdownsString.join('\n\n');
            } else return '-'
        }

        function getExecutorRow(task) {
            return `${task.department ? task.department.name : '-'} \n` + `${task.brigade_name}`
        }

        function mergeIssueCells(firstRowNumber, lastRowNumber) {
            ['A', 'B', 'C', 'D', 'E', 'F', 'G'].forEach(cell => {
                ws.mergeCells(`${cell}${firstRowNumber}`, `${cell}${lastRowNumber}`)
            })
        }

        const period = store.getters['reportsData/getReportDate']
            .map(date => moment(date).format('DD.MM.YY'))

        wb.xlsx.writeBuffer().then(function (data) {
            let blob = new Blob([data], {type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"})
            saveAs(blob, `Суточный отчёт ЕДДС ${period.join('-')}.xlsx`)
        })
    }
}