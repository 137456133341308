<template>
  <div class="row">
    <div class="col-12 col-lg-5">
      <div class="tab mb-0">
        <b-tabs v-model="tabIndex"
                content-class="tabs-custom"
                active-nav-item-class="active-b-tab">
          <div class="tab-content-custom scroll">
            <standard-building-filter />
            <standard-building-filter2 />
            <standard-building-filter-result />
          </div>
        </b-tabs>
      </div>
    </div>
    <div class="col-12 col-lg-7">
      <standard-building-map />
    </div>
  </div>
</template>

<script>
import StandardBuildingFilter from "@/components/Billing/StandardBuildingFilter";
import StandardBuildingFilterResult from "@/components/Billing/StandardBuildingFilterResult";
import StandardBuildingMap from "@/components/Billing/StandardBuildingMap";
import StandardBuildingFilter2 from "@/components/Billing/StandardBuildingFilter2";

export default {
  name: "StandardBuildingPage",
  components: {StandardBuildingFilter2, StandardBuildingMap, StandardBuildingFilterResult, StandardBuildingFilter},
  computed: {
    tabIndex: {
      get() {
        return this.$store.getters['standardBuilding/tabIndex']
      },
      set(index) {
        this.$store.commit('standardBuilding/SET_TAB_INDEX', index)
      }
    }
  }
}
</script>

<style scoped>
.scroll {
  overflow-y: scroll;
  height: calc(100vh - 120px);
}

.tab-content-custom {
  padding: 1.25rem;
  background-color: #F9FDFF;
}

.tab-content-custom::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  background-color: #C7D3D9;
  border-radius: 0.2em;
}

.tab-content-custom::-webkit-scrollbar-thumb {
  background-color: #97ACB5;
  border-radius: 0.2em;
}
</style>