<template>
  <svg
    :width="width"
    :height="height"
    viewBox="0 0 25 22"
    :fill="iconColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14.9211 19.3829H10.0459C9.15838 19.3829 9.15838 18.0757 10.0459
    18.0757H14.9211C15.8086 18.0757 15.8086 19.3829 14.9211 19.3829Z" />
    <path
      d="M18.4466 22H6.51999C5.6325 22 5.6325 20.6928 6.51999
    20.6928H18.4466C19.3341 20.6928 19.3341 22 18.4466 22Z" />
    <path
      d="M11.8132 0C8.85744 0.155993 6.19586 1.32057 4.14819 3.11928L6.70368
    5.61024C8.06531 4.45641 9.8096 3.67441 11.8132 3.52975V0Z" />
    <path
      d="M3.62387 11.515C3.76632 9.61086 4.5361 7.9237 5.75647 6.53573L3.19503
    4.03897C1.3372 6.05033 0.155862 8.58805 0 11.515H3.62387Z" />
    <path
      d="M3.61791 12.8222H0C0.140067 15.3925 1.0818 17.7553 2.60525 19.6621C2.838
    19.9532 3.28085 19.9849 3.56963 19.7461L5.30051 18.3169C5.58183 18.0845 5.60955
    17.6842 5.38693 17.3975C4.38471 16.1068 3.74874 14.5332 3.61791 12.8222Z" />
    <path
      d="M23.1914 5.86154C22.5864 6.58573 21.6885 7.65968 20.6847
    8.85795C21.1225 9.87931 21.3791 10.9922 21.3791 12.1687C21.3791 14.1455
    20.7005 15.9677 19.5579 17.4263C19.3422 17.7016 19.4033 18.0982 19.6751
    18.3213L21.3982 19.7351C21.6905 19.975 22.1382 19.9422 22.3727 19.6479C24.0195
    17.5822 25 14.987 25 12.1684C25.0003 9.82354 24.3268 7.68117 23.1914 5.86154Z" />
    <path
      d="M22.3932 4.7452C20.2013 1.98816 16.8492 0.195254 13.1541
    0.000915527V3.53299C15.4521 3.7038 17.8127 4.74782 19.4888 6.83702C19.4888
    6.83702 11.5049 12.6137 11.2209 12.8908C10.568 13.5273 10.568 14.5594 11.2209
    15.1959C11.8739 15.8323 12.9327 15.8323 13.5857 15.1959C14.0089 14.7831 22.3932
    4.7452 22.3932 4.7452ZM22.3599 4.78529C22.3601 4.78529 22.3601 4.785 22.3601 4.785L22.3599 4.78529Z" />
  </svg>
</template>

<script>
export default {
  name: "IconSpeed",
  props: {
    width: {
      type: [Number, String],
      default: 18
    },
    height: {
      type: [Number, String],
      default: 18
    },
    iconColor: {
      type: String,
      default: '#000'
    }
  }
}
</script>

<style scoped>

</style>