import * as API from './API';

export default {
    getZone(zoneId) {
        return API.apiClient.get(`/zones/${zoneId}`);
    },
    getZones(page) {
        return API.apiClient.get(`/zones?page=${page}`);
    },
    getAllZones() {
        return API.apiClient.get('/zones/all');
    },
    paginateZones(page) {
        return API.apiClient.get(`/zones?page=${page}`);
    },
    createZone(payload) {
        return API.apiClient.post(`/zones`, payload)
    },
    updateZonesAuto() {
        return API.apiClient.post('/zones/auto')
    },
    updateZone(id, payload){
        return API.apiClient.patch(`/zones/${id}`, payload);
    },
    deleteZone(id){
        return API.apiClient.delete(`/zones/${id}`);
    }
}