<template>
  <div class="row">
    <div class="col-lg-12">
      <div class="custom-card">
        <div class="card-header pb-0">
          <h4 class="table-title d-inline-block">
            Балансовые группы
          </h4>
          <router-link
            to="admin-balance-group-create"
            class="btn custom-btn-blue-outline float-right"
          >
            Добавить балансовую группу
          </router-link>
          <hr class="divider">
        </div>
        <div class="card-body">
          <div class="container-fluid">
            <div class="row">
              <balance-group-table />
              <base-pagination
                v-if="this.$store.state.balanceGroup.meta && this.$store.state.balanceGroup.meta.last_page > 1"
                path="admin-balance-groups"
                :meta="this.$store.state.balanceGroup.meta"
                :links="this.$store.state.balanceGroup.links"
                action="balanceGroup/paginateBalanceGroups"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BasePagination from "@/components/BasePagination";
import store from "@/store";
import BalanceGroupTable from "@/components/Billing/admin/BalanceGroupsList";

export default {
  name: "BalanceGroupPage",
  components: {
    BalanceGroupTable,
    BasePagination
  },
  beforeRouteEnter(to, from, next) {
    const currentPage = parseInt(to.query.page) || 1;
    store.dispatch('zone/getAllZones')
    store.dispatch('device/getAllDevices')
    store.dispatch("balanceGroup/getBalanceGroups", currentPage).then(() => {
      to.params.page = currentPage;
      next();
    });
  }
}
</script>

<style scoped>

</style>