<template>
  <div class="row">
    <div class="col-12 mb-3">
      <energy-consumption-logbook-header />
    </div>
    <div class="col-12">
      <energy-consumption-logbook />
    </div>
  </div>
</template>

<script>
import EnergyConsumptionLogbookHeader from "@/components/EnergyConsumption/EnergyConsumptionLogbookHeader";
import EnergyConsumptionLogbook from "@/components/EnergyConsumption/EnergyConsumptionLogbook";
export default {
  name: "EnergyConsumptionLogbookPage",
  components: {EnergyConsumptionLogbook, EnergyConsumptionLogbookHeader},
  mounted() {
    const currentYear = new Date().getFullYear();
    this.$store.dispatch('energyConsumptionObjects/getAllEnergyConsumptionObjectsWithMeters');
    this.$store.commit('electricMetersReadings/SET_FILTER_PARAMS', {
      year: currentYear
    });
    this.$store.dispatch('electricMetersReadings/getElectricMetersMonthReading');
  }
}
</script>

<style scoped>

</style>