<template>
  <b-modal
    id="plan-issue"
    ref="plan-issue"
    centered
    hide-header
    hide-footer
  >
    <div class="col-12">
      <div class="custom-modal-header">
        <div class="custom-modal-title">
          Запланировать заявку
        </div>
        <img
          alt="Закрыть"
          class="custom-modal-close"
          src="@/assets/img/icons/close.svg"
          @click="$bvModal.hide('plan-issue')"
        >
      </div>
      <div class="modal-divider" />
    </div>
    <form @submit.prevent="submitForm()">
      <div class="col-12 mb-2">
        <div class="form-group">
          <label class="form-label">Плановая дата начала</label>
          <date-picker
            v-model="payload.planned_start_date"
            :default-value="defaultValue"
            input-class="form-control-fix w-100 pl-2"
            placeholder="Выберите плановую дату начала"
            type="day"
            format="DD.MM.YYYY"
            value-type="YYYY-MM-DD"
          />
        </div>
        <div class="form-group">
          <label class="form-label">Плановая дата окончания</label>
          <date-picker
            v-model="payload.planned_end_date"
            :default-value="defaultValue"
            input-class="form-control-fix w-100 pl-2"
            placeholder="Выберите плановую дату окончания"
            type="day"
            format="DD.MM.YYYY"
            value-type="YYYY-MM-DD"
          />
        </div>
        <div class="modal-divider" />
        <div class="row">
          <div class="col-12">
            <button
              type="submit"
              class="btn custom-btn-blue w-100"
            >
              Запланировать
            </button>
          </div>
        </div>
      </div>
    </form>
  </b-modal>
</template>

<script>
import DatePicker from "vue2-datepicker";
import notification from "@/mixins/notification";
import {mapGetters} from "vuex";
export default {
  name: "PlanIssueForm",
  components: {DatePicker},
  mixins: [notification],
  data() {
    return {
      payload: {
        planned_start_date: null,
        planned_end_date: null
      },
      defaultValue: new Date()
    }
  },
  computed: {
    ...mapGetters({
      issue: 'issue/getIssue'
    })
  },
  methods: {
    submitForm() {
      this.$store.dispatch('issue/planIssue', this.payload)
          .then(() => {
            this.$bvModal.hide('plan-issue');
            this.$store.dispatch('issue/getIssue', this.issue.id);
            this.$store.dispatch('history/getIssueHistory', this.issue.id);
            this.toast('Заявка запланирована', 'success');
          })
          .catch(error => {
            this.alert('Ошибка', error, 'error');
          })
    }
  }
}
</script>

<style scoped>

</style>