<template>
  <div class="row">
    <div class="col-lg-12">
      <div class="custom-card">
        <div class="card-header pb-0">
          <h4 class="table-title d-inline-block">
            Картографические слои
          </h4>
          <router-link
            to="/admin-layers-create"
            class="btn custom-btn-blue-outline float-right"
          >
            Добавить слой
          </router-link>
          <hr class="divider">
        </div>
        <div class="card-body">
          <div class="container-fluid">
            <div class="row">
              <layers-table />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LayersTable from "@/components/admin/LayersList";

export default {
  name: "LayersPage",
  components: {
    LayersTable
  },
  mounted() {
    this.$store.dispatch('layer/getLayers');
  }
}
</script>

<style scoped>

</style>