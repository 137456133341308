import NetworkElementsService from "@/services/NetworkElementsService";

export const namespaced = true;

export const state = {
    elements: [],
    filterParams: null,
    loading: false,
    error: null
}

export const mutations = {
    SET_ELEMENTS(state, elements) {
        state.elements = elements;
    },
    SET_FILTER_PARAMS(state, filterParams) {
        state.filterParams = filterParams
    },
    SET_LOADING(state, loading) {
        state.loading = loading;
    },
    SET_ERROR(state, error) {
        state.error = error;
    },
    SELECT_ELEMENT(state, {newElementId, oldElementId}) {
        if (oldElementId) {
            const oldElement = state.elements.find(item => item.id === oldElementId);
            oldElement.isSelected = false;
        }

        const newElement = state.elements.find(item => item.id === newElementId);
        newElement.isSelected = true;
    }
}

export const actions = {
    getAllNetworkElements({commit, state}) {
        commit("SET_LOADING", true);
        NetworkElementsService.fetchAllNetworkElements(state.filterParams)
            .then(elements => commit("SET_ELEMENTS", elements))
            .catch(error => commit("SET_ERROR", error))
            .finally(() => commit("SET_LOADING", false));
    }
}

export const getters = {
    getNetworkElements: state => {
        return state.elements;
    },
    getNetworkElement: state => id => {
        const element = state.elements.find(element => element.id === id);
        if (element) return element;
    }
}