export default class Vehicle {
    constructor(id, type, attributes, showInList, showOnMap, fixOnMap) {
        this.id = id;
        this.type = type;
        this.attributes = attributes;
        this.showInList = showInList;
        this.showOnMap = showOnMap;
        this.fixOnMap = fixOnMap;
    }

    static getTransportTypes() {
        return [
            {id: 1, name: 'Легковая'},
            {id: 2, name: 'Грузовая'},
            {id: 3, name: 'Водовозка'},
            {id: 4, name: 'Спец. техника'}
        ]
    }

    getTypeLabel() {
        switch (this.attributes.type) {
            case 1:
                return 'Легковая'
            case 2:
                return 'Грузовая'
            case 3:
                return 'Водовозка'
            case 4:
                return 'Спец. техника'
        }
    }
}