<template>
  <div class="row">
    <div class="col-xs-12 col-lg-5 mobile-mb-20">
      <div class="tab mb-0">
        <b-tabs
          v-model="tabIndex"
          content-class="tabs-custom"
          active-nav-item-class="active-b-tab"
        >
          <div class="tab-content-custom scroll">
            <billing-buildings-filter @data-complete="filter = $event"/>
            <billing-buildings-filter-result :filter_params="filter"/>
            <billing-building-detail/>
            <billing-client-detail title="Выберите абонента в списке объекта" />
          </div>
        </b-tabs>
      </div>
    </div>
    <div class="col-xs-12 col-lg-7">
      <billing-buildings-map />
    </div>
  </div>
</template>

<script>
import BillingBuildingsFilter from "@/components/Billing/BillingBuildingsFilter";
import BillingBuildingsFilterResult from "@/components/Billing/BillingBuildingsFilterResult";
import BillingBuildingsMap from "@/components/Billing/BillingBuildingsMap";
import BillingBuildingDetail from "@/components/Billing/BillingBuildingDetail";
import notification from "@/mixins/notification";
import BillingClientDetail from "@/components/Billing/BillingClientDetail";

export default {
  name: "BillingBuildingsPage",
  components: {
    BillingClientDetail,
    BillingBuildingDetail,
    BillingBuildingsMap, BillingBuildingsFilterResult, BillingBuildingsFilter
  },
  mixins: [notification],
  data() {
    return {
      filter: null
    }
  },
  computed: {
    tabIndex: {
      get() {
        return this.$store.getters['building/getTabIndex']
      },
      set(index) {
        this.$store.commit('building/SET_TAB_INDEX', index)
      }
    }
  },
  mounted() {
    this.$root.$on("BaseMap::map-click", latlng => {
      this.getBuildingsByCoords(latlng)
    })
  },
  beforeDestroy() {
    this.$root.$off('BaseMap::map-click');
  },
  methods: {
    getBuildingsByCoords(coords) {
      let payload = {
        params: {
          distance: 20,
          latitude: coords.lat,
          longitude: coords.lng
        }
      }

      this.$store.dispatch('building/getBuildingsByCoords', payload)
          .then(() => {
            if (this.$store.state.building.buildings.length === 0) {
              this.toast('Здания не найдены', 'warning');
            } else {
              this.$store.commit('building/SET_TAB_INDEX', 1);
            }
          })
          .catch(() => {
            this.toast(this.$store.state.building.error, 'error');
          })
    }
  }
}
</script>

<style scoped>
.scroll {
  overflow-y: scroll;
  height: calc(100vh - 120px);
}

.tab-content-custom {
  padding: 1.25rem;
  background-color: #F9FDFF;
}

.tab-content-custom::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  background-color: #C7D3D9;
  border-radius: 0.2em;
}

.tab-content-custom::-webkit-scrollbar-thumb {
  background-color: #97ACB5;
  border-radius: 0.2em;
}

@media (max-width: 500px) {
  .mobile-mb-20 {
    margin-bottom: 20px;
  }
}
</style>