<template>
  <div class="row justify-content-center align-items-center fixed-height">
    <div class="col-4">
      <category-form />
    </div>
  </div>
</template>

<script>
import CategoryForm from "@/components/ServiceDesk/admin/CategoryForm";
export default {
  name: "CategoryFormPage",
  components: {CategoryForm}
}
</script>

<style scoped>

</style>