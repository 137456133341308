<template>
  <div class="custom-card">
    <div class="card-body">
      <div class="container-fluid">
        <div class="row">
          <div class="col-12">
            <form @submit.prevent="checkType">
              <div class="form-group">
                <label class="form-label">Наименование</label>
                <input
                  v-model="payload.name"
                  type="text"
                  class="form-control form-control-fix"
                  placeholder="Введите наименование"
                  required
                >
              </div>
              <button class="btn custom-btn-blue w-100">Сохранить</button>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapState} from "vuex";
import {showAlert, showToast} from "@/utils/notification";

export default {
  name: "ConsumablesForm",
  data() {
    return {
      payload: {
        name: null
      },
      type: 'create'
    }
  },
  computed: {
    ...mapState({
      consumable: state => state.consumables.consumable
    })
  },
  mounted() {
    if (this.$route.name === 'UpdateConsumable') {
      this.type = 'update';

      this.$store.dispatch('consumables/getConsumable', this.$route.params.id)
          .then(() => {
            this.payload = {
              name: this.consumable.name
            }
          })
    }
  },
  methods: {
    checkType() {
      if (this.type === 'create') this.createConsumable()
      else this.updateConsumable()
    },
    createConsumable() {
      this.$store.dispatch('consumables/createConsumable', this.payload)
          .then(() => {
            this.$router.push('/consumables-catalog');
            showToast('Расходный материал добавлен', 'success')
          })
          .catch(error => showAlert('Ошибка', error))
    },
    updateConsumable() {
      this.$store.dispatch('consumables/updateConsumable', {
        consumableId: this.$route.params.id,
        payload: this.payload
      })
          .then(() => {
            this.$router.push('/consumables-catalog');
            showToast('Расходный материал обновлён', 'success')
          })
          .catch(error => showAlert('Ошибка', error))
    }
  }
}
</script>

<style scoped>

</style>