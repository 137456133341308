<template>
  <div class="custom-card">
    <div v-if="$store.state.appeals.appeals.length"
         class="card-body">
      <div class="card-capture mb-3">
        Нераспределённые обращения
      </div>
      <table class="service-desk-table table-responsive-xxl">
        <thead class="service-desk-table-header">
          <tr>
            <td/>
            <td>#</td>
            <td colspan="2">Категория</td>
            <td>Дата</td>
            <td>Адрес</td>
            <td>Способ подачи</td>
            <td>Комментарий</td>
          </tr>
        </thead>
        <tbody class="service-desk-table-body">
          <tr v-for="appeal in appeals"
              :key="appeal.id"
              class="appeal-item">
            <td class="text-center">
              <label class="custom-control custom-checkbox mb-0">
                <input
                  :id="`appeal_${appeal.id}`"
                  type="checkbox"
                  class="custom-control-input"
                  v-model="appealsArray"
                  :value="appeal.id"
                >
                <span class="custom-control-label"/>
              </label>
            </td>
            <td>{{ appeal.id }}</td>
            <td>
              <div class="appeal-category-color"
                   :style="{'border-color': appeal.category.group_color}"/>
            </td>
            <td>{{ appeal.category.group_name }}: {{appeal.category.name}}</td>
            <td>{{ appeal.created_at | formatDateTime }}</td>
            <td>{{ appeal.getFullAddress() }}</td>
            <td>{{ appeal.getSourceLabel() }}</td>
            <td>{{ appeal.message }}</td>
          </tr>
        </tbody>
      </table>
      <div class="row">
        <div class="col-12 appeals-pagination">
          <base-pagination-links
            v-if="$store.state.appeals.meta && $store.state.appeals.meta.last_page > 1"
            path="create-issue"
            :meta="$store.state.appeals.meta"
            :payload="generatePayload()"
            action="appeals/getAppeals"
          />
        </div>
      </div>
    </div>
    <div v-else
         class="card-body">
      <div v-if="!$store.state.appeals.loading"
           class="no-result-title">Обращения не найдены</div>
      <div v-else
           class="text-center">
        <base-loader />
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import BasePaginationLinks from "@/components/BasePaginationLinks";
import BaseLoader from "@/layout/BaseLoader";

export default {
  name: "CreateIssueAppealsList",
  components: {BaseLoader, BasePaginationLinks},
  computed: {
    ...mapGetters({
      appeals: 'appeals/getAppeals',
      appealsForLink: 'appeals/getAppealsForLink'
    }),
    appealsArray: {
      get: function () {
        return this.appealsForLink;
      },
      set: function (value) {
        this.$store.commit('appeals/SET_APPEALS_FOR_LINK', value);
      }
    },
    generatePayload: function () {
      return () => {
        return {
          params: this.$store.state.appeals.filterParams,
          page: null
        }
      }
    }
  },
  mounted() {
    this.$store.dispatch('serviceDeskCategories/getAllCategories');
  }
}
</script>

<style scoped>
.appeal-category-color {
  width: 18px;
  height: 18px;
  border-radius: 9px;
  border: 2px solid;
}

.appeals-pagination {
  display: flex;
  justify-content: flex-end;
}

@media (max-width: 1199px) {
  .appeals-pagination {
    margin-bottom: 20px;
  }
}
</style>