import {getError} from "@/utils/helpers";
import RoleService from "@/services/RoleService";

export const namespaced = true;

export const state  = {
    roles: [],
    role: null,
    meta: null,
    links: null,
    loading: false,
    error: null
};

export const mutations = {
    SET_ROLES(state, roles) {
        state.roles = roles;
    },
    SET_ROLE(state, role) {
        state.role = role;
    },
    SET_META(state, meta) {
        state.meta = meta;
    },
    SET_LINKS(state, links) {
        state.links = links;
    },
    SET_LOADING(state, loading) {
        state.loading = loading;
    },
    SET_ERROR(state, error) {
        state.error = error;
    },
    DELETE_ROLE(state, role_id){
        let index = state.roles.findIndex(role => role.id == role_id);
        state.roles.splice(index, 1);
    }
};

export const actions = {
    getRoles({ commit }, page) {
        commit("SET_LOADING", true);
        RoleService.getRoles(page)
            .then(({roles, meta}) => {
                commit("SET_ROLES", roles);
                commit("SET_META", meta);
            })
            .catch(error => {
                commit("SET_ERROR", error);
            })
            .finally(() => commit("SET_LOADING", false));
    },
    getRole({commit}, roleId) {
        return new Promise((resolve, reject) => {
            RoleService.getRole(roleId)
                .then(role => {
                    commit("SET_ROLE", role);
                    resolve();
                })
                .catch(error => {
                    commit("SET_ERROR", error);
                    reject(error);
                })
        })
    },
    getAllRoles({ commit }) {
        commit("SET_LOADING", true);
        RoleService.getAllRoles()
            .then(roles => {
                commit("SET_ROLES", roles);
            })
            .catch(error => {
                commit("SET_ERROR", error);
            })
            .finally(() => commit("SET_LOADING", false));
    },
    createRole(context, payload) {
        return new Promise((resolve, reject) => {
            RoleService.createRole(payload)
                .then(() => resolve())
                .catch(error => reject(getError(error)))
        })
    },
    updateRole(context, {roleId, payload}) {
        return new Promise((resolve, reject) => {
            RoleService.updateRole(roleId, payload)
                .then(() => resolve())
                .catch(error => reject(getError(error)))
        })
    },
    deleteRole(context, roleId) {
        return new Promise((resolve, reject) => {
            RoleService.deleteRole(roleId)
                .then(() => resolve())
                .catch(error => reject(getError(error)))
        })
    },
    assignPermission(context, {roleId, permissionId}) {
        return new Promise((resolve, reject) => {
            RoleService.assignPermission(roleId, permissionId)
                .then(() => resolve())
                .catch(error => reject(getError(error)))
        })
    },
    removePermission(context, {roleId, permissionId}) {
        return new Promise((resolve, reject) => {
            RoleService.removePermission(roleId, permissionId)
                .then(() => resolve())
                .catch(error => reject(getError(error)));
        })
    }
};

export const getters = {
    getRole: state => id =>{
        return state.roles.find(role => role.id === Number.parseInt(id));
    },
    getRoleName: state => id => {
        return state.roles.find(role => role.id === id).attributes.name
    },
    getRoleIdByName: state => name => {
        return state.roles.find(role => role.attributes.name === name).id
    },
    roles: state => {
        return state.roles;
    },
    meta: state => {
        return state.meta;
    },
    links: state => {
        return state.links;
    },
    loading: state => {
        return state.loading;
    },
    error: state => {
        return state.error;
    }
}