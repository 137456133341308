import * as API from './API';
import TransportOrder from "@/models/serviceDesk/TransportOrder";
import {getError} from "@/utils/helpers";

export default {
    fetchTransportOrders(filterParams) {
        return new Promise((resolve, reject) => {
            API.apiClient.get(`/service-desk/transport-orders`, {params: filterParams})
                .then(response => resolve(response.data.data.map(item => new TransportOrder({
                    id: item.id,
                    ...item.attributes
                }))))
                .catch(error => reject(getError(error)));
        })
    },
    fetchTransportOrder(transportOrderId) {
        return new Promise((resolve, reject) => {
            API.apiClient.get(`/service-desk/transport-orders/${transportOrderId}`)
                .then(response => {
                    const {id, attributes} = response.data.data;
                    resolve(new TransportOrder({id, ...attributes}))
                })
                .catch(error => reject(getError(error)));
        })
    },
    createTransportOrder(payload) {
        return API.apiClient.post(`/service-desk/transport-orders`, payload);
    },
    acceptTransportOrder(transportOrderId, payload) {
        return API.apiClient.post(`/service-desk/transport-orders/${transportOrderId}/accept`, payload);
    },
    cancelTransportOrder(transportOrderId) {
        return API.apiClient.post(`/service-desk/transport-orders/${transportOrderId}/cancel`);
    }
}