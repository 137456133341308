<template>
  <div class="row">
    <div class="col-12">
      <div class="custom-card">
        <div class="card-body">
          <div class="container-fluid">
            <div class="row">
              <div class="col-12">
                <h4 class="table-title d-inline-block">
                  Работы
                </h4>
                <div v-if="$store.state.works.loading"
                     class="d-inline-block ml-2">
                  <base-loader />
                </div>
                <router-link to="/create-work">
                  <button class="btn custom-btn-blue float-right">
                    Добавить работу
                  </button>
                </router-link>
                <hr class="divider">
              </div>
              <div v-if="$store.state.works.works.length"
                   class="col-12">
                <works-list />
              </div>
              <div v-if="!$store.state.works.works.length && !$store.state.works.loading"
                   class="col-12 mt-2">
                <div class="no-result-title">Работы не найдены</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BaseLoader from "@/layout/BaseLoader";
import WorksList from "@/components/ServiceDesk/admin/WorksList";
export default {
  name: "WorksListPage",
  components: {WorksList, BaseLoader},
  mounted() {
    this.$store.dispatch('works/getWorks', 1);
  }
}
</script>

<style scoped>

</style>