<template>
  <div class="custom-card">
    <div class="card-body">
      <div class="row">
        <div class="col-12">
          <div class="task-card-header">
            <div class="d-flex justify-content-between align-items-center">
              <h3 class="task-card-title">Задание <span>#{{task.task.id}}</span></h3>
              <div>
                <base-badge :label="task.task.state.label"
                            :color="task.task.state.color" />
              </div>
            </div>
            <div class="task-type-label">{{task.task.type_label}}</div>
          </div>
        </div>
        <div class="col-12">
          <div class="row">
            <div v-if="task.department"
                 class="col-6 service-desk-info-block">
              <div>Назначено подразделение</div>
              <div>{{task.department.name}}</div>
            </div>
            <div v-if="task.brigade"
                 class="col-6 service-desk-info-block">
              <div>Назначена бригада</div>
              <div>{{task.brigade.name}}</div>
            </div>
          </div>
        </div>
        <div class="col-6">
          <div v-if="task.task.planned_start_date"
               class="service-desk-info-block">
            <div>Плановая дата начала</div>
            <div>
              {{ task.task.planned_start_date | formatDateTime }}
            </div>
          </div>
        </div>
        <div class="col-6">
          <div v-if="task.task.actual_start_date"
               class="service-desk-info-block">
            <div>Факт. дата начала</div>
            <div>
              {{ task.task.actual_start_date | formatDateTime }}
            </div>
          </div>
        </div>
        <div class="col-6">
          <div v-if="task.task.planned_end_date"
               class="service-desk-info-block">
            <div>Плановая дата окончания</div>
            <div>
              {{ task.task.planned_end_date | formatDateTime }}
            </div>
          </div>
        </div>
        <div class="col-6">
          <div v-if="task.task.actual_end_date"
               class="service-desk-info-block">
            <div>Факт. дата окончания</div>
            <div>
              {{ task.task.actual_end_date | formatDateTime }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import BaseBadge from "@/layout/BaseBadge";

export default {
  name: "TaskInfo",
  components: {BaseBadge},
  computed: {
    ...mapGetters({
      task: 'task/getTask'
    })
  }
}
</script>

<style scoped>
.task-type-label {
  font-size: 16px;
  font-weight: 600;
  color: #262F3D;
}
</style>