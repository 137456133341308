import { render, staticRenderFns } from "./StandardBuildingFilter2.vue?vue&type=template&id=4c7c4164&scoped=true&"
import script from "./StandardBuildingFilter2.vue?vue&type=script&lang=js&"
export * from "./StandardBuildingFilter2.vue?vue&type=script&lang=js&"
import style0 from "./StandardBuildingFilter2.vue?vue&type=style&index=0&id=4c7c4164&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4c7c4164",
  null
  
)

export default component.exports