import IssuesStatisticService from "@/services/IssuesStatisticService";

export const namespaced = true;

export const state = {
    closedIssuesCountByType: [],
    closedIssuesCountByTypeLoading: false,
    closedIssuesCountByTypeError: null,
    openedIssuesCountByGroups: [],
    openedIssuesCountByGroupsLoading: false,
    openedIssuesCountByGroupsError: null,
    closedIssuesCountByGroups: [],
    closedIssuesCountByGroupsLoading: false,
    closedIssuesCountByGroupsError: null
}

export const mutations = {
    SET_CLOSED_ISSUES_COUNT_BY_TYPE(state, issuesCount) {
        state.closedIssuesCountByType = issuesCount;
    },
    SET_CLOSED_ISSUES_COUNT_BY_TYPE_LOADING(state, loading) {
        state.closedIssuesCountByTypeLoading = loading;
    },
    SET_CLOSED_ISSUES_COUNT_BY_TYPE_ERROR(state, error) {
        state.closedIssuesCountByTypeError = error;
    },
    SET_OPENED_ISSUES_COUNT_BY_GROUPS(state, issuesCount) {
        state.openedIssuesCountByGroups = issuesCount;
    },
    SET_OPENED_ISSUES_COUNT_BY_GROUPS_LOADING(state, loading) {
        state.openedIssuesCountByGroupsLoading = loading;
    },
    SET_OPENED_ISSUES_COUNT_BY_GROUPS_ERROR(state, error) {
        state.openedIssuesCountByGroupsError = error;
    },
    SET_CLOSED_ISSUES_COUNT_BY_GROUPS(state, issuesCount) {
        state.closedIssuesCountByGroups = issuesCount;
    },
    SET_CLOSED_ISSUES_COUNT_BY_GROUPS_LOADING(state, loading) {
        state.closedIssuesCountByGroupsLoading = loading;
    },
    SET_CLOSED_ISSUES_COUNT_BY_GROUPS_ERROR(state, error) {
        state.closedIssuesCountByGroupsError = error;
    }
}

export const actions = {
    getClosedIssuesCountByType({commit}, filter) {
        commit("SET_CLOSED_ISSUES_COUNT_BY_TYPE_LOADING", true);
        return new Promise((resolve, reject) => {
            IssuesStatisticService.fetchClosedIssuesByType(filter)
                .then(data => {
                    commit("SET_CLOSED_ISSUES_COUNT_BY_TYPE", data);
                    resolve();
                })
                .catch(error => {
                    commit("SET_CLOSED_ISSUES_COUNT_BY_TYPE_ERROR", error);
                    reject();
                })
                .finally(() => commit("SET_CLOSED_ISSUES_COUNT_BY_TYPE_LOADING", false));
        })
    },
    getOpenedIssuesCountByGroups({commit}, filter) {
        commit("SET_OPENED_ISSUES_COUNT_BY_GROUPS_LOADING", true);
        return new Promise((resolve, reject) => {
            IssuesStatisticService.fetchIssuesCountByGroups(filter)
                .then(data => {
                    commit("SET_OPENED_ISSUES_COUNT_BY_GROUPS", data);
                    resolve();
                })
                .catch(error => {
                    commit("SET_OPENED_ISSUES_COUNT_BY_GROUPS_ERROR", error);
                    reject(error);
                })
                .finally(() => commit("SET_OPENED_ISSUES_COUNT_BY_GROUPS_LOADING", false));
        })
    },
    getClosedIssuesCountByGroups({commit}, filter) {
        commit("SET_CLOSED_ISSUES_COUNT_BY_GROUPS_LOADING", true);
        return new Promise((resolve, reject) => {
            IssuesStatisticService.fetchIssuesCountByGroups(filter)
                .then(data => {
                    commit("SET_CLOSED_ISSUES_COUNT_BY_GROUPS", data);
                    resolve();
                })
                .catch(error => {
                    commit("SET_CLOSED_ISSUES_COUNT_BY_GROUPS_ERROR", error);
                    reject(error);
                })
                .finally(() => commit("SET_CLOSED_ISSUES_COUNT_BY_GROUPS_LOADING", false));
        })
    }
}

export const getters = {
    getClosedIssuesCountByType: state => {
        return state.closedIssuesCountByType;
    }
}