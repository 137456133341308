<template>
  <div>
    <div class="custom-card mb-2">
      <div class="card-header d-flex align-items-center">
        <h4 class="network_object__card_title d-inline-block mr-2">
          Счётчики
        </h4>
        <div class="d-flex">
          <div
            class="network_object__control_btn"
            @click="createElectricMeter()"
          >
            <img
              src="@/assets/img/icons/plus.svg"
              alt="Добавить">
          </div>
        </div>
      </div>
      <div class="card-body">
        <table
          v-if="energyConsumptionObject.electricMeters.length"
          class="admin-table">
          <thead class="admin-table-header">
            <tr>
              <td>ID</td>
              <td>Номер</td>
              <td>Модель</td>
              <td>Дата установки</td>
              <td>Управление</td>
            </tr>
          </thead>
          <tbody class="admin-table-body">
            <tr
              v-for="electricMeter in energyConsumptionObject.electricMeters"
              :key="electricMeter.id"
              class="electric-meter"
            >
              <td>{{ electricMeter.id }}</td>
              <td>{{ electricMeter.number }}</td>
              <td>{{ electricMeter.model }}</td>
              <td>{{ electricMeter.installationDate }}</td>
              <td>
                <div class="electric-meter__controls">
                  <div
                    class="network_object__control_btn"
                    @click="updateElectricMeter(electricMeter)"
                  >
                    <img
                      src="@/assets/img/transport/settings.svg"
                      alt="Изменить">
                  </div>
                  <div
                    class="network_object__control_btn"
                    @click="deleteElectricMeter(electricMeter.id)"
                  >
                    <img
                      src="@/assets/img/telemetry/trash.svg"
                      alt="Удалить">
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
        <div v-if="!energyConsumptionObject.electricMeters.length"
             class="col-12 mt-2">
          <div class="no-result-title">Счётчики не найдены</div>
        </div>
      </div>
    </div>
    <electric-meter-form
      :form-type="formType"
      :electric-meter="selectedElectricMeter"/>
  </div>

</template>

<script>
import {mapState} from "vuex";
import {showAlert, showToast} from "@/utils/notification";
import ElectricMeterForm from "@/components/EnergyConsumption/admin/ElectricMeterForm";

export default {
  name: "ElectricMetersList",
  components: {ElectricMeterForm},
  data() {
    return {
      formType: '',
      selectedElectricMeter: null
    }
  },
  computed: {
    ...mapState({
      energyConsumptionObject: state => state.energyConsumptionObjects.energyConsumptionObject
    })
  },
  methods: {
    createElectricMeter() {
      this.formType = 'create';
      this.selectedElectricMeter = null;
      this.$bvModal.show('electric-meter-modal');
    },
    updateElectricMeter(electricMeter) {
      this.formType = 'update';
      this.selectedElectricMeter = electricMeter;
      this.$bvModal.show('electric-meter-modal');
    },
    deleteElectricMeter(id) {
      this.$store.dispatch('electricMeters/deleteElectricMeter', {
        electricMeterId: id,
        energyConsumptionObjectId: this.$route.params.id
      })
          .then(() => {
            showToast('Счётчик удалён', 'success');
            this.$store.dispatch('energyConsumptionObjects/getEnergyConsumptionObjectWithMeters',
                this.$route.params.id);
          })
          .catch(error => showAlert('Ошибка', error));
    }
  }
}
</script>

<style scoped>
.electric-meter__controls {
  display: flex;
  justify-content: center;
}
</style>