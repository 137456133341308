import TasksService from "@/services/TasksService";

export const namespaced = true

export const state = {
    tasks: [],
    filterParams: null,
    meta: null,
    links: null,
    loading: false,
    error: null
}

export const mutations = {
    SET_TASKS(state, tasks) {
        state.tasks = tasks;
    },
    SET_FILTER_PARAMS(state, params) {
        state.filterParams = params;
    },
    SET_META(state, meta) {
        state.meta = meta;
    },
    SET_LINKS(state, links) {
        state.links = links;
    },
    SET_LOADING(state, loading) {
        state.loading = loading;
    },
    SET_ERROR(state, error) {
        state.error = error;
    }
}

export const actions = {
    getLinkedTasks({commit}, issue_id) {
        commit("SET_LOADING", true);
        TasksService.getLinkedTasks(issue_id)
            .then(tasks => commit("SET_TASKS", tasks))
            .catch(error => commit("SET_ERROR", error))
            .finally(() => commit("SET_LOADING", false));
    },
    getUserTasks({commit}, page) {
        commit("SET_LOADING", true);
        TasksService.getUserTasks(page)
            .then(({tasks, meta, links}) => {
                commit("SET_TASKS", tasks);
                commit("SET_META", meta);
                commit("SET_LINKS", links);
            })
            .catch(error => commit("SET_ERROR", error))
            .finally(() => commit("SET_LOADING", false));
    },
    getTasks({commit}, payload) {
        commit("SET_LOADING", true);
        TasksService.getTasks(payload)
            .then(({tasks, meta, links}) => {
                commit("SET_TASKS", tasks);
                commit("SET_META", meta);
                commit("SET_LINKS", links);
            })
            .catch(error => commit("SET_ERROR", error))
            .finally(() => commit("SET_LOADING", false));
    }
}

export const getters = {
    getTasks: state => {
        return state.tasks;
    },
    getFilterParams: state => {
        return state.filterParams;
    }
}