<template>
  <div class="custom-card">
    <div class="card-body">
      <div class="col-12">
        <h4 class="table-title d-inline-block">
          Ресурсы подразделения
        </h4>
        <div v-if="loading"
             class="d-inline-block ml-2">
          <base-loader />
        </div>
        <button
          class="btn custom-btn-blue-outline float-right"
          @click="createBrigade()"
        >
          Добавить
        </button>
        <hr class="divider">
      </div>
      <div v-if="brigades.length"
           class="col-12">
        <table class="admin-table">
          <thead class="admin-table-header">
            <tr>
              <td>Наименование</td>
              <td>Мастер</td>
              <td>Транспорт</td>
              <td>Управление</td>
            </tr>
          </thead>
          <tbody class="admin-table-body">
            <tr v-for="brigade in brigades"
                :key="brigade.id"
                class="department-item"
            >
              <td>{{brigade.name}}</td>
              <td>{{brigade.masterName}}</td>
              <td>{{brigade.transportGovNumber}} {{brigade.transportName}}</td>
              <td class="w-25">
                <edit-icon
                  class="cursor-pointer"
                  @click="editBrigade(brigade.id)"
                />
                <trash2-icon
                  class="cursor-pointer"
                  @click="deleteBrigade(brigade.id)"
                />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div v-else
           class="col-12">
        <div class="no-result-title">
          Ресурсы не найдены.
        </div>
      </div>

      <base-paginate
        v-if="meta && meta.last_page > 1"
        action="departmentBrigades/getDepartmentBrigadesList"
        :meta="meta"
      />
    </div>

    <department-add-brigade-form
      :form-type="formType"
      :brigade-id="editBrigadeId"
      @change-form-type="formType = $event"
    />

  </div>
</template>

<script>
import BaseLoader from "@/layout/BaseLoader";
import DepartmentAddBrigadeForm from "@/components/ServiceDesk/admin/DepartmentAddBrigadeForm";
import {mapState} from 'vuex';
import notification from "@/mixins/notification";
import BasePaginate from "@/components/BasePaginate";
export default {
  name: "DepartmentBrigadesList",
  components: {BasePaginate, DepartmentAddBrigadeForm, BaseLoader},
  mixins: [notification],
  data() {
    return {
      formType: 'create',
      editBrigadeId: null
    }
  },
  computed: {
    ...mapState({
      brigades: state => state.departmentBrigades.brigades,
      meta: state => state.departmentBrigades.meta,
      loading: state => state.departmentBrigades.loading
    })
  },
  mounted() {
    this.$store.dispatch('departmentBrigades/getDepartmentBrigadesList', {
      department_id: this.$route.params.id,
      page: 1
    });
  },
  methods: {
    createBrigade() {
      this.formType = 'create';
      this.$bvModal.show('add-brigade');
    },
    editBrigade(brigadeId) {
      this.formType = 'update';
      this.editBrigadeId = brigadeId;
      this.$bvModal.show('add-brigade');
    },
    deleteBrigade(brigadeId) {
      this.$store.dispatch('departmentBrigades/deleteDepartmentBrigade', {
        department_id: this.$route.params.id,
        brigade_id: brigadeId
      })
          .then(() => this.toast('Бригада удалена', 'success'))
          .catch(error => this.alert('Ошибка', error, 'error'))
    }
  }
}
</script>

<style scoped>

</style>