<template>
  <div class="row justify-content-center align-items-center fixed-height">
    <div class="col-xl-4 col-xs-12">
      <network-equipment-types-form />
    </div>
  </div>
</template>

<script>
import NetworkEquipmentTypesForm from "@/components/Telemetry/admin/NetworkEquipmentTypesForm";
export default {
  name: "NetworkEquipmentTypesFormPage",
  components: {NetworkEquipmentTypesForm}
}
</script>

<style scoped>

</style>