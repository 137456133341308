<template>
  <div class="row"
       v-if="$store.state.appeals.linked_appeals.length">
    <div class="col-12 mb-2">
      <div class="custom-card"
           v-b-toggle="`related-appeals`">
        <div class="card-body d-flex justify-content-between align-items-center">
          <div class="task-card-title d-flex align-items-center">
            Связанные обращения
            <div class="items-count">{{appeals.length}}</div>
          </div>
          <div class="chevron-block">
            <chevron-right-icon class="chevron"
                                :class="{'rotate': collapseVisible}" />
          </div>
        </div>
      </div>
    </div>
    <b-collapse id="related-appeals"
                class="col-12"
                v-model="collapseVisible">
      <div v-for="appeal in appeals"
           :key="appeal.id"
           class="custom-card mb-2 appeal">
        <div class="card-body">
          <div class="d-flex justify-content-between">
            <div @click="getAppealDetail(appeal.id)"
                 class="appeal-info">
              {{ appeal.getCaption() }}
            </div>
            <div @click="removeAppealFromIssue(appeal.id)"
                 class="remove-appeal">
              <x-circle-icon />
            </div>
          </div>
          <div class="appeal-message">
            {{ appeal.message }}
          </div>
        </div>
      </div>
    </b-collapse>
    <appeal-modal v-if="$store.state.appeals.appeal" />
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import notification from "@/mixins/notification";
import AppealModal from "@/components/CallCenter/AppealModal";

export default {
  name: "RelatedAppeals",
  components: {AppealModal},
  mixins: [notification],
  props: ['issue_id'],
  data() {
    return {
      collapseVisible: false
    }
  },
  computed: {
      ...mapGetters({
        appeals: 'appeals/getLinkedToIssueAppeals'
      })
  },
  methods: {
    getAppealDetail(id) {
      this.$store.dispatch('appeals/getAppeal', id)
          .then(() => this.$bvModal.show('appeal-modal'));
      this.$store.dispatch('history/getAppealHistory', id);
    },
    removeAppealFromIssue(appeal_id) {
      this.$store.dispatch('appeals/unlinkAppealFromIssue', appeal_id)
          .then(() => {
            this.toast('Обращение откреплено', 'success');
            this.$store.dispatch('appeals/getLinkedToIssueAppeals', this.issue_id);
            this.$store.dispatch('appeals/getAppealsByStatus', 1);
          })
          .catch(error => {
            this.alert('Ошибка', error, 'error');
          });
    }
  }
}
</script>

<style scoped>
.appeal-info {
  font-size: 14px;
  color: #468FC5;
  font-weight: 700;
  cursor: pointer;
}

.appeal-message {
  font-size: 14px;
  color: #262F3D;
  font-weight: 600;
}

.remove-appeal {
  cursor: pointer;
}

.remove-appeal svg{
  color: #468FC5;
}
</style>