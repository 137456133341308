export default class NetworkObject {
    constructor({id, attributes}) {
        this.id = id;
        this.name = attributes.name;
        this.type = attributes.construction_type;
        this.address = attributes.address;
        this.phone = attributes.phone;
        this.coordinates = attributes.coordinates ? attributes.coordinates.coordinates.reverse() : [];
    }

    getTypeLabel() {
        switch (this.type) {
            case 1:
                return 'ВНС'
            case 2:
                return 'РЧВ'
            case 3:
                return 'МКД'
            case 4:
                return 'КП'
            case 5:
                return 'Котельная'
            case 6:
                return 'Стоки'


        }
    }

    static getTypesArray() {
        return [
            {id: 1, label: 'ВНС'},
            {id: 2, label: 'РЧВ'},
            {id: 3, label: 'МКД'},
            {id: 4, label: 'КП'},
            {id: 5, label: 'Котельная'},
            {id: 6, label: 'Стоки'},
            {id: 7, label: 'Источники воды'}
        ]
    }
}