<template>
  <div>
    <div class="form-group">
      <div class="form-label">Группа категории</div>
      <div v-for="group in categoryGroups"
           :key="group.id"
           @click="group_id = group.id"
           :class="{active: group.id === group_id}"
           class="tag">
        {{ group.name }}
      </div>
    </div>
    <div class="form-group">
      <label class="form-label">Категория</label>
      <v-select
        :options="categoriesByGroupId(group_id)"
        :reduce="category => category.id"
        :get-option-label="(option) => option.name"
        placeholder="Выберите категорию"
        :value="category_id"
        @input="$emit('update:category_id', $event)"
        :class="{'is-invalid': (validation.$dirty && !validation.required)}"
      >
        <template #no-options="">
          Не найдено категорий. Возможно вы не выбрали группу категорий.
        </template>
      </v-select>

      <label class="error small form-text invalid-feedback">
        Поле обязательно для заполнения
      </label>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "ProblemCategorySelector",
  props: {
    category_id: {
      required: true
    },
    validation: {
      required: false,
      default: () => {
        return {
          required: false,
          $dirty: false
        }
      }
    },
    group: {
      required: false,
      default: null,
      type: Number
    }
  },
  data() {
    return {
      group_id: null
    }
  },
  computed: {
    ...mapGetters({
      categoryGroups: 'serviceDeskCategories/getGroupsContainingCategories',
      categoriesByGroupId: 'serviceDeskCategories/getCategoriesByGroupId'
    })
  },
  watch: {
    group_id: function (groupId) {
      if (!this.group) this.$emit('update:category_id', null);
      this.$emit('update:group_id', groupId);
    },
    group: function (val) {
      this.group_id = val;
      this.$emit('update:category_id', null)
    }
  },
  mounted() {
    this.$store.dispatch('serviceDeskCategories/getAllCategories');
    this.$store.dispatch('serviceDeskCategories/getCategoryGroups');
  }
}
</script>

<style scoped>
.tag {
  cursor: pointer;
}

.tag.active {
  background-color: #262F3D;
  color: white;
}

.form-label {
  font-size: 14px;
  color: #262F3D;
  font-weight: 600;
  margin-bottom: .5rem;
}
</style>