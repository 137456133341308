import { render, staticRenderFns } from "./DeviceAccessForm.vue?vue&type=template&id=3498c772&scoped=true&"
import script from "./DeviceAccessForm.vue?vue&type=script&lang=js&"
export * from "./DeviceAccessForm.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3498c772",
  null
  
)

export default component.exports