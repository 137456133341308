<template>
  <div class="row mb-3">
    <div class="col-12">
      <div class="custom-card">
        <div class="card-body">
          <div class="filter__wrapper">
            <v-select
              v-model="filter.transport_type"
              :options="transportTypes"
              :reduce="type => type.id"
              :get-option-label="(option) => option.name"
              placeholder="Выберите тип транспорта"
              class="w-100"
              @input="submitFilter()"
            />

            <v-select
              v-model="filter.state"
              :options="transportOrderStates"
              :reduce="state => state.id"
              :get-option-label="(option) => option.name"
              placeholder="Выберите статус"
              class="w-100"
              @input="submitFilter()"
            />

            <button @click="clearFilter()"
                    class="btn custom-btn-blue-outline reset-filter-btn">
              <icon-reset />
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import IconReset from "@/components/Icons/IconReset";
import Vehicle from "@/models/transport/Vehicle";
import TransportOrder from "@/models/serviceDesk/TransportOrder";
export default {
  name: "TransportOrdersFilter",
  components: {IconReset},
  data() {
    return {
      filter: {
        transport_type: null,
        state: 1
      },
      filterCollapseToggled: false
    }
  },
  computed: {
    transportTypes: () => Vehicle.getTransportTypes(),
    transportOrderStates: () => TransportOrder.getStates()
  },
  methods: {
    submitFilter() {
      this.$store.commit('transportOrders/SET_FILTER_PARAMS', this.filter);
      this.$store.dispatch('transportOrders/getTransportOrders');
    },
    clearFilter() {
      this.filter = {
        transport_type: null,
        state: 1
      }
      this.submitFilter();
    }
  }
}
</script>

<style scoped>
.filter__wrapper {
  display: flex;
  gap: 24px;
}
</style>