<template>
  <b-tab title="Результат"
         title-item-class="custom-nav-item"
         title-link-class="custom-nav-link">
    <h4 class="tab-title">
      Результат:
    </h4>

    <div
      v-if="this.$store.state.standardBuilding.buildings.length === 0"
      class="row"
    >
      <div class="col-12">
        <div class="no-result-title">
          Здания не найдены.
        </div>
        <div class="no-result-text">
          Попробуйте изменить параметры анализа.
        </div>
      </div>
    </div>

    <div class="row">
      <div v-for="building in this.$store.getters['standardBuilding/buildings']"
           :key="building.id"
           class="col-12 d-flex mb-2">
        <div class="building-block">
          <div class="building-address-block">
            <div class="building-address">{{getFullAddress(building)}}</div>
            <div @click="$store.commit(
                   'baseMap/SET_CENTER_ZOOM',
                   { center: building.attributes.geometry.coordinates, zoom: 17})
                 "
                 class="building-map">
              <img alt="Показать на карте"
                   src="@/assets/img/billing/map.svg">
            </div>
          </div>
          <div class="coefficient-block">
            <img class="coefficient-icon"
                 :id="`${building.id}_popover`"
                 src="@/assets/img/icons/temperatures.png">
            <b-popover :target="`${building.id}_popover`"
                       triggers="hover"
                       placement="bottom">
              Отклонение сезонного/не сезонного коэффициентов
            </b-popover>
            <span class="coefficient"
                  :style="{'color': getCoefficientColor(building.attributes.season_coefficient)}">
              {{ getPercent(building.attributes.season_coefficient) }}
            </span>
            <span class="coefficient-divider">/</span>
            <span class="coefficient"
                  :style="{'color': getCoefficientColor(building.attributes.not_season_coefficient)}">
              {{ getPercent(building.attributes.not_season_coefficient) }}
            </span>
          </div>
        </div>
      </div>
    </div>

    <base-pagination-payload
      v-if="this.$store.state.standardBuilding.meta &&
        this.$store.state.standardBuilding.meta.last_page > 1"
      :meta="this.$store.state.standardBuilding.meta"
      :links="this.$store.state.standardBuilding.links"
      :payload="getPayload()"
      :action="getMethodAction()"
      path="standard-building"/>

  </b-tab>
</template>

<script>
import BasePaginationPayload from "@/components/BasePaginationPayload";
export default {
  name: "StandardBuildingFilterResult",
  components: {BasePaginationPayload},
  computed: {
    getFullAddress: function () {
      return building => {
        let full_address_arr = [building.attributes.city, building.attributes.settlement, building.attributes.street,
          building.attributes.house, building.attributes.block].filter(item => item !== null)
        return full_address_arr.join(', ')
      }
    }
  },
  methods: {
    getCoefficientColor(coefficient) {
      if (coefficient >= 0 && coefficient <= 1.2) return '#04bd00'
      else if (coefficient > 1.2 && coefficient <= 2) return '#ffbf1c'
      else if (coefficient > 2 && coefficient <= 4) return '#ff6b1c'
      else if (coefficient > 4) return '#E02800'
    },
    getMethodAction() {
      switch (this.$store.state.standardBuilding.method) {
        case 1:
          return 'standardBuilding/getBuildingsV1'
        case 2:
          return 'standardBuilding/getBuildingsV2'
      }
    },
    getPayload() {
      return {
        filter: this.$store.state.standardBuilding.filter_params,
        page: 1
      }
    },
    getPercent(coefficient) {
      let percent_coefficient = (coefficient * 100) - 100;
      if (percent_coefficient > 0) return '+' + percent_coefficient.toFixed(0) + '%'
      else return percent_coefficient.toFixed(0) + '%'
    }
  }
}
</script>

<style scoped>
.tab-title {
  font-size: 24px;
  font-weight: 700;
  color: #262F3D;
  margin-bottom: 20px;
}

.building-block {
  width: 100%;
  padding: 10px 15px;
  background-color: white;
  border: 1px solid #468FC5;
  border-radius: 6px;
  margin-right: 45px;
}

.building-address-block {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}

.building-address {
  display: flex;
  color: #262f3d!important;
  font-weight: 600;
}

.building-map {
  position: absolute;
  right: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  background-color: #EBF1F3;
  border-radius: 6px;
  cursor: pointer;
}

.coefficient-block {
  display: flex;
  align-items: center;
}

.coefficient-icon {
  width: 20px;
  margin-right: 6px;
}

.coefficient {
  font-weight: 600;
}

.coefficient-divider {
  margin: 0 3px;
}
</style>