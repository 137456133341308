<template>
  <div v-if="tasks.length !== 0"
       class="row">
    <div class="col-12 mb-2">
      <div class="custom-card"
           v-b-toggle="`related-tasks`">
        <div class="card-body d-flex justify-content-between align-items-center">
          <div class="task-card-title d-flex align-items-center">
            Задания
            <div class="items-count">{{tasks.length}}</div>
          </div>
          <div class="chevron-block">
            <chevron-right-icon class="chevron"
                                :class="{'rotate': collapseVisible}" />
          </div>
        </div>
      </div>
    </div>
    <b-collapse id="related-tasks"
                v-model="collapseVisible"
                class="col-12">
      <div class="custom-card">
        <div class="card-body">
          <table class="admin-table">
            <thead class="admin-table-header">
              <tr>
                <td>Тип задания</td>
                <td>Факт. время начала</td>
                <td>Факт. время окончания</td>
                <td>Статус</td>
              </tr>
            </thead>
            <tbody class="admin-table-body">
              <tr v-for="task in tasks"
                  :key="task.id"
                  @click="$router.push(`/task/${task.id}`)"
                  class="task-item">
                <td>{{ task.type_label }}</td>
                <td>{{ task.actual_start_date | formatDateTime }}</td>
                <td>{{ task.actual_end_date | formatDateTime }}</td>
                <td>
                  <base-badge :label="task.state.label"
                              :color="task.state.color"/>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </b-collapse>
  </div>
</template>

<script>
import BaseBadge from "@/layout/BaseBadge";
import {mapGetters} from "vuex";

export default {
  name: "RelatedTasksList",
  components: {BaseBadge},
  props: ['issue_id'],
  data() {
    return {
      collapseVisible: false
    }
  },
  computed: {
    ...mapGetters({
      tasks: 'tasks/getTasks'
    })
  }
}
</script>

<style scoped>
.admin-table-header td {
  font-size: 14px;
  padding: 4px;
}

.admin-table-body tr td {
  font-size: 14px;
  padding: 4px;
}

.task-item {
  transition: .3s;
  cursor: pointer;
}

.task-item:hover {
  background-color: #e9e9e9;
  transition: .3s;
}
</style>