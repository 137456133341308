<template>
  <div class="row">
    <div class="col-xl-4 col-xs-12">
      <tasks-list />
    </div>
    <div class="col-xl-8 col-xs-12" />
  </div>
</template>

<script>
import TasksList from "@/components/ServiceDesk/Tasks/TasksList";
export default {
  name: "MyTasksListPage",
  components: {TasksList},
  data() {
    return {
      timing: null
    }
  },
  mounted() {
    this.$store.dispatch('tasks/getUserTasks', 1);

    this.timing = setInterval(this.getUserTasks, process.env.VUE_APP_SERVICE_DESK_GET_DATA_TIMING)
  },
  beforeDestroy() {
    this.$store.commit('tasks/SET_TASKS', []);
    clearInterval(this.timing);
  },
  methods: {
    getUserTasks() {
      const currentPage = this.$route.query.page || 1;
      this.$store.dispatch('tasks/getUserTasks', currentPage);
    }
  }
}
</script>

<style scoped>

</style>