<template>
  <div>
    <table class="admin-table">
      <thead class="admin-table-header">
        <tr>
          <td>#</td>
          <td>Наименование</td>
          <td>Управление</td>
        </tr>
      </thead>
      <tbody class="admin-table-body">
        <tr v-for="consumable in consumablesList"
            :key="consumable.id"
            class="equipment-item"
        >
          <td>{{ consumable.id }}</td>
          <td>{{ consumable.name }}</td>
          <td class="table-action w-25 ">
            <router-link :to="`/update-consumable/`+consumable.id">
              <edit-icon class="mr-1" />
            </router-link>
            <trash2-icon @click="deleteConsumable(consumable.id)"
                         class="cursor-pointer" />
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import {mapState} from "vuex";
import {showToast, showAlert} from "@/utils/notification";

export default {
  name: "ConsumablesList",
  computed: {
    ...mapState({
      consumablesList: state => state.consumables.consumables
    })
  },
  methods: {
    deleteConsumable(consumableId) {
      this.$store.dispatch('consumables/deleteConsumable', consumableId)
          .then(() => {
            showToast('Расходный материал удалён', 'success');
            this.$store.commit("consumables/DELETE_CONSUMABLE", consumableId);
          })
          .catch(error => showAlert('Ошибка', error))
    }
  }
}
</script>

<style scoped>

</style>