<template>
  <div class="row">
    <div class="col-12">
      <div class="custom-card">
        <div class="card-body">
          <div class="row">
            <div class="col-12">
              <h4 class="table-title d-inline-block">
                Типы сетевого оборудования
              </h4>
              <div
                v-if="loading"
                class="d-inline-block ml-2">
                <base-loader />
              </div>
              <router-link to="/network-equipment-types-create">
                <button class="btn custom-btn-blue float-right">
                  Добавить тип сетевого объекта
                </button>
              </router-link>
              <hr class="divider">
            </div>

            <div
              v-if="networkEquipmentTypes.length"
              class="col-12"
            >
              <network-equipment-types-list />
            </div>

            <div
              v-if="!networkEquipmentTypes.length && !loading"
              class="col-12 mt-2"
            >
              <div class="no-result-title">Типы сетевого оборудования не найдены</div>
            </div>

          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BaseLoader from "@/layout/BaseLoader";
import NetworkEquipmentTypesList from "@/components/Telemetry/admin/NetworkEquipmentTypesList";
import {mapState} from "vuex";
export default {
  name: "NetworkEquipmentTypesListPage",
  components: {NetworkEquipmentTypesList, BaseLoader},
  computed: {
    ...mapState({
      networkEquipmentTypes: state => state.networkEquipmentTypes.networkEquipmentTypes,
      loading: state => state.networkEquipmentTypes.loading
    })
  },
  mounted() {
    this.$store.dispatch('networkEquipmentTypes/getNetworkEquipmentTypes');
  }
}
</script>

<style scoped>

</style>