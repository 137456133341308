<template>
  <table class="admin-table">
    <thead class="admin-table-header">
      <tr>
        <td class="d-none d-md-table-cell">
          ID
        </td>
        <td class="d-none d-md-table-cell">
          Наименование
        </td>
        <td class="d-none d-md-table-cell">
          Sys_ID
        </td>
        <td>Действия</td>
      </tr>
    </thead>
    <tbody class="admin-table-body">
      <tr
        v-for="zone in this.$store.state.zone.zones"
        :key="zone.id"
      >
        <td class="d-none d-md-table-cell">
          {{ zone.id }}
        </td>
        <td class="d-none d-md-table-cell">
          {{ zone.attributes.name }}
        </td>
        <td class="d-none d-md-table-cell">
          {{ zone.attributes.sys_id }}
        </td>
        <td class="table-action">
          <router-link :to="'/admin-zones-update/'+zone.id">
            <edit-icon class="mr-1" />
          </router-link>
          <a>
            <trash2-icon @click="deleteZone(zone.id)" />
          </a>
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
import ZonesService from "@/services/ZonesService";

export default {
  name: "ZonesList",
  methods: {
    async deleteZone(zone_id) {
      this.$swal({
        title: 'Вы действительно хотите удалить зону?',
        showCancelButton: true,
        confirmButtonText: `Да`,
        cancelButtonText: 'Отмена',
        icon: 'warning'
      }).then(result => {
        if (result.isConfirmed) {
          ZonesService.deleteZone(zone_id).then(() => {
            this.$swal('Зона успешно удалена!', '', 'success')
            this.$store.commit('zone/DELETE_ZONES', zone_id);
          })
        }
      });
    }
  }
}
</script>

<style scoped>

</style>