<template>
  <b-modal
    id="add-mechanical-equipment"
    ref="add-mechanical-equipment"
    centered
    hide-header
    hide-footer
  >
    <div class="col-12">
      <div class="custom-modal-header">
        <div class="custom-modal-title">
          Добавить СММ
        </div>
        <img
          alt="Закрыть"
          class="custom-modal-close"
          src="@/assets/img/icons/close.svg"
          @click="$bvModal.hide('add-mechanical-equipment')"
        >
      </div>
      <div class="modal-divider" />
    </div>
    <form @submit.prevent="submitForm()">
      <div class="col-12 mb-2">
        <div class="form-group">
          <label class="form-label">Выберите средство малой механизации</label>
          <v-select v-model="payload.equipment_id"
                    :options="mechanicalEquipments"
                    :reduce="equipment => equipment.id"
                    :get-option-label="(option) => option.name"
                    placeholder="Выберите СММ"
          />
        </div>
        <div class="form-group">
          <label class="form-label">Время работы, часы</label>
          <input
            v-model.number="payload.duration"
            type="text"
            class="form-control"
            placeholder="Введите время работы">
        </div>
        <div class="modal-divider" />
        <div class="row">
          <div class="col-12">
            <button
              type="submit"
              class="btn custom-btn-blue w-100"
            >
              Добавить
            </button>
          </div>
        </div>
      </div>
    </form>
  </b-modal>
</template>

<script>
import { mapState } from 'vuex';
import notification from "@/mixins/notification";
export default {
  name: "AddMechanicalEquipmentForm",
  mixins: [notification],
  data() {
    return {
      payload: {
        equipment_id: null,
        duration: null
      }
    }
  },
  computed: {
    ...mapState({
      mechanicalEquipments: state => state.mechanicalEquipments.mechanicalEquipments,
      task: state => state.task.task
    })
  },
  mounted() {
    this.$store.dispatch('mechanicalEquipments/getAllMechanicalEquipments');
  },
  methods: {
    submitForm() {
      this.$store.dispatch('taskMechanicalEquipments/addMechanicalEquipmentToTask', {
        taskId: this.task.task.id,
        payload: this.payload
      })
          .then(() => {
            this.$store.dispatch('taskMechanicalEquipments/getTaskMechanicalEquipments', this.task.task.id);
            this.toast('Средство малой механизации добавлено в задание', 'success');
            this.$bvModal.hide('add-mechanical-equipment');
          })
          .catch(error => this.alert('Ошибка', error, 'error'));
    }
  }
}
</script>

<style scoped>

</style>