<template>
  <table class="admin-table">
    <thead class="admin-table-header">
      <tr>
        <td>ID</td>
        <td>Активность</td>
        <td>Наименование</td>
        <td>Сенсор</td>
        <td>Тип</td>
        <td>Время</td>
        <td>Действия</td>
      </tr>
    </thead>
    <tbody class="admin-table-body">
      <tr
        v-for="config in this.$store.state.journalConfigs.journalConfigs"
        :key="config.id"
      >
        <td>{{ config.id }}</td>
        <td>{{ config.attributes.active == 1 ? 'Активно' : 'Не активно' }}</td>
        <td>{{ config.attributes.name }}</td>
        <td>{{ config.attributes.sensor_id }}</td>
        <td>{{ typeConfig(config.attributes.type) }}</td>
        <td>{{ config.attributes.times }}</td>
        <td class="table-action">
          <router-link :to="'/admin-journal-configs-update/'+config.id">
            <edit-icon class="mr-1" />
          </router-link>
          <a @click="deleteConfig(config.id)">
            <trash2-icon />
          </a>
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
import {mapGetters} from "vuex";
import LogbookService from "@/services/LogbookService";

export default {
  name: "JournalConfigsList",
  computed: {
    ...mapGetters({
    })
  },
  methods:{
    async deleteConfig(config_id){
      this.$swal({
        title: 'Вы действительно хотите удалить конфигурацию?',
        showCancelButton: true,
        confirmButtonText: `Да`,
        cancelButtonText: 'Отмена',
        icon: 'warning'
      }).then(result=>{
        if (result.isConfirmed) {
          LogbookService.deleteConfig(config_id).then(() => {
            this.$swal('Конфигурация успешно удалена!', '', 'success')
            this.$store.commit('journalConfigs/DELETE_JOURNAL_CONFIGS', config_id);
          })
        }
      });
    },
    typeConfig(type) {
      switch (type) {
        case 1: return 'Уровень РЧВ';
        case 2: return 'УЗР - накопленый';
        case 3: return 'УЗР - моментальный';
        case 4: return 'Стоки';
        case 5: return 'Текстовое значение';
      }
    }
  }
}
</script>

<style scoped>

</style>