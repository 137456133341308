<template>
  <div class="row">
    <div class="col-lg-12 mb-4">
      <div class="custom-card">
        <div class="card-header pb-0">
          <h4 class="table-title d-inline-block">
            Объекты сети
          </h4>
          <router-link
            to="/network-objects-create"
            class="btn custom-btn-blue-outline float-right"
          >
            Добавить объект сети
          </router-link>
          <hr class="divider">
        </div>
        <div class="card-body">
          <div class="container-fluid">
            <div class="row">
              <network-objects-list />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import NetworkObjectsList from "@/components/Telemetry/admin/NetworkObject/NetworkObjectsList";

export default {
  name: "NetworkObjectsListPage",
  components: {
    NetworkObjectsList
  },
  mounted() {
    this.$store.dispatch('networkObjects/getAllNetworkObjects');
  }
}
</script>

<style scoped>

</style>