<template>
  <div class="row">
    <div class="col-xl-4 col-lg-6 col-md-12 mb-3">
      <div class="custom-card mb-0">
        <div class="card-body">
          <div class="container-fluid">
            <div class="row">
              <users-form />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="$route.name === 'UsersUpdate'"
         class="col-xl-4 col-lg-6 col-md-12">
      <div class="custom-card mb-3">
        <div class="card-body">
          <div class="card-title">Роли пользователя</div>

          <ul>
            <li v-for="userRole in user ? user.roles: []"
                :key="userRole">
              <div class="d-flex justify-content-between">
                <span>{{userRole.attributes.name}}</span>
                <trash2-icon
                  class="cursor-pointer"
                  @click="removeUserRole(userRole.id)"/>
              </div>
            </li>
          </ul>

          <hr>

          <user-change-role-form />
        </div>
      </div>

      <div class="custom-card">
        <div class="card-body">
          <div class="card-title">Подписки</div>
          <ul>
            <li v-for="subscription in userSubscriptions"
                :key="subscription.id">
              <div class="d-flex justify-content-between">
                <span>{{subscription.typeName}}</span>
                <trash2-icon
                  class="cursor-pointer"
                  @click="removeSubscription(subscription.id)"/>
              </div>
            </li>
          </ul>

          <hr>

          <user-subscriptions-form />
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import UsersForm from "@/components/admin/UsersForm";
import UserChangeRoleForm from "@/components/admin/UserChangeRoleForm";
import {mapState} from "vuex";
import {showAlert, showToast} from "@/utils/notification";
import UserSubscriptionsForm from "@/components/admin/UserSubscriptionsForm";

export default {
  name: "UsersFormPage",
  components: {
    UserSubscriptionsForm,
    UserChangeRoleForm,
    UsersForm
  },
  computed: {
    ...mapState({
      user: state => state.user.user,
      userSubscriptions: state => state.userSubscriptions.userSubscriptions
    })
  },
  mounted() {
    this.$store.dispatch('userSubscriptions/getSubscriptionTypes');
    this.$store.dispatch('userSubscriptions/getUserSubscriptions', this.user.id);
  },
  methods: {
    removeUserRole(role_id) {
      this.$store.dispatch('user/removeRole', {
        user_id: this.user.id,
        role_id: role_id
      })
          .then(() => {
            showToast('Роль удалена', 'success');
            this.$store.dispatch('user/getUser', this.user.id);
          })
          .catch(error => showAlert('Ошибка', error));
    },
    removeSubscription(subscriptionId) {
      this.$store.dispatch('userSubscriptions/deleteSubscription', subscriptionId)
          .then(() => {
            showToast('Подписка отменена', 'success');
            this.$store.commit('userSubscriptions/DELETE_SUBSCRIPTION', subscriptionId);
          })
          .catch(error => showAlert('Ошибка', error));
    }
  }
}
</script>

<style scoped>

</style>