export default class TaskState {
    static NEW = 1;
    static PLANNED = 9;
    static IN_WORK = 4;
    static CANCELED = 5;
    static CLOSED = 6;
    static SUSPENDED = 7;

    constructor(id) {
        this.id = id;
        this.label = this.getStateLabel();
        this.color = this.getStateColor();
    }

    getStateLabel() {
        switch (this.id) {
            case TaskState.NEW:
                return 'Новое'
            case TaskState.PLANNED:
                return 'Запланировано'
            case TaskState.IN_WORK:
                return 'В работе'
            case TaskState.CANCELED:
                return 'Отменено'
            case TaskState.CLOSED:
                return 'Закрыто'
            case TaskState.SUSPENDED:
                return 'Приостановлено'
            default:
                return 'Статус отсутствует'
        }
    }

    getStateColor() {
        switch (this.id) {
            case TaskState.NEW:
                return '#468FC5'
            case TaskState.PLANNED:
                return '#46c5b2'
            case TaskState.IN_WORK:
                return '#FFBF1C'
            case TaskState.CANCELED:
                return '#E02800'
            case TaskState.CLOSED:
                return '#4f4f4f'
            case TaskState.SUSPENDED:
                return '#4f4f4f'
            default:
                return '#7c7c7c'
        }
    }
}