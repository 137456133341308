<template>
  <div class="row">
    <div class="col-12">
      <div class="custom-card">
        <div class="card-body">
          <div class="container-fluid">
            <div class="row">
              <div class="col-12">
                <h4 class="table-title d-inline-block">
                  Категории
                </h4>
                <div v-if="$store.state.serviceDeskCategories.categoriesLoading"
                     class="d-inline-block ml-2">
                  <base-loader />
                </div>
                <router-link to="/create-category">
                  <button class="btn custom-btn-blue float-right">
                    Добавить категорию
                  </button>
                </router-link>
                <hr class="divider">
              </div>
              <div v-if="$store.state.serviceDeskCategories.categories.length"
                   class="col-12">
                <categories-list />
              </div>
              <div v-if="!$store.state.serviceDeskCategories.categories.length
                     && !$store.state.serviceDeskCategories.categoriesLoading"
                   class="col-12 mt-2">
                <div class="no-result-title">Категории не найдены</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BaseLoader from "@/layout/BaseLoader";
import CategoriesList from "@/components/ServiceDesk/admin/CategoriesList";
export default {
  name: "CategoriesListPage",
  components: {CategoriesList, BaseLoader},
  mounted() {
    this.$store.dispatch('serviceDeskCategories/getCategories', this.$store.state.serviceDeskCategories.page);
  }
}
</script>

<style scoped>

</style>