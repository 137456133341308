<template>
  <div>
    <table class="admin-table mb-2">
      <thead class="admin-table-header">
        <tr>
          <td>ID</td>
          <td>Наименование</td>
          <td>Адрес</td>
        </tr>
      </thead>
      <tbody class="admin-table-body">
        <tr
          v-for="energyConsumptionObject in energyConsumptionObjects"
          :key="energyConsumptionObject.id"
          class="energy-consumption-object__item"
          @click="$router.push(`/energy-consumption-object/${energyConsumptionObject.id}`)"
        >
          <td>{{ energyConsumptionObject.id }}</td>
          <td>{{ energyConsumptionObject.name }}</td>
          <td>{{ energyConsumptionObject.address }}</td>
        </tr>
      </tbody>
    </table>
    <base-paginate
      v-if="meta && meta.last_page > 1"
      :meta="meta"
      action="energyConsumptionObjects/getEnergyConsumptionObjectsListWithMeters"
      commit="energyConsumptionObjects/SET_PAGE"/>
  </div>
</template>

<script>
import {mapState} from "vuex";
import BasePaginate from "@/components/BasePaginate";

export default {
  name: "EnergyConsumptionObjectsList",
  components: {BasePaginate},
  computed: {
    ...mapState({
      energyConsumptionObjects: state => state.energyConsumptionObjects.energyConsumptionObjectsList,
      meta: state => state.energyConsumptionObjects.meta
    })
  }
}
</script>

<style scoped>
.energy-consumption-object__item {
  cursor: pointer;
}

.energy-consumption-object__item:hover {
  background-color: aliceblue;
}
</style>