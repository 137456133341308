<template>
  <div class="row">
    <div
      class="col-xl-4 col-md-6 col-sm-12 mb-3"
      v-for="chart in charts"
      :key="chart.id"
    >
      <div class="custom-card">
        <div class="card-body">
          <div class="chart-title">Количество поступивших обращений {{ chart.groupName }}
            <base-loader v-if="loading" />
          </div>
          <apexchart
            type="donut"
            :options="chart.options"
            :series="chart.series"
          />

        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters, mapState} from "vuex";
import VueApexCharts from "vue-apexcharts";
import BaseLoader from "@/layout/BaseLoader";

export default {
  name: "AppealsCountBaCategory",
  components: {BaseLoader, 'apexchart': VueApexCharts},
  props: ['filter'],
  data() {
    return {
      charts: []
    }
  },
  computed: {
    ...mapGetters({
      appealsCount: 'appealsStatistic/getAppealsCountByCategory'
    }),
    ...mapState({
      appealsCount: state => state.appealsStatistic.appealsCountByCategory,
      categoryGroups: state => state.serviceDeskCategories.categoryGroups,
      loading: state => state.appealsStatistic.appealsCountByCategoryLoading
    })
  },
  watch: {
    filter: function () {
      this.createChartData();
    }
  },
  mounted() {
    this.$store.dispatch('serviceDeskCategories/getCategoryGroups')
        .then(() => {
          this.createChartData()
        })
  },
  methods: {
    createChartData() {
      this.charts = [];
      this.$store.dispatch('appealsStatistic/getAppealsCountByCategories', this.filter)
          .then(() => {
            this.categoryGroups.forEach(group => {
              const filteredStatistic = this.appealsCount.filter(statItem => statItem.group.id === group.id);

              if (filteredStatistic.length) {
                this.charts.push({
                  id: group.id,
                  groupName: group.name,
                  appealsCountByCategories: filteredStatistic,
                  series: filteredStatistic.map(statItem => statItem.count),
                  options: {
                    legend: {
                      show: true,
                      position: 'bottom'
                    },
                    labels: filteredStatistic.map(statItem => statItem.category.name),
                    tooltip: {
                      enabled: false
                    },
                    dataLabels: {
                      formatter: (val, opts) => {
                        return opts.w.config.series[opts.seriesIndex]
                      }
                    },
                    plotOptions: {
                      pie: {
                        expandOnClick: false,
                        donut: {
                          labels: {
                            show: true,
                            total: {
                              show: true,
                              label: 'Всего'
                            }
                          }
                        }
                      }
                    }
                  }
                })
              }
            })
          })
    }
  }
}
</script>

<style scoped>
.chart-title {
  font-size: 22px;
  font-weight: 600;
  color: #262F3D;
}
</style>