import * as API from "@/services/API";
import {getError} from "@/utils/helpers";
import Department from "@/models/serviceDesk/Department";
import DepartmentEmployee from "@/models/serviceDesk/DepartmentEmployee";
import DepartmentBrigade from "@/models/serviceDesk/DepartmentBrigade";

export default {
    getAllDepartments() {
        return new Promise((resolve, reject) => {
            API.apiClient.get('service-desk/departments/all')
                .then(response => {
                    resolve(response.data.data.map(department =>
                        new Department(department.id, department.attributes)))
                })
                .catch(error => reject(getError(error)));
        });
    },
    getDepartments(page) {
        return new Promise((resolve, reject) => {
            API.apiClient.get(`service-desk/departments?page=${page}`)
                .then(response => {
                    resolve({
                        departments: response.data.data.map(department => {
                            return new Department(department.id, department.attributes)
                        }),
                        meta: response.data.meta,
                        links: response.data.links
                    })
                })
                .catch(error => reject(getError(error)));
        })
    },
    fetchDepartment(department_id) {
        return new Promise((resolve, reject) => {
            API.apiClient.get(`/service-desk/departments/${department_id}`)
                .then(response => {
                    const {id, attributes} = response.data.data
                    resolve(new Department(id, attributes));
                })
                .catch(error => reject(getError(error)));
        })
    },
    createDepartment(payload) {
        return new Promise((resolve, reject) => {
            API.apiClient.post(`service-desk/departments`, payload)
                .then(() => resolve())
                .catch(error => reject(getError(error)))
        })
    },
    updateDepartment({department_id, payload}) {
        return new Promise((resolve, reject) => {
            API.apiClient.patch(`service-desk/departments/${department_id}`, payload)
                .then(() => resolve())
                .catch(error => reject(getError(error)));
        })
    },
    activateDepartment(department_id) {
        return new Promise((resolve, reject) => {
            API.apiClient.post(`service-desk/departments/${department_id}/activate`)
                .then(() => resolve())
                .catch(error => reject(getError(error)));
        })
    },
    deactivateDepartment(department_id) {
        return new Promise((resolve, reject) => {
            API.apiClient.post(`service-desk/departments/${department_id}/deactivate`)
                .then(() => resolve())
                .catch(error => reject(getError(error)));
        })
    },
    fetchDepartmentStaffList(department_id) {
        return new Promise((resolve, reject) => {
            API.apiClient.get(`service-desk/departments/${department_id}/employees`)
                .then(response => resolve(response.data.data
                    .map(item => new DepartmentEmployee(item.id, item.attributes))))
                .catch(error => reject(getError(error)));
        })
    },
    addEmployeeToDepartment(department_id, payload) {
        return API.apiClient.post(`service-desk/departments/${department_id}/employees`, payload);
    },
    removeEmployeeFromDepartment(department_id, employee_id) {
        return API.apiClient.delete(`service-desk/departments/${department_id}/employees/${employee_id}`)
    },
    fetchDepartmentBrigadesList(department_id, page = 1) {
        return new Promise((resolve, reject) => {
            API.apiClient.get(`/service-desk/departments/${department_id}/brigades`, {params: {page: page}})
                .then(response => resolve({
                    brigades: response.data.data.map(item => new DepartmentBrigade(item)),
                    meta: response.data.meta
                }))
                .catch(error => reject(getError(error)));
        })
    },
    fetchAllDepartmentBrigades(department_id) {
        return new Promise((resolve, reject) => {
            API.apiClient.get(`/service-desk/departments/${department_id}/brigades/all`)
                .then(response => resolve(response.data.data.map(item => new DepartmentBrigade(item))))
                .catch(error => reject(getError(error)));
        })
    },
    createDepartmentBrigade(department_id, payload) {
        return API.apiClient.post(`/service-desk/departments/${department_id}/brigades`, payload);
    },
    updateDepartmentBrigade(department_id, brigade_id, payload) {
        return API.apiClient.patch(`/service-desk/departments/${department_id}/brigades/${brigade_id}`, payload);
    },
    deleteDepartmentBrigade(department_id, brigade_id) {
        return API.apiClient.delete(`/service-desk/departments/${department_id}/brigades/${brigade_id}`);
    }
}