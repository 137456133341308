<template>
  <div class="custom-card">
    <div class="card-body">
      <div class="chart-title">Количество выполненных заявок по типам
        <base-loader v-if="$store.state.issuesStatistic.closedIssuesCountByTypeLoading" />
      </div>
      <apexchart
        ref="chartComponent"
        type="bar"
        :options="chartOptions"
        :series="chartSeries"
      />
    </div>
  </div>
</template>

<script>
import BaseLoader from "@/layout/BaseLoader";
import VueApexCharts from "vue-apexcharts";
import {mapGetters} from "vuex";
export default {
  name: "ClosedIssuesCountByDay",
  components: {BaseLoader, 'apexchart': VueApexCharts},
  props: ['filter'],
  data() {
    return {
      chartOptions: {},
      chartSeries: []
    }
  },
  computed: {
    ...mapGetters({
      issuesCount: 'issuesStatistic/getClosedIssuesCountByType',
      issueTypes: 'issueTypes/getIssueTypes'
    })
  },
  watch: {
    filter: function () {
      this.createChartData();
    }
  },
  mounted() {
    this.createChartData();
  },
  methods: {
    createChartData() {
      this.$store.dispatch('issuesStatistic/getClosedIssuesCountByType', this.filter)
          .then(() => {
            this.chartOptions = {
              legend: {
                position: 'right',
                offsetY: 40
              },
              chart: {
                type: 'bar',
                stacked: true,
                toolbar: {
                  show: true
                },
                height: 400
              },
              responsive: [{
                breakpoint: 480,
                options: {
                  legend: {
                    position: 'bottom',
                    offsetX: -10,
                    offsetY: 0
                  }
                }
              }],
              plotOptions: {
                bar: {
                  horizontal: false,
                  borderRadius: 0,
                  dataLabels: {
                    total: {
                      enabled: true,
                      style: {
                        fontSize: '13px',
                        fontWeight: 900
                      }
                    }
                  }
                }
              },
              dataLabels: {
                enabled: true
              },
              xaxis: {
                categories: this.issuesCount.map(item => this.$moment(item.date).format('DD.MM.YYYY')),
                labels: {
                  show: true,
                  rotateAlways: true
                }
              },
              fill: {
                opacity: 1
              },
              tooltip: {
                y: {
                  formatter: function (val) {
                    return val.toFixed()
                  }
                }
              }
            }

            this.$store.dispatch('issueTypes/getIssueTypes')
                .then(() => {
                  this.chartSeries = this.issueTypes.map(item => {
                    return {
                      name: item.name,
                      typeId: item.id,
                      data: []
                    }
                  })

                  this.chartSeries.forEach(series => {
                    this.issuesCount.forEach(statistic => {
                      series.data.push(statistic.values.find(value => value.type === series.typeId).count)
                    })
                  })
                })
          })
    }
  }
}
</script>

<style scoped>
.chart-title {
  font-size: 22px;
  font-weight: 600;
  color: #262F3D;
}
</style>