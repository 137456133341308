import { render, staticRenderFns } from "./JournalConfigsList.vue?vue&type=template&id=38c399c5&scoped=true&"
import script from "./JournalConfigsList.vue?vue&type=script&lang=js&"
export * from "./JournalConfigsList.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "38c399c5",
  null
  
)

export default component.exports