<template>
  <div class="row">
    <div class="col-12">
      <div class="custom-card">
        <div class="card-body">
          <div>
            <div class="d-flex justify-content-between align-items-center">
              <div class="task-card-title">
                Заявка <span>#{{ issue.id }}</span> от {{ issue.created_at | formatDateTime }}
                <issue-export :issue="issue" />
              </div>
              <div class="issue-badges">
                <base-badge
                  v-if="isAccident"
                  label="Авария"
                  color="#EB5757" />
                <base-badge
                  :label="issue.state.label"
                  :color="issue.state.color" />
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-6">
              <div v-if="issue.type_name"
                   class="service-desk-info-block">
                <div>Тип заявки</div>
                <div>{{ issue.type_name }}</div>
              </div>
            </div>
            <div class="col-6">
              <div v-if="issue.category"
                   class="service-desk-info-block">
                <div>Категория заявки</div>
                <div>{{ issue.category.group_name }}: {{ issue.category.name }}</div>
              </div>
            </div>
            <div class="col-6">
              <div class="service-desk-info-block">
                <div>Адрес</div>
                <div>{{ issue.getFullAddress() }}</div>
              </div>
            </div>
            <div class="col-6">
              <div class="service-desk-info-block">
                <div>Создатель</div>
                <div>{{ issue.author }}</div>
              </div>
            </div>
            <div class="col-6">
              <div class="service-desk-info-block">
                <div>Основание заявки</div>
                <div>{{ issue.based_on }}</div>
              </div>
            </div>
            <div class="col-6">
              <div v-if="issue.description"
                   class="service-desk-info-block">
                <div>Комментарий</div>
                <div>{{ issue.description }}</div>
              </div>
            </div>
            <div class="col-6">
              <div v-if="issue.activated_in"
                   class="service-desk-info-block">
                <div>Отложена до</div>
                <div>{{ issue.activated_in | formatDate }}</div>
              </div>
            </div>
            <div
              v-if="issue.planned_start_at"
              class="col-12"
            >
              <div class="row">
                <div class="col-6">
                  <div v-if="issue.planned_start_at"
                       class="service-desk-info-block">
                    <div>Плановая дата начала</div>
                    <div>{{ issue.planned_start_at | formatDate }}</div>
                  </div>
                </div>
                <div class="col-6">
                  <div v-if="issue.planned_end_at"
                       class="service-desk-info-block">
                    <div>Плановая дата окончания</div>
                    <div>{{ issue.planned_end_at | formatDate }}</div>
                  </div>
                </div>
              </div>
            </div>
            <div v-if="issue.tags.length"
                 class="col-12">
              <div>
                <div class="tags-title">Теги</div>
                <div v-for="(tag, index) in issue.tags"
                     :key="index"
                     class="tag">
                  {{tag}}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BaseBadge from "@/layout/BaseBadge";
import IssueExport from "@/components/ServiceDesk/Issues/IssueExport";
import {mapGetters} from 'vuex';

export default {
  name: "IssueInfo",
  components: {IssueExport, BaseBadge},
  props: ['issue'],
  computed: {
    ...mapGetters({
      isAccident: 'shutdowns/isAccident'
    })
  }
}
</script>

<style scoped>
.issue-params div {
  font-size: 16px;
  color: #97ACB5;
  font-weight: 700;
}

.issue-param-value {
  color: #468FC5;
  font-weight: 700;
}

.tags-title {
  display: block;
  color: #262F3D;
  font-size: 14px;
  line-height: 1;
  font-weight: 600;
  margin-bottom: 5px;
  margin-top: 20px;
}

.issue-badges {
  display: flex;
  gap: 8px;
}
</style>