import AppealsStatisticService from "@/services/AppealsStatisticService";

export const namespaced = true;

export const state = {
    appealsCountByDay: [],
    appealsCountByCategory: [],
    appealsCountByDayLoading: false,
    appealsCountByCategoryLoading: false,
    appealsCountByDayError: null,
    appealsCountByCategoryError: null
}

export const mutations = {
    SET_APPEALS_COUNT_BY_DAY(state, appealsCount) {
        state.appealsCountByDay = appealsCount;
    },
    SET_APPEALS_COUNT_BY_CATEGORY(state, appealsCount) {
        state.appealsCountByCategory = appealsCount;
    },
    SET_APPEALS_COUNT_BY_DAY_LOADING(state, loading) {
        state.appealsCountByDayLoading = loading;
    },
    SET_APPEALS_COUNT_BY_CATEGORY_LOADING(state, loading) {
        state.appealsCountByCategoryLoading = loading;
    },
    SET_APPEALS_COUNT_BY_DAY_ERROR(state, error) {
        state.appealsCountByDayError = error;
    },
    SET_APPEALS_COUNT_BY_CATEGORY_ERROR(state, error) {
        state.appealsCountByCategoryError = error;
    }
}

export const actions = {
    getAppealsCountByDay({commit}, filter) {
        commit("SET_APPEALS_COUNT_BY_DAY_LOADING", true);
        return new Promise((resolve, reject) => {
            AppealsStatisticService.fetchAppealsCountByDay(filter)
                .then(data => {
                    commit("SET_APPEALS_COUNT_BY_DAY", data);
                    resolve()
                })
                .catch(error => {
                    commit("SET_APPEALS_COUNT_BY_DAY_ERROR", error);
                    reject()
                })
                .finally(() => commit("SET_APPEALS_COUNT_BY_DAY_LOADING", false))
        })
    },
    getAppealsCountByCategories({commit}, filter) {
        commit("SET_APPEALS_COUNT_BY_CATEGORY_LOADING", true);
        return new Promise((resolve, reject) => {
            AppealsStatisticService.fetchAppealsCountByCategory(filter)
                .then(data => {
                    commit("SET_APPEALS_COUNT_BY_CATEGORY", data);
                    resolve();
                })
                .catch(error => {
                    commit("SET_APPEALS_COUNT_BY_CATEGORY_ERROR", error);
                    reject();
                })
                .finally(() => commit("SET_APPEALS_COUNT_BY_CATEGORY_LOADING", false))
        })
    }
}

export const getters = {
    getAppealsCountByDay: state => {
        return state.appealsCountByDay;
    },
    getAppealsCountByCategory: state => {
        return state.appealsCountByCategory;
    }
}