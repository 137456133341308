import TransportDataServersService from "@/services/TransportDataServersService";
import {getError} from "@/utils/helpers";

export const namespaced = true;

export const state = {
    dataServers: [],
    loading: false,
    error: null
}

export const mutations = {
    SET_DATA_SERVERS(state, dataServers) {
        state.dataServers = dataServers;
    },
    SET_LOADING(state, loading) {
        state.loading = loading;
    },
    SET_ERROR(state, error) {
        state.error = error;
    }
}

export const actions = {
    getAllTransportDataServers({commit}) {
        commit("SET_LOADING", true);
        TransportDataServersService.fetchAllTransportDataServers()
            .then(dataServers => commit("SET_DATA_SERVERS", dataServers))
            .catch(error => commit("SET_ERROR", error))
            .finally(() => commit("SET_LOADING", false));
    },
    createTransportDataServer({commit}, payload) {
        return new Promise((resolve, reject) => {
            TransportDataServersService.createTransportDataServer(payload)
                .then(() => resolve())
                .catch(error => {
                    commit("SET_ERROR", getError(error));
                    reject(getError(error));
                })
        })
    },
    updateTransportDataServer({commit}, {dataServerId, payload}) {
        return new Promise((resolve, reject) => {
            TransportDataServersService.updateTransportDataServer({dataServerId, payload})
                .then(() => resolve())
                .catch(error => {
                    commit("SET_ERROR", getError(error));
                    reject(getError(error));
                })
        })
    },
    deleteTransportDataServer({commit}, dataServerId) {
        return new Promise((resolve, reject) => {
            TransportDataServersService.deleteTransportDataServer(dataServerId)
                .then(() => resolve())
                .catch(error => {
                    commit("SET_ERROR", getError(error));
                    reject(getError(error));
                })
        })
    },
    activateTransportDataServer({commit}, dataServerId) {
        return new Promise((resolve, reject) => {
            TransportDataServersService.activateTransportDataServer(dataServerId)
                .then(() => resolve())
                .catch(error => {
                    commit("SET_ERROR", getError(error));
                    reject(getError(error));
                })
        })
    },
    deactivateTransportDataServer({commit}, dataServerId) {
        return new Promise((resolve, reject) => {
            TransportDataServersService.deactivateTransportDataServer(dataServerId)
                .then(() => resolve())
                .catch(error => {
                    commit("SET_ERROR", getError(error));
                    reject(getError(error));
                })
        })
    }
}

export const getters = {
    getTransportDataServers: state => {
        return state.dataServers;
    },
    getTransportDataServer: state => id => {
        return state.dataServers.find(item => item.id === parseInt(id));
    }
}