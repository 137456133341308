<template>
  <div>
    <div class="form-group">
      <label class="form-label">Отчёт</label>
      <v-select
        v-model="report"
        :options="reports"
        :reduce="report => report.key"
        :get-option-label="(option) => option.name"
        placeholder="Выберите отчёт"
        @input="$store.commit('reportsData/SET_REPORT_DATA', [])"/>
    </div>
    <daily-report-form v-if="report === 'DailyReport'"/>
    <accident-report-form v-if="report === 'AccidentReport'"/>
    <issues-count-report-form v-if="report === 'IssuesCountReport'"/>
    <appeals-statistic-report-form v-if="report === 'AppealsCountByCategories'"/>
    <water-flow-report-form v-if="report === 'WaterFlowReport'"/>
    <fuel-and-lubricants-report-form v-if="report === 'FuelAndLubricantsReport'" />
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import DailyReportForm from "@/components/ServiceDesk/ReportsForms/DailyReportForm";
import AccidentReportForm from "@/components/ServiceDesk/ReportsForms/AccidentReportForm";
import IssuesCountReportForm from "@/components/ServiceDesk/ReportsForms/IssuesCountReportForm";
import AppealsStatisticReportForm from "@/components/ServiceDesk/ReportsForms/AppealsStatisticReportForm";
import WaterFlowReportForm from "@/components/ServiceDesk/ReportsForms/WaterFlowReportForm";
import FuelAndLubricantsReportForm from "@/components/ServiceDesk/ReportsForms/FuelAndLubricantsReportForm";

export default {
  name: "ReportsForm",
  components: {
    FuelAndLubricantsReportForm,
    WaterFlowReportForm,
    AppealsStatisticReportForm, IssuesCountReportForm, AccidentReportForm, DailyReportForm
},
  data() {
    return {
      reports: [
        {key: 'DailyReport', name: 'Суточный отчёт ЕДДС'},
        {key: 'AccidentReport', name: 'Отчёт по аварийности'},
        {key: 'IssuesCountReport', name: 'Отчёт показатели в динамике'},
        {key: 'AppealsCountByCategories', name: 'Статистика обращений'},
        {key: 'WaterFlowReport', name: 'Отчет по расходам/потерям воды'},
        {key: 'FuelAndLubricantsReport', name: 'Отчет по расходу ГСМ'}
      ]
    }
  },
  computed: {
    ...mapGetters({
      currentReport: 'reportsData/getCurrentReport'
    }),
    report: {
      get: function () {
        return this.currentReport
      },
      set: function (key) {
        this.$store.commit("reportsData/SET_CURRENT_REPORT", key);
      }
    }
  }
}
</script>

<style scoped>

</style>