<template>
  <div class="custom-card">
    <div class="card-header d-flex align-items-center pb-0">
      <h4 class="network_object__card_title d-inline-block mr-2">
        {{energyConsumptionObject.name}}
      </h4>
      <div class="d-flex">
        <div
          class="network_object__control_btn"
          @click="$bvModal.show('energy-consumption-object-modal')"
        >
          <img
            src="@/assets/img/transport/settings.svg"
            alt="Изменить">
        </div>
        <div
          class="network_object__control_btn"
          @click="deleteEnergyConsumptionObject(energyConsumptionObject.id)"
        >
          <img
            src="@/assets/img/telemetry/trash.svg"
            alt="Удалить">
        </div>
      </div>
    </div>
    <div class="card-body">
      <div class="row">
        <div class="col-md-3 col-sm-6 col-xs-12">
          <div class="network_object__info">
            <div>Адрес</div>
            <div>{{energyConsumptionObject.address}}</div>
          </div>
        </div>
      </div>
    </div>
    <energy-consumption-object-form form-type="update"/>
  </div>
</template>

<script>
import {mapState} from "vuex";
import {showAlert, showToast} from "@/utils/notification";
import EnergyConsumptionObjectForm from "@/components/EnergyConsumption/admin/EnergyConsumptionObjectForm";

export default {
  name: "EnergyConsumptionObjectInfo",
  components: {EnergyConsumptionObjectForm},
  computed: {
    ...mapState({
      energyConsumptionObject: state => state.energyConsumptionObjects.energyConsumptionObject
    })
  },
  methods: {
    deleteEnergyConsumptionObject(id) {
      this.$store.dispatch('energyConsumptionObjects/deleteEnergyConsumptionObject', id)
          .then(() => {
            showToast('Объект энергопотребления удалён', 'success');
            this.$store.dispatch('energyConsumptionObjects/getEnergyConsumptionObjectsListWithMeters');
          })
          .catch(error => showAlert('Ошибка', error))
    }
  }
}
</script>

<style scoped>

</style>