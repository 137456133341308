<template>
  <div class="card border-radius-6">
    <div class="card-body">
      <div class="m-sm-4">
        <div class="project-name">
          {{projectName}}
        </div>
        <form @submit.prevent="login">
          <div class="form-group">
            <label>E-mail</label>
            <input
              v-model="username"
              class="form-control form-control-fix"
              type="email"
              name="email"
              placeholder="Введите Ваш e-mail"
              required
            >
          </div>
          <div class="form-group">
            <label>Пароль</label>
            <input
              v-model="password"
              class="form-control form-control-fix"
              type="password"
              name="password"
              placeholder="Введите Ваш пароль"
              required
            >
          </div>
          <div>
            <div class="custom-control custom-checkbox align-items-center">
              <input
                id="remember"
                type="checkbox"
                class="custom-control-input"
                value="remember-me"
                name="remember-me"
              >
              <label
                for="remember"
                class="custom-control-label text-small"
              >Запомнить этот компьютер</label>
            </div>
          </div>
          <div class="text-center mt-3">
            <button
              type="submit"
              class="btn custom-btn-blue"
              :disabled="loading"
            >
              Авторизоваться
              <base-loader
                v-if="loading"
                color-class="text-light"
              />
            </button>
          </div>
        </form>
      </div>
      <flash-message :error="error" />
    </div>
  </div>
</template>

<script>
import FlashMessage from "@/components/FlashMessage";
import {mapGetters} from "vuex";
import BaseLoader from "@/layout/BaseLoader";

export default {
  name: "LoginPage",
  components: {
    BaseLoader,
    FlashMessage
  },
  data() {
    return {
      username: null,
      password: null,
      error: null
    };
  },
  computed: {
    ...mapGetters({
      projectName: 'projectConfig/getProjectName',
      loading: 'auth/loading'
    })
  },
  methods: {
    async login() {
      const payload = {
        username: this.username,
        password: this.password
      };
      this.error = null;
      try {
        await this.$store.dispatch('auth/login', payload);
        const authUser = await this.$store.dispatch("auth/getAuthUser");
        if (authUser) {
          this.$store.dispatch("auth/setGuest", {value: "isNotGuest"});
          this.$store.dispatch("auth/subscribeWSChannels");
          this.$router.push("/");
        } else {
          const error = Error(
              "Не удается получить данные пользователя, проверьте настройки API."
          );
          error.name = "Fetch User";
          throw error;
        }
      } catch (error) {
        this.$store.commit("auth/SET_LOADING", false);
        if (typeof error.response.data === 'string') this.error = 'ошибка API, попробуйте ещё раз.'
        else this.error = error.response.data.errors;
      }
    }
  }
}
</script>

<style scoped>
.border-radius-6 {
  border-radius: 6px !important;
}

.project-name {
  font-weight: 700;
  font-size: 2.15rem;
  padding: 0px 1rem 0.5rem 1rem;
  color: #262F3D;
  display: block;
  text-align: center;
}
</style>
