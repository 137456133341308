<template>
  <div class="row">
    <div class="col-12 mb-2">
      <div class="custom-card">
        <div class="card-body d-flex justify-content-between align-items-center">
          <div class="task-card-title mb-0 d-flex align-items-center">
            Расходы воды
            <div class="items-count">{{ waterFlows.length }}</div>
          </div>
          <div class="task_component__actions_wrapper">
            <div
              v-if="task.task.state.id !== TASK_STATE.CLOSED
                && task.task.state.id !== TASK_STATE.CANCELED"
              class="task_component__action">
              <plus-icon
                @click="$bvModal.show('add-water-flow')"
                class="chevron" />
            </div>
            <div
              v-if="waterFlows.length"
              class="task_component__action">
              <chevron-right-icon
                @click="collapseVisible = !collapseVisible"
                class="chevron"
                :class="{'rotate': collapseVisible}" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <b-collapse
      v-model="collapseVisible"
      class="col-12">
      <div v-for="waterFlow in waterFlows"
           :key="waterFlow.id"
           class="custom-card mb-2 comment">
        <div class="card-body">
          <div class="d-flex justify-content-between mb-1">
            <div class="water-flow-type">{{ waterFlow.typeName }}</div>
            <trash2-icon
              @click="deleteWaterFlow(waterFlow.id)"
              class="cursor-pointer text-dark" />
          </div>
          <div class="task_component__param">
            Объём: <span>{{waterFlow.volume}}м<sup>3</sup></span>
          </div>
          <span class="task_component__param">
            Дата расхода: <span>{{waterFlow.releasedAt | formatDate}}</span>
          </span>
          <span class="task_component__param"
                v-if="waterFlow.comment">
            Комментарий: <span>{{ waterFlow.comment }}</span>
          </span>
        </div>
      </div>
    </b-collapse>

    <add-water-flow />
  </div>
</template>

<script>
import {mapState} from "vuex";
import {showAlert, showToast} from "@/utils/notification";
import AddWaterFlow from "@/components/ServiceDesk/Tasks/ActionsForms/AddWaterFlow";
import TaskState from "@/models/serviceDesk/TaskState";

export default {
  name: "WaterFlowsList",
  components: {AddWaterFlow},
  data() {
    return {
      collapseVisible: false
    }
  },
  computed: {
    ...mapState({
      waterFlows: state => state.waterFlows.taskWaterFlows,
      task: state => state.task.task
    }),
    TASK_STATE: () => TaskState
  },
  methods: {
    deleteWaterFlow(id) {
      this.$store.dispatch('waterFlows/removeWaterFlow', {
        taskId: this.task.task.id,
        waterFlowId: id
      })
          .then(() => {
            this.$store.commit('waterFlows/DELETE_WATER_FLOW', id);
            showToast('Расход воды удалён', 'success');
          })
          .catch(error => showAlert('Ошибка', error));
    }
  }
}
</script>

<style scoped>
.water-flow-type {
  font-size: 16px;
  font-weight: 600;
  color: #262F3D;
}

.text-dark {
  color: #262F3D;
}

.works-list li span {
  font-size: 14px;
  font-weight: 400;
  color: #468FC5;
}
</style>