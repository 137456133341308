<template>
  <table class="admin-table">
    <thead class="admin-table-header">
      <tr>
        <td>ID</td>
        <td>Наименование</td>
        <td>Гос.номер</td>
        <td>Тип</td>
        <td>Внешний ID</td>
        <td>Статус</td>
        <td>Действия</td>
      </tr>
    </thead>
    <tbody class="admin-table-body">
      <tr
        v-for="auto in this.$store.state.autos.autos"
        :key="auto.id"
      >
        <td>{{ auto.id }}</td>
        <td>{{ auto.attributes.label }}</td>
        <td>{{ auto.attributes.gov_number }}</td>
        <td>{{ auto.getTypeLabel() }}</td>
        <td>{{ auto.attributes.external_id }}</td>
        <td>
          {{auto.attributes.state.name}}
          <div
            class="vehicle__change_state"
            @click="changeVehicleState(auto)">Сменить статус</div>
        </td>
        <td class="table-action">
          <router-link :to="'/admin-autos-update/'+auto.id">
            <edit-icon class="mr-1" />
          </router-link>
          <a @click="deleteAuto(auto.id)">
            <trash2-icon />
          </a>
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
import AutosService from "@/services/AutosService";
import {showToast, showAlert} from "@/utils/notification";
import {mapState} from "vuex";

export default {
  name: "AutosList",
  computed: {
    ...mapState({
      currentPage: state => state.autos.page
    })
  },
  methods:{
    changeVehicleState(vehicle) {
      if (vehicle.attributes.state.id === 1) {
        this.$store.dispatch('autos/sendVehicleToRepair', vehicle.id)
            .then(() => {
              showToast('Статус изменён', 'success');
              this.$store.dispatch('autos/getAutos', this.currentPage || 1);
            })
            .catch(error => showAlert('Ошибка', error))
      } else {
        this.$store.dispatch('autos/makeVehicleAvailable', vehicle.id)
            .then(() => {
              showToast('Статус изменён', 'success');
              this.$store.dispatch('autos/getAutos', this.currentPage || 1);
            })
            .catch(error => showAlert('Ошибка', error))
      }
    },
    async deleteAuto(auto_id){
      this.$swal({
        title: 'Вы действительно хотите удалить транспорт?',
        showCancelButton: true,
        confirmButtonText: `Да`,
        cancelButtonText: 'Отмена',
        icon: 'warning'
      }).then(result=>{
        if (result.isConfirmed) {
          AutosService.deleteAuto(auto_id).then(() => {
            this.$swal('Транспорт успешно удалена!', '', 'success')
            this.$store.commit('autos/DELETE_AUTOS', auto_id);
          })
        }
      });
    }
  }
}
</script>

<style scoped>
.vehicle__change_state {
  color: #468FC5;
  font-weight: 600;
  cursor: pointer;
}
</style>