<template>
  <b-modal
    id="energy-consumption-object-modal"
    centered
    hide-header
    hide-footer
    @shown="fillFormFields()"
    @hidden="clearFormFields()"
  >
    <div class="col-12">
      <div class="custom-modal-header">
        <div
          v-if="formType === 'create'"
          class="custom-modal-title">
          Создать объект энергопотребления
        </div>
        <div
          v-else
          class="custom-modal-title">
          Изменить объект энергопотребления
        </div>
        <img
          class="custom-modal-close"
          src="@/assets/img/icons/close.svg"
          alt="Закрыть"
          @click="$bvModal.hide('energy-consumption-object-modal')"
        >
      </div>
      <div class="modal-divider" />
    </div>
    <form @submit.prevent="submitForm()">
      <div class="col-12 mb-2">
        <div class="form-group">
          <label class="form-label">Наименование</label>
          <input
            v-model="payload.name"
            type="text"
            class="form-control form-control-fix"
            placeholder="Введите наименование">
        </div>
        <div class="form-group">
          <label class="form-label">Адрес</label>
          <dadata-suggestions
            class="form-control form-control-fix"
            v-model="payload.address"
            placeholder="Начните вводить адрес"
          />
        </div>
        <div class="modal-divider" />
        <div class="row">
          <div class="col-12">
            <button
              type="submit"
              class="btn custom-btn-blue w-100"
            >
              Сохранить
            </button>
          </div>
        </div>
      </div>
    </form>
  </b-modal>
</template>

<script>
import Vue from "vue";
import DadataSuggestions from "vue-dadata-suggestions";
import {showToast, showAlert} from "@/utils/notification";
import {mapState} from "vuex";

Vue.use(DadataSuggestions, {
  token: process.env.VUE_APP_DADATA_API_KEY,
  type: 'ADDRESS',
  geoLocation: [{
    "kladr_id": process.env.VUE_APP_DADATA_KLADR_ID
  }]
});
export default {
  name: "EnergyConsumptionObjectForm",
  props: {
    formType: {
      required: true,
      type: String
    }
  },
  data() {
    return {
      payload: {
        name: null,
        address: null
      }
    }
  },
  computed: {
    ...mapState({
      energyConsumptionObject: state => state.energyConsumptionObjects.energyConsumptionObject
    })
  },
  methods: {
    submitForm() {
      if (this.formType === 'create') this.createEnergyConsumptionObject()
      else this.updateEnergyConsumptionObject()
    },
    createEnergyConsumptionObject() {
      this.$store.dispatch('energyConsumptionObjects/createEnergyConsumptionObject', this.payload)
          .then(() => {
            showToast('Объект энергопотребления добавлен', 'success');
            this.$bvModal.hide('energy-consumption-object-modal');
            this.$store.dispatch('energyConsumptionObjects/getEnergyConsumptionObjectWithMeters',
                this.$route.params.id);
          })
          .catch(error => showAlert('Ошибка', error))
    },
    updateEnergyConsumptionObject() {
      this.$store.dispatch('energyConsumptionObjects/updateEnergyConsumptionObject', {
        id: this.energyConsumptionObject.id,
        payload: this.payload
      })
          .then(() => {
            showToast('Объект энергопотребления обновлен', 'success');
            this.$bvModal.hide('energy-consumption-object-modal');
            this.$store.dispatch('energyConsumptionObjects/getEnergyConsumptionObjectWithMeters',
                this.$route.params.id);
          })
          .catch(error => showAlert('Ошибка', error))
    },
    fillFormFields() {
      if (this.formType === 'update') {
        this.payload = {
          name: this.energyConsumptionObject.name,
          address: this.energyConsumptionObject.address
        }
      }
    },
    clearFormFields() {
      this.payload = {
        name: null,
        address: null
      }
    }
  }
}
</script>

<style scoped>

</style>