<template>
  <div class="row">
    <div class="col-xl-7 col-xs-12"
         :class="{'col-xl-12': hideMap}">
      <appeals-list-filter/>

      <div class="row">
        <div class="col-md-12 d-flex align-items-center mb-3">
          <div class="list-header mr-2">Обращения</div>
          <base-loader v-if="$store.state.appeals.loading" />
        </div>

        <div class="col-md-12 items-list-overflow">
          <appeals-list />
        </div>
      </div>

      <hr>

      <div class="row mt-3">

        <appeals-page-issues-filter />

        <div class="col-md-12 d-flex align-items-center mb-3">
          <div class="list-header mr-2">Заявки</div>
          <base-loader v-if="$store.state.issues.loading" />
        </div>

        <div
          v-if="!$store.state.issues.issues.length
            && !$store.state.issues.loading"
          class="col-md-12"
        >
          <div class="no-result-title">
            Заявки не найдены
          </div>
        </div>

        <div
          v-else-if="$store.state.issues.issues.length"
          class="col-md-12 items-list-overflow"
        >
          <issues-list
            :headers="headers"
            :issues="issues"
          />
        </div>

        <div
          v-if="this.$store.state.issues.meta
            && this.$store.state.issues.meta.last_page > 1"
          class="col-md-12"
        >
          <base-paginate
            :meta="$store.state.issues.meta"
            action="issues/getIssues"
            commit="issues/SET_PAGE"
          />
        </div>

      </div>

    </div>
    <div v-show="!hideMap"
         class="col-xl-5 col-xs-12">
      <appeals-list-map/>
    </div>
    <create-appeal-form/>
    <appeal-modal v-if="$store.state.appeals.appeal"/>
  </div>
</template>

<script>
import AppealsListFilter from "@/components/CallCenter/AppealsListFilter";
import AppealsListMap from "@/components/CallCenter/AppealsListMap";
import AppealsList from "@/components/CallCenter/AppealsList";
import {mapGetters} from "vuex";
import CreateAppealForm from "@/components/CallCenter/CreateAppealForm";
import AppealModal from "@/components/CallCenter/AppealModal";
import IssuesList from "@/components/ServiceDesk/Issues/IssuesList";
import BaseLoader from "@/layout/BaseLoader";
import BasePaginate from "@/components/BasePaginate";
import IssueState from "@/models/serviceDesk/IssueState";
import AppealsPageIssuesFilter from "@/components/CallCenter/AppealsPageIssuesFilter";

export default {
  name: "AppealsListPage",
  components: {
    AppealsPageIssuesFilter,
    IssuesList,
    AppealModal,
    CreateAppealForm,
    AppealsList,
    AppealsListMap,
    AppealsListFilter,
    BaseLoader,
    BasePaginate
  },
  data() {
    return {
      timing: null,
      headers: [
        {text: '#', value: 'id', type: 'text'},
        {text: 'Дата открытия', value: 'created_at', type: 'dateTime'},
        {text: 'Тип', value: 'type_name', type: 'text'},
        {text: 'Категория', value: 'category', type: 'category'},
        {text: 'Адрес', value: 'address', type: 'address'},
        {text: 'Комментарий', value: 'description', type: 'text'},
        {text: 'Статус', value: 'state', type: 'badge'}
      ]
    }
  },
  computed: {
    ...mapGetters({
      hideMap: 'appeals/getHideMap',
      filterParams: 'appeals/getFilterParams',
      issues: 'issues/getIssues'
    })
  },
  mounted() {
    this.fetchData();
    this.$store.dispatch('issueTypes/getIssueTypes');

    this.timing = setInterval(this.fetchData, process.env.VUE_APP_SERVICE_DESK_GET_DATA_TIMING);
  },
  beforeDestroy() {
    this.$store.commit("appeals/SET_FILTER_PARAMS", null);
    this.$store.commit('appeals/SET_APPEALS', []);

    this.$store.commit("issues/SET_FILTER_PARAMS", null);
    this.$store.commit("issues/SET_ALL_ISSUES", []);
    this.$store.commit("issues/SET_ISSUES", []);
    clearInterval(this.timing);
  },
  methods: {
    fetchData() {
      this.$store.dispatch("appeals/getAppeals", this.$store.state.appeals.page);

      this.$store.commit('issues/SET_FILTER_PARAMS', {
        issue_states: [
          IssueState.NEW,
          IssueState.APPOINTED,
          IssueState.ACCEPTED,
          IssueState.IN_WORK,
          IssueState.REJECTED
        ].join()
      });
      this.$store.dispatch('issues/getIssues', this.$store.state.issues.page);
    }
  }
}
</script>

<style scoped>
.list-header {
  font-size: 22px;
  font-weight: 600;
  color: #262F3D;
}
</style>