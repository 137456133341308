<template>
  <div class="row">
    <div class="col-xl-12">
      <transport-orders-filter />
      <div class="custom-card">
        <div class="card-body">
          <div class="row">
            <div v-if="!transportOrders.length && !loading"
                 class="col-md-12">
              <div class="no-result-title">
                Заказы транспорта не найдены
              </div>
            </div>

            <div v-else-if="transportOrders.length"
                 class="col-md-12"
            >
              <transport-orders-list />
            </div>

            <div v-if="loading"
                 class="col-md-12 text-center mt-2">
              <base-loader />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TransportOrdersFilter from "@/components/ServiceDesk/TransportOrdersFilter";
import {mapState} from "vuex";
import TransportOrdersList from "@/components/ServiceDesk/TransportOrdersList";
import BaseLoader from "@/layout/BaseLoader";
export default {
  name: "TransportOrdersListPage",
  components: {BaseLoader, TransportOrdersList, TransportOrdersFilter},
  computed: {
    ...mapState({
      transportOrders: state => state.transportOrders.transportOrders,
      loading: state => state.transportOrders.loading
    })
  },
  mounted() {
    this.$store.commit('transportOrders/SET_FILTER_PARAMS', {state: 1});
    this.$store.dispatch('transportOrders/getTransportOrders');
  }
}
</script>

<style scoped>

</style>