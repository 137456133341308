<template>
  <b-tab
    title="Результат"
    title-item-class="custom-nav-item"
    title-link-class="custom-nav-link"
  >
    <!--    <button class="back-btn">
      <chevron-left-icon />
      <span>Вернуться назад</span>
    </button>-->
    <h4 class="tab-title mb-20">
      Результат:
    </h4>

    <div v-if="loading"
         class="loader-position">
      <base-loader width="1rem !important"
                   height="1rem !important"
      />
    </div>

    <div
      v-if="this.$store.state.building.buildings.length === 0"
      class="row"
    >
      <div class="col-12">
        <div class="no-result-title">
          Здания не найдены.
        </div>
        <div class="no-result-text">
          Попробуйте упросить параметры фильтрации.
        </div>
      </div>
    </div>

    <div
      id="filter-result"
      class="row"
    >
      <div
        v-for="building in this.$store.getters['building/getBuildings']"
        :key="building.id"
        class="col-12 d-flex justify-content-end mb-3 building-item"
      >
        <div class="result-item">
          <div class="">
            <div class="address-wrapper">
              <div
                class="result-address"
                @click="getBuildingDetail(building.id)"
              >
                {{ getFullAddress(building) }}
              </div>
              <div class="result-map"
                   @click="$store.commit(
                     'baseMap/SET_CENTER_ZOOM',
                     { center: building.attributes.geometry.coordinates, zoom: 17})
                   ">
                <img
                  alt="Показать на карте"
                  src="@/assets/img/billing/map.svg"
                >
              </div>
            </div>
            <div class="building-params ">
              <div v-if="building.attributes.fact_consumption != null">
                Фактическое водопотребление:
                <span class="building-param-value">
                  {{building.attributes.fact_consumption.toFixed(2)}}м<sup>3</sup>/месяц
                </span>
              </div>
              <div v-if="building.attributes.fact_drainage != null">
                Фактическое водоотведение:
                <span class="building-param-value">
                  {{ building.attributes.fact_drainage.toFixed(2) }}м<sup>3</sup>/месяц
                </span>
              </div>
              <div v-if="building.attributes.normative_consumption != null">
                ТУ водопотребление:
                <span class="building-param-value">
                  {{ building.attributes.normative_consumption.toFixed(2) }}м<sup>3</sup>/месяц
                </span>
              </div>
              <div v-if="building.attributes.normative_drainage != null">
                ТУ водоотведение:
                <span class="building-param-value">
                  {{ building.attributes.normative_drainage.toFixed(2) }}м<sup>3</sup>/месяц
                </span>
              </div>
              <div v-if="building.attributes.fact_ratio != null">
                Водопотребление/водоотведение:
                <span :style="{'color': getDifferenceColor(building.attributes.fact_ratio)}">
                  {{ getRatioPercentDifference(building.attributes.fact_ratio) }}
                </span>
              </div>
              <div v-if="building.attributes.consumption_ratio != null">
                Водопотребление/ТУ:
                <span :style="{'color': getDifferenceColor(building.attributes.consumption_ratio)}">
                  {{ getRatioPercentDifference(building.attributes.consumption_ratio) }}
                </span>
              </div>
              <div v-if="building.attributes.drainage_ratio != null">
                Водоотведение/ТУ:
                <span :style="{'color': getDifferenceColor(building.attributes.drainage_ratio)}">
                  {{ getRatioPercentDifference(building.attributes.drainage_ratio) }}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <base-pagination-payload
      v-if="this.$store.state.building.meta && this.$store.state.building.meta.last_page > 1"
      :links="this.$store.state.building.links"
      :meta="this.$store.state.building.meta"
      :payload="generatePaginationPayload(this.filter_params)"
      action="building/paginateBuildings"
      path="billing-buildings"
    />
  </b-tab>
</template>

<script>
import BasePaginationPayload from "@/components/BasePaginationPayload";
import notification from "@/mixins/notification";
import BaseLoader from "@/layout/BaseLoader";
import {mapGetters} from "vuex";

export default {
  name: "BillingBuildingsFilterResult",
  components: {BaseLoader, BasePaginationPayload},
  mixins: [notification],
  props: ['filter_params'],
  computed: {
    ...mapGetters({
      loading: 'building/getBuildingLoading'
    }),
    generatePaginationPayload: function () {
      return filter_params => {
        return {
          data: filter_params,
          link: 0
        }
      }
    },
    getFullAddress: function () {
      return building => {
        let full_address_arr = [building.attributes.city, building.attributes.settlement, building.attributes.street,
          building.attributes.house, building.attributes.block].filter(item => item !== null)
        return full_address_arr.join(', ')
      }
    }
  },
  mounted() {
    this.$root.$on("bv::collapse::state", (collapseID, opened) => {
      let arrow_element = document.getElementById(collapseID + '_icon')
      if (opened) arrow_element.style.transform = 'rotateZ(90deg)'
      else arrow_element.style.transform = 'rotate(0deg)'
    })
  },
  methods: {
    getBuildingDetail(id) {
      this.$store.dispatch("building/getBuildingDetail", id)
          .then(() => {
            this.$store.commit('building/SET_TAB_INDEX', 2);
          })
          .catch(() => {
            this.alert('Ошибка', this.$store.state.building.error, 'error')
          })
    },
    getRatioPercentDifference(ratio) {
      let percentDifference = (ratio * 100) - 100
      if (ratio <= 1) {
        return percentDifference.toFixed(0) + '%'
      } else {
        return '+' + percentDifference.toFixed(0) + '%'
      }
    },
    getDifferenceColor(ratio) {
      if (ratio >= 0 && ratio <= 1.2) return '#04bd00'
      else if (ratio > 1.2 && ratio <= 2) return '#ffbf1c'
      else if (ratio > 2 && ratio <= 4) return '#ff6b1c'
      else if (ratio > 4) return '#E02800'
    }
  }
}
</script>

<style scoped>
.tab-title {
  font-size: 24px;
  font-weight: 700;
  color: #262F3D;
  margin-bottom: 40px;
}

.mb-20 {
  margin-bottom: 20px;
}

.result-item {
  width: 100%;
  padding: 10px 15px;
  background-color: white;
  border: 1px solid #468FC5;
  border-radius: 6px;
  margin-right: 45px;
}

.address-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.result-address {
  display: flex;
  color: #262F3D !important;
  font-weight: 600;
  cursor: pointer;
}

.result-map {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  background-color: #EBF1F3;
  border-radius: 6px;
  position: absolute;
  right: 10px;
  cursor: pointer;
}

.result-map img {
  width: 16px;
}

.address-wrapper img {
  transition: .2s;
}

.no-result-title {
  font-size: 20px;
  font-weight: 500;
  color: black;
  text-align: center;
  line-height: 1;
}

.no-result-text {
  font-size: 15px;
  text-align: center;
}

.loader-position {
  position: absolute;
  right: 20px;
  top: 50px;
}

.building-params div {
  color: #97ACB5;
  font-weight: 600;
}

.building-param-value {
  color: #468FC5;
  font-weight: 600;
}

/*.back-btn {
  display: flex;
  align-items: center;
  border: 1px solid #468FC5;
  border-radius: 5px;
  background-color: #fff;
  color: #468FC5;
  margin-bottom: 10px;
}

.back-btn span {
  line-height: 1;
}*/
</style>