import NetworkEquipmentParameter from "@/models/telemetry/NetworkEquipmentParameter";
import NetworkObject from "@/models/telemetry/NetworkObject";

export default class NetworkEquipment {
    constructor(params) {
        this.id = params.id;
        this.name = params.name;
        this.typeId = params.type.id || null;
        this.typeName = params.type.attributes.name || null;
        this.networkObjectId = params.network_object.id;
        this.networkObject = new NetworkObject({
            id: params.network_object.id,
            attributes: params.network_object.attributes
        });
        this.serial = params.serial;
        this.durability = params.durability;
        this.buildDate = params.build_date;
        this.installationDate = params.installation_date;
        this.parameters = params.parameters.map(item => new NetworkEquipmentParameter({
            id: item.id,
            ...item.attributes
        }))
    }
}