<template>
  <b-modal
    id="add-emergency-element"
    ref="add-emergency-element"
    centered
    hide-header
    hide-footer
    size="xl"
    @shown="needResize = true"
    @hidden="needResize = false"
  >
    <div class="col-12">
      <div class="custom-modal-header">
        <div class="custom-modal-title">
          Добавить аварийный элемент
        </div>
        <img
          class="custom-modal-close"
          src="@/assets/img/icons/close.svg"
          @click="$bvModal.hide('add-emergency-element')"
        >
      </div>
      <div class="modal-divider"/>
    </div>
    <div class="row modal-wrapper">
      <div class="col-md-5 col-sm-12">
        <form @submit.prevent="submitForm()">
          <div class="form-group">
            <label class="form-label">Сеть</label>
            <v-select
              v-model="payload.network_id"
              :options="networks"
              :reduce="network => network.id"
              :get-option-label="(option) => option.name"
              placeholder="Выберите сеть"
            />
          </div>
          <div class="form-group">
            <label class="form-label">Тип элемента</label>
            <v-select
              v-model="payload.element_type"
              :options="elementTypes"
              :reduce="elementType => elementType.id"
              :get-option-label="(option) => option.name"
              placeholder="Выберите тип элемента"/>
          </div>
          <div v-for="(feature, index) in payload.features"
               :key="index"
               class="form-group">
            <label class="form-label">{{ feature.feature_name }}</label>
            <input v-model="feature.value"
                   placeholder="Введите значение"
                   type="text"
                   class="form-control form-control-fix">
          </div>
          <div class="form-group">
            <label class="form-label">Комментарий</label>
            <textarea class="form-control form-control-fix"
                      maxlength="1500"
                      placeholder="Введите комментарий"
                      v-model="payload.comment"/>
          </div>
          <div class="modal-divider"/>
          <div class="row">
            <div class="col-12">
              <button
                type="submit"
                class="btn custom-btn-blue w-100"
              >
                Добавить
              </button>
            </div>
          </div>
        </form>
      </div>
      <div class="col-md-7 col-sm-12">
        <div class="map-wrapper mb-3">
          <add-emergency-element-map :needResize="needResize" />
        </div>
        <div
          v-if="elements.length"
          class="network-elements-list-wrapper">
          <table class="service-desk-table">
            <thead class="service-desk-table-header">
              <tr>
                <td />
                <td>Sys</td>
                <td>Тип</td>
              </tr>
            </thead>
            <tbody class="service-desk-table-body">
              <tr
                v-for="element in elements"
                :key="element.id"
              >
                <td>
                  <label class="custom-control custom-radio m-0">
                    <input v-model="selectedElementId"
                           :value="element.id"
                           class="custom-control-input"
                           name="custom-radio"
                           type="radio">
                    <span class="custom-control-label" />
                  </label>
                </td>
                <td>{{ element.externalId }}</td>
                <td>{{ element.typeName }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </b-modal>
</template>

<script>
import {mapGetters} from "vuex";
import notification from "@/mixins/notification";
import AddEmergencyElementMap from "@/components/ServiceDesk/Tasks/ActionsForms/AddEmergencyElementMap";

export default {
  name: "AddEmergencyElement",
  components: {AddEmergencyElementMap},
  mixins: [notification],
  data() {
    return {
      payload: {
        network_id: null,
        network_element: null,
        element_type: null,
        comment: null,
        features: [
          {feature_type: 1, feature_name: 'Материал', value: null},
          {feature_type: 2, feature_name: 'Длина', value: null},
          {feature_type: 3, feature_name: 'Диаметр', value: null}
        ]
      },
      needResize: false,
      selectedElementId: null
    }
  },
  computed: {
    ...mapGetters({
      networks: 'engineeringNetworks/getEngineeringNetworks',
      elementTypes: 'networkElementTypes/getElementTypes',
      elements: 'networkElements/getNetworkElements',
      element: 'networkElements/getNetworkElement',
      task: 'task/getTask'
    })
  },
  watch: {
    selectedElementId: function (newElementId, oldElementId) {
      this.$store.commit('networkElements/SELECT_ELEMENT', {newElementId, oldElementId});

      const element = this.element(newElementId);
      this.payload.element_type = element.typeId;
      this.payload.network_element = element.id;
    }
  },
  mounted() {
    this.$store.dispatch('engineeringNetworks/getAllEngineeringNetworks');
    this.$store.dispatch('networkElementTypes/getNetworkElementTypes');

    this.$root.$on('BaseMap::map-click', coords => {
      this.$store.commit('networkElements/SET_FILTER_PARAMS', {
        lat: coords.lat,
        lon: coords.lng,
        network_id: this.payload.network_id
      })
      this.$store.dispatch('networkElements/getAllNetworkElements');
    })
  },
  methods: {
    submitForm() {
      this.payload.features = this.payload.features.map(feature => {
        return {feature_type: feature.feature_type, value: feature.value}
      })

      this.$store.dispatch('emergencyElements/createEmergencyElement', {
        issue_id: this.task.issue.id,
        payload: this.payload
      })
          .then(() => {
            this.$bvModal.hide('add-emergency-element');
            this.toast('Аварийный элемент добавлен', 'success');
            this.$store.dispatch('emergencyElements/getEmergencyElements', this.task.issue.id);
            this.$store.commit('networkElements/SET_ELEMENTS', []);

            this.payload = {
              network_id: null,
              network_element: null,
              element_type: null,
              comment: null,
              features: [
                {feature_type: 1, feature_name: 'Материал', value: null},
                {feature_type: 2, feature_name: 'Длина', value: null},
                {feature_type: 3, feature_name: 'Диаметр', value: null}
              ]
            }
          })
          .catch(error => {
            this.alert('Ошибка', error, 'error');
          });
    }
  }
}
</script>

<style scoped>
.modal-wrapper {
  padding: 8px 16px;
}

.map-wrapper {
  height: 50%;
}

.network-elements-list-wrapper {
  max-height: 272px;
  overflow-y: auto;
  padding-right: 10px;
}

.network-elements-list-wrapper::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  background-color: #d6d6d6;
  border-radius: 0.2em;
}

.network-elements-list-wrapper::-webkit-scrollbar-thumb {
  background-color: rgba(151, 172, 181, 1);
  border-radius: 0.2em;
}

@media (max-width: 767px) {
  .map-wrapper {
    margin-top: 16px;
    height: 300px;
  }
}
</style>