<template>
  <base-map :center="center"
            :zoom="zoom"
            :markers="this.marker"
            id-key="-create-appeal"
            :need-resize="needResize"
            base_layers_control_position_top="10px"
  />
</template>

<script>
import notification from "@/mixins/notification";
import BaseMap from "@/components/BaseMap";
import Marker from "@/models/baseMap/Marker";
import Icon from "@/models/baseMap/Icon";
import {mapGetters, mapState} from "vuex";

export default {
  name: "CreateAppealMap",
  components: {BaseMap},
  mixins: [notification],
  props: ['needResize'],
  data() {
    return {
      marker: []
    }
  },
  computed: {
    ...mapGetters({
      coordinates: 'projectConfig/getProjectCoordinates',
      mapZoom: 'projectConfig/getProjectZoom'
    }),
    ...mapState({
      center: state => state.baseMap.center,
      zoom: state => state.baseMap.zoom
    })
  },
  mounted() {
    this.$root.$on('fit-bounds-map', dadataCallback => {
      let qc_geo = parseInt(dadataCallback.qc_geo)
      if (qc_geo <= 1) {
        this.zoom = 18;
        this.center = dadataCallback.coords;
        this.createMarker(dadataCallback.coords);

      } else if (qc_geo > 1 && qc_geo < 4) {
        this.createMarker(dadataCallback.coords);
        this.zoom = 16;
        this.center = dadataCallback.coords;

      } else {
        this.toast('Координаты по указанному адресу не найдены, поставьте отметку вручную', 'warning')
      }
    })

    this.$root.$on('BaseMap::map-click', coords => {
      this.createMarker(coords);
    })
  },
  methods: {
    createMarker(coords) {
      this.marker = [
        new Marker({
          coords: coords,
          icon: new Icon({
            type: 'icon',
            url: require('@/assets/img/icons/pin-low.svg'),
            size: [36, 36],
            anchor: [18, 36]
          })
        })
      ]
    }
  }
}
</script>

<style scoped>
</style>
