<template>
  <div class="row">
    <div class="col-12 mb-2">
      <div class="custom-card">
        <div class="card-body d-flex justify-content-between align-items-center">
          <div class="task-card-title mb-0 d-flex align-items-center">
            Комментарии
            <div class="items-count">{{ comments.length }}</div>
          </div>
          <div class="task_component__actions_wrapper">
            <div class="task_component__action">
              <plus-icon
                @click="$bvModal.show('add-comment')"
                class="chevron" />
            </div>
            <div
              v-if="comments.length"
              class="task_component__action">
              <chevron-right-icon
                @click="collapseVisible = !collapseVisible"
                class="chevron"
                :class="{'rotate': collapseVisible}" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <b-collapse id="comments-list"
                v-model="collapseVisible"
                class="col-12">
      <div v-for="comment in comments"
           :key="comment.id"
           class="custom-card mb-2 comment">
        <div class="card-body">
          <div class="row">
            <div class="col-8 comment__text">{{ comment.comment }}</div>
            <div class="col-4">
              <span class="comment__author">{{comment.author}}</span>
              <span class="comment__date">{{ comment.created_date | formatDateTime }}</span>
            </div>
          </div>
        </div>
      </div>
    </b-collapse>

    <add-comment-form />
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import AddCommentForm from "@/components/ServiceDesk/Tasks/ActionsForms/AddCommentForm";

export default {
  name: "CommentsList",
  components: {AddCommentForm},
  props: {
    showAddBtn: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      collapseVisible: false
    }
  },
  computed: {
    ...mapGetters({
      comments: 'comments/getComments'
    })
  }
}
</script>

<style scoped>
.comment__text {
  color: #262F3D;
  line-height: 1.2;
  font-size: 15px;
  display: flex;
  align-items: center;
}

.comment__author {
  display: block;
  text-align: right;
  line-height: 1.2;
  color: #97ACB5;
  font-size: 12px;
}

.comment__date {
  display: block;
  text-align: right;
  line-height: 1.2;
  color: #97ACB5;
  font-size: 12px;
}
</style>