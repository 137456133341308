<template>
  <span class="badge"
        :style="{'background-color': color}">
    {{label}}
  </span>
</template>

<script>
export default {
  name: "BaseBadge",
  props: {
    label: {
      type: String,
      required: true
    },
    color: {
      type: String,
      default: '#468FC5'
    }
  }
}
</script>

<style scoped>

</style>