<template>
  <div class="row">
    <div class="col-lg-12">
      <div class="custom-card">
        <div class="card-header pb-0">
          <h4 class="table-title d-inline-block">
            Автопарк
          </h4>
          <router-link
            to="/admin-autos-create"
            class="btn custom-btn-blue-outline float-right"
          >
            Добавить технику
          </router-link>
          <hr class="divider">
        </div>
        <div class="card-body">
          <div class="container-fluid">
            <div class="row">
              <autos-table/>
              <div class="col-md-12"
                   v-if="$store.state.autos.meta && $store.state.autos.meta.last_page > 1">
                <div class="float-right">
                  <base-paginate
                    :meta="$store.state.autos.meta"
                    action="autos/getAutos"
                    commit="autos/SET_PAGE"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AutosTable from "@/components/Transport/admin/AutosList";
import BasePaginate from "@/components/BasePaginate";

export default {
  name: "AutosPage",
  components: {
    BasePaginate,
    AutosTable
  },
  mounted() {
    this.$store.dispatch('autos/getAutos', 1);
  }
}
</script>

<style scoped>

</style>