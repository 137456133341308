import ServiceDeskCategoriesService from "@/services/ServiceDeskCategoriesService";
import {getError} from "@/utils/helpers";

export const namespaced = true;

export const state = {
    categories: [],
    categoryGroups: [],
    categoriesLoading: false,
    categoryGroupsLoading: false,
    categoriesError: null,
    categoryGroupsError: null,
    page: null,
    meta: null
}

export const mutations = {
    SET_CATEGORIES(state, categories) {
        state.categories = categories;
    },
    SET_CATEGORY_GROUPS(state, groups) {
        state.categoryGroups = groups;
    },
    SET_CATEGORIES_LOADING(state, loading) {
        state.categoriesLoading = loading;
    },
    SET_CATEGORY_GROUPS_LOADING(state, loading) {
        state.categoryGroupsLoading = loading;
    },
    SET_CATEGORIES_ERROR(state, error) {
        state.categoriesError = error;
    },
    SET_CATEGORY_GROUPS_ERROR(state, error) {
        state.categoryGroupsError = error;
    },
    SET_PAGE(state, page) {
        state.page = page;
    },
    SET_META(state, meta) {
        state.meta = meta;
    }
}

export const actions = {
    getCategories({commit}, page) {
        commit("SET_CATEGORIES_LOADING", true);
        ServiceDeskCategoriesService.fetchCategories(page)
            .then(({categories, meta}) => {
                commit("SET_CATEGORIES", categories);
                commit("SET_META", meta);
            })
            .catch(error => commit("SET_CATEGORIES_ERROR", error))
            .finally(() => commit("SET_CATEGORIES_LOADING", false));
    },
    getAllCategories({commit}) {
        commit("SET_CATEGORIES_LOADING", true);
        ServiceDeskCategoriesService.fetchAllCategories()
            .then(categories => commit("SET_CATEGORIES", categories))
            .catch(error => commit("SET_CATEGORIES_ERROR", error))
            .finally(() => commit("SET_CATEGORIES_LOADING", false));
    },
    getCategoryGroups({commit}) {
        commit("SET_CATEGORY_GROUPS_LOADING", true);
        return new Promise((resolve, reject) => {
            ServiceDeskCategoriesService.fetchCategoryGroups()
                .then(groups => {
                    commit("SET_CATEGORY_GROUPS", groups);
                    resolve();
                })
                .catch(error => {
                    commit("SET_CATEGORY_GROUPS_ERROR", error);
                    reject(error);
                })
                .finally(() => commit("SET_CATEGORY_GROUPS_LOADING", false));
        })
    },
    createCategory({commit}, payload) {
        return new Promise((resolve, reject) => {
            ServiceDeskCategoriesService.createCategory(payload)
                .then(() => resolve())
                .catch(error => {
                    commit("SET_CATEGORIES_ERROR", getError(error));
                    reject(getError(error));
                })
        })
    },
    updateCategory({commit}, {id, payload}) {
        return new Promise((resolve, reject) => {
            ServiceDeskCategoriesService.updateCategory(id, payload)
                .then(() => resolve())
                .catch(error => {
                    commit("SET_CATEGORIES_ERROR", getError(error));
                    reject(getError(error));
                })
        })
    },
    deleteCategory({commit}, id) {
        return new Promise((resolve, reject) => {
            ServiceDeskCategoriesService.deleteCategory(id)
                .then(() => resolve())
                .catch(error => {
                    commit("SET_CATEGORIES_ERROR", getError(error));
                    reject(getError(error));
                })
        })
    }
}

export const getters = {
    getCategories: state => {
        return state.categories;
    },
    getCategory: state => id => {
        const category = state.categories.find(item => item.id === parseInt(id))
        return category ? category : 'Категория не найдена';
    },
    getCategoryName: state => id => {
        const category = state.categories.find(item => item.id === parseInt(id))
        return category ? category.name : 'Имя не найдено';
    },
    getCategoryGroupColor: state => id => {
        const category = state.categories.find(item => item.id === parseInt(id))
        return category ? category.group.color : '#000'
    },
    getCategoryGroups: state => {
        return state.categoryGroups;
    },
    getCategoriesByGroupId: state => group_id => {
        return state.categories.filter(item => item.group.id === group_id);
    },
    getGroupsContainingCategories: state => {
        return state.categoryGroups.filter(group => state.categories.some(item => item.group.id === group.id));
    }
}