import { getError } from "@/utils/helpers";
import LogbookService from "@/services/LogbookService";

export const namespaced = true;

function setPaginatedJournalConfigs(commit, response) {
    commit("SET_JOURNAL_CONFIGS", response.data.data);
    commit("SET_META", response.data.meta);
    commit("SET_LINKS", response.data.links);
    commit("SET_LOADING", false);
}

export const state = {
    journalConfigs: [],
    meta: null,
    links: null,
    loading: false,
    error: null
};

export const mutations = {
    SET_JOURNAL_CONFIGS(state, journalConfigs) {
        state.journalConfigs = journalConfigs;
    },
    SET_META(state, meta) {
        state.meta = meta;
    },
    SET_LINKS(state, links) {
        state.links = links;
    },
    SET_LOADING(state, loading) {
        state.loading = loading;
    },
    SET_ERROR(state, error) {
        state.error = error;
    },
    DELETE_JOURNAL_CONFIGS(state, journal_config_id){
        let index = state.journalConfigs.findIndex(config => config.id == journal_config_id);
        state.journalConfigs.splice(index, 1);
    }
};

export const actions = {
    getAllJournalConfigs({ commit }, page) {
        commit("SET_LOADING", true);
        LogbookService.getAllConfigurations(page)
            .then(response => {
                commit("SET_JOURNAL_CONFIGS", response.data.data);
            })
            .catch(error => {
                commit("SET_LOADING", false);
                commit("SET_ERROR", getError(error));
            });
    },
    getJournalConfigs({ commit }, page) {
        commit("SET_LOADING", true);
        LogbookService.getPaginateConfigurations(page)
            .then(response => {
                setPaginatedJournalConfigs(commit, response);
            })
            .catch(error => {
                commit("SET_LOADING", false);
                commit("SET_ERROR", getError(error));
            });
    },
    paginateJournalConfigs({ commit }, link) {
        commit("SET_LOADING", true);
        LogbookService.getPaginateConfigurations(link)
            .then(response => {
                setPaginatedJournalConfigs(commit, response);
            })
            .catch(error => {
                commit("SET_LOADING", false);
                commit("SET_ERROR", getError(error));
            });
    }
};

export const getters = {
    getJournalConfig: state => id =>{
        return state.journalConfigs.find(config => config.id == id);
    },
    journalConfigs: state => {
        return state.journalConfigs;
    },
    getConfigurationByType: state => type => {
        return state.journalConfigs.filter(config => config.attributes.type == type);
    },
    getConfigByDeviceAndType: state => (device, type) => {
        return state.journalConfigs.filter(
            config => config.attributes.type == type && config.attributes.device_id == device
        );
    },
    meta: state => {
        return state.meta;
    },
    links: state => {
        return state.links;
    },
    loading: state => {
        return state.loading;
    },
    error: state => {
        return state.error;
    }
};