<template>
  <b-modal
    id="add-appeal"
    ref="add-appeal"
    centered
    hide-header
    hide-footer
  >
    <div class="col-12">
      <div class="custom-modal-header">
        <div class="custom-modal-title">
          Прикрепление обращения
        </div>
        <img
          class="custom-modal-close"
          src="@/assets/img/icons/close.svg"
          @click="$bvModal.hide('add-appeal')"
        >
      </div>
      <div class="modal-divider" />
    </div>
    <form @submit.prevent="submitForm()">
      <div class="col-12 mb-2">
        <div class="form-group">
          <label class="form-label">Обращение</label>
          <v-select
            v-model="appeal_id"
            :options="appeals.map(appeal => appeal.getSelectOptionData())"
            :get-option-label="(option) => option.label"
            :reduce="appeal => appeal.id"
            placeholder="Выберите обращение"
          >
            <template #option="option">
              <div class="appeal-option">
                <div class="font-weight-bold">
                  №{{option.id}} от {{ option.created_at | formatDateTime}}
                </div>
                <div>{{option.full_address}}</div>
                <div>Статус: {{option.state_label}}</div>
                <div>Категория: {{appeal_category_name(option.category_id)}}</div>
              </div>
            </template>
            <template #no-options="">
              Не найдено обращений со статусом "Новое"
            </template>
          </v-select>
        </div>
        <div class="modal-divider" />
        <div class="row">
          <div class="col-12">
            <button
              type="submit"
              class="btn custom-btn-blue w-100"
            >
              Прикрепить
            </button>
          </div>
        </div>
      </div>
    </form>
  </b-modal>
</template>

<script>
import notification from "@/mixins/notification";
import {mapGetters} from "vuex";

export default {
  name: "AddAppealForm",
  mixins: [notification],
  data() {
    return {
      appeal_id: null
    }
  },
  computed: {
    ...mapGetters({
      appeals: 'appeals/getAppeals',
      issue_id: 'issue/getIssueId',
      appeal_category_name: 'serviceDeskCategories/getCategoryName'
    })
  },
  mounted() {
    this.$store.dispatch('appeals/getAppealsByStatus', 1);
    this.$store.dispatch('serviceDeskCategories/getAllCategories');
  },
  methods: {
    submitForm() {
      const payload = {
        appeal_id: this.appeal_id,
        params: {
          issue_id: this.issue_id
        }
      }
      this.$store.dispatch('appeals/linkAppealToIssue', payload)
          .then(() => {
            this.$bvModal.hide('add-appeal');
            this.$store.dispatch('appeals/getLinkedToIssueAppeals', this.issue_id);
            this.$store.dispatch('appeals/getAppealsByStatus', 1);
            this.toast('Обращение прикреплено к заявке', 'success');
            this.appeal_id = null;
          })
          .catch(error => {
            this.alert('Ошибка', error, 'error');
          })
    }
  }
}
</script>

<style scoped>

</style>