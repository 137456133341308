<template>
  <base-map :center="appeal.coordinates"
            :zoom="zoom"
            :markers="markers"
            :need-resize="needResize"
            id-key="-appeal-modal"
            base_layers_control_position_top="40px"
  />
</template>

<script>
import Marker from "@/models/baseMap/Marker";
import Icon from "@/models/baseMap/Icon";
import BaseMap from "@/components/BaseMap";
import {mapGetters} from "vuex";

export default {
  name: "AppealMap",
  components: {BaseMap},
  props: ['appeal', 'needResize'],
  data() {
    return {
      markers: [],
      zoom: 17
    }
  },
  computed: {
    ...mapGetters({
      appealCategoryColor: 'serviceDeskCategories/getCategoryGroupColor'
    })
  },
  watch: {
    appeal: function () {
      this.createMarker()
    }
  },
  mounted() {
    this.createMarker()
  },
  methods: {
    createMarker() {
      this.markers = [new Marker({
        coords: this.appeal.coordinates,
        icon: new Icon({
          type: 'div_icon',
          size: [24, 24],
          anchor: [12, 12],
          html: `<div
                    class="appeal-map-icon" style="outline-color: ${this.appealCategoryColor(this.appeal.category_id)}">
                     <div class="appeal-map-img ${this.appeal.getImageClassName()}" />
                 </div>`
        })
      })]
    }
  }
}
</script>

<style scoped>

</style>