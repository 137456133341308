import Swal from "sweetalert2";

export const showToast = (title, icon = 'info') => {
    Swal.fire({
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: toast => {
            toast.addEventListener('mouseenter', Swal.stopTimer)
            toast.addEventListener('mouseleave', Swal.resumeTimer)
        },
        title: title,
        icon: icon
    })
}

export const showAlert = (title, text, icon = 'error') => {
    Swal.fire({
        title: title,
        text: text,
        icon: icon,
        confirmButtonColor: '#EB5757'
    })
}