<template>
  <div>
    <div
      v-if="!$store.state.appeals.appeals.length && !$store.state.appeals.loading"
      class="no-result-title"
    >
      Обращения не найдены
    </div>
    <div v-if="$store.state.appeals.appeals.length"
         class="row">
      <div class="col-12">
        <table class="service-desk-table table-responsive-xxl">
          <thead class="service-desk-table-header">
            <tr>
              <td>#</td>
              <td colspan="2">Категория</td>
              <td>Дата</td>
              <td>Адрес</td>
              <td>Статус</td>
              <td>Способ подачи</td>
              <td>Комментарий</td>
            </tr>
          </thead>
          <tbody class="service-desk-table-body">
            <tr v-for="appeal in appeals"
                :key="appeal.id"
                class="appeal-item"
                @click="getAppeal(appeal.id)">
              <td>{{ appeal.id }}</td>
              <td>
                <div class="appeal-category-color"
                     :style="{'border-color': appeal.category.group_color}"/>
              </td>
              <td>{{ appeal.category.group_name }}: {{ appeal.category.name }}</td>
              <td>{{ appeal.created_at | formatDateTime }}</td>
              <td>{{ appeal.getFullAddress() }}</td>
              <td>
                <img v-if="appeal.state === 1"
                     src="@/assets/img/icons/appealStates/new.svg"
                     alt="Новая">
                <img v-if="appeal.state === 2"
                     src="@/assets/img/icons/appealStates/appointed.svg"
                     alt="Привязано">
                <img v-if="appeal.state === 3"
                     src="@/assets/img/icons/appealStates/in_work.svg"
                     alt="В работе">
                <img v-if="appeal.state === 4"
                     src="@/assets/img/icons/appealStates/canceled.svg"
                     alt="Отменено">
                <img v-if="appeal.state === 5"
                     src="@/assets/img/icons/appealStates/closed.svg"
                     alt="Закрыто">
              </td>
              <td>{{ appeal.getSourceLabel() }}</td>
              <td>{{ appeal.message }}</td>
            </tr>
          </tbody>
        </table>
      </div>

      <div class="col-12 appeals-pagination">
        <base-paginate
          v-if="$store.state.appeals.meta && $store.state.appeals.meta.last_page > 1"
          :meta="$store.state.appeals.meta"
          action="appeals/getAppeals"
          commit="appeals/SET_PAGE"
        />
      </div>

    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import BasePaginate from "@/components/BasePaginate";

export default {
  name: "AppealsList",
  components: {
    BasePaginate
  },
  computed: {
    ...mapGetters({
      appeals: 'appeals/getAppeals'
    }),
    generatePayload: function () {
      return () => {
        return {
          params: this.$store.state.appeals.filterParams,
          page: null
        }
      }
    }
  },
  mounted() {
    this.$store.dispatch('serviceDeskCategories/getAllCategories');
  },
  methods: {
    getAppeal(id) {
      this.$store.dispatch('appeals/getAppeal', id)
          .then(() => {
            this.$bvModal.show('appeal-modal');
            this.$store.dispatch('history/getAppealHistory', id);
          })
    }
  }
}
</script>

<style scoped>
.scroll {
  overflow-y: scroll;
  max-height: calc(100vh - 125px);
  overflow-x: hidden;
}

.scroll::-webkit-scrollbar {
  width: 7px;
  height: 8px;
  background-color: #C7D3D9;
  border-radius: 0.2em;
}

.scroll::-webkit-scrollbar-thumb {
  background-color: #97ACB5;
  border-radius: 0.2em;
}

.appeal-category-color {
  width: 18px;
  height: 18px;
  border-radius: 9px;
  border: 2px solid;
}

.appeals-pagination {
  display: flex;
  justify-content: flex-end;
}

@media (max-width: 1199px) {
  .appeals-pagination {
    margin-bottom: 20px;
  }
}
</style>