<template>
  <div class="row">
    <div class="col-12">
      <div class="custom-card">
        <div class="card-body">
          <div class="row">
            <div class="col-12">
              <h4 class="table-title d-inline-block">
                Инженерные сети
              </h4>
              <div v-if="$store.state.engineeringNetworks.loading"
                   class="d-inline-block ml-2">
                <base-loader />
              </div>
              <router-link to="/engineering-network-create">
                <button class="btn custom-btn-blue float-right">
                  Добавить сеть
                </button>
              </router-link>
              <hr class="divider">
            </div>
            <div v-if="$store.state.engineeringNetworks.engineeringNetworks.length"
                 class="col-12">
              <engineering-networks-list />
            </div>
            <div v-if="!$store.state.engineeringNetworks.engineeringNetworks.length
                   && !$store.state.engineeringNetworks.loading"
                 class="col-12 mt-2">
              <div class="no-result-title">Инженерные сети не найдены</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import EngineeringNetworksList from "@/components/EngineeringNetworks/EngineeringNetworksList";
import BaseLoader from "@/layout/BaseLoader";
export default {
  name: "EngineeringNetworksListPage",
  components: {BaseLoader, EngineeringNetworksList},
  mounted() {
    this.$store.dispatch('engineeringNetworks/getEngineeringNetworks', 1);
  }
}
</script>

<style scoped>

</style>