import BillingService from "@/services/BillingService";

export const namespaced = true

function setPaginatedBuildings(commit, response_data) {
    response_data.data.forEach(item => {
        item.attributes.geometry.coordinates = item.attributes.geometry.coordinates.reverse()
    })
    commit("SET_BUILDINGS", response_data.data);
    commit("SET_META", response_data.meta);
    commit("SET_LINKS", response_data.links);
}

export const state = {
    buildings: [],
    tabIndex: 0,
    method: 1,
    filter_params: null,
    meta: null,
    links: null,
    loading: false,
    error: null
}

export const mutations = {
    SET_BUILDINGS(state, buildings) {
        state.buildings = buildings;
    },
    SET_TAB_INDEX(state, index) {
        state.tabIndex = index;
    },
    SET_FILTER_PARAMS(state, params) {
        state.filter_params = params;
    },
    SET_META(state, meta) {
        state.meta = meta;
    },
    SET_LINKS(state, links) {
        state.links = links;
    },
    SET_LOADING(state, loading) {
        state.loading = loading;
    },
    SET_ERROR(state, error) {
        state.error = error;
    },
    SET_METHOD(state, method) {
        state.method = method;
    }
}

export const actions = {
    getBuildingsV1({commit}, payload) {
        return new Promise(resolve => {
            commit("SET_LOADING", true);
            BillingService.getStandardBuildingsV1(payload)
                .then(response_data => {
                    resolve()
                    setPaginatedBuildings(commit, response_data)
                })
                .finally(() => commit("SET_LOADING", false));
        })

    },
    getBuildingsV2({commit}, payload) {
        return new Promise(resolve => {
            commit("SET_LOADING", true);
            BillingService.getStandardBuildingsV2(payload)
                .then(response_data => {
                    resolve();
                    setPaginatedBuildings(commit, response_data)
                })
                .finally(() => commit("SET_LOADING", false));
        })
    }
}

export const getters = {
    buildings: state => {
        return state.buildings;
    },
    tabIndex: state => {
        return state.tabIndex;
    }
}