export default class Leak {
    constructor(id, attributes) {
        this.id = id;
        this.actual_start_date = attributes.actual_start_date;
        this.actual_end_date = attributes.actual_end_date;
        this.category = attributes.category;
        this.type = attributes.type;
        this.pressure = attributes.pressure;
        this.volume = attributes.volume;
        this.height = attributes.height;
        this.width = attributes.width;
        this.diameter = attributes.diameter;
        this.wormholes_count = attributes.wormholes_count;
    }

    getCategoryLabel() {
        switch (this.category) {
            case 1:
                return 'Технологический сброс'
            case 2:
                return 'Аварийная утечка'
        }
    }

    getTypeLabel() {
        switch (this.type) {
            case 1:
                return 'Свищ'
            case 2:
                return 'Трещина'
            case 3:
                return 'Отверстие'
            case 4:
                return 'Гидрант'
        }
    }
}