<template>
  <div>
    <div v-for="(actualConsumption, index) in updateActualConsumptions"
         :key="index"
         class="form-group">
      <label class="form-label">{{actualConsumption.consumable.name}}</label>
      <div class="input-group">
        <input
          v-model="actualConsumption.amount"
          type="number"
          class="form-control">
        <button
          @click="removeActualConsumptionFromList(index)"
          type="button"
          class="consumable__prepend_btn remove input-group-prepend">-</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ActualConsumptionsListUpdate",
  props: {
    taskWork: {
      required: true,
      type: Object
    }
  },
  data() {
    return {
      updateActualConsumptions: [],
      deleteActualConsumptions: []
    }
  },
  watch: {
    updateActualConsumptions: function (updateActualConsumptions) {
      this.$emit('getUpdateActualConsumptionsList', updateActualConsumptions);
    },
    deleteActualConsumptions: function (deleteActualConsumptions) {
      this.$emit('getDeleteActualConsumptionsList', deleteActualConsumptions);
    }
  },
  mounted() {
    this.createConsumptionsArray();
  },
  methods: {
    createConsumptionsArray() {
      const {consumptions} = this.taskWork
      this.updateActualConsumptions = consumptions.map((b, idx) => Object.assign({ index: idx }, b));
    },
    removeActualConsumptionFromList(index) {
      this.deleteActualConsumptions.push(this.updateActualConsumptions[index]);
      this.updateActualConsumptions.splice(index, 1);
    }
  }
}
</script>

<style scoped>
.consumable__prepend_btn {
  border-radius: 0 6px 6px 0;
  outline: none;
  border: none;
  width: 30px;
  font-size: 24px;
  color: white;
  line-height: 1.4;
  display: flex;
  align-items: center;
  justify-content: center;
}

.consumable__prepend_btn.remove {
  background-color: #eb5757;
}
</style>