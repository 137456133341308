<template>
  <div class="custom-card">
    <div class="card-header d-flex align-items-center pb-0">
      <h4 class="network_object__card_title d-inline-block mr-2">
        {{networkObject.name}}
      </h4>
      <div class="d-flex">
        <div
          class="network_object__control_btn"
          @click="$bvModal.show('update-network-object')"
        >
          <img
            src="@/assets/img/transport/settings.svg"
            alt="Изменить">
        </div>
        <div
          class="network_object__control_btn"
          @click="deleteNetworkObject(networkObject.id)"
        >
          <img
            src="@/assets/img/telemetry/trash.svg"
            alt="Удалить">
        </div>
      </div>
    </div>
    <div class="card-body">
      <div class="row">
        <div class="col-md-3 col-sm-6 col-xs-12">
          <div class="network_object__info">
            <div>Адрес</div>
            <div>{{networkObject.address}}</div>
          </div>
        </div>
        <div class="col-md-3 col-sm-6 col-xs-12">
          <div class="network_object__info">
            <div>Тип объекта</div>
            <div>{{networkObject.getTypeLabel()}}</div>
          </div>
        </div>
        <div class="col-md-3 col-sm-6 col-xs-12">
          <div class="network_object__info">
            <div>Телефон</div>
            <div>{{networkObject.phone}}</div>
          </div>
        </div>
        <div class="col-md-3 col-sm-6 col-xs-12">
          <div class="network_object__info">
            <div>Координаты</div>
            <div>{{networkObject.coordinates.join()}}</div>
          </div>
        </div>
      </div>
    </div>
    <network-object-update-form />
  </div>
</template>

<script>
import {mapState} from "vuex";
import {showAlert, showToast} from "@/utils/notification";
import NetworkObjectUpdateForm from "@/components/Telemetry/admin/NetworkObject/NetworkObjectUpdateForm";

export default {
  name: "NetworkObjectInfo",
  components: {NetworkObjectUpdateForm},
  computed: {
    ...mapState({
      networkObject: state => state.networkObjects.networkObject
    })
  },
  methods: {
    async deleteNetworkObject(networkObjectId) {
      this.$swal({
        title: 'Вы действительно хотите удалить объект сети?',
        showCancelButton: true,
        confirmButtonText: `Да`,
        cancelButtonText: 'Отмена',
        icon: 'warning'
      }).then(result => {
        if (result.isConfirmed) {
          this.$store.dispatch('networkObjects/deleteNetworkObject', networkObjectId)
              .then(() => {
                showToast('Объект сети удалён', 'success');
                this.$store.commit('networkObjects/DELETE_NETWORK_OBJECT', networkObjectId);
              })
              .catch(error => showAlert('Ошибка', error))
        }
      });
    }
  }
}
</script>

<style scoped>
</style>