<template>
  <div v-if="reportData.length"
       class="custom-card">
    <div class="card-body">
      <div class="col-12">
        <table class="admin-table table-responsive">
          <thead class="admin-table-header">
            <tr>
              <td>Наименование</td>
              <td>Ед. изм.</td>
              <td v-for="(item, index) in reportData"
                  :key="index">{{ formatDate(item.date) }}
              </td>
            </tr>
          </thead>
          <tbody class="admin-table-body">
            <tr>
              <td class="table-section-title"
                  :colspan="reportData.length + 2">Водопровод - сети
              </td>
            </tr>
            <tr>
              <td class="text-left">Количество повреждений, в т.ч. аварий на водопроводной сети</td>
              <td>шт.</td>
              <td v-for="(item, index) in reportData"
                  :key="index">{{ item.accident_water.all_count }}
              </td>
            </tr>
            <tr>
              <td class="text-left">Справочно: с разрытием</td>
              <td>шт.</td>
              <td v-for="(item, index) in reportData"
                  :key="index">{{ item.accident_water.with_earth_breaking }}
              </td>
            </tr>
            <tr>
              <td class="text-left">Справочно: из ВК</td>
              <td>шт.</td>
              <td v-for="(item, index) in reportData"
                  :key="index">{{ item.accident_water.from_well }}
              </td>
            </tr>
            <tr>
              <td class="text-left">Справочно: на поверхности</td>
              <td>шт.</td>
              <td v-for="(item, index) in reportData"
                  :key="index">{{ item.accident_water.on_surface }}
              </td>
            </tr>
            <tr>
              <td class="text-left">Авария (с отключением)</td>
              <td>шт.</td>
              <td v-for="(item, index) in reportData"
                  :key="index">{{ item.accident_water.with_shutdowns }}
              </td>
            </tr>
            <tr>
              <td class="text-left">Повреждения (без отключения)</td>
              <td>шт.</td>
              <td v-for="(item, index) in reportData"
                  :key="index">{{ item.accident_water.without_shutdowns }}
              </td>
            </tr>
            <tr>
              <td class="table-section-title"
                  :colspan="reportData.length + 2">Канализация - сети
              </td>
            </tr>
            <tr>
              <td class="text-left">Количество повреждений на канализационной сети</td>
              <td>шт.</td>
              <td v-for="(item, index) in reportData"
                  :key="index">{{ item.accident_drainage.all_count }}
              </td>
            </tr>
            <tr>
              <td class="text-left">Засоры</td>
              <td>шт.</td>
              <td v-for="(item, index) in reportData"
                  :key="index">{{ item.accident_drainage.clogging }}
              </td>
            </tr>
            <tr>
              <td class="text-left">Порывы на напорных сетях</td>
              <td>шт.</td>
              <td v-for="(item, index) in reportData"
                  :key="index">{{ item.accident_drainage.gusts }}
              </td>
            </tr>
            <tr>
              <td class="table-section-title"
                  :colspan="reportData.length + 2">Протяженность сети
              </td>
            </tr>
            <tr>
              <td class="text-left">Протяженность водопроводной сети</td>
              <td>км.</td>
              <td v-for="(item, index) in reportData"
                  :key="index">{{ item.accident_water.network_len }}
              </td>
            </tr>
            <tr>
              <td class="text-left">Протяженность канализационной сети</td>
              <td>км.</td>
              <td v-for="(item, index) in reportData"
                  :key="index">{{ item.accident_drainage.network_len }}
              </td>
            </tr>
            <tr>
              <td class="table-section-title"
                  :colspan="reportData.length + 2">Аварийность
              </td>
            </tr>
            <tr>
              <td class="text-left">По водопроводным сетям</td>
              <td>кол-во авар./км*год</td>
              <td v-for="(item, index) in reportData"
                  :key="index">
                {{ getAccidentRate(item.accident_water.all_count, item.accident_water.network_len) }}
              </td>
            </tr>
            <tr>
              <td class="text-left">По канализационным сетям</td>
              <td>кол-во авар./км*год</td>
              <td v-for="(item, index) in reportData"
                  :key="index">
                {{ getAccidentRate(item.accident_drainage.all_count, item.accident_drainage.network_len) }}
              </td>
            </tr>
            <tr>
              <td class="table-section-title"
                  :colspan="reportData.length + 2">Очистка канализационной сети
              </td>
            </tr>
            <tr>
              <td class="text-left">Протяженность промытой канализационной сети</td>
              <td>м.</td>
              <td v-for="(item, index) in reportData"
                  :key="index">{{ item.accident_drainage.washed_len }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "AccidentReport",
  computed: {
    ...mapGetters({
      reportParams: 'reportsData/getReportParams',
      reportData: 'reportsData/getReportData'
    })
  },
  methods: {
    formatDate(date) {
      if (this.reportParams.group === 1) {
        return this.$moment(date).format('MMMM');
      } else {
        return this.$moment(date).format('DD');
      }
    },
    getAccidentRate(accidentsCount, networkLength) {
      if (networkLength) return accidentsCount / networkLength;
      else return 0
    }
  }
}
</script>

<style scoped>
.table-section-title {
  text-align: left;
  font-size: 20px;
  font-weight: 600;
}
</style>