import * as API from "@/services/API";
import DeviceAccess from "@/models/telemetry/DeviceAccess";
import {getError} from "@/utils/helpers";

export default {
    fetchAccessesList() {
        return new Promise((resolve, reject) => {
            API.apiClient.get(`/object-monitoring/devices-access`)
                .then(response => resolve(response.data.map(item => new DeviceAccess(item))))
                .catch(error => reject(getError(error)));
        })
    },
    allowAccess(payload) {
        return API.apiClient.post(`/object-monitoring/devices-access`, payload);
    },
    revokeAccess(payload) {
        return API.apiClient.delete(`/object-monitoring/devices-access`, {data: payload})
    }
}