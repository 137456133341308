<template>
  <b-modal
    id="add-work-actual-consumption"
    ref="add-work-actual-consumption"
    centered
    hide-header
    hide-footer
    @hidden="clearFields()"
  >
    <div class="col-12">
      <div class="custom-modal-header">
        <div class="custom-modal-title">
          Добавить затраченные материалы
        </div>
        <img
          alt="x"
          class="custom-modal-close"
          src="@/assets/img/icons/close.svg"
          @click="$bvModal.hide('add-work-actual-consumption')"
        >
      </div>
      <div class="modal-divider" />
    </div>
    <form @submit.prevent="submitForm()">
      <div class="col-12 mb-2">
        <actual-consumptions-list-create
          :task-work="taskWork"
          :form-type="type"
          :update-actual-consumptions-list="updateActualConsumptionsList"
          @getNewActualConsumptionsList="consumptions => newActualConsumptionsList = consumptions" />

        <actual-consumptions-list-update
          v-if="type === 'update'"
          :task-work="taskWork"
          @getUpdateActualConsumptionsList="consumptions => updateActualConsumptionsList = consumptions"
          @getDeleteActualConsumptionsList="consumptions => deleteActualConsumptionsList = consumptions"
        />

        <div class="modal-divider" />
        <div class="row">
          <div class="col-12">
            <button
              type="submit"
              class="btn custom-btn-blue w-100"
            >
              Сохранить
            </button>
          </div>
        </div>
      </div>
    </form>
  </b-modal>
</template>

<script>
import {mapState} from "vuex";
import {showAlert, showToast} from "@/utils/notification";
import ActualConsumptionsListCreate from "@/components/ServiceDesk/Tasks/ActionsForms/ActualConsumptionsListCreate";
import ActualConsumptionsListUpdate from "@/components/ServiceDesk/Tasks/ActionsForms/ActualConsumptionsListUpdate";

export default {
  name: "AddWorkActualConsumption",
  components: {ActualConsumptionsListUpdate, ActualConsumptionsListCreate},
  props: {
    taskWork: {
      required: true
    },
    type: {
      required: true,
      type: String
    }
  },
  data() {
    return {
      newActualConsumptionsList: [],
      updateActualConsumptionsList: [],
      deleteActualConsumptionsList: []
    }
  },
  computed: {
    ...mapState({
      task: state => state.task.task
    })
  },
  methods: {
    clearFields() {
      this.newActualConsumptionsList = [];
      this.updateActualConsumptionsList = [];
      this.deleteActualConsumptionsList = [];
    },
    submitForm() {
      if (this.newActualConsumptionsList.length) {
        this.$store.dispatch('works/addActualConsumptionInWork', {
          taskId: this.task.task.id,
          workId: this.taskWork.id,
          payload: {
            consumptions: this.newActualConsumptionsList.map(item => {
              return {
                consumable_id: item.consumable.id,
                amount: item.amount
              }
            })
          }
        })
            .then(() => {
              this.$store.dispatch('works/getTaskWorks', this.task.task.id);
              showToast('Затраченные материалы добавлены в работу', 'success');
              this.$bvModal.hide('add-work-actual-consumption');
            })
            .catch(error => showAlert('Ошибка', error))
      }
      if (this.updateActualConsumptionsList.length) {
        this.$store.dispatch('works/updateActualConsumptionInWork', {
          taskId: this.task.task.id,
          workId: this.taskWork.id,
          payload: {
            consumptions: this.updateActualConsumptionsList.map(item => {
              return {
                id: item.id,
                consumable_id: item.consumable.id,
                amount: item.amount
              }
            })
          }
        })
            .then(() => {
              this.$store.dispatch('works/getTaskWorks', this.task.task.id);
              showToast('Затраченные материалы обновлены', 'success');
              this.$bvModal.hide('add-work-actual-consumption');
            })
            .catch(error => showAlert('Ошибка', error))
      }
      if (this.deleteActualConsumptionsList.length) {
        const lastIndex = this.deleteActualConsumptionsList.length - 1;
        this.deleteActualConsumptionsList.forEach(async (consumption, index) => {
          await this.$store.dispatch('works/deleteActualConsumptionFromWork', {
            taskId: this.task.task.id,
            workId: this.taskWork.id,
            consumptionId: consumption.id
          }).catch(error => {
            showAlert('Ошибка', error);
            return false;
          })

          if (index === lastIndex) {
            await this.$store.dispatch('works/getTaskWorks', this.task.task.id);
            this.$bvModal.hide('add-work-actual-consumption');
          }
        })

      }
    }
  }
}
</script>

<style scoped>

</style>