<template>
  <div>
    <div class="custom-card mb-2">
      <div class="card-header d-flex align-items-center">
        <h4 class="network_object__card_title d-inline-block mr-2">
          Устройства
        </h4>
        <div class="d-flex">
          <div
            class="network_object__control_btn"
            @click="createDevice()"
          >
            <img
              src="@/assets/img/icons/plus.svg"
              alt="Изменить">
          </div>
        </div>
      </div>
    </div>
    <div
      v-for="device in devices"
      :key="device.id"
      class="custom-card">
      <div class="card-body row mb-2">
        <div class="col-md-6 col-sm-12">
          <div class="d-flex align-items-center mb-2">
            <div class="device__name mr-2">{{ device.name }}</div>
            <div class="d-flex">
              <div
                class="network_object__control_btn"
                @click="editDevice(device)"
              >
                <img
                  src="@/assets/img/transport/settings.svg"
                  alt="Изменить">
              </div>
              <div
                class="network_object__control_btn"
                @click="deleteDevice(device.id)"
              >
                <img
                  src="@/assets/img/telemetry/trash.svg"
                  alt="Удалить">
              </div>
            </div>
          </div>

          <div class="device__info">
            <div class="network_object__info">
              <div>Наименование устройства</div>
              <div>{{ device.deviceName }}</div>
            </div>

            <div class="network_object__info">
              <div>Провайдер данных</div>
              <div>{{ DeviceModel.getConnectionTypeName(device.dataSource) }}</div>
            </div>

            <div v-if="device.parentId"
                 class="network_object__info">
              <div>Родительское устройство</div>
              <div>{{ device.parentId }}</div>
            </div>

          </div>

        </div>
        <div class="col-md-6 col-sm-12">
          <device-sensors-list
            :sensors-list="device.sensors"
            :device-id="device.id" />
        </div>
      </div>
    </div>

    <div v-if="!devices.length && !loading">
      <div class="no-result-title mt-2">Устройства не найдено</div>
    </div>

    <div v-if="loading"
         class="text-center mt-2">
      <base-loader />
    </div>

    <device-form :device="currentDevice" />
    <device-sensors-form />

  </div>
</template>

<script>
import {mapState} from "vuex";
import DeviceSensorsList from "@/components/Telemetry/admin/NetworkObject/DeviceSensorsList";
import Device from "@/models/telemetry/Device";
import DeviceForm from "@/components/Telemetry/admin/NetworkObject/DeviceForm";
import {showAlert, showToast} from "@/utils/notification";
import DeviceSensorsForm from "@/components/Telemetry/admin/NetworkObject/DeviceSensorsForm";
import BaseLoader from "@/layout/BaseLoader";

export default {
  name: "DevicesList",
  components: {BaseLoader, DeviceSensorsForm, DeviceForm, DeviceSensorsList},
  data() {
    return {
      currentDevice: null
    }
  },
  computed: {
    ...mapState({
      devices: state => state.networkObjectPage.networkObjectDevices,
      loading: state => state.networkObjectPage.loading
    }),
    DeviceModel: () => Device
  },
  methods: {
    createDevice() {
      this.currentDevice = null;
      this.$bvModal.show('device-form');
    },
    editDevice(device) {
      this.currentDevice = device;
      this.$bvModal.show('device-form');
    },
    deleteDevice(deviceId) {
      this.$swal({
        title: 'Вы действительно хотите удалить устройство?',
        showCancelButton: true,
        confirmButtonText: `Да`,
        cancelButtonText: 'Отмена',
        icon: 'warning'
      }).then(result => {
        if (result.isConfirmed) {
          this.$store.dispatch('device/deleteDevice', deviceId)
              .then(() => {
                this.$store.dispatch('networkObjectPage/getNetworkObjectDevices', {
                  construction_id: this.$route.params.id
                });
                showToast('Устройство удалено', 'success');
              })
              .catch(error => showAlert('Ошибка', error));
        }
      });
    }
  }
}
</script>

<style scoped>
.device__name {
  font-size: 18px;
  color: #468FC5;
  font-weight: bold;
}

.device__info {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 1fr);
  row-gap: 16px;
}
</style>