<template>
  <form @submit.prevent="submitForm()">
    <div class="row">
      <div class="col-xl-6 col-xs-12">
        <div class="form-group">
          <label class="form-label">Группировка</label>
          <v-select
            v-model="reportParams.group"
            :options="groups"
            :reduce="report => report.key"
            :get-option-label="(option) => option.name"
            @input="$store.commit('reportsData/SET_REPORT_DATA', [])"
            placeholder="Выберите группировку" />
        </div>
      </div>
      <div class="col-xl-6 col-xs-12">
        <div class="form-group">
          <label class="form-label">Дата</label>
          <date-picker
            input-class="form-control-fix w-100 pl-2"
            placeholder="Выберите дату"
            v-model="reportParams.date"
            :default-value="defaultValue"
            :format="reportParams.group === 1 ? 'DD.MM.YYYY':'MMMM YYYY'"
            :type="reportParams.group === 1 ? 'day':'month'"
            range
            value-type="YYYY-MM-DD" />
        </div>
      </div>
    </div>
    <button class="btn custom-btn-blue w-100">Сформировать</button>
  </form>
</template>

<script>
import DatePicker from "vue2-datepicker";

export default {
  name: "IssuesCountReportForm",
  components: {DatePicker},
  data() {
    return {
      reportParams: {
        group: 1,
        date: [this.$moment().format('YYYY-MM-DD'), this.$moment().format('YYYY-MM-DD')]
      },
      defaultValue: new Date(),
      groups: [
        {key: 1, name: 'Дни'},
        {key: 2, name: 'Месяцы'}
      ]
    }
  },
  methods: {
    submitForm() {
      const [periodStart, periodEnd] = this.reportParams.date;
      this.$store.commit('reportsData/SET_REPORT_PARAMS', {
        date: this.reportParams.date,
        group: this.reportParams.group
      });
      this.$store.dispatch('reportsData/getIssuesCountReportData', {
        group: this.reportParams.group,
        period_start: periodStart,
        period_end: periodEnd
      })
    }
  }
}
</script>

<style scoped>

</style>