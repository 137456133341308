<template>
  <div class="row">
    <div class="col-xl-12 d-flex align-items-center mb-2">
      <div class="page-title mr-2">Статистика за текущий месяц</div>
      <base-loader v-if="loading" />
    </div>
    <div v-if="resourceStatistic"
         class="resource-statistic">
      <div class="work-shifts-count-wrapper">
        <resource-work-shifts-count/>
      </div>
      <div class="completed-tasks-count">
        <resource-completed-tasks-count/>
      </div>
    </div>
  </div>
</template>

<script>
import ResourceWorkShiftsCount from "@/components/ServiceDesk/ResourceWorkshiftsCount";
import ResourceCompletedTasksCount from "@/components/ServiceDesk/ResourceCompletedTasksCount";
import {mapState} from "vuex";
import BaseLoader from "@/layout/BaseLoader";

export default {
  name: "ResourceDashboardPage",
  components: {BaseLoader, ResourceCompletedTasksCount, ResourceWorkShiftsCount},
  computed: {
    ...mapState({
      resourceStatistic: state => state.resourceStatistic.resourceStatistic,
      loading: state => state.resourceStatistic.resourceStatisticLoading
    })
  },
  mounted() {
    this.$store.dispatch('resourceStatistic/getResourceStatistic', {
      resourceId: this.$route.params.id,
      params: {
        start_period: this.$moment().startOf('month').format('YYYY-MM-DD'),
        end_period: this.$moment().format('YYYY-MM-DD')
      }
    });
  }
}
</script>

<style scoped>
.page-title {
  display: flex;
  align-items: center;
  font-size: 24px;
  font-weight: 600;
  color: #468FC5;
}

.resource-statistic {
  display: grid;
  grid-template-rows: 76px 1fr;
  gap: 1rem;
}
</style>