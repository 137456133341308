import BaseLayersService from "@/services/BaseLayersService";

export const namespaced = true;

export const state = {
    baseLayers: [],
    loading: false,
    error: null
}

export const mutations = {
    SET_BASE_LAYERS(state, baseLayers) {
        state.baseLayers = baseLayers;
    },
    SET_LOADING(state, loading) {
        state.loading = loading;
    },
    SET_ERROR(state, error) {
        state.error = error;
    }
}

export const actions = {
    getBaseLayers({commit}) {
        commit("SET_LOADING", true);
        return new Promise((resolve, reject) => {
            BaseLayersService.fetchBaseLayers()
                .then(baseLayers => {
                    commit("SET_BASE_LAYERS", baseLayers);
                    resolve();
                })
                .catch(error => {
                    commit("SET_ERROR", error);
                    reject();
                })
                .finally(() => commit("SET_LOADING", false))
        })
    }
}