<template>
  <div class="row">
    <div class="col-12">
      <div class="custom-card">
        <div class="card-body">
          <div class="container-fluid">
            <div class="row">
              <div class="col-12">
                <h4 class="table-title d-inline-block">
                  Типы заданий
                </h4>
                <div v-if="$store.state.task_types.loading"
                     class="d-inline-block ml-2">
                  <base-loader/>
                </div>
                <router-link to="/task-types-create">
                  <button class="btn custom-btn-blue float-right">
                    Добавить тип задания
                  </button>
                </router-link>
                <hr class="divider">
              </div>
              <div class="col-12">
                <task-types-list />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TaskTypesList from "@/components/ServiceDesk/admin/TaskTypesList";
import BaseLoader from "@/layout/BaseLoader";

import store from "@/store";
export default {
  name: "TaskTypesListPage",
  components: {TaskTypesList, BaseLoader},
  beforeRouteEnter(to, from, next) {
    const currentPage = parseInt(to.query.page) || 1;
    store.dispatch("task_types/getTaskTypes", currentPage).then(() => {
      to.params.page = currentPage;
      next();
    });
  }
}
</script>

<style scoped>

</style>