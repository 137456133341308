import BillingService from "@/services/BillingService";
import {getError} from "@/utils/helpers";

export const namespaced = true;

function setPaginatedBuildings(commit, response) {
    response.data.data.forEach(el => {
        el.attributes.geometry.coordinates = el.attributes.geometry.coordinates.reverse()
    })
    commit("SET_BUILDINGS", response.data.data);
    commit("SET_META", response.data.meta);
    commit("SET_LINKS", response.data.links);
    commit("SET_LOADING", false);
}

export const state = {
    buildings: [],
    building: null,
    meta: null,
    links: null,
    loading: false,
    building_loading: false,
    error: null,
    tabIndex: 0
}

export const mutations = {
    SET_BUILDINGS(state, buildings) {
        state.buildings = buildings;
    },
    SET_BUILDING(state, building) {
        state.building = building;
    },
    SET_META(state, meta) {
        state.meta = meta;
    },
    SET_LINKS(state, links) {
        state.links = links;
    },
    SET_LOADING(state, loading) {
        state.loading = loading;
    },
    SET_BUILDING_LOADING(state, loading) {
        state.building_loading = loading;
    },
    SET_ERROR(state, error) {
        state.error = error;
    },
    SET_TAB_INDEX(state, index) {
        state.tabIndex = index;
    }
}

export const actions = {
    getBuildings({commit}, payload) {
        commit("SET_LOADING", true);
        return new Promise((resolve, reject) => {
            BillingService.getBuildings(payload)
                .then(response => {
                    setPaginatedBuildings(commit, response);
                    resolve();
                })
                .catch(error => {
                    commit("SET_ERROR", getError(error))
                    reject();
                })
                .finally(() => commit("SET_LOADING", false))
        })
    },

    paginateBuildings({commit}, payload) {
        commit("SET_LOADING", true);
        BillingService.paginateBuildings(payload)
            .then(response => setPaginatedBuildings(commit, response))
            .catch(error => commit("SET_ERROR", getError(error)))
            .finally(() => commit("SET_LOADING", false))
    },
    getBuildingDetail({commit}, id) {
        commit("SET_BUILDING_LOADING", true)
        return new Promise((resolve, reject) => {
            BillingService.getBuildingDetail(id)
                .then(response => {
                    commit("SET_BUILDING", response.data.data);
                    resolve();
                })
                .catch(error => {
                    commit("SET_ERROR", getError(error));
                    reject();
                }).finally(() => commit("SET_BUILDING_LOADING", false))
        })
    },
    getBuildingsByCoords({commit}, payload) {
        return new Promise((resolve, reject) => {
            BillingService.getBuildingByCoords(payload)
                .then(response => {
                    setPaginatedBuildings(commit, response)
                    resolve();
                })
                .catch(error => {
                    commit("SET_ERROR", getError(error));
                    reject();
                })
        })
    }
}

export const getters = {
    getBuilding: state => id => {
        return state.buildings.find(building => building.id === id);
    },
    getStateBuilding: state => {
        return state.building;
    },
    getBuildings: state => {
        return state.buildings
    },
    getStoreStatus: state => {
        return state.buildings.length !== 0;
    },
    getBuildingCoords: state => id => {
        return state.buildings.find(building => building.id === id).attributes.geometry.coordinates;
    },
    getBuildingId: state => {
        return state.building.id;
    },
    getBuildingAddress: state => {
        return state.building.attributes.address;
    },
    getBuildingWSZoneId: state => {
        return state.building.attributes.zone_ws_id;
    },
    getBuildingWDZoneId: state => {
        return state.building.attributes.zone_wd_id
    },
    getBuildingSource: state => {
        return state.building.attributes.source;
    },
    getBuildingType: state => {
        return state.building.attributes.building_type;
    },
    getBuildingArea: state => {
        return state.building.attributes.building_area;
    },
    getBuildingLeavingArea: state => {
        return state.building.attributes.leaving_area;
    },
    getBuildingTechnicalCondition: state => {
        return state.building.attributes.technical_conditions;
    },
    getBuildingPrivateClientsCount: state => {
        return state.building.attributes.clients.filter(client => client.attributes.type === 1).length;
    },
    getBuildingBusinessClientsCount: state => {
        return state.building.attributes.clients.filter(client => client.attributes.type === 2).length;
    },
    getBuildingClients: state => {
        return state.building.attributes.clients;
    },
    getBuildingConnectionPointsVS: state => {
        return state.building.attributes.connection_points_count_vs;
    },
    getBuildingConnectionPointsVO: state => {
        return state.building.attributes.connection_points_count_vo;
    },
    getBuildingBalance: state => {
        return state.building.attributes.balance.toFixed(2);
    },
    getBuildingScheduleAm: state => {
        return state.building.attributes.schedule_am_supply;
    },
    getBuildingSchedulePm: state => {
        return state.building.attributes.schedule_pm_supply;
    },
    getBuildingAccidentRate: state => {
        return state.building.attributes.accident_rate;
    },
    getBuildingLeaks: state => {
        return state.building.attributes.leaks;
    },
    getBuildingIllegalConnections: state => {
        return state.building.attributes.illegal_connections;
    },
    getBuildingIllegalTU: state => {
        return state.building.attributes.illegal_tu;
    },
    getBuildingLoading: state => {
        return state.building_loading;
    },
    getTabIndex: state => {
        return state.tabIndex;
    }
}