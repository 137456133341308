<template>
  <svg width="25"
       height="22"
       viewBox="0 0 25 22"
       fill="#468FC5"
       xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd"
          clip-rule="evenodd"
          d="M5.75 12.4963C6.16421 12.4963 6.5 12.8321 6.5 13.2463V17.7463C6.5 18.1605 6.16421 18.4963 5.75
           18.4963H1.25C0.835786 18.4963 0.5 18.1605 0.5 17.7463C0.5 17.3321 0.835786 16.9963 1.25 16.9963H5V13.2463C5
            12.8321 5.33579 12.4963 5.75 12.4963Z"/>
    <path fill-rule="evenodd"
          clip-rule="evenodd"
          d="M18.5 4.24629C18.5 3.83207 18.8358 3.49629 19.25 3.49629H23.75C24.1642 3.49629 24.5 3.83207 24.5
           4.24629C24.5 4.6605 24.1642 4.99629 23.75 4.99629H20V8.74629C20 9.1605 19.6642 9.49629 19.25 9.49629C18.8358
            9.49629 18.5 9.1605 18.5 8.74629V4.24629Z"/>
    <path fill-rule="evenodd"
          clip-rule="evenodd"
          d="M19.0127 3.70204C19.3116 3.4152 19.7863 3.42493 20.0732 3.72376C23.5175 7.31212 23.9722 12.8246 21.1624
           16.9288C18.3526 21.0331 13.049 22.6034 8.45765 20.6904C8.07529 20.5311 7.89448 20.092 8.05378 19.7097C8.21308
            19.3273 8.65218 19.1465 9.03454 19.3058C12.9701 20.9455 17.5162 19.5995 19.9247 16.0815C22.3331 12.5634
            21.9434 7.83832 18.991 4.76247C18.7042 4.46364 18.7139 3.98887 19.0127 3.70204Z"/>
    <path fill-rule="evenodd"
          clip-rule="evenodd"
          d="M15.966 2.69081C12.032 1.05644 7.49092 2.40305 5.08386 5.91781C2.67679 9.43257 3.06262 14.1533 6.00847
           17.2306C6.2949 17.5298 6.28454 18.0045 5.98533 18.291C5.68612 18.5774 5.21136 18.5671 4.92492 18.2678C1.48827
            14.6779 1.03815 9.1706 3.84626 5.07025C6.65437 0.969889 11.952 -0.601075 16.5414 1.3056C16.924 1.46451
             17.1052 1.90343 16.9463 2.28595C16.7874 2.66846 16.3485 2.84973 15.966 2.69081Z"/>
  </svg>

</template>

<script>
export default {
  name: "IconReset"
}
</script>

<style scoped>

</style>