import { render, staticRenderFns } from "./EnergyConsumptionObjectForm.vue?vue&type=template&id=5a0c8a80&scoped=true&"
import script from "./EnergyConsumptionObjectForm.vue?vue&type=script&lang=js&"
export * from "./EnergyConsumptionObjectForm.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5a0c8a80",
  null
  
)

export default component.exports