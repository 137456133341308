<template>
  <div class="custom-card">
    <div class="card-body">
      <div class="chart-title">Количество переходящих заявок
        <base-loader v-if="loading" />
      </div>
      <apexchart
        type="line"
        :options="createChartOptions"
        :series="createChartSeries" />
    </div>
  </div>
</template>

<script>
import BaseLoader from "@/layout/BaseLoader";
import VueApexCharts from "vue-apexcharts";
import {mapState} from "vuex";
export default {
  name: "TransitIssuesChart",
  components: {BaseLoader, 'apexchart': VueApexCharts},
  props: {
    filter: {
      type: Object
    }
  },
  data() {
    return {
      chartOptions: {},
      chartSeries: []
    }
  },
  computed: {
    ...mapState({
      loading: state => state.reportsData.loading,
      reportData: state => state.reportsData.reportData
    }),
    createChartOptions() {
      return {
        chart: {
          type: 'line',
          height: 400,
          zoom: {
            enabled: false
          }
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          curve: 'straight'
        },
        xaxis: {
          categories: this.reportData.map(item => this.$moment(item.date).format('DD.MM.YYYY')),
          labels: {
            show: true,
            rotateAlways: false
          }
        }
      }
    },
    createChartSeries() {
      return [
        {
          name: 'Поступило за период',
          data: this.reportData.map(item => item.count_opened)
        },
        {
          name: 'Завершено за период',
          data: this.reportData.map(item => item.count_closed)
        },
        {
          name: 'Остаток на конец периода',
          data: this.reportData.map(item => item.count_end_period)
        }
      ]
    }
  },
  watch: {
    filter: function () {
      this.fetchData()
    }
  },
  mounted() {
    this.fetchData()
  },
  methods: {
    fetchData() {
      this.$store.dispatch('reportsData/getIssuesCountReportData', {
        group: 1,
        period_start: this.filter.start_period,
        period_end: this.filter.end_period
      })
    }
  }
}
</script>

<style scoped>
.chart-title {
  font-size: 22px;
  font-weight: 600;
  color: #262F3D;
}
</style>