<template>
  <b-modal
    id="appeal-modal"
    ref="appeal-modal"
    centered
    hide-header
    hide-footer
    size="xl"
    class="appeal-modal"
    @shown="shownEvent()"
    @hidden="hiddenEvent()"
  >
    <div @click="$bvModal.hide('appeal-modal')"
         class="appeal-modal-close">
      <img src="@/assets/img/icons/close.svg"
           alt="x">
    </div>

    <div class="appeal-modal-content">
      <div class="row">
        <div class="appeal-info col-xl-4 col-xs-12">
          <div class="appeal-number">
            <div>Обращение <span>#{{appeal.id}}</span></div>
            <div class="appeal-date">{{ appeal.created_at | formatDateTime }}</div>
          </div>
          <div class="d-flex justify-content-between">
            <div class="appeal-state">
              <img v-if="appeal.state === 1"
                   src="@/assets/img/icons/appealStates/new.svg"
                   alt="Новая">
              <img v-if="appeal.state === 2"
                   src="@/assets/img/icons/appealStates/appointed.svg"
                   alt="Привязано">
              <img v-if="appeal.state === 3"
                   src="@/assets/img/icons/appealStates/in_work.svg"
                   alt="В работе">
              <img v-if="appeal.state === 4"
                   src="@/assets/img/icons/appealStates/canceled.svg"
                   alt="Отменено">
              <img v-if="appeal.state === 5"
                   src="@/assets/img/icons/appealStates/closed.svg"
                   alt="Закрыто">
              {{appeal.getStateLabel()}}
            </div>
            <div @click="$bvModal.show('cancel-appeal')"
                 class="cancel-appeal">
              <img src="@/assets/img/icons/cancelAppeal.svg"
                   alt="х">
              Отменить
            </div>

            <b-modal id="cancel-appeal"
                     hide-footer
                     hide-header
                     centered>
              <div class="confirmation-modal-wrapper">
                <div @click="$bvModal.hide('cancel-appeal')"
                     class="confirmation-modal-close">
                  <img src="@/assets/img/icons/close.svg"
                       alt="x">
                </div>
                <div class="confirmation-modal-title">
                  Вы действительно хотите отменить данное обращение?
                </div>
                <button @click="cancelAppeal(appeal.id)"
                        class="confirmation-modal-button confirm">Отменить обращение</button>
                <button @click="$bvModal.hide('cancel-appeal')"
                        class="confirmation-modal-button reject">Назад</button>
              </div>
            </b-modal>

          </div>
          <div class="appeal-category">
            <img src="@/assets/img/icons/appealCategoryIcon.svg">
            {{ appeal.category.group_name }}: {{ appeal.category.name }}
          </div>
          <div class="appeal-modal-divider" />
          <div class="appeal-info-block">
            <div class="appeal-info-title">Способ подачи обращения</div>
            <div class="appeal-info-data">{{ appeal.getSourceLabel() }}</div>
          </div>
          <div class="appeal-info-block">
            <div class="appeal-info-title">Тип обратившегося</div>
            <div class="appeal-info-data">
              <span v-if="!editApplicantView">{{ appeal.getApplicantTypeLabel() }}</span>
              <select v-else
                      v-model="applicant.applicant_type"
                      class="form-control form-control-fix mb-1">
                <option :value="1">
                  Физическое лицо
                </option>
                <option :value="2">
                  Юридическое лицо
                </option>
                <option :value="3">
                  Тех. лаборатория отдела ОРСиС
                </option>
                <option :value="4">
                  ВКХ
                </option>
                <option :value="5">
                  ИВР
                </option>
                <option :value="6">
                  ЕДС
                </option>
              </select>
              <img
                v-if="lessThenDay(appeal.created_at)"
                @click="editApplicant()"
                class="edit-appeal-icon"
                src="@/assets/img/icons/pencil.svg"
                alt="изменить">
            </div>
          </div>
          <div class="appeal-info-block">
            <div class="appeal-info-title">ФИО обратившегося</div>
            <div class="appeal-info-data">
              <span v-if="!editApplicantView">{{ appeal.applicant.name }}</span>
              <dadata-suggestions
                v-else
                class="form-control form-control-fix"
                v-model="applicant.applicant_name"
                type="NAME"
                placeholder="Введите ФИО"
              />
              <img
                v-if="lessThenDay(appeal.created_at)"
                @click="editApplicant()"
                class="edit-appeal-icon"
                src="@/assets/img/icons/pencil.svg"
                alt="изменить">
            </div>
          </div>
          <div class="appeal-info-block">
            <div class="appeal-info-title">Телефон обратившегося</div>
            <div class="appeal-info-data">
              <span v-if="!editApplicantView">{{ appeal.applicant.phone }}</span>
              <the-mask
                v-else
                v-model="applicant.applicant_phone"
                :mask="['+7 (###) ###-##-##']"
                :masked="true"
                class="form-control"
                placeholder="Введите телефон"
              />
              <img
                v-if="lessThenDay(appeal.created_at)"
                @click="editApplicant()"
                class="edit-appeal-icon"
                src="@/assets/img/icons/pencil.svg"
                alt="изменить">
            </div>
          </div>
          <div class="appeal-info-block">
            <div class="appeal-info-title">Адрес проблемы</div>
            <div class="appeal-info-data">
              <span v-if="!editAddressView">{{ appeal.getFullAddress() }}</span>
              <dadata-suggestions
                v-else
                class="form-control form-control-fix"
                type="ADDRESS"
                @change="setAddress"
                placeholder="Введите адрес"
                v-model="full_address"
              />
              <img
                v-if="lessThenDay(appeal.created_at)"
                @click="editAddress()"
                class="edit-appeal-icon"
                src="@/assets/img/icons/pencil.svg"
                alt="изменить">
            </div>
          </div>
          <div class="appeal-info-block">
            <div class="appeal-info-title">Комментарий</div>
            <div class="appeal-info-data">{{ appeal.message }}</div>
          </div>
          <div class="appeal-modal-divider" />
          <div v-if="appeal.issue_id !== null"
               class="related-issue">
            <span>Связанная заявка</span>
            <div @click="$router.push(`/issue/${appeal.issue_id}`)"
                 class="d-flex align-items-center justify-content-between">
              <span>Заявка #{{ appeal.issue_id }}</span>
              <chevron-right-icon />
            </div>
          </div>

          <button v-if="editApplicantView"
                  @click="saveChanges('applicant')"
                  class="btn custom-btn-blue-outline w-100 mb-2">Изменить обратившегося</button>

          <button v-if="editAddressView"
                  @click="saveChanges('address')"
                  class="btn custom-btn-blue-outline w-100 mb-2">Изменить адрес</button>

        </div>
        <div class="appeal-history col-xl-4 col-xs-12">
          <div class="appeal-history-title">
            История
          </div>
          <history-list :history="history" />
        </div>
        <div class="appeal-map col-xl-4 col-xs-12">
          <appeal-map :appeal="appeal"
                      :need-resize="needResize" />
        </div>
      </div>
    </div>
  </b-modal>
</template>

<script>
import {mapGetters} from "vuex";
import AppealMap from "@/components/CallCenter/AppealMap";
import Vue from "vue";
import DadataSuggestions from "vue-dadata-suggestions";
import {TheMask} from "vue-the-mask";
import notification from "@/mixins/notification";
import HistoryList from "@/components/ServiceDesk/HistoryList";

Vue.use(DadataSuggestions, {
  token: process.env.VUE_APP_DADATA_API_KEY,
  geoLocation: [{
    "kladr_id": process.env.VUE_APP_DADATA_KLADR_ID
  }]
});

export default {
  name: "AppealModal",
  components: {HistoryList, AppealMap, TheMask},
  mixins: [notification],
  data() {
    return {
      needResize: false,
      editApplicantView: false,
      editAddressView: false,
      applicant: {
        applicant_name: null,
        applicant_phone: null,
        applicant_type: null
      },
      address: {
        address_region: null,
        address_city: null,
        address_settlement: null,
        address_street: null,
        address_house: null,
        address_block: null,
        address_flat: null,
        lat: null,
        lon: null
      },
      full_address: null,
      issueType: null
    }
  },
  computed: {
    ...mapGetters({
      appeal: 'appeals/getAppeal',
      history: 'history/getAppealHistory',
      filterParams: 'appeals/getFilterParams',
      issueTypes: 'issueTypes/getIssueTypes'
    })
  },
  methods: {
    cancelAppeal(id) {
      this.$store.dispatch('appeals/cancelAppeal', id)
          .then(() => {
            this.toast('Обращение отменено', 'success');
            this.$store.dispatch('appeals/getAppeal', this.appeal.id);
            this.$store.dispatch('history/getAppealHistory', this.appeal.id);
            this.$store.dispatch('appeals/getAppeals', 1);
            this.$bvModal.hide('cancel-appeal');
          })
          .catch(() => {
            this.alert('Ошибка', this.$store.state.appeals.error, 'error')
          })
    },
    editApplicant() {
      this.editApplicantView = !this.editApplicantView;
    },
    editAddress() {
      this.editAddressView = !this.editAddressView;
    },
    saveChanges(type) {
      if (type === 'applicant') {
        this.$store.dispatch('appeals/changeAppealApplicant', {id: this.appeal.id, payload: this.applicant})
            .then(() => {
              this.editApplicantView = false;
              this.$store.dispatch('appeals/getAppeal', this.appeal.id);
              this.$store.dispatch('history/getAppealHistory', this.appeal.id);
              this.$store.dispatch('appeals/getAppeals', this.$store.state.appeals.page);
              this.toast('Информация о заявителе изменена', 'success');
            })
            .catch(error => {
              this.alert('Ошибка', error, 'error');
            })
      } else if (type === 'address') {
        this.$store.dispatch('appeals/changeAppealAddress', {id: this.appeal.id, payload: this.address})
            .then(() => {
              this.editAddressView = false;
              this.$store.dispatch('appeals/getAppeal', this.appeal.id);
              this.$store.dispatch('history/getAppealHistory', this.appeal.id);
              this.$store.dispatch('appeals/getAppeals', this.$store.state.appeals.page);
              this.toast('Адрес изменён', 'success');
            })
            .catch(error => {
              this.alert('Ошибка', error, 'error');
            })
      }
    },
    setAddress(i) {
      this.address.address_region = i.data.region;
      this.address.address_city = i.data.city;
      this.address.address_settlement = i.data.settlement_with_type;
      this.address.address_street = i.data.street_with_type;
      this.address.address_house = i.data.house;
      this.address.address_block = i.data.block;
      this.address.address_flat = i.data.flat;

      this.address.lat = parseFloat(i.data.geo_lat);
      this.address.lon = parseFloat(i.data.geo_lon);
    },
    shownEvent() {
      this.needResize = true;

      this.address = this.appeal.address;
      this.applicant.applicant_name = this.appeal.applicant.name;
      this.applicant.applicant_phone = this.appeal.applicant.phone;
      this.applicant.applicant_type = this.appeal.applicant.type;
      this.full_address = this.appeal.getFullAddress();
    },
    hiddenEvent() {
      this.needResize = false;

      this.editApplicantView = false;
      this.editAddressView = false;
    },
    lessThenDay(createdDate) {
      return Math.floor((new Date() - new Date(createdDate)) / (1000*60*60)) <= 24
    }
  }
}
</script>

<style scoped>
.appeal-number {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 24px;
  line-height: 1.1;
  font-weight: 700;
  color: #262F3D;
  margin-bottom: 10px;
}

.appeal-date {
  font-size: 14px;
  color: #262F3D;
  font-weight: 600;
}

.appeal-number span {
  font-size: 20px;
  font-weight: 500;
  color: #468FC5;
  line-height: 1.1;
  font-style: italic;
}

.appeal-state {
  color: #262F3D;
  display: flex;
  align-items: center;
  margin-bottom: 6px;
  line-height: 1;
}

.appeal-state img {
  margin-right: 10px;
  width: 16px;
  height: 16px;
}

.cancel-appeal {
  color: #EB5757;
  display: flex;
  align-items: center;
  line-height: 1;
  cursor: pointer;
}

.cancel-appeal img {
  margin-right: 4px;
}

.appeal-category {
  display: flex;
  align-items: center;
  line-height: 1;
}

.appeal-category img {
  margin-right: 10px
}

.appeal-modal-divider {
  border-top: 1px solid #EAEFF2;
  margin: 30px 0;
}

.appeal-info-block {
  margin-bottom: 20px;
}

.appeal-info-title {
  display: block;
  color: #262F3D;
  font-size: 14px;
  line-height: 1;
  font-weight: 600;
  margin-bottom: 5px;
}

.appeal-info-data {
  display: flex;
  justify-content: space-between;
  color: #468FC5;
  font-size: 16px;
  font-weight: 600;
}

.related-issue {
  margin-bottom: 30px;
  cursor: pointer;
}

.related-issue > span {
  margin-bottom: 5px;
  color: #262F3D;
  font-weight: 600;
  font-size: 14px;
}

.related-issue div {
  background-color: #EAEFF2;
  border-radius: 10px;
  padding: 10px;
  color: #468FC5;
  font-size: 16px;
  text-decoration: underline;
}

.appeal-info {
  padding-right: 40px;
}

.appeal-history {
  padding: 0 40px;
  border-left: 1px solid #d1d1d1;
  border-right: 1px solid #d1d1d1;
}

.appeal-map {
  padding-top: 30px;
  padding-left: 40px;
}

.appeal-modal-close {
  position: absolute;
  right: 25px;
  top: 25px;
  cursor: pointer;
  z-index: 20;
}

.edit-appeal-icon {
  cursor: pointer;
  margin-left: 20px;
}

.appeal-history-title {
  font-size: 24px;
  font-weight: 700;
  color: #0A6187;
  margin-bottom: 10px;
}

@media (max-width: 766px) {
  .appeal-map {
    margin-top: 40px;
    height: 400px;
  }
}
</style>