var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.reportData.length)?_c('div',{staticClass:"custom-card"},[_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('button',{staticClass:"btn custom-btn-blue-outline-xs float-right",on:{"click":function($event){return _vm.saveExcel()}}},[_vm._v("Сохранить в excel ")])])]),_vm._l((_vm.categoryGroups),function(categoryGroup){return _c('div',{key:categoryGroup.id,staticClass:"mb-3"},[(_vm.issuesByCategoryGroup(categoryGroup.id).length)?_c('div',[_c('div',{staticClass:"type-title mb-2"},[_vm._v(" "+_vm._s(categoryGroup.name)+" ")]),_vm._l((_vm.issueTypes),function(type){return _c('div',{key:type.id},[(_vm.issuesByCategoryGroup(categoryGroup.id).filter(function (item) {
              return item.type_id ? item.type_id === type.id : false
            }).length)?_c('div',[_c('div',{staticClass:"group-title mb-2"},[_vm._v(" "+_vm._s(type.name)+" ")]),_c('daily-report-table',{attrs:{"issues":_vm.issuesByCategoryGroup(type.id).filter(function (item) {
                  return item.type_id ? item.type_id === type.id : false
                })}})],1):_vm._e()])}),(_vm.issuesWithoutCategory.length)?_c('div',[_c('div',{staticClass:"type-title mb-2"},[_vm._v(" Другое ")]),_vm._l((_vm.issueTypes),function(type){return _c('div',{key:type.id},[(_vm.issuesWithoutCategory.filter(function (item) {
                return item.type_id ? item.type_id === type.id : false
              }).length)?_c('div',[_c('div',{staticClass:"group-title mb-2"},[_vm._v(" "+_vm._s(type.name)+" ")]),_c('daily-report-table',{attrs:{"issues":_vm.issuesWithoutCategory.filter(function (item) {
                    return item.type_id ? item.type_id === type.id : false
                  })}})],1):_vm._e()])})],2):_vm._e()],2):_vm._e()])})],2)])]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }