<template>
  <div class="row mb-3">
    <div class="col-11">
      <div class="custom-card">
        <div class="card-body">
          <div class="d-flex">
            <v-select
              v-model="filter.tags"
              :options="Tag.tags"
              :reduce="tag => tag.id"
              :get-option-label="(option) => option.label"
              multiple
              class="w-100"
              placeholder="Выберите теги"
              @input="submitFilter()"
            />
            <button @click="clearFilter()"
                    class="btn custom-btn-blue-outline reset-filter-btn">
              <icon-reset />
            </button>
            <button @click="$router.push('/create-issue')"
                    class="btn custom-btn-blue add-issue-btn">
              <img src="@/assets/img/icons/plus.svg"
                   alt="+">
            </button>
          </div>
          <b-collapse id="filter-collapse"
                      v-model="filterCollapseToggled">
            <div class="row mt-3">
              <div class="col-6">
                <div class="form-group">
                  <label class="form-label">Адрес</label>
                  <dadata-suggestions
                    v-model="address"
                    class="form-control form-control-fix"
                    type="ADDRESS"
                    @change="setAddress"
                    placeholder="Введите адрес"
                  />
                </div>
              </div>
              <div class="col-6">
                <div class="form-group">
                  <label class="form-label">ФИО заявителя</label>
                  <input
                    v-model="filter.applicant_name"
                    @change="submitFilter()"
                    placeholder="Введите ФИО"
                    class="form-control"
                  >
                </div>
              </div>
              <div class="col-12">
                <div class="states-filter-label">
                  Статус:
                </div>
              </div>
              <div class="col-12 mb-2">
                <label v-for="state in states"
                       :key="state.id"
                       class="custom-control custom-checkbox d-inline-block mr-3">
                  <input
                    id="drainage_not_compare"
                    v-model="filter.issue_states"
                    :value="state.id"
                    class="custom-control-input"
                    type="checkbox"
                    :disabled="validateStateFilter(state.id)"
                    @change="submitFilter()"
                  >
                  <span class="custom-control-label">
                    {{state.label}}
                  </span>
                </label>
              </div>
              <div class="col-12">
                <div class="form-group">
                  <label class="form-label">Сортировка</label>
                  <v-select
                    v-model="filter.sort_dir"
                    :options="sortingOptions"
                    :reduce="sort => sort.dir"
                    :get-option-label="(option) => option.label"
                    placeholder="Выберите сортировку"
                    @input="submitFilter()"
                  />
                </div>
              </div>
            </div>
          </b-collapse>
        </div>
      </div>
    </div>
    <div class="col-1">
      <div class="toggle-filter-block">
        <div class="toggle-filter"
             v-b-toggle.filter-collapse>
          <chevron-left-icon :class="{rotate: filterCollapseToggled}"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Tag from '@/models/serviceDesk/Tag'
import IconReset from "@/components/Icons/IconReset";
import IssueState from "@/models/serviceDesk/IssueState";
import Vue from "vue";
import DadataSuggestions from "vue-dadata-suggestions";

Vue.use(DadataSuggestions, {
  token: process.env.VUE_APP_DADATA_API_KEY,
  geoLocation: [{
    "kladr_id": process.env.VUE_APP_DADATA_KLADR_ID
  }]
});

export default {
  name: "IssuesListFilter",
  components: {IconReset},
  data() {
    return {
      filter: {
        tags: [],
        sort_dir: 'asc',
        issue_states: [
          IssueState.NEW,
          IssueState.APPOINTED,
          IssueState.ACCEPTED,
          IssueState.IN_WORK,
          IssueState.REJECTED
        ],
        address_region: null,
        address_city: null,
        address_settlement: null,
        address_street: null,
        address_house: null,
        address_block: null,
        address_flat: null,
        applicant_name: null
      },
      Tag: Tag,
      states: [
        {id: IssueState.NEW, label: 'Новая'},
        {id: IssueState.APPOINTED, label: 'Назначена'},
        {id: IssueState.ACCEPTED, label: 'Принята'},
        {id: IssueState.IN_WORK, label: 'В работе'},
        {id: IssueState.REJECTED, label: 'Снята'}
      ],
      sortingOptions: [
        {dir: 'asc', label: 'По возрастанию даты'},
        {dir: 'desc', label: 'По убыванию даты'}
      ],
      filterCollapseToggled: false,
      address: null
    }
  },
  methods: {
    submitFilter() {
      const {tags, issue_states, ...rest} = this.filter;
      const filter = {
        tags: tags.join(),
        issue_states: issue_states.join(),
        ...rest
      }

      this.$store.commit('issues/SET_FILTER_PARAMS', filter);
      this.$store.dispatch('issues/getIssues', 1);
    },
    clearFilter() {
      this.address = null;
      this.filter = {
        tags: [],
        sort_dir: 'asc',
        issue_states: [
          IssueState.NEW,
          IssueState.APPOINTED,
          IssueState.ACCEPTED,
          IssueState.IN_WORK,
          IssueState.REJECTED
        ],
        address_region: null,
        address_city: null,
        address_settlement: null,
        address_street: null,
        address_house: null,
        address_block: null,
        address_flat: null,
        applicant_name: null
      }
      this.$store.commit('issues/SET_FILTER_PARAMS', {
        issue_states: [
          IssueState.NEW,
          IssueState.APPOINTED,
          IssueState.ACCEPTED,
          IssueState.IN_WORK,
          IssueState.REJECTED
        ].join()
      });

      this.$store.dispatch('issues/getIssues', 1);
    },
    validateStateFilter(state_id) {
      if (this.filter.issue_states.length === 1) {
        return !!this.filter.issue_states.find(id => id === state_id);
      }
    },
    setAddress(i) {
      this.filter.address_region = i.data.region;
      this.filter.address_city = i.data.city;
      this.filter.address_settlement = i.data.settlement_with_type;
      this.filter.address_street = i.data.street_with_type;
      this.filter.address_house = i.data.house;
      this.filter.address_block = i.data.block;
      this.filter.address_flat = i.data.flat;

      this.submitFilter()
    }
  }
}
</script>

<style scoped>
.add-issue-btn {
  padding: 0 9px;
  margin-left: 20px;
}

.reset-filter-btn {
  margin-left: 20px;
}

.toggle-filter-block {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
}

.toggle-filter {
  display: flex;
  align-items: center;
  height: 44px;
  cursor: pointer;
}

.toggle-filter svg {
  width: 30px;
  height: 30px;
  color: #468FC5;
  transition: .3s;
}

.rotate {
  transform: rotateZ(-90deg);
}

.states-filter-label {
  font-size: 14px;
  color: #262f3d;
  font-weight: 600;
  margin-bottom: .5rem;
}
</style>