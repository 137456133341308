<template>
  <full-calendar-component
    ref="calendar"
    :options="calendarOptions"
  >
    <template v-slot:eventContent="arg">
      <slot name="eventItem"
            :eventData="arg.event"/>
    </template>
  </full-calendar-component>
</template>

<script>
import FullCalendarComponent from '@fullcalendar/vue'
import dayGridPlugin from '@fullcalendar/daygrid'
import ruLocale from '@fullcalendar/core/locales/ru'

export default {
  name: "BaseCalendar",
  components: {FullCalendarComponent},
  props: {
    events: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      calendarOptions: {
        plugins: [dayGridPlugin],
        initialView: 'dayGridMonth',
        locale: ruLocale,
        timeZone: 'UTC',
        displayEventTime: false,
        events: null,
        datesSet: this.getCurrentCalendarDates
      }
    }
  },
  watch: {
    events: function (events) {
      this.setEvents(events);
    }
  },
  methods: {
    setEvents(events) {
      this.calendarOptions.events = events;
    },
    getCurrentCalendarDates(dateInfo) {
      this.$store.commit("workShift/SET_FILTER_PARAMS", {
        period_start: this.$moment(dateInfo.start).format('YYYY-MM-DD'),
        period_end: this.$moment(dateInfo.end).format('YYYY-MM-DD')
      })
    }
  }
}
</script>

<style scoped>

</style>