import DeviceAccessService from "@/services/DeviceAccessService";
import {getError} from "@/utils/helpers";

export const namespaced = true;

export const state = {
    deviceAccesses: [],
    error: null,
    loading: false
}

export const mutations = {
    SET_DEVICE_ACCESSES(state, accesses) {
        state.deviceAccesses = accesses;
    },
    SET_ERROR(state, error) {
        state.error = error;
    },
    SET_LOADING(state, loading) {
        state.loading = loading;
    }
}

export const actions = {
    getAccessesList({commit}) {
        commit("SET_LOADING", true);
        DeviceAccessService.fetchAccessesList()
            .then(accesses => commit("SET_DEVICE_ACCESSES", accesses))
            .catch(error => commit("SET_ERROR", error))
            .finally(() => commit("SET_LOADING", false));
    },
    allowAccess(context, payload) {
        return new Promise((resolve, reject) => {
            DeviceAccessService.allowAccess(payload)
                .then(() => resolve())
                .catch(error => reject(getError(error)));
        })
    },
    revokeAccess(context, payload) {
        return new Promise((resolve, reject) => {
            DeviceAccessService.revokeAccess(payload)
                .then(() => resolve())
                .catch(error => reject(getError(error)));
        })
    }
}