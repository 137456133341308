import IssueService from "@/services/IssueService";
import {getError} from "@/utils/helpers";

export const namespaced = true

export const state = {
    issue: null,
    relatedIssues: [],
    loading: false,
    action_loading: false,
    error: null
}

export const mutations = {
    SET_ISSUE(state, issue) {
        state.issue = issue;
    },
    SET_RELATED_ISSUES(state, issues) {
        state.relatedIssues = issues;
    },
    SET_LOADING(state, loading) {
        state.loading = loading;
    },
    SET_ACTION_LOADING(state, loading) {
        state.action_loading = loading;
    },
    SET_ERROR(state, error) {
        state.error = error;
    }
}

export const actions = {
    getIssue({commit}, id) {
        commit("SET_LOADING", true);
        return new Promise((resolve, reject) => {
            IssueService.getIssue(id)
                .then(issue => {
                    commit("SET_ISSUE", issue);
                    resolve();
                })
                .catch(error => {
                    commit("SET_ERROR", getError(error));
                    reject();
                })
                .finally(() => commit("SET_LOADING", false));
        })
    },
    getRelatedIssuesList({commit}, issue_id) {
        IssueService.fetchRelatedIssuesList(issue_id)
            .then(issues => commit("SET_RELATED_ISSUES", issues))
            .catch(error => commit("SET_ERROR", error));
    },
    AppointDepartmentToIssue({commit}, payload) {
        commit("SET_ACTION_LOADING", true);
        return new Promise((resolve, reject) => {
            IssueService.appointDepartmentToIssue(payload)
                .then(() => resolve())
                .catch(error => reject(getError(error)))
                .finally(() => commit("SET_ACTION_LOADING", false));
        })
    },
    AddTaskToIssue({commit}, payload) {
        commit("SET_ACTION_LOADING", true);
        return new Promise((resolve, reject) => {
            IssueService.addTaskToIssue(payload)
                .then(() => resolve())
                .catch(error => reject(getError(error)))
                .finally(() => commit("SET_ACTION_LOADING", false));
        })
    },
    cancelIssue({commit}, issue_id) {
        commit("SET_ACTION_LOADING", true);
        return new Promise((resolve, reject) => {
            IssueService.cancelIssue(issue_id)
                .then(() => resolve())
                .catch(error => reject(getError(error)))
                .finally(() => commit("SET_ACTION_LOADING", false));
        })
    },
    rejectIssue({commit}, issue_id) {
        commit("SET_ACTION_LOADING", true);
        return new Promise((resolve, reject) => {
            IssueService.rejectIssue(issue_id)
                .then(() => resolve())
                .catch(error => reject(getError(error)))
                .finally(() => commit("SET_ACTION_LOADING", false));
        })
    },
    closeIssue({commit}, issue_id) {
        commit("SET_ACTION_LOADING", true);
        return new Promise((resolve, reject) => {
            IssueService.closeIssue(issue_id)
                .then(() => resolve())
                .catch(error => reject(getError(error)))
                .finally(() => commit("SET_ACTION_LOADING", false));
        })
    },
    acceptIssue({commit}, issue_id) {
        commit("SET_ACTION_LOADING", true);
        return new Promise((resolve, reject) => {
            IssueService.acceptIssue(issue_id)
                .then(() => resolve())
                .catch(error => reject(getError(error)))
                .finally(() => commit("SET_ACTION_LOADING", false));
        })
    },
    startIssue({commit}, issue_id) {
        commit("SET_ACTION_LOADING", true);
        return new Promise((resolve, reject) => {
            IssueService.startIssue(issue_id)
                .then(() => resolve())
                .catch(error => reject(getError(error)))
                .finally(() => commit("SET_ACTION_LOADING", false));
        })
    },
    postponeIssue({commit, state}, payload) {
        commit("SET_ACTION_LOADING", true);
        return new Promise((resolve, reject) => {
            IssueService.postponeIssue(state.issue.id, payload)
                .then(() => resolve())
                .catch(error => reject(getError(error)))
                .finally(() => commit("SET_ACTION_LOADING", false));
        })
    },
    planIssue({commit, state}, payload) {
        commit("SET_ACTION_LOADING", true);
        return new Promise((resolve, reject) => {
            IssueService.planIssue(state.issue.id, payload)
                .then(() => resolve())
                .catch(error => reject(getError(error)))
                .finally(() => commit("SET_ACTION_LOADING", false));
        })
    },
    changeIssueCategory({commit}, {issue_id, payload}) {
        commit("SET_ACTION_LOADING", true);
        return new Promise((resolve, reject) => {
            IssueService.changeIssueCategory(issue_id, payload)
                .then(() => resolve())
                .catch(error => reject(getError(error)))
                .finally(() => commit("SET_ACTION_LOADING", false));
        })
    },
    mergeIssue({commit}, {issue_id, payload}) {
        commit("SET_ACTION_LOADING", true);
        return new Promise((resolve, reject) => {
            IssueService.mergeIssue(issue_id, payload)
                .then(() => resolve())
                .catch(error => reject(getError(error)))
                .finally(() => commit("SET_ACTION_LOADING", false));
        })
    }
}

export const getters = {
    getIssue: state => {
        return state.issue
    },
    getIssueId: state => {
        return state.issue.id;
    }
}