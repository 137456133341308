<template>
  <div class="row justify-content-center">
    <div class="col-md-4">
      <div class="custom-card mb-0">
        <div class="card-body">
          <div class="container-fluid">
            <div class="row">
              <auto-form-el />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AutoFormEl from "@/components/Transport/admin/AutoForm";

export default {
  name: "AutoFormPage",
  components: {
    AutoFormEl
  },
  watch: {
    $route: 'fetchData'
  }
}
</script>

<style scoped>

</style>