<template>
  <div class="row">
    <div class="col-lg-12">
      <div class="custom-card">
        <div class="card-header pb-0">
          <h4 class="table-title d-inline-block">
            Роли
          </h4>
          <router-link
            to="/admin-roles-create"
            class="btn custom-btn-blue-outline float-right"
          >
            Добавить роль
          </router-link>
          <hr class="divider">
        </div>
        <div class="card-body">
          <div class="container-fluid">
            <div class="row">
              <roles-table />
              <base-pagination-page
                v-if="this.$store.state.role.meta && this.$store.state.role.meta.page.lastPage > 1"
                path="admin-roles"
                :meta="this.$store.state.role.meta"
                :links="this.$store.state.role.links"
                action="role/paginateRoles"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import RolesTable from "@/components/admin/RolesList";
import BasePaginationPage from "@/components/BasePaginationPage";
import store from "@/store/index";

export default {
  name: "RolesPage",
  components: {
    RolesTable,
    BasePaginationPage
  },
  beforeRouteEnter(to, from, next) {
    const currentPage = parseInt(to.query.page) || 1;
    store.dispatch("role/getRoles", currentPage).then(() => {
      to.params.page = currentPage;
      next();
    });
  }
}
</script>

<style scoped>

</style>