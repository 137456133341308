import ReportsService from "@/services/ReportsService";
import AppealsStatisticService from "@/services/AppealsStatisticService";

export const namespaced = true;

export const state = {
    reportData: [],
    reportParams: null,
    currentReportKey: 'DailyReport',
    group: 1,
    reportDate: null,
    loading: false,
    error: null
}

export const mutations = {
    SET_REPORT_DATA(state, data) {
        state.reportData = data;
    },
    SET_REPORT_PARAMS(state, params) {
        state.reportParams = params;
    },
    SET_CURRENT_REPORT(state, report_key) {
        state.currentReportKey = report_key;
    },
    SET_GROUP(state, group) {
        state.group = group;
    },
    SET_REPORT_DATE(state, date) {
        state.reportDate = date;
    },
    SET_LOADING(state, loading) {
        state.loading = loading;
    },
    SET_ERROR(state, error) {
        state.error = error;
    }
}

export const actions = {
    getDailyReportData({commit}, params) {
        commit("SET_LOADING", true);
        ReportsService.getDailyReportData(params)
            .then(issues => commit("SET_REPORT_DATA", issues))
            .catch(error => commit("SET_ERROR", error))
            .finally(() => commit("SET_LOADING", false));
    },
    getAccidentReportData({commit}, params) {
        commit("SET_LOADING", true);
        ReportsService.getAccidentReportData(params)
            .then(data => commit("SET_REPORT_DATA", data))
            .catch(error => commit("SET_ERROR", error))
            .finally(() => commit("SET_LOADING", false));
    },
    getIssuesCountReportData({commit}, params) {
        commit("SET_LOADING", true);
        return new Promise((resolve, reject) => {
            ReportsService.getIssuesCountReportData(params)
                .then(data => {
                    commit("SET_REPORT_DATA", data);
                    resolve();
                })
                .catch(error => {
                    commit("SET_ERROR", error);
                    reject(error)
                })
                .finally(() => commit("SET_LOADING", false));
        })
    },
    getAppealsCountByCategories({commit}, params) {
        commit("SET_LOADING", true);
        AppealsStatisticService.fetchAppealsCountByCategory(params)
            .then(data => commit("SET_REPORT_DATA", data))
            .catch(error => commit("SET_ERROR", error))
            .finally(() => commit("SET_LOADING", false));
    },
    getWaterFlowReportData({commit}, params) {
        commit("SET_LOADING", true);
        ReportsService.getWaterFlowReport(params)
            .then(data => commit("SET_REPORT_DATA", data))
            .catch(error => commit("SET_ERROR", error))
            .finally(() => commit("SET_LOADING", false));
    },
    getFuelAndLubricantsReportData({commit}, params) {
        commit("SET_LOADING", true);
        ReportsService.fetchFuelAndLubricantsReportData(params)
            .then(data => commit("SET_REPORT_DATA", data))
            .catch(error => commit("SET_ERROR", error))
            .finally(() => commit("SET_LOADING", false));
    }
}

export const getters = {
    getCurrentReport: state => {
        return state.currentReportKey;
    },
    getReportParams: state => {
        return state.reportParams;
    },
    getReportData: state => {
        return state.reportData;
    },
    getIssuesByType: state => typeId => {
        return state.reportData.filter(item => item.type_id === parseInt(typeId));
    },
    getIssuesByCategoryGroup: state => categoryGroupId => {
        return state.reportData.filter(item => item.category?.group.id === parseInt(categoryGroupId))
    },
    getIssuesWithoutCategory: state => {
        return state.reportData.filter(item => !item.category);
    },
    getReportDate: state => {
        return state.reportDate;
    }
}