<template>
  <div class="row">
    <div class="col-12 col-lg-6 mobile-mb-20">
      <div class="custom-card mb-4">
        <div class="card-header">
          <h1
            v-if="isVS"
            class="card-title mb-0"
          >
            Баланс водоснабжения
          </h1>
          <h1
            v-else
            class="card-title mb-0"
          >
            Баланс водоотведения
          </h1>
        </div>
        <div class="card-body">
          <div class="row">
            <div class="col-lg-6 col-xs-12">
              <div class="form-group">
                <label class="form-label">Балансовые группы</label>
                <v-select
                  v-model="filter.groups"
                  :options="sortGroups(getGroups)"
                  :reduce="group => group.id"
                  multiple
                  :get-option-label="(option) => option.attributes.name"
                  :disabled="filter.zones.length !== 0"
                />
              </div>
            </div>
            <div class="col-lg-6 col-xs-12">
              <div class="form-group">
                <label class="form-label">Зоны</label>
                <v-select
                  v-model="filter.zones"
                  :options="sortGroups(getBalanceGroupsByZones)"
                  :reduce="zone => zone.id"
                  multiple
                  :get-option-label="(option) => option.attributes.name"
                  :disabled="filter.groups.length !== 0"
                />
              </div>
            </div>
            <div class="col-lg-6 col-xs-12">
              <div id="BalancePeriod"
                   class="form-group">
                <label class="form-label">Период</label>
                <date-picker
                  id="period"
                  v-model="filter.period"
                  :format="'MMMM YYYY г.'"
                  type="month"
                  placeholder="Выберите период"
                  input-class="custom-datepicker form-control-fix"
                  multiple
                  value-type="YYYY-MM-DD"
                  :default-value="defaultValue"
                  :disabled-date="notAfterToday"
                />
              </div>
            </div>
            <div class="col-lg-6 col-xs-12">
              <div class="form-group">
                <label class="form-label">Группировка</label>
                <select
                  v-model="grouping"
                  class="form-control form-control-fix"
                >
                  <option
                    :value="null"
                    disabled
                  >
                    Выберите группировку
                  </option>
                  <option :value="0">
                    Общая
                  </option>
                  <option :value="1">
                    По месяцам
                  </option>
                </select>
              </div>
            </div>
            <div class="col-lg-12 col-xs-12">
              <button
                class="btn custom-btn-blue w-100"
                @click="submitFilter()"
              >
                Сгруппировать
                <base-loader class="ml-1"
                             v-if="loading" />
              </button>
            </div>
          </div>
        </div>
      </div>
      <balance-result
        :filter="this.filter"
        :isVs="this.$route.name === 'WaterBalance'"
      />
    </div>
    <div class="col-12 col-lg-6">
      <balance-map
        :balance_zones_ids="filter.zones"
        :balance_groups_ids="filter.groups"
        :periods="filter.period"
      />
    </div>
  </div>

</template>

<script>
import BalanceMap from "@/components/Billing/BalanceMap";
import {mapGetters} from "vuex";
import DatePicker from "vue2-datepicker";
import BalanceResult from "@/components/Billing/BalanceResult";
import BaseLoader from "@/layout/BaseLoader";
import notification from "@/mixins/notification";

export default {
  name: "BalancePage",
  components: {BaseLoader, BalanceResult, BalanceMap, DatePicker},
  mixins: [notification],
  data() {
    return {
      isVS: true,
      isZones: false,
      filter: {
        groups: [],
        zones: [],
        period: null
      },
      defaultValue: new Date()
    }
  },
  computed: {
    ...mapGetters({
      getBalanceGroupsByZones: 'balanceGroup/getBalanceGroupsByZones',
      getGroups: 'balanceGroup/getBalanceGroupsForTotal',
      getZone: 'zone/getZone',
      loading: 'balance/loading'
    }),
    grouping: {
      get() {
        return this.$store.getters['balance/grouping'];
      },
      set(grouping) {
        this.$store.commit('balance/SET_GROUPING', grouping);
      }
    },
    sortGroups: function () {
      return groups => {
        let sorted_groups = []

        if (this.$route.name === 'WaterBalance') {
          sorted_groups = groups.filter(group => group.attributes.network === 1);
        } else if (this.$route.name === 'DrainBalance') {
          sorted_groups = groups.filter(group => group.attributes.network === 2);
        }

        return sorted_groups
      }
    }
  },
  watch: {
    $route() {
      this.isVS = this.$route.name === 'WaterBalance';
      this.filter.groups = []
      this.filter.zones = []
      this.filter.period = null
      this.grouping = null

      this.$store.commit('balance/CLEAR_STATE');
    }
  },
  beforeDestroy() {
    this.$store.commit('balance/CLEAR_STATE');
  },
  mounted() {
    this.$store.dispatch("zone/getAllZones");
    this.$store.dispatch("balanceGroup/getAllBalanceGroups");
    this.$store.dispatch('device/getAllDevices');

    this.isVS = this.$route.name === 'WaterBalance';
  },
  methods: {
    submitFilter() {
      if (this.filter.groups.length === 0 && this.filter.zones.length === 0) {
        this.alert('Ошибка', 'Выберите балансовую группу или зону', 'warning');
        return false;
      } else if (this.filter.period === null) {
        this.alert('Ошибка', 'Выберите периоды', 'warning');
        return false;
      } else if (this.grouping === null) {
        this.alert('Ошибка', 'Выберите группировку', 'warning');
        return false;
      }

      let payload
      if (this.filter.zones.length !== 0) {
        payload = {
          params: {
            periods: this.filter.period,
            groups: this.filter.zones
          },
          grouping: this.grouping
        }
        this.isZones = true
      } else if (this.filter.groups.length !== 0) {
        payload = {
          params: {
            periods: this.filter.period,
            groups: this.filter.groups
          },
          grouping: this.grouping
        }
        this.isZones = false
      }

      if (this.isVS) {
        this.$store.dispatch('balance/getSupplyBalance', payload);
      } else {
        this.$store.dispatch('balance/getDrainBalance', payload);
      }
    },
    notAfterToday(date) {
      let lastDate = new Date();
      lastDate.setDate(1);
      lastDate.setMonth(lastDate.getMonth() - 1);
      return date > lastDate;
    }
  }
}
</script>

<style scoped>

</style>