import * as API from './API';
import NetworkEquipmentParameterValue from "@/models/telemetry/NetworkEquipmentParameterValue";
import {getError} from "@/utils/helpers";

export default {
    createNetworkEquipmentParameter(equipmentId, payload) {
        return API.apiClient.post(`/network-equipments/${equipmentId}/parameters`, payload);
    },
    updateNetworkEquipmentParameter(equipmentId, parameterId, payload) {
        return API.apiClient.patch(`/network-equipments/${equipmentId}/parameters/${parameterId}`, payload);
    },
    deleteNetworkEquipmentParameter(equipmentId, parameterId) {
        return API.apiClient.delete(`/network-equipments/${equipmentId}/parameters/${parameterId}`);
    },
    fetchLatestParametersValues() {
        return new Promise((resolve, reject) => {
            API.apiClient.get(`/network-equipments/momentary-readings`)
                .then(response => resolve(response.data.data.map(item => new NetworkEquipmentParameterValue({
                    id: item.id,
                    ...item.attributes
                }))))
                .catch(error => reject(getError(error)))
        })
    }
}