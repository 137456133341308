<template>
  <div class="custom-card">
    <div class="card-body">
      <div
        v-if="resourceStatistic.tasks_types.length"
        class="chart-title mb-2">Количество выполненных заданий</div>
      <div
        v-else
        class="chart-title">Выполненные задания отсутствуют</div>
      <table class="table">
        <tbody>
          <tr v-for="taskType in resourceStatistic.tasks_types"
              :key="taskType.id">
            <td>{{taskType.name}}</td>
            <td>{{taskType.count}}</td>
          </tr>
        </tbody>
      </table>
      <apexchart
        v-if="resourceStatistic.tasks_types.length"
        type="donut"
        height="400px"
        :options="createChartOptions"
        :series="createChartSeries"
      />
    </div>
  </div>
</template>

<script>
import {mapState} from "vuex";
import VueApexCharts from "vue-apexcharts";

export default {
  name: "ResourceCompletedTasksCount",
  components: {'apexchart': VueApexCharts},
  computed: {
    ...mapState({
      resourceStatistic: state => state.resourceStatistic.resourceStatistic
    }),
    createChartOptions() {
      return {
        legend: {
          show: true,
          position: 'bottom'
        },
        labels: this.resourceStatistic.tasks_types.map(item => item.name),
        tooltip: {
          enabled: false
        },
        dataLabels: {
          formatter: (val, opts) => {
            return opts.w.config.series[opts.seriesIndex]
          }
        },
        plotOptions: {
          pie: {
            expandOnClick: false,
            donut: {
              labels: {
                show: true,
                total: {
                  show: true,
                  label: 'Всего'
                }
              }
            }
          }
        }
      }
    },
    createChartSeries() {
      return this.resourceStatistic.tasks_types.map(item => item.count)
    }
  }
}
</script>

<style scoped>
.chart-title {
  font-size: 20px;
  font-weight: 600;
  color: #262F3D;
}

.table td {
  font-weight: 500;
  font-size: 15px;
}

@media (max-width: 767px) {
  chart-title {
    font-size: 18px;
  }
}
</style>