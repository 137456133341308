import {getError} from "@/utils/helpers";
import SensorService from "@/services/SensorService";

export const namespaced = true;

function setPaginatedSensors(commit, response) {
    commit("SET_SENSORS", response.data.data);
    commit("SET_META", response.data.meta);
    commit("SET_LINKS", response.data.links);
    commit("SET_LOADING", false);
}

export const state  = {
    sensors: [],
    sensor: null,
    deviceSensors: [],
    meta: null,
    links: null,
    loading: false,
    error: null
};

export const mutations = {
    SET_SENSORS(state, sensors) {
        state.sensors = sensors;
    },
    SET_SENSOR(state, sensor) {
        state.sensor = sensor;
    },
    SET_DEVICE_SENSORS(state, sensors) {
        state.deviceSensors = sensors;
    },
    SET_META(state, meta) {
        state.meta = meta;
    },
    SET_LINKS(state, links) {
        state.links = links;
    },
    SET_LOADING(state, loading) {
        state.loading = loading;
    },
    SET_ERROR(state, error) {
        state.error = error;
    },
    DELETE_SENSOR(state, sensor_id){
        let index = state.sensors.findIndex(sensor => sensor.id == sensor_id);
        state.sensors.splice(index, 1);
    }
};

export const actions = {
    getSensors({ commit }, page) {
        commit("SET_LOADING", true);
        SensorService.getSensors(page)
            .then(response => {
                setPaginatedSensors(commit, response);
            })
            .catch(error => {
                commit("SET_LOADING", false);
                commit("SET_ERROR", getError(error));
            });
    },
    getAllSensors({ commit }) {
        commit("SET_LOADING", true);
        SensorService.getAllSensors()
            .then(response => {
                commit("SET_SENSORS", response.data.data);
            })
            .catch(error => {
                commit("SET_LOADING", false);
                commit("SET_ERROR", getError(error));
            });
    },
    getSensor({commit}, sensorId) {
        return new Promise((resolve, reject) => {
            SensorService.getSensor(sensorId)
                .then(sensor => {
                    commit("SET_SENSOR", sensor);
                    resolve(sensor);
                })
                .catch(error => reject(error))
        })
    },
    createSensor(context, payload) {
        return new Promise((resolve, reject) => {
            SensorService.createSensor(payload)
                .then(() => resolve())
                .catch(error => reject(getError(error)));
        })
    },
    updateSensor(context, {sensorId, payload}) {
        return new Promise((resolve, reject) => {
            SensorService.updateSensor(sensorId, payload)
                .then(() => resolve())
                .catch(error => reject(getError(error)));
        })
    },
    deleteSensor(context, sensorId) {
        return new Promise((resolve, reject) => {
            SensorService.deleteSensor(sensorId)
                .then(() => resolve())
                .catch(error => reject(getError(error)));
        })
    },
    getDeviceSensorsList({commit}, deviceId) {
        return new Promise((resolve, reject) => {
            SensorService.fetchDeviceSensorsList(deviceId)
                .then(sensors => {
                    commit("SET_DEVICE_SENSORS", sensors);
                    resolve();
                })
                .catch(error => reject(error));
        })
    },
    attachToParameter(context, {sensorId, payload}) {
        return new Promise((resolve, reject) => {
            SensorService.attachSensorToParameter(sensorId, payload)
                .then(() => resolve())
                .catch(error => reject(getError(error)));
        })
    },
    detachParameter(context, sensorId) {
        return new Promise((resolve, reject) => {
            SensorService.detachSensorFromParameter(sensorId)
                .then(() => resolve())
                .catch(error => reject(getError(error)));
        })
    }
};

export const getters = {
    getSensor: state => id =>{
        return state.sensors.find(sensor => sensor.id == id);
    },
    filSensors: state => d => {
        return state.sensors.filter(sensors => sensors.attributes.device_id == d);
    },
    getSensorsByDeviceId: state => id => {
        return state.sensors.filter(sensor => sensor.attributes.device_id === parseInt(id))
    },
    shortName: state => id => {
        let sensor = state.sensors.find(sensor => sensor.id == id);
        if (sensor != undefined){
            return sensor.attributes.sensor_short_name;
        }else{
            return '';
        }
    },
    measure: state => id =>{
        let sensor = state.sensors.find(sensor => sensor.id == id);
        if (sensor != undefined){
            return sensor.attributes.sensor_measure;
        }else{
            return '';
        }
    },
    getSensorMaxValue: state => id => {
        let sensor = state.sensors.find(sensor => parseInt(sensor.id) === id);
        if (sensor !== undefined) return sensor.attributes.max_value;
        else return ''
    },
    getSensorMinValue: state => id => {
        let sensor = state.sensors.find(sensor => parseInt(sensor.id) === id);
        if (sensor !== undefined) return sensor.attributes.min_value;
        else return ''
    },
    sensors: state => {
        return state.sensors;
    },
    meta: state => {
        return state.meta;
    },
    links: state => {
        return state.links;
    },
    loading: state => {
        return state.loading;
    },
    error: state => {
        return state.error;
    }
}