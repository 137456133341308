<template>
  <b-modal
    id="add-comment"
    ref="add-comment"
    centered
    hide-header
    hide-footer
  >
    <div class="col-12">
      <div class="custom-modal-header">
        <div class="custom-modal-title">
          Добавить комментарий
        </div>
        <img
          class="custom-modal-close"
          src="@/assets/img/icons/close.svg"
          @click="$bvModal.hide('add-comment')"
        >
      </div>
      <div class="modal-divider" />
    </div>
    <form @submit.prevent="submitForm()">
      <div class="col-12 mb-2">
        <div class="form-group">
          <label class="form-label">Комментарий</label>
          <textarea class="form-control form-control-fix"
                    maxlength="1500"
                    placeholder="Введите комментарий"
                    v-model="payload.comment"/>
        </div>
        <div class="modal-divider" />
        <div class="row">
          <div class="col-12">
            <button
              type="submit"
              class="btn custom-btn-blue w-100"
            >
              Добавить
            </button>
          </div>
        </div>
      </div>
    </form>
  </b-modal>
</template>

<script>
import {mapGetters} from "vuex";
import notification from "@/mixins/notification";

export default {
  name: "AddCommentForm",
  mixins: [notification],
  data() {
    return {
      payload: {
        comment: null
      }
    }
  },
  computed: {
    ...mapGetters({
      task_id: 'task/getTaskId'
    })
  },
  methods: {
    submitForm() {
      this.$store.dispatch('task/addComment', {
        task_id: this.task_id,
        payload: this.payload
      })
          .then(() => {
            this.payload.comment = null;
            this.$store.dispatch('comments/getTaskComments', this.task_id);
            this.$bvModal.hide('add-comment');
            this.toast('Комментарий добавлен', 'success');
          })
          .catch(error => this.alert('Ошибка', error, 'error'));
    }
  }
}
</script>

<style scoped>

</style>