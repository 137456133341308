export const getError = error => {
    const errorMessage = "ошибка API, попробуйте ещё раз.";

    if (error.name === "Fetch User") {
        return error.message;
    }

    if (!error.response) {
        return errorMessage;
    }

    if (error.response.data && error.response.data.errors) {
        let error_string  = '';
        error.response.data.errors.forEach(function(item) {
            error_string += item.detail + "\n";
        });
        return error_string;
    }

    return errorMessage;
};

export const groupBy = (list, keyGetter) => {
    const map = new Map();
    list.forEach(item => {
        const key = keyGetter(item);
        const collection = map.get(key);
        if (!collection) map.set(key, [item])
        else collection.push(item)
    })
    return map;
}

export const isNumber = event => {
    event = (event) ? event : window.event;
    let charCode = (event.which) ? event.which : event.keyCode;
    if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46 && charCode !== 44) {
        event.preventDefault();
    } else {
        return true;
    }
}