<template>
  <table class="admin-table">
    <thead class="admin-table-header">
      <tr>
        <td>ID</td>
        <td>Наименование</td>
        <td>Тип</td>
        <td>Адрес</td>
        <td>Телефон</td>
        <td>Координаты</td>
      </tr>
    </thead>
    <tbody class="admin-table-body">
      <tr
        v-for="networkObject in networkObjects"
        :key="networkObject.id"
        class="network_object__item"
        @click="$router.push('/network-object-page/' + networkObject.id)"
      >
        <td>{{ networkObject.id }}</td>
        <td>{{ networkObject.name }}</td>
        <td>{{ networkObject.getTypeLabel() }}</td>
        <td>{{ networkObject.address }}</td>
        <td>{{ networkObject.phone }}</td>
        <td>{{ networkObject.coordinates.join() }}</td>
      </tr>
    </tbody>
  </table>
</template>

<script>
import {mapState} from "vuex";

export default {
  name: "NetworkObjectsList",
  computed: {
    ...mapState({
      networkObjects: state => state.networkObjects.networkObjects
    })
  }
}
</script>

<style scoped>
.network_object__item {
  cursor: pointer;
}

.network_object__item:hover {
  background-color: aliceblue;
}
</style>