import AppealsService from '@/services/AppealsService'
import {getError} from "@/utils/helpers";

export const namespaced = true

export const state = {
    appeals: [],
    page: null,
    linked_appeals: [],
    appealsForLink: [],
    appeal: null,
    filterParams: null,
    hideMap: false,
    meta: null,
    links: null,
    loading: false,
    action_loading: false,
    appeal_loading: false,
    error: null
}

export const mutations = {
    SET_APPEALS(state, appeals) {
        state.appeals = appeals
    },
    SET_PAGE(state, page) {
        state.page = page;
    },
    SET_LINKED_APPEALS(state, appeals) {
        state.linked_appeals = appeals;
    },
    SET_APPEALS_FOR_LINK(state, appeals_id) {
        state.appealsForLink = appeals_id;
    },
    SET_APPEAL(state, appeal) {
        state.appeal = appeal;
    },
    SET_FILTER_PARAMS(state, params) {
        state.filterParams = params;
    },
    TOGGLE_HIDE_MAP(state) {
        state.hideMap = !state.hideMap;
    },
    SET_META(state, meta) {
        state.meta = meta;
    },
    SET_LINKS(state, links) {
        state.links = links;
    },
    SET_LOADING(state, loading) {
        state.loading = loading;
    },
    SET_ACTION_LOADING(state, loading) {
        state.action_loading = loading;
    },
    SET_APPEAL_LOADING(state, loading) {
        state.appeal_loading = loading;
    },
    SET_ERROR(state, error) {
        state.error = error;
    }
}

export const actions = {
    createAppeal({commit}, payload) {
        return new Promise((resolve, reject) => {
            AppealsService.createAppeal(payload)
                .then(id => resolve(id))
                .catch(error => {
                    commit("SET_ERROR", error)
                    reject()
                })
        })
    },
    getAppeals({commit, state}, page) {
        commit("SET_LOADING", true)
        AppealsService.getAppeals(state.filterParams, page)
            .then(({appeals, meta, links}) => {
                commit("SET_APPEALS", appeals);
                commit("SET_META", meta);
                commit("SET_LINKS", links);
            })
            .catch(error => commit("SET_ERROR", error))
            .finally(() => commit("SET_LOADING", false))
    },
    getAppealsByStatus({commit}, status) {
        commit("SET_LOADING", true);
        AppealsService.getAppealsByStatus(status)
            .then(appeals => commit("SET_APPEALS", appeals))
            .catch(error => commit("SET_ERROR", error))
            .finally(() => commit("SET_LOADING", false));
    },
    getAppeal({commit}, id) {
        commit("SET_APPEAL_LOADING", true);
        return new Promise(resolve => {
            AppealsService.getAppeal(id)
                .then(appeal => {
                    commit("SET_APPEAL", appeal);
                    resolve()
                })
                .catch(error => commit("SET_ERROR", error))
                .finally(() => commit("SET_APPEAL_LOADING", false));
        })
    },
    changeAppealAddress({commit}, params) {
        return new Promise((resolve, reject) => {
            AppealsService.changeAppealAddress(params.id, params.payload)
                .then(() => resolve())
                .catch(error => {
                    commit("SET_ERROR", getError(error));
                    reject()
                });
        })
    },
    changeAppealApplicant({commit}, params) {
        return new Promise((resolve, reject) => {
            AppealsService.changeAppealApplicant(params.id, params.payload)
                .then(() => resolve())
                .catch(error => {
                    commit("SET_ERROR", getError(error));
                    reject(getError(error));
                });
        })
    },
    cancelAppeal({commit}, id) {
        return new Promise((resolve, reject) => {
            AppealsService.cancelAppeal(id)
                .then(() => resolve())
                .catch(error => {
                    commit("SET_ERROR", getError(error));
                    reject(getError(error))
                })
        })
    },
    linkAppealToIssue({commit}, payload) {
        return new Promise((resolve, reject) => {
            AppealsService.linkAppeal(payload)
                .then(() => resolve())
                .catch(error => {
                    commit("SET_ERROR", getError(error));
                    reject(getError(error));
                })
                .finally(() => commit("SET_ACTION_LOADING", false))
        })
    },
    unlinkAppealFromIssue({commit}, appeal_id) {
        commit("SET_ACTION_LOADING", true);
        return new Promise((resolve, reject) => {
            AppealsService.unlinkAppeal(appeal_id)
                .then(() => resolve())
                .catch(error => reject(getError(error)))
                .finally(() => commit("SET_ACTION_LOADING", false));
        })
    },
    getLinkedToIssueAppeals({commit}, issue_id) {
        commit("SET_LOADING", true);
        AppealsService.getLinkedToIssueAppeals(issue_id)
            .then(appeals => commit("SET_LINKED_APPEALS", appeals))
            .catch(error => commit("SET_ERROR", error))
            .finally(() => commit("SET_LOADING", false));
    }
}

export const getters = {
    getAppeals: state => {
        return state.appeals
    },
    getAppealById: state => appeal_id => {
        return state.appeals.find(appeal => appeal.id === appeal_id);
    },
    getAppeal: state => {
        return state.appeal
    },
    getLinkedToIssueAppeals: state => {
        return state.linked_appeals;
    },
    getHideMap: state => {
        return state.hideMap;
    },
    getFilterParams: state => {
        return state.filterParams;
    },
    getAppealsForLink: state => {
        return state.appealsForLink;
    }
}