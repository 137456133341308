<template>
  <div class="row justify-content-center align-items-center fixed-height">
    <div class="col-xl-4 col-xs-12">
      <transport-data-server-form />
    </div>
  </div>
</template>

<script>
import TransportDataServerForm from "@/components/Transport/admin/TransportDataServerForm";
export default {
  name: "TransportDataServerFormPage",
  components: {TransportDataServerForm}
}
</script>

<style scoped>

</style>