<template>
  <div>
    <table class="admin-table">
      <thead class="admin-table-header">
        <tr>
          <td>#</td>
          <td>Наименование</td>
          <td>Управление</td>
        </tr>
      </thead>
      <tbody class="admin-table-body">
        <tr v-for="work in works"
            :key="work.id"
            class="work-item"
        >
          <td>{{ work.id }}</td>
          <td>{{ work.name }}</td>
          <td class="table-action">
            <router-link :to="`/update-work/`+work.id">
              <edit-icon class="mr-1" />
            </router-link>
            <trash2-icon @click="deleteWork(work.id)"
                         class="cursor-pointer" />
          </td>
        </tr>
      </tbody>
    </table>
    <base-pagination
      v-if="$store.state.works.meta
        && $store.state.works.meta.last_page > 1"
      path="works"
      :meta="$store.state.works.meta"
      :links="$store.state.works.links"
      action="works/getWorks"
    />
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import BasePagination from "@/components/BasePagination";
import notification from "@/mixins/notification";

export default {
  name: "WorksList",
  components: {BasePagination},
  mixins: [notification],
  computed: {
    ...mapGetters({
      works: 'works/getWorks'
    })
  },
  methods: {
    deleteWork(id) {
      this.$store.dispatch('works/deleteWork', id)
          .then(() => {
            const currentPage = this.$route.query.page || 1
            this.$store.dispatch('works/getWorks', currentPage);
            this.toast('Работа удалена', 'success');
          })
          .catch(error => this.alert('Ошибка', error, 'error'));
    }
  }
}
</script>

<style scoped>

</style>