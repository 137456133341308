<template>
  <div class="row">
    <div class="col-xl-3 col-xs-12">
      <div class="custom-card">
        <div class="card-body">
          <h2 class="mb-3">Расписание бригад</h2>
          <form @submit.prevent="submitForm()">
            <div class="form-group">
              <label class="form-label">Дата начала</label>
              <date-picker
                id="startDate"
                input-class="form-control-fix w-100 pl-2"
                placeholder="Выберите дату"
                v-model="payload.start_date"
                :default-value="defaultValue"
                format="DD.MM.YYYY HH:mm"
                type="datetime"
                value-type="YYYY-MM-DD HH:mm"
                @clear="payload.start_date = null" />
            </div>
            <div class="form-group">
              <label class="form-label">Дата окончания</label>
              <date-picker
                id="endDate"
                input-class="form-control-fix w-100 pl-2"
                placeholder="Выберите дату"
                v-model="payload.end_date"
                :default-value="defaultValue"
                format="DD.MM.YYYY HH:mm"
                type="datetime"
                value-type="YYYY-MM-DD HH:mm"
                @clear="payload.end_date = null" />
            </div>
            <div class="form-group">
              <label class="form-label">Бригада</label>
              <v-select
                v-model="payload.brigade_id"
                :options="brigades"
                :reduce="brigade => brigade.id"
                :get-option-label="(option) => option.name"
                placeholder="Выберите бригаду"
              />
            </div>
            <button class="btn custom-btn-blue w-100">Добавить</button>
          </form>
        </div>
      </div>
    </div>
    <div class="col-xl-9 col-xs-12">
      <div class="custom-card">
        <div class="card-body">
          <base-calendar :events="workShifts.map(workShift => workShift.getCalendarEvent())">
            <template v-slot:eventItem="{eventData}">
              <div class="text-center">
                {{ eventData.extendedProps.time_start }} -
                {{ eventData.extendedProps.time_end }}
              </div>
              <div class="text-center">
                {{ eventData.title }}
              </div>
            </template>
          </base-calendar>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BaseCalendar from "@/components/BaseCalendar";
import DatePicker from "vue2-datepicker";
import {mapGetters} from "vuex";
import notification from "@/mixins/notification";
export default {
  name: "BrigadesWorkShiftPage",
  components: {BaseCalendar, DatePicker},
  mixins: [notification],
  data() {
    return {
      payload: {
        start_date: null,
        end_date: null,
        brigade_id: null
      },
      defaultValue: new Date()
    }
  },
  computed: {
    ...mapGetters({
      brigades: 'brigades/getBrigades',
      workShifts: 'workShift/getWorkShifts',
      filterParams: 'workShift/getFilterParams'
    })
  },
  mounted() {
    this.$store.dispatch('brigades/getAllBrigades');
    this.$store.dispatch('workShift/getWorkShifts', this.filterParams);
    this.addEventListeners();
  },
  methods: {
    getWorkShifts() {
      this.$store.dispatch('workShift/getWorkShifts', this.filterParams);
    },
    addEventListeners() {
      let self = this
      document.getElementsByClassName('fc-prev-button')[0].addEventListener('click', function () {
        self.getWorkShifts()
      })
      document.getElementsByClassName('fc-next-button')[0].addEventListener('click', function () {
        self.getWorkShifts()
      })
      document.getElementsByClassName('fc-today-button')[0].addEventListener('click', function () {
        self.getWorkShifts()
      })
    },
    submitForm() {
      this.$store.dispatch('workShift/createWorkShift', this.payload)
          .then(() => {
            this.payload = {
              start_date: null,
              end_date: null,
              brigade_id: null
            }
            this.toast('Смена создана', 'success');
            this.$store.dispatch('workShift/getWorkShifts', this.filterParams);
          })
          .catch(error => {
            this.alert('Ошибка', error, 'error');
          })
    }
  }
}
</script>

<style scoped>

</style>