<template>
  <div class="row mb-3">
    <div class="col-11">
      <div class="custom-card">
        <div class="card-body">
          <div class="d-flex">
            <v-select
              v-model="filter.department_id"
              :options="departments"
              :reduce="department => department.id"
              :get-option-label="(option) => option.name"
              class="w-100 mr-2"
              placeholder="Выберите подразделение"
              @input="submitFilter()" />
          </div>
          <b-collapse
            id="filter-collapse"
            v-model="filterCollapseToggled">
            <div class="row mt-3">
              <div class="col-12">
                <div class="states-filter-label">Статус:</div>
                <label v-for="state in states"
                       :key="state.id"
                       class="custom-control custom-checkbox d-inline-block mr-2">
                  <input
                    id="Illegal"
                    v-model="filter.issue_states"
                    :value="state.id"
                    class="custom-control-input"
                    type="checkbox"
                    :disabled="validateIssueStatesFilter(state.id)"
                    @change="submitFilter()"
                  >
                  <span class="custom-control-label">{{state.label}}</span>
                </label>
              </div>
            </div>
          </b-collapse>
        </div>
      </div>
    </div>
    <div class="col-1">
      <div class="toggle-filter-block">
        <div class="toggle-filter"
             v-b-toggle.filter-collapse>
          <chevron-left-icon :class="{rotate: filterCollapseToggled}"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import IssueState from "@/models/serviceDesk/IssueState";

export default {
  name: "DepartmentIssuesListFilter",
  data() {
    return {
      filter: {
        department_id: null,
        issue_states: [IssueState.APPOINTED, IssueState.ACCEPTED, IssueState.IN_WORK]
      },
      states: [
        {id: IssueState.APPOINTED, label: 'Назначена'},
        {id: IssueState.ACCEPTED, label: 'Принята'},
        {id: IssueState.IN_WORK, label: 'В работе'}
      ],
      filterCollapseToggled: false
    }
  },
  computed: {
    ...mapGetters({
      departments: 'departments/getDepartments'
    })
  },
  mounted() {
    this.$store.dispatch('departments/getAllDepartments');
  },
  methods: {
    validateIssueStatesFilter(state_id) {
      if (this.filter.issue_states.length === 1) {
        return !!this.filter.issue_states.find(id => id === state_id)
      }
    },
    submitFilter() {
      this.$store.commit("issues/SET_FILTER_PARAMS", {
        department_id: this.filter.department_id,
        issue_states: this.filter.issue_states.join()
      })
      this.$store.dispatch('issues/getIssues', 1);
    }
  }
}
</script>

<style scoped>
.toggle-filter-block {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
}

.toggle-filter {
  display: flex;
  align-items: center;
  height: 44px;
  cursor: pointer;
}

.toggle-filter svg {
  width: 30px;
  height: 30px;
  color: #468FC5;
  transition: .3s;
}

.rotate {
  transform: rotateZ(-90deg);
}

.states-filter-label {
  font-size: 14px;
  color: #262F3D;
  font-weight: 600;
  margin-bottom: .5rem;
}
</style>