<template>
  <b-modal
    id="add-water-flow"
    ref="add-water-flow"
    centered
    hide-header
    hide-footer
    @hidden="clearFields()"
  >
    <div class="col-12">
      <div class="custom-modal-header">
        <div class="custom-modal-title">
          Добавить расход воды
        </div>
        <img
          alt="Закрыть"
          class="custom-modal-close"
          src="@/assets/img/icons/close.svg"
          @click="$bvModal.hide('add-work')"
        >
      </div>
      <div class="modal-divider" />
    </div>
    <form @submit.prevent="submitForm()">
      <div class="col-12 mb-2">
        <div class="form-group">
          <label class="form-label">Расходованный объем воды, м<sup>3</sup></label>
          <input
            v-model="payload.volume"
            class="form-control form-control-fix"
            type="text"
            placeholder="Введите объем воды"
          >
        </div>
        <div class="form-group">
          <label class="form-label">Статья списания</label>
          <v-select v-model="payload.type"
                    :options="waterFlowTypes"
                    :reduce="type => type.id"
                    :get-option-label="(option) => option.name"
                    placeholder="Выберите статья списания"
          />
        </div>
        <div class="form-group">
          <label class="form-label">Дата расхода</label>
          <date-picker
            v-model="payload.released_at"
            :default-value="defaultValue"
            input-class="form-control-fix w-100 pl-2"
            placeholder="Выберите дату"
            type="day"
            value-type="YYYY-MM-DD"
            format="DD.MM.YYYY"
          />
        </div>
        <div class="form-group">
          <label class="form-label">Комментарий</label>
          <textarea v-model="payload.comment"
                    maxlength="1500"
                    class="form-control form-control-fix"
                    placeholder="Введите комментарий" />
        </div>
        <div class="modal-divider" />
        <div class="row">
          <div class="col-12">
            <button
              type="submit"
              class="btn custom-btn-blue w-100"
            >
              Добавить
            </button>
          </div>
        </div>
      </div>
    </form>
  </b-modal>
</template>

<script>
import DatePicker from "vue2-datepicker";
import {mapState} from "vuex";
import {showAlert, showToast} from "@/utils/notification";

export default {
  name: "AddWaterFlow",
  components: {DatePicker},
  data() {
    return {
      payload: {
        volume: null,
        type: null,
        released_at: null,
        comment: null
      },
      waterFlowTypes: [
        {id: 1, name: 'Реализация воды'},
        {id: 2, name: 'Собственные нужды'}
      ]
    }
  },
  computed: {
    ...mapState({
      task: state => state.task.task
    })
  },
  methods: {
    submitForm() {
      this.$store.dispatch('waterFlows/addWaterFlow', {
        taskId: this.task.task.id,
        payload: this.payload
      })
          .then(() => {
            this.$bvModal.hide('add-water-flow');
            this.$store.dispatch('waterFlows/getTaskWaterFlows', this.task.task.id);
            showToast('Расход воды добавлен', 'success');
            this.clearFields();
          })
          .catch(error => showAlert('Ошибка', error))
    },
    clearFields() {
      this.payload = {
        volume: null,
        type: null,
        released_at: null,
        comment: null
      }
    }
  }
}
</script>

<style scoped>

</style>
