import TaskTypesService from "@/services/TaskTypesService";
import {getError} from "@/utils/helpers";

export const namespaced = true;

export const state = {
    task_types: [],
    taskTypesFeatures: [],
    meta: null,
    links: null,
    error: null,
    loading: false
}

export const mutations = {
    SET_TASK_TYPES(state, types) {
        state.task_types = types;
    },
    SET_TASK_TYPES_FEATURES(state, features) {
        state.taskTypesFeatures = features;
    },
    SET_META(state, meta) {
        state.meta = meta;
    },
    SET_LINKS(state, links) {
        state.links = links;
    },
    SET_ERROR(state, error) {
        state.error = error;
    },
    SET_LOADING(state, loading) {
        state.loading = loading;
    },
    DELETE_TASK_TYPE(state, taskTypeId) {
        const index = state.task_types.findIndex(taskType => taskType.id === taskTypeId);
        state.task_types.splice(index, 1);
    }
}

export const actions = {
    getAllTaskTypes({commit}) {
        commit("SET_LOADING", true);
        TaskTypesService.getAllTaskTypes()
            .then(task_types => commit("SET_TASK_TYPES", task_types))
            .catch(error => commit("SET_ERROR", error))
            .finally(() => commit("SET_LOADING", false));
    },
    getTaskTypes({commit}, page) {
        commit("SET_LOADING", true);
        TaskTypesService.getTaskTypes(page)
            .then(response_data => {
                const {task_types, meta, links} = response_data;
                commit("SET_TASK_TYPES", task_types);
                commit("SET_META", meta);
                commit("SET_LINKS", links);
            })
            .catch(error => commit("SET_ERROR", error))
            .finally(() => commit("SET_LOADING", false));
    },
    createTaskType({commit}, payload) {
        return new Promise((resolve, reject) => {
            TaskTypesService.createTaskType(payload)
                .then(() => resolve())
                .catch(error => {
                    commit("SET_ERROR", error);
                    reject(error)
                })
        })
    },
    updateTaskType(context, {taskTypeId, payload}) {
        return new Promise((resolve, reject) => {
            TaskTypesService.updateTaskType(taskTypeId, payload)
                .then(() => resolve())
                .catch(error => reject(getError(error)))
        })
    },
    deleteTaskType(context, taskTypeId) {
        return new Promise((resolve, reject) => {
            TaskTypesService.deleteTaskType(taskTypeId)
                .then(() => resolve())
                .catch(error => reject(getError(error)))
        })
    },
    getTaskTypesFeatures({commit}) {
        TaskTypesService.fetchTaskTypesFeatures()
            .then(features => commit("SET_TASK_TYPES_FEATURES", features))
            .catch(error => commit("SET_ERROR", error))
    }
}

export const getters = {
    getTaskTypes: state => {
        return state.task_types
    },
    getTaskTypeById: state => taskTypeId => {
        const taskType = state.task_types.find(item => item.id === parseInt(taskTypeId));
        return taskType ? taskType : null
    },
    getTaskTypesByCategoryGroup: state => categoryGroupId => {
        return state.task_types.filter(taskType => taskType.categoryGroups
            .some(group => group.id === categoryGroupId))
    }
}