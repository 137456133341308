<template>
  <div class="col-lg-12 p-0 map-base-height">
    <div v-if="$store.state.standardBuilding.buildings.length !== 0"
         class="map-indication-switcher">
      <div class="custom-control custom-switch">
        <input id="season_switch"
               v-model="season_indication"
               type="checkbox"
               class="custom-control-input"
        >
        <label v-if="season_indication"
               class="custom-control-label"
               for="season_switch">Сезон</label>
        <label v-else
               class="custom-control-label"
               for="season_switch">Не сезон</label>
      </div>
    </div>
    <base-map :center="this.$store.getters['baseMap/getCenter']"
              :zoom="this.$store.getters['baseMap/getZoom']"
              :circles="createMapGeometry()"
              base_layers_control_position_top="20px"
    />
  </div>
</template>

<script>
import BaseMap from "@/components/BaseMap";
import Circle from "@/models/baseMap/Circle";

export default {
  name: "StandardBuildingMap",
  components: {BaseMap},
  data() {
    return {
      season_indication: true
    }
  },
  methods: {
    createMapGeometry() {
      return this.$store.state.standardBuilding.buildings.map(building => {
        return new Circle({
          coords: building.attributes.geometry.coordinates,
          radius: 10,
          color: this.getGeometryColor(building)
        })
      });
    },
    getGeometryColor(building) {
      if (this.season_indication) {
        return this.getCoefficientColor(building.attributes.season_coefficient);
      } else {
        return this.getCoefficientColor(building.attributes.not_season_coefficient);
      }
    },
    getCoefficientColor(coefficient) {
      if (coefficient >= 0 && coefficient <= 1.2) return '#04bd00'
      else if (coefficient > 1.2 && coefficient <= 2) return '#ffbf1c'
      else if (coefficient > 2 && coefficient <= 4) return '#ff6b1c'
      else if (coefficient > 4) return '#E02800'
    }
  }
}
</script>

<style scoped>
.map-indication-switcher {
  z-index: 1000;
  width: 110px;
  background-color: white;
  border-radius: 5px;
  padding: 5px;
  position: absolute;
  right: 10px;
  top: 10px;
}
</style>