<template>
  <div class="custom-card">
    <div class="card-body">
      <form @submit.prevent="checkType">
        <div class="form-group">
          <label class="form-label">Наименование</label>
          <input
            v-model="payload.name"
            class="form-control"
            placeholder="Введите наименование сети"
          >
        </div>

        <div class="form-group">
          <label class="form-label">Тип сети</label>
          <v-select
            v-model="payload.network_type"
            :options="networkTypes"
            :reduce="type => type.id"
            placeholder="Выберите тип"
          />
        </div>

        <div v-if="type === 'update'"
             class="form-group">
          <button
            v-if="this.active === 0"
            @click="activateEngineeringNetwork()"
            type="button"
            class="btn custom-btn-blue-outline-xs w-100">
            Сменить статус на "Активно"
          </button>
          <button
            v-else
            @click="deactivateEngineeringNetwork()"
            type="button"
            class="btn custom-btn-blue-outline-xs w-100">
            Сменить статус на "Не активно"
          </button>
        </div>

        <button
          type="submit"
          class="btn custom-btn-blue w-100"
        >Сохранить</button>
      </form>
    </div>
  </div>
</template>

<script>
import notification from "@/mixins/notification";

export default {
  name: "EngineeringNetworkForm",
  mixins: [notification],
  data() {
    return {
      payload: {
        name: null,
        network_type: null
      },
      active: null,
      networkTypes: [
        {id: 1, label: 'Водоснабжение'},
        {id: 2, label: 'Водоотведение'},
        {id: 3, label: 'Теплосети'},
        {id: 4, label: 'Ливневая канализация'}
      ],
      type: 'create'
    }
  },
  mounted() {
    if (this.$route.name === 'EngineeringNetworkUpdate') {
      const engineeringNetwork = this.$store.getters['engineeringNetworks/getEngineeringNetwork'](this.$route.params.id)
      this.payload.name = engineeringNetwork.name;
      this.payload.network_type = engineeringNetwork.typeId;
      this.active = engineeringNetwork.active;

      this.type = 'update'
    }
  },
  methods: {
    checkType() {
      if (this.type === 'create') this.createEngineeringNetwork()
      else this.updateEngineeringNetwork()
    },
    createEngineeringNetwork() {
      this.$store.dispatch('engineeringNetworks/createEngineeringNetworks', this.payload)
          .then(() => {
            this.toast('Инженерная сеть создана', 'success');
            this.$router.push('/engineering-networks');
          })
          .catch(error => this.alert('Ошибка', error, 'error'));
    },
    updateEngineeringNetwork() {
      this.$store.dispatch('engineeringNetworks/updateEngineeringNetwork', {
        networkId: this.$route.params.id,
        payload: this.payload
      })
          .then(() => {
            this.toast('Инженерная сеть обновлена', 'success');
            this.$router.push('/engineering-networks');
          })
          .catch(error => this.alert('Ошибка', error, 'error'));
    },
    activateEngineeringNetwork() {
      this.$store.dispatch('engineeringNetworks/activateEngineeringNetwork', this.$route.params.id)
          .then(() => {
            this.toast('Сеть переведена в статус "Активно"', 'success');
            this.$router.push('/engineering-networks');
          })
          .catch(error => this.alert('Ошибка', error, 'error'));
    },
    deactivateEngineeringNetwork() {
      this.$store.dispatch('engineeringNetworks/deactivateEngineeringNetwork', this.$route.params.id)
          .then(() => {
            this.toast('Сеть переведена в статус "Активно"', 'success');
            this.$router.push('/engineering-networks');
          })
          .catch(error => this.alert('Ошибка', error, 'error'));
    }
  }
}
</script>

<style scoped>

</style>