<template>
  <div class="row">
    <div class="col-xl-5 col-xs-12">
      <create-issue-form />
      <div class="position-relative map-height mt-3">
        <create-appeal-map />
      </div>
    </div>
    <div class="col-xl-7 col-xs-12">
      <div class="row mb-3">
        <div class="col-12 mb-3">
          <create-issue-appeals-list-filter />
        </div>

        <div class="col-12">
          <create-issue-appeals-list />
        </div>
      </div>

      <div
        v-if="issues.length"
        class="row"
      >
        <div class="col-12">
          <div class="custom-card">
            <div class="card-body">
              <div class="card-capture mb-3">Похожие заявки</div>
              <issues-list
                :headers="headers"
                :issues="issues"
              />
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import CreateIssueForm from "@/components/ServiceDesk/Issues/CreateIssueForm";
import CreateAppealMap from "@/components/CallCenter/CreateAppealMap";
import CreateIssueAppealsList from "@/components/CallCenter/CreateIssueAppealsList";
import CreateIssueAppealsListFilter from "@/components/CallCenter/CreateIssueAppealsListFilter";
import IssuesList from "@/components/ServiceDesk/Issues/IssuesList";
import {mapGetters} from "vuex";
export default {
  name: "CreateIssuePage",
  components: {IssuesList, CreateIssueAppealsListFilter, CreateIssueAppealsList, CreateAppealMap, CreateIssueForm},
  data() {
    return {
      headers: [
        {text: '#', value: 'id', type: 'text'},
        {text: 'Дата открытия', value: 'created_at', type: 'dateTime'},
        {text: 'Тип', value: 'type_name', type: 'text'},
        {text: 'Адрес', value: 'address', type: 'address'},
        {text: 'Комментарий', value: 'description', type: 'text'},
        {text: 'Статус', value: 'state', type: 'badge'}
      ]
    }
  },
  computed: {
    ...mapGetters({
      issues: 'issues/getIssues'
    })
  },
  mounted() {
    this.$store.commit('appeals/SET_FILTER_PARAMS', {
      appeal_status: '1'
    })
    this.$store.dispatch('appeals/getAppeals', 1);
    this.$store.dispatch('issueTypes/getIssueTypes');
    this.$store.dispatch('departments/getAllDepartments');
  },
  beforeDestroy() {
    this.$store.commit("appeals/SET_FILTER_PARAMS", null);
    this.$store.commit('appeals/SET_APPEALS', []);
    this.$store.commit('issues/SET_ISSUES', []);
  }
}
</script>

<style scoped>
.map-height {
  height: 300px;
}

.card-header {
  font-size: 22px;
  font-weight: 600;
  color: #262F3D;
}
</style>