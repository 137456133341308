<template>
  <div class="col-md-12">
    <form @submit.prevent="createNetworkObject()">
      <div class="form-group">
        <label class="form-label">Наименование</label>
        <input
          v-model="payload.name"
          type="text"
          class="form-control form-control-fix"
          placeholder="Наименование"
          maxlength="31"
          required
        >
      </div>
      <div class="form-group">
        <label class="form-label">Тип</label>
        <v-select
          v-model="payload.construction_type"
          :options="NetworkObjectTypes"
          :reduce="type => type.id"
          :get-option-label="(option) => option.label"
          placeholder="Выберите тип объекта"
        />
      </div>
      <div class="form-group">
        <label class="form-label">Адрес</label>
        <input
          v-model="payload.address"
          type="text"
          class="form-control form-control-fix"
          placeholder="Адрес"
          required
        >
      </div>
      <div class="form-group">
        <label class="form-label">Телефон</label>
        <input
          v-model="payload.phone"
          type="text"
          class="form-control form-control-fix"
          placeholder="Телефон"
          required
        >
      </div>
      <div class="form-group">
        <label class="form-label">Широта</label>
        <input
          v-model="payload.lat"
          type="text"
          class="form-control form-control-fix"
          placeholder="Широта"
          required
        >
      </div>
      <div class="form-group">
        <label class="form-label">Долгота</label>
        <input
          v-model="payload.lng"
          type="text"
          class="form-control form-control-fix"
          placeholder="Долгота"
          required
        >
      </div>
      <div class="text-center">
        <button
          type="submit"
          class="btn custom-btn-blue-outline w-100"
        >
          Сохранить
        </button>
      </div>
    </form>
  </div>
</template>

<script>
import {showAlert, showToast} from "@/utils/notification";
import NetworkObject from "@/models/telemetry/NetworkObject";

export default {
  name: "NetworkObjectCreateForm",
  data() {
    return {
      payload: {
        construction_type: null,
        name: null,
        address: null,
        phone: null,
        lat: null,
        lng: null
      }
    }
  },
  computed: {
    NetworkObjectTypes: () => NetworkObject.getTypesArray()
  },
  methods: {
    createNetworkObject(){
      this.$store.dispatch('networkObjects/createNetworkObject', this.payload)
          .then(() => {
            showToast('Объект сети добавлен', 'success');
            this.$router.push('/network-objects-catalog');
          })
          .catch(error => showAlert('Ошибка', error))
    }
  }
}
</script>

<style scoped>

</style>