<template>
  <b-tab title="Метод 1"
         title-item-class="custom-nav-item"
         title-link-class="custom-nav-link">

    <div v-if="$store.state.standardBuilding.loading"
         class="loader-block">
      <base-loader width="1rem !important"
                   height="1rem !important" />
    </div>

    <h4 class="tab-title">
      Метод расчёта по договорам
    </h4>

    <div class="row">

      <div class="col-12 mb-3">
        <h5>Норматив водопотребления, м<sup>3</sup>/месяц</h5>
        <input v-model="filter.normative_value"
               class="form-control form-control-fix">
      </div>

      <div class="col-12 mb-3">
        <h5>Период для анализа</h5>
        <date-picker
          id="period"
          v-model="filter.normative_year"
          :format="'YYYY год'"
          type="year"
          placeholder="Выберите период"
          input-class="custom-datepicker form-control-fix"
          value-type="YYYY"
          :default-value="defaultValue"
          :disabled-date="notAfterToday"
        />
      </div>

      <div class="col-12 mb-3">
        <h5>Диапазон отклонения сезонного коэффициента, %</h5>
        <div class="row">
          <div class="col-xl-6 col-lg-12 range-mb">
            <input v-model.number="filter.season_coefficient_filter[0]"
                   placeholder="Минимальное значение"
                   class="form-control form-control-fix form-element">
          </div>
          <div class="col-xl-6 col-lg-12">
            <input v-model.number="filter.season_coefficient_filter[1]"
                   placeholder="Максимальное значение"
                   class="form-control form-control-fix form-element">
          </div>
        </div>
      </div>


      <div class="col-12 mb-3">
        <h5>Диапазон отклонения не сезонного коэффициента, %</h5>
        <div class="row">
          <div class="col-xl-6 col-lg-12 range-mb">
            <input v-model.number="filter.not_season_coefficient_filter[0]"
                   placeholder="Минимальное значение"
                   class="form-control form-control-fix form-element">
          </div>
          <div class="col-xl-6 col-lg-12">
            <input v-model.number="filter.not_season_coefficient_filter[1]"
                   placeholder="Максимальное значение"
                   class="form-control form-control-fix form-element">
          </div>
        </div>
      </div>

      <div class="col-12">
        <hr>
      </div>

      <div class="col-12 mb-4">
        <h5>Сортировка результатов</h5>
        <div class="row">
          <div class="col-xxl-6 col-lg-12">
            <select v-model="filter.sort_by"
                    class="form-control form-control-fix">
              <option :value="null">Параметр сортировки</option>
              <option value="season_coefficient">Сезонный коэффициент</option>
              <option value="not_season_coefficient">Не сезонный коэффициент</option>
            </select>
          </div>
          <div class="col-xxl-6 col-lg-12">
            <select v-model="filter.sort_dir"
                    class="form-control form-control-fix">
              <option :value="null">Направление сортировки</option>
              <option value="asc">По возрастанию</option>
              <option value="desc">По убыванию</option>
            </select>
          </div>
        </div>
      </div>

      <div class="col-md-6 col-xs-12">
        <button class="btn custom-btn-blue-outline w-100"
                @click="submitFilter()">
          Применить фильтр
        </button>
      </div>
      <div class="col-md-6 col-xs-12">
        <button class="btn custom-btn-blue-outline w-100 d-flex align-items-center justify-content-center"
                @click="clearFilter()">
          <refresh-ccw-icon class="mr-2"/>
          Сбросить
        </button>
      </div>

    </div>
  </b-tab>
</template>

<script>
import DatePicker from "vue2-datepicker";
import BaseLoader from "@/layout/BaseLoader";
import cleanFilter from "@/mixins/cleanFilter";
import notification from "@/mixins/notification";

export default {
  name: "StandardBuildingFilter",
  components: {BaseLoader, DatePicker},
  mixins: [cleanFilter, notification],
  data() {
    return {
      filter: {
        normative_year: null,
        normative_value: 6.59,
        season_coefficient_filter: [],
        not_season_coefficient_filter: [],
        sort_by: null,
        sort_dir: null
      },
      defaultValue: new Date()
    }
  },
  methods: {
    submitFilter() {
      if (this.validateFilter(this.filter) === false) return false

      let filter = this.clean(this.filter);

      this.$store.commit('standardBuilding/SET_METHOD', 1);
      this.$store.commit('standardBuilding/SET_FILTER_PARAMS', filter);

      let payload = {
        filter: filter,
        page: 1
      }
      this.$store.dispatch('standardBuilding/getBuildingsV1', payload).then(() => {
        this.$store.commit('standardBuilding/SET_TAB_INDEX', 2);
      });
    },
    clearFilter() {
      this.filter.normative_value = 6.59;
      this.filter.normative_year = null;
      this.filter.season_coefficient_filter = [];
      this.filter.not_season_coefficient_filter = [];
      this.filter.sort_dir = null;
      this.filter.sort_by = null;
    },
    validateFilter(filter) {
      if (filter.normative_year === null) {
        this.alert('Ошибка', 'Заполните период для анализа', 'warning');
        return false
      }
      if (filter.normative_value === '') {
        this.alert('Ошибка', 'Заполните норматив водопотребления или нажмите "Сбросить"', 'warning');
        return false
      }
      // eslint-disable-next-line no-bitwise
      if (typeof filter.season_coefficient_filter[0] == 'number' ^
          typeof filter.season_coefficient_filter[1] == 'number') {
        this.alert(
            'Ошибка',
            'Заполните мин. и макс. значения диапазона отклонения сезонного коэффициента',
            'warning'
        );
        return false
      }
      // eslint-disable-next-line no-bitwise
      if (typeof filter.not_season_coefficient_filter[0] == 'number' ^
          typeof filter.not_season_coefficient_filter[1] == 'number') {
        this.alert(
            'Ошибка',
            'Заполните мин. и макс. значения диапазона отклонения не сезонного коэффициента',
            'warning'
        );
        return false
      }
    },
    notAfterToday(date) {
      let lastDate = new Date();
      lastDate.setDate(1);
      lastDate.setMonth(lastDate.getMonth() - 1);
      return date > lastDate;
    }
  }
}
</script>

<style scoped>
.tab-title {
  font-size: 22px;
  font-weight: 700;
  color: #262F3D;
  margin-bottom: 20px;
}

.loader-block {
  position: absolute;
  right: 20px;
  top: 50px;
}

@media (max-width: 1199px) {
  .range-mb {
    margin-bottom: .5rem;
  }
}
</style>