<template>
  <div class="custom-card">
    <div class="card-body">
      <div class="container-fluid">
        <div class="row">
          <div class="col-12">
            <form @submit.prevent="checkType">
              <div class="form-group">
                <label class="form-label">Наименование</label>
                <input
                  v-model="payload.name"
                  type="text"
                  class="form-control form-control-fix"
                  placeholder="Введите наименование работы"
                  required
                >
              </div>
              <button class="btn custom-btn-blue w-100">Сохранить</button>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import notification from "@/mixins/notification";

export default {
  name: "WorksForm",
  mixins: [notification],
  data() {
    return {
      work_id: null,
      payload: {
        name: null
      },
      type: 'create'
    }
  },
  computed: {
    ...mapGetters({
      work: 'works/getWork'
    })
  },
  mounted() {
    if (this.$route.name === "UpdateWork") {
      const work = this.work(this.$route.params.id);
      this.work_id = work.id;
      this.payload.name = work.name;
      this.type = 'update';
    }
  },
  methods: {
    checkType() {
      if (this.type === 'create') {
        this.createWork();
      } else if (this.type === 'update') {
        this.updateWork();
      }
    },
    createWork() {
      this.$store.dispatch('works/createWork', this.payload)
          .then(() => {
            this.toast('Работа создана', 'success');
            this.$router.push('/works');
          })
          .catch(error => this.alert('Ошибка', error, 'error'))
    },
    updateWork() {
      this.$store.dispatch('works/updateWork', {
        work_id: this.work_id,
        payload: this.payload
      })
          .then(() => {
            this.toast('Работа обновлена', 'success');
            this.$router.push('/works');
          })
          .catch(error => this.alert('Ошибка', error, 'error'));
    }
  }
}
</script>

<style scoped>

</style>