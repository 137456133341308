<template>
  <div class="row">
    <div class="col-lg-12">
      <div class="custom-card">
        <div class="card-header pb-0">
          <h4 class="table-title d-inline-block">
            Конфигуратор журнала
          </h4>
          <router-link
            to="/admin-journal-configs-create"
            class="btn custom-btn-blue-outline float-right"
          >
            Добавить конфигурацию
          </router-link>
          <hr class="divider">
        </div>
        <div class="card-body">
          <div class="container-fluid">
            <div class="row">
              <journal-config-table />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import JournalConfigTable from "@/components/Telemetry/admin/JournalConfigsList";
import store from "@/store";

export default {
  name: "JournalConfigsPage",
  components: {
    JournalConfigTable
  },
  beforeRouteEnter(to, from, next) {
    const currentPage = parseInt(to.query.page) || 1;
    store.dispatch("journalConfigs/getAllJournalConfigs", currentPage).then(() => {
      to.params.page = currentPage;
      next();
    });
  },
  watch: {
    $route: 'fetchData'
  },
  mounted() {
    this.fetchData();
  },
  methods:{
    fetchData() {
      this.$store.dispatch("device/getAllDevices");
      this.$store.dispatch("sensor/getAllSensors");
    }
  }
}
</script>

<style scoped>

</style>