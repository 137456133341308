<template>
  <div class="row access-denied">
    <x-circle-icon />
    <div class="access-text">Доступ запрещён</div>
    <div>
      <router-link to="/">Перейти на главную</router-link>
    </div>
  </div>
</template>

<script>
export default {
  name: "AccessDeniedPage"
}
</script>

<style scoped>
.access-denied {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: calc(100vh - 64px);
}

.access-denied svg {
  color: #468FC5;
  width: 50px;
  height: 50px;
}

.access-text {
  font-size: 30px
}

.access-denied div:last-child {
  font-size: 20px
}
</style>