<template>
  <div class="row">
    <div class="col-12 mb-3">
      <div class="custom-card">
        <div class="card-body">
          <div class="row">
            <div class="col-12">
              <h4 class="table-title d-inline-block mb-3">
                Диспетчерская - отчеты
              </h4>
            </div>
            <div class="col-12">
              <reports-form />
            </div>
          </div>
        </div>
      </div>
    </div>

    <div v-if="!loading"
         class="col-12">
      <daily-report v-if="currentReportKey === 'DailyReport'" />
      <accident-report v-if="currentReportKey === 'AccidentReport'"/>
      <issues-count-report v-if="currentReportKey === 'IssuesCountReport'" />
      <appeals-statistic-report v-if="currentReportKey === 'AppealsCountByCategories'" />
      <water-flow-report v-if="currentReportKey === 'WaterFlowReport'" />
      <fuel-and-lunricants-report v-if="currentReportKey === 'FuelAndLubricantsReport'" />
    </div>

    <div v-if="loading"
         class="col-12 text-center">
      <base-loader />
    </div>
  </div>
</template>

<script>
import ReportsForm from "@/components/ServiceDesk/ReportsForm";
import DailyReport from "@/components/ServiceDesk/ReportsPreviews/DailyReport";
import BaseLoader from "@/layout/BaseLoader";
import AccidentReport from "@/components/ServiceDesk/ReportsPreviews/AccidentReport";
import IssuesCountReport from "@/components/ServiceDesk/ReportsPreviews/IssuesCountReport";
import AppealsStatisticReport from "@/components/ServiceDesk/ReportsPreviews/AppealsStatisticReport";
import {mapState} from "vuex";
import WaterFlowReport from "@/components/ServiceDesk/ReportsPreviews/WaterFlowReport";
import FuelAndLunricantsReport from "@/components/ServiceDesk/ReportsPreviews/FuelAndLubricantsReport";
export default {
  name: "ReportsPage",
  components: {
    FuelAndLunricantsReport,
    WaterFlowReport,
    AppealsStatisticReport, IssuesCountReport, AccidentReport, BaseLoader, DailyReport, ReportsForm
},
  computed: {
    ...mapState({
      currentReportKey: state => state.reportsData.currentReportKey,
      reportData: state => state.reportsData.reportData,
      loading: state => state.reportsData.loading
    })
  },
  beforeDestroy() {
    this.$store.commit("reportsData/SET_ISSUES", []);
  }
}
</script>

<style scoped>

</style>