<template>
  <div>
    <div v-for="sensor in sensorsList"
         :key="sensor.id"
         class="sensor">
      <div class="sensor__header">
        <div class="sensor__name">{{sensor.sensor_name}}</div>
        <div class="sensor__actions">
          <div
            class="network_object__control_btn"
            @click="editSensor(sensor)"
          >
            <img
              src="@/assets/img/transport/settings.svg"
              alt="Изменить">
          </div>
          <div
            class="network_object__control_btn"
            @click="deleteSensor(sensor.id)"
          >
            <img
              src="@/assets/img/telemetry/trash.svg"
              alt="Удалить">
          </div>
        </div>
      </div>

      <div class="sensor__text">
        <span>Краткое наименование:</span>
        <span>{{sensor.sensor_short_name}}</span>
      </div>
      <div class="sensor__text">
        <span>Единицы измерения: </span>
        <span>{{sensor.sensor_measure}}</span>
      </div>
      <div class="sensor__text">
        <span>Мин. значение</span>
        <span>{{sensor.min_value}}</span>
      </div>
      <div class="sensor__text">
        <span>Макс. значение</span>
        <span>{{sensor.max_value}}</span>
      </div>

      <div class="form-group mt-2">
        <label class="form-label">Связанный параметр</label>
        <v-select
          :value="sensor.parameter ? sensor.parameter.id : null"
          :options="parameters({id: $route.params.id})"
          :reduce="parameter => parameter.id"
          :get-option-label="option => option.equipmentName + ': ' + option.name"
          placeholder="Выберите параметр"
          @input="saveDeviceParameter($event, sensor.id)"
          :disabled="attachedParameterInputDisabled"
        />
      </div>

    </div>
    <div @click="createSensor()"
         class="sensor__add_btn">Добавить датчик</div>
  </div>
</template>

<script>
import {showAlert, showToast} from "@/utils/notification";
import {mapGetters} from "vuex";

export default {
  name: "DeviceSensorsList",
  props: {
    sensorsList: {
      type: Array,
      required: true
    },
    deviceId: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      attachedParameterInputDisabled: false
    }
  },
  computed: {
    ...mapGetters({
      parameters: 'networkEquipments/getNetworkEquipmentsParametersByNetworkObjectId'
    })
  },
  methods: {
    createSensor() {
      this.$store.commit('networkObjectPage/SET_DEVICE_ID', this.deviceId);
      this.$store.commit('networkObjectPage/SET_CURRENT_DEVICE_SENSOR', null);
      this.$bvModal.show('modal-sensor');
    },
    editSensor(sensor) {
      this.$store.commit('networkObjectPage/SET_DEVICE_ID', this.deviceId);
      this.$store.commit('networkObjectPage/SET_CURRENT_DEVICE_SENSOR', sensor);
      this.$bvModal.show('modal-sensor');
    },
    deleteSensor(sensorId) {
      this.$swal({
        title: 'Вы действительно хотите удалить датчик?',
        showCancelButton: true,
        confirmButtonText: `Да`,
        cancelButtonText: 'Отмена',
        icon: 'warning'
      }).then(result => {
        if (result.isConfirmed) {
          this.$store.dispatch('sensor/deleteSensor', sensorId)
              .then(() => {
                showToast('Датчик удалён', 'success');
                this.$store.dispatch('networkObjectPage/getNetworkObjectDevices', {
                  construction_id: this.$route.params.id
                });
              })
              .catch(error => showAlert('Ошибка', error))
        }
      });
    },
    saveDeviceParameter(event, sensorId) {
      this.attachedParameterInputDisabled = true;
      if (event) {
        this.$store.dispatch('sensor/attachToParameter', {
          sensorId: sensorId,
          payload: {
            parameter_id: event
          }
        })
            .then(() => {
              showToast('Параметр привязан', 'success');
              this.$store.dispatch('networkObjectPage/getNetworkObjectDevices', {
                construction_id: this.$route.params.id
              });
            })
            .catch(error => showAlert('Ошибка', error))
            .finally(() => this.attachedParameterInputDisabled = false)
      } else  {
        this.$store.dispatch('sensor/detachParameter', sensorId)
            .then(() => {
              showToast('Параметр отвязан', 'success');
              this.$store.dispatch('networkObjectPage/getNetworkObjectDevices', {
                construction_id: this.$route.params.id
              });
            })
            .catch(error => showAlert('Ошибка', error))
            .finally(() => this.attachedParameterInputDisabled = false)
      }
    }
  }
}
</script>

<style scoped>
.sensor {
  padding: 10px;
}

.sensor__header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.sensor__name {
  display: inline-block;
  font-size: 18px;
  font-weight: 700;
  color: #262f3d;
  line-height: 1;
}

.sensor__actions {
  display: flex;
}

.sensor__text span {
  font-size: 14px;
  font-weight: 600;
}

.sensor__text span:first-child {
  color: #468fc5;
  margin-right: 3px;
}

.sensor__text span:last-child {
  color: #262f3d;
}

.sensor__add_btn {
  width: 100%;
  text-align: center;
  cursor: pointer;
  color: #468fc5;
  font-weight: 600;
}
</style>