import * as API from './API'

export default {
    getBalanceGroup(zoneId) {
        return API.apiClient.get(`/balanceGroups/${zoneId}`);
    },
    getBalanceGroups(page) {
        return API.apiClient.get(`/balanceGroups/?page=${page}`);
    },
    getAllBalanceGroups() {
        return API.apiClient.get('/balanceGroups/all')
    },
    paginateBalanceGroup(page) {
        return API.apiClient.get(`/balanceGroups/?page=${page}`);
    },
    createBalanceGroup(payload) {
        return API.apiClient.post(`/balanceGroups`, payload)
    },
    updateBalanceGroup(id, payload){
        return API.apiClient.patch(`/balanceGroups/${id}`, payload);
    },
    deleteBalanceGroup(id){
        return API.apiClient.delete(`/balanceGroups/${id}`);
    }
}