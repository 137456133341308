<template>
  <div>
    <ul class="timeline">
      <history-item v-for="item in history"
                    :key="item.id"
                    :item="item"/>
    </ul>
  </div>
</template>

<script>
import HistoryItem from "@/components/ServiceDesk/HistoryItem";

export default {
  name: "HistoryList",
  components: {HistoryItem},
  props: {
    history: {
      required: true
    }
  }
}
</script>

<style scoped>
</style>