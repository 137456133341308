<template>
  <svg width="24"
       height="24"
       viewBox="0 0 24 24"
       fill="none"
       xmlns="http://www.w3.org/2000/svg">
    <path d="M12 15C12.7956 15 13.5587 14.6839 14.1213 14.1213C14.6839 13.5587
    15 12.7956 15 12C15 11.2044 14.6839 10.4413 14.1213 9.87868C13.5587 9.31607
    12.7956 9 12 9C11.2044 9 10.4413 9.31607 9.87868 9.87868C9.31607 10.4413
    9 11.2044 9 12C9 12.7956 9.31607 13.5587 9.87868 14.1213C10.4413 14.6839 11.2044 15 12 15Z"
          stroke=""
          stroke-width="1.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"/>
    <path d="M3 13.2824V11.6992C3 10.7636 3.76462 9.99002 4.70915 9.99002C6.33733 9.99002
    7.003 8.83859 6.18441 7.4263C5.71664 6.6167 5.9955 5.56423 6.81409 5.09646L8.37031
    4.20591C9.08096 3.78312 9.9985 4.03499 10.4213 4.74564L10.5202 4.91655C11.3298
    6.32885 12.6612 6.32885 13.4798 4.91655L13.5787 4.74564C14.0015 4.03499 14.919
    3.78312 15.6297 4.20591L17.1859 5.09646C18.0045 5.56423 18.2834 6.6167 17.8156
    7.4263C16.997 8.83859 17.6627 9.99002 19.2909 9.99002C20.2264 9.99002 21 10.7546
    21 11.6992V13.2824C21 14.2179 20.2354 14.9915 19.2909 14.9915C17.6627 14.9915
    16.997 16.1429 17.8156 17.5552C18.2834 18.3738 18.0045 19.4173 17.1859
    19.8851L15.6297 20.7756C14.919 21.1984 14.0015 20.9465 13.5787 20.2359L13.4798
    20.065C12.6702 18.6527 11.3388 18.6527 10.5202 20.065L10.4213 20.2359C9.9985
    20.9465 9.08096 21.1984 8.37031 20.7756L6.81409 19.8851C6.42198 19.6593 6.13546
    19.2872 6.0174 18.8504C5.89935 18.4136 5.95941 17.9478 6.18441 17.5552C7.003
    16.1429 6.33733 14.9915 4.70915 14.9915C3.76462 14.9915 3 14.2179 3 13.2824Z"
          stroke=""
          stroke-width="1.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"/>
  </svg>
</template>
<script>
export default {
  name: "IconSettins"
}
</script>
