<template>
  <b-modal
    id="equipment-form"
    ref="equipment-form"
    centered
    hide-header
    hide-footer
    @shown="fillFields()"
    @hidden="clearFields()"
  >
    <div class="col-12">
      <div class="custom-modal-header">
        <div class="custom-modal-title">
          Сетевое оборудование
        </div>
        <img
          alt="Закрыть"
          class="custom-modal-close"
          src="@/assets/img/icons/close.svg"
          @click="$bvModal.hide('equipment-form')"
        >
      </div>
      <div class="modal-divider"/>
    </div>

    <div class="col-12">
      <form @submit.prevent="checkType">

        <div class="form-group">
          <label class="form-label">Тип</label>
          <v-select
            v-model="payload.type_id"
            :options="networkEquipmentTypes"
            :reduce="type => type.id"
            :get-option-label="(option) => option.name"
            placeholder="Выберите тип сетевого оборудования"
          />
        </div>

        <div class="form-group">
          <label class="form-label">Сетевой объект</label>
          <v-select
            v-model="payload.network_object_id"
            :options="networkObjects"
            :reduce="object => object.id"
            :get-option-label="(option) => option.name"
            placeholder="Выберите объект сети"
          />
        </div>

        <div class="form-group">
          <label class="form-label">Наименование</label>
          <input
            v-model="payload.name"
            class="form-control"
            type="text"
            placeholder="Введите наименование"
          >
        </div>

        <div class="form-group">
          <label class="form-label">Серийный номер</label>
          <input
            v-model="payload.serial"
            class="form-control"
            type="text"
            placeholder="Введите серийный номер"
          >
        </div>

        <div class="form-group">
          <label class="form-label">Срок службы по паспорту (в днях)</label>
          <input
            v-model="payload.durability"
            class="form-control"
            type="text"
            placeholder="Введите срок службы"
          >
        </div>

        <div class="form-group">
          <label class="form-label">Дата изготовления</label>
          <date-picker
            input-class="form-control-fix w-100 pl-2"
            placeholder="Выберите дату изготовления"
            v-model="payload.build_date"
            :default-value="defaultValue"
            format="DD.MM.YYYY"
            type="day"
            value-type="YYYY-MM-DD" />
        </div>

        <div class="form-group">
          <label class="form-label">Дата установки</label>
          <date-picker
            input-class="form-control-fix w-100 pl-2"
            placeholder="Выберите дату установки"
            v-model="payload.installation_date"
            :default-value="defaultValue"
            format="DD.MM.YYYY"
            type="day"
            value-type="YYYY-MM-DD" />
        </div>

        <div class="text-center">
          <hr>
          <button
            type="submit"
            class="btn custom-btn-blue w-100"
          >
            Сохранить
          </button>
        </div>
      </form>
    </div>

  </b-modal>
</template>

<script>
import DatePicker from "vue2-datepicker";
import {mapState} from "vuex";
import {showAlert, showToast} from "@/utils/notification";

export default {
  name: "EquipmentsForm",
  components: {DatePicker},
  props: {
    equipment: {
      required: true
    }
  },
  data() {
    return {
      payload: {
        type_id: null,
        network_object_id: null,
        name: null,
        serial: null,
        durability: null,
        build_date: null,
        installation_date: null
      },
      formType: 'create',
      defaultValue: new Date()
    }
  },
  computed: {
    ...mapState({
      networkObjects: state => state.networkObjects.networkObjects,
      networkEquipmentTypes: state => state.networkEquipmentTypes.networkEquipmentTypes
    })
  },
  mounted() {
    this.$store.dispatch('networkEquipmentTypes/getNetworkEquipmentTypes');
    this.$store.dispatch('networkObjects/getAllNetworkObjects');
  },
  methods: {
    checkType() {
      if (this.formType === 'create') this.createNetworkEquipment()
      else this.updateNetworkEquipment();
    },
    createNetworkEquipment() {
      this.$store.dispatch('networkEquipments/createNetworkEquipment', this.payload)
          .then(() => {
            showToast('Сетевое оборудование добавлено', 'success');
            this.$bvModal.hide('equipment-form');
            this.$store.dispatch('networkEquipments/getNetworkEquipments', {
              object_id: this.$route.params.id
            });
          })
          .catch(error => showAlert('Ошибка', error));
    },
    updateNetworkEquipment() {
      this.$store.dispatch('networkEquipments/updateNetworkEquipment', {
        equipmentId: this.equipment.id,
        payload: this.payload
      })
          .then(() => {
            showToast('Сетевое оборудование обновлено', 'success');
            this.$bvModal.hide('equipment-form');
            this.$store.dispatch('networkEquipments/getNetworkEquipments', {
              object_id: this.$route.params.id
            });
          })
          .catch(error => showAlert('Ошибка', error));
    },
    fillFields() {
      if (this.equipment) {
        this.formType = 'update';

        this.payload.type_id = this.equipment.typeId;
        this.payload.network_object_id = this.equipment.networkObjectId;
        this.payload.name = this.equipment.name;
        this.payload.serial = this.equipment.serial;
        this.payload.durability = this.equipment.durability;
        this.payload.build_date = this.equipment.buildDate;
        this.payload.installation_date = this.equipment.installationDate;
      }
    },
    clearFields() {
      this.formType = 'create';
      this.payload = {
        type_id: null,
        network_object_id: null,
        name: null,
        serial: null,
        durability: null,
        build_date: null,
        installation_date: null
      }

    }
  }
}
</script>

<style scoped>

</style>