<template>
  <base-map
    :center="center"
    :zoom="zoom"
    :layers="layers"
    :need-resize="needResize"
    :set_marker_by_click="true"
    :circles="setCircles"
    :polylines="setPolylines"
    :polygons="setPolygons"
  />
</template>

<script>
import BaseMap from "@/components/BaseMap";
import {mapGetters, mapState} from "vuex";
import Circle from "@/models/baseMap/Circle";
import Polyline from "@/models/baseMap/Polyline";
import Polygon from "@/models/baseMap/Polygon";
export default {
  name: "AddEmergencyElementMap",
  components: {BaseMap},
  props: {
    needResize: {
      required: true
    }
  },
  computed: {
    ...mapGetters({
      elements: 'networkElements/getNetworkElements'
    }),
    ...mapState({
      center: state => state.baseMap.center,
      zoom: state => state.baseMap.zoom,
      layers: state => state.baseMap.layers
    }),
    setCircles: function () {
      return this.elements
          .filter(item => item.geometryType === 'Point')
          .map(item => {
            const color = item.isSelected ? '#000' : '#468FC5'
            return new Circle({
              coords: item.coordinates,
              radius: 3,
              color: color
            })
          })
    },
    setPolylines: function () {
      return this.elements
          .filter(item => item.geometryType === 'LineString' || item.geometryType === 'MultiLineString')
          .map(item => {
            const color = item.isSelected ? '#000' : '#468FC5'
            return new Polyline({
              coords: item.coordinates,
              color: color
            })
          })
    },
    setPolygons: function () {
      return this.elements
          .filter(item => item.geometryType === 'Polygon' || item.geometryType === 'MultiPolygon')
          .map(item => {
            const color = item.isSelected ? '#000' : '#468FC5'
            return new Polygon({
              coords: item.coordinates,
              color: color,
              fill: color
            })
          })
    }
  }
}
</script>

<style scoped>

</style>