import * as API from './API'
import {getError} from "@/utils/helpers";

export default {
    fetchAppealsCountByDay(filter) {
        return new Promise((resolve, reject) => {
            API.apiClient.get('call-center/statistic/opened-count-by-days', {params: filter})
                .then(response => resolve(response.data.data))
                .catch(error => reject(getError(error)))
        })
    },
    fetchAppealsCountByCategory(filter) {
        return new Promise((resolve, reject) => {
            API.apiClient.get('call-center/statistic/categories', {params: filter})
                .then(response => resolve(response.data.data))
                .catch(error => reject(getError(error)))
        })
    }
}