<template>
  <div class="custom-card mb-2">
    <div class="card-body">
      <div class="row">
        <div class="col-12">
          <div class="shutdown-title">
            <div class="shutdown-number">Отключение <span>#{{ shutdown.id }}</span></div>
            <div class="d-flex align-items-center">
              <base-badge :label="shutdown.getStateLabel()"
                          :color="shutdown.getStateColor()"/>
              <div v-if="!shutdown.actual_start_date"
                   @click="$bvModal.show(`delete-shutdown_${shutdown.id}`)"
                   class="delete-shutdown">
                <x-circle-icon />
              </div>
            </div>
          </div>
        </div>
        <div class="col-12">
          <div class="shutdown-comment">{{ shutdown.comment }}</div>
        </div>
        <div class="col-6">
          <div class="shutdown-block">
            <div>Плановая дата начала</div>
            <div>{{ shutdown.planned_start_date | formatDateTime }}</div>
          </div>
        </div>
        <div class="col-6">
          <div v-if="shutdown.actual_start_date"
               class="shutdown-block">
            <div>Факт. дата начала</div>
            <div>{{ shutdown.actual_start_date | formatDateTime }}</div>
          </div>
        </div>
        <div class="col-6">
          <div class="shutdown-block">
            <div>Плановая дата окончания</div>
            <div>{{ shutdown.planned_end_date | formatDateTime }}</div>
          </div>
        </div>
        <div class="col-6">
          <div v-if="shutdown.actual_end_date"
               class="shutdown-block">
            <div>Факт. дата окончания</div>
            <div>{{ shutdown.actual_end_date | formatDateTime }}</div>
          </div>
        </div>
        <div class="col-12 mt-3">
          <button v-if="!shutdown.actual_start_date"
                  @click="startShutdown()"
                  class="btn custom-btn-blue-outline-xs">Начать</button>
          <button v-if="shutdown.actual_start_date && !shutdown.actual_end_date"
                  @click="finishShutdown()"
                  class="btn custom-btn-blue-outline-xs">Закончить</button>
        </div>
      </div>
    </div>

    <b-modal :id="`delete-shutdown_${shutdown.id}`"
             hide-footer
             hide-header
             centered>
      <div class="confirmation-modal-wrapper">
        <div @click="$bvModal.hide(`delete-shutdown_${shutdown.id}`)"
             class="confirmation-modal-close">
          <img src="@/assets/img/icons/close.svg"
               alt="x">
        </div>
        <div class="confirmation-modal-title">
          Вы действительно хотите удалить данное отключение?
        </div>
        <button @click="deleteShutdown()"
                class="confirmation-modal-button confirm">Удалить отключение</button>
        <button @click="$bvModal.hide(`delete-shutdown_${shutdown.id}`)"
                class="confirmation-modal-button reject">Назад</button>
      </div>
    </b-modal>

  </div>
</template>

<script>
import BaseBadge from "@/layout/BaseBadge";
import {mapGetters} from "vuex";
import notification from "@/mixins/notification";
export default {
  name: "ShutdownListItem",
  components: {BaseBadge},
  mixins: [notification],
  props: {
    shutdown: {
      type: Object,
      required: true
    }
  },
  computed: {
    ...mapGetters({
      issueId: 'issue/getIssueId'
    })
  },
  methods: {
    startShutdown() {
      this.$store.dispatch('shutdowns/startShutdown',
          {issue_id: this.issueId, shutdown_id: this.shutdown.id})
          .then(() => {
            this.toast('Отключение начато', 'success');
            this.$store.dispatch('shutdowns/getIssueShutdowns', this.issueId);
            this.$store.dispatch('history/getIssueHistory', this.issueId);
          })
          .catch(error => {
            this.alert('Ошибка', error, 'error');
          })
    },
    finishShutdown() {
      this.$store.dispatch('shutdowns/finishShutdown',
          {issue_id: this.issueId, shutdown_id: this.shutdown.id})
          .then(() => {
            this.toast('Отключение закончено', 'success');
            this.$store.dispatch('shutdowns/getIssueShutdowns', this.issueId);
            this.$store.dispatch('history/getIssueHistory', this.issueId);
          })
          .catch(error => {
            this.alert('Ошибка', error, 'error');
          })
    },
    deleteShutdown() {
      this.$store.dispatch('shutdowns/deleteShutdown',
          {issue_id: this.issueId, shutdown_id: this.shutdown.id})
          .then(() => {
            this.$bvModal.hide(`delete-shutdown_${this.shutdown.id}`);
            this.toast('Отключение удалено', 'success');
            this.$store.dispatch('shutdowns/getIssueShutdowns', this.issueId);
            this.$store.dispatch('history/getIssueHistory', this.issueId);
          })
          .catch(error => {
            this.alert('Ошибка', error, 'error');
          })
    }
  }
}
</script>

<style scoped>
.shutdown-block {
  margin-top: 20px;
}

.shutdown-block div:first-child {
  color: #262F3D;
  font-size: 14px;
  line-height: 15px;
  font-weight: 600;
  margin-bottom: 5px;
}

.shutdown-block div:last-child {
  color: #468FC5;
  font-size: 16px;
  line-height: 17px;
}

.shutdown-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.shutdown-number {
  color: #262F3D;
  font-size: 16px;
  font-weight: 600;
}

.shutdown-number span {
  color: #468FC5;
  font-size: 16px;
  font-weight: 600;
}

.shutdown-comment {
  color: #468FC5;
  font-weight: 500;
}

.delete-shutdown {
  cursor: pointer;
  margin-left: 15px;
  line-height: 1;
}
</style>