export default class TransportOrder {
    constructor(params) {
        this.id = params.id;
        this.state = params.state;
        this.transportType = params.transport_type;
        this.transport = params.transport;
        this.brigade = params.brigade;
        this.deliveryAt = params.delivery_at;
        this.comment = params.comment;
        this.address = params.address;
        this.task = params.task;
    }

    static getStates() {
        return [
            {id: 1, name: 'Новый'},
            {id: 2, name: 'Подтверждён'},
            {id: 3, name: 'Отменён'}
        ]
    }

    getStateColor() {
        switch (this.state.id) {
            case 1:
                return '#468FC5'
            case 2:
                return '#04BD00'
            case 3:
                return '#4f4f4f'
        }
    }

    getFullAddress() {
        return [this.address.city, this.address.settlement, this.address.street,
            this.address.house, this.address.block, this.address.flat]
            .filter(item => item !== null)
            .join(', ')
    }
}