<template>
  <svg
    :width="width"
    :height="height"
    viewBox="0 0 25 23"
    :fill="iconColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4.49804 10.7416C2.01782 10.7416 0 12.7594 0 15.2397C0 16.4936 0.685611
    18.008 2.03784 19.741C3.00364 20.9788 3.95812 21.8738 3.9983 21.9113L4.49809
    22.378L4.99788 21.9113C5.03806 21.8738 5.99254 20.9788 6.95834 19.741C8.31052
    18.008 8.99618 16.4936 8.99618 15.2397C8.99613 12.7594 6.97831 10.7416 4.49804
    10.7416ZM4.49804 16.6949C3.69565 16.6949 3.04285 16.0421 3.04285 15.2397C3.04285
    14.4373 3.69565 13.7845 4.49804 13.7845C5.30043 13.7845 5.95324 14.4373 5.95324
    15.2397C5.95324 16.0421 5.30043 16.6949 4.49804 16.6949Z" />
    <path
      d="M20.5019 0C18.0217 0 16.0039 2.01782 16.0039 4.49804C16.0039 5.75194
    16.6895 7.26641 18.0417 8.9994C19.0074 10.2371 19.962 11.1322 20.0021 11.1697L20.5019
    11.6363L21.0016 11.1697C21.0418 11.1322 21.9963 10.2371 22.9621 8.9994C24.3143 7.26641
    24.9999 5.75194 24.9999 4.49804C25 2.01787 22.9822 0 20.5019 0ZM20.5019 5.95328C19.6996
    5.95328 19.0468 5.30048 19.0468 4.49809C19.0468 3.6957 19.6996 3.04289 20.5019
    3.04289C21.3044 3.04289 21.9572 3.6957 21.9572 4.49809C21.9572 5.30048 21.3044 5.95328 20.5019 5.95328Z" />
    <path
      d="M17.8167 22.1084H7.42761V20.6437H17.8167C18.8936 20.6437 19.7697 19.7676
    19.7697 18.6907C19.7697 17.6137 18.8936 16.7376 17.8167 16.7376H13.7207C11.8362
    16.7376 10.3029 15.2045 10.3029 13.3199C10.3029 11.4354 11.8362 9.90222 13.7207
    9.90222H17.5725V11.3669H13.7207C12.6437 11.3669 11.7676 12.243 11.7676 13.3199C11.7676
    14.3968 12.6437 15.273 13.7207 15.273H17.8167C19.7012 15.273 21.2344 16.8061 21.2344
    18.6907C21.2344 20.5752 19.7012 22.1084 17.8167 22.1084Z" />
  </svg>
</template>

<script>
export default {
  name: "IconDayMileage",
  props: {
    width: {
      type: [Number, String],
      default: 18
    },
    height: {
      type: [Number, String],
      default: 18
    },
    iconColor: {
      type: String,
      default: '#262F3D'
    }
  }
}
</script>

<style scoped>

</style>