<template>
  <div v-if="!transportOrders.length && task.task.state.id === TASK_STATE.NEW"
       class="row">
    <div class="col-12 mb-2">
      <div class="custom-card">
        <div class="card-body d-flex justify-content-between align-items-center">
          <div class="task-card-title mb-0 d-flex align-items-center">
            Заказы транспорта
            <div class="items-count">{{ transportOrders.length }}</div>
          </div>
          <div class="task_component__actions_wrapper">
            <div
              v-if="task.task.state.id === TASK_STATE.NEW"
              class="task_component__action">
              <plus-icon
                @click="$bvModal.show('add-transport-order')"
                class="chevron" />
            </div>
            <div
              v-if="transportOrders.length"
              class="task_component__action">
              <chevron-right-icon
                @click="collapseVisible = !collapseVisible"
                class="chevron"
                :class="{'rotate': collapseVisible}" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <b-collapse id="comments-list"
                v-model="collapseVisible"
                class="col-12">
      <div v-for="transportOrder in transportOrders"
           :key="transportOrder.id"
           class="custom-card mb-2 comment">
        <div class="card-body">
          <div class="transport_order__actions">
            <base-badge
              :color="transportOrder.getStateColor()"
              :label="transportOrder.state.name" />
            <x-circle-icon
              v-if="transportOrder.state.id === 1"
              class="cursor-pointer"
              @click="cancelTransportOrder(transportOrder.id)" />
          </div>
          <div class="row">
            <div class="col-6 transport_order__block">
              <div class="transport_order__parameter">Тип транспорта</div>
              <div class="transport_order__value">{{transportOrder.transportType.name}}</div>
            </div>
            <div v-if="transportOrder.transport"
                 class="col-6 transport_order__block">
              <div class="transport_order__parameter">Транспорт</div>
              <div class="transport_order__value">
                {{transportOrder.transport.name}}
                {{transportOrder.transport.gov_number}}
              </div>
            </div>
            <div class="col-6 transport_order__block">
              <div class="transport_order__parameter">Дата подачи</div>
              <div class="transport_order__value">{{transportOrder.deliveryAt | formatDateTime}}</div>
            </div>
            <div v-if="transportOrder.comment"
                 class="col-6 transport_order__block">
              <div class="transport_order__parameter">Комментарий</div>
              <div class="transport_order__value">{{transportOrder.comment}}</div>
            </div>
          </div>
        </div>
      </div>
    </b-collapse>

    <add-transport-order />
  </div>
</template>

<script>
import {mapState} from "vuex";
import BaseBadge from "@/layout/BaseBadge";
import {showToast, showAlert} from "@/utils/notification";
import AddTransportOrder from "@/components/ServiceDesk/Tasks/ActionsForms/AddTransportOrder";
import TaskState from "@/models/serviceDesk/TaskState";

export default {
  name: "TaskTransportOrdersList",
  components: {AddTransportOrder, BaseBadge},
  data() {
    return {
      collapseVisible: false
    }
  },
  computed: {
    ...mapState({
      transportOrders: state => state.transportOrders.transportOrders,
      task: state => state.task.task
    }),
    TASK_STATE: () => TaskState
  },
  methods: {
    cancelTransportOrder(transportOrderId) {
      this.$store.dispatch('transportOrders/cancelTransportOrder', transportOrderId)
          .then(() => {
            showToast('Заказ транспорта отменён', 'success');
            this.$store.commit('transportOrders/SET_FILTER_PARAMS', {
              task_id: this.task.task.id
            });
            this.$store.dispatch('transportOrders/getTransportOrders');
          })
          .catch(error => showAlert('Ошибка', error))
    }
  }
}
</script>

<style scoped>
.transport_orders__list {
  list-style: disc;
  padding-left: 18px;
  color: #468FC5;
}

.transport_order {
  margin-bottom: 10px;
}

.transport_order__actions {
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.transport_order__block:not(:last-child) {
  margin-bottom: 20px;
}

.transport_order__parameter {
  display: block;
  color: #262F3D;
  font-size: 14px;
  line-height: 1;
  font-weight: 600;
  margin-bottom: 5px;
}

.transport_order__value {
  color: #468FC5;
  font-size: 16px;
  font-weight: 600;
  line-height: 1.2;
}
</style>