import * as API from './API';
import {getError} from "@/utils/helpers";

export default {
    fetchResourceStatistic(resourceId, params) {
        return new Promise((resolve, reject) => {
            API.apiClient.get(`/service-desk/brigades/${resourceId}/statistics`, {params: params})
                .then(response => resolve(response.data.data))
                .catch(error => reject(getError(error)))
        })
    }
}