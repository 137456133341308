<template>
  <div class="row">
    <div class="col-12 col-lg-12">
      <logbook-header />
      <logbook-table />
    </div>
  </div>
</template>

<script>
import LogbookHeader from "@/components/Telemetry/LogbookHeader";
import LogbookTable from "@/components/Telemetry/LogbookTable";

export default {
  name: "LogbookPage",
  components: {
    LogbookTable,
    LogbookHeader
  },
  mounted() {
    this.$store.dispatch('logbookPage/getNetworkObjectsList');
  }
}
</script>

<style scoped>

</style>