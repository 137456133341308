<template>
  <div
    id="map-block"
    class="position-relative map-base-height"
  >
    <div class="transport-type-block">
      <div
        class="transport-type"
        @click="changeTypeTransport(1)"
      >
        <img
          src="@/assets/img/transport/car.svg"
          alt="Легковые автомобили"
        >
      </div>
      <div
        class="transport-type"
        @click="changeTypeTransport(2)"
      >
        <img
          src="@/assets/img/transport/truck.svg"
          alt="Грузовые автомобили"
        >
      </div>
      <div
        class="transport-type"
        @click="changeTypeTransport(3)"
      >
        <img
          src="@/assets/img/transport/water-carrier.svg"
          alt="Водовозки"
        >
      </div>
      <div
        class="transport-type"
        @click="changeTypeTransport(4)"
      >
        <img
          src="@/assets/img/transport/special-vehicle.svg"
          alt="Спец. техника"
        >
      </div>
      <div
        class="transport-type"
        @click="changeTypeTransport(0)"
      >
        <img
          src="@/assets/img/transport/all-vehicles.svg"
          alt="Вся техника"
        >
      </div>
    </div>
    <button v-if="$store.state.autos.movement_history.length"
            class="btn custom-btn-blue-outline clear-movement-history-btn"
            @click="$store.commit('autos/CLEAR_MOVEMENT_HISTORY')">
      Очистить путь
    </button>
    <base-map :center="this.$store.getters['baseMap/getCenter']"
              :zoom="this.$store.getters['baseMap/getZoom']"
              :polyline="createPolyline()"
              :markers="createMarkers()"
              :base_layers_control_position_top="'20px'"
    />
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import BaseMap from "@/components/BaseMap";
import Marker from "@/models/baseMap/Marker";
import Icon from "@/models/baseMap/Icon"
import Polyline from "@/models/baseMap/Polyline";

export default {
  name: "TransportMap",
  components: {
    BaseMap
  },
  props: ['hideMap', 'fixAutoMap'],
  data() {
    return {
      display_vehicle_history: true
    }
  },
  computed: {
    ...mapGetters({
      autos: 'autos/autosMap',
      lat: 'transportData/getLat',
      lon: 'transportData/getLon',
      sp: 'transportData/getSpeed',
      timedata: 'transportData/getTimestamp',
      engine: 'transportData/getEngineStatus',
      wialonComplete: 'transportData/complete'
    }),
    getIconClass: function () {
      return img_name => {
        switch (img_name) {
          case 'car-tab11.png' :
            return 'car-tab11'
          case 'car-tab22.png' :
            return 'car-tab22'
          case 'water.png' :
            return 'water-png'
          case 'car-tab33.png' :
            return 'car-tab33'
          case 'tow-truck.png' :
            return 'tow-truck'
          case 'tractor.png' :
            return 'tractor'
          case 'truck-1.png' :
            return 'truck-1'
          case 'truck-2.png' :
            return 'truck-2'
          case 'truck-3.png' :
            return 'truck-3'
          case 'van.png' :
            return 'van-png'
        }
      }
    },
    statusColor: function () {
      return param => {
        if (param == true || param > 0) {
          return '#468FC5'
        }
      }
    }
  },
  methods: {
    changeTypeTransport(type) {
      this.$store.commit('autos/SET_DISPLAY_TYPE', type);
    },
    getPopupIconClass(type) {
      switch (type) {
        case 1:
          return 'car-icon'
        case 2:
          return 'truck-icon'
        case 3:
          return 'water-carrier-icon'
        case 4:
          return 'special-vehicle-icon'
      }
    },
    createPolyline() {
      return new Polyline({
        coords: this.$store.getters['autos/getMovementHistory'].map(item => [item.attributes.lon, item.attributes.lat])
      })
    },
    createMarkers() {
      let markers = [];
      this.$store.getters['autos/getAutos'].forEach(auto => {

        if (auto.showOnMap && !auto.fixOnMap) {
          markers.push( new Marker({
            coords: [this.$store.getters['transportData/getLat'](auto.attributes.external_id),
            this.$store.getters['transportData/getLon'](auto.attributes.external_id)],
            popup_content: this.createPopupContent(auto),
            accumulation: true,
            icon: new Icon({
              type: 'div_icon',
              class: this.getIconClass(auto.attributes.img) + ' transport-map-icon',
              html: `<div class="transport-map-label">${auto.attributes.label}</div>`
            })
          }))
        }

        if (auto.showOnMap && auto.fixOnMap) {
          let html = `<div class="transport-map-label-fixed">
                    <div class="row">
                      <div class="col-12">
                          <div class="vehicle-speed text-center">
                              ${this.$store.getters['transportData/getSpeed'](auto.attributes.external_id)} км/ч
                          </div>
                      </div>
                      <div class="col-12">
                          <div class="vehicle-name">${auto.attributes.label}</div>
                      </div>
                      <div class="col-12">
                          <div class="vehicle-name">${auto.attributes.gov_number}</div>
                      </div>
                    </div>
                  </div>`

          markers.push(new Marker({
            coords: [this.$store.getters['transportData/getLat'](auto.attributes.external_id),
              this.$store.getters['transportData/getLon'](auto.attributes.external_id)],
            icon: new Icon({
              type: 'div_icon',
              class: this.getIconClass(auto.attributes.img) + ' transport-map-icon',
              html: html
            })
          }))
        }

      })

      if (this.$store.getters['autos/getMovementHistory']) {
        this.$store.getters['autos/getMovementHistory'].filter((e, i) => i % 10 === 0).forEach(history_item => {
          markers.push(new Marker({
            coords: [history_item.attributes.lat, history_item.attributes.lon],
            rotation_angle: history_item.attributes.course,
            popup_content: "Время: " + history_item.attributes.date + ", Скорость: " + history_item.attributes.speed,
            icon: new Icon({
              type: 'icon',
              url: require('@/assets/img/transport/cursor.svg')
            })
          }))
        })
      }

      return markers
    },
    createPopupContent(auto) {
      return `<div class="row">
                <div class="col-12 d-flex align-items-center">
                    <div class="vehicle-icon ${this.getPopupIconClass(auto.attributes.type)}"></div>
                    <div class="vehicle-speed">
                        ${this.$store.getters['transportData/getSpeed'](auto.attributes.external_id)} км/ч
                    </div>
                </div>
                <div class="col-12">
                    <div class="vehicle-name">${auto.attributes.label}</div>
                </div>
                <div class="col-12">
                    <div class="vehicle-name">${auto.attributes.gov_number}</div>
                </div>
              </div>`

    }
  }
}
</script>

<style scoped>
.transport-type-block {
  position: absolute;
  z-index: 999;
  right: 22px;
  top: 35%;
  background-color: rgba(10, 97, 135, 0.7);
  padding: 10px;
  border-radius: 14px;
  backdrop-filter: blur(4px);
}

.transport-type {
  height: 40px;
  width: 40px;
  display: flex;
  align-content: center;
  justify-content: center;
  background-color: #EAF9FF;
  border: 2px solid #1F9BCF;
  border-radius: 7px;
  backdrop-filter: blur(2px);
  margin-bottom: 15px;
}

.transport-type:nth-last-child(1) {
  margin-bottom: 0;
}

.transport-type > img {
  width: 28px;
}

.clear-movement-history-btn {
  position: absolute;
  z-index: 500;
  right: 10px;
  top: 10px;
  font-size: 14px;
}

@media (min-width: 992px) and (max-width: 1398px) {
  #map-block {
    width: 100%;
  }
}
</style>