<template>
  <div class="logbook__header">
    <div class="logbook__header_title">Журнал ручного ввода</div>
    <date-picker
      id="period"
      v-model="date"
      format="D MMMM YYYY г."
      input-class="form-control-fix w-100 pl-2"
      placeholder="Выберите день"
      type="date"
      :default-value="defaultValue"
      @input="getParametersValues"
    />
    <base-loader v-if="loading" />
  </div>
</template>

<script>
import DatePicker from "vue2-datepicker";
import BaseLoader from "@/layout/BaseLoader";
import {mapState} from "vuex";
export default {
  name: "LogbookHeader",
  components: {BaseLoader, DatePicker},
  data() {
    return {
      date: new Date(),
      defaultValue: new Date()
    }
  },
  computed: {
    ...mapState({
      loading: state => state.logbookPage.valuesLoading
    })
  },
  mounted() {
    this.getParametersValues();
    this.$store.commit('logbookPage/SET_CURRENT_LOGBOOK_DATE', this.date)
  },
  methods: {
    getParametersValues() {
      this.$store.commit('logbookPage/SET_CURRENT_LOGBOOK_DATE', this.date);
      this.$store.dispatch('logbookPage/getParametersValues', {
        date: this.$moment(this.date).format('YYYY-MM-DD')
      })
    }
  }
}
</script>

<style scoped>
.logbook__header {
  display: flex;
  align-items: center;
  gap: 12px;
  margin-bottom: 20px;
}

.logbook__header_title {
  font-size: 24px;
  color: #262f3d;
  font-weight: 700;
}
</style>