import NetworkEquipmentTypesService from "@/services/NetworkEquipmentTypesService";
import {getError} from "@/utils/helpers";

export const namespaced = true;

export const state = {
    networkEquipmentTypes: [],
    error: null,
    loading: false
}

export const mutations = {
    SET_NETWORK_EQUIPMENT_TYPES(state, equipmentTypes) {
        state.networkEquipmentTypes = equipmentTypes;
    },
    SET_ERROR(state, error) {
        state.error = error;
    },
    SET_LOADING(state, loading) {
        state.loading = loading;
    },
    DELETE_NETWORK_EQUIPMENT_TYPE(state, typeId) {
        const index = state.networkEquipmentTypes.findIndex(item => item.id === typeId);
        state.networkEquipmentTypes.splice(index, 1);
    }
}

export const actions = {
    getNetworkEquipmentTypes({commit}) {
        commit("SET_LOADING", true);
        NetworkEquipmentTypesService.fetchNetworkEquipmentTypesService()
            .then(equipmentTypes => commit("SET_NETWORK_EQUIPMENT_TYPES", equipmentTypes))
            .catch(error => commit("SET_ERROR", error))
            .finally(() => commit("SET_LOADING", false));
    },
    createNetworkEquipmentType(context, payload) {
        return new Promise((resolve, reject) => {
            NetworkEquipmentTypesService.createNetworkEquipmentType(payload)
                .then(() => resolve())
                .catch(error => reject(getError(error)))
        })
    },
    deleteNetworkEquipmentType(context, equipmentTypeId) {
        return new Promise((resolve, reject) => {
            NetworkEquipmentTypesService.deleteNetworkEquipmentType(equipmentTypeId)
                .then(() => resolve())
                .catch(error => reject(getError(error)))
        })
    }
}