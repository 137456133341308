<template>
  <div class="row">
    <div class="col-12 mb-2">
      <div class="custom-card">
        <div class="card-body d-flex justify-content-between align-items-center">
          <div class="task-card-title mb-0 d-flex align-items-center">
            Средства малой механизации
            <div class="items-count">{{ taskMechanicalEquipments.length }}</div>
          </div>
          <div class="task_component__actions_wrapper">
            <div v-if="task.task.state.id !== TASK_STATE.CLOSED
                   && task.task.state.id !== TASK_STATE.CANCELED"
                 class="task_component__action">
              <plus-icon
                @click="$bvModal.show('add-mechanical-equipment')"
                class="chevron" />
            </div>
            <div
              v-if="taskMechanicalEquipments.length"
              class="task_component__action">
              <chevron-right-icon
                @click="collapseVisible = !collapseVisible"
                class="chevron"
                :class="{'rotate': collapseVisible}" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <b-collapse
      v-model="collapseVisible"
      class="col-12">
      <div v-for="equip in taskMechanicalEquipments"
           :key="equip.id"
           class="custom-card mb-2 comment">
        <div class="card-body">
          <div class="equipment__wrapper">
            <div>
              <div class="equipment__name">{{ equip.name }}</div>
              <div class="task_component__param">
                Время работы: <span>{{equip.duration}}ч.</span>
              </div>
            </div>
            <trash2-icon
              @click="deleteEquipment(equip.id)"
              class="cursor-pointer" />
          </div>
        </div>
      </div>
    </b-collapse>

    <add-mechanical-equipment-form />
  </div>
</template>

<script>
import {mapState} from 'vuex'
import notification from "@/mixins/notification";
import AddMechanicalEquipmentForm from "@/components/ServiceDesk/Tasks/ActionsForms/AddMechanicalEquipmentForm";
import TaskState from "@/models/serviceDesk/TaskState";
export default {
  name: "MechanicalEquipmentsList",
  components: {AddMechanicalEquipmentForm},
  mixins: [notification],
  data() {
    return {
      collapseVisible: false
    }
  },
  computed: {
    ...mapState({
      taskMechanicalEquipments: state => state.taskMechanicalEquipments.taskMechanicalEquipments,
      task: state => state.task.task
    }),
    TASK_STATE: () => TaskState
  },
  methods: {
    deleteEquipment(equipId) {
      this.$store.dispatch('taskMechanicalEquipments/removeMechanicalEquipmentFromTask', {
        taskId: this.task.task.id,
        payload: {
          equipment_id: equipId
        }
      })
          .then(() => this.toast('Средство малой механизации удалено из задания', 'success'))
          .catch(error => this.alert('Ошибка', error, 'error'))
    }
  }
}
</script>

<style scoped>
.equipment__name {
  font-size: 16px;
  font-weight: 600;
  color: #262F3D;
  line-height: 100%;
  margin-bottom: .5rem;
}

.equipment__wrapper {
  display: flex;
  justify-content: space-between;
}
</style>