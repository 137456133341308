<template>
  <div class="row">
    <div class="col-12">
      <div class="custom-card">
        <div class="card-body">
          <div class="row">
            <div class="col-12">
              <h4 class="table-title d-inline-block">
                Типы объектов сети
              </h4>
              <div
                v-if="loading"
                class="d-inline-block ml-2">
                <base-loader />
              </div>
              <router-link to="/network-object-types-create">
                <button class="btn custom-btn-blue float-right">
                  Добавить тип объектов сети
                </button>
              </router-link>
              <hr class="divider">
            </div>

            <div
              v-if="networkObjectTypes.length"
              class="col-12"
            >
              <network-object-types-list />
            </div>

            <div
              v-if="!networkObjectTypes.length && !loading"
              class="col-12 mt-2"
            >
              <div class="no-result-title">Типы объектов сети не найдены</div>
            </div>

          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import NetworkObjectTypesList from "@/components/Telemetry/admin/NetworkObjectTypesList";
import BaseLoader from "@/layout/BaseLoader";
import {mapState} from "vuex";
export default {
  name: "NetworkObjectTypesListPage",
  components: {BaseLoader, NetworkObjectTypesList},
  computed: {
    ...mapState({
      networkObjectTypes: state => state.networkObjectTypes.networkObjectTypes,
      loading: state => state.networkObjectTypes.loading
    })
  },
  mounted() {
    this.$store.dispatch('networkObjectTypes/getNetworkObjectTypesList');
  }
}
</script>

<style scoped>

</style>