export default class File {
    constructor(id, attributes) {
        this.id = id;
        this.name = attributes.name;
        this.url = attributes.path;
    }

    getFileType() {
        const file_extension = this.url.split('.').pop();
        if (file_extension === 'doc'
            || file_extension === 'txt'
            || file_extension === 'pdf') {
            return 'document'
        } else if (file_extension === 'jpg' || file_extension === 'png') {
            return 'image'
        } else {
            return 'unknown_type'
        }
    }
}