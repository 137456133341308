<template>
  <div class="row">
    <div class="col-12">
      <div class="custom-card">
        <div class="card-body">
          <div class="container-fluid">
            <div class="row">
              <div class="col-12">
                <h4 class="table-title d-inline-block">
                  Расходные материалы
                </h4>
                <div v-if="loading"
                     class="d-inline-block ml-2">
                  <base-loader />
                </div>
                <router-link to="/create-consumable">
                  <button class="btn custom-btn-blue float-right">
                    Добавить расходный материал
                  </button>
                </router-link>
                <hr class="divider">
              </div>
              <div v-if="consumablesList.length"
                   class="col-12">
                <consumables-list />
              </div>
              <div v-if="!consumablesList.length
                     && !loading"
                   class="col-12 mt-2">
                <div class="no-result-title">Расходные материалы не найдены</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BaseLoader from "@/layout/BaseLoader";
import ConsumablesList from "@/components/ServiceDesk/admin/ConsumablesList";
import {mapState} from "vuex";
export default {
  name: "ConsumablesListPage",
  components: {ConsumablesList, BaseLoader},
  computed: {
    ...mapState({
      consumablesList: state => state.consumables.consumables,
      loading: state => state.consumables.loading
    })
  },
  mounted() {
    this.$store.dispatch('consumables/getConsumablesList');
  }
}
</script>

<style scoped>

</style>