export default class Tag {
    static tags = [
        {id: 1, label: 'Водоснабжение'},
        {id: 2, label: 'Водоотведение'},
        {id: 3, label: 'Отключения'},
        {id: 4, label: 'Разрытие'},
        {id: 5, label: 'Повреждение'},
        {id: 6, label: 'Повреждение в колодце'},
        {id: 7, label: 'Повреждение на поверхности'},
        {id: 8, label: 'Промывка'}
    ]

    static getTagLabel(tagId) {
        const tag = this.tags.find(tag => tag.id === tagId)
        if (tag) return tag.label;
    }
}