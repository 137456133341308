import * as API from './API';
import {getError} from "@/utils/helpers";
import {ProjectConfig} from "@/models/ProjectConfig";

export default {
   getProjectConfig() {
       return new Promise((resolve, reject) => {
           API.apiClient.get('/configuration')
               .then(response => resolve(new ProjectConfig(response.data.configuration)))
               .catch(error => reject(getError(error)));
       })
   }
}