<template>
  <table class="daily-report-table">
    <thead>
      <tr>
        <td rowspan="2">#</td>
        <td rowspan="2">Открыта</td>
        <td rowspan="2">Закрыта</td>
        <td rowspan="2">Адрес</td>
        <td rowspan="2">Отключения</td>
        <td colspan="2">Что сделано</td>
      </tr>
      <tr class="second-row">
        <td>Подразделение</td>
        <td>Задания</td>
      </tr>
    </thead>
    <tbody v-for="issue in issues"
           :key="issue.id">
      <tr>
        <td :rowspan="issue.tasks.length">
          <router-link :to="`/issue/${issue.id}`">
            {{ issue.id }}
          </router-link>
        </td>
        <td :rowspan="issue.tasks.length">{{ issue.created_at | formatDateTime }}</td>
        <td :rowspan="issue.tasks.length">{{ issue.closed_at | formatDateTime }}</td>
        <td :rowspan="issue.tasks.length">{{ issue.getFullAddress() }}</td>
        <td :rowspan="issue.tasks.length"
            class="text-left">
          <div v-if="issue.shutdowns.length">
            <div v-for="shutdown in issue.shutdowns"
                 :key="shutdown.id"
                 class="shutdown-item mb-2">
              <div v-if="shutdown.actual_start_date">
                Факт. дата начала: <span>{{ shutdown.actual_start_date | formatDateTime }}</span>
              </div>
              <div v-if="shutdown.actual_end_date">
                Факт. дата окончания: <span>{{ shutdown.actual_end_date | formatDateTime }}</span>
              </div>
              <div>
                Комментарий:
                <span>{{ shutdown.comment }}</span>
              </div>
            </div>
          </div>
          <div
            v-else
            class="text-center">-</div>
        </td>
        <td>
          <div v-if="issue.tasks.length">
            <div>{{issue.tasks[0].department ? issue.tasks[0].department.name : '-'}}</div>
            <div>{{issue.tasks[0].brigade_name}}</div>
          </div>
        </td>
        <td>
          <div v-if="issue.tasks.length">
            <div>{{issue.tasks[0].type_label}}</div>
            <div class="mb-2">{{issue.tasks[0].actual_start_date | formatDateTime}} -
              {{issue.tasks[0].actual_end_date | formatDateTime}}</div>
            <div v-if="issue.tasks[0].works.length">
              <ul v-for="element in issue.emergencyElements"
                  :key="element.id"
                  class="mb-2">
                <li v-if="issue.tasks[0].works.filter(work => {
                      return work.element ? work.element.id === element.id : false}).length"
                    class="list-title">
                  <div>{{element.element_type.name}}</div>
                  <div v-for="feature in element.features"
                       :key="feature.id">
                    {{feature.feature.name}}: {{feature.value}}{{feature.unit}}
                  </div>
                  <div>{{element.damageName}}</div>
                </li>
                <li v-for="work in issue.tasks[0].works.filter(work => {
                      return work.element ? work.element.id === element.id : false})"
                    :key="work.id">
                  {{work.name}}
                </li>
              </ul>
              <ul v-if="issue.tasks[0].works.filter(work => !work.element).length">
                <li class="list-title">
                  <div>Без аварийного элемента</div>
                </li>
                <li v-for="work in issue.tasks[0].works.filter(work => !work.element)"
                    :key="work.id">
                  {{work.name}}
                </li>
              </ul>
            </div>
          </div>
        </td>
      </tr>
      <tr v-for="task in issue.tasks.filter((item, index) => index !== 0)"
          :key="task.id">
        <td>
          <div>{{task.department ? task.department.name : '-'}}</div>
          <div>{{task.brigade_name}}</div>
        </td>
        <td>
          <div>{{task.type_label}}</div>
          <div class="mb-2">
            {{task.actual_start_date | formatDateTime}} - {{task.actual_end_date | formatDateTime}}
          </div>
          <div v-if="task.works.length">
            <ul v-for="element in issue.emergencyElements"
                :key="element.id">
              <li v-if="task.works.filter(work => {
                    return work.element ? work.element.id === element.id : false}).length"
                  class="list-title">
                <div>{{element.element_type.name}}</div>
                <div v-for="feature in element.features"
                     :key="feature.id">
                  {{feature.feature.name}}: {{feature.value}}{{feature.feature.unit}}
                </div>
                <div>{{element.damageName}}</div>
              </li>
              <li v-for="work in task.works.filter(work => {
                    return work.element ? work.element.id === element.id : false})"
                  :key="work.id">
                {{work.name}}
              </li>
            </ul>
            <ul v-if="task.works.filter(work => !work.element).length">
              <li class="list-title">
                <div>Без аварийного элемента</div>
              </li>
              <li v-for="work in task.works.filter(work => !work.element)"
                  :key="work.id">
                {{work.name}}
              </li>
            </ul>
          </div>
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "DailyReportTable",
  props: {
    issues: {
      required: true,
      type: Array
    }
  },
  computed: {
    ...mapGetters({
      departmentName: 'departments/getDepartmentName'
    })
  }
}
</script>

<style scoped>

.shutdown-item div {
  color: #262F3D;
}

.shutdown-item div span {
  color: #468FC5;
}

.daily-report-table {
  border-collapse: separate;
  border-spacing: 0;
  width: 100%;
  text-align: center;
  margin-bottom: 20px;
}

.daily-report-table thead td {
  border-right: 1px solid #468FC5;
  border-top: 1px solid #468FC5;
  background-color: #262F3D;
  color: white;
  font-weight: 700;
  font-size: 16px;
  padding: 8px;
}

.daily-report-table thead td:first-child {
  border-left: 1px solid #468FC5;
  border-radius: 10px 0 0 0;
}

.daily-report-table thead td:last-child {
  border-radius: 0 10px 0 0;
}

.daily-report-table tbody td {
  border-right: 1px solid #468FC5;
  border-bottom: 1px solid #468FC5;
  color: #262F3D;
  padding: 8px;
  text-align: left;
}

.daily-report-table tbody tr:first-child td:first-child {
  border-left: 1px solid #468FC5;
}

.second-row td:first-child {
  border-left: none !important;
  border-radius: 0 !important;
}

.second-row td:last-child {
  border-radius: 0 !important;
}

.list-title {
  list-style-type: none;
  margin-left: -40px;
}

.list-title:first-child {
  font-weight: 600;
}
</style>