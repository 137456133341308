<template>
  <form @submit.prevent="submitForm()">
    <div class="row">
      <div class="col-12">
        <div class="form-group">
          <label class="form-label">Период</label>
          <date-picker
            input-class="form-control-fix w-100 pl-2"
            placeholder="Выберите период"
            v-model="reportParams.date"
            :default-value="defaultValue"
            format="DD.MM.YYYY"
            type="day"
            range
            value-type="YYYY-MM-DD" />
        </div>
      </div>
    </div>
    <button class="btn custom-btn-blue w-100">Сформировать</button>
  </form>
</template>

<script>
import DatePicker from "vue2-datepicker";
export default {
  name: "AppealsStatisticReportForm",
  components: {DatePicker},
  data() {
    return {
      defaultValue: new Date(),
      reportParams: {
        date: [this.$moment().format('YYYY-MM-DD'), this.$moment().format('YYYY-MM-DD')]
      }
    }
  },
  methods: {
    submitForm() {
      this.$store.commit('reportsData/SET_REPORT_DATE', this.reportParams.date);
      const [start, end] = this.reportParams.date;
      this.$store.dispatch('serviceDeskCategories/getCategoryGroups')
          .then(() => {
            this.$store.dispatch('reportsData/getAppealsCountByCategories', {
              start_period: start,
              end_period: end
            })
          })
    }
  }
}
</script>

<style scoped>

</style>