import moment from "moment";
import IssueState from "@/models/serviceDesk/IssueState";
export default class Issue {
    constructor(id, attributes) {
        this.id = id;
        this.tags = attributes.tags;
        this.author = attributes.author || null;
        this.created_at = attributes.created_at;
        this.closed_at = attributes.closed_at || null;
        this.state = new IssueState(attributes.state);
        this.based_on = attributes.based_on;
        this.department_id = attributes.department?.id;
        this.department_name = attributes.department?.name;
        this.address = attributes.address;
        this.coordinates = attributes.address.geometry?.coordinates.reverse() || null;
        this.appeals_count = attributes.appeals_count || 0;
        this.category = attributes.category || null;
        this.category_id = attributes.category_id || null;
        this.type_id = attributes.type?.id;
        this.type_name = attributes.type?.name;
        this.activated_in = attributes.activated_in || null;
        this.description = attributes.description;
        this.planned_start_at = attributes.planned_start_at || null;
        this.planned_end_at = attributes.planned_end_at || null;
    }

    getCaption() {
        return `№${this.id} от ${moment(this.created_at).format(process.env.VUE_APP_BASE_DATE_TIME_FORMAT)},
         ${this.getFullAddress()}`
    }

    getFullAddress() {
        return [this.address.city, this.address.settlement, this.address.street,
            this.address.house, this.address.block, this.address.flat]
            .filter(item => item !== null)
            .join(', ')
    }
}