<template>
  <div v-if="reportData.length"
       class="custom-card">
    <div class="card-body">
      <div class="col-12">
        <table class="admin-table table-responsive">
          <thead class="admin-table-header">
            <tr>
              <td class="sticky-left"/>
              <td v-for="(item, index) in reportData"
                  :key="index">
                {{ formatDate(item.date) }}
              </td>
            </tr>
          </thead>
          <tbody class="admin-table-body">
            <tr>
              <td class="sticky-left">Остаток с прошлого периода</td>
              <td v-for="(item, index) in reportData"
                  :key="index">{{ item.count_transit }}
              </td>
            </tr>
            <tr>
              <td class="sticky-left">Поступило за период</td>
              <td v-for="(item, index) in reportData"
                  :key="index">{{ item.count_opened }}
              </td>
            </tr>
            <tr>
              <td class="sticky-left">Завершено за период</td>
              <td v-for="(item, index) in reportData"
                  :key="index">{{ item.count_closed }}
              </td>
            </tr>
            <tr>
              <td class="sticky-left">Остаток на конец периода</td>
              <td v-for="(item, index) in reportData"
                  :key="index">{{ item.count_end_period }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "IssuesCountReport",
  computed: {
    ...mapGetters({
      reportData: 'reportsData/getReportData',
      reportParams: 'reportsData/getReportParams'
    })
  },
  methods: {
    formatDate(date) {
      if (this.reportParams.group === 1) return this.$moment(date).format("DD.MM.YY")
      else return this.$moment(date).format("MMMM YYYY")
    }
  }
}
</script>

<style scoped>
.sticky-left {
  position: sticky;
  left: 0;
}

.admin-table-body tr td {
  background-color: #F9FDFF;
}
</style>