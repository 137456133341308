<template>
  <svg
    :width="width"
    :height="height"
    viewBox="0 0 12 9"
    :fill="iconColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.7143 2.14286H10.2857C10.0491 2.14286 9.85714 2.33486 9.85714
    2.57143V3.85714H8.57143V3C8.57143 2.52643 8.18786 2.14286 7.71429
    2.14286H6V1.28571H7.71429C7.95086 1.28571 8.14286 1.09371 8.14286
    0.857143V0.428571C8.14286 0.192 7.95086 0 7.71429 0H3C2.76343 0 2.57143
    0.192 2.57143 0.428571V0.857143C2.57143 1.09371 2.76343 1.28571 3
    1.28571H4.71429V2.14286H3C2.52643 2.14286 2.14286 2.52643 2.14286
    3V4.71429H1.28571V3.42857C1.28571 3.192 1.09371 3 0.857143 3H0.428571C0.192
    3 0 3.192 0 3.42857V7.28571C0 7.52229 0.192 7.71429 0.428571 7.71429H0.857143C1.09371
    7.71429 1.28571 7.52229 1.28571 7.28571V6H2.14286V7.11086C2.14286 7.35814 2.24957
    7.593 2.43557 7.75586L3.615 8.78786C3.771 8.92457 3.97157 9 4.179 9H7.71429C8.18786
    9 8.57143 8.61643 8.57143 8.14286V6.85714H9.85714V8.14286C9.85714 8.37943 10.0491 8.57143
    10.2857 8.57143H10.7143C11.4244 8.57143 12 7.99586 12 7.28571V3.42857C12 2.71843 11.4244
    2.14286 10.7143 2.14286ZM6.78514 5.80929L5.928 7.095C5.79643 7.29214 5.57143 7.28571 5.57143
    7.28571V6H4.71429C4.55614 6 4.41086 5.913 4.33629 5.77371C4.26171 5.63443 4.26986 5.46514
    4.35771 5.334L5.21486 4.04829C5.346 3.85071 5.57143 3.85714 5.57143 3.85714V5.14286H6.42857C6.58671
    5.14286 6.732 5.22986 6.80657 5.36914C6.88114 5.50843 6.873 5.67771 6.78514 5.80929Z" />
  </svg>
</template>

<script>
export default {
  name: "IconEngine",
  props: {
    width: {
      type: [Number, String],
      default: 18
    },
    height: {
      type: [Number, String],
      default: 18
    },
    iconColor: {
      type: String,
      default: '#97ACB5'
    }
  }
}
</script>

<style scoped>

</style>