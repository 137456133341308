<template>
  <div class="custom-card">
    <div class="card-body">
      <div class="container-fluid">
        <div class="row">
          <div class="col-12">
            <form @submit.prevent="checkType">
              <div class="form-group">
                <label class="form-label">Наименование</label>
                <input
                  v-model="payload.name"
                  type="text"
                  class="form-control form-control-fix"
                  placeholder="Введите категории"
                  required
                >
              </div>

              <div class="form-group">
                <label class="form-label">Группа</label>
                <v-select
                  v-model="payload.group_id"
                  :options="categoryGroups"
                  :reduce="group => group.id"
                  :get-option-label="(option) => option.name"
                  placeholder="Выберите группу"
                />
              </div>
              <button class="btn custom-btn-blue w-100">Сохранить</button>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import notification from "@/mixins/notification";

export default {
  name: "CategoryForm",
  mixins: [notification],
  data() {
    return {
      payload: {
        name: null,
        group_id: null
      },
      category_id: null,
      type: 'create'
    }
  },
  computed: {
    ...mapGetters({
      categoryGroups: 'serviceDeskCategories/getCategoryGroups',
      category: 'serviceDeskCategories/getCategory'
    })
  },
  mounted() {
    this.$store.dispatch('serviceDeskCategories/getCategoryGroups');

    if (this.$route.name === 'UpdateCategory') {
      this.type = 'update';
      const category = this.category(this.$route.params.id);
      this.category_id = category.id;
      this.payload.name = category.name;
      this.payload.group_id = category.group.id;
    }
  },
  methods: {
    checkType() {
      if (this.type === 'create') {
        this.createCategory();
      } else {
        this.updateCategory();
      }
    },
    createCategory() {
      this.$store.dispatch('serviceDeskCategories/createCategory', this.payload)
          .then(() => {
            this.toast('Категория добавлена', 'success');
            this.$router.push('/service-desk-categories');
          })
          .catch(error => this.alert('Ошибка', error, 'error'))
    },
    updateCategory() {
      this.$store.dispatch('serviceDeskCategories/updateCategory', {
        id: this.category_id,
        payload: this.payload
      })
          .then(() => {
            this.toast('Категория обновлена', 'success');
            this.$router.push('/service-desk-categories');
          })
          .catch(error => this.alert('Ошибка', error, 'error'))
    }
  }
}
</script>

<style scoped>

</style>