import { render, staticRenderFns } from "./ZonesPage.vue?vue&type=template&id=197e3012&scoped=true&"
import script from "./ZonesPage.vue?vue&type=script&lang=js&"
export * from "./ZonesPage.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "197e3012",
  null
  
)

export default component.exports