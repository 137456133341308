<template>
  <div class="row">
    <div class="col-lg-3">
      <div class="custom-card">
        <div class="card-header">
          <h1 class="card-title mb-0 d-inline-block mr-2">
            Рабочая смена
          </h1>
          <loader v-if="loader" />
        </div>
        <div class="card-body">
          <img
            v-if="edit_mode"
            class="custom-modal-close float-right"
            src="@/assets/img/icons/close.svg"
            @click="disableEditMode"
          >
          <div class="form-group">
            <label class="form-label">Сотрудник</label>
            <select
              v-model="event.employee_id"
              class="form-control form-control-fix"
              :disabled="edit_mode"
            >
              <option
                disabled
                value="null"
              >
                Выберите сотрудника
              </option>
              <option
                v-for="employee in this.$store.state.employee.staff"
                :key="employee.id"
                :value="employee.id"
              >
                {{ employee.attributes.name }}
              </option>
            </select>
          </div>
          <div class="form-group">
            <label class="form-label">Дата и время начала</label>
            <date-picker
              id="period"
              v-model="event.date_start"
              :format="'HH:mm DD MMMM YYYY г.'"
              input-class="custom-datepicker form-control-fix"
              placeholder="Выберите дату"
              type="datetime"
              :default-value="defaultValue"
              value-type="YYYY-MM-DD HH:mm"
            />
          </div>
          <div class="form-group">
            <label class="form-label">Дата и время окончания</label>
            <date-picker
              id="period"
              v-model="event.date_end"
              :format="'HH:mm DD MMMM YYYY г.'"
              input-class="custom-datepicker form-control-fix"
              placeholder="Выберите дату"
              type="datetime"
              :default-value="defaultValue"
              value-type="YYYY-MM-DD HH:mm"
            />
          </div>
          <button
            v-if="!edit_mode"
            class="btn custom-btn-blue-outline mb-2 w-100"
            @click="addEvent"
          >
            Добавить
          </button>
          <button
            v-if="edit_mode"
            class="btn custom-btn-blue-outline mb-2 w-100"
            @click="updateEvent"
          >
            Изменить
          </button>
          <button
            v-if="edit_mode"
            class="btn custom-btn-red-outline mb-2 w-100"
            @click="deleteEvent"
          >
            Удалить
          </button>
        </div>
      </div>
    </div>
    <div class="col-lg-9">
      <div class="custom-card">
        <div class="card-body">
          <full-calendar-component
            ref="calendar"
            :options="calendarOptions"
          >
            <template v-slot:eventContent="arg">
              <div class="text-center">
                {{ arg.event.extendedProps.time_start }} -
                {{ arg.event.extendedProps.time_end }}
              </div>
              <div class="text-center">
                {{ arg.event.title }}
              </div>
            </template>
          </full-calendar-component>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import FullCalendarComponent from '@fullcalendar/vue'
import dayGridPlugin from '@fullcalendar/daygrid'
import ruLocale from '@fullcalendar/core/locales/ru'
import DatePicker from "vue2-datepicker";
import WorkShiftService from "@/services/WorkShiftService";
import notification from "@/mixins/notification";
import {getError} from "@/utils/helpers";
import {mapGetters} from "vuex";
import Loader from "@/layout/BaseLoader";

export default {
  name: "WorkShiftPage",
  components: {Loader, FullCalendarComponent, DatePicker},
  mixins: [notification],
  data() {
    return {
      calendarOptions: {
        plugins: [dayGridPlugin],
        initialView: 'dayGridMonth',
        locale: ruLocale,
        timeZone: 'UTC',
        displayEventTime: false,
        events: [],
        eventClick: this.openEventModal,
        datesSet: this.setCurrentCalendarDates
      },
      event: {
        id: null,
        date_start: null,
        date_end: null,
        employee_id: null
      },
      edit_mode: false,
      loader: false,
      current_calendar_dates_start: null,
      current_calendar_dates_end: null,
      defaultValue: new Date()
    }
  },
  computed: {
    ...mapGetters({
      getEmployeeName: 'employee/getEmployeeName'
    })
  },
  mounted() {
    this.$store.dispatch('employee/getStaff')
    this.getWorkShifts()
    this.addEventListeners();
  },
  methods: {
    addEventListeners() {
      let self = this
      document.getElementsByClassName('fc-prev-button')[0].addEventListener('click', function () {
        self.getWorkShifts()
      })
      document.getElementsByClassName('fc-next-button')[0].addEventListener('click', function () {
        self.getWorkShifts()
      })
      document.getElementsByClassName('fc-today-button')[0].addEventListener('click', function () {
        self.getWorkShifts()
      })
    },
    addEvent() {
      let payload = {
        employee_id: this.event.employee_id,
        workshift_start: this.event.date_start,
        workshift_end: this.event.date_end
      }
      WorkShiftService.createWorkShiftItem(payload)
          .then(() => {
            this.getWorkShifts()
            this.toast('Рабочая смена создана')
          })
          .catch(error => {
            this.alert('Ошибка добавления', getError(error), 'error')
          })
    },
    updateEvent() {
      let payload = {
        workshift_start: this.event.date_start,
        workshift_end: this.event.date_end
      }
      WorkShiftService.updateWorkShiftItem(payload, this.event.id)
          .then(() => {
            this.getWorkShifts()
            this.toast('Рабочая смена обновлена')
            this.disableEditMode()
          })
          .catch(error => {
            this.alert('Ошибка изменения', getError(error), 'error')
          })
    },
    deleteEvent() {
      WorkShiftService.deleteWorkShiftItem(this.event.id)
          .then(() => {
            this.getWorkShifts()
            this.toast('Рабочая смена удалена')
            this.disableEditMode()
          })
          .catch(error => {
            this.alert('Ошибка удаления', getError(error), 'error')
          })
    },
    getWorkShifts() {
      this.calendarOptions.events = []
      this.loader = true
      let payload = {
        workshift_start: this.current_calendar_dates_start,
        workshift_end: this.current_calendar_dates_end
      }
      WorkShiftService.getWorkShift(payload)
          .then(response => {
            response.data.data.forEach(workShiftItem => {
              this.calendarOptions.events.push({
                id: workShiftItem.id,
                title: this.getEmployeeName(workShiftItem.attributes.employee_id),
                time_start: this.$moment.utc(workShiftItem.attributes.workshift_start).format('HH:mm'),
                time_end: this.$moment.utc(workShiftItem.attributes.workshift_end).format('HH:mm'),
                employee_id: workShiftItem.attributes.employee_id,
                start: workShiftItem.attributes.workshift_start,
                end: workShiftItem.attributes.workshift_end,
                classNames: ['calendar-event'],
                display: 'block'
              })
            })
          })
          .catch(error => {
            this.alert('Ошибка получения графика', getError(error), 'error')
          })
          .finally(() => {
            this.loader = false
          })
    },
    openEventModal(event) {
      this.edit_mode = true
      this.event.id = event.event.id
      this.event.employee_id = event.event.extendedProps.employee_id
      this.event.date_start = this.$moment.utc(event.event.start).format('YYYY-MM-DD HH:mm')
      this.event.date_end = this.$moment.utc(event.event.end).format('YYYY-MM-DD HH:mm')
    },
    disableEditMode() {
      this.edit_mode = false
      this.event.employee_id = null
      this.event.date_start = null
      this.event.date_end = null
    },
    setCurrentCalendarDates(dateInfo) {
      this.current_calendar_dates_start = dateInfo.start
      this.current_calendar_dates_end = dateInfo.end
    }
  }
}
</script>

<style scoped>
.content {
  padding: 2rem 2.5rem;
}

@media (max-width: 400px) {
  .content {
    padding: 2rem 1rem;
  }
}
</style>