<template>
  <div>
    <table class="admin-table">
      <thead class="admin-table-header">
        <tr>
          <td>ID</td>
          <td>Наименование</td>
          <td>Тип</td>
          <td>Статус</td>
          <td>Действия</td>
        </tr>
      </thead>
      <tbody class="admin-table-body">
        <tr
          v-for="engineeringNetwork in engineeringNetworks"
          :key="engineeringNetwork.id"
          class="network-element"
        >
          <td>{{ engineeringNetwork.id }}</td>
          <td>{{ engineeringNetwork.name }}</td>
          <td>{{ engineeringNetwork.typeName }}</td>
          <td>{{ engineeringNetwork.getActiveLabel() }}</td>
          <td class="table-action">
            <router-link :to="'/engineering-network-update/'+engineeringNetwork.id">
              <edit-icon class="mr-1"/>
            </router-link>
          </td>
        </tr>
      </tbody>
    </table>
    <base-paginate
      v-if="$store.state.engineeringNetworks.meta && $store.state.engineeringNetworks.meta.last_page > 1"
      :meta="$store.state.engineeringNetworks.meta"
      action="engineeringNetworks/getEngineeringNetworks"
      commit="engineeringNetworks/SET_PAGE"
    />
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import BasePaginate from "@/components/BasePaginate";

export default {
  name: "EngineeringNetworksList",
  components: {BasePaginate},
  computed: {
    ...mapGetters({
      engineeringNetworks: 'engineeringNetworks/getEngineeringNetworks'
    })
  }
}
</script>

<style scoped>

</style>