<template>
  <div class="row mb-3">
    <div class="col-11">
      <div class="custom-card">
        <div class="card-body">
          <div class="d-flex">
            <v-select
              v-model="filter.category_id"
              :options="categories.concat()
                .sort((a, b) => a.group.id - b.group.id || a.name.localeCompare(b.name))"
              :reduce="category => category.id"
              :get-option-label="(option) => option.name"
              placeholder="Выберите категорию"
              class="w-100"
              @input="submitFilter()"
            >
              <template #option="{name, group}">
                <span class="font-weight-bold">{{group.name}}: </span>{{name}}
              </template>
            </v-select>
            <button @click="clearFilter()"
                    class="btn custom-btn-blue-outline reset-filter-btn">
              <icon-reset />
            </button>
          </div>
          <b-collapse
            id="planned-issues-filter-collapse"
            v-model="filterCollapseToggled">
            <div class="row mt-3">
              <div class="col-6">
                <div class="form-group">
                  <label class="form-label">Тип</label>
                  <v-select
                    v-model="filter.type"
                    :options="issueTypes"
                    :reduce="type => type.id"
                    :get-option-label="(option) => option.name"
                    placeholder="Выберите тип"
                    @input="submitFilter()"
                  />
                </div>
              </div>
              <div class="col-6">
                <div class="form-group">
                  <label class="form-label">Подразделение</label>
                  <v-select
                    v-model="filter.department_id"
                    :options="departments"
                    :reduce="department => department.id"
                    :get-option-label="(option) => option.name"
                    placeholder="Выберите подразделение"
                    @input="submitFilter()"
                  />
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-6">
                <div class="form-group">
                  <label class="form-label">Адрес</label>
                  <dadata-suggestions
                    v-model="address"
                    class="form-control form-control-fix"
                    type="ADDRESS"
                    @change="setAddress"
                    placeholder="Введите адрес"
                  />
                </div>
              </div>

              <div class="col-6">
                <div class="form-group">
                  <label class="form-label">Плановая дата</label>
                  <date-picker
                    v-model="planned_period"
                    :default-value="defaultValue"
                    input-class="form-control-fix w-100 pl-2"
                    placeholder="Выберите дату"
                    value-type="YYYY-MM-DD"
                    range
                    @change="submitFilter()"
                  />
                </div>
              </div>

              <div class="col-12">
                <div class="form-group">
                  <label class="form-label">Сортировка по плановой дате</label>
                  <v-select
                    v-model="filter.sort_dir"
                    :options="sortingOptions"
                    :reduce="sort => sort.dir"
                    :get-option-label="(option) => option.label"
                    placeholder="Выберите сортировку"
                    @input="submitFilter()"
                  />
                </div>
              </div>
            </div>
          </b-collapse>
        </div>
      </div>
    </div>
    <div class="col-1">
      <div class="toggle-filter-block">
        <div class="toggle-filter"
             v-b-toggle.planned-issues-filter-collapse>
          <chevron-left-icon :class="{rotate: filterCollapseToggled}"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import IconReset from "@/components/Icons/IconReset";
import {mapState} from "vuex";
import DatePicker from "vue2-datepicker";
import Vue from "vue";
import DadataSuggestions from "vue-dadata-suggestions";
import IssueState from "@/models/serviceDesk/IssueState";

Vue.use(DadataSuggestions, {
  token: process.env.VUE_APP_DADATA_API_KEY,
  geoLocation: [{
    "kladr_id": process.env.VUE_APP_DADATA_KLADR_ID
  }]
});

export default {
  name: "PlannedIssuesListFilter",
  components: {IconReset, DatePicker},
  data() {
    return {
      filterCollapseToggled: false,
      filter: {
        category_id: null,
        address_region: null,
        address_city: null,
        address_settlement: null,
        address_street: null,
        address_house: null,
        address_block: null,
        address_flat: null,
        sort_dir: null,
        sort_by: 'planned_start_at',
        issue_states: IssueState.POSTPONED,
        type: null,
        department_id: null
      },
      planned_period: [],
      sortingOptions: [
        {dir: 'asc', label: 'По возрастанию даты'},
        {dir: 'desc', label: 'По убыванию даты'}
      ],
      address: null,
      defaultValue: new Date()
    }
  },
  computed: {
    ...mapState({
      categories: state => state.serviceDeskCategories.categories,
      issueTypes: state => state.issueTypes.types,
      departments: state => state.departments.departments
    }),
    IssueState: () => IssueState
  },
  mounted() {
    this.$store.dispatch('serviceDeskCategories/getAllCategories');
    this.$store.dispatch('issueTypes/getIssueTypes');
    this.$store.dispatch('departments/getAllDepartments');
  },
  methods: {
    submitFilter() {
      if (this.planned_period.length) {
        const [planned_date_start, planned_date_end] = this.planned_period;
        this.filter.planned_date_start = planned_date_start;
        this.filter.planned_date_end = planned_date_end;
      }
      this.$store.commit('plannedIssues/SET_FILTER_PARAMS', this.filter);
      this.$store.dispatch('plannedIssues/getIssues', 1);
    },
    clearFilter() {
      this.address = null;
      this.planned_period = null;
      this.filter = {
        category_id: null,
        address_region: null,
        address_city: null,
        address_settlement: null,
        address_street: null,
        address_house: null,
        address_block: null,
        address_flat: null,
        planned_date_start: null,
        sort_dir: null,
        sort_by: 'planned_start_at',
        issue_states: IssueState.POSTPONED,
        type: null,
        department_id: null
      }
      this.$store.commit('plannedIssues/SET_FILTER_PARAMS', this.filter);
      this.$store.dispatch('plannedIssues/getIssues', 1);
    },
    setAddress(i) {
      this.filter.address_region = i.data.region;
      this.filter.address_city = i.data.city;
      this.filter.address_settlement = i.data.settlement_with_type;
      this.filter.address_street = i.data.street_with_type;
      this.filter.address_house = i.data.house;
      this.filter.address_block = i.data.block;
      this.filter.address_flat = i.data.flat;

      this.submitFilter()
    }
  }
}
</script>

<style scoped>
.add-issue-btn {
  padding: 0 9px;
  margin-left: 20px;
}

.reset-filter-btn {
  margin-left: 20px;
}

.toggle-filter-block {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
}

.toggle-filter {
  display: flex;
  align-items: center;
  height: 44px;
  cursor: pointer;
}

.toggle-filter svg {
  width: 30px;
  height: 30px;
  color: #468FC5;
  transition: .3s;
}

.rotate {
  transform: rotateZ(-90deg);
}
</style>