export default class DataServer {
    constructor(id, params) {
        this.id = id;
        this.name = params.name;
        this.address = params.address;
        this.type = params.type;
        this.auth_type = params.auth_type;
        this.username = params.username;
        this.token = params.token;
        this.is_active = params.is_active;
    }

    getAuthTypeLabel() {
        switch (this.auth_type) {
            case 'no_auth':
                return 'Без аутентификации'
            case 'login_and_password':
                return 'Логин и пароль'
            case 'token':
                return 'По ключу'
        }
    }

    getStateLabel() {
        return this.is_active ? 'Активен' : 'Не активен'
    }
}