<template>
  <div class="row">
    <div v-if="this.$store.state.tasks.loading"
         class="col-12 text-center mt-3">
      <base-loader />
    </div>
    <div v-if="this.$store.state.tasks.tasks.length === 0 && !this.$store.state.tasks.loading"
         class="col-12 mt-3">
      <div class="no-result-title">
        Задания не найдены.
      </div>
    </div>
    <div v-if="!this.$store.state.tasks.loading"
         class="col-12 scroll">
      <div class="row">
        <div v-for="task in tasks"
             :key="task.id"
             class="col-12 task-item mb-2">
          <div class="custom-card">
            <div class="card-body">
              <div class="task-card-header">
                <div class="d-flex justify-content-between align-items-center">
                  <h3 @click="$router.push(`/task/${task.task.id}`)"
                      class="task-card-title cursor-pointer">
                    {{ task.task.type_label }}
                  </h3>
                  <div>
                    <base-badge :label="task.task.state.label"
                                :color="task.task.state.color"/>
                  </div>
                </div>
                <div @click="$router.push(`/task/${task.task.id}`)"
                     class="task-type-label">
                  Задание <span>#{{ task.task.id }}</span>
                </div>
              </div>

              <div class="task-info">
                <div @click="$router.push(`/issue/${task.issue.id}`)">
                  Связанная заявка <span>#{{ task.issue.id }}</span>
                </div>
                <div @click="$router.push(`/issue/${task.issue.id}`)">
                  Адрес: <span>{{ task.issue.getFullAddress() }}</span>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-12">
      <base-pagination-payload
        v-if="$store.state.tasks.meta && $store.state.tasks.meta.last_page > 1"
        :links="$store.state.tasks.links"
        :meta="$store.state.tasks.meta"
        :payload="createPayload()"
        action="tasks/getTasks"
        path="tasks"
      />
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import BaseBadge from "@/layout/BaseBadge";
import BasePaginationPayload from "@/components/BasePaginationPayload";
import BaseLoader from "@/layout/BaseLoader";

export default {
  name: "TasksList",
  components: {BaseLoader, BaseBadge, BasePaginationPayload},
  computed: {
    ...mapGetters({
      tasks: 'tasks/getTasks',
      filterParams: 'tasks/getFilterParams'
    })
  },
  methods: {
    createPayload() {
      return {
        page: 1,
        params: this.filterParams
      }
    }
  }
}
</script>

<style scoped>
.scroll {
  overflow-y: auto;
  max-height: calc(100vh - 184px);
  overflow-x: hidden;
  padding-right: 20px;
}

.scroll::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  background-color: #d6d6d6;
  border-radius: 0.2em;
}

.scroll::-webkit-scrollbar-thumb {
  background-color: rgba(151, 172, 181, 1);
  border-radius: 0.2em;
}

.task-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 4px;
}

.task-info div {
  font-weight: 500;
  cursor: pointer;
}

.task-info div span {
  color: #468FC5;
}

.task-card-title {
  font-size: 20px;
  font-weight: 600;
  color: black;
  margin-bottom: 0;
}

.task-type-label span {
  color: #468FC5;
  font-weight: 500;
}

.task-type-label {
  font-size: 16px;
  font-weight: 600;
  color: #262F3D;
  cursor: pointer;
}
</style>