<template>
  <journal-config-form-el />
</template>

<script>
import JournalConfigFormEl from "@/components/Telemetry/admin/JournalConfigsForm";

export default {
  name: "JournalConfigsFormPage",
  components: {
    JournalConfigFormEl
  },
  watch: {
    $route: 'preloadData'
  },
  created() {
    this.preloadData();
  },
  methods: {
    preloadData() {
      this.$store.dispatch("device/getAllDevices");
      this.$store.dispatch("sensor/getAllSensors");
    }
  }
}
</script>

<style scoped>

</style>