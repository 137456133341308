import * as API from './API';
import Consumable from "@/models/serviceDesk/Consumable";
import {getError} from "@/utils/helpers";

export default {
    fetchConsumablesList() {
        return new Promise((resolve, reject) => {
            API.apiClient.get(`/service-desk/consumables`)
                .then(response => resolve(response.data.data.map(item => new Consumable({
                    id: item.id,
                    ...item.attributes
                }))))
                .catch(error => reject(getError(error)))
        })
    },
    fetchConsumable(consumableId) {
        return new Promise((resolve, reject) => {
            API.apiClient.get(`/service-desk/consumables/${consumableId}`)
                .then(response => {
                    const {id, attributes} = response.data.data;
                    resolve(new Consumable({id, ...attributes}));
                })
                .catch(error => reject(getError(error)))
        })
    },
    createConsumable(payload) {
        return API.apiClient.post(`/service-desk/consumables`, payload);
    },
    updateConsumable(consumableId, payload) {
        return API.apiClient.patch(`/service-desk/consumables/${consumableId}`, payload);
    },
    deleteConsumable(consumableId) {
        return API.apiClient.delete(`/service-desk/consumables/${consumableId}`);
    }
}