<template>
  <div class="links-pagination">
    <div v-for="(link, index) in meta.links"
         :key="index">
      <div v-if="link.label === 'pagination.previous'"
           @click="prevPage()"
           class="nav-arrow">
        <chevron-left-icon/>
      </div>
      <div v-else-if="link.label === 'pagination.next'"
           @click="nextPage()"
           class="nav-arrow">
        <chevron-right-icon/>
      </div>
      <div v-else-if="link.label === '...'"
           class="hidden-elements">
        {{ link.label }}
      </div>
      <div v-else
           @click="setPage(parseInt(link.label))"
           class="pagination-item"
           :class="{active: link.active}">
        {{ link.label }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "BasePaginationLinks",
  props: {
    action: {
      type: String,
      required: true
    },
    path: {
      type: String,
      default: null
    },
    meta: {
      type: Object,
      required: true
    },
    payload: {
      type: Object,
      required: false
    }
  },
  methods: {
    prevPage() {
      this.payload['page'] = this.meta.current_page - 1
      this.$store.dispatch(this.action, this.payload)
          .then(() => {
            if (this.path) {
              this.$router.push({
                path: this.path,
                query: {page: this.meta.current_page - 1}
              });
            }
          });
    },
    nextPage() {
      this.payload['page'] = this.meta.current_page + 1
      this.$store.dispatch(this.action, this.payload)
          .then(() => {
            if (this.path) {
              this.$router.push({
                path: this.path,
                query: {page: this.meta.current_page + 1}
              });
            }
          });
    },
    setPage(page) {
      this.payload['page'] = page;
      this.$store.dispatch(this.action, this.payload)
          .then(() => {
            if (this.path) {
              this.$router.push({
                path: this.path,
                query: {page: page}
              })
            }
          })
    }
  }
}
</script>

<style scoped>
.links-pagination {
  display: flex;
}

.pagination-item {
  margin-right: 5px;
  background-color: #EAEFF2;
  padding: 4px 6px;
  color: #468FC5;
  border-radius: 3px;
  cursor: pointer;
}

.active {
  background-color: #468FC5;
  color: white;
}

.hidden-elements {
  display: flex;
  align-items: flex-end;
  color: #468FC5;
  margin-right: 5px;
  font-size: 17px;
  height: 100%;
  padding: 0 5px;
}

.nav-arrow {
  display: flex;
  align-items: center;
  height: 100%;
  cursor: pointer;
}

.nav-arrow svg {
  color: #468FC5;
}
</style>