export default class EmergencyElement {
    constructor(id, attributes) {
        this.id = id;
        this.network = attributes.network;
        this.network_element = attributes.network_element;
        this.element_type = attributes.element_type;
        this.comment = attributes.comment;
        this.features = attributes.features;
        this.damageId = attributes.damage?.id;
        this.damageName = attributes.damage?.name;
    }

    getCaption() {
        return `${this.element_type.name} #${this.id}`
    }
}