<template>
  <div class="row justify-content-center align-items-center fixed-height">
    <div class="col-xl-4 col-xs-12">
      <device-access-form />
    </div>
  </div>
</template>

<script>
import DeviceAccessForm from "@/components/Telemetry/admin/DeviceAccessForm";
export default {
  name: "DeviceAccessFormPage",
  components: {DeviceAccessForm}
}
</script>

<style scoped>

</style>