<template>
  <div class="custom-card">
    <div class="card-body">
      <div class="d-flex">
        <div class="w-100">
          <dadata-suggestions
            class="form-control form-control-fix"
            type="ADDRESS"
            @change="setAddress"
            placeholder="Начните вводить адрес"
            v-model="rawAddress"
          />
        </div>
        <button @click="clearFilter()"
                class="btn custom-btn-blue-outline reset-filter-btn">
          <icon-reset />
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import DadataSuggestions from "vue-dadata-suggestions";
import IconReset from "@/components/Icons/IconReset";

Vue.use(DadataSuggestions, {
  token: process.env.VUE_APP_DADATA_API_KEY,
  geoLocation: [{
    "kladr_id": process.env.VUE_APP_DADATA_KLADR_ID
  }]
});
export default {
  name: "CreateIssueAppealsListFilter",
  components: {IconReset},
  data() {
    return {
      filter: {
        address_region: null,
        address_city: null,
        address_settlement: null,
        address_street: null,
        address_house: null,
        address_block: null,
        address_flat: null,
        appeal_status: '1'
      },
      rawAddress: null
    }
  },
  methods: {
    submitFilter() {
      this.$store.commit('appeals/SET_FILTER_PARAMS', this.filter);
      this.$store.dispatch('appeals/getAppeals', 1);
    },
    clearFilter() {
      this.filter = {
        address_region: null,
        address_city: null,
        address_settlement: null,
        address_street: null,
        address_house: null,
        address_block: null,
        address_flat: null,
        appeal_status: '1'
      }
      this.rawAddress = null;

      this.$store.commit('appeals/SET_FILTER_PARAMS', null);
      this.$store.dispatch('appeals/getAppeals', 1);
    },
    setAddress(i) {
      this.filter.address_region = i.data.region;
      this.filter.address_city = i.data.city;
      this.filter.address_settlement = i.data.settlement_with_type;
      this.filter.address_street = i.data.street_with_type;
      this.filter.address_house = i.data.house;
      this.filter.address_block = i.data.block;
      this.filter.address_flat = i.data.flat;

      this.submitFilter()
    }
  }
}
</script>

<style scoped>
.reset-filter-btn {
  margin-left: 20px;
}
</style>